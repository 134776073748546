<template>
    <div class="text-center">
        <v-snackbar 
            v-model="alert_notification"
            bottom            
            :color="get_alert_notification_type"
            text> 
            <div>           
                <v-icon class='mr-2' :color="get_alert_notification_type">{{ get_notification_icon }}</v-icon> <span v-html="get_notification_message"></span>
            </div>
        </v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data: () => ({
        alert_notification: false,             
    }),
    computed: {
        ...mapGetters({
            get_alert_notification_type:        'alert_notifications/get_alert_notification_type_getter',
            get_open_alert_notification:        'alert_notifications/get_open_alert_notification_getter',
            get_notification_message:           'alert_notifications/get_notification_message_getter',
            get_notification_icon:              'alert_notifications/get_notification_icon_getter'
        })
        
    },
    methods: {
        ...mapActions({
            set_alert_notification_type:        'alert_notifications/set_alert_notification_type_action',
            open_alert_notification:            'alert_notifications/open_alert_notification_action'
        })
    },
    mounted() {       
    },
    watch: {
        get_open_alert_notification() {
            this.alert_notification = this.get_open_alert_notification
        }
    }
}

</script>