<template>
    <div>
        <div v-if="setup.pp">
          <v-autocomplete
            v-model="pptemplate"
            :items="items"
            item-value="id"
            item-text="template_name"
            :value="value"
            dense
            outlined
            :label="value.template_name"
            placeholder="Choose a template"
            @change="selecttemplate"
            return-object
          ></v-autocomplete>
        </div>
    </div>
</template>

<script>
export default {
  components: {
  },
  props: [
    'setup', 'items', 'value'
  ],
  data: () => ({
    pptemplate:       null,
  }),
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    selecttemplate(){
      this.$emit('selecttemplate', this.pptemplate)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>