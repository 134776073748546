<template>
    <div>
      <ListItem
        :setup="project_setup"
        :items="project"
        :header="headersfreetier"
        :template="gettopasstemplate"
        @addchild="addchild"
        @addsection="addsection"
        @editrecord="editrecord"
        @deleteparent="deleteparent"
      />
      <DialogComponent
        :value="addchilddialogmodal"
        v-model="addchilddialogmodal"
        :dialogvalue="dialogvalue"
        @closedialog="closedialog"
        @savedata="savedata"
      />
      <DialogComponent
        :value="editchildmodal"
        v-model="editchildmodal"
        :dialogvalue="dialogvalueedit"
        :ddvalues="ddvalues"
        :childdata="getchilddata"
        @closedialog="closedialog"
        @saveeditedchild="saveeditedchild"
        @deletechild="deletechild"
      />
      <DialogWarning 
        v-model="dialogwarningdeletechild"
        :value="dialogwarningdeletechild"
        :dialogsvalue="dialogwarningcontent"
        @donothing="donothingchild"
        @delproject="confirmdeletechild"
      />
      <DialogWarning 
        v-model="dialogwarningdeleteparent"
        :value="dialogwarningdeleteparent"
        :dialogsvalue="dialogwarningcontent"
        @donothing="donothingparent"
        @delproject="confirmdeleteparent"
      />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ListItem from '../../components/ListItems.vue'
import DialogComponent from '../../components/DialogComponent.vue'
import DialogWarning from '../../components/DialogWarning.vue'
export default {
  components: {
    ListItem,
    DialogComponent,
    DialogWarning
  },
  props: [
  ],
  data: () => ({
    headersfreetier: [
      { text: 'Task Name', filterable: true, value: 'task_name', class: 'headerColorToBlue '},
      { text: 'Duration', filterable: true, value: 'duration'},
      { text: 'Status', filterable: true, value: 'status'},
      { text: 'Start', filterable: true, value: 'start'},
      { text: 'Finish', filterable: true, value: 'finish'},
      { text: 'Notes', filterable: true, value: 'notes'},
      // { text: 'Media', width: '15%', filterable: false}
    ],
    project_setup: {
      isreal: true,
      prepends: [
        {
          id:       1,
          action:   true,
          label:    'add task',
          icon:     'mdi-plus'
        }
      ],
      buttons: [
        {
          id:       1,
          action:   true,
          label:    'add section',
          dark:     true,
          text:     false,
          class:    'pl-10 pr-10',
          color:    '#0b4ef3',
          icon:     'mdi-plus'
        }
      ]
    },
    addchilddialogmodal: false,
    dialogvalue: {
      modal: false,
      reminder: true,
      title: 'add task',
      icon: 'mdi-plus',
      textfield: [
        {
          id:     1,
          label:  'task name',
          hint:   'task name',
          icon:   'mdi-tooltip-text-outline',
          type:   'text'
        }
      ]
    },
    editchildmodal: false,
    ddvalues: ['Pending', 'Started', 'On-Hold', 'Waiting-on Info', 'Complete'],
    dialogvalueedit: {
      ppeditchild:  true,
      title:        'Edit Entry',
      icon:         'mdi-pen',
      button: [
        {
          id:       1,
          icon:     'mdi-delete'
        }
      ],
      textfield:          [
        {
          id:       1,
          label:    'task name',
          hint:     'task name',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        },
        {
          id:       2,
          label:    'duration',
          hint:     'duration',
          icon:     'mdi-tooltip-text-outline',
          type:     'number'
        },
        {
          id:       1,
          label:    'who',
          hint:     'who',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        }
      ],
      date: [
        {
          id:       1,
          label:    'start date',
          hint:     'start date',
          icon:     'mdi-calendar'
        },
        {
          id:       2,
          label:    'finish date',
          hint:     'finish date',
          icon:     'mdi-calendar'
        }
      ],
      dropdown: [
        {
          id:       1,
          label:    'status',
          hint:     'status',
          icon:     'mdi-tooltip-text-outline'
        }
      ],
      textarea: [
        {
          id:         1,
          label:      'notes',
          hint:       'notes',
          icon:       'mdi-tooltip-text-outline'
        }
      ]
    },
    dialogwarningdeletechild: false,
    dialogwarningdeleteparent: false,
    dialogwarningcontent: {
      warningdelete: true,
      title: 'Are you sure you want to delete this entry?',
      content: 'All of the data that belongs to this entry will be deleted, are you sure about this?',
      buttons: [
        {
          id:         2,
          label:      'Yes',
          action:     true,
          class:      '',
          text:       true,
          dark:       false
        },
        {
          id:         1,
          label:      'No',
          action:     false,
          color:      '#0b4ef3',
          text:       false,
          class:      'pl-16 pr-16',
          dark:       true
        },
      ]
    },
  }),
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
      getuser:              'auth/getuser',
      gettopasstemplate:    'pp/getToPassTemplate',
      project:              'pp/getproject',
      parent_data:          'pp/getparentdata',
      getproject:           'auth/getproject',
      getchilddata:         'pp/getchilddata'
    })
  },
  methods: {
    addchild(){
      this.addchilddialogmodal = true
    },
    closedialog(){
      this.addchilddialogmodal = false
      this.editchildmodal = false
      this.dialogwarningdeletechild = false
      this.dialogwarningdeleteparent = false
    },
    async fetchproject(){
      await this.$axios.get(`projectplanner/getThisTemplate/${this.getproject.template_name}`)
      .then(({data}) => {
        this.$store.dispatch('pp/setproject', data.data)
      })
    },
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async savedata(data){
      const tp = {
        task:           data.input[1],
        id:             this.parent_data.id,
        template_name:  this.parent_data.template_name
      }
      //console.log(tp)
      await this.$axios.post('projectplanner/addSubTask', tp)
      .then(({data}) => {
        this.showSnackBar(data.message)
        this.closedialog()
        this.fetchproject()
      })
    },
    addsection(){
      if(this.getuser.info.access_id == 1){
        this.showSnackBar('Please upgade your account to add more section')
        return
      }
      
    },
    editrecord(){
      this.editchildmodal = true
    },
    async saveeditedchild(data){
      //console.log(data)
      let tp = {
        status:     data.dropdown,
        start:      data.start,
        finish:     data.finish,
        notes:      data.notes,
        duration:   data.textfield[0].duration,
        task_name:  data.textfield[0].task_name == undefined ? '' : data.textfield[0].task_name,
        who:        data.textfield[0].who,
      }
      await this.$axios.patch(`/projectplanner/editChild/${this.getchilddata.id}`, tp)
      this.closedialog()
      this.fetchproject()
    },
    deletechild(){
      this.dialogwarningdeletechild = true
    },
    donothingchild(){
      this.dialogwarningdeletechild = false
    },
    async confirmdeletechild(){
      //console.log(this.getchilddata.id)
      const deleteitem = await this.$axios.delete(`projectplanner/deleteChildTask/${this.getchilddata.id}`)
      this.showSnackBar(deleteitem.data.message)
      this.fetchproject()
      this.closedialog()
    },
    deleteparent(){
      this.dialogwarningdeleteparent = true
    },
    donothingparent(){
      this.dialogwarningdeleteparent = false
    },
    async confirmdeleteparent(){
      if(this.getuser.info.access_id == 1){
        this.showSnackBar('Please upgade your account to add and delete sections')
        return
      }
      const deleteparent = await this.$axios.delete(`/deleteTask/${this.parent_data.id}`)
      this.showSnackBar(deleteparent.data.message)
      this.fetchproject()
      this.closedialog()
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>