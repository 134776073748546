import Vue from "vue"

export default {
    namespaced: true,
    state: {
      templates: [],
      selectedtemplate: [],
      topasstemplate: {},
      project: [],
      parent_data: {},
      edit_child_data: {},
      checklist: []
    },
    mutations: {
      setTemplates(state, payload){
        state.templates = [...payload]
      },
      setSelectedTemplate(state, payload){
        state.selectedtemplate = [...payload]
      },
      setToPassTemplate(state, payload){
        state.topasstemplate = payload
      },
      clearselectedandtopass(state){
        state.selectedtemplate = []
        state.topasstemplate = {}
      },
      setproject(state, payload){
        let dd = []
        if(!Array.isArray(payload)){
          Object.keys(payload).forEach(q => {
            // console.log(payload[q])
            dd.push(payload[q])
          })
          state.project = [...dd]
          return
        }
        state.project = [...payload]
      },
      setparentdata(state, payload){
        state.parent_data = payload
      },
      setchildata(state, payload){
        state.edit_child_data = payload
      },
      async setnewprojectonchangename(){
        const project_id = Vue.$store.getters['auth/getproject']
        await Vue.$axios.get(`projectplanner/getThisTemplate/${project_id.template_name}`)
        .then(({data}) => {
          Vue.$store.dispatch('pp/setproject', data.data)
        })
      },
      set_checklist(state, payload){
        state.checklist = [...payload]
      },
      set_selected_project_template_mutations(state, payload) {    
        if ( payload.template_id ) {   
          state.topasstemplate = state.templates.find(template => template.id === payload.template_id);
        } else {
          state.topasstemplate = null;
        }
      }
    },
    getters: {
      getTemplates(state){
        return state.templates
      },
      getSelectedTemplate(state){
        return state.selectedtemplate
      },
      getToPassTemplate(state){
        return state.topasstemplate
      },
      getproject(state){
        return state.project;
      },
      getparentdata(state){
        return state.parent_data
      },
      getchilddata(state){
        return state.edit_child_data
      },
      get_checklist : state => state.checklist
    },
    actions: {
      setTemplates({commit}, payload){
        commit('setTemplates', payload)
      },
      setSelectedTemplate({commit}, payload){
        commit('setSelectedTemplate', payload)
      },
      setToPassTemplate({commit}, payload){
        commit('setToPassTemplate', payload)
      },
      setproject({commit}, payload){
        commit('setproject', payload)
      },
      setparentdata({commit}, payload){
        commit('setparentdata', payload)
      },
      setchildata({commit}, payload){
        commit('setchildata', payload)
      },
      set_checklist({commit}, payload){
        commit('set_checklist', payload)
      },
      set_project_template_actions({commit}, payload) {
        commit('set_selected_project_template_mutations', payload);
      }
    }
}