export default {
    namespaced: true,
    state: {
        service_types: [],
        country_list: [],
        cities: [],
        postalcities: [],
        phycities: []
    },
    mutations: {
        setservice_types(state, payload){
            state.service_types = [...payload]
        },
        setcountry_list(state, payload){
            state.country_list = [...payload]
        },
        setcities(state, payload){
            state.cities = [...payload]
        },
        setpostalcities(state, payload){
            state.postalcities = [...payload]
        },
        setphycities(state, payload){
            state.phycities = [...payload]
        },
    },
    getters: {
        getservice_types(state){
            return state.service_types
        },
        getcountry_list(state){
            return state.country_list
        },
        getcities(state){
            return state.cities
        },
        getpostalcities(state){
            return state.postalcities
        },
        getphycities(state){
            return state.phycities
        }
    },
    actions: {
        setservice_types({commit}, payload){
            commit('setservice_types', payload)
        },
        setcountry_list({commit}, payload){
            commit('setcountry_list', payload)
        },
        setcities({commit}, payload){
            commit('setcities', payload)
        },
        setpostalcities({commit}, payload){
            commit('setpostalcities', payload)
        },
        setphycities({commit}, payload){
            commit('setphycities', payload)
        }
    }
}