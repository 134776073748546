<template>
    <v-row justify="center">
        <v-dialog
            v-model="add_task_modal"
            persistent
            scrollable
            max-width="690">
            <v-card>
                <v-card-title>
                    <v-icon>mdi-plus</v-icon>
                    <small>Add Task</small>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        outlined
                        prepend-icon="mdi-tooltip-text"
                        label="Add Task"
                        dense
                        class="mt-3"
                        v-model="task_name">
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="closedialog">
                        Close
                    </v-btn>
                    <v-btn
                        color="#093cba"
                        dark
                        @click="save_task"
                        class="pl-16 pr-16">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data: () => ({
        task_name: '',
        add_task_modal: false
    }),
    props: {
        show_add_task_modal: Boolean,
        task_item: Object
    },
    methods: {
        ...mapActions({
            add_new_task:       'project_planner_v2/add_new_task_actions'
        }),
        async save_task() {
            const section = this.task_item;
            const name = this.task_name;
            
            await this.add_new_task({
                section: section,
                data: {
                    name: name
                }
            });
            this.closedialog();
        },
        closedialog() {            
            this.task_name = '';
            this.$emit('close_add_task_dialog');            
        }
    },
    watch: {
        show_add_task_modal() {
            this.add_task_modal = this.show_add_task_modal;
        }
    }
}
</script>