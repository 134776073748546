<template>
    <v-row justify="center">
        <v-dialog
            v-model="upload_image_model"
            persistent
            max-width="650">
            <v-card>
                <v-card-title>Upload Image</v-card-title>
                <v-card-text>
                    <v-file-input
                        v-model="image_upload"
                        accept="image/jpeg, image/jpg, image/png, image/gif"
                        label="Upload Image File"
                        hint="Upload Image File">
                    </v-file-input>
                    <v-img v-if="preview_image_upload != null"
                        :src="preview_image_upload"                        
                        contain
                        aspect-ratio="1.5"></v-img>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text 
                        :disabled="loading"
                        color="error" 
                        @click="evtCloseDialog">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Cancel
                    </v-btn>
                    <v-btn color="success"
                        :loading="loading" 
                        :disabled="loading" 
                        class="px-6" @click="evtUploadFile">
                        <v-icon class="mr-2">mdi-upload</v-icon>
                        Upload
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import BillOfMaterials from '../modules/BillOfMaterials.js'

export default {
    data: () => ({
        upload_image_model: false,
        image_upload: null,
        loading: false,
        preview_image_upload: null,
        bill_of_materials: new BillOfMaterials(),
        image_path: `${process.env.VUE_APP_URL}uploads/bom`
    }),
    props: {
        upload_image_dialog: Boolean,
        bom_item: Object
    },
    methods: {
        ...mapActions({
            setBoMItemImage:      'billofmaterials/setBoMItemImageAction'
        }),
        evtCloseDialog() {
            this.$emit('evtCloseDialog')
        },
        async evtUploadFile() {
            this.loading = true
            //this.setBoMItemImage({bom_item: this.bom_item, image: this.image_upload})
            await this.bill_of_materials.uploadBoMItemImageUpload({id: this.bom_item.id, image: this.image_upload})
            this.loading = false
            this.image_upload = null
            this.evtCloseDialog()
        },
        getImagePath(item) {
            return String(item).includes('blob:') == true ? item : `${this.image_path}/${item}`
        }
    },
    mounted() {
        this.bill_of_materials.setStore(this.$store)
    },
    watch: {
        upload_image_dialog() {
            this.upload_image_model = this.upload_image_dialog
            if ( this.upload_image_dialog == true ) {
                this.preview_image_upload = this.bom_item.image != null ? this.getImagePath(this.bom_item.image) : null
            }
        }
    }
}
</script>