<template>
    <div>
        <v-row>
            <v-col cols="11">
                <v-text-field
                    v-model="searchpdf"                     
                    prepend-inner-icon="mdi-magnify"
                    placeholder="search by video name | description here..."
                    label="search by video name | description here...">
                </v-text-field>               
            </v-col>           
            <v-col cols="1" class="justify-content-right">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on">
                            <v-switch 
                                v-model="show_deleted"
                                x-small                   
                                class="mx-3"
                                inset>
                            </v-switch>
                        </div>
                    </template>
                    <span>Show or hide deleted files.</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="show_deleted ? get_uploaded_trashed_files.pdf : get_pdf_files.pdf" 
            :sort-by="sortBy"
            :sort-desc="sortDesc"          
            :search="searchpdf">  
            <template v-slot:item="{ item }" >
                <tr>
                    <td>{{ item.name }}</td>                    
                    <td>{{ item.get_user.name }}</td>
                    <td class="text-center">
                        <div>
                            <v-icon color="red">mdi-file-pdf-box</v-icon>
                            <v-btn text small
                                @click="open_pdf(item.file_url)"
                                >View</v-btn>
                        </div>
                    </td>
                    <td class="text-center">{{ item.created_at }}</td>
                    <td class="text-center">
                        <div v-if="!show_deleted">
                            <v-btn text small fab color="primary" @click="edit_pdf_file(item)">
                                <v-icon small>mdi-pen</v-icon>
                            </v-btn>
                            <v-btn text small fab color="error" @click="delete_uploaded_file_modal(item)">
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                        <div v-else>
                            <v-btn text small fab color="success" @click="restore_deleted_file_modal(item)">
                                <v-icon small>mdi-restore</v-icon>
                            </v-btn>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <!-- Delete Modal -->
        <v-dialog
            width="50%"
            v-model="delete_modal"           
            persistent
            transition="dialog-bottom-transition"
            :loading="loading">
            <v-card>
                <v-card-title>
                    <span>{{ modal_title }}</span>
                    <v-spacer></v-spacer>
                    <v-btn text small fab @click="close_delete_modal()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <template slot="progress">
                        <v-progress-linear
                            color="primary"
                            height="5"
                            indeterminate></v-progress-linear>
                    </template>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="black--text mt-5">
                    Are you sure your want to delete this file?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text small width="150" class="mx-2" @click="close_delete_modal()">No</v-btn>
                    <v-btn color="error" width="150" class="white--text" small @click="execute_delete_file_modal_action()">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- /Delete Modal -->

        <!-- Restore Modal -->
        <v-dialog
            width="50%"
            v-model="restore_modal"           
            persistent
            transition="dialog-bottom-transition"
            :loading="loading">
            <v-card>
                <v-card-title>
                    <span>{{ modal_title }}</span>
                    <v-spacer></v-spacer>
                    <v-btn text small fab @click="close_restore_modal()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <template slot="progress">
                        <v-progress-linear
                            color="primary"
                            height="5"
                            indeterminate></v-progress-linear>
                    </template>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="black--text mt-5">
                    Are you sure your want to restore this file?
                </v-card-text>               
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text small width="150" class="mx-2" @click="close_restore_modal()">No</v-btn>
                    <v-btn color="error" width="150" class="white--text" small @click="execute_restore_file_modal_action()">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- /Restore Modal -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data: () => ({
        loading: false,
        delete_modal: false,
        restore_modal: false,
        modal_title: null,
        show_deleted: false,
        searchpdf: '',
        toggle_none: 0,
        sortBy: 'updated_at',
        sortDesc: true,
        APP_URL: process.env.VUE_APP_URL,
        file: null,
        headers: [
            {
                text: 'Title',
                align: 'start',
                sortable: true,
                filterable: true,
                value: 'name',
            }, 
            /*{
                text: 'Description',
                align: 'start',
                sortable: true,
                filterable: true,               
                value: 'description',
            },*/
            {
                text: 'Author',
                align: 'start',
                sortable: true,
                filterable: true,
                value: 'get_user.name',
            },
            {
                text: 'File(s)',
                align: 'center',
                sortable: false,
                value: 'file_url',
            }, 
            {
                text: 'Created',
                align: 'center',
                sortable: true,
                value: 'created_at',
            },
            {
                text: 'Action',
                align: 'center',
                sortable: false,
                value: '',
            },
        ]
    }),
    props: {
        
    },
    computed: {
        ...mapGetters({
            get_pdf_files:                          'admin_helper_training/get_uploaded_files',
            get_uploaded_trashed_files:             'admin_helper_training/get_uploaded_trashed_files'
        })
    },
    methods: {
        ...mapActions({
            fetch_files_by_types:                   'admin_helper_training/fetch_files_by_types',
            delete_uploaded_file:                   'admin_helper_training/delete_uploaded_file',
            get_trashed_uploaded_files_by_types:    'admin_helper_training/get_trashed_uploaded_files_by_types',
            restore_trashed_uploaded_files:         'admin_helper_training/restore_trashed_uploaded_files'
        }),
        open_pdf(file_url) {
            window.open(this.APP_URL + file_url, '_blank');
        },
        edit_pdf_file(item) {
            this.$emit('edit_dialog', item)           
        },
        delete_pdf_file() {
            this.delete_uploaded_file(this.file);
        },
        restore_trashed_file() {
            this.restore_trashed_uploaded_files(this.file);
        },
        delete_uploaded_file_modal(file) {
            this.file = file;
            this.modal_title = `Delete the file ${file.name}.`;
            this.delete_modal = true
        },
        async execute_delete_file_modal_action() {
            this.loading = true;
            await this.delete_pdf_file();
            this.loading = false;
            this.close_delete_modal();          
        },
        close_delete_modal() {
            this.modal_title = null;
            this.delete_modal = false;
            this.file = null;
        },
        restore_deleted_file_modal(file) {
            this.file = file;
            this.modal_title = `Restore the file ${file.name}.`;
            this.restore_modal = true;
        },
        close_restore_modal() {
            this.file = null;
            this.modal_title = null;
            this.restore_modal = false
        }, 
        async execute_restore_file_modal_action() {
            this.loading = true;
            await this.restore_trashed_file();
            this.loading = false;
            this.close_restore_modal();
        }
    },
    watch: {
        
    },
    mounted() {    
        this.get_trashed_uploaded_files_by_types('pdf');
        this.fetch_files_by_types('pdf');
    }
}
</script>