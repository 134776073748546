
export default {
    namespaced: true,
    state: {
        messagebox: {},
        open: false
    },
    mutations: {
        open_messagebox_dialog_mutation(state) {
            state.open = true;
        },
        set_messagebox_details_mutation(state, payload) {
            state.messagebox = payload;
        },
        close_messagebox_dialog_mutation(state) {
            state.open = false;
        }
    },
    getters: {
        get_open_messagebox_dialog_getter: state => state.open,
        get_messagebox_details_getter: state => state.messagebox
    },
    actions: {
        set_messagebox_details_action({ commit }, payload) {
            commit('set_messagebox_details_mutation', payload);
        },
        open_messagebox_dialog_action({ commit }) {
            commit('open_messagebox_dialog_mutation');
        },
        close_messagebox_dialog_action({ commit }) {
            commit('close_messagebox_dialog_mutation');
        }
    }
}