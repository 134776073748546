import store from '@/states/index.js'

import Profile from '../SupplierProfile'

export default [
    {
        path: '/supplier-profile',
        component: Profile,
        name: 'supplierprofile',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 2){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]