<template>
    <v-row justify="center">
        <v-dialog
            v-model="add_sitediary_entry_dialog"
            persistent
            max-width="690"
            scrollable>
            <v-card>
                <v-card-title style="background: #062a82;" class="white--text">
                    Add Site Diary
                </v-card-title>
                <v-card-text class="mt-6">
                    <v-form
                        ref="sitediary_entry_form"
                        v-model="valid"
                        lazy-validation>                        
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="entryData.site_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="entryData.site_date"
                                    label="site date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    dense
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="entryData.site_date"
                                no-title
                                scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(entryData.site_date)">
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>                                           
                        <v-text-field
                            label="Log Name"
                            outlined
                            prepend-icon="mdi-tooltip-text-outline"
                            hint="Log Name"
                            dense                                
                            required
                            v-model="entryData.log_name"
                            type="text"
                        ></v-text-field>              
                        <v-combobox
                            v-model="entryData.tags"
                            :items="gettags"
                            :search-input.sync="search"
                            hide-selected
                            label="Add Some Tags"
                            item-text="tags"
                            multiple
                            persistent-hint
                            small-chips
                            outlined
                            dense                              
                            prepend-icon="mdi-tag-multiple">
                            <template v-slot:no-data>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-combobox>                                             
                        <v-file-input
                            v-model="entryData.primary_image"
                            small-chips
                            dense
                            outlined
                            label="Add Primary Image"
                            required></v-file-input>

                        <v-file-input
                            v-model="entryData.alt_images"
                            color="primary accent-4"
                            counter
                            label="Alternative Images"
                            multiple
                            placeholder="Select your Images"
                            prepend-icon="mdi-file-multiple"
                            :show-size="1000"
                            outlined
                            dense
                            type="file">
                            <template v-slot:selection="{ index, text }">
                                <v-chip
                                    v-if="index < 2"
                                    color="primary accent-4"
                                    dark
                                    label
                                    small>
                                    {{ text }}
                                </v-chip>

                                <span
                                    v-else-if="index === 2"
                                    class="text-overline grey--text text--darken-3 mx-2">
                                    +{{ entryData.alt_images.length - 2 }} File(s)
                                </span>
                            </template>
                        </v-file-input>                        
                        <v-textarea
                            outlined
                            dense
                            v-model="entryData.notes"
                            prepend-icon="mdi-tooltip-text-outline"
                            label="Notes"
                            required>
                        </v-textarea>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text class="px-16" @click="eventCancelAddEntryDialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Cancel
                    </v-btn>
                    <v-btn color="primary" class="px-16" @click="eventSaveEntryData()">
                        <v-icon class="mr-2">mdi-content-save-outline</v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SiteDiary from '../modules/sitediary';

export default {
    data: () => ({
        add_sitediary_entry_dialog: false,
        entryData: {
            site_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            log_name: '',
            tags: [],
            primary_image: null,
            alt_images: [],
            notes: '',
            project_id: '',
        },
        menu: null,
        search: null,
        valid: true
    }),
    props: {
        addSiteDiaryEntryDialog: Boolean
    },
    computed: {
        ...mapGetters({
            gettags:                'sitediary/gettags',
            getproject:             'auth/getproject',
            get_upload_setting:     'sitediaryv2/get_upload_setting_getter'
        })
    },
    methods: {
        checkFileList() {
            console.log(this.entryData.alt_images)
        },
        eventCancelAddEntryDialog() {
            this.$emit('closeAddEntryDialog')
        },
        eventSaveEntryData() {
            this.entryData.project_id = this.getproject.id
            SiteDiary().saveSiteDiary(this.entryData, this.$store)
            this.eventCancelAddEntryDialog()
        }
    },
    watch: {
        addSiteDiaryEntryDialog() {            
            if ( typeof this.$refs.sitediary_entry_form != 'undefined' ) {
                this.$refs.sitediary_entry_form.reset()
            }
            this.add_sitediary_entry_dialog = this.addSiteDiaryEntryDialog
        }
    }
}

</script>