<template>
    <div>
        <v-dialog
            v-model="desgintracker_dialog"
            max-width="1280px"
            persistent>
            <v-card>
                <v-card-title>Design Tracker</v-card-title>
                <v-card-text>
                    <v-select
                        v-model="selected_project"
                        :items="get_projects"
                        item-text="name"
                        item-value="id">
                    </v-select>
                </v-card-text>
                <v-card-text>
                    <v-tabs
                        v-model="designtracker_tabs">
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab href="#fff">
                            FURNITURES, FIXTURES & FITTINGS
                        </v-tab>
                        <v-tab href="#app">
                            APPLIANCES
                        </v-tab>
                        <v-tab href="#fc">
                            FLOORING CALCULATIONS
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items 
                        v-model="designtracker_tabs">
                        <v-tab-item
                            value="fff">
                            <FFFTable
                                :user_data="user"
                                :project="selected_project"></FFFTable>
                        </v-tab-item>
                        <v-tab-item
                            value="app">
                            <ApplianceTable
                                :user_data="user"
                                :project="selected_project"></ApplianceTable>
                        </v-tab-item>
                        <v-tab-item
                            value="fc">
                            <FCTable
                                :user_data="user"
                                :project="selected_project"></FCTable>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="evnt_close_dialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FFFTable from './subcomponents/FFFTable.vue'
import ApplianceTable from './subcomponents/ApplianceTable.vue'
import FCTable from './subcomponents/FCTable.vue'

export default {
    data: () => ({
        designtracker_tabs: '#fff',
        desgintracker_dialog: false,
        selected_project: null
    }),
    components: {
        FFFTable,
        ApplianceTable,
        FCTable
    },
    props: ['desgintrackerdialog', 'user'],
    computed: {
        ...mapGetters({
            get_projects: 'admin_designtracker/get_projects_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_get_projects:             'admin_designtracker/fetch_get_projects_action',
            fetch_designtracker_data:       'admin_designtracker/fetch_designtracker_data_action'
        }),
        evnt_close_dialog() {
            this.$emit('evnt_closedialog')
        }
    },
    watch: {
        desgintrackerdialog() {
            this.desgintracker_dialog = this.desgintrackerdialog            
        },
        async user() {
            await this.fetch_get_projects({users_id: this.user.id})
            setTimeout(() => {
                this.selected_project = this.get_projects[0].id
                this.fetch_designtracker_data({
                    users_id: this.user.id,
                    project_id: this.selected_project
                })
            }, 200);
        },
        selected_project() {            
            this.fetch_designtracker_data({
                users_id: this.user.id,
                project_id: this.selected_project
            })
        }
    }
}
</script>