import store from '@/states/index.js'

import HelperTrainingFiles from '../HelperTrainingFiles'

export default [
    {
        path: '/admin_helper_training_files',
        component: HelperTrainingFiles,
        name: 'admin_helper_training_files',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if (role.info.role === 1) {
                next()
            } else {
                next('/')
            }
            return
        }
    }
]