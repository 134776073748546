<template>
    <v-container
        class="pa-0"
        style="width: 1080px;"
    >
        <v-breadcrumbs :items="get_bcrumbs" large>
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
        </v-breadcrumbs>
    </v-container>
</template>
<script>
export default {
    props: [
        'get_bcrumbs'
    ]
}
</script>