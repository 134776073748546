import store from '@/states/index.js'

import HomeComponent from '../HomeComponent'

export default [
    {
        path: '/admin_dashboard',
        component: HomeComponent,
        name: 'admin_dashboard',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 1){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]