<template>
    <v-container fluid class="mt-5 flex">
      <v-card>
        <v-card-title style="fontSize: 14px;">
            <strong>User List</strong>
        </v-card-title>
        <v-card-subtitle>
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="search name | email here..."
              label="search name | email here..."
            >
            </v-text-field>
        </v-card-subtitle>
        <v-card-text>
            <v-data-table
                :headers="table_header"
                :items="get_user_list"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                item-key="id"
            >
                <!-- <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr>
                            <th v-for="(h, i) in headers" :key="i">
                                <strong>{{h.text}}</strong>
                            </th>
                        </tr>
                    </thead>
                </template> -->
                <template v-slot:item="{ item, expand, isExpanded }" >
                    <tr
                        style="cursor:pointer;"
                        @click="expand(!isExpanded)"
                    >
                        <td>
                            {{ item.id }}
                        </td>
                        <td>
                            {{ item.name }}
                        </td>
                        <td>
                            {{ item.email }}
                        </td>
                        <td>
                            <label v-if="item.get_partner === null">No</label>
                            <label v-else>Yes</label>
                        </td>
                        <td>
                            {{ item.created_at }}
                        </td>
                        <td>
                            <label
                                :style="item.login_count > 0 ? 'background: red; color: white; padding: 5px;' : 'color: black'"
                            >
                                {{item.login_count}}
                            </label>
                        </td>
                        <td>
                            {{item.updated_at}}
                        </td>
                    </tr>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-card>
                            <label class="ml-3">Primary Account</label>
                            <v-card-title
                                style="fontSize: 14px;"
                            >
                                {{item.name}}
                                <v-spacer></v-spacer>
                                {{item.email}} | {{item.phone}}
                            </v-card-title>
                            <v-card-subtitle>
                                <label
                                    v-if="item.access_id === 1"
                                >
                                    Free Tier
                                </label>
                                <label
                                    v-if="item.access_id === 2"
                                >
                                    Paid Tier
                                </label>
                                <label
                                    v-if="item.access_id === 3"
                                >
                                    Premium Tier
                                </label>
                                <label
                                    v-if="item.access_id === 4"
                                >
                                    Standard Tier
                                </label>
                            </v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col
                                        cols="6"
                                    >
                                        <label>ID: {{item.id}}</label>
                                        <label style="display: block;">Name: {{item.name}}</label>
                                        <label 
                                            style="display: block"
                                            v-if="item.access_id === 1"
                                        >
                                            Tier: Free Tier
                                        </label>
                                        <label 
                                            style="display: block"
                                            v-if="item.access_id === 2"
                                        >
                                            Tier: Paid Tier
                                        </label>
                                        <label 
                                            style="display: block"
                                            v-if="item.access_id === 3"
                                        >
                                            Tier: Premium Tier
                                        </label>
                                        <label style="display: block;">Email: {{item.email}}</label>
                                        <label style="display: block;">Phone: {{item.phone}}</label>
                                    </v-col>
                                    <v-col
                                        cols="6"
                                    >
                                        <label
                                            v-if="item.get_email_frequency !== null"
                                        >
                                            Email Frequency: {{item.get_email_frequency.frequency}}
                                        </label>
                                        <label
                                            v-else
                                        >
                                            Email Frequency: Not Yet Set
                                        </label>
                                        <label 
                                            style="display: block;"
                                            v-if="item.from_sb3d === 0"
                                        >
                                            SB3D Client: No
                                        </label>
                                        <label 
                                            style="display: block;"
                                            v-if="item.from_sb3d === 1"
                                        >
                                            SB3D Client: Yes
                                        </label>
                                        <label 
                                            style="display: block;"
                                            v-if="item.has_supplier_directory === null || item.has_supplier_directory === 0"
                                        >
                                            Has Supplier Directory: No
                                        </label>
                                        <label 
                                            style="display: block;"
                                            v-if="item.has_supplier_directory > 1"
                                        >
                                            Has Supplier Directory: Yes
                                        </label>
                                        <label style="display:block;">
                                            Supplier Click Tracker:
                                            <v-btn small text fab
                                            @click="get_supplier_click_tracker_by_user(item.id)">
                                                <v-icon small>mdi-eye</v-icon>
                                            </v-btn>
                                        </label>
                                        <label style="display: block;">
                                            Design Tracker:
                                            <v-btn small text fab
                                                @click="evnt_show_designtracker_dialog(item)">                                                
                                                <v-icon small>mdi-eye</v-icon>
                                            </v-btn>
                                        </label>
                                        <v-btn 
                                            small
                                            color="red"
                                            text
                                            @click="deleteuser(item)"
                                            class="mt-3 mr-3"
                                            outlined
                                        >
                                            <v-icon class="mr-3" small>mdi-delete-outline</v-icon>delete
                                        </v-btn>
                                        <v-btn 
                                            small
                                            color="primary"
                                            class="pl-10 pr-10 mt-3"
                                            @click="edit_user(item)"
                                        >
                                            <v-icon class="mr-3" small>mdi-pen</v-icon>edit
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-divider></v-divider>
                        <label class="ml-3">Partner Account</label>
                        <v-card
                            v-if="item.get_partner !== null"
                        >
                            <v-card-title
                                style="fontSize: 14px;"
                            >
                                {{item.get_partner.name}}
                                <v-spacer></v-spacer>
                                {{item.get_partner.email}} | {{item.get_partner.phone}}
                            </v-card-title>
                            <v-card-subtitle>
                                <label
                                    v-if="item.get_partner.access_id === 1"
                                >
                                    Free Tier
                                </label>
                                <label
                                    v-if="item.get_partner.access_id === 2"
                                >
                                    Paid Tier
                                </label>
                                <label
                                    v-if="item.get_partner.access_id === 3"
                                >
                                    Premium Tier
                                </label>
                                <label 
                                    style="display: block"
                                    v-if="item.get_partner.access_id === 4"
                                >
                                    Tier: Standard Tier
                                </label>
                            </v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col
                                        cols="6"
                                    >
                                        <label>ID: {{item.get_partner.id}}</label>
                                        <label style="display: block;">Name: {{item.get_partner.name}}</label>
                                        <label 
                                            style="display: block"
                                            v-if="item.get_partner.access_id === 1"
                                        >
                                            Tier: Free Tier
                                        </label>
                                        <label 
                                            style="display: block"
                                            v-if="item.get_partner.access_id === 2"
                                        >
                                            Tier: Paid Tier
                                        </label>
                                        <label 
                                            style="display: block"
                                            v-if="item.get_partner.access_id === 3"
                                        >
                                            Tier: Premium Tier
                                        </label>
                                        <label 
                                            style="display: block"
                                            v-if="item.get_partner.access_id === 4"
                                        >
                                            Tier: Standard Tier
                                        </label>
                                        <label style="display: block;">Email: {{item.get_partner.email}}</label>
                                        <label style="display: block;">Phone: {{item.get_partner.phone}}</label>
                                    </v-col>
                                    <v-col
                                        cols="6"
                                    >
                                        <label
                                            v-if="item.get_partner.get_email_frequency !== null"
                                        >
                                            Email Frequency: {{item.get_partner.get_email_frequency.frequency}}
                                        </label>
                                        <label
                                            v-else
                                        >
                                            Email Frequency: Not Yet Set
                                        </label>
                                        <label 
                                            style="display: block;"
                                            v-if="item.get_partner.from_sb3d === 0"
                                        >
                                            SB3D Client: No
                                        </label>
                                        <label 
                                            style="display: block;"
                                            v-if="item.get_partner.from_sb3d === 1"
                                        >
                                            SB3D Client: Yes
                                        </label>
                                        <label 
                                            style="display: block;"
                                            v-if="item.get_partner.has_supplier_directory === null || item.get_partner.has_supplier_directory === 0"
                                        >
                                            Has Supplier Directory: No
                                        </label>
                                        <label 
                                            style="display: block;"
                                            v-if="item.get_partner.has_supplier_directory === 1"
                                        >
                                            Has Supplier Directory: Yes
                                        </label>
                                        <label style="display:block;">
                                            Supplier Click Tracker: 
                                            <v-btn small text fab
                                                @click="get_supplier_click_tracker_by_user(item.get_partner.id)">
                                                <v-icon small>mdi-eye</v-icon>
                                            </v-btn>
                                        </label>
                                        <v-btn 
                                            small
                                            color="red"
                                            text
                                            @click="deleteuser(item.get_partner)"
                                            class="mt-3 mr-3"
                                            outlined
                                        >
                                            <v-icon class="mr-3" small>mdi-delete-outline</v-icon>delete
                                        </v-btn>
                                        <v-btn 
                                            small
                                            color="primary"
                                            class="pl-10 pr-10 mt-3"
                                            @click="edit_user(item.get_partner)"
                                        >
                                            <v-icon class="mr-3" small>mdi-pen</v-icon>edit
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </td>
                </template>
            </v-data-table>
        </v-card-text>
      </v-card>
        <v-row justify="center">
            <v-dialog
                max-width="420"
                v-model="show_dialog_delete"
                persistent
            >
                <v-card>
                    <v-card-title style="fontSize: 18px;">
                        <small>Deleting User from master list</small>
                    </v-card-title>

                    <v-card-text>
                        <label>Are you sure about this?</label>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="delete_user"
                        small
                        outlined
                    >
                        <v-icon class="mr-3" small>mdi-delete-outline</v-icon>delete
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pl-8 pr-8"
                        dark
                        small
                        @click="cancel"
                    >
                        <v-icon class="mr-3" small>mdi-window-close</v-icon>cancel
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                max-width="680"
                v-model="edit_user_dialog"
                persistent
            >
                <v-card>
                    <v-card-title style="fontSize: 14px;">
                        <small>Edit: {{get_edit_user.name}}</small>
                    </v-card-title>

                    <v-card-text>
                        <v-autocomplete
                            v-model="tier_model"
                            label="Tier"
                            :items="tier"
                            item-value="id"
                            item-text="text"
                            return-object
                            outlined
                            dense
                        >
                        </v-autocomplete>
                        <v-autocomplete
                            v-model="directory_access"
                            label="Supplier Directory Access"
                            :items="supplier_directory"
                            item-value="id"
                            item-text="text"
                            return-object
                            outlined
                            dense
                        >
                        </v-autocomplete>
                        <v-autocomplete
                            v-model="has_bom_model"
                            label="Has BoM"
                            :items="has_bom"
                            item-value="id"
                            item-text="text"
                            return-object
                            outlined
                            dense
                        >
                        </v-autocomplete>
                        <v-switch
                            v-if="get_edit_user.parent_user_id == null"
                            v-model="allow_multiple_projects"
                            class="mt-0 pt-0"
                            label="Allow Multiple Projects"></v-switch>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        small
                        @click="cancel"
                    >
                        <v-icon class="mr-3" small>mdi-window-close</v-icon>cancel
                    </v-btn>
                    <v-btn
                        dark
                        small
                        class="pl-10 pr-10"
                        color="primary"
                        @click="update_user"
                    >
                        <v-icon class="mr-3" small>mdi-pen</v-icon>update
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog
                max-width="680"
                v-model="show_tracked_clicks"
                persistent
            >
                <v-card>
                    <v-card-title>
                        Supplier Profile: Click Tracker
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="click_tracker_headers"
                            :items="get_supplier_click_trackers">
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                        color="primary"
                        text
                        small
                        @click="show_tracked_clicks = false">
                        <v-icon class="mr-3" small>mdi-window-close</v-icon>cancel
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <DesignTrackerView
            :desgintrackerdialog="designtrackerview_dialog"
            :user="user_data"
            @evnt_closedialog="evnt_close_dialog()"></DesignTrackerView>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DesignTrackerView from '../users/components/DesignTrackerView.vue'

export default {
  components: {
    DesignTrackerView
  },
  props: [
  ],
  data: () => ({
    table_header:[
      { text: 'ID', sortable: false },
      { text: 'Name', sortable: false },
      { text: 'Email', sortable: false },
      { text: 'Has Partner / Paid', sortable: false },
      { text: 'Registered At', sortable: false },
      { text: 'Login Count', sortable: true, value: 'login_count' },
      { text: 'Last Login', sortable: true, value: 'updated_at' }
    //   { text: 'Action(s)' }
    ],
    search: null,
    show_dialog_delete: false,
    edit_user_dialog: false,
    tier: [
        {
            id: 1,
            text: 'Free Tier'
        },
        {
            id: 2,
            text: 'Basic / Standard Tier'
        },
        {
            id: 3,
            text: 'Pro Tier'
        },
        {
            id: 4,
            text: 'Standard'
        }
    ],
    supplier_directory: [
        {
            id: 1,
            text:   'No'
        },
        {
            id: 2,
            text:   'Yes'
        }
    ],
    has_bom_model: null,
    has_bom: [
        {
            id:     1,
            text:   'No'
        },
        {
            id:     2,
            text:   'Yes'
        }
    ],
    tier_model: null,
    directory_access: null,
    sortBy: 'login_count',
    sortDesc: true,
    show_tracked_clicks: false,
    click_tracker_headers: [    
        {text: 'Supplier', sortable: true, value: 'get_supplierprofile.company_name'},
        {text: 'Click Counter', sortable: false, value: 'click_counter', align: 'center'},
        {text: 'Last Clicked',  sortable: true, value: 'updated_at'}
    ],
    allow_multiple_projects: false,
    designtrackerview_dialog: false,
    user_data: null
  }),
  mounted () {
    this.fetchusers();
  },
  created () {
  },
  computed: {
    ...mapGetters({
        // get_user_list:                  'admin_users/get_user_list'
        get_to_delete_user:                 'admin_users/get_to_delete_user',
        get_edit_user:                      'admin_users/get_edit_user',
        get_supplier_click_trackers:        'admin_users/get_supplier_click_trackers_getters'
    }),
    get_user_list(){
        return this.$store.getters['admin_users/get_user_list'](this.search)
    }
  },
  methods: {
    ...mapActions({
        fetch_supplier_click_tracker_data:  'admin_users/fetch_supplier_click_tracker_data_actions'
    }),
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async fetchusers(){
        await this.$axios.get('admin/users/f_users')
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('admin_users/set_user_list', data.data)
            }
        })
    },
    deleteuser(data){
        this.$store.dispatch('admin_users/set_to_delete_user', data)
        this.show_dialog_delete = true
    },
    cancel(){
        this.show_dialog_delete = false
        this.$store.dispatch('admin_users/set_to_delete_user', {})
        this.edit_user_dialog = false
        this.$store.dispatch('admin_users/set_edit_user', {})
        this.tier_model = null
        this.directory_access = null
    },
    async delete_user(){
        await this.$axios.delete(`admin/users/delete_user/${this.get_to_delete_user.id}`)
        .then(({data}) => {
            if(data.response){
                this.cancel()
                this.fetchusers()
            }
        })
    },
    edit_user(data){
        this.$store.dispatch('admin_users/set_edit_user', data)
        this.allow_multiple_projects = this.get_edit_user.allow_multiple_projects == 0 ? false : true;        
        this.edit_user_dialog = true;
    },
    async update_user(){
        const tp = {
            tier: this.tier_model,
            directory_access: this.directory_access,
            has_bom:    this.has_bom_model,
            allow_multiple_projects: this.allow_multiple_projects
        }
        await this.$axios.patch(`admin/users/update_user/${this.get_edit_user.id}`, tp)
        .then(({data}) => {
            if(data.response){
                this.cancel()
                this.fetchusers()
                this.showSnackBar(data.message)
            }
        })
    },
    get_supplier_click_tracker_by_user(users_id) {        
        this.fetch_supplier_click_tracker_data(users_id);
        this.show_tracked_clicks = true;
    },
    evnt_show_designtracker_dialog(item) {
        this.user_data = item;
        this.designtrackerview_dialog = true
    },
    evnt_close_dialog() {
        this.designtrackerview_dialog = false
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>