<template>
    <div>
        <v-data-iterator
            :items="show_deleted ? get_trashed_video_archives : get_video_archives"
            :item-per-page="5"
            :search="search_archive_video"
            :sort-by="sortBy"
            :sort-desc="sortDesc"           
            item-key="id"
            >
            <template v-slot:header>
                <v-row>
                    <v-col cols="11">
                        <v-text-field
                            v-model="search_archive_video"                     
                            prepend-inner-icon="mdi-magnify"
                            placeholder="search by video name | description here..."
                            label="search by video name | description here...">
                        </v-text-field>
                    </v-col>
                    <v-col cols="1" class="justify-content-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-switch 
                                        v-model="show_deleted"
                                        x-small                   
                                        class="mx-3"
                                        inset>
                                    </v-switch>
                                </div>
                            </template>
                            <span>Show or hide deleted files.</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:default="{items}">
                <v-row>
                    <v-col
                        v-for="item in items"
                        :key="item.id"
                        cols="auto"
                        md="3"
                        sm="6"                
                        class="mb-5">                       
                        <VideoArchivedPlayer 
                            :video="item"
                            @delete_video="delete_uploaded_video($event)"
                            @edit_uploaded_video="update_uploaded_video($event)"
                            @restore_video="restore_video_archived($event)"></VideoArchivedPlayer>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VideoArchivedPlayer from './VideoArchivedPlayer.vue';

export default {
    data: () => ({
        show_deleted: false,     
        search_archive_video: null,
        sortBy: 'updated_at',
        sortDesc: true
    }),
    components: {
        VideoArchivedPlayer
    },
    computed: {
        ...mapGetters({
            'get_video_archives':                   'admin_video_archived/get_video_archives_getters',
            'get_trashed_video_archives':           'admin_video_archived/get_trashed_video_archives_getters'
        })
    },
    methods: {
        ...mapActions({
            'fetch_video_archived':                 'admin_video_archived/fetch_video_archived_actions',           
            'delete_uploaded_video_archived':       'admin_video_archived/delete_uploaded_video_archived_actions',
            'fetch_trashed_video_archives':         'admin_video_archived/fetch_trashed_video_archives_actions',
            'retore_trashed_video_archive':         'admin_video_archived/retore_trashed_video_archive_actions'
        }),
        delete_uploaded_video(event) {
            this.delete_uploaded_video_archived(event);
        },
        update_uploaded_video(event) {
            this.$emit('edit_uploaded_video', event);
        },
        restore_video_archived(event) {
            this.retore_trashed_video_archive(event);
        }
    },
    mounted() {
        this.fetch_video_archived();
        this.fetch_trashed_video_archives();
    }
}
</script>