<template>
    <v-row justify="center">
        <v-dialog
            v-model="edit_task_dialog"
            persistent
            scrollable
            max-width="690">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-calendar-check-outline</v-icon><small>Edit: {{task_item.name}}</small>
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn
                            text
                            small
                            fab
                            color="red"
                            @click="open_delete_task_dialog()">
                            <v-icon
                                style="cursor: pointer;">
                            mdi-delete
                        </v-icon>
                        </v-btn>
                    </div>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-3 pt-3">
                    <v-text-field
                        v-model="item.name"                        
                        label="Task Name"
                        prepend-icon="mdi-tooltip-text-outline"
                        outlined
                        dense>
                    </v-text-field>
                    <!-- Duration -->
                    <v-text-field
                        v-model="item.duration"
                        label="Duration"
                        prepend-icon="mdi-tooltip-text-outline"
                        type="number"
                        outlined
                        dense
                        suffix="Day(s)">
                    </v-text-field>
                    <!-- ./Duration -->

                    <!-- Status -->
                    <v-combobox
                        v-model="item.status"
                        :items="status_item"
                        prepend-icon="mdi-list-status"
                        label="Status"
                        outlined
                        dense>
                    </v-combobox>
                    <!-- ./Status -->
                    <!-- Who -->
                    <v-text-field
                        v-model="item.who"
                        label="Who"
                        prepend-icon="mdi-tooltip-text-outline"
                        outlined
                        dense>
                    </v-text-field>
                    <!-- ./who -->
                    <!-- Start Date -->
                    <v-menu
                        ref="start_date_menu"
                        v-model="start_date_menu"
                        :close-on-content-click="false"
                        :return-value.sync="item.start"
                        transition="scale-transition"
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="item.start"
                                label="Start Date"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense>
                            </v-text-field>
                        </template>
                        <v-date-picker
                            v-model="startdate"
                            no-title
                            scrollable
                            :max="start_max_date">
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="start_date_menu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.start_date_menu.save(startdate)">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                    <!-- ./Start Date -->
                    <!-- Date Finish -->
                    <v-menu
                        ref="finish_date_menu"
                        v-model="finish_date_menu"
                        :close-on-content-click="false"
                        :return-value.sync="item.finish"
                        transition="scale-transition"
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="item.finish"
                                label="Finish Date"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense>
                            </v-text-field>
                        </template>
                        <v-date-picker
                            v-model="finishdate"
                            no-title
                            scrollable
                            :min="finish_min_date">
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="finish_date_menu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.finish_date_menu.save(finishdate)">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                    <!-- ./Date Finish -->
                    <!-- Notes -->
                    <v-textarea
                        v-model="item.notes"
                        label="Notes"
                        prepend-icon="mdi-tooltip-text-outline"
                        outlined
                        dense>
                    </v-textarea>
                    <!-- ./Notes-->
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="close_dialog">
                    Close
                </v-btn>
                <v-btn
                    color="#093cba"
                    dark
                    @click="update_task"
                    class="pl-16 pr-16">
                    Save
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Confirm Dialog -->
        <v-dialog
            max-width="420"
            v-model="confirm_delete_task"
            persistent>
            <v-card>
                <v-card-title style="fontSize: 18px;">
                    <small>Are you sure you want to delete this entry?</small>
                </v-card-title>

                <v-card-text>
                    <small>All of the data that belongs to this entry will be deleted, are you sure about this?</small>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="delete_section_task()">
                        Yes
                    </v-btn>
                    <v-btn
                        class="px-16 white--text"
                        style="background-color: rgb(11, 78, 243); border-color: rgb(11, 78, 243);"
                        @click="close_delete_task_dialog()">
                        No
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- ./Confirm Dialog -->
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';


export default {
    data: () => ({
        edit_task_dialog: false,
        item: {},
        startdate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        finishdate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        start_date_menu: false,
        finish_date_menu: false,
        start_max_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        finish_min_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        status_item: ['Pending', 'Started', 'On-Hold', 'Waiting-on Info', 'Complete'],
        confirm_delete_task: false
    }),
    props: {
        task_item: Object,
        show_edit_task_dialog: Boolean,
        section_item: Object,
    },
    computed: {

    },
    methods: {
        ...mapActions({
            edit_task:              'project_planner_v2/edit_task_actions',
            delete_task:            'project_planner_v2/delete_task_actions'
        }),
        async update_task() {
            await this.edit_task({
                section: this.section_item,
                task_id: this.task_item.id,
                data: {
                    name: this.task_item.name,
                    duration: this.task_item.duration,
                    status: this.task_item.status,
                    who: this.task_item.who,
                    start: this.task_item.start,
                    finish: this.task_item.finish,
                    notes: this.task_item.notes,
                    project_task_id: this.section_item.id
                }
            });
            this.close_dialog();
        },
        close_dialog() {
            /** Clear all fields if the dialog is close */
            this.$emit('close_edit_task_dialog');
        },
        open_delete_task_dialog() {
            this.confirm_delete_task = true;
        },
        close_delete_task_dialog() {
            this.confirm_delete_task = false;
        },
        async delete_section_task() {
            const task_id = this.item.id;
            await this.delete_task({
                task_id: task_id
            });
            this.close_delete_task_dialog();
            this.close_dialog();
        },
        compute_date_duration() {
            const start_date = new Date(this.item.start);
            const finish_date = new Date(this.item.finish);
            const days = Math.abs(finish_date - start_date) / (24*60*60*1000);
            this.item.duration = days;
        }
    },
    watch: {
        show_edit_task_dialog() {
            this.edit_task_dialog = this.show_edit_task_dialog;
            this.item = this.task_item;
            if ( this.show_edit_task_dialog === true ) {
                if ( typeof this.item.start === 'undefined' ) {
                    this.item.start = new Date();
                    this.item.finish = new Date();
                }
            }
        },
        'item.start'() {
            let start_date = new Date(this.item.start);            
            if ( start_date.toLocaleDateString() == '1/1/1970' || typeof start_date === 'undefined') {
                start_date = new Date();
                this.item.start = start_date;               
            } else {
                this.item.start = start_date.toLocaleDateString('en-US');

                /** Update Minimum Date */       
                this.finish_min_date = new Date(start_date.setDate(start_date.getDate() + 1)).toISOString();
            } 
            this.compute_date_duration();       
        },
        'item.finish'() {
            let finish_date = new Date(this.item.finish);           
            if ( finish_date.toLocaleDateString() == '1/1/1970' || typeof finish_date === 'undefined') {
                finish_date = new Date();         
                this.item.finish = finish_date;                   
            } else {
                this.item.finish = finish_date.toLocaleDateString('en-US');                
                
                /** Update Maximum Date */
                this.start_max_date = new Date(finish_date.setDate(finish_date.getDate() + 1)).toISOString();
            }
            this.compute_date_duration();
        },
        'item.duration'() {
            let start_date = new Date(this.item.start);
            const duration = parseInt(this.item.duration == null || this.item.duration == '' ? 0 : this.item.duration);
            this.item.finish = new Date(start_date.setDate(start_date.getDate() + duration)).toISOString();
        },
        'item.status'() {
            if ( this.item.status == null || this.item.status == '' ) {
                this.item.status = 'Pending';
            }
        }
    }
}
</script>