import store from '@/states/index.js'

import ClientPlan from '../ClientPlan'

export default [
    {
        path: '/client_plan',
        component: ClientPlan,
        name: 'client_plan',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 2){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]