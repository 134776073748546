<template>
    <v-row>
        <v-dialog
            v-model="edit_budgetmanager_item_formdialog"
            persistent
            max-width="650">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-pen</v-icon>
                    Edit Item
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-6">
                    <v-form
                        ref="edit_trans_form"
                        v-model="valid"
                        lazy-validation>
                        <v-text-field
                            v-model="item_description"
                            dense
                            outlined
                            prepend-icon="mdi-tooltip-text-outline"
                            :rules="form_rules.item_description"
                            label="Item Description"
                            hint="Item Description">
                        </v-text-field>
                        <v-text-field
                            v-model="budget"
                            dense
                            outlined
                            prepend-icon="mdi-tooltip-text-outline"
                            :rules="form_rules.budget"
                            label="Budget"
                            hint="Budget"
                            autofocus>
                        </v-text-field>
                        <v-text-field
                            v-model="actual_price"
                            dense
                            outlined
                            prepend-icon="mdi-tooltip-text-outline"                           
                            label="Actual Price"
                            hint="Actual Price">
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" class="px-6"
                        @click="evt_close_dialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>Close
                    </v-btn>
                    <v-btn color="rgb(11, 78, 243)" class="px-6 white--text"
                        @click="evt_save_budgetmanager_item()">
                        <v-icon>mdi-content-save-outline</v-icon>Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data: () => ({
        edit_budgetmanager_item_formdialog: false,
        item_description: null,
        budget: null,
        actual_price: null,
        valid: true,
        form_rules: {
            item_description: [v =>!!v || 'Item description is required.'],
            budget: [v =>!!v || 'Budget is required.'],
            actual_price: [v =>!!v || 'Actual price is required.']
        }
    }),
    props: {
        show_edit_budgetmanager_item_formdialog: Boolean,
        item: Object
    },
    computed: {

    },
    methods: {
        ...mapActions({
            edit_budgetmanager_item:        'budgetmanager/edit_budgetmanager_item_action'
        }),
        evt_close_dialog() {
            this.$emit('close_dialog');
        },
        evt_save_budgetmanager_item() {
            this.valid = this.$refs.edit_trans_form.validate();
            if (this.valid) {
                const variation = this.actual_price - this.budget;
                const percent = variation == 0 ? 0 : (variation / this.budget) * 100;
                const outstanding = this.actual_price - this.item.paid_to_date;                
                this.edit_budgetmanager_item({
                    id: this.item.id,
                    item_description: this.item_description,
                    budget: this.budget,
                    variation: variation,
                    actual_price: this.actual_price,
                    percent: percent,
                    outstanding: outstanding
                });
                this.evt_close_dialog()
            }
        }
    },
    watch: {
        show_edit_budgetmanager_item_formdialog() {
            this.edit_budgetmanager_item_formdialog = this.show_edit_budgetmanager_item_formdialog;
            if ( this.show_edit_budgetmanager_item_formdialog == true ) {                
                this.item_description = this.item.item_description;
                this.budget = this.item.budget;
                this.actual_price = this.item.actual_price;
            }
        }
    }
}
</script>