<template>
    <v-row justify="center">
        <v-dialog
            v-model="show_pp_modal"
            persistent
            max-width="690">
            <v-card>
                <v-card-title class="mb-3">
                    <v-icon class="mr-2"></v-icon><small>Edit / Delete Project</small>
                    <v-spacer></v-spacer>
                    <v-icon
                        v-if="getuser.info.parent_user_id == null"
                        style="cursor: pointer;"
                        @click="delete_project">
                        mdi-delete
                    </v-icon>
                </v-card-title>
                <v-card-text>
                    <v-combobox v-if="is_edit_project_name == false && granting_user_access() == true"
                        v-model="selected_project"
                        :items="get_projects"
                        item-value="id"
                        item-text="name"                         
                        label="Project List"
                        outlined
                        prepend-icon="mdi-tooltip-text-outline"                       
                        class="px-5"
                        dense                       
                        return-object
                        @change="select_project">
                    </v-combobox>
                    <v-text-field                    
                        outlined
                        label="Project Name"
                        prepend-icon="mdi-tooltip-text-outline"                       
                        class="px-5"
                        dense
                        v-model="project_name"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="close_ed_projectmodal">
                        Close
                    </v-btn>
                    <v-btn
                        v-if="getuser.info.parent_user_id == null"
                        color="#093cba"
                        dark                   
                        class="pl-16 pr-16"
                        @click="update_project_name()">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { UserAccess } from '@/modules/helpertraining/modules/UserAccess';

export default {
    data: () => ({   
        show_pp_modal: false,
        selected_project: null,
        project_name: null,
        is_edit_project_name: false,
        access_tobe_granted: ['Premium Tier', 'Standard Tier']
    }),
    props: {
        show_ed_projectmodal: Boolean
    },
    computed: {
        ...mapGetters({
            get_projects:           'auth/get_projects',
            getproject:             'auth/getproject',
            getuser:                'auth/getuser',
        })
    },
    methods: {
        ...mapActions({
            set_selected_project:       'auth/set_selected_project',
            edit_selected_project:      'auth/edit_selected_project_actions',
            set_project_template:       'pp/set_project_template_actions',
            update_project_id:          'auth/update_project_id_actions',
            edit_project:               'project_planner_v2/edit_project_actions',                  
        }),
        showSnackBar(message){
            this.$store.commit("auth/setMessage", 
            {show: true, message: message}, 
            {root: 1})
        },
        close_ed_projectmodal() {
            this.$emit('close_projectmodal', false);
            location.reload();
        },
        async update_project_name(){
            await this.edit_project({
                project_id: this.getproject.id,
                name: this.project_name
            });
            this.close_ed_projectmodal();
        },
        delete_project() {
            if ( this.granting_user_access() == true ) {
                if ( this.selected_project ) {
                    this.$emit('deletethis', this.selected_project);
                    this.selected_project = null;
                } else {
                    this.showSnackBar('Please select a project to be deleted.');
                }
            } else {
                this.$emit('deletethis', this.getproject);
            }
        },
        select_project() {
            this.set_selected_project(this.selected_project);
            //this.fetchproject();            
            if ( this.selected_project.template_id != null ) {
                this.set_project_template(this.selected_project);
            }
        },
        async fetchproject(){
            await this.$axios.get(`projectplanner/getThisTemplate/${this.getproject.template_name}`)
            .then(({data}) => {
                this.$store.dispatch('pp/setproject', data.data)               
            });
        },
        granting_user_access() {
            let userAccess = new UserAccess(this.getuser.info.access_id, this.access_tobe_granted);           
            return userAccess.get_access_grant() && 
                   userAccess.allow_multiple_projects(this.getuser.info, this.get_projects.length);
        }
    },
    watch: {
        show_ed_projectmodal() {            
            this.show_pp_modal = this.show_ed_projectmodal;
            if (this.show_ed_projectmodal == true) {
                this.project_name = this.getproject.name;
                this.selected_project = this.getproject;
            }
        },
        selected_project() {
            this.project_name = this.selected_project ? this.selected_project.name : null;
        }
    }
}
</script>