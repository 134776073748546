<template>
    <v-text-field
        v-model="a.category"
        label="Add Category"
        dense
        outlined
        suffix="press ENTER to save"
        @keypress.enter="add_category"
    >
    </v-text-field>
</template>

<script>
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
        a: {
            category: null
        }
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    add_category(){
        this.$emit('add_category', this.a.category)
        this.a = {}
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>