<template>
    <v-container fluid>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                width="90%"
                class="mt-15"
                elevation="0"
            >
                <v-card-title>
                    <small>Form Request Information</small>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="table_header"
                        :items="get_data"
                        item-key="id"
                        hide-default-header
                    >
                        <template v-slot:top>
                            <v-text-field
                                v-model="search"
                                prepend-inner-icon="mdi-magnify"
                                placeholder="search supplier name here..."
                                label="search supplier name here..."
                            >
                            </v-text-field>
                        </template>
                        <template v-slot:header="{ props: { headers } }">
                            <thead>
                                <tr>
                                    <th v-for="(h, i) in headers" :key="i">
                                        <strong>{{h.text}}</strong>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item="{ item }" >
                            <tr
                                style="cursor: pointer;"
                                @click="get_client_information(item.supplier_id)"
                            >
                                <td>
                                    <strong>{{ item.supplier_info.name }}</strong>
                                </td>
                                <td>
                                    <i>{{ item.supplier_info.email }}</i>
                                </td>
                                <td>
                                    <strong
                                        style="background: teal; color: white;"
                                        class="pa-3"
                                    >{{ item.count }}</strong>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="show_dialog_client"
                width="70%"
                scrollable
            >
            <v-card>
                <v-card-text>
                    <v-row
                        class="ma-5"
                    >
                        <v-col
                            cols="4"
                            v-for="(data, dataindex) in clients"
                            :key="dataindex"
                        >
                            <v-card
                                v-if="data !== null"
                            >
                                <v-card-title>
                                    <small>{{data.name}}</small>
                                </v-card-title>
                                <v-card-subtitle>
                                    <label>Email: <strong><i>{{data.email}}</i></strong></label>
                                    <label
                                        style="display: block;"
                                    >
                                        Mobile: <strong>{{data.phone}}</strong>
                                    </label>
                                </v-card-subtitle>
                                <v-card-text
                                    style="height: 200px;"
                                >
                                    <label>
                                        Build Route: <strong>{{data.build_route}}</strong><br/>
                                        Build Route (Other): <strong>{{data.build_route_other}}</strong>
                                    </label>
                                    <label
                                        style="display: block;"
                                    >
                                        Build Stage: <strong>{{data.build_stage}}</strong><br/>
                                        Build Stage (Other): <strong>{{data.build_stage_other}}</strong>
                                    </label>
                                    <label>
                                        Would you like to join the webinar? <strong>{{data.invite}}</strong>
                                    </label>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        block
                                        color="black"
                                        class="white--text"
                                        small
                                        @click="check_convo(data.id)"
                                    >
                                        See Convo
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            <v-card
                                v-else
                            >
                                <v-card-title>
                                    <small>Deleted User</small>
                                </v-card-title>
                                <v-card-text
                                    style="height: 200px;"
                                >
                                    <label>
                                        Build Route: <strong>{{data.build_route}}</strong><br/>
                                        Build Route (Other): <strong>{{data.build_route_other}}</strong>
                                    </label>
                                    <label
                                        style="display: block;"
                                    >
                                        Build Stage: <strong>{{data.build_stage}}</strong><br/>
                                        Build Stage (Other): <strong>{{data.build_stage_other}}</strong>
                                    </label>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <vue-json-to-csv :json-data="clients"
                    :csv-title="My_CSV"
                >
                    <v-btn
                        text
                        class="pl-10 pr-10"
                    >
                        Download CSV
                    </v-btn>
                </vue-json-to-csv>
                <v-btn
                    color="primary"
                    class="pl-10 pr-10"
                    @click="show_dialog_client = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="convo_dialog"
                width="65%"
                scrollable
            >
                <v-card>
                    <v-card-title>
                        <small>Conversation</small>
                        <v-spacer/>
                        <v-icon
                            @click="convo_dialog = false"
                        >
                            mdi-close
                        </v-icon>
                    </v-card-title>
                    <v-card-text
                        style="height: 250px;"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                v-for="(convo, convoid) in get_convo"
                                :key="convoid"
                            >
                                <div
                                    v-if="convo.from_supplier === 1"
                                >
                                    <strong>From Supplier</strong>
                                    <small
                                        style="display: block;"
                                    >{{convo.created_at}}</small>
                                    <label
                                        style="display: block"
                                    >
                                        {{convo.message}}
                                    </label>
                                </div>
                                <div
                                    v-if="convo.from_client === 1"
                                >
                                    <strong>From Client</strong>
                                    <small
                                        style="display: block;"
                                    >
                                        {{convo.created_at}}
                                    </small>
                                    <label
                                        style="display: block"
                                    >
                                        {{convo.message}}
                                    </label>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapGetters } from 'vuex'
import VueJsonToCsv from 'vue-json-to-csv'
  export default {
    components: {
        VueJsonToCsv
    },
    props: [
    ],
    data: () => ({
        table_header: [
            {
                text:   'Supplier Name'
            },
            {
                text:   'Email'
            },
            {
                text:   'Count'
            }
        ],
        search: null,
        clients: [],
        show_dialog_client: false,
        convo_dialog: false
    }),
    async mounted () {
        this.$store.dispatch('admin_form_request/set_data')
    },
    created () {
    },
    computed: {
        ...mapGetters({
            get_convo:           'admin_form_request/get_convo'
        }),
        get_data(){
            return this.$store.getters['admin_form_request/get_data'](this.search)
        }
    },
    methods: {
        /**
         * 
         * @arguments {*} supplier_id 
         */
        async get_client_information(supplier_id){
            await this.$axios.get(`/admin/from_form/g_client_information/${supplier_id}`)
            .then(({data}) => {
                // console.log(data)
                if(data.response){
                    this.clients = data.data
                    this.show_dialog_client = true
                }
            })
        },
        /**
         * 
         * @param {*} id
         * - id of form_request 
         */
        async check_convo(id){
            await this.$axios.get('/admin/from_form/g_convo', {
                id:     id
            })
            .then(({data}) => {
                this.$store.dispatch('admin_form_request/set_convo', data.data)
                this.convo_dialog = true
            })
        }
    },
    watch: {
    }
  }
</script>
  
<style scoped lang="scss">
</style>