import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        data: [],
        convo: []
    },
    mutations: {
        set_data(state, payload){
            state.data = [...payload]
        },
        set_convo(state, payload){
            state.convo = [...payload]
        }
    },
    getters: {
        get_data : state => (value) => {
            if(value === null || value === ''){
                return state.data
            }
            return state.data.filter(q => {
                return q.supplier_info.name.toLowerCase().includes(value)
                || q.supplier_info.email.toLowerCase().includes(value)
            })
        },
        get_convo : state => state.convo
    },
    actions: {
        async set_data({commit}){
            await Vue.axios.get('/admin/from_form/g_data')
            .then(({data}) => {
                commit('set_data', data.data)
            })
        },
        set_convo({commit}, payload){
            commit('set_convo', payload)
        }
    }
}