<template>
    <div>
        <v-row>
          <v-chip
              class="ma-2"
              color="success"
              outlined
              v-for="(item, index) in data"
              :key="index"
              @click="fetch_category(item)"
          >
              <v-icon
                class="mr-2"
              >
                {{ item.icon }}
              </v-icon>
              <label
                  style="font-weight: 900; font-size: 16px;"
              >{{ item.name }}</label>
          </v-chip>
        </v-row>
        <hr
          class="mt-10 mb-10"
        />
        <v-row>
          <v-chip
              class="ma-2"
              color="success"
              outlined
              v-for="(item, index) in sub_data"
              :key="index"
              @click="fetch_sub_category(item)"
          >
              <v-icon
                class="mr-2"
              >
                {{ item.icon }}
              </v-icon>
              <label
                  style="font-weight: 900; font-size: 16px;"
              >{{ item.name }}</label>
          </v-chip>
        </v-row>
    </div>
</template>

<script>
export default {
  components: {
  },
  props: [
    'data',
    'sub_data'
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    async fetch_category(data){
      await this.$store.dispatch('shop/filter_by_category', data)
    },
    async fetch_sub_category(data){
      await this.$store.dispatch('shop/set_fetch_sub_category', data)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>