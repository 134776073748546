<template>
    <div>
        <v-tabs
            v-model="tab"
            centered
            icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab
                v-for="(tab, index) in tabdata"
                :key="index"
                :href="tab.href">
                {{tab.label}}                
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item
                value="tab-1">
                <FFFExtends
                    :project="project"
                    :user_id="user_id"
                    ></FFFExtends>
            </v-tab-item>
            <v-tab-item
                value="tab-2">
                <AppliancesExtends
                    :project="project"
                    :user_id="user_id"></AppliancesExtends>
            </v-tab-item>
            <v-tab-item
                value="tab-3">
                <FlooringCalculationsExtends
                    :project="project"
                    :user_id="user_id"></FlooringCalculationsExtends>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>

import FFFExtends from './DesignTracker/FFFExtends.vue';
import AppliancesExtends from './DesignTracker/AppliancesExtends.vue';
import FlooringCalculationsExtends from './DesignTracker/FlooringCalculationsExtends.vue';

export default {
    data: () => ({
        tab: null,
        tabdata: [
            {
                label:      'furniture, fixtures & fittings',
                href:       '#tab-1'
            },
            {
                label:      'appliances',                
                href:       '#tab-2'
            },
            {
                label:      'flooring calculations',                
                href:       '#tab-3'
            }
        ]
    }),
    props: {
        project: Object,
        user_id: Number
    },
    components: {
        FFFExtends,
        AppliancesExtends,
        FlooringCalculationsExtends
    }
}
</script>