<template>
    <div>
        <v-data-iterator
            :items="get_video_files.video"
            :search="search"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            item-key="id">
            <template v-slot:header>
                <v-text-field
                    v-model="search"                           
                    prepend-inner-icon="mdi-magnify"
                    placeholder="search by video name | description here..."
                    label="search by video name | description here...">
                </v-text-field>
            </template>
            <template v-slot:default="{items}">
                <v-row class="d-flex jsutify-content-center">
                    <v-col
                        v-for="item in items"
                        :key="item.id"
                        cols="3"
                        md="3"
                        sm="6"
                        sx="6"
                        >
                        <v-card class="text-center pt-2 pb-2">
                            <video v-if="granting_user_access()" controls class="video-content" :poster="get_file_complete_url(item.thumbnail)">
                                <source :src="get_file_complete_url(item.file_url)" :type="item.file_mimes" height="300">
                            </video>
                            <video v-else class="video-content" oncontextmenu="return false;" :poster="get_file_complete_url(item.thumbnail)">
                                <source :src="get_file_complete_url(item.file_url)" :type="item.file_mimes" height="300">
                            </video>
                            <v-card-title class="no-gutters">
                                <h4 class="card-video-title px-2">{{ item.name }}</h4>
                            </v-card-title>
                            <!-- <v-card-actions>
                                Uploaded By: 
                                <v-chip
                                    class="p-2"
                                    color="primary mx-2"
                                    small
                                    label>
                                <v-icon left>mdi-account-circle-outline</v-icon>
                                    {{ item.get_user.name }}
                                </v-chip>
                            </v-card-actions> -->
                            <!--<v-divider></v-divider>-->
                            <!--<v-card-text>
                                <v-row class="card-desc">
                                    <v-col class="text-left">
                                        <h4 class="text-muted">Description: </h4>
                                        <p class="px-2 text-justify black--text">
                                            {{ item.description }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card-text>-->
                            <!--<v-divider></v-divider>-->
                            <!-- <v-card-text v-if="granting_user_access()" class="white--text text-left">
                                <v-chip
                                    x-small                                           
                                    color="white"
                                    label
                                    text-color="black">
                                <v-icon left>
                                    mdi-calendar
                                </v-icon>
                                    <div>Created: {{ item.created_at }} | Updated: {{ item.updated_at }} {{ item.deleted_at ? `| Deleted: ${item.deleted_at}` : '' }}</div>
                                </v-chip>
                            </v-card-text> -->
                            <v-divider></v-divider>
                            <v-card-actions class="ml-2 mr-2 mt-2" style="padding:0;" v-if="!granting_user_access()">
                                <v-alert 
                                    dense
                                    outlined
                                    type="error"
                                    x-small
                                    class="text-center">
                                    <span style="font-size:12px;">Please upgrade your account to access this feature!</span>
                                </v-alert>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { UserAccess } from './../modules/UserAccess.js';

export default {
    data: () => ({
        search: '',
        sortBy: 'updated_at',
        sortDesc: true,
        APP_URL: process.env.VUE_APP_URL,
        access_tobe_granted: ['Premium Tier', 'Standard Tier']       
    }),
    components: {

    },
    computed: {
        ...mapGetters({
            get_video_files         :   'helper_training/get_uploaded_files_getters',
            get_user                :   'auth/getuser'
        })
    },
    methods: {
        ...mapActions({
            fetch_files_by_types    :   'helper_training/fetch_files_by_types'
        }),
        get_file_complete_url(url) {
            return this.APP_URL + url;
        },
        granting_user_access() {           
            let userAccess = new UserAccess(this.get_user.info.access_id, this.access_tobe_granted);           
            return userAccess.get_access_grant();
        }
    },
    mounted() {
        this.fetch_files_by_types('video');
    }
}
</script>

<style scoped lang="scss">
.card-title {
    font-size: 14px;
}

.card-video.title {
    font-size: 14px;
}
.card-desc{
    height: 100px;
    overflow: hidden;
}
.no-gutters {
    padding:0;
    margin: 0;
}
.video-content {
    object-fit: initial;
    width: 100%;
    height: 300px;
}
</style>