<template>
    <div>
        <v-dialog
            v-model="imageview_dialog"
            persistent
            scrollable>
            <v-card class="pb-10">
                <v-card-title>
                    <small>View Image</small>
                </v-card-title>
                <v-card-text>
                    <v-img :src="image_src"></v-img>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="closedialog()">CLOSE</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data: () => ({
        imageview_dialog: false
    }),
    props: {
        image_src: String,
        open_imageview_dialog: Boolean
    },
    methods: {
        closedialog() {
            this.$emit('closedialog');
        }       
    },
    watch: {
        open_imageview_dialog() {
            this.imageview_dialog = this.open_imageview_dialog;
        }
    }
}
</script>