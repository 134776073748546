import Vue from 'vue'

export default class ClientQuotation {
    constructor(store) {
        this.store = store
    }

    getClientRequestQuotationFromBoM(supplier_id) {
        Vue.axios.get(`supplier/get_client_request_quotation/${supplier_id}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {                    
                    this.store.dispatch('client_quotation/setClientRequestedQuotationAction', result.data)
                } else {
                    this._display_alert_notification({
                        open: true, icon: 'mdi-alert-circle',
                        type: 'error', message: result
                    })
                }
            } else {
                this._display_alert_notification({
                    open: true, icon: 'mdi-alert-circle',
                    type: 'error', message: data
                })
            }
        }).catch( (err) => {
            this._display_alert_notification({
                open: true, icon: 'mdi-alert-circle',
                type: 'error', message: err
            })
        })
    }

    createClientRequestQuotation(param) {
        Vue.axios.post(`supplier/create_material_quotation`, param).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    //
                    this.store.dispatch('client_quotation/addClientQuotedMaterialAction', {
                        supplier_material_id: param.supplier_material_id,
                        result: result.data
                    })
                } else {
                    this._display_alert_notification({
                        open: true, icon: 'mdi-alert-circle',
                        type: 'error', message: result
                    })
                }
            } else {
                this._display_alert_notification({
                    open: true, icon: 'mdi-alert-circle',
                    type: 'error', message: data
                })
            }
        }).catch( (err) => {
            this._display_alert_notification({
                open: true, icon: 'mdi-alert-circle',
                type: 'error', message: err
            })
        })
    }

    getClientQuotedMaterials(supplier_id) {
        Vue.axios.get(`supplier/get_quoted_materials/${supplier_id}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {                    
                    this.store.dispatch('client_quotation/setClientQuotedMaterialsAction', result.data)
                } else {
                    this._display_alert_notification({
                        open: true, icon: 'mdi-alert-circle',
                        type: 'error', message: result
                    })
                }
            } else {
                this._display_alert_notification({
                    open: true, icon: 'mdi-alert-circle',
                    type: 'error', message: data
                })
            }
        }).catch( (err) => {
            this._display_alert_notification({
                open: true, icon: 'mdi-alert-circle',
                type: 'error', message: err
            })
        })
    }

    updateClientQuotedMaterials(quoted_material_id, data) {
        Vue.axios.patch(`supplier/update_quoted_material/${quoted_material_id}`, data).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {                    
                    this.store.dispatch('client_quotation/updateClientQuotedMaterialAction', result.data)
                } else {
                    this._display_alert_notification({
                        open: true, icon: 'mdi-alert-circle',
                        type: 'error', message: result
                    })
                }
            } else {
                console.log(data)
            }
        })
    }

    /** Private method for displaying error message */
    _display_alert_notification = (alert_info) => {
        this.store.dispatch('alert_notifications/open_alert_notification_action', alert_info.open)
        this.store.dispatch('alert_notifications/set_notification_icon_action', alert_info.icon)
        this.store.dispatch('alert_notifications/set_alert_notification_type_action', alert_info.type)
        this.store.dispatch('alert_notifications/set_notification_message_action', alert_info.message)
    }
}