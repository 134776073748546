<template>
    <div>
        <v-tabs
            v-model="tab"
            centered
            icons-and-text>
                <v-tabs-slider></v-tabs-slider>
                <v-tab
                    v-for="(tab, tabindex) in tabdata"
                    :key="tabindex"
                    :href="tab.href">
                    {{tab.label}}                    
                </v-tab>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        value="tab-1">
                        <NotCompletedSnag
                            :project="project"
                            :user_id="user_id"></NotCompletedSnag>
                    </v-tab-item>
                    <v-tab-item
                        value="tab-2">
                        <CompletedSnag
                            :project="project"
                            :user_id="user_id"></CompletedSnag>
                    </v-tab-item>
            </v-tabs-items>
        </v-tabs>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import CompletedSnag from './Snags/CompletedSnag.vue';
import NotCompletedSnag from './Snags/NotCompletedSnag.vue';

export default {
    data: () => ({
        tab: null,
        tabdata: [
            {
                label:        'Snags',
                href:         '#tab-1'
            },
            {
                label:        'Completed Snags',
                href:         '#tab-2'
            }
        ],
        headers: [
            { text: '', width: '2%' },
            { text: 'Description', filterable: true, sortable: true, value: 'description' },
            { text: 'Who', filterable: true, sortable: true, value: 'who' },
            { text: 'Deadline', filterable: true, sortable: true, value: 'created_at' },
            { text: 'Image' }
        ]
    }),
    props: {
        project: Object,
        user_id: Number
    },
    components: {
        CompletedSnag,
        NotCompletedSnag
    },
    methods: {
        ...mapActions({
            fetch_snags_data:           'snags/fetch_snags_data_action'
        })
    },
    async mounted() {
        await this.fetch_snags_data({
            project_id: this.project.id,
            user_id: this.user_id
        });
    },
    watch: {
        async project() {            
            await this.fetch_snags_data({
                project_id: this.project.id,
                user_id: this.user_id
            });
        }
    }
}
</script>