import store from '@/states/index.js'

import SupplierLostQuotes from '../SupplierLostQuotes.vue'

export default [
    {
        path: '/supplier_quotes_lost',
        component: SupplierLostQuotes,
        name: 'supplier_quotes_lost',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 2){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]