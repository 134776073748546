<template>
    <div>
        <v-card
            class="v-card-border-top"
            elevation="10">
            <v-card-text class="no-gutters">
                <!--<video v-if="granting_user_access()" controls oncontextmenu="return false;" ref="videos">
                    <source :src="process_video_url(video.url)" :type="video.mime"> 
                </video>-->
                <video oncontextmenu="return false;" controls ref="videos">
                    <source :src="process_video_url(video.url)" :type="video.mime"> 
                </video>
            </v-card-text>              
            <v-card-title class="no-gutters ml-3 mr-3">
                {{ video.name }}
                <v-spacer></v-spacer>             
                <!--<v-btn v-if="granting_user_access()" text x-small fab class="success mb-2" @click="view_video(video)">
                    <v-icon>mdi-eye</v-icon>
                </v-btn>-->
            </v-card-title>
            <v-divider></v-divider>       
            <!--<v-card-subtitle class="no-gutters pl-3 pt-2 pb-2 blue darken-1 white--text">
                <v-row no-gutters>
                    <v-col cols="1" class="pt-1 mr-5">
                        <v-avatar class="white--text" color="primary" size="32">RP</v-avatar>
                    </v-col>
                    <v-col cols="auto" class="no-gutters">
                        <div style="font-weight: bold;">{{ video.get_user.name }}</div>
                        <div style="font-size:12px;">Created: {{ video.created_at }}</div>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text class="no-gutters">
                <v-list three-line class="no-gutters">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="no-gutters">Description:</v-list-item-title>
                            <v-list-item-subtitle class="no-gutters">
                                {{ video.description }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>-->
            <v-divider></v-divider>
            <!--<v-card-actions small v-if="!granting_user_access()">
               <v-alert type="error">
                    <div style="font-size:12px;">Only Premium Tier or Standard Tier can play this video. You can contact us to upgrade your account to Premium or Standard Tier.</div>
                </v-alert>
            </v-card-actions>-->
        </v-card>
        <VideoPlayerFullscreen
            :show_dialog="view_video_dialog"
            :video_archive_item="video_archive_item"
            @close_dialog="close_view_video_dialog()"></VideoPlayerFullscreen>
    </div>
</template>

<script>
import VideoPlayerFullscreen from './VideoPlayerFullscreen.vue'
import { UserAccess } from './../../../helpertraining/modules/UserAccess';

export default {
    data: () => ({
        view_video_dialog: false,
        video_archive_item: {},
        APP_URL: process.env.VUE_APP_URL,
        access_tobe_granted: ['Premium Tier', 'Standard Tier'] 
    }),
    components: {
        VideoPlayerFullscreen
    },
    props: {
        video: Object,
        user_info: Object
    },
    methods: {
        view_video(item) {           
            this.view_video_dialog = true;
            this.video_archive_item = item;
        }, 
        close_view_video_dialog() {           
            this.view_video_dialog = false;
        },
        process_video_url(url) {           
            return this.APP_URL + url;
        },
        granting_user_access() {           
            let userAccess = new UserAccess(this.user_info.info.access_id, this.access_tobe_granted);                      
            return userAccess.get_access_grant();           
        }
    },
    watch: {
        video() {
            this.$refs.videos.load();
        }
    }
}
</script>
<style lang="scss" scoped>
.v-card-border-top {
    border-top:5px solid #062a82;
}
video {
    object-fit: initial;
    width: 100%;
    height: 300px;   
}
.no-gutters {
    margin:0;
    padding:0;
}
</style>