<template>
    <v-row justify="center">
        <v-dialog
            v-model="restore_item_confirm_dialog"
            persistent
            max-width="450">
            <v-card>
                <v-card-title>Are you sure you want to restore this item?</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-3">
                    <small>All of the data that belongs to this item wll be restored, are you sure about this?</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="px-16 white--text"
                        text color="success"
                        @click="event_yes_button()">Yes</v-btn>
                    <v-btn class="px-16 white--text"
                        color="rgb(11, 78, 243)"
                        @click="event_no_button()">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import SiteDiary from '../modules/sitediary';

export default {
    data: () => ({
        restore_item_confirm_dialog: false
    }),
    props: {
        confirm_dialog: Boolean,
        item: Object,              
    },
    computed: {

    },
    methods: {
        event_no_button() {
            this.$emit('close_confirm_dialog')
        },
        event_yes_button() {
            SiteDiary().restore_deleted_sitediary(this.item.id, this.$store)
            this.event_no_button()
        }
    },
    watch: {
        confirm_dialog() {
            this.restore_item_confirm_dialog = this.confirm_dialog
        }
    }
}
</script>