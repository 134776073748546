import store from '@/states/index.js'

import SupplierApprovedQuotes from '../SupplierApprovedQuotes.vue'

export default [
    {
        path: '/supplier_approved_quotes',
        component: SupplierApprovedQuotes,
        name: 'supplier_approved_quotes',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 2){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]