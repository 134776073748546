<template>
    <v-container fluid :style="isMobile() ? '' : 'width: 1380px;'">
        <v-card class="mt-5 flex">
            <v-card-title style="background: #062a82;">
                <v-icon class="mr-2" style="color: white;">mdi-toolbox</v-icon>
                <small style="color:white;">Materials</small>
                <v-spacer></v-spacer>
                <v-btn dark rounded @click="openNewMaterialModal()" class="mx-2"
                    style="background-color:#0b4ef3;color:white;">Add
                    new material</v-btn>
                <v-btn dark rounded @click="openNewMaterialCSVModal()" class="mx-2"
                    style="background-color:#0b4ef3;color:white;">Bulk upload</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table :items="get_supplier_materials" :headers="supplier_material_headers">
                    <template v-slot:item="{ item }">
                        <tr @click="openEditMaterialModal(item)" style="cursor:pointer;">
                            <td>{{ item.name }}</td>
                            <!-- <td>
                                <v-img :src="image_path + '/' + item.image" width="150" height="150" contain
                                    aspect-ratio="1"></v-img>
                            </td> -->
                            <td>{{ item.price }}</td>
                            <td>{{ item.unit }}</td>
                            <td>
                                <v-btn text @click.stop="openDeleteMaterialModal(item)">
                                    <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="new_material_modal" persistent scrollable max-width="690">
            <v-card>
                <v-card-title>Add new material</v-card-title>
                <v-card-text class="py-2">
                    <v-text-field v-model="new_material.name" label="Name *" prepend-icon="mdi-text-box-outline" dense
                        outlined type="text" autofocus></v-text-field>
                    <!-- <v-file-input v-model="new_material.image" label="Image" dense outlined></v-file-input> -->
                    <v-text-field v-model="new_material.price" label="Price *" prepend-icon="mdi-numeric" dense outlined
                        type="number"></v-text-field>
                    <v-text-field v-model="new_material.unit" label="Unit" hint="Enter Unit (pcs, no., packs, etc.)"
                        prepend-icon="mdi-scale" dense outlined type="text"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="closeNewMaterialModal()">Cancel</v-btn>
                    <v-btn color="success" @click="saveNewMaterial()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="new_material_csv_modal" persistent scrollable max-width="690">
            <v-card>
                <v-card-title>Add new material CSV</v-card-title>
                <v-card-text class="py-2">
                    <v-file-input v-model="new_material_csv" label="Material CSV" dense outlined></v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="closeNewMaterialCSVModal()">Cancel</v-btn>
                    <v-btn color="success" @click="saveNewMaterialCSV()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="edit_material_modal" persistent scrollable max-width="690">
            <v-card>
                <v-card-title>Edit material</v-card-title>
                <v-card-text class="py-2">
                    <v-text-field v-model="material_to_edit.name" label="Name *" prepend-icon="mdi-text-box-outline" dense
                        outlined type="text" autofocus></v-text-field>
                    <!-- <v-file-input v-model="material_to_edit.image" label="Image" dense outlined></v-file-input> -->
                    <v-text-field v-model="material_to_edit.price" label="Price *" prepend-icon="mdi-numeric" dense outlined
                        type="number"></v-text-field>
                    <v-text-field v-model="material_to_edit.unit" label="Unit" hint="Enter Unit (pcs, no., packs, etc.)"
                        prepend-icon="mdi-scale" dense outlined type="text"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="closeEditMaterialModal()">Cancel</v-btn>
                    <v-btn color="success" @click="editMaterial()">Edit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="delete_material_modal" persistent scrollable max-width="690">
            <v-card>
                <v-card-title>Are you sure?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="closeDeleteMaterialModal()">Cancel</v-btn>
                    <v-btn color="error" @click="deleteMaterial()">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        new_material_modal: false,
        new_material: {
            name: '',
            // image: null,
            price: 0,
            unit: '',
        },
        new_material_csv_modal: false,
        new_material_csv: null,
        supplier_material_headers: [
            { text: 'Name', value: 'name' },
            { text: 'Price', value: 'price' },
            { text: 'Unit', value: 'unit' },
            { text: '', width: '5%' }
        ],
        edit_material_modal: false,
        material_to_edit: {
            id: null,
            name: '',
            price: 0,
            unit: ''
        },
        delete_material_modal: false,
        material_to_delete: null
        // image_path: `${process.env.VUE_APP_URL}uploads/bom`
    }),
    mounted() {
        this.getMaterials()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_supplier_materials: 'supplier_materials/get_supplier_materials'
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        openNewMaterialModal() {
            this.new_material_modal = true
        },
        closeNewMaterialModal() {
            this.new_material_modal = false
            this.new_material.name = ''
            // this.new_material.image = null
            this.new_material.price = 0
            this.new_material.unit = ''
        },
        openNewMaterialCSVModal() {
            this.new_material_csv_modal = true
        },
        closeNewMaterialCSVModal() {
            this.new_material_csv_modal = false
        },
        openEditMaterialModal(item) {
            this.edit_material_modal = true
            this.material_to_edit.id = item.id
            this.material_to_edit.name = item.name
            this.material_to_edit.price = item.price
            this.material_to_edit.unit = item.unit
        },
        closeEditMaterialModal() {
            this.edit_material_modal = false
            this.material_to_edit.id = null
            this.material_to_edit.name = null
            this.material_to_edit.price = null
            this.material_to_edit.unit = null
        },
        async editMaterial() {
            let payload = {
                name: this.material_to_edit.name,
                price: this.material_to_edit.price,
                unit: this.material_to_edit.unit
            }
            await this.$axios.patch(`supplier/materials/update_material/${this.material_to_edit.id}`, payload)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.getMaterials()
                        this.closeEditMaterialModal()
                    }
                })
        },
        openDeleteMaterialModal(item) {
            this.delete_material_modal = true
            this.material_to_delete = item
        },
        closeDeleteMaterialModal() {
            this.delete_material_modal = false
            this.material_to_delete = null
        },
        async deleteMaterial() {
            await this.$axios.delete(`supplier/materials/delete_material/${this.material_to_delete.id}`)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.getMaterials()
                        this.closeDeleteMaterialModal()
                    }
                })
        },
        async getMaterials() {
            await this.$axios.get('supplier/materials/get_materials')
                .then(({ data }) => {
                    if (data.response) {
                        this.$store.dispatch('supplier_materials/set_supplier_materials', data.data)
                    }
                })
        },
        async saveNewMaterial() {
            const new_material_form = new FormData()
            new_material_form.append('name', this.new_material.name)
            // if (this.new_material.image != null) {
            //     new_material_form.append('image', this.new_material.image)
            // }
            new_material_form.append('unit', this.new_material.unit)
            new_material_form.append('price', this.new_material.price)

            await this.$axios.post('supplier/materials/create_material', new_material_form)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.getMaterials()
                        this.closeNewMaterialModal()
                    }
                })
        },
        async saveNewMaterialCSV() {
            const new_material_form = new FormData()
            new_material_form.append('csv', this.new_material_csv)
            console.log(new_material_form)
            await this.$axios.post('supplier/materials/create_material_from_csv', new_material_form)
                .then(({ data }) => {
                    this.showSnackBar(data.message)
                    if (data.response) {
                        this.new_material_csv = null
                        // this.getMaterials()
                        this.closeNewMaterialCSVModal()
                    }
                })
        },
        isMobile() {
            return this.$vuetify.breakpoint.mobile
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss"></style>