import store from '@/states/index.js'

import UserDashboard from '../UserComponent'

export default [
    {
        path: '/user_list',
        component: UserDashboard,
        name: 'user_list',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 1){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]