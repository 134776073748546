export default {
    namespaced: true,
    state: {
        tips: [],
        suggested_tips: []
    },
    mutations: {
        set_tips(state, payload) {
            state.tips = [...payload]
        },
        set_suggested_tips(state, payload) {
            state.suggested_tips = [...payload]
        },
    },
    getters: {
        get_tips(state) {
            return state.tips
        }
    },
    actions: {
        set_tips({ commit }, payload) {
            commit('set_tips', payload)
        },
        set_suggested_tips({ commit }, payload) {
            commit('set_suggested_tips', payload)
        },
    }
}