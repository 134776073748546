<template>
    <v-row justify="center">
        <v-dialog
            v-model="view_image_dialog"
            transition="dialog-bottom-transition"
            persistent
            max-width="1080"
            scrollable>
            <v-card>
                <v-card-title style="background: #062a82;" class="white--text">
                    <v-icon class="mr-2 white--text">mdi-image-multiple</v-icon>
                    View {{ file_lists.length }} Image(s)
                </v-card-title>                
                <v-card-text class="mt-6">
                    
                    <v-carousel
                        show-arrows-on-hover
                        height="auto">
                        <v-carousel-item
                        v-for="(image, i) in file_lists"
                        :key="i"
                        reverse-transition="fade-transition"
                        transition="fade-transition"                        
                        eager>
                        <img :src="image" height="100%" width="100%" eager @error="show_error_image($event)" />
                    </v-carousel-item>
                    </v-carousel>`
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="px-16" text @click="event_close_viewdialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import DefaultImage from '../../../assets/logo.png';

export default {
    data: () => ({
        view_image_dialog: false,
        file_lists: [],
        imagePath: process.env.VUE_APP_URL
    }),
    props: {
        viewImageDialog: Boolean,
        fileLists: Array
    },
    computed: {

    },
    methods: {
        event_close_viewdialog() {
            this.$emit('event_close_viewdialog')
        },
        show_error_image(event) {
            event.target.src = DefaultImage
        }      
    },
    watch: {
        viewImageDialog() {
            this.view_image_dialog = this.viewImageDialog
            this.file_lists = this.fileLists
        }
    }
}
</script>