<template>
    <v-container fluid class="mt-5 flex">
        <!-- Old Design -->
        <v-card>
            <v-card-title>
                <strong>Assets Uploader</strong>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row no-gutters>
                    <v-col>
                        <v-subheader>Users</v-subheader>
                        <v-divider></v-divider>
                        <v-autocomplete
                            v-model="users"
                            :items="get_users"
                            item-text="name"
                            item-value="id">
                        </v-autocomplete>
                    </v-col>
                    <v-col class="ml-5">
                        <v-subheader>Project</v-subheader>
                        <v-divider></v-divider>
                        <v-list dense v-if="get_users_project">
                            <v-list-item-group>
                                <v-list-item
                                    v-for="(project, index) in get_users_project"
                                    :key="index"
                                    @click="selected_template(project)">
                                    <v-list-item-content v-if="project">                                        
                                        <v-list-item-title v-text="project.name"></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content v-else>
                                        <v-list-item-title class="error white--text px-2 py-2">                                           
                                            <span>The client should create a project first before uploading a file.</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>                   
                    </v-col>
                    <v-col class="ml-5">
                        <v-subheader>Assets</v-subheader>
                        <v-divider></v-divider>
                        <v-list dense v-if="project_id">
                            <v-list-item-group
                                v-model="asset_selected">
                                <v-list-item
                                    v-for="(asset, index) in assets"
                                    :key="index"
                                    @click="show_users_directories()">
                                    <v-list-item-icon><v-icon>mdi-file</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="asset"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                    <v-col class="ml-5">
                        <v-subheader>Directories</v-subheader>
                        <v-divider></v-divider>
                        <DirectoryListing
                            :directories="get_users_project_directories"
                            :users="users"
                            :is_asset="is_asset"
                            :project_id="project_id"></DirectoryListing>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DirectoryListing from './components/DirectoryListing.vue';

export default {
    data: () => ({
        users: null,
        search: null,
        assets: ['File Manager'],
        asset_selected: null,
        template_selected: null,
        project_id: null,
        is_asset: false
    }),
    components: {
        DirectoryListing
    },
    computed: {
        ...mapGetters({
            'get_users':                        'admin_assets_uploader/get_users_getters',
            'get_users_project':                'admin_assets_uploader/get_users_project_getters',
            'get_users_project_directories':    'admin_assets_uploader/get_users_project_directories_getters'
        })
    },
    methods: {
        ...mapActions({
            'fetch_userslist':              'admin_assets_uploader/fetch_userslist_actions',
            'fetch_users_project':          'admin_assets_uploader/fetch_users_project_actions',
            'fetch_users_directories':      'admin_assets_uploader/fetch_users_directories_actions',
            'set_users_project_to_empty':   'admin_assets_uploader/set_users_project_to_empty_actions'
        }),
        selected_template(project) {
            if ( project ) {
                this.project_id = project.id;                
            }
        },
        async show_users_directories() {                 
            await this.fetch_users_directories({project_id: this.project_id});
            this.is_asset = true;
        }
    },
    watch: {
        users() {            
            this.project_id = null;
            this.template_selected = null;
            this.asset_selected = null;
            this.set_users_project_to_empty();
            this.is_asset = false;
            if ( this.users) {
                this.fetch_users_project(this.users);               
            }
        }
    },
    mounted() {
        this.fetch_userslist();
        this.set_users_project_to_empty();
    }
}
</script>