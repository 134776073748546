<template>
    <v-container fluid class="mt-5 flex">
        <v-card>
            <v-card-title style="fontSize: 14px;">
                <strong>Trade Rate Supplier List</strong>
                <v-spacer></v-spacer>
                <v-btn 
                    text class="mr-3" 
                    @click="add_dialog = true"
                >
                    <v-icon class="mr-3">mdi-database-plus-outline</v-icon>add
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table 
                    :headers="table_header" 
                    :items="get_suppliers" 
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    item-key="id" 
                    :search="search"
                >
                    <template v-slot:top>
                        <v-text-field 
                            v-model="search" 
                            prepend-inner-icon="mdi-magnify" 
                            placeholder="search..."
                            label="search...">
                        </v-text-field>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                {{ item.id }}
                            </td>
                            <td>
                                {{ item.supplier_name }}
                            </td>
                            <td>
                                <div class="material_badge" v-for="(item, itemindex) in item.materials" :key="itemindex">
                                    {{ item }}
                                </div>
                            </td>
                            <td>
                                {{ item.website }}
                            </td>
                            <td>
                                {{ item.notes }}
                            </td>
                            <td>
                                <v-icon small class="mr-2" @click="open_delete_dialog(item)">
                                    mdi-delete-outline
                                </v-icon>
                                <v-icon small class="mr-2" @click="open_edit_dialog(item)">
                                    mdi-pen
                                </v-icon>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-row justify="center">
            <v-dialog max-width="680" v-model="add_dialog" persistent>
                <v-card>
                    <v-card-title style="fontSize: 14px;">Add Supplier</v-card-title>
                    <v-card-text>
                        <v-text-field 
                            v-model="new_supplier.name" 
                            outlined dense label="Supplier Name"
                            placeholder="Supplier Name" 
                            prepend-icon="mdi-account-outline"></v-text-field>
                        <v-combobox 
                            v-model="new_supplier.materials" 
                            outlined 
                            multiple 
                            chips 
                            dense 
                            hide-selected
                            :items="get_materials"
                            label="Materials" 
                            placeholder="Materials" 
                            prepend-icon="mdi-account-outline"
                        >
                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                <v-chip v-bind="attrs" :input-value="selected" label small>
                                    <span class="pr-2">
                                        {{ item }}
                                    </span>
                                    <v-icon small @click="parent.selectItem(item)">
                                        $delete
                                    </v-icon>
                                </v-chip>
                            </template>
                            <template v-slot:no-data>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Press <kbd>enter</kbd> to create a new material
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-combobox>
                        <v-text-field v-model="new_supplier.website" outlined dense label="Website"
                            placeholder="Website" prepend-icon="mdi-account-outline"></v-text-field>
                        <v-text-field v-model="new_supplier.notes" outlined dense label="Notes" placeholder="Notes"
                            prepend-icon="mdi-account-outline"></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text small @click="close_dialog">
                            <v-icon class="mr-3" small>mdi-window-close</v-icon>cancel
                        </v-btn>
                        <v-btn dark small class="pl-10 pr-10" color="primary" @click="add_supplier">
                            <v-icon class="mr-3" small>mdi-plus</v-icon>add
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog max-width="680" v-model="edit_dialog" persistent>
                <v-card>
                    <v-card-title style="fontSize: 14px;">Edit Supplier</v-card-title>
                    <v-card-text>
                        <v-text-field 
                            v-model="new_supplier.name" 
                            outlined dense label="Supplier Name"
                            placeholder="Supplier Name" 
                            prepend-icon="mdi-account-outline"></v-text-field>
                        <v-combobox 
                            v-model="new_supplier.materials" 
                            outlined 
                            multiple 
                            chips 
                            dense 
                            hide-selected
                            :items="get_materials"
                            label="Materials" 
                            placeholder="Materials" 
                            prepend-icon="mdi-account-outline"
                        >
                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                <v-chip v-bind="attrs" :input-value="selected" label small>
                                    <span class="pr-2">
                                        {{ item }}
                                    </span>
                                    <v-icon small @click="parent.selectItem(item)">
                                        $delete
                                    </v-icon>
                                </v-chip>
                            </template>
                            <template v-slot:no-data>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Press <kbd>enter</kbd> to create a new material
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-combobox>
                        <v-text-field v-model="new_supplier.website" outlined dense label="Website"
                            placeholder="Website" prepend-icon="mdi-account-outline"></v-text-field>
                        <v-text-field v-model="new_supplier.notes" outlined dense label="Notes" placeholder="Notes"
                            prepend-icon="mdi-account-outline"></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text small @click="close_dialog">
                            <v-icon class="mr-3" small>mdi-window-close</v-icon>cancel
                        </v-btn>
                        <v-btn dark small class="pl-10 pr-10" color="primary" @click="edit_supplier">
                            <v-icon class="mr-3" small>mdi-pen</v-icon>edit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        
        <v-row justify="center">
            <v-dialog max-width="420" v-model="delete_dialog" persistent>
                <v-card>
                    <v-card-title style="fontSize: 18px;">
                        <small>Deleting Supplier</small>
                    </v-card-title>

                    <v-card-text>
                        <label>Are you sure?</label>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="pl-8 pr-8" dark @click="delete_dialog = false">
                            cancel
                        </v-btn>
                        <v-btn text @click="delete_supplier">
                            delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        table_header: [
            { text: 'ID', value: 'id', sortable: false },
            { text: 'Supplier', value: 'supplier_name', sortable: false },
            { text: 'Materials', value: 'materials', sortable: false },
            { text: 'Website', value: 'website', sortable: false },
            { text: 'Notes', value: 'notes', sortable: false },
            { text: 'Action(s)', sortable: false }
        ],
        search: null,
        add_dialog: false,
        edit_dialog: false,
        delete_dialog: false,
        supplier_id: null,
        new_supplier: {
            name: null,
            materials: null,
            website: null,
            notes: null,
        },
        sortBy: 'created_at',
        sortDesc: true,
    }),
    async mounted() {
        this.fetchsuppliers()
    },
    created() {
    },
    computed: {
        get_suppliers() {
            return this.$store.getters['admin_trade_rate_suppliers/get_suppliers']
        },
        get_materials() {
            return this.$store.getters['admin_trade_rate_suppliers/get_materials']
        }
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async fetchsuppliers() {
            await this.$axios.get('admin/trade_rate_suppliers/get_suppliers')
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.$store.dispatch('admin_trade_rate_suppliers/set_suppliers', data.data)
                        let arr = []
                        data.materials.forEach((existing_materials) => {
                            existing_materials.forEach((material) => {
                                arr.push(material)
                            })
                        })
                        this.$store.dispatch('admin_trade_rate_suppliers/set_materials', arr)
                    }
                })
        },
        open_delete_dialog(item) {
            this.supplier_id = item.id
            this.delete_dialog = true
        },
        open_edit_dialog(item) {
            this.supplier_id = item.id
            this.new_supplier.name = item.supplier_name
            this.new_supplier.website = item.website
            this.new_supplier.materials = item.materials
            this.new_supplier.notes = item.notes
            this.edit_dialog = true
        },
        close_dialog() {
            this.add_dialog = false
            this.edit_dialog = false
            this.delete_dialog = false
            this.new_supplier.name = null
            this.new_supplier.website = null
            this.new_supplier.materials = null
            this.new_supplier.notes = null
        },
        async add_supplier() {
            let payload = {
                supplier_name: this.new_supplier.name,
                website: this.new_supplier.website,
                materials: this.new_supplier.materials,
                notes: this.new_supplier.notes
            }
            await this.$axios.post('admin/trade_rate_suppliers/add_supplier', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.showSnackBar('Supplier added')
                        this.close_dialog()
                        this.fetchsuppliers()
                    }
                    else {
                        this.showSnackBar(data.message)
                    }

                })
        },
        async edit_supplier() {
            let payload = {
                supplier_name: this.new_supplier.name,
                website: this.new_supplier.website,
                materials: this.new_supplier.materials,
                notes: this.new_supplier.notes
            }
            await this.$axios.patch(`admin/trade_rate_suppliers/edit_supplier/${this.supplier_id}`, payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.showSnackBar('Supplier edited')
                        this.close_dialog()
                        this.fetchsuppliers()
                    }
                    else {
                        this.showSnackBar(data.message)
                    }
                })
        },
        async delete_supplier() {
            await this.$axios.delete(`admin/trade_rate_suppliers/delete_supplier/${this.supplier_id}`)
                .then(() => {
                    this.showSnackBar('Supplier deleted')
                    this.close_dialog()
                    this.fetchsuppliers()
                })
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
.material_badge {
    border-radius: 10px;
    background-color: #1976d2 !important;
    color: #FFFFFF;
    display: inline-block;
    font-size: 12px;
    height: 20px;
    letter-spacing: 0;
    line-height: 1;
    min-width: 20px;
    padding: 4px 6px;
    margin: 0px 1px;
}
</style>