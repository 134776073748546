import store from '@/states/index.js'

import ShopComponent from '../ShopComponent.vue'
import ProductComponent from '../ProductPageComponent.vue'

export default [
    {
        path: '/shop',
        component: ShopComponent,
        name: 'shop',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 3){
                next()
            }else{
                next('/')
            }
            return
        }
    },
    {
        path: '/product_page',
        component: ProductComponent,
        name: 'product_page',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 3){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]