<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="get_snags_data.filter(snag => snag.deleted_at == null)"
            class="elevation-1"
            mobile-breakpoint="0">
            <template v-slot:item="props">
                <tr style="cursor:pointer;">             
                    <td>
                        <small v-if="props.item.deleted_at == null">{{ props.item.description }}</small>                   
                    </td>
                    <td>
                        <div
                            v-if="props.item.deleted_at == null">
                            <small
                                v-for="(item, index) in props.item.who"
                                :key="index">
                                {{item}}<br/>
                            </small>
                        </div>
                        <div v-else>
                            <small
                                v-for="(item, itemindex) in props.item.who"
                                :key="itemindex">
                                {{item}}<br/>
                            </small>
                        </div>
                    </td>
                    <td>
                        <small>{{ props.item.created_at }}</small>
                    </td>
                    <td>
                        <img :src="`${img_url}frontend/images/${props.item.image}`" style="max-width: 250px; max-height: 250px;"/>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        headers: [            
            { text: 'Description', sortable: true, value: 'description' },
            { text: 'Who', sortable: true, value: 'who' },
            { text: 'Deadline', sortable: true, value: 'created_at' },
            { text: 'Image' }
        ]
    }),
    props: {
        project: Object,
        user_id: Number
    },
    computed: {
        ...mapGetters({
            get_snags_data:             'snags/get_snags_data_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_snags_data:           'snags/fetch_snags_data_action'
        })
    }
}
</script>