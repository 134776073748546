export default {
    namespaced: true,
    state: {
        quoted_data: []
    },
    mutations: {
        set_quoted_data(state, payload){
            state.quoted_data = [...payload]
        }
    },
    getters: {
        get_quoted_data(state){
            return state.quoted_data
        }
    },
    actions: {
        set_quoted_data({commit}, payload){
            commit('set_quoted_data', payload)
        }
    }
}