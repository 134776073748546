<template>
    <div>
        <v-list>
            <v-list-group
                v-for="item in get_decisions_viewdata"
                :key="item.id"
                v-model="item.active"
                no-action>
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title style="color: #0b4ef3;">
                            <v-badge
                                color="#0b4ef3"
                                bordered>
                                <v-icon slot="badge" v-if="item.disabled">mdi-exclamation-thick</v-icon>
                                <v-icon slot="badge" v-else>mdi-check-bold</v-icon>
                                <span slot="default"><strong>{{item.item}}</strong><br/><small>{{task_count_remaining(item)}} task(s) remaining</small></span>
                            </v-badge>
                            <v-progress-linear
                                :value="compute_progress(item)"
                                striped
                                height="15"
                                color="#7b9ff9">
                                <template v-slot:default="{ value }">
                                    <strong>{{ value.toFixed(2) }}%</strong>
                                </template>
                            </v-progress-linear>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-data-table
                    :headers="headers"
                    :items="item.child"
                    item-key="task_name"
                    class="elevation-1 custom-table"
                    style="font-weight:700;"
                    :hide-default-footer="true"
                    disable-pagination
                    mobile-breakpoint="0">
                    <template v-slot:item="{item}">
                        <tr>
                            <td><v-checkbox v-model="item['is_done']" disabled></v-checkbox></td>
                            <td style="cursor: pointer; white-space: pre-line;"><small v-if="!item['disabled']">{{item['item']}}</small></td>
                            <td style="cursor: pointer;" v-if="!item['disabled']">
                                <small v-if="item['notes'] == null || item['notes'].length === 0">
                                    No Notes
                                </small>
                                <div v-else>
                                    <small
                                        v-for="(notes, notesindex) in item['notes']" 
                                        :key="notesindex">
                                        {{notes}}<br/>
                                    </small>
                                </div>
                            </td>                           
                        </tr>
                    </template>
                </v-data-table>
            </v-list-group>
        </v-list>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        headers: [
            { text: '', width: '1%' },
            { text: 'Item' },
            { text: 'Notes' }            
        ]
    }),
    props: {
        project: Object,
        user_id: Number
    },
    computed: {
        ...mapGetters({
            get_decisions_viewdata:         'decisions/get_decisions_viewdata_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_decisions_data:           'decisions/fetch_decisions_data_action'
        }),
        compute_progress(item) {
            const total_items = item.child.length;
            const total_completed_items = item.child.filter(i => i.is_done == true).length;

            return (total_completed_items/total_items) * 100;
        },
        task_count_remaining(item) {
            return item.child.filter(task => task.is_done == false).length;
        }
    },
    async mounted() {
        await this.fetch_decisions_data({
            project_id: this.project.id,
            user_id: this.user_id
        });
    },
    watch: {
        async project() {
            await this.fetch_decisions_data({
                project_id: this.project.id,
                user_id: this.user_id
            });
        }
    }
}
</script>