<template>
    <v-row justify="center">
        <v-dialog
            v-model="edit_sitediary_entry_dialog"
            persistent
            max-width="690"
            scrollable>
            <v-card>
                <v-card-title style="background: #062a82;" class="white--text">
                    Edit Site Diary
                    <v-spacer></v-spacer>
                    <v-btn class="error" @click="event_delete_sitediary_data()">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="mt-6">
                    <v-form
                        ref="sitediary_entry_form"
                        v-model="valid"
                        lazy-validation>                        
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="entryData.site_diary_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="entryData.site_diary_date"
                                    label="site date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    dense
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="entryData.site_diary_date"
                                no-title
                                scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(entryData.site_diary_date)">
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>                                           
                        <v-text-field
                            label="Log Name"
                            outlined
                            prepend-icon="mdi-tooltip-text-outline"
                            hint="Log Name"
                            dense                                
                            required
                            v-model="entryData.log_name"
                            type="text"
                        ></v-text-field>              
                        <v-combobox
                            v-model="entryData.tags"
                            :items="gettags"
                            :search-input.sync="search"
                            hide-selected
                            label="Add Some Tags"
                            item-text="tags"
                            multiple
                            persistent-hint
                            small-chips
                            outlined
                            dense                              
                            prepend-icon="mdi-tag-multiple">
                            <template v-slot:no-data>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-combobox>
                        <!-- Primary Image -->                                                
                        <v-layout class="justify-center align-center ml-8">
                            <v-img :src="`${path}frontend/images/${entryData.image}`" width="150" height="150" class="text-right pa-2">
                                <v-btn icon dark color="error" @click="event_remove_primary_image()">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-img>
                        </v-layout>
                        <v-file-input
                            v-model="primary_image"
                            small-chips
                            dense
                            outlined
                            class="mt-4"
                            label="Add Primary Image"></v-file-input>
                        <!-- Alternative Images - Multiple Images -->
                        <v-sheet
                            class="mx-auto my-5"
                            max-width="650"
                            max-height="250"
                            v-if="entryData.alternative_images.length > 0">
                            <v-slide-group
                                v-model="alternative_images"
                                show-arrows>
                                <v-slide-item
                                    v-for="(image, index) in entryData.alternative_images"
                                    :key="index">
                                    <div>
                                        <v-img :src="`${path}/${image}`" width="150" height="150" class="mx-2 my 2 text-right pa-2"></v-img>
                                    </div>
                                </v-slide-item>
                            </v-slide-group>
                        </v-sheet>
                        <v-file-input
                            v-model="alt_images"
                            color="primary accent-4"
                            counter
                            label="Add Alternative Images"
                            multiple
                            placeholder="Select your Images"
                            prepend-icon="mdi-file-multiple"
                            :show-size="1000"
                            outlined
                            dense
                            type="file">
                            <template v-slot:selection="{ index, text }">
                                <v-chip
                                    v-if="index < 2"
                                    color="primary accent-4"
                                    dark
                                    label
                                    small>
                                    {{ text }}
                                </v-chip>

                                <span
                                    v-else-if="index === 2"
                                    class="text-overline grey--text text--darken-3 mx-2">
                                    +{{ alt_images.length - 2 }} File(s)
                                </span>
                            </template>
                        </v-file-input>  
                        <v-textarea
                            outlined
                            dense
                            v-model="entryData.notes"
                            prepend-icon="mdi-tooltip-text-outline"
                            label="Notes">
                        </v-textarea>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text class="px-16" @click="event_close_update_dialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Cancel
                    </v-btn>
                    <v-btn color="primary" class="px-16" @click="event_update_sitediary()">
                        <v-icon class="mr-2">mdi-content-save-outline</v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <ConfirmDialog
            :confirm_dialog="confirm_delete_dialog"
            :item="sitediary_item"            
            @close_no_confirm_dialog="event_delete_no_confirm_dialog()"
            @close_yes_confirm_dialog="event_delete_yes_confirm_dialog()"></ConfirmDialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import DefaultImage from '../../../assets/logo.png';
import SiteDiary from '../modules/sitediary';
import ConfirmDialog from './ConfirmDialog.vue';

export default {
    data: () => ({
        edit_sitediary_entry_dialog: false,
        entryData: {
            site_diary_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            log_name: '',
            tags: [],
            primary_image: null,
            alternative_images: [],
            notes: '',
            project_id: '',
        },
        alt_images: [],
        primary_image: null,
        search: null,
        menu: null,
        valid: true,
        alternative_images: null,
        image_default: DefaultImage,
        path: process.env.VUE_APP_URL,
        confirm_delete_dialog: false,
    }),
    props: {
        edit_sitediary_dialog: Boolean,
        sitediary_item: Object
    },
    components: {
        ConfirmDialog
    },
    computed: {
        ...mapGetters({
            gettags:                'sitediary/gettags',
            get_upload_setting:     'sitediaryv2/get_upload_setting_getter'
        })
    },
    methods: {
        event_update_sitediary() {
            // Update basic information of Site Diary but not the alternative images            
            SiteDiary().updateSiteDiaryData(this.sitediary_item.id, {
                image: this.primary_image,
                log_name: this.entryData.log_name,
                notes: this.entryData.notes,
                site_diary_date: this.entryData.site_diary_date,                
                alt_images: this.alt_images,
                tags: this.entryData.tags,
                previous_images: this.entryData.alternative_images
            }, this.$store)
            this.event_close_update_dialog()
        },
        event_close_update_dialog() {
            this.$emit('close_update_dialog')
        },
        event_delete_alt_image(image) {
            const filtered_images = this.entryData.alternative_images.filter( (item) => item != image )
            this.entryData.alternative_images = filtered_images            
        },
        event_delete_sitediary_data() {
            this.confirm_delete_dialog = true
        },
        event_delete_no_confirm_dialog() {
            this.confirm_delete_dialog = false
        },
        event_delete_yes_confirm_dialog() {
            this.confirm_delete_dialog = false
            this.event_close_update_dialog()
        }
    },
    watch: {
        edit_sitediary_dialog() {
            if ( typeof this.$refs.sitediary_entry_form != 'undefined' ) {
                this.$refs.sitediary_entry_form.reset(); 
            }
            this.edit_sitediary_entry_dialog = this.edit_sitediary_dialog
            this.entryData = Object.assign({}, this.sitediary_item)
            if ( this.sitediary_item.alternative_images != null ) {
                this.entryData.alternative_images = JSON.parse(this.sitediary_item.alternative_images)
            } else {
                this.entryData.alternative_images = []
            }
        }
    }
}

</script>