<template>
    <v-card>
        <v-toolbar
            text
            elevation="0"
            style="background: #062a82;"
        >
            <v-toolbar-title style="color: white;">
                <v-icon class="mr-2" style="color: white;">
                    {{ setup.icon }}
                </v-icon>
                {{ setup.name }}
            </v-toolbar-title>
        </v-toolbar>
        <v-data-table
            :headers="setup.table_header"
            :items="items"
            :search="search"
            class="elevation-1"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
        >
            <template v-slot:[`header.website`]="{ header }">
                <div v-if="user_info.info.access_id === 3">
                    {{ header.text }}
                </div>
                <div v-else>
                    Upgrade to a Pro account to view websites
                </div>
            </template>
            <template v-slot:[`header.notes`]="{ header }">
                <div v-if="user_info.info.access_id === 3">
                    {{ header.text }}
                </div>
                <div v-else>
                    Upgrade to a Pro account to view supplier notes
                </div>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <label
                            v-if="user_info.info.access_id === 3"
                        >
                            {{ item.supplier_name }}
                        </label>
                        <label
                            v-else
                        >
                            Upgrade to a Pro account to view supplier names
                        </label>
                    </td>
                    <td>
                        <div class="material_badge" v-for="(item, itemindex) in item.materials" :key="itemindex">
                            {{ item }}
                        </div>
                    </td>
                    <td>
                        <div v-if="user_info.info.access_id === 3">
                            <a target="_blank" :href="item.website">{{ item.website }}</a>
                        </div>
                    </td>
                    <td>
                        <div v-if="user_info.info.access_id === 3">
                            {{ item.notes }}
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-card>
</template>


<script>
export default {
    components: {
    },
    props: [
        'setup',
        'items',
        'user_info'
    ],
    data: () => ({
        search: '',
        sortBy: 'created_at',
        sortDesc: true,
    }),
    methods: {
    }
}
</script>

<style scoped lang="scss">
.material_badge {
    border-radius: 10px;
    background-color:#1976d2 !important;
    color: #FFFFFF;
    display: inline-block;
    font-size: 12px;
    height: 20px;
    letter-spacing: 0;
    line-height: 1;
    min-width: 20px;
    padding: 4px 6px;
    margin: 0px 1px;
}
</style>