<template>
    <div>
        <v-autocomplete
            v-model="selected_project_template"
            :items="get_project_template"
            label="Select Project Template"
            item-value="id"
            item-text="template_name"
            dense
            outlined
            class="pt-3"              
            return-object>
        </v-autocomplete>
        <v-card v-if="selected_project_template != null">
            <v-card-title>
                <small>{{selected_project_template.template_name}}</small>
                <v-spacer></v-spacer>
                <v-btn-toggle>
                    <v-btn rounded
                        @click="cancel_add_project()">
                        Cancel
                    </v-btn>
                    <v-btn
                        class="white--text px-16"
                        style="background-color: rgb(9, 60, 186); border-color: rgb(9, 60, 186);"
                        @click="show_inherit_modules_dialog()"                       
                        rounded>
                        Grab Template
                    </v-btn>
                </v-btn-toggle>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-list>
                    <v-list-group
                        v-for="item in selected_project_template.get_parents"
                        :key="item.title"
                        v-model="item.active"
                        no-action
                        :disabled="item.disable">
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title style="color: #0b4ef3;">
                                    <v-badge
                                        color="#0b4ef3"
                                        bordered>
                                        <v-icon slot="badge" v-if="item.disable">mdi-exclamation-thick</v-icon>
                                        <v-icon slot="badge" v-else>mdi-check-bold</v-icon>
                                        <span slot="default">{{item.task_name}}</span>
                                    </v-badge>
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>                       
                        <v-data-table
                            :headers="header"
                            :items="item.getchild"
                            item-key="task_name"
                            class="elevation-1 custom-table"
                            style="font-weight:700;"
                            :hide-default-footer="true"
                            disable-pagination
                            mobile-breakpoint="0">
                            <template v-slot:item="{item}">
                                <tr>
                                    <td style="cursor: pointer; white-space: pre-line;"><label>{{item['task_name']}}</label></td>
                                    <td style="cursor: pointer;">
                                        <label class="unselectable" v-if="item['duration'] === '' || item['duration'] === null"> 
                                            Need duration
                                        </label>
                                        <label class="unselectable" v-else>
                                            {{item['duration']}} Days
                                        </label>
                                    </td>
                                    <td style="cursor: pointer;">
                                        <label class="unselectable" v-if="item['status'] === 'Pending'" style="color: red !important;">
                                            <i>{{item['status']}}</i>
                                        </label>
                                        <label class="unselectable" v-if="item['status'] === 'Started'" style="color: green !important;">
                                            <i>{{item['status']}}</i>
                                        </label>
                                        <label class="unselectable" v-if="item['status'] === 'On-Hold'" style="color: red !important;">
                                            <i>{{item['status']}}</i>
                                        </label>
                                        <label class="unselectable" v-if="item['status'] === 'Waiting-on Info'" style="color: orange !important;">
                                            <i>{{item['status']}}</i>
                                        </label>
                                        <label class="unselectable" v-if="item['status'] === 'Complete'" style="color: green !important;">
                                            <i>{{item['status']}}</i>
                                        </label>
                                    </td>
                                    <td class="unselectable" style="cursor: pointer;">{{item['start']}}</td>
                                    <td class="unselectable" style="cursor: pointer;">{{item['finish']}}</td>
                                    <td class="unselectable" style="cursor: pointer;">{{item['notes']}}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-list-group>
                </v-list>
            </v-card-text>
        </v-card>

        <!-- Add Project Modal -->
        <v-row justify="center">
            <v-dialog
                v-model="add_project_dialog"
                width="500">
                <v-card>
                    <v-card-title>
                        New Project
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="mt-3">
                        <v-text-field
                            v-model="project_name"
                            prepend-icon="mdi-text-box-outline"
                            label="New Project Name"
                            dense
                            outlined
                            ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="success" 
                            text
                            @click="cancel_add_new_project_dialog()">Close</v-btn>
                        <v-btn
                            style="background-color: rgb(9, 60, 186); border-color: rgb(9, 60, 186);"
                            class="px-16 white--text"
                            @click="save_new_project()">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- ./Add Project Modal -->

        <!-- Inherit Modules from Delete Project -->
        <InheritModules
            :inheritModules="show_inherit_modules"
            :user_id="getuser.id"
            @close_dialog="close_inherit_modules_dialog()"
            @skip_inherit_modules="execute_skip_inherit_modules()">
        </InheritModules>
        <!-- ./Inherit Modules from Delete Project -->
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import InheritModules from './inheritmodules/InheritModules.vue';

export default {
    data: () => ({
        show_inherit_modules: false,
        selected_project_template: null,
        header: [
            { text: 'Task Name', filterable: true, value: 'name', class: 'headerColorToBlue'},
            { text: 'Duration', filterable: true, value: 'duration'},
            { text: 'Status', filterable: true, value: 'status'},
            { text: 'Start', filterable: true, value: 'start'},
            { text: 'Finish', filterable: true, value: 'finish'},
            { text: 'Notes', filterable: true, value: 'notes'},
        ],
        add_project_dialog: false,
        project_name: null
    }),
    components: {
        InheritModules
    },
    props: {
        
    },
    computed: {
        ...mapGetters({
            get_project_template:       'project_planner_v2/get_project_template_getter',
            getuser:                    'auth/getuser',
        })
    },
    methods: {
        ...mapActions({
            add_project:                'project_planner_v2/add_project_actions',
            set_inherited_projects:     'project_planner_v2/set_inherited_projects_action'
        }),
        grab_project_template() {
            this.add_project_dialog = true;
        },
        add_new_project_dialog() {
            this.add_project_dialog = true;
        },
        cancel_add_new_project_dialog() {
            this.add_project_dialog = false;
        },
        cancel_add_project() {
            this.add_project_dialog = false;
            this.project_name = null;
            this.selected_project_template = null;
            this.set_inherited_projects({});
            this.$emit('cancel_add_project');
        },
        async save_new_project() {
            this.showSnackBar('If you have selected inherit from module, then, please wait for one to two minutes to finish the process of inheriting modules to new project.')
            await this.add_project({'name': this.project_name, 'template_id': this.selected_project_template.id});
            this.cancel_add_project();
        },
        /** Inherit Modules from Deleted Project */
        show_inherit_modules_dialog() {
            this.show_inherit_modules = true;
        },
        close_inherit_modules_dialog() {
            this.show_inherit_modules = false;
        },
        execute_skip_inherit_modules() {
            this.show_inherit_modules = false;
            this.grab_project_template();
        },
        showSnackBar(message){
            this.$store.commit("auth/setMessage", 
            {show: true, message: message}, 
            {root: 1})
        }
    }
}
</script>