<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-card
                class="mx-auto"
                outlined
                style="width: 500px;"
            >
                <v-toolbar dark color="#073095">
                <v-toolbar-title>Registration Form</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-tabs
                        v-model="tab"
                        centered
                        icons-and-text
                    >
                    <v-tabs-slider></v-tabs-slider>
                        <v-tab
                            v-for="(tab, tabindex) in tabdata"
                            :key="tabindex"
                            :href="tab.href"
                        >
                            {{tab.label}}
                        </v-tab>
                        <v-tabs-items v-model="tab">
                            <v-tab-item
                                value="tab-1"
                            >
                                <v-text-field
                                    prepend-icon="email"
                                    v-model="register.email"
                                    name="email"
                                    label="email"
                                    type="email"
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="lock"
                                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show2 ? 'text' : 'password'"
                                    name="password"
                                    label="password"
                                    v-model="register.password"
                                    class="input-group--focused"
                                    @click:append="show2 = !show2"
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="person"
                                    v-model="register.name"
                                    name="full name"
                                    label="full name"
                                    type="text"
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="phone"
                                    v-model="register.phone"
                                    name="phone"
                                    label="phone"
                                    type="number"
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="mdi-map-marker"
                                    v-model="register.address"
                                    name="address"
                                    label="address"
                                    type="text"
                                ></v-text-field>
                                <v-autocomplete
                                    :items="get_counties"
                                    label="county"
                                    item-value="id"
                                    item-text="name"
                                    v-model="register.county"
                                    return-object
                                    prepend-icon="mdi-map-marker"
                                >
                                </v-autocomplete>
                                <v-text-field
                                    prepend-icon="mdi-map-marker"
                                    v-model="register.town"
                                    name="town"
                                    label="town"
                                    type="text"
                                ></v-text-field>
                                <v-text-field
                                    prepend-icon="mdi-map-marker"
                                    v-model="register.zipcode"
                                    name="eircode | zipcode"
                                    label="eircode | zipcode"
                                    type="text"
                                ></v-text-field>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="#062a82" text @click="$router.push({name: 'login'})">Back</v-btn>
                                    <v-btn color="#093cba" class="white--text pl-10 pr-10" @click="registerBtn">Register</v-btn>
                                </v-card-actions>
                            </v-tab-item>
                            <v-tab-item
                                value="tab-2"
                            >
                                <v-text-field
                                    v-for="(item, itemindex) in supplierinfo"
                                    :key="itemindex"
                                    :label="item.label"
                                    :type="item.type"
                                    :prepend-icon="item.icon"
                                    v-model="supplier_registration_model[item.id]"
                                >
                                </v-text-field>
                                <v-autocomplete
                                    :items="get_service_types"
                                    label="service type"
                                    item-value="id"
                                    item-text="service_type"
                                    v-model="supplier_register.service_type"
                                    return-object
                                    prepend-icon="mdi-map-marker"
                                >
                                </v-autocomplete>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="#062a82" text @click="$router.push({name: 'login'})">Back</v-btn>
                                    <v-btn color="#093cba" class="white--text pl-10 pr-10" @click="register_btn_supplier">Register</v-btn>
                                </v-card-actions>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tabs>
                    
                </v-card-text>
                
            </v-card>
        </v-layout>
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            persistent
            v-model="OTPdialog"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >An OTP code has been sent to your email: <br/>{{register.email}}</v-toolbar>
                <v-card-text>
                    <v-text-field
                        standard
                        v-model="otpInput"
                        label="OTP Code"
                    >
                    </v-text-field>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    text
                    @click="verifyOtp"
                >Verify</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    data: () => ({
        tab: null,
        register: {
            name: '',
            username: '',
            email: '',
            phone: '',
            password: '',
            address: '',
            county: null,
            town: '',
            zipcode: ''
        },
        show2: false,
        OTPdialog: false,
        otpInput: '',
        userId: '',
        tabdata: [
            {
                label:        'I am a user',
                href:         '#tab-1'
            },
            {
                label:        'I am a supplier',
                href:         '#tab-2'
            }
        ],
        supplierinfo: [
            {
                id:         1,
                label:      'fullname',
                hint:       'enter your fullname',
                icon:       'person',
                type:       'text'
            },
            {
                id:         2,
                label:      'username',
                hint:       'enter your username',
                icon:       'person',
                type:       'text'
            },
            {
                id:         3,
                label:      'email',
                hint:       'your email address',
                icon:       'email',
                type:       'email'
            },
            {
                id:         4,
                label:      'phone',
                hint:       'mobile | landline number',
                icon:       'phone',
                type:       'number'
            },
            {
                id:         5,
                label:      'password',
                hint:       'your desired password',
                icon:       'lock',
                type:       'password'
            },
            {
                id:         6,
                label:      'retype password',
                hint:       'retype password',
                icon:       'lock',
                type:       'password'
            },
            {
                id:         7,
                label:      'company name',
                hint:       'company name',
                icon:       'mdi-domain',
                type:       'text'
            },
            {
                id:         8,
                label:      'website',
                hint:       'your website url',
                icon:       'mdi-link-variant',
                type:       'text'
            }
        ],
        supplier_registration_model: [],
        supplier_register: {
            service_type: null
        }
    }),
    async mounted () {
        await this.$axios.get('counties')
        .then(({data}) => {
            this.$store.dispatch('auth/set_counties', data.data)
        })
        await this.$axios.get('get_service_types')
        .then(({data}) => {
            this.$store.dispatch('auth/set_service_types', data.data)
        })
    },
    created () {
        if(localStorage.getItem("token") !== null){
            this.$router.push({name: 'profile'})
        }
    },
    computed: {
        ...mapGetters({
            get_counties:               'auth/get_counties',
            get_service_types:          'auth/get_service_types'
        })
    },
    methods: {
        showSnackBar(message){
            this.$store.commit("auth/setMessage", 
            {show: true, message: message}, 
            {root: 1})
        },
        login(){
            this.$router.push({name: 'index'})
        },
        async registerBtn(){
            this.showSnackBar("Please wait...")
            const dataToPass = {
                name: this.register.name,
                username: this.register.username,
                email: this.register.email,
                phone: this.register.phone,
                password: this.register.password,
                address: this.register.address,
                county: this.register.county.name,
                town: this.register.town,
                zipcode: this.register.zipcode
            }
            await this.$axios.post('register', dataToPass)
            .then(({data}) => {
                if(data.response){
                    this.showSnackBar(data.message)
                    this.OTPdialog = true
                    this.userId = data.id
                    // this.$router.push({name: 'index'})
                }else{
                    this.showSnackBar(data.message)
                    return
                }
            })
        },
        async register_btn_supplier(){
            const tp = {
                fullname:           this.supplier_registration_model[1],
                username:           this.supplier_registration_model[2],
                email:              this.supplier_registration_model[3],
                phone:              this.supplier_registration_model[4],
                password:           this.supplier_registration_model[5],
                rpassword:          this.supplier_registration_model[6],
                company:            this.supplier_registration_model[7],
                website:            this.supplier_registration_model[8],
                service_type:       this.supplier_register.service_type.id
            }
            if(tp.password !== tp.rpassword){
                this.showSnackBar('password did not match!')
                return
            }else{
                await this.$axios.post('supplier/registersupplier', tp)
                .then(({data}) => {
                    if(data.response){
                        this.showSnackBar(data.message)
                        this.$router.push({name: 'login'})
                        return
                    }else{
                        this.showSnackBar(data.message)
                        return
                    }
                })
            }
        },
        async verifyOtp(){
            const toPass = {
                id: this.userId,
                otp: this.otpInput
            };
            await this.$axios.post('verifyOtp', toPass)
            .then(({data}) => {
                if(data.response){
                    this.OTPdialog = false
                    this.showSnackBar(data.message)
                    this.$router.push({name: 'login'})
                }else{
                    this.showSnackBar(data.message)
                }
            })
        }
    },
    watch: {

    }
  }
</script>
<style lang="scss" scoped>
    tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }
</style>