<template>
    <v-container fluid class="mt-5 flex">
        <v-row dense>
            <v-col cols="12" class="mb-5">
            <v-btn
                depressed
                color="primary"
                class="mr-5 mt-5"
                @click="Tab('1')"
            >
                Manage Tips
            </v-btn>
            <v-btn
                depressed
                color="primary"
                class="mr-5 mt-5"
                @click="Tab('2')"
            >
                Suggested Tips
            </v-btn>
            </v-col>  
        </v-row>
        <v-card v-if="tab == 1">
            <v-card-title style="fontSize: 14px;">
                <strong>Manage Tips</strong>
            </v-card-title>
            <v-card-subtitle>
                <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="search tips"
                    label="search tips"
                >
                </v-text-field>
                <v-btn
                    dark
                    color="primary"
                    class="pl-12 pr-12"
                    @click="open_add_dialog"
                >
                <v-icon class="mr-3">mdi-lightbulb-outline</v-icon>add tip
                </v-btn>
            </v-card-subtitle>
            <v-card-text>
                <v-data-table
                    :headers="table_header"
                    :items="get_tips"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    item-key="id"
                >
                    <template v-slot:item="{ item }" >
                        <tr style="cursor:pointer;" @click="open_edit_dialog(item)"
                        >
                            <td>
                                {{ item.id }}
                            </td>
                            <td>
                                <div class="pt-3 pb-3" v-html="item.description" style="max-height:300px;overflow-y:auto"></div>
                            </td>
                            <td>
                                <div v-if="item.get_user != null">{{ item.get_user.name }}</div>
                            </td>
                            <td>
                                <div v-if="item.get_admin != null">{{ item.get_admin.name }}</div>
                            </td>
                            <td>
                                {{item.created_at}}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-card v-if="tab == 2">
            <v-card-title style="fontSize: 14px;">
                <strong>Suggested Tips</strong>
            </v-card-title>
            <v-card-subtitle>
                <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" placeholder="search tips" label="search tips">
                </v-text-field>
            </v-card-subtitle>
            <v-card-text>
                <v-data-table 
                    :headers="table_header_suggestion"
                    :items="get_suggested_tips"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    item-key="id"
                >
                    <template v-slot:item="{ item }">
                        <tr style="cursor:pointer;" @click="open_edit_suggested_dialog(item)">
                            <td>
                                {{ item.id }}
                            </td>
                            <td>
                                <div class="pt-3 pb-3" v-html="item.description" style="max-height:300px;overflow-y:auto"></div>
                            </td>
                            <td>
                                <div v-if="item.get_user != null">{{ item.get_user.name }}</div>
                            </td>
                            <td>
                                {{ item.created_at }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-row justify="center">
            <v-dialog
                max-width="680"
                v-model="add_dialog"
                persistent
            >
                <v-card>
                    <v-card-title style="fontSize: 14px;">Add Tip</v-card-title>

                    <v-card-text>
                        <wysiwyg 
                            label="Tip description"
                            outlined
                            hint="Tip description"
                            class="px-5"
                            dense
                            v-model="tip"
                        />
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        small
                        @click="close_dialog"
                    >
                        <v-icon class="mr-3" small>mdi-window-close</v-icon>cancel
                    </v-btn>
                    <v-btn
                        dark
                        small
                        class="pl-10 pr-10"
                        color="primary"
                        @click="add_tip"
                    >
                        <v-icon class="mr-3" small>mdi-plus</v-icon>add
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                max-width="680"
                v-model="edit_dialog"
                persistent
            >
                <v-card>
                    <v-card-title style="fontSize: 14px;">Edit Tip
                        <v-spacer></v-spacer>
                        <v-btn
                            dark
                            small
                            @click="delete_dialog = true"
                        ><v-icon class="mr-3" small>mdi-delete</v-icon>delete</v-btn>
                    </v-card-title>
                    
                    <v-card-text>
                        <wysiwyg 
                            label="Tip description"
                            outlined
                            hint="Tip description"
                            class="px-5"
                            dense
                            v-model="tip"/>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        small
                        @click="close_dialog"
                    >
                        <v-icon class="mr-3" small>mdi-window-close</v-icon>cancel
                    </v-btn>
                    <v-btn
                        dark
                        small
                        class="pl-10 pr-10"
                        color="primary"
                        @click="edit_tip"
                    >
                        <v-icon class="mr-3" small>mdi-plus</v-icon>edit
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                max-width="680"
                v-model="edit_suggested_dialog"
                persistent
            >
                <v-card>
                    <v-card-title style="fontSize: 14px;">Edit Suggested Tip
                        <v-spacer></v-spacer>
                        <v-btn
                            dark
                            small
                            @click="delete_dialog = true"
                        ><v-icon class="mr-3" small>mdi-delete</v-icon>delete</v-btn>
                    </v-card-title>

                    <v-card-text>
                        <wysiwyg 
                            label="Tip description"
                            outlined
                            hint="Tip description"
                            class="px-5"
                            dense
                            v-model="tip"/>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        small
                        @click="close_dialog"
                    >
                        <v-icon class="mr-3" small>mdi-window-close</v-icon>cancel
                    </v-btn>
                    <v-btn
                        dark
                        small
                        class="pl-10 pr-10"
                        color="primary"
                        @click="accept_tip"
                    >
                        <v-icon class="mr-3" small>mdi-plus</v-icon>approve
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                max-width="300"
                v-model="delete_dialog"
                persistent
            >
                <v-card>
                    <v-card-title style="fontSize: 18px;" center>Are you sure?
                    </v-card-title>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        small
                        @click="delete_dialog = false"
                    >
                        no
                    </v-btn>
                    <v-btn
                        dark
                        small
                        class="pl-10 pr-10"
                        color="primary"
                        @click="delete_tip"
                    >
                        <v-icon class="mr-3" small>mdi-check</v-icon>yes
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    table_header:[
      { text: 'ID', sortable: false },
      { text: 'Description', sortable: false, width: '70%' },
      { text: 'Author', sortable: false },
      { text: 'Accepted by', sortable: false },
      { text: 'Created', sortable: false }
    ],
    table_header_suggestion:[
      { text: 'ID', sortable: false },
      { text: 'Description', sortable: false, width: '70%' },
      { text: 'Suggested by', sortable: false },
      { text: 'Created', sortable: false }
    ],
    tab: "1",
    search: null,
    add_dialog: false,
    edit_dialog: false,
    edit_suggested_dialog: false,
    delete_dialog: false,
    tip: '',
    tip_id: null,
    sortBy: 'created_at',
    sortDesc: true,
  }),
  mounted () {
  },
  created () {
    this.fetchtips()
    this.fetchsuggestedtips()
  },
  computed: {
    get_tips(){
        return this.$store.getters['admin_tips/get_tips'](this.search)
    },
    get_suggested_tips(){
        return this.$store.getters['admin_tips/get_suggested_tips'](this.search)
    },
    getuser(){
        return this.$store.getters['auth/getuser']
    },
  },
  methods: {
    Tab(tab){
        this.tab = tab
    },
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async fetchtips(){
        await this.$axios.get('admin/tips/g_tips')
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('admin_tips/set_tips', data.data)
            }
        })
    },
    async fetchsuggestedtips(){
        await this.$axios.get('admin/tips/get_suggested_tips')
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('admin_tips/set_suggested_tips', data.data)
            }
        })
    },
    open_add_dialog(){
        this.add_dialog = true
    },
    open_edit_dialog(item){
        this.tip = item.description
        this.tip_id = item.id
        this.edit_dialog = true
    },
    open_edit_suggested_dialog(item){
        this.tip = item.description
        this.tip_id = item.id
        this.edit_suggested_dialog = true
    },
    close_dialog(){
        this.add_dialog = false
        this.edit_dialog = false
        this.edit_suggested_dialog = false
        this.tip = ''
        this.tip_id = null
    },
    async add_tip(){
        await this.$axios.post('admin/tips/add_tip', { user_id: this.getuser.id, description: this.tip, accepted: true })
        .then(({data}) => {
            if (data.response) {
                this.showSnackBar('Tip added')
                this.close_dialog()
                this.fetchtips()
            }
            else {
                this.showSnackBar(data.message)
            }
            
        })
    },
    async edit_tip(){
        await this.$axios.patch(`admin/tips/edit_tip/${this.tip_id}`, { description: this.tip })
        .then(({data}) => {
            if (data.response) {
                this.showSnackBar('Tip edited')
                this.close_dialog()
                this.fetchtips()
                this.fetchsuggestedtips()
            }
            else {
                this.showSnackBar(data.message)
            }
        })
    },
    async accept_tip(){
        await this.$axios.patch(`admin/tips/accept_tip/${this.tip_id}`, { description: this.tip, user_id: this.getuser.id })
        .then(({data}) => {
            if (data.response) {
                this.showSnackBar('Tip approved')
                this.close_dialog()
                this.fetchtips()
                this.fetchsuggestedtips()
            }
            else {
                this.showSnackBar(data.message)
            }
        })
    },
    async delete_tip(){
        await this.$axios.delete(`admin/tips/delete_tip/${this.tip_id}`)
        .then(() => {
            this.showSnackBar('Tip deleted')
            this.close_dialog()
            this.delete_dialog = false
            this.fetchtips()
            this.fetchsuggestedtips()
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>