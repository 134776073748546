<template>
    <v-row justify="center">
        <v-dialog
            v-model="all_bm_trans_formdialog"
            persistent
            scrollable
            max-width="900">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-clipboard-text-clock</v-icon>
                    Transaction Summary
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    
                    <v-text-field
                        v-model="search"                     
                        append-icon="mdi-magnify"
                        placeholder="search transaction..."
                        label="search transaction...">
                    </v-text-field>
                    <v-data-table
                        :search="search"
                        :headers="headers"
                        :items="get_budgetmanager_transactions"
                        class="elevation-1"
                        mobile-breakpoint="0">
                        <template v-slot:[`item.amount`]="{ item }">
                            <span>€ {{ item.amount | formatNumber }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="px-16" text color="error"
                        @click="close_dialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        all_bm_trans_formdialog: false,
        headers: [
            { text: 'Amount', value: 'amount' },
            { text: 'Payment Method', value: 'payment_method' },
            { text: 'Date', value: 'created_at' },
            { text: 'Name', value: 'name' }
        ],
        search: null        
    }),
    props: {
        show_all_bm_trans_formdialog: Boolean,
        users_id: Number,
        project_id: Number
    },
    computed: {
        ...mapGetters({
            get_budgetmanager_transactions:         'budget_transaction/get_budgetmanager_transactions_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_all_budgetmanager_transaction:    'budget_transaction/fetch_all_budgetmanager_transaction_action'
        }),
        close_dialog() {
            this.$emit('close_dialog');
        }
    },
    watch: {
        show_all_bm_trans_formdialog() {            
            this.all_bm_trans_formdialog = this.show_all_bm_trans_formdialog;
            if ( this.show_all_bm_trans_formdialog == true ) {
                this.fetch_all_budgetmanager_transaction({
                    users_id: this.users_id,
                    project_id: this.project_id
                });
            }
        }
    }
}
</script>