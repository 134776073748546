import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        service_types: [],
        to_delete: {}
    },
    mutations: {
        set_service_types(state, payload){
            state.service_types = [...payload]
        },
        set_to_delete(state, payload){
            state.to_delete = payload
        }
    },
    getters: {
        get_service_types : state => value => {
            if(value === null || value === ''){
                return state.service_types
            }
            return state.service_types.filter(q => {
                return q.service_type.toLowerCase().includes(value)
            })
        },
        get_to_delete : state => state.to_delete
    },
    actions: {
        set_service_types({commit}, payload){
            commit('set_service_types', payload)
        },
        async set_fetch_service_types({commit}){
            await Vue.axios.get('admin/service_types/get_service_types')
            .then(({data}) => {
                commit('set_service_types', data.data)
            })
        },
        set_to_delete({commit}, payload){
            commit('set_to_delete', payload)
        }
    }
}