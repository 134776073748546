import Vue from "vue";

export default {
    namespaced: true,
    state: {
      todo: [],
      donetodo: [],
      reminders: []
    },
    mutations: {
      setTodo(state, payload){
        state.todo = [...payload]
      },
      setDoneTodo(state, payload){
        state.donetodo = [...payload]
      },
      set_reminders(state, payload) {
        state.reminders = [...payload];
      }
    },
    getters: {
      getTodo(state){
        return state.todo
      },
      getDoneTodo(state){
        return state.donetodo
      },
      get_completed_reminders_getter: state => state.reminders.filter(reminder => reminder.status == 'Completed'),
      get_reminders_getter: state => state.reminders.filter(reminder => reminder.status == 'Pending')
    },
    actions: {
      setTodo({commit}, payload){
        commit('setTodo', payload)
      },
      setDoneTodo({commit}, payload){
        commit('setDoneTodo', payload)
      },
      async fetch_reminders_action({commit}, payload) {
        await Vue.axios.get(`/reminders/getreminders/${payload.project_id}/${payload.user_id}`)
          .then( ({data}) => {
            commit('set_reminders', data.data);
          })
      }
    }
}