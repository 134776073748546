<template>
  <v-row justify="center">
    <v-dialog v-model="show_checklist_dialog" fullscreen persistent>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Checklist</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark
          @click="close_checklist_dialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-card-title style="fontSize: 18px;">
          <small>&nbsp;</small>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4" style="border-right: 1px solid gray;">
              <v-list>
                <v-list-item-group v-model="selected_checklist" color="primary">
                  <v-list-item 
                    v-for="(item, index) in checklist_item" 
                    :key="index"
                    @click="showtablechild(item.get_child_tasks)">
                    <v-list-item-icon>
                      <v-icon>mdi-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <small>
                          <strong>{{ item.task }}</strong>
                        </small>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col cols="8">
              <v-data-table :headers="checklist_headers" :items="child_checklist" class="elevation-1">
                <template v-slot:item="props">
                  <tr>
                    <td>
                      <small>
                        {{ props.item.task }}
                      </small>
                    </td>
                    <td>
                      <v-switch 
                        v-model="props.item.is_done"
                        @change="update_is_done(props.item)"></v-switch>
                    </td>
                    <td class="pt-4">
                      <v-textarea 
                        v-model="props.item.notes" 
                        dense 
                        outlined
                        @focusout="update_notes(props.item)"></v-textarea>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            outlined 
            text
            @click="close_checklist_dialog()">
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data: () => ({
    show_checklist_dialog: false,
    child_checklist: [],
    selected_checklist: [],
    checklist_headers: [
      { text: 'Task', width: '15' },
      { text: 'Done', width: '5' },
      { text: 'Notes', width: '80' }
    ]
  }),
  props: {
    checklist_item: Array,
    show_checklist: Boolean
  },
  methods: {
    ...mapActions({
      edit_checklist_subitem:       'project_planner_v2/edit_checklist_subitem_actions'
    }),
    close_checklist_dialog() {     
      this.$emit('close_checklist_dialog');
    },
    showtablechild(items) {
      this.child_checklist = items;
    },
    async update_notes(item) {
      await this.edit_checklist_subitem({
        checklist_id: item.id,
        data: {
          notes: item.notes
        }
      });
    },
    async update_is_done(item) {      
      await this.edit_checklist_subitem({
        checklist_id: item.id,
        data: {
          is_done: item.is_done
        }
      });
    }
  },
  watch: {
    show_checklist() {
      this.show_checklist_dialog = this.show_checklist;
    }
  }
}
</script>