import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        video_archives: [],
        trashed_video_archives: [],
        upload_progress: {}
    },
    mutations: {
        set_video_archived_mutations(state, payload) {
            state.video_archives = [...payload];
        },
        add_uploaded_video_archive_mutations(state, payload) {
            state.video_archives.push(payload);
        },
        set_upload_progress_mutations(state, payload) {
            state.upload_progress = payload;
        },
        update_uploaded_video_archive_mutations(state, payload) {
            state.video_archives.splice(state.video_archives.findIndex(video => video.id === payload.id), 1, payload);
        },
        delete_uploaded_video_archive_mutations(state, payload) {
            state.video_archives.splice(state.video_archives.findIndex(video => video.id === payload.id), 1);
            state.trashed_video_archives.push(payload);
        },
        set_trashed_video_archives_mutations(state, payload) {
            state.trashed_video_archives = [...payload];
        },
        restore_trashed_video_archive_mutations(state, payload) {
            state.trashed_video_archives.splice(state.trashed_video_archives.findIndex(video => video.id === payload.id), 1);
            state.video_archives.push(payload);
        }
    },
    getters: {
        get_video_archives_getters: state => state.video_archives,
        get_upload_progress_getters: state => state.upload_progress,
        get_trashed_video_archives_getters: state => state.trashed_video_archives,
    },
    actions: {
        async fetch_video_archived_actions({commit}) {
            await Vue.axios.get(`admin/video_archived/g_uploaded_video_archives`).then( ({data}) => {
                if ( data.response == false ) {
                    console.log(data.error);
                } else {
                    commit('set_video_archived_mutations', data.data);
                }
            });
        },
        async upload_video_archived_actions({commit}, payload) {
            const fileFormData = new FormData();
            fileFormData.append('name', payload.name);
            fileFormData.append('file', payload.file);
            fileFormData.append('description', payload.description);

            await Vue.axios.post(`admin/video_archived/upload_video_archive`, fileFormData, {
                onUploadProgress: (progressEvent) => {
                    commit('set_upload_progress_mutations',progressEvent);
                }
            }).then( ({data}) => {
                if ( data.response == false ) {
                    console.log(data.error);
                } else {
                    commit('add_uploaded_video_archive_mutations', data.data[0]);
                }
            });
        },
        async update_uploaded_video_archived_actions({commit}, payload) {
            const fileFormData = new FormData();
            fileFormData.append('name', payload.name);
            fileFormData.append('file', payload.file);
            fileFormData.append('description', payload.description);

            await Vue.axios.post(`admin/video_archived/edit_uploaded_video_archive/${payload.id}`, fileFormData, {
                onUploadProgress: (progressEvent) => {
                    commit('set_upload_progress_mutations',progressEvent);
                }
            }).then( ({data}) => {
                commit('update_uploaded_video_archive_mutations', data.data[0]);
            });
        },
        async delete_uploaded_video_archived_actions({commit}, payload) {
            //console.log(commit, payload);
            await Vue.axios.delete(`admin/video_archived/delete_uploaded_video_archive/${payload.id}`).then( ({data}) => {
                if ( data.response == false ) {
                    console.log(data.error);
                } else {
                    commit('delete_uploaded_video_archive_mutations', data.data[0]);
                }
            });
        },
        async fetch_trashed_video_archives_actions({commit}) {
            await Vue.axios.get(`admin/video_archived/g_trashed_video_archived`).then( ({data}) => {
                if (data.response == false) {
                    console.log(data.error);
                } else {
                    commit('set_trashed_video_archives_mutations', data.data)
                }
            });
        },
        async retore_trashed_video_archive_actions({commit}, payload) {
            await Vue.axios.patch(`admin/video_archived/restore_trashed_video_archived/${payload.id}`).then( ({data}) => {
                if ( data.response == false ) {
                    console.log(data.error);
                } else {
                    commit('restore_trashed_video_archive_mutations', data.data[0]);
                }
            });
        }
    },
}