import store from '@/states/index.js'

import SiteDiary from '../SiteDiary.vue'

export default [
    {
        path: '/sitediary',
        component: SiteDiary,
        name: 'sitediary',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 3){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]