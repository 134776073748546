<template>
    <v-row justify="center">
        <v-dialog
            v-model="add_material_model"
            persistent
            max-width="600">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    Add Material
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-text-field
                        v-model="add_material.name"
                        label="Material Name"
                        hint="Enter Material Name"
                        prepend-icon="mdi-text-box-outline"
                        dense
                        outlined
                        type="text"
                        autofocus></v-text-field>
                    <v-file-input
                        v-model="add_material.image"
                        label="Material Image"
                        hint="Upload Material Image"
                        dense
                        outlined></v-file-input>
                    <v-text-field
                        v-model="add_material.quantity"
                        label="Material Quantity"
                        hint="Enter Material Quantity"
                        prepend-icon="mdi-numeric"
                        dense
                        outlined
                        type="number"></v-text-field>
                    <v-text-field
                        v-model="add_material.unit"
                        label="Material Unit"
                        hint="Enter Material Unit (pcs, no., packs, etc.)"
                        prepend-icon="mdi-scale"
                        dense
                        outlined
                        type="text"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" class="px-6" @click="evtCloseDialog"
                        :disabled="loading">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Cancel
                    </v-btn>
                    <v-btn color="success" class="px-6" @click="evtAddNewMaterial"
                        :loading="loading"
                        :disabled="loading">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import BillOfMaterials from '../modules/BillOfMaterials.js'

export default {
    data: () => ({
        add_material_model: false,
        add_material: {
            name: null,
            image: null,
            quantity: 0,
            unit: null
        },
        bill_of_materials: new BillOfMaterials(),
        loading: false
    }),
    props: {
        add_material_dialog: Boolean,
        choosen_template: Object
    },
    methods: {
        evtCloseDialog() {
            this.$emit('evtCloseDialog');
        },
        async evtAddNewMaterial() {
            this.loading = true
            await this.bill_of_materials.addBoMMaterialPerTemplate(this.add_material, this.choosen_template)
            this.loading = false
            this.evtCloseDialog()
        }
    },
    mounted() {
        this.bill_of_materials.setStore(this.$store)
    },
    watch: {
        add_material_dialog() {
            this.add_material_model = this.add_material_dialog
            if ( this.add_material_dialog == false ) {
                this.add_material.name = null
                this.add_material.image = null
                this.add_material.quantity = 0
                this.add_material.unit = null
            }
        }
    }
}
</script>