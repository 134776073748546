<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="tableheader"
          :items="get_quoted_data"
        >
          <template v-slot:top>
            <v-card
              class="elevation-1"
            >
              <v-card-title style="fontSize: 18px;">
                Placed Quotations
              </v-card-title>
            </v-card>
          </template>
          <template v-slot:item="{ item, expand, isExpanded }" >
            <tr
              style="cursor:pointer;" 
              @click="expand(!isExpanded)"
            >
              <td>
                <strong v-if="item.get_requests != null">
                  {{ item.get_requests.project_name }}
                </strong>
                <strong v-else>
                  Request Deleted
                </strong>
              </td>
              <td>
                <strong v-if="item.get_requests != null">
                  {{ item.get_requests.build_stage }}
                </strong>
                <strong v-else>Request Deleted</strong>
              </td>
              <td>
                <strong v-if="item.get_requests != null">
                  {{ item.get_requests.user.name }}
                </strong>
                <strong v-else>Request Deleted</strong>
              </td>
              <td>
                <strong v-if="item.get_requests != null">
                  {{ item.get_requests.address_line }} {{ item.get_requests.city }}, {{ item.get_requests.state }}, {{ item.get_requests.country }}, {{ item.get_requests.postal_code }} 
                </strong>
                <strong v-else>Request Deleted</strong>
              </td>
              <td><v-textarea
                        :value="`${item.description}`"
                        class="no-background-textarea"
                        readonly
                        style="pointer-events:none;font-weight:bold;margin-top:25px"
                        flat
                        solo
                        dense
                        no-resize
                        rows="3"></v-textarea></td>
              <td><strong>€ {{ item.quote_price | formatNumber }}</strong></td>
              <td style="height:200px;">
                <div v-if="item.get_requests.attachments != null">
                  <div v-if="item.get_requests.attachments[0].split('.').pop() == 'png' || item.get_requests.attachments[0].split('.').pop() == 'jpg' || item.get_requests.attachments[0].split('.').pop() == 'jpeg'">
                    <v-img contain :src="`${url}frontend/images/${item.get_requests.attachments[0]}`" style="max-width:250px; max-height:200px;"/>
                  </div>
                  <div v-if="item.get_requests.attachments[0].split('.').pop() == 'pdf'">
                    <v-img contain :src="`https://media.istockphoto.com/vectors/icon-major-file-format-vector-icon-illustration-vector-id1298834280?k=20&m=1298834280&s=612x612&w=0&h=SxEbyHKi18H1XpPpKKzoppWgMM3x0tc3veN5e6wl7Y8=`" style="max-width:250px; max-height:150px;"/>
                  </div>
                  <div v-if="item.get_requests.attachments[0].split('.').pop() == 'docx'">
                    <v-img contain :src="`https://seeklogo.com/images/M/microsoft-word-logo-E648C182A5-seeklogo.com.png`" style="max-width:250px; max-height:150px;"/>
                  </div>
                  <div v-if="item.get_requests.attachments[0].split('.').pop() == 'xlsx'">
                    <v-img contain :src="`https://seeklogo.com/images/E/excel-logo-974BFF9CB9-seeklogo.com.png`" style="max-width:250px; max-height:150px;"/>
                  </div>
                  <div v-if="item.get_requests.attachments.length > 1">+ {{item.get_requests.attachments.length - 1}} more</div>
                </div>
                <div v-else>
                  None
                </div>
              </td>
              <!-- <td><img v-if="item.get_requests != null" style="max-width: 150px; max-height: 150px;" :src="`${url}frontend/images/${item.get_requests.attachments}`"/></td> -->
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card>
                <v-card-title style="fontSize: 14px;">
                  Client: {{item.get_requests.user.name}}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="4"
                    >
                      <label>Project Name</label>
                      <v-text-field
                        readonly
                        filled
                        dense
                        :value="item.get_requests.project_name"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="4"
                    >
                      <label>Build Stage</label>
                      <v-text-field
                        readonly
                        filled
                        dense
                        :value="item.get_requests.build_stage"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="4"
                    >
                      <label>Address</label>
                      <v-text-field
                        readonly
                        filled
                        dense
                        :value="`${item.get_requests.address_line} ${item.get_requests.city} ${item.get_requests.state} ${item.get_requests.country} ${item.get_requests.postal_code}`"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <label>Description</label>
                      <v-textarea
                        :value="`${item.description}`"
                        readonly
                        filled
                        dense
                      >
                      </v-textarea>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <label>Attachment</label>
                      <v-row 
                        class="mt-1 mb-3"
                      >
                        <div v-if="item.attachment != null">
                          <v-btn
                            style="display: inline-block; color: blue;"
                            outlined
                            class="ml-3"
                            @click="download_attachment(item.attachment)"
                          >
                            <v-icon
                              class="mr-3"
                            >
                              mdi-briefcase-download-outline                              
                            </v-icon>
                            Download Attachment By Me
                          </v-btn>
                        </div>
                        <div v-else>
                          No attachment by me
                        </div>
                      </v-row>
                      <v-row>
                        <div v-if="item.get_requests.attachments != null">
                          <v-btn
                            v-for="(attachment, index) in item.get_requests.attachments"
                            :key="index"
                            style="display: inline-block;margin-right:5px;margin-bottom:5px;"
                            outlined
                            @click="download_attachment(item.get_requests.attachments[index])"
                          >
                            <v-icon
                              class="mr-3"
                            >
                              mdi-briefcase-download-outline                              
                            </v-icon>
                            Download Attachment By Client
                          </v-btn>
                        </div>
                        <div v-else>
                          No attachments by client
                        </div>
                      </v-row>
                      <!-- <v-img
                        v-if="item.get_requests.attachments == null"
                        lazy-src="https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
                        max-height="100%"
                        max-width="100%"
                        src="https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
                      ></v-img>
                      <v-img v-else
                        :lazy-src="`${url}frontend/images/${item.get_requests.attachments}`"
                        max-height="100%"
                        max-width="100%"
                        :src="`${url}frontend/images/${item.get_requests.attachments}`"
                      >
                      </v-img> -->
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    
  },
  props: [
  ],
  data: () => ({
    tableheader: [
      { text: 'Project Name' },
      { text: 'Build Stage' },
      { text: 'Client Name' },
      { text: 'Address' },
      { text: 'Description' },
      { text: 'Price' },
      { text: 'Attachment' }
    ],
    url: null
  }),
  mounted () {
    this.get_quoted()
  },
  created () {
    this.url = process.env.VUE_APP_URL
  },
  computed: {
    ...mapGetters({
      get_quoted_data:                    'supplier_quoted/get_quoted_data'
    })
  },
  methods: {
    download_attachment(data){
      window.open(this.url+ 'frontend/images/' + data)
    },
    async get_quoted(){
      await this.$axios.get('supplier/quoted/get_quoted')
      .then(({data}) => {
        console.log(data)
        if(data.response){
          this.$store.dispatch('supplier_quoted/set_quoted_data', data.data)
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .no-background-textarea > .v-input__control > .v-input__slot {
    background:none!important;
  }
  ::v-deep .no-background-textarea > .v-input__control > .v-input__slot > .v-text-field__slot > textarea {
    overflow:hidden!important;
  }
</style>