<template>
    <div>
        <v-card>
            <v-card-title>
                <small>{{ template_name }}</small>
                <v-spacer></v-spacer>
                <v-btn 
                    class="white--text pl-10 pr-10 mr-3"
                    style="background-color: rgb(11, 78, 243); border-color: rgb(11, 78, 243);"
                    rounded
                    @click="open_add_section_dialog()">
                    <v-icon class="mr-1">mdi-plus</v-icon>Add Section
                </v-btn>
                <v-btn v-if="has_checklist == true"
                    class="px-10"
                    color="primary"
                    rounded
                    @click="show_checklist_dialog()">
                    Checklist
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-expansion-panels>
                    <draggable 
                        style="width:100%"
                        handle=".handle"
                        :list="items"
                        @change="check_move()">
                        <v-expansion-panel
                        v-for="(item, index) in items"
                        :key="index">
                        <v-expansion-panel-header                             
                            class="white--text"
                            color="primary">
                            <div>
                                <v-icon 
                                    class="handle white--text"
                                    slot="prependIcon">
                                    mdi-drag
                                </v-icon>
                                <v-icon
                                    class="white--text ml-4" 
                                    slot="prependIcon"
                                    @click.native.stop="add_task_dialog(item)">
                                    mdi-plus
                                </v-icon>
                                <v-icon                                    
                                    slot="prependIcon"
                                    class="ms-5 white--text"
                                    @click.native.stop="confim_delete_project_section(item.id)">
                                    mdi-delete
                                </v-icon>
                                <span class="ml-6"><strong>{{item.name}}</strong></span>
                            </div>                       
                            <template v-slot:actions>
                                <v-icon color="white">
                                $expand
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-data-table
                                :headers="header"
                                :items="item.get_sub_tasks"
                                item-key="id"
                                class="elevation-1 custom-table"
                                style="font-weight:700;"
                                :hide-default-footer="true"
                                disable-pagination
                                mobile-breakpoint="0">
                                <template v-slot:item="{item}">
                                    <tr @click="open_edittask_dialog(item)">
                                        <td style="cursor: pointer; white-space: pre-line;"><small>{{item['name']}}</small></td>
                                        <td style="cursor: pointer;">
                                            <small class="unselectable" v-if="item['duration'] === '' || item['duration'] === null"> 
                                                Need duration
                                            </small>
                                            <small class="unselectable" v-else>
                                                {{item['duration']}} Days
                                            </small>
                                        </td>
                                        <td style="cursor: pointer;">
                                            <small class="unselectable" v-if="item['status'] === 'Pending'" style="color: red !important;">
                                                <i>{{item['status']}}</i>
                                            </small>
                                            <small class="unselectable" v-if="item['status'] === 'Started'" style="color: green !important;">
                                                <i>{{item['status']}}</i>
                                            </small>
                                            <small class="unselectable" v-if="item['status'] === 'On-Hold'" style="color: red !important;">
                                                <i>{{item['status']}}</i>
                                            </small>
                                            <small class="unselectable" v-if="item['status'] === 'Waiting-on Info'" style="color: orange !important;">
                                                <i>{{item['status']}}</i>
                                            </small>
                                            <small class="unselectable" v-if="item['status'] === 'Complete'" style="color: green !important;">
                                                <i>{{item['status']}}</i>
                                            </small>
                                        </td>
                                        <td class="unselectable" style="cursor: pointer;"><small>{{item['start']}}</small></td>
                                        <td class="unselectable" style="cursor: pointer;"><small>{{item['finish']}}</small></td>
                                        <td class="unselectable" style="cursor: pointer;"><small>{{item['notes']}}</small></td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                    </draggable>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
        <AddSection
        :add_section_dialog="add_section"
        :project_id="project_id"
        @close_dialog="close_add_section_dialog()"></AddSection>

        <!-- Delete Section Dialog -->
        <v-row justify="center">
            <v-dialog
                max-width="420"
                v-model="confirm_delete_section"
                persistent>
                <v-card>
                    <v-card-title style="fontSize: 18px;">
                        <small>Are you sure you want to delete this entry?</small>
                    </v-card-title>

                    <v-card-text>
                        <small>All of the data that belongs to this entry will be deleted, are you sure about this?</small>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="delete_project_task()">
                            Yes
                        </v-btn>
                        <v-btn
                            class="px-16 white--text"
                            style="background-color: rgb(11, 78, 243); border-color: rgb(11, 78, 243);"
                            @click="cancel_delete_project_section()">
                            No
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- /Delete Section Dialog -->

        <!-- Checklist - Free Tier -->
        <ChecklistView
            :show_checklist="show_checklist"
            :checklist_item="checklist_item"
            @close_checklist_dialog="close_checklist_dialog()"></ChecklistView>
        <!-- ./Checklist - Free Tier -->

        <!-- Add Task -->
        <AddTask
            :show_add_task_modal="show_add_task_dialog"
            :task_item="task_item"
            @close_add_task_dialog="close_addtask_dialog()">
        </AddTask>
        <!-- /Add Task-->

        <!-- Edit Task -->
        <EditTask
            :show_edit_task_dialog="show_edittask_dialog"
            :task_item="task_item"
            :section_item="section"        
            @close_edit_task_dialog="close_edittask_dialog"
            >
        </EditTask>
        <!-- ./Edit Task -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import AddSection from './AddSection.vue';
import ChecklistView from './ChecklistView.vue';
import AddTask from './AddTask.vue';
import EditTask from './EditTask.vue';
import draggable from 'vuedraggable';

export default {
    data: () => ({
        header: [
            { text: 'Task Name', filterable: true, value: 'name', class: 'headerColorToBlue'},
            { text: 'Duration', filterable: true, value: 'duration'},
            { text: 'Status', filterable: true, value: 'status'},
            { text: 'Start', filterable: true, value: 'start'},
            { text: 'Finish', filterable: true, value: 'finish'},
            { text: 'Notes', filterable: true, value: 'notes'},
        ],
        add_section: false,
        section_id: null,
        confirm_delete_section: false,
        has_checklist: false,
        show_checklist: false,
        show_add_task_dialog: false,
        task_item: {},
        show_edittask_dialog: false,
        section: {},
        section_data: [],
        updated_item_order: []
    }),
    props: {
        items: Array,
        checklist_item: Array,
        project_id: Number,
        template_name: String
    },
    components: {
        AddSection,
        ChecklistView,
        AddTask,
        EditTask,
        draggable
    },
    computed: {

    },
    methods: {
        ...mapActions({
            delete_project_section:     'project_planner_v2/delete_project_section_actions'
        }),
        close_add_section_dialog() {
            this.add_section = false;           
        },
        open_add_section_dialog() {
            this.add_section = true;
        }, 
        confim_delete_project_section(section_id) {
            this.section_id = section_id;
            this.confirm_delete_section = true;
        },   
        async delete_project_task() {
            await this.delete_project_section({
                project_id: this.project_id,
                section_id: this.section_id
            });
            this.cancel_delete_project_section();
        },
        cancel_delete_project_section() {
            this.confirm_delete_section = false;
            this.section_id = null;
        },
        show_checklist_dialog() {
            this.show_checklist = true;
        },
        close_checklist_dialog() {
            this.show_checklist = false;
        },
        add_task_dialog(item) {
            this.show_add_task_dialog = true;
            this.task_item = item;
        },
        close_addtask_dialog() {
            this.show_add_task_dialog = false;
            //location.reload();
        },
        open_edittask_dialog(item) {
            this.task_item = item;
            this.section = this.items.find(item => item.id == this.task_item.project_task_id);            
            this.show_edittask_dialog = true;
        },
        close_edittask_dialog() {
            this.show_edittask_dialog = false;
        },
        check_move() {
            this.updated_item_order = [];
            this.items.map( (item, index) => {
                this.updated_item_order.push({
                    id: item.id,
                    project_id: item.project_id,
                    order: index
                })
            });

            this.update_reorder_of_sections({
                project_id: this.project_id,
                sections: this.items,
                data: this.updated_item_order
            });
        }
    },
    watch: {
        checklist_item() {
            if ( this.checklist_item.length > 0 ) {
                this.has_checklist = true;
            } else {
                this.has_checklist = false;
            }
        }
    }
}
</script>

<style scoped lang="scss">
</style>