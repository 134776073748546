export default {
    namespaced: true,
    state: {
        plan_upload_data: []
    },
    mutations: {
        set_plan_upload_data(state, payload){
            state.plan_upload_data = [...payload]
        }
    },
    getters: {
        get_plan_upload_data : state => state.plan_upload_data
    },
    actions: {
        set_plan_upload_data({commit}, payload){
            commit('set_plan_upload_data', payload)
        }
    }
}