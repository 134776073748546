import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        categories: [],
        selected_category: {},
        selected_sub_category: {},
        items: [],
        to_delete_item: {},
        to_edit_item: {},
        bool_cat_selected: false,
        sub_category_items: []
    },
    mutations: {
        set_categories(state, payload){
            state.categories = [...payload]
        },
        set_selected_category(state, payload){
            state.selected_category = payload
        },
        set_items(state, payload){
            state.items = [...payload]
        },
        set_to_delete_item(state, payload){
            state.to_delete_item = payload
        },
        set_to_edit_item(state, payload){
            state.to_edit_item = payload
        },
        set_update_edit_chip_item(state, payload){
            state.to_edit_item.chip_data = payload
        },
        set_update_edit_price(state, payload){
            state.to_edit_item.price = payload
        },
        set_bool_cat_selected(state, payload){
            state.bool_cat_selected = payload
        },
        set_sub_category_items(state, payload){
            state.sub_category_items = [...payload]
        },
        set_selected_sub_category(state, payload){
            state.selected_sub_category = payload
        }
    },
    getters: {
        get_categories : (state) => state.categories,
        get_selected_category : (state) => state.selected_category,
        get_items : (state) => (value) => {
            if(value == null || value == ""){
                return state.items
            }
            return state.items.filter( q => {
                return q.name.toLowerCase().includes(value)
            })
        },
        get_to_delete_item : (state) => state.to_delete_item,
        get_to_edit_item : (state) => state.to_edit_item,
        get_bool_cat_selected : (state) => state.bool_cat_selected,
        get_sub_category_items : (state) => state.sub_category_items,
        get_selected_sub_category : (state) => state.selected_sub_category
    },
    actions: {
        async get_categories({commit}){
            await Vue.axios.get('/shop/get_all_categories')
            .then(({data}) => {
                commit('set_categories', data.data)
            })
        },
        async add_category(_, payload){
            await Vue.axios.post('/shop/add_category_data', payload)
        },
        async get_items_via_cat_id({commit}, payload){
            commit('set_selected_category', payload)
            await Vue.axios.get(`/shop/get_item_via_category_id/${payload.id}`)
            .then(({data}) => {
                commit('set_items', data.data)
            })
            await Vue.axios.get(`/shop/get_sub_categories/${payload.id}`)
            .then(({data}) => {
                commit('set_sub_category_items', data.data)
            })
        },
        async delete_item(_, payload){
            await Vue.axios.delete(`/shop/delete_item/${payload.id}`)
        },
        set_to_delete_item({commit}, payload){
            commit('set_to_delete_item', payload)
        },
        set_to_edit_item({commit}, payload){
            commit('set_to_edit_item', payload)
        },
        update_edit_chip_item({commit}, payload){
            commit('set_update_edit_chip_item', payload)
        },
        set_update_edit_price({commit}, payload){
            commit('set_update_edit_price', payload)
        },
        set_bool_cat_selected({commit}, payload){
            commit('set_bool_cat_selected', payload)
        },
        async set_search_via_sub_item({commit}, payload){
            commit('set_selected_sub_category', payload)
            await Vue.axios.get(`/shop/get_item_via_sub_cat/${payload.id}`)
            .then(({data}) => {
                commit('set_items', data.data)
            })
        },
        async bulk_upload_csv(_, payload){
            await Vue.axios.post('/shop/bulk_upload_csv', payload)
        }
    }
}