import Vue from 'vue'

export default {
    namespaced: true,
    state: {
      requestdata: [],
      servicetypes: [],
      country_list: [],
      state_list: [],
      city_list: [],
      cities: [],
      to_edit_quote: {}
    },
    mutations: {
      setrequestdata(state, payload){
        state.requestdata = [...payload]
      },
      setservicetypes(state, payload){
        state.servicetypes = [...payload]
      },
      setcountry_list(state, payload){
        state.country_list = [...payload]
      },
      setstate_list(state, payload) {
        state.state_list = [...payload]
      },
      setcity_list(state, payload) {
        state.city_list = [...payload]
      },
      setcities(state, payload){
        state.cities = [...payload]
      },
      set_to_edit_quote(state, payload){
        state.to_edit_quote = payload
      }
    },
    getters: {
      getrequestdata(state){
        return state.requestdata
      },
      getservicetypes(state){
        return state.servicetypes
      },
      getcountry_list(state){
        return state.country_list
      },
      getstate_list(state) {
        return state.state_list
      },
      getcity_list(state) {
        return state.city_list
      },
      getcities(state){
        return state.cities
      },
      get_to_edit_quote : state => state.to_edit_quote
    },
    actions: {
      setrequestdata({commit}, payload){
        commit('setrequestdata', payload)
      },
      setservicetypes({commit}, payload){
        commit('setservicetypes', payload)
      },
      setcountry_list({commit}, payload){
        commit('setcountry_list', payload)
      },
      setstate_list({ commit }, payload) {
        commit('setstate_list', payload)
      },
      setcity_list({ commit }, payload) {
        commit('setcity_list', payload)
      },
      setcities({commit}, payload){
        commit('setcities', payload)
      },
      set_to_edit_quote({commit}, payload){
        commit('set_to_edit_quote', payload)
      },
      async s_get_requests({commit}){
        await Vue.axios.get('supplierrequest/gRequests')
        .then(({data}) => {
          commit('setrequestdata', data.data)
        })
      }
    }
}