<template>
    <v-container fluid class="mt-5 flex">
        <small>* The cost calculated below is an estimate and is intended only as a guide. Please check and verify all costs throughout your build with your engineer or QS. If you would like exact costs specific to your location and site conditions please contact www.selfbuild3d.com <v-icon style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon></small>
        <v-autocomplete
            v-model="chosenmaterialstemplate"
            outlined
            small-chips
            dense
            :items="getbillofmaterialstemplate"
            item-text="name"
            item-value="id"
            label="choose a template"
            return-object
            prepend-inner-icon="mdi-form-dropdown"            
            class="mt-5">
        </v-autocomplete>
        <div class="mb-6">
            <v-row v-if="chosenmaterialstemplate != null">
                <v-data-table
                :headers="headers"
                :items="billofmaterialsvariables"
                class="elevation-1"
                mobile-breakpoint="0"
                :hide-default-footer="true"
                disable-pagination
                style="width: 250px;">
                    <template v-slot:item="props">
                        <tr style="cursor: pointer;">
                            <td>{{props.item.cname}}</td>
                            <td style="cursor:pointer;" @click="billOfMaterialsUpdateValue(props.item)">
                                <label v-if="props.item.value == null">
                                    {{props.item.constant}}
                                </label>
                                <label v-else>{{props.item.value}}</label>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <v-spacer></v-spacer>
                <v-btn class="mt-5 mr-3 white--text" color="#0b4ef3" @click="evtAddMaterialDialog()">
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    Add Material
                </v-btn>
                <v-btn class="mt-5 mr-3" style="background-color:#0b4ef3;color:white;">
                    <vue-excel-xlsx
                        :data="getbillofmaterialsdata"
                        :columns="headersexport"
                        :file-name="'bill of materials'"
                        :file-type="'xlsx'"
                        :sheet-name="'Sheet 1'">
                        DOWNLOAD
                    </vue-excel-xlsx>
                </v-btn>
            </v-row>
            <BoMTable v-if="chosenmaterialstemplate != null"
                :choosen_material_template="chosenmaterialstemplate"></BoMTable> 
        </div>        
        
        <EditVariables
            :edit_variable_dialog="edit_variable_dialog"
            :bom_variables="bom_variables"
            :selected_bom_template="chosenmaterialstemplate"
            @evtCloseDialog="evtCloseDialog"></EditVariables>
        
        <AddBoMItem
            :add_material_dialog="add_material_dialog"
            :choosen_template="chosenmaterialstemplate"
            @evtCloseDialog="evtCloseAddMaterialDialog"></AddBoMItem>

        <v-dialog
            persistent
            v-model="show_tutorial"
            width="1000">
            <v-card>
                <v-card-title>
                Tutorial
                <v-spacer></v-spacer>
                <v-icon
                    small
                    color="red"
                    @click="show_tutorial = false">
                    mdi-close
                </v-icon>
                </v-card-title>
                <v-divider></v-divider>
                <vue-pdf-embed source="bill_of_materials.pdf" />
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import BillOfMaterials from './modules/BillOfMaterials.js'
import EditVariables from './components/EditVariables.vue'
import BoMTable from './components/BoMTable.vue';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import AddBoMItem from './components/AddBoMItem.vue';

export default {
    data: () => ({
        chosenmaterialstemplate: null,
        bill_of_materials: new BillOfMaterials(),
        edit_variable_dialog: false,
        bom_variables: null,
        headers: [
            { text: 'square meters' },
            { text: 'value' }
        ],
        headersexport: [
            { label: 'ID', field: 'id' },
            { label: 'Name', field: 'name' },
            { label: 'Quantity', field: 'quantity' },
            { label: 'Unit', field: 'unit' },
            { label: 'Supplier 1 Name', field: 'supplier1name' },
            { label: 'Supplier 1 Unit Cost', field: 'supplier1' },
            { label: 'Supplier 1 Total Cost', field: 'supplier1_tc' },
            { label: 'Supplier 2 Name', field: 'supplier2name' },
            { label: 'Supplier 2 Unit Cost', field: 'supplier2' },
            { label: 'Supplier 2 Total Cost', field: 'supplier2_tc' },
            { label: 'Supplier 3 Name', field: 'supplier3name' },
            { label: 'Supplier 3 Unit Cost', field: 'supplier3' },
            { label: 'Supplier 3 Total Cost', field: 'supplier3_tc' },
            { label: 'Variance', field: 'variation' },
        ],
        show_tutorial: false,
        add_material_dialog: false
    }),
    components: {
        EditVariables,
        BoMTable,
        VuePdfEmbed,
        AddBoMItem
    },
    props: {

    },
    computed: {
        ...mapGetters({
            getbillofmaterialstemplate:               'billofmaterials/getbillofmaterialstemplate',
            billofmaterialsvariables:                 'billofmaterials/billofmaterialsvariables',
            getbillofmaterialsdata:                   'billofmaterials/getbillofmaterialsdata',
        })
    },
    methods: {
        billOfMaterialsUpdateValue(item) {            
            this.bom_variables = item
            this.edit_variable_dialog = true
        },
        evtCloseDialog() {
            this.edit_variable_dialog = false
        },
        evtAddMaterialDialog() {
            //console.log(this.chosenmaterialstemplate)
            this.add_material_dialog = true
        },
        evtCloseAddMaterialDialog() {
            this.add_material_dialog = false
        }
    },
    mounted() {
        this.bill_of_materials.setStore(this.$store)
    },
    watch: {
        chosenmaterialstemplate() {
            this.bill_of_materials.chosenBillOfMaterialsTemplte(this.chosenmaterialstemplate)
        }
    }
}
</script>