<template>
    <v-row justify="center" v-if="directories.length > 0">
        <v-expansion-panels accordion>
            <v-expansion-panel
                v-for="directory in directories"
                :key="directory.id">
                <v-expansion-panel-header>
                    <span>{{ directory.name + ' (' + directory.contains + ')'}}</span>                    
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list dense>                       
                        <v-subheader>
                            <v-btn text small color="primary" @click="open_add_file_dialog(directory.id)">
                                <v-icon small>mdi-plus</v-icon>
                                Upload File
                            </v-btn>
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-list-item-group>
                            <v-list-item
                                v-for="media in directory.getmedia"
                                :key="media.id">
                                <v-list-item-icon>
                                    <v-icon :color="search_icon_color(media.extension_file)">mdi-{{ search_icon_def(media.extension_file) }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="media.name + ' (' + media.extension_file + ')'" @click="open_file(media.path)">                                       
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <DialogComponent
            v-model="addfiledialog"
            :value="addfiledialog"
            :dialogvalue="addfiledialogsetup"
            @savefilemanagermedia="savefilemanagermedia"
            @closedialog="closeaddfiledialog"
        />
    </v-row>
    <v-row justify="center" v-else-if="is_asset" class="pt-2 black--text">
        <v-col class="error white--text px-2 py-2">
            <strong>There are no directories set in this project. The client must visit first the file manager to set a default directories.</strong>
        </v-col>
    </v-row>
</template>

<script>
import DialogComponent from '@/components/DialogComponent.vue';
import { mapActions } from 'vuex';

export default {
    data: () => ({
        icon_definition: [
            {icon_id: 'svg', icon: 'svg', color: 'secondary'},
            {icon_id: 'deb', icon: 'debian', color: 'success'},
            {icon_id: 'pdf', icon: 'file-pdf-box', color: 'error'},
            {icon_id: 'xlsx', icon: 'microsoft-excel', color: 'success'},
            {icon_id: 'docx', icon: 'microsoft-word', color: 'primary'},
            {icon_id: 'zip', icon: 'zip-box', color: 'yellow'},
            {icon_id: 'ods', icon: 'table-large', color: 'success'},
            {icon_id: 'odt', icon: 'file-word', color: 'primary'},
            {icon_id: 'jpg', icon: 'file-jpg-box', color: 'primary'},
        ],
        APP_URL: process.env.VUE_APP_URL,
        addfiledialog: false,
        addfiledialogsetup:{
            addfileinfilemanager: true,
            title:                'add file / media',
            icon:                 'mdi-file',
            iconclass:            'mr-2',
            textfield: [
                {
                id:               1,
                label:            'file name',
                hint:             'file name',
                icon:            'mdi-tooltip-text-outline'
                }
            ]
        },
        dir_id: null,
    }),
    components: {
        DialogComponent
    },
    props: {
        directories: Array,
        users: Number,
        project_id: Number,
        is_asset: Boolean
    },
    methods: {
        ...mapActions({
            'add_user_media':    'admin_assets_uploader/add_user_media_action'
        }),
        search_icon_def(extension) {
            const media_icon = this.icon_definition.filter(icon => icon.icon_id == extension);      
            return media_icon.length > 0 ? media_icon[0].icon : 'file';
        },
        search_icon_color(extension) {
            const media_icon = this.icon_definition.filter(icon => icon.icon_id == extension);      
            return media_icon.length > 0 ? media_icon[0].color : 'secondary';
        },
        open_file(path) {
            window.open(this.APP_URL + path, '_blank');
        },
        async savefilemanagermedia(data){           
            let formData = new FormData();
            formData.append('file', data.file);
            formData.append('name', data.filename);
            formData.append('dirId', this.dir_id); 
            formData.append('user_id', this.users);
            formData.append('project_id', this.project_id);
            await this.add_user_media({formData: formData});
            this.closeaddfiledialog();
        },
        closeaddfiledialog(){
            this.addfiledialog = false;
            this.dir_id = null;
        },
        open_add_file_dialog(dir_id) {
            this.addfiledialog = true;
            this.dir_id = dir_id;
        }
    }
}

</script>