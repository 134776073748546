<template>
    <div>
        <v-tabs
            v-model="tab">
            <v-tab>
                Pending
            </v-tab>
            <v-tab>
                Completed
            </v-tab>

            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="get_reminders">
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="get_completed_reminders">
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>        
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        tab: null,
        headers: [{
            'text': 'Reminders',
            'value': 'todo'
        }]
    }),
    props: {
        project: Object,
        user_id: Number
    },
    computed: {
        ...mapGetters({
            get_completed_reminders:    'home/get_completed_reminders_getter',
            get_reminders:              'home/get_reminders_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_reminders:        'home/fetch_reminders_action'
        })
    },
    async mounted() {
        console.log('Fetch Reminders...');
        await this.fetch_reminders({
            project_id: this.project.id,
            user_id: this.user_id
        });
    },
    watch: {
        project() {
            /** Pull the record in the database */            
            this.fetch_reminders({
                project_id: this.project.id,
                user_id: this.user_id
            });
        }
    }
}
</script>