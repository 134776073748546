<template>
  <v-container fluid class="mt-5 flex">
    <div v-if="getuser.project_id == null" align="center">
      <strong>You need to create a project</strong>
      <p>Kindly click the Proceed button below to create a project, and we can continue to add entries</p>
      <v-btn 
          color="#0b4ef3" 
          dark 
          class="pl-16 pr-16" 
          rounded
          @click="$router.push({name: 'projectplanner'})"
      >
          Proceed
      </v-btn>
    </div>
    <div v-else>
      <v-card>
        <h5>In BETA Testing</h5>
        <v-card-title style="background: #062a82;">
          <v-icon class="mr-2" style="color: white;">{{supplierrequest.icon}}</v-icon>
          <small style="color: white;">{{supplierrequest.title}}</small>
          <v-spacer></v-spacer>
          <v-btn
            :class="supplierrequest.button.class"
            :dark="supplierrequest.button.dark"
            :text="supplierrequest.button.text"
            :color="supplierrequest.button.color"
            rounded
            @click="addsupplierrequest"
          >
            <v-icon :class="supplierrequest.button.iconclass">{{supplierrequest.button.icon}}</v-icon>New Request
          </v-btn>
        </v-card-title>
        <v-card-text>
          <DataTable
            class="mt-5 mb-5"
            :setup="supplierrequesttable"
            :items="getrequestdata"
            @show_attachment_supplier_request="show_attachment_supplier_request"
            @acceptquotation="acceptquotation"
            @delete_supplier_request_item="delete_supplier_request_item"
            @edit_supplier_request="edit_supplier_request"
          />
          <Dialog
            v-model="supplierrequestdialogboolean"
            :value="supplierrequestdialogboolean"
            :dialogvalue="supplierrequestdialog"
            :ddvalues="getservicetypes"
            :ddvalues2="build_stage"
            :ddvalues3="getcountry_list"
            :ddvalues4="getstate_list"
            :ddvalues5="getcity_list"
            @getStates="getStates"
            @getCities="getCities"
            @closedialog="closedialog"
            @send_supplier_request="send_supplier_request"
          />
        </v-card-text>
      </v-card>
    </div>
    <DialogWarning
      v-model="dialogwarning"
      :value="dialogwarning"
      :dialogsvalue="dialogwarningcontent"
      @delproject="delproject"
      @donothing="donothing"
    />
    <Dialog
      v-model="dialogimage"
      :value="dialogimage"
      :dialogvalue="dialogimagecontent"
      @closedialog="close_dialog_image"
    />
    <v-row justify="center" v-if="Object.keys(get_to_edit_quote).length != 0">
      <v-dialog
          v-model="model_edit_supplier_request"
          persistent
          max-width="690"
      >
        <v-card>
          <v-card-title style="fontSize: 14px;">
            <small>
              <v-icon class="mr-3" small>mdi-pen</v-icon>edit supplier request
            </small>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <div class="mt-3"></div>
            <label>attached media</label>
            <br>
            <div style="overflow-x:auto;overflow-y:hidden;height:auto;width:auto;white-space:nowrap">
              <div
                v-for="(attachment, index) in get_to_edit_quote.attachments"
                :key="index"
                style="display:inline-block;margin-right:20px;margin-bottom:20px;"
              >
                <div v-if="get_to_edit_quote.attachments[index].split('.').pop() == 'png' || get_to_edit_quote.attachments[index].split('.').pop() == 'jpg' || get_to_edit_quote.attachments[index].split('.').pop() == 'jpeg'">
                  <img 
                    style="max-width: 300px; max-height: 300px;"
                    :src="`${url}frontend/images/${(get_to_edit_quote.attachments[index])}`"
                  />
                </div>
                <div v-if="get_to_edit_quote.attachments[index].split('.').pop() == 'pdf'">
                  <img :src="`https://media.istockphoto.com/vectors/icon-major-file-format-vector-icon-illustration-vector-id1298834280?k=20&m=1298834280&s=612x612&w=0&h=SxEbyHKi18H1XpPpKKzoppWgMM3x0tc3veN5e6wl7Y8=`" style="max-width:125px; max-height:125px;"/>
                  <br><v-btn
                    outlined
                    class="elevation-3"
                    @click="download_attachment(get_to_edit_quote.attachments[index])"
                    style="display: inline-block;margin-right:5px;"
                  >
                    <v-icon class="mr-3">mdi-briefcase-download-outline</v-icon>
                    View
                  </v-btn>
                </div>
                <div v-if="get_to_edit_quote.attachments[index].split('.').pop() == 'docx'">
                  <img :src="`https://seeklogo.com/images/M/microsoft-word-logo-E648C182A5-seeklogo.com.png`" style="max-width:125px; max-height:125px;"/>
                  <br><v-btn
                    outlined
                    class="elevation-3"
                    @click="download_attachment(get_to_edit_quote.attachments[index])"
                    style="display: inline-block;margin-right:5px;"
                  >
                    <v-icon class="mr-3">mdi-briefcase-download-outline</v-icon>
                    View
                  </v-btn>
                </div>
                <div v-if="get_to_edit_quote.attachments[index].split('.').pop() == 'xlsx'">
                  <img :src="`https://seeklogo.com/images/E/excel-logo-974BFF9CB9-seeklogo.com.png`" style="max-width:125px; max-height:125px;"/>
                  <br><v-btn
                    outlined
                    class="elevation-3"
                    @click="download_attachment(get_to_edit_quote.attachments[index])"
                    style="display: inline-block;margin-right:5px;"
                  >
                    <v-icon class="mr-3">mdi-briefcase-download-outline</v-icon>
                    View
                  </v-btn>
                </div>
                
              </div>
            </div>
            <v-file-input
              chips
              outlined
              dense
              multiple
              label="attachment"
              v-model="entries.attachment"
              class="mt-3"
            ></v-file-input>
            <v-autocomplete
              v-model="entries.service_type"
              outlined
              small-chips
              dense
              :items="getservicetypes"
              item-text="service_type"
              item-value="id"
              :label="get_to_edit_quote.service.service_type"
              return-object
              prepend-icon="mdi-form-dropdown"
            >
            </v-autocomplete>
            <v-text-field
              v-model="entries.project_name"
              outlined
              dense
              :label="get_to_edit_quote.project_name"
              prepend-icon="mdi-tooltip-text-outline"
            >
            </v-text-field>
            <v-text-field
              v-model="entries.description"
              outlined
              dense
              :label="get_to_edit_quote.description"
              prepend-icon="mdi-tooltip-text-outline"
            >
            </v-text-field>
            <v-combobox
              v-model="get_to_edit_quote.descriptions"
              :search-input.sync="search"
              hide-selected
              label="scope of work"
              item-text="tags"
              multiple
              persistent-hint
              small-chips
              outlined
              dense
              prepend-icon="mdi-tooltip-text-outline"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
            <v-autocomplete
              v-model="entries.build_stage"
              outlined
              small-chips
              dense
              :items="build_stage"
              item-text="text"
              item-value="id"
              :label="get_to_edit_quote.build_stage"
              return-object
              prepend-icon="mdi-form-dropdown"
            >
            </v-autocomplete>
            <v-text-field
              v-model="entries.address"
              outlined
              dense
              :label="get_to_edit_quote.address_line"
              prepend-icon="mdi-tooltip-text-outline"
            >
            </v-text-field>
            <v-autocomplete
              v-model="entries.country"
              outlined
              small-chips
              dense
              :items="getcountry_list"
              item-text="country_name"
              item-value="country_name"
              :label="get_to_edit_quote.country"
              return-object
              prepend-icon="mdi-form-dropdown"
              @change="getStates(entries.country)"
            >
            </v-autocomplete>
            <v-autocomplete
              v-model="entries.state"
              outlined
              small-chips
              dense
              :items="getstate_list"
              item-text="state_name"
              item-value="state_name"
              :label="get_to_edit_quote.state"
              return-object
              prepend-icon="mdi-form-dropdown"
              @change="getCities(entries.state)"
            >
            </v-autocomplete>
            <v-autocomplete
              v-model="entries.city"
              outlined
              small-chips
              dense
              :items="getcity_list"
              item-text="city_name"
              item-value="city_name"
              :label="get_to_edit_quote.city"
              return-object
              prepend-icon="mdi-form-dropdown"
            >
            </v-autocomplete>
            <v-text-field
              v-model="entries.zipcode"
              outlined
              dense
              :label="get_to_edit_quote.postal_code"
              prepend-icon="mdi-tooltip-text-outline"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="closedialog"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              dark
              @click="s_edit_quotation"
              class="pl-12 pr-12"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <DialogWarning
      v-model="dialog_warning_change_quote"
      :value="dialog_warning_change_quote"
      :dialogsvalue="dialog_warning_change_quote_content"
      @delproject="go_edit_request"
      @donothing="donothing"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from '../../components/DataTable.vue'
import Dialog from '../../components/DialogComponent.vue'
import DialogWarning from '../../components/DialogWarning.vue'
export default {
  components: {
    DataTable,
    Dialog,
    DialogWarning
  },
  props: [
  ],
  data: () => ({
    to_delete_item: {},
    dialogwarning: false,
    dialogwarningcontent: {
      warningdelete: true,
      title: 'Are you sure you want to delete this supplier request?',
      content: 'All of the data that belongs to this supplier request will be deleted, are you sure about this?',
      buttons: [
        {
          id:         2,
          label:      'Yes',
          action:     true,
          class:      '',
          text:       true,
          dark:       false
        },
        {
          id:         1,
          label:      'No',
          action:     false,
          color:      '#0b4ef3',
          text:       false,
          class:      'pl-16 pr-16',
          dark:       true
        },
      ]
    },
    // quote changes
    dialog_warning_change_quote: false,
    dialog_warning_change_quote_content: {
      warningdelete: true,
      title: 'Are you sure you want to edit this supplier request?',
      content: 'All of the supplier that place quote on this quotation will be prompted this might change the prices, are you sure about this?',
      buttons: [
        {
          id:         2,
          label:      'Yes',
          action:     true,
          class:      '',
          text:       true,
          dark:       false
        },
        {
          id:         1,
          label:      'No',
          action:     false,
          color:      '#0b4ef3',
          text:       false,
          class:      'pl-16 pr-16',
          dark:       true
        },
      ]
    },
    // end quote changes
    dialogimage: false,
    dialogimagecontent: {
      showimage: true,
      item: {}
    },
    supplierrequest: {
      title: 'Supplier Request',
      icon:   'mdi-email-fast',
      button: {
        dark:       true,
        text:       false,
        icon:       'mdi-plus',
        iconclass:  'mr-2',
        class:      'pl-8 pr-8',
        color:      '#0B4EF3'
      }
    },
    supplierrequesttable: {
      supplierrequest: true,
      headers: [
        { text: 'Project Type', value: 'project_name', width: '15%' },
        { text: 'Status', value: 'Unquoted', width: '5%' },
        { text: 'Description', value: 'description', width: '15%' },
        //{ text: 'Assigned To', value: '' },
        { text: 'Date', value: 'created_at', width: '15%' },
        { text: 'Service Type', value: 'service_type', width: '15%' },
        { text: 'Build Stage', value: 'build_stage', width: '15%' },
        { text: 'Address', value: 'address_line', width: '15%' },
        { text: 'Attachments', width: '20%' },
        { text: 'Actions', width: '20%' },
      ]
    },
    build_stage: [
      {id: 1, text: 'No Planning Yet'},
      {id: 2, text: 'Just Looking'},
      {id: 3, text: 'Planning Approved'},
      {id: 4, text: 'Site Not Started'},
      {id: 5, text: 'Broke Soil On Site'},
      {id: 6, text: 'Foundation Poured'},
      {id: 7, text: 'House Sealed Roof On'}
    ],
    supplierrequestdialogboolean: false,
    supplierrequestdialog: {
      addsupplierrequest: true,
      title:        'add supplier request',
      icon:         'mdi-plus',
      iconclass:    'mr-2',
      items: [
        {
          id:         1,
          ttype:      'attachment',
          label:      'attachment',
          hint:       'attachment',
          icon:       'mdi-attachment',
        },
        {
          id:         2,
          ttype:      'dd',
          label:      'service type',
          hint:       'service type',
          icon:       'mdi-form-dropdown'
        },
        {
          id:         3,
          ttype:      'input',
          label:      'project type',
          hint:       'project type',
          icon:       'mdi-tooltip-text-outline',
          type:       'text'
        },
        {
          id:         4,
          ttype:      'input',
          label:      'description',
          hint:       'description',
          icon:       'mdi-tooltip-text-outline'
        },
        {
          id:         5,
          ttype:      'combobox',
          label:      'scope of work',
          hint:       'scope of work',
          icon:       'mdi-tooltip-text-outline'
        },
        {
          ttype:      'small'
        },
        {
          id:         6,
          ttype:      'dd2',
          label:      'build stage',
          hint:       'build stage',
          icon:       'mdi-form-dropdown'
        },
        {
          id:         7,
          ttype:      'input',
          label:      'address',
          hint:       'address',
          icon:       'mdi-tooltip-text-outline',
          type:       'text'
        },
        {
          id:         9,
          ttype:      'dd3',
          label:      'country',
          hint:       'country',
          icon:       'mdi-form-dropdown'
        },
        {
          id:         10,
          ttype:      'dd4',
          label:      'state/county',
          hint:       'state/county',
          icon:       'mdi-form-dropdown'
        },
        {
          id:         8,
          ttype:      'dd5',
          label:      'city/town',
          hint:       'city/town',
          icon:       'mdi-form-dropdown'
        },
        {
          id:         11,
          ttype:      'input',
          label:      'eircode | zipcode',
          hint:       'eircode | zipcode',
          icon:       'mdi-tooltip-text-outline',
          type:       'text'
        }
      ]
    },
    model_edit_supplier_request: false,
    entries: {
      attachment: [],
      service_type: null,
      project_name: null,
      description: null,
      address: null,
      county: null,
      city: null,
      zipcode: null
    },
    search: null,
    url: null
  }),
  mounted () {
  },
  created () {
    this.getrequests()
    this.url = process.env.VUE_APP_URL
  },
  computed: {
    ...mapGetters({
      getuser:                      'auth/getuser',
      getrequestdata:               'supplierrequest/getrequestdata',
      getservicetypes:              'supplierrequest/getservicetypes',
      getcountry_list:              'supplierrequest/getcountry_list',
      getstate_list:                'supplierrequest/getstate_list',
      getcity_list:                 'supplierrequest/getcity_list',
      getcities:                    'supplierrequest/getcities',
      get_to_edit_quote:            'supplierrequest/get_to_edit_quote'
    })
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    download_attachment(data){
      window.open(this.url + 'frontend/images/' + data)
    },
    async getrequests(){
      await this.$axios.get('supplierrequest/gRequests')
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('supplierrequest/setrequestdata', data.data)
        }
      })
      await this.$axios.get('misc/servicetypes')
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('supplierrequest/setservicetypes', data.data)
        }
      })
      await this.$axios.get('misc/getCountries')
      .then(({data}) => {
        console.log(data)
        this.$store.dispatch('supplierrequest/setcountry_list', data)
      })
    },
    async getStates($country) {
      await this.$axios.get('misc/getStates', $country.country_name)
      .then(({data}) => {
        console.log(data)
        this.$store.dispatch('supplierrequest/setstate_list', data)
      })
    },
    async getCities($state) {
      await this.$axios.get('misc/getCities', $state.state_name)
      .then(({data}) => {
        console.log(data)
        this.$store.dispatch('supplierrequest/setcity_list', data)
      })
    },
    addsupplierrequest(){
      this.supplierrequestdialogboolean = true
    },
    closedialog(){
      this.supplierrequestdialogboolean = false
      this.model_edit_supplier_request = false
      this.search = null
      this.entries.attachment = []
      this.entries.service_type = null
      this.entries.project_name = null
      this.entries.description = null
      this.entries.address = null
      this.entries.county = null
      this.entries.state = null
      this.entries.city = null
      this.entries.zipcode = null
    },
    async send_supplier_request(data){
      const formData = new FormData()
      for(let i = 0; i < data.textfield[0].attachments.length; i++){
        formData.append('attachments_' + i, data.textfield[0].attachments[i])
      }
      formData.append('attachment_count', data.textfield[0].attachments.length)
      //formData.append('file', data.textfield[0].attachments)
      formData.append('address', data.textfield[0].address)
      formData.append('build_stage', data.textfield[0].build_stage.text)
      formData.append('city', data.textfield[0].city)
      formData.append('description', data.textfield[0].description)
      formData.append('scope_of_work', data.textfield[0].scope_of_work)
      formData.append('postcode', data.textfield[0].postcode)
      formData.append('project_name', data.textfield[0].project_name)
      formData.append('service_type', data.textfield[0].service_type.id)
      formData.append('country', data.textfield[0].country.country_name)
      formData.append('state', data.textfield[0].state.state_name)
      formData.append('city', data.textfield[0].city.city_name)
      await this.$axios.post('supplierrequest/saveRequest', formData)
      .then(({data}) => {
        if(data.response){
          this.closedialog()
          this.getrequests()
          location.reload()
          return
        }else{
          this.showSnackBar(data.message)
          return
        }
      })
    },
    /*searchcounty(data){
      let to_pass_cities = []
      data.cities.forEach((q, i) => {
        to_pass_cities.push({
          id:   i + 1,
          name: q
        })
      })
      this.$store.commit('supplierrequest/setcities', to_pass_cities)
    },*/
    async acceptquotation(data){
      //console.log(data)
      await this.$axios.post('supplierrequest/acceptquote', data)
      .then(({data}) => {
        if(data.response){
          this.showSnackBar(data.message)
          this.getrequests()
        }
      })
    },
    delete_supplier_request_item(data){
      this.to_delete_item = data
      this.dialogwarning = true
    },
    donothing(){
      this.dialogwarning = false
      this.dialog_warning_change_quote = false
    },
    async delproject(){
      await this.$axios.delete(`/supplierrequest/delete_request/${this.to_delete_item.id}`)
      .then(({data}) => {
        if(data.response){
          this.donothing()
          this.showSnackBar(data.message)
          this.getrequests()
        }
      })
    },
    show_attachment_supplier_request(data){
      this.dialogimage = true
      this.dialogimagecontent.item = data
    },
    close_dialog_image(){
      this.dialogimage = false
    },
    edit_supplier_request(data){
      this.$store.dispatch('supplierrequest/set_to_edit_quote', data)
      this.model_edit_supplier_request = true
    },
    async s_edit_quotation(){
      this.dialog_warning_change_quote = true
    },
    async go_edit_request(){
      this.showSnackBar("Please wait...")
      const formData = new FormData()
      if (this.entries.attachment != null) {
        for (let i = 0; i < this.entries.attachment.length; i++) {
          formData.append('attachments_' + i, this.entries.attachment[i])
          console.log(this.entries.attachment[i])
        }
        formData.append('attachment_count', this.entries.attachment.length)
      }
      
      //formData.append('file', this.entries.attachment.length == 0 ? this.get_to_edit_quote.attachments : this.entries.attachment)
      formData.append('address', this.entries.address == null ? this.get_to_edit_quote.address_line : this.entries.address)
      formData.append('build_stage', this.get_to_edit_quote.build_stage)
      formData.append('description', this.entries.description == null ? this.get_to_edit_quote.description : this.entries.description)
      formData.append('scope_of_work', this.get_to_edit_quote.descriptions)
      formData.append('postcode', this.entries.zipcode == null ? this.get_to_edit_quote.postal_code : this.entries.zipcode)
      formData.append('project_name', this.entries.project_name == null ? this.get_to_edit_quote.project_name : this.entries.project_name)
      formData.append('service_type', this.entries.service_type == null ? this.get_to_edit_quote.service_type : this.entries.service_type.id)
      formData.append('country', this.entries.country == null ? this.get_to_edit_quote.country : this.entries.country.country_name)
      formData.append('state', this.entries.state == null ? this.get_to_edit_quote.state : this.entries.state.state_name)
      formData.append('city', this.entries.city == null ? this.get_to_edit_quote.city : this.entries.city.city_name)
      await this.$axios.post(`supplierrequest/editRequest/${this.get_to_edit_quote.id}`, formData)
      .then(({data}) => {
        if(data.response){
          this.showSnackBar(data.message)
          this.closedialog()
          this.donothing()
          this.getrequests()
          return
        }else{
          this.showSnackBar(data.message)
          return
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>