<template>
    <v-container fluid class="flex">
      <small>* The cost calculated below is an estimate and is intended only as a guide. Please check and verify all costs throughout your build with your engineer or QS. If you would like exact costs specific to your location and site conditions please contact www.selfbuild3d.com</small>
      <v-col
        cols="12"
      >
        <v-autocomplete
          v-model="chosentemplatecomputed"
          outlined
          small-chips
          dense
          :items="getbudgetmanagertemplates"
          item-text="template_name"
          item-value="id"
          label="choose a template"
          return-object
          prepend-inner-icon="mdi-form-dropdown"
          @change="chosentemplate">
          <template slot="item" slot-scope="{ item }">
            {{item.template_name}} <v-icon v-if="item.disabled" small>mdi-lock</v-icon>
          </template>
        </v-autocomplete>
      </v-col>
      <div v-if="getchosentemplate.id">
        <v-col
          cols="12">
          <v-btn
            v-for="(btn, btnindex) in costestimationsetup.buttons"
            :key="btnindex"
            :dark="btn.dark"
            :text="btn.text"
            :class="btn.class"
            :color="btn.color"
            @click="btn.action ? budgetbuild() : ''">
            <v-icon
              :class="btn.iconclass"
            >
              {{btn.icon}}
            </v-icon>
            {{btn.label}}
          </v-btn>
        </v-col>
        <v-col
          cols="12"
        >
          <strong>Build Project Budget: € {{getcostestimationdata[0].amount | formatNumber}}</strong>
        </v-col>
        <v-col
          cols="12"
        >
          <DataTable
            :setup="tab2datatablesetup"
            :items="getcostestimationdata"
            @editbudgetcostestimation="editbudgetcostestimation"
          />
        </v-col>
        <DialogComponent
          v-model="budgetbuildboolean"
          :value="budgetbuildboolean"
          :dialogvalue="budgetbuilddialog"
          @closedialog="closedialog"
          @saveprojectbuildbudget="saveprojectbuildbudget"
        />
      </div>     
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '../../../components/DataTable.vue'
import DialogComponent from '../../../components/DialogComponent.vue'
export default {
  components: {
    DataTable,
    DialogComponent
  },
  props: [
  ],
  data: () => ({
    costestimationsetup: {
      buttons: [{
        id:             1,
        label:          'edit project budget',
        dark:           true,
        text:           false,
        color:          '#0b4ef3',
        class:          'pl-8 pr-8',
        icon:           'mdi-pen',
        iconclass:      'mr-2',
        action:         true
      }]
    },
    tab2datatablesetup: {
      costestimation: true,
      headers: [
        { text: 'ID', value: 'index'},
        { text: 'Name', value: 'name' },
        { text: '% Budget', value: 'budget' },
        { text: 'Amount', value: 'result' },
        { text: 'Budget Amount', value: 'amount' },
      ]
    },
    budgetbuildboolean: false,
    budgetbuilddialog: {
      addbudgetcostestimation: true,
      label: 'edit build project budget',
      icon: 'mdi-pen',
      iconclass: 'mr-2',
      textfield: [
        {
          id:     1,
          label:  'amount',
          hint:   'amount',
          icon:   'mdi-tooltip-text-outline',
          type:   'number'
        }
      ]
    },
    chosentemplatecomputed: null
  }),
  mounted () {
    if ( this.getchosentemplate != null ) {
      this.chosentemplatecomputed = this.getchosentemplate;
    }
  },
  created () {
    this.chosentemplate()
  },
  computed: {
    ...mapGetters({
      getcostestimationdata:                'costestimation/getcostestimationdata',
      getproject:                           'auth/getproject',
      gettoeditbudget:                      'costestimation/gettoeditbudget',
      getbudgetmanagertemplates:            'budgetmanager/getbudgetmanagertemplates',
      getchosentemplate:                    'budgetmanager/getchosentemplate'
    }),
    /*chosentemplatecomputed: {
      get(){
        return this.$store.getters['budgetmanager/getchosentemplate']
      },
      set(value){
        this.$store.dispatch('budgetmanager/setchosentemplate', value)
      }
    },*/
  },
  methods: {
    ...mapActions({
      select_budgetmanager_template:          'budgetmanager/select_budgetmanager_template_action',
    }),
    async chosentemplate(){
      if ( this.chosentemplatecomputed != null) {
        await this.$axios.get(`budgetmanager/chosentemplate/${this.chosentemplatecomputed.id}/${this.getproject.id}`)
        .then(({data}) => {
          let tovuexdatatable = []
          let tovuexparentitem = {}
          tovuexparentitem = data.data[0]
          data.data.forEach((q, i) => {
            if(i > 0){
              tovuexdatatable.push({
                ...q
              })
            }
          })
          this.$store.dispatch('budgetmanager/setbudgetmanagertab1data', tovuexdatatable)
          this.$store.dispatch('budgetmanager/setparentbudgetmanagerdata', tovuexparentitem)
        })
      }
    },
    budgetbuild(){
      this.budgetbuildboolean = true
    },
    closedialog(){
      this.budgetbuildboolean = false
      this.editbudgetbuildboolean = false
    },
    async saveprojectbuildbudget(data){
      let tp = {
        data:               data.textfield[0].budget
      }
      await this.$axios.post(`costestimation/addBudgetCE/${this.getproject.id}`, tp)
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('costestimation/refetchcostestimatin', this.getproject)
          this.closedialog()
        }
      })
    },
    editbudgetcostestimation(){
      this.editbudgetbuildboolean = true
    },
    async saveadjustedbudgetcostestimation(data){
      let tp = {
        data: {
          budget:         data.textfield[0].budget,
          id:             this.gettoeditbudget.id
        }
      }
      await this.$axios.patch(`costestimation/updateBudgetCostEstimation/${this.getproject.id}`, tp)
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('costestimation/refetchcostestimatin', this.getproject)
          this.closedialog()
        }
      })
    }
  },
  watch: {
    getchosentemplate() {
      this.chosentemplatecomputed = this.getchosentemplate;
    },
    chosentemplatecomputed() {
      this.select_budgetmanager_template(this.chosentemplatecomputed);
    }
  }
}
</script>

<style scoped lang="scss">
</style>