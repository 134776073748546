<template>
    <v-container fluid class="mt-5 flex">
        <div>
            <DataTable 
                :setup="tablesetup" 
                :items="get_tips" 
                @addsuggestion="addsuggestion"
            />
            <DialogComponent 
                v-model="showdialogsuggestion"
                :value="showdialogsuggestion"
                :dialogvalue="dialogtipsvalue"
                @savesuggestion="savesuggestion"
                @closedialog="closedialog"
            />
        </div>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from './components/DataTable.vue'
import DialogComponent from './components/DialogComponent.vue'
export default {
    components: {
        DataTable,
        DialogComponent
    },
    props: [
    ],
    data: () => ({
        tablesetup: {
            name: 'Tips',
            icon: 'mdi-lightbulb',
            tableheader: [
                { text: 'Description', value: 'description' }
            ],
            button: [
                {
                    id: 1,
                    icon: 'mdi-plus',
                    label: 'suggestion box',
                    dark: true,
                    action: true,
                    text: false,
                    class: 'pl-16 pr-16',
                    color: '#0b4ef3',
                    classicon: 'mr-2'
                }
            ]
        },
        dialogtipsvalue: {
            tips: true,
            title: 'add suggestion',
            icon: 'mdi-lightbulb',
            textarea: [
                {
                    id: 1,
                    label: 'notes',
                    hint: 'notes',
                    icon: 'mdi-tooltip-text-outline'
                }
            ],
        },
        showdialogsuggestion: false,
        
    }),
    mounted() {
        this.fetchtips()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            getuser: 'auth/getuser',
            get_tips: 'tips/get_tips'
        }),
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        async fetchtips() {
            await this.$axios.get('admin/tips/g_tips')
            .then(({data}) => {
                console.log(data)
                if (data.response){
                    this.$store.dispatch('tips/set_tips', data.data)
                }
            })
        },
        addsuggestion() {
            this.showdialogsuggestion = true
        },
        closedialog() {
            this.showdialogsuggestion = false
        },
        async savesuggestion(data) {
            await this.$axios.post('admin/tips/add_tip', { user_id: this.getuser.id, description: data, accepted: false })
                .then(() => {
                    this.showSnackBar('Suggestion submitted')
                    this.closedialog()
                    this.fetchtips()

                })
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">

</style>