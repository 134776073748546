<template>
  <v-container fluid class="mt-5 flex">
    <UnderConstruction/>
  </v-container>
</template>

<script>
import UnderConstruction from '../../components/UnderConstruction.vue'
export default {
  components: {
    UnderConstruction
  },
  props: [
  ],
  data: () => ({
  }),
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>