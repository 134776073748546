<template>
  <v-container fluid class="mt-5 flex">
    <small>* The templates below are intended as a guide only. 
      <!-- <GuidelineComponent :color="color" /> -->
    </small>
    <div v-if="getuser.project_id == null || is_add_new_project == true">
      <v-col cols="12" sm="12" lg="4" md="4">
        <DropDown
          :setup="dropdownsetup"
          :items="gettemplates"
          :value="gettopasstemplate"
          @selecttemplate="selecttemplate"
        />
      </v-col>
      <div v-if="getuser.info.access_id == 1 && getselectedtemplate.length != 0">
        <ListItem
          :setup="list_setup"
          :items="getselectedtemplate"
          :header="headersfreetier"
          :template="gettopasstemplate"
          @grabtemplate="grabtemplatefn"
          @cancelgrab="cancelgrab"
        />
      </div>
      <div v-if="((getuser.info.access_id === 2) || (getuser.info.access_id === 3) || (getuser.info.access_id === 4)) && getselectedtemplate.length != 0">
        <ListItem
          :setup="list_setup"
          :items="getselectedtemplate"
          :header="headersfreetier"
          :template="gettopasstemplate"
          @grabtemplate="grabtemplatefn"
          @cancelgrab="cancelgrab"
        />
      </div>
      <DialogComponent
        v-model="showdialogtemplatename"
        :value="showdialogtemplatename"
        :dialogvalue="dialogvalue"
        @closedialog="closedialog"
        @savedata="savedata"
      />
    </div>
    <div v-else>
      <div 
        v-if="getuser.info.access_id == 1"
      >
        <FreeTier />
      </div>
      <div v-else>
        <PaidTier @is_add_project="add_new_project($event)"/>
      </div>
    </div>
    <v-row justify="center">
      <v-dialog
        v-model="is_loading"
        persistent
        max-width="290"
      >
        <v-card>
        <v-card-title>
          <small>Please wait.....</small>
        </v-card-title>
        <v-card-text class="text-center">
          <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
      </v-card>
      </v-dialog>
    </v-row>
    
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DropDown from '../../components/DropDown.vue'
import ListItem from '../../components/ListItems.vue'
import DialogComponent from '../../components/DialogComponent.vue'
import FreeTier from './FreeTier.vue'
import PaidTier from './PaidTier.vue'
// import GuidelineComponent from '@/components/GuidelineComponent.vue'

export default {
  components: {
    DropDown,
    ListItem,
    DialogComponent,
    FreeTier,
    PaidTier,
    // GuidelineComponent
  },
  props: [
  ],
  data: () => ({
    dropdownsetup: {
      pp: true
    },
    headersfreetier: [
      { text: 'Task Name', filterable: true, value: 'task_name', class: 'headerColorToBlue '},
      { text: 'Duration', filterable: true, value: 'duration'},
      { text: 'Status', filterable: true, value: 'status'},
      { text: 'Start', filterable: true, value: 'start'},
      { text: 'Finish', filterable: true, value: 'finish'},
      { text: 'Notes', filterable: true, value: 'notes'},
      // { text: 'Media', width: '15%', filterable: false}
    ],
    list_setup: {
      pp: true,
      buttons: [
        {
          id:       1,
          action:   false,
          label:    'Cancel',
          text:     true,
          dark:     false,
          color:    '',
          class:    '',
        },
        {
          id:       2,
          action:   true,
          label:    'Grab Template',
          text:     false,
          dark:     true,
          color:    '#0b4ef3',
          class:    'pl-10 pr-10',
        }
      ]
    },
    dialogvalue: {
      modal: false,
      reminder: true,
      title: 'Template Name',
      icon: 'mdi-reminder',
      textfield: [
        {
          id:     1,
          label:  'Template Name',
          hint:   'Template Name',
          icon:   'mdi-tooltip-text-outline',
          type:   'text'
        }
      ]
    },
    showdialogtemplatename: false,
    color: 'black',
    is_loading: false
  }),
  mounted () {
    if (this.getproject == null) {
      this.is_add_new_project = true;
    }
  },
  created () {
    if(localStorage.getItem('token') === null){
      this.$router.push({name: 'login'})
    }
    this.fetchtemplates()
    this.fetchproject()
  },
  computed: {
    ...mapGetters({
      getuser:              'auth/getuser',
      gettemplates:         'pp/getTemplates',
      getselectedtemplate:  'pp/getSelectedTemplate',
      gettopasstemplate:    'pp/getToPassTemplate',
      getproject:           'auth/getproject',
      project:              'pp/getproject',
      parent_data:          'pp/getparentdata'
    })
  },
  methods: {
    ...mapActions({
      set_newly_added_project: 'auth/set_newly_added_project_action'
    }),
    async fetchproject(){
      await this.$axios.get(`projectplanner/getThisTemplate/${this.getproject.template_name}`)
      .then(({data}) => {
        this.$store.dispatch('pp/setproject', data.data)
      })
    },
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async fetchtemplates(){
      await this.$axios.get('projectplanner/getSystemTemplates')
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('pp/setTemplates', data.data)
        }else{
          this.$router.push({name: 'login'})
        }
      })
    },
    async selecttemplate(data){
      this.$store.dispatch('pp/setToPassTemplate', data)
      await this.$axios.get('projectplanner/gtsystemp', data)
      .then(({data}) => {
        if(this.getuser.info.access_id == 1){
          if(data.data.length > 0){
            data.data.forEach((q, i) => {
              if(i >= 1){
                q.disable = true
              }
            })
          }else{
            data.data.push({
              completion: null,
              disable: false,
              duration: null,
              finish: null,
              image: null,
              is_template: 1,
              notes: null,
              predecessor: null,
              start: null,
              status: 'Pending',
              task_name: 'Blank Template',
              who: null
            })
          }
        }
        this.$store.dispatch('pp/setSelectedTemplate', data.data)
      })
    },
    async grabtemplatefn(){
      if(this.getuser.info.access_id == 1){
        if(this.gettopasstemplate.template_name == 'Blank Template'){
          this.showdialogtemplatename = true
          return
        }else{
          this.showSnackBar('Please update to Paid / Premium User to use this template: ' + this.gettopasstemplate.template_name)
          return
        }
      }else{
        this.showdialogtemplatename = true
        return
      }
    },
    async savedata(template_name){
      this.is_loading = true
      let tp = {
        template_name:    template_name.input[1],
        template_data:    this.gettopasstemplate
      }
      await this.$axios.post('projectplanner/grabThisTemplate', tp)
      .then(({data}) => {
        if(data.response){
          this.$store.commit('auth/updateProjectId', data.data)          
          this.set_newly_added_project(data.data);
          this.is_add_new_project = false;
          this.fetchproject()
          this.closedialog()
          this.is_loading = false
        }
      })
    },
    closedialog(){
      this.showdialogtemplatename = false
    },
    cancelgrab(){
      this.$store.commit('pp/clearselectedandtopass')
      this.is_add_new_project = false;
    },
    add_new_project(is_add_project) {
      //console.log(is_add_project);
      this.is_add_new_project = is_add_project;
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>