<template>
    <v-row justify="center">
        <v-dialog
            v-model="edit_sitediary_dialog"
            persistent
            scrollable
            max-width="690">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-notebook</v-icon><small>Edit Site Diary: {{ site_diary.log_name }}</small>
                    <v-spacer></v-spacer>
                    <small>
                        <v-btn text small color="red"
                            @click="open_delete_sitediary_dialog()">
                            <v-icon>mdi-delete</v-icon> DELETE
                        </v-btn>
                    </small>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation>
                        <div class="mt-5">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="site_diary.site_diary_date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="site_diary.site_diary_date"
                                        label="site date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        dense
                                        outlined
                                        v-bind="attrs"
                                        v-on="on"></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="site_diary.site_diary_date"
                                    no-title
                                    scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(site_diary.site_diary_date)">
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </div>
                        <div class="mt-5">
                            <v-text-field
                                label="Log Name"
                                outlined
                                prepend-icon="mdi-tooltip-text-outline"
                                hint="Log Name"
                                dense
                                required
                                :rules="required_rules.log_name"
                                v-model="site_diary.log_name"
                                type="text">
                            </v-text-field>
                        </div>
                        <div class="mt-5">
                            <v-combobox
                                v-model="site_diary.tags"
                                :items="gettags"
                                :search-input.sync="search"
                                hide-selected
                                label="Add Some Tags"
                                item-text="tags"
                                multiple
                                persistent-hint
                                small-chips
                                outlined
                                dense
                                prepend-icon="mdi-tooltip-text-outline">
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-combobox>
                        </div>
                        <div class="mt-5">
                            <v-row justify="center" v-if="site_diary.image">
                                <v-col cols="12">
                                    <v-checkbox
                                        v-model="remove_image"
                                        label="Remove Image"
                                        ></v-checkbox>
                                </v-col>
                                <v-col cols="12">
                                    <v-img
                                        :src="set_sitediary_image(site_diary.image)">
                                    </v-img>
                                </v-col>                       
                            </v-row>
                            <v-file-input                            
                                v-model="image"
                                small-chips
                                dense
                                outlined
                                class="mt-5"                            
                                label="Replace Image">
                            </v-file-input>
                        </div>
                        <div class="mt-5">
                            <v-textarea
                                outlined
                                dense
                                v-model="site_diary.notes"
                                prepend-icon="mdi-tooltip-text-outline"
                                label="Notes"
                                required
                                :rules="required_rules.notes">
                            </v-textarea>
                        </div>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="closedialog()">
                    Close
                </v-btn>
                <v-btn
                    color="#093cba"
                    dark
                    @click="savesitediary()"
                    class="pl-16 pr-16">
                    Save
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Confirm Dialog Box -->
        <v-row justify="center">
            <v-dialog
                max-width="420"
                v-model="delete_confirm_dialog"
                persistent>
                <v-card>
                    <v-card-title style="fontSize: 18px;">
                        <small>Are you sure you want to delete this entry?</small>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <small>All of the data that belongs to this entry will be deleted. Are you sure you want to delete this entry?</small>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text class="pl-16 pr-16" color="success" @click="delete_sitediary_data()">Yes</v-btn>
                        <v-btn color="#093cba" class="pl-16 pr-16" dark @click="delete_confirm_dialog = false">No</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- ./Confirm Dialog Box -->
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data: () => ({
        delete_confirm_dialog: false,
        edit_sitediary_dialog: false,
        search: null,
        menu: null,
        site_diary: {},
        APP_PATH_URL: process.env.VUE_APP_URL,
        image: null,
        valid: true,
        remove_image: false,
        required_rules: {
            log_name: [v => !!v || 'The log name is a required field!'],            
            notes: [v => !!v || 'The Notes are a required field!']
        },
    }),
    props: {
        show_edit_sitediary_dialog: Boolean,
        item: Object        
    },
    computed: {
        ...mapGetters({
            gettags:                'sitediary/gettags'
        })
    },
    methods: {
        ...mapActions({
            delete_sitediary:       'sitediary/delete_sitediary_action',
            update_sitediary:       'sitediary/update_sitediary_action'
        }),
        closedialog() {
            this.$refs.form.resetValidation();
            this.remove_image = false;
            this.image = null;
            this.$emit('closedialog');
        },
        savesitediary() {
            /** Update site diary data */
            this.valid = this.$refs.form.validate();
            console.log(this.valid);
            if (this.valid == true) {
                const formData = new FormData()
                formData.append('date', this.site_diary.site_diary_date)
                formData.append('logname', this.site_diary.log_name)
                formData.append('tags', this.site_diary.tags)
                formData.append('file', this.image)
                formData.append('notes', this.site_diary.notes)
                formData.append('remove_image', this.remove_image)
                this.update_sitediary({
                    item: this.site_diary,
                    formData: formData
                });

                this.closedialog();
            }
        },
        set_sitediary_image(image) {
            return `${this.APP_PATH_URL}frontend/images/${image}`;
        },
        open_delete_sitediary_dialog() {
            this.delete_confirm_dialog = true;
        },
        async delete_sitediary_data() {            
            await this.delete_sitediary({
                item: this.item
            });
            this.delete_confirm_dialog = false;
            this.closedialog();
        }
    },
    created() {
        
    },
    watch: {
        show_edit_sitediary_dialog() {
            if ( this.show_edit_sitediary_dialog == true ) {
                this.edit_sitediary_dialog = true;                
                this.site_diary.created_at = this.item.created_at;
                this.site_diary.deleted_at = this.item.deleted_at;
                this.site_diary.id = this.item.id;
                this.site_diary.image = this.item.image;
                this.site_diary.log_name = this.item.log_name;
                this.site_diary.project_id = this.item.project_id;
                this.site_diary.site_diary_date = this.item.site_diary_date;
                this.site_diary.tags = this.item.tags;
                this.site_diary.updated_at = this.item.updated_at;
                this.site_diary.users_id = this.item.users_id;
                this.site_diary.notes = this.item.notes;
            } else {
                this.edit_sitediary_dialog = false;
            }
        }
    }
}

</script>