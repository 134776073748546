<template>
  <v-container fluid class="mt-5 flex">
    <v-tabs
      v-model="tab"
      grow
      class="mb-5"
    >
    <v-tabs-slider></v-tabs-slider>
      <v-tab
        v-for="(tab, tabindex) in tabdata"
        :key="tabindex"
        :href="tab.href"
      >
        {{tab.label}}
      </v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item
          value="tab-1"
        >
          <v-row>
            <v-col
              cols="4"
            >
              <v-card>
                <v-card-title style="fontSize: 14px;">
                  <v-icon class="mr-3">mdi-chat</v-icon>Chats
                </v-card-title>
                <v-card-subtitle>
                  <v-text-field
                    filled
                    dense
                    prepend-inner-icon="mdi-magnify"
                    placeholder="search here..."
                    v-model="search"
                  ></v-text-field>
                </v-card-subtitle>
                <v-card-text>
                  <v-list dense>
                    <v-list-item-group
                      color="primary"
                    >
                      <v-list-item
                        v-for="(item, i) in get_conversation"
                        :key="i"
                        @click="get_messages(item)"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-comment-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon small class="mr-1" v-if="item.is_read == 0">mdi-message-badge</v-icon>
                            <label>
                              <strong v-if="item.is_read == 0">
                                {{item.client.name}}
                              </strong>
                              <label v-else>
                                {{item.client.name}}
                              </label>
                            </label> | 
                            <label>
                              <strong v-if="item.is_read == 0">
                                {{item.request.project_name}}
                                <small 
                                  style="display: block;"
                                  v-if="item.request.deleted_at != null"
                                >
                                  <v-icon 
                                    class="mr-1"
                                    small
                                  >
                                    mdi-alert-box
                                  </v-icon>
                                  project deleted
                                </small>
                              </strong>
                              <label v-else>
                                {{item.request.project_name}}
                                <small 
                                  style="display: block;" 
                                  v-if="item.request.deleted_at != null"
                                >
                                  <v-icon
                                    class="mr-1"
                                    small
                                  >
                                    mdi-alert-box
                                  </v-icon>
                                  project deleted
                                </small>
                              </label>
                            </label>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-if="Object.keys(get_convo).length != 0"
              cols="8"
              style="height: 560px; overflow: scroll;"
            >
              <v-row
                no-gutters
              >
                <v-container
                  v-for="(item_chat, item_chat_index) in get_convo"
                  :key="item_chat_index"
                >
                  <v-card
                    v-if="item_chat.from_supplier === 1"
                    color="blue darken-4"
                    style="float: right;"
                    class="rounded-tl-0"
                  >
                    <v-card-text>
                      <small class="white--text">you</small>
                      <h4 class="white--text" style="display:block;">{{item_chat.message}}</h4>
                      <small class="white--text" style="display:block;">{{item_chat.created_at}}</small>
                    </v-card-text>
                  </v-card>
                  <v-card
                    v-if="item_chat.from_client === 1"
                    color="blue darken-2"
                    style="float: left;"
                    class="rounded-tr-0"
                  >
                    <v-card-text>
                      <small class="white--text">client</small>
                      <h4 class="white--text">{{item_chat.message}}</h4>
                      <small class="white--text">{{item_chat.created_at }}</small>
                    </v-card-text>
                  </v-card>
                </v-container>
                <v-container>
                  <v-row
                    class="pa-2"
                  >
                    <v-text-field
                      rounded
                      v-model="message"
                      placeholder="Reply Here..."
                      outlined
                      append-icon="send"
                      @click:append="toggleSend"
                      @keypress.enter="toggleSend"
                    ></v-text-field>
                  </v-row>
                </v-container>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item
          value="tab-2"
        >
          <v-row>
            <v-col
              cols="4"
            >
              <v-card>
                <v-card-title style="fontSize: 14px;">
                  <v-icon class="mr-3">mdi-chat</v-icon>Chats
                </v-card-title>
                <v-card-subtitle>
                  <v-text-field
                    filled
                    dense
                    prepend-inner-icon="mdi-magnify"
                    placeholder="search here..."
                    v-model="search_supplier_directory"
                  ></v-text-field>
                </v-card-subtitle>
                <v-card-text>
                  <v-list dense>
                    <v-list-item-group
                      color="primary"
                    >
                      <v-list-item
                        v-for="(item, i) in get_conversation_supplier_directory"
                        :key="i"
                        @click="get_messages_supplier_directory(item)"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-comment-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon small class="mr-1" v-if="item.is_read == 0">mdi-message-badge</v-icon>
                            <label>
                              <strong v-if="item.is_read == 0">
                                {{item.client.name}}
                              </strong>
                              <label v-else>
                                {{item.client.name}}
                              </label>
                            </label>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-if="Object.keys(get_convo_supplier_directory).length != 0"
              cols="8"
              style="height: 560px; overflow: scroll;"
            >
              <v-row
                no-gutters
              >
                <v-container
                  v-for="(item_chat, item_chat_index) in get_convo_supplier_directory"
                  :key="item_chat_index"
                >
                  <v-card
                    v-if="item_chat.from_supplier === 1"
                    color="blue darken-4"
                    style="float: right;"
                    class="rounded-tl-0"
                  >
                    <v-card-text>
                      <small class="white--text">you</small>
                      <h4 class="white--text" style="display:block;">{{item_chat.message}}</h4>
                      <small class="white--text" style="display:block;">{{item_chat.created_at}}</small>
                    </v-card-text>
                  </v-card>
                  <v-card
                    v-if="item_chat.from_client === 1"
                    color="blue darken-2"
                    style="float: left;"
                    class="rounded-tr-0"
                  >
                    <v-card-text>
                      <small class="white--text">client</small>
                      <h4 class="white--text">{{item_chat.message}}</h4>
                      <small class="white--text">{{item_chat.created_at }}</small>
                    </v-card-text>
                  </v-card>
                </v-container>
                <v-container>
                  <v-row
                    class="pa-2"
                  >
                    <v-text-field
                      rounded
                      v-model="message_supplier_directory"
                      placeholder="Reply Here..."
                      outlined
                      append-icon="send"
                      @click:append="toggleSendSupplierDirectory"
                      @keypress.enter="toggleSendSupplierDirectory"
                    ></v-text-field>
                  </v-row>
                </v-container>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item
          value="tab-3"
        >
          <v-row>
            <v-col
              cols="4"
            >
              <v-card>
                <v-card-title style="fontSize: 14px;">
                  <v-icon class="mr-3">mdi-chat</v-icon>Chats Upload Plan
                </v-card-title>
                <v-card-subtitle>
                  <v-text-field
                    filled
                    dense
                    prepend-inner-icon="mdi-magnify"
                    placeholder="search here..."
                    v-model="search_upload_plan"
                  ></v-text-field>
                </v-card-subtitle>
                <v-card-text>
                  <v-list dense>
                    <v-list-item-group
                      color="primary"
                    >
                      <v-list-item
                        v-for="(item, i) in get_convo_upload_plan"
                        :key="i"
                        @click="get_messages_upload_plan(item)"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-comment-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon small class="mr-1" v-if="item.is_read == 0">mdi-message-badge</v-icon>
                            <label>
                              <strong v-if="item.is_read == 0">
                                {{item.get_client.name}}
                              </strong>
                              <label v-else>
                                {{item.get_client.name}}
                              </label>
                            </label> |
                            <label>
                              <strong v-if="item.is_read == 0">
                                {{item.get_plan.name}}
                                <small 
                                  style="display: block;"
                                  v-if="item.get_plan.deleted_at != null"
                                >
                                  <v-icon 
                                    class="mr-1"
                                    small
                                  >
                                    mdi-alert-box
                                  </v-icon>
                                  project deleted
                                </small>
                              </strong>
                              <label v-else>
                                {{item.get_plan.name}}
                                <small 
                                  style="display: block;"
                                  v-if="item.get_plan.deleted_at != null"
                                >
                                  <v-icon
                                    class="mr-1"
                                    small
                                  >
                                    mdi-alert-box
                                  </v-icon>
                                  project deleted
                                </small>
                              </label>
                            </label>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="8"
            >
              <v-row>
                <v-container
                  v-for="(item_chat, item_chat_index) in get_conversation_upload_plan"
                  :key="item_chat_index"
                >
                  <v-card
                    v-if="item_chat.is_supplier === 1"
                    color="blue darken-4"
                    style="float: right;"
                    class="rounded-tr-0"
                  >
                    <v-card-text>
                      <small class="white--text">you</small>
                      <h4 class="white--text">{{item_chat.supplier_message}}</h4>
                      <small class="white--text">{{item_chat.created_at}}</small>
                    </v-card-text>
                  </v-card>
                  <v-card
                    v-if="item_chat.is_client === 1"
                    color="blue darken-2"
                    style="float: left;"
                    class="rounded-tl-0"
                  >
                    <v-card-text>
                      <small class="white--text">client</small>
                      <h4 class="white--text" style="display:block;">{{item_chat.client_message}}</h4>
                      <small class="white--text" style="display:block;">{{item_chat.created_at}}</small>
                    </v-card-text>
                  </v-card>
                </v-container>
                <v-container>
                  <v-row
                    class="pa-2"
                  >
                    <v-text-field
                      rounded
                      v-model="message_upload"
                      placeholder="Reply Here..."
                      outlined
                      append-icon="send"
                      @click:append="send_upload_plan_message"
                      @keypress.enter="send_upload_plan_message"
                    ></v-text-field>
                  </v-row>
                </v-container>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item
          value="tab-4"
        >
          <ServiceSupplier/>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ServiceSupplier from './modules/ServiceSupplier.vue'
export default {
  components: {
    ServiceSupplier
  },
  props: [
  ],
  data: () => ({
    message: null,
    message_supplier_directory: null,
    search: null,
    search_supplier_directory: null,
    tab: null,
    tabdata: [
      {
        label:        'Client Request',
        href:         '#tab-1'
      },
      {
        label:        'Supplier Directory',
        href:         '#tab-2'
      },
      {
        label:        'Plan Upload',
        href:         '#tab-3'
      },
      {
        label:        'Service / Supplier',
        href:         '#tab-4'
      }
    ],
    search_upload_plan: null,
    message_upload: null
  }),
  async mounted () {
    await this.$axios.get('/supplier/inbox/g_chat_upload_plans')
    .then(({data}) => {
      if(data.response){
        this.$store.dispatch('supplier_inbox/set_upload_plan', data.data)
      }
    })
    this.fchats()
    this.get_supplier_directory()
  },
  created () {
  },
  computed: {
    ...mapGetters({
      // get_conversation:                        'supplier_inbox/get_conversation',
      get_convo:                                  'supplier_inbox/get_convo',
      get_convo_supplier_directory:               'supplier_inbox/get_convo_supplier_directory',
      get_parent_convo:                           'supplier_inbox/get_parent_convo',
      get_parent_convo_supplier_directory:        'supplier_inbox/get_parent_convo_supplier_directory',
      get_conversation_upload_plan:               'supplier_inbox/get_upload_plan_conversation'
    }),
    get_conversation(){
      return this.$store.getters['supplier_inbox/get_conversation'](this.search)
    },
    get_conversation_supplier_directory(){
      return this.$store.getters['supplier_inbox/get_conversation_supplier_directory'](this.search_supplier_directory)
    },
    get_convo_upload_plan(){
      return this.$store.getters['supplier_inbox/get_upload_plan'](this.search_upload_plan)
    }
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async fchats(){
      await this.$axios.get('supplier/inbox/getChats')
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('supplier_inbox/set_conversation', data.data)
        }
      })
    },
    async get_supplier_directory(){
      await this.$axios.get('supplier/inbox/getChatsSupplierDirectory')
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('supplier_inbox/set_conversation_supplier_directory', data.data)
        }
      })
    },
    get_messages(data) {
      this.$store.dispatch('supplier_inbox/set_convo', data.get_convo)
      this.$store.dispatch('supplier_inbox/set_parent_convo', data)
    },
    get_messages_supplier_directory(data){
      this.$store.dispatch('supplier_inbox/set_convo_supplier_directory', data.get_convo)
      this.$store.dispatch('supplier_inbox/set_parent_convo_supplier_directory', data)
    },
    async toggleSend(){
      this.showSnackBar('Please wait...')
      await this.$axios.post('supplier/inbox/send_message', {chat_id: this.get_convo[0], message: this.message, parent_convo: this.get_parent_convo})
      .then(({data}) => {
        console.log(data)
        if(data.response){
          this.showSnackBar(data.message)
          this.message = null
          this.fchats()
          this.$store.dispatch('supplier_inbox/set_convo', data.data)
          return
        }else{
          this.showSnackBar(data.message)
          return
        }
      })
    },
    async toggleSendSupplierDirectory(){
      this.showSnackBar('Please wait...')
      await this.$axios.post('supplier/inbox/send_message_supplier_directory', {supplier_directory_id: this.get_convo_supplier_directory[0], message: this.message_supplier_directory, parent_convo: this.get_parent_convo_supplier_directory})
      .then(({data}) => {
        if(data.response){
          this.showSnackBar(data.message)
          this.message_supplier_directory = null
          this.get_supplier_directory()
          this.$store.dispatch('supplier_inbox/set_convo_supplier_directory', data.data)
          return
        }else{
          this.showSnackBar(data.message)
          return
        }
      })
    },
    async get_messages_upload_plan(data){
      await this.$axios.get(`supplier/inbox/g_conversation_upload_plan/${data.plan_id}/${data.client_id}`)
      .then(({data}) => {
        this.$store.dispatch('supplier_inbox/set_upload_plan_conversation', data.data)
      })
    },
    async send_upload_plan_message(){
      this.showSnackBar('Please Wait..')
      const data = this.get_conversation_upload_plan[0]
      const tp = {
        plan_id:    data.plan_id,
        client_id:  data.client_id,
        message:    this.message_upload
      }
      await this.$axios.post(`supplier/inbox/p_chat_upload_plans`, tp)
      .then(({data}) => {
        this.showSnackBar(data.message)
        this.get_messages_upload_plan(tp)
        this.message_upload = null
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>