import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import AuthState from '../modules/auth/states/authstate.js'
import HomeState from '../modules/home/states/home.js'
import ProjectPlannerState from '../modules/projectplanner/states/ppstate.js'
import SiteDiaryState from '../modules/sitediary/states/sitediary.js'
import FileManagerState from '../modules/filemanager/states/filemanager.js'
import DesignTracker from '../modules/designtracker/states/designtracker.js'
import Decisions from '../modules/decisions/states/decision.js'
import Snags from '../modules/snags/states/snag.js'
import BudgetManager from '../modules/budgetmanager/states/budgetmanager.js'
import CostEstimation from '../modules/budgetmanager/states/costestimation.js'
import BillOfMaterials from '../modules/budgetmanager/states/billofmaterials.js'
import SupplierDirectory from '../modules/supplierdirectory/states/supplierdirectory.js'
import SupplierRequest from '../modules/supplierrequest/states/supplierrequest.js'
import Inbox from '../modules/inbox/states/inbox.js'
import UploadPlanState from '../modules/uploadplans/states/upload.js'
import Tips from '../modules/tips/states/tips.js'
import HelperTraining from '../modules/helpertraining/states/index.js'
import TradeRateSuppliers from '../modules/traderatesuppliers/states/suppliers.js'
import VideoArchives from '../modules/video_archived/states/index.js'
import ProjectPlannerV2 from '../modules/project_planner_v2/states/index.js'
import Dashboard from '@/modules/home/states/dashboard.js';
import BudgetTransaction from '@/modules/budgetmanager/states/budgettransaction.js'
import Reminders from '@/modules/home/states/reminders.js'
import MessageBox from '@/components/messagebox/state/index.js'
import SiteDiaryStateV2 from '@/client/sitediary/states/index.js'

// Supplier Profile
import SupplierProfile from '../supplier/profile/states/supplierprofile.js'
import SupplierRequests from '../supplier/requests/states/requests.js'
import Currencies from '../supplier/profile/states/currencylist.js'
import SupplierCompanyProfile from '../supplier/company_profile/states/supplier_company_profiles.js'
import SupplierInbox from '../supplier/inbox/states/inbox.js'
import SupplierNotification from '../supplier/notification/states/notification.js'
import SupplierToBeQuoted from '../supplier/to_be_quoted/states/tobequoted.js'
import SupplierQuoted from '../supplier/supplier_quoted/states/quoted.js'
import SupplierApprovedQuotes from '../supplier/supplier_approved_quotes/states/supplier_approved_quotes.js'
import SupplierLostQuotes from '../supplier/supplier_lost_quotes/states/supplier_lost_quotes.js'
import SupplierMaterials from '../supplier/supplier_materials/states/index.js'
import ClientPlan from '../supplier/client_plans/states/clientplan.js'
import FormRequestS from '../supplier/form_requests/states/index.js'
import Suppliers from '@/client/budgetmanager/billofmaterials/state/suppliers.js'
import ClientQuotation from '../supplier/bom_quotation/state/client_quotation.js'

// End Supplier Profile

// SB3D
import SB3DState from '../sb3d/landing_page/states/sb3d.js'
// SB3D end

// Admin
import HomeComponent from '../admin/landing_page/states/home.js'
import SupplierComponent from '../admin/suppliers/states/supplier.js'
import ServiceTypes from '../admin/service_types/states/servicetype.js'
import SupplierReports from '../admin/supplier_reports/states/supplier_reports.js'
import UserState from '../admin/users/states/user.js'
import ProjectPlannerTempalte from '../admin/project_planner/states/project_planner.js'
import ServiceRequestReports from '../admin/service_request_reports/states/index.js'
import FormRequestState from '../admin/form_request/states/index.js'
import PlanUpload from '../admin/plan_upload/states/plan_upload.js'
import AdminTips from '../admin/tips/states/tips.js'
import AdminHelperTraining from '../admin/helper_training_files/states/index.js'
import AdminTradeRateSuppliers from '../admin/traderatesuppliers/states/suppliers.js'
import AdminVideoArchived from '../admin/video_archived/states/index.js'
import AdminAssetsUploader from '../admin/assets_uploader/states/index.js'
import AdminDesignTracker from '../admin/users/states/designtracker.js'
import AdminECommerce from '../admin/shop/states/index.js'
// End admin

// non auth
import NonAuthUploadPlan from '../modules/non_auth_upload_plan/states/non_auth_upload.js'

// shop
import ShopState from '../modules/shop/states/shop.js'

// Client Components
import AlertNotification from '@/client/components/states/alertnotification.js'

Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        auth:                               AuthState,
        home:                               HomeState,
        pp:                                 ProjectPlannerState,
        uploadplan:                         UploadPlanState,
        sitediary:                          SiteDiaryState,
        filemanager:                        FileManagerState,
        designtracker:                      DesignTracker,
        decisions:                          Decisions,
        snags:                              Snags,
        budgetmanager:                      BudgetManager,
        costestimation:                     CostEstimation,
        billofmaterials:                    BillOfMaterials,
        supplierdirectory:                  SupplierDirectory,
        supplierrequest:                    SupplierRequest,
        inbox:                              Inbox,
        helper_training:                    HelperTraining,
        video_archived:                     VideoArchives,
        project_planner_v2:                 ProjectPlannerV2,
        dashboard:                          Dashboard,
        budget_transaction:                 BudgetTransaction,
        reminders:                          Reminders,
        sitediaryv2:                        SiteDiaryStateV2,  
        // supplier
        supplierprofile:                    SupplierProfile,
        supplier_requests:                  SupplierRequests,
        currencies:                         Currencies,
        supplier_company_profile:           SupplierCompanyProfile,
        supplier_inbox:                     SupplierInbox,
        supplier_notification:              SupplierNotification,
        supplier_to_be_quoted:              SupplierToBeQuoted,
        supplier_quoted:                    SupplierQuoted,
        supplier_approved_quotes:           SupplierApprovedQuotes,
        supplier_lost_quotes:               SupplierLostQuotes,
        supplier_materials:                 SupplierMaterials,
        client_plan:                        ClientPlan,
        sb3d:                               SB3DState,
        supplier_form_request:              FormRequestS,
        suppliers:                          Suppliers,
        client_quotation:                   ClientQuotation,
        // admin
        admin_home:                         HomeComponent,
        admin_supplier:                     SupplierComponent,
        admin_service_types:                ServiceTypes,
        admin_users:                        UserState,
        admin_supplier_reports:             SupplierReports,
        admin_project_planner:              ProjectPlannerTempalte,
        admin_service_request:              ServiceRequestReports,
        admin_form_request:                 FormRequestState,
        admin_plan_upload:                  PlanUpload,
        admin_tips:                         AdminTips,
        tips:                               Tips,
        admin_helper_training:              AdminHelperTraining,
        admin_video_archived:               AdminVideoArchived,
        admin_assets_uploader:              AdminAssetsUploader,
        admin_designtracker:                AdminDesignTracker,
        admin_shop:                         AdminECommerce,
        // non auth
        non_auth_upload_plan:               NonAuthUploadPlan,
        trade_rate_suppliers:               TradeRateSuppliers,
        admin_trade_rate_suppliers:         AdminTradeRateSuppliers,
        messagebox:                         MessageBox,
        shop:                               ShopState,
        alert_notifications:                AlertNotification,
    },
    plugins: [createPersistedState()]
})

export default store