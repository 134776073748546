<template>    
    <div class="mt-5">
        <v-data-table
            :headers="headers"
            :items="getbillofmaterialsdata"
            class="elevation-1"
            mobile-breakpoint="0"
            disable-pagination
            hide-default-footer
            style="width: 100%;">
            <template v-slot:item="props">
                <tr style="cursor: pointer;">
                <td>{{props.item.index}}</td>
                <td>
                    <v-edit-dialog
                        :return-value.sync="props.item.name"
                        large
                        @save="evtUpdateName(props.item)">
                        <strong>{{props.item.name}}</strong>
                        <template v-slot:input>
                            <div class="mt-4 text-h6">
                                <v-icon class="mr-2">mdi-plus</v-icon>
                                <small>Edit Item Name</small>
                            </div>
                            <v-text-field
                            v-model="props.item.name"
                            label="Edit"
                            single-line
                            counter
                            autofocus
                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </td>
                <td @click="evtUploadImage(props.item)" class="py-3">                                    
                    <v-img
                        :src="getImagePath(props.item.image)"
                        width="150"
                        height="150"
                        contain                    
                        aspect-ratio="1"></v-img>
                </td>
                <td>
                    <v-edit-dialog
                        style="cursor: pointer;"
                        :return-value.sync="props.item.quantity"
                        large
                        @save="evtUpdateItem('quantity', props.item.quantity, props.item.id, props.item)">
                    <div style="cursor: pointer;">{{ props.item.quantity }}</div>
                    <template v-slot:input>
                        <div class="mt-4 text-h6">
                            Update Quantity
                        </div>
                        <v-text-field
                        v-model="props.item.quantity"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                        ></v-text-field>
                    </template>
                    </v-edit-dialog>
                </td>
                <td>{{props.item.unit}}</td>
                <td>
                    <small>Supplier Name: {{ props.item.supplier1name }}</small><br/>
                    <small>Supplier Link: </small><a :href="`${props.item.supplier1url}`" target="_blank"><small><strong>{{ props.item.supplier1url }}</strong></small></a><br/>
                    <small @click="evtUpdateSupplier(props.item)">
                        Unit Cost: € <strong>{{props.item.supplier1 | formatNumber}}</strong><br/>
                        Total Cost: € <strong>{{props.item.supplier1_tc | formatNumber}}</strong>
                    </small>
                </td>
                <td>
                    <small>Supplier Name: {{ props.item.supplier2name }}</small><br/>
                    <small>Supplier Link: </small><a :href="`${props.item.supplier2url}`" target="_blank"><small><strong>{{ props.item.supplier2url }}</strong></small></a><br/>
                    <small @click="evtUpdateSupplier(props.item)">
                    Unit Cost: € <strong>{{props.item.supplier2 | formatNumber}}</strong><br/>
                    Total Cost: € <strong>{{props.item.supplier2_tc | formatNumber}}</strong>
                    </small>
                </td>
                <td>
                    <small>Supplier Name: {{ props.item.supplier3name }}</small><br/>
                    <small>Supplier Link: </small><a :href="`${props.item.supplier3url}`" target="_blank"><small><strong>{{ props.item.supplier3url }}</strong></small></a><br/>
                    <small @click="evtUpdateSupplier(props.item)">
                    Unit Cost: € <strong>{{props.item.supplier3 | formatNumber}}</strong> <br/>
                    Total Cost: € <strong>{{props.item.supplier3_tc | formatNumber}}</strong>
                    </small>
                </td>
                <td><small>€ {{props.item.variation | formatNumber}}</small></td>
                </tr>
            </template>
            <template v-slot:footer>
                <div class="pb-5"></div>
            </template>
        </v-data-table>
        <AddUpdateSupplier
            :edit_supplier_dialog="edit_supplier_dialog"
            :material="bom_material"
            @evtCloseDialog="evtCloseSupplierDialog"></AddUpdateSupplier>
        
        <UploadImage
            :upload_image_dialog="upload_image_dialog"
            :bom_item="bom_item"
            @evtCloseDialog="evtCloseUploadImageDialog"></UploadImage>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BillOfMaterials from '../modules/BillOfMaterials.js'
import AddUpdateSupplier from './AddUpdateSupplier.vue';
import UploadImage from './UploadImage.vue';

export default {
    data: () => ({
        headers: [
            { text: 'ID', width: '5%' },
            { text: 'Name', width: '23%' },
            { text: 'Image', width: '10%', align: 'center' },
            { text: 'Qty', width: '5%' },
            { text: 'Unit', width: '5%' },
            { text: 'Supplier 1', width: '15%' },
            { text: 'Supplier 2', width: '15%' },
            { text: 'Supplier 3', width: '15%' },
            { text: 'Variance', width: '7%' }
        ],
        bill_of_materials: new BillOfMaterials(),
        edit_supplier_dialog: false,
        upload_image_dialog: false,
        bom_item: null,
        bom_material: null,
        image_path: `${process.env.VUE_APP_URL}uploads/bom`
    }),
    props: {
        choosen_material_template: Object
    },
    components: {
        AddUpdateSupplier,
        UploadImage
    },
    computed: {
        ...mapGetters({
            getbillofmaterialsdata:                   'billofmaterials/getbillofmaterialsdata',
        })
    },
    methods: {
        evtUpdateItem(label, value, id, props) {
            this.bill_of_materials.updateBoMItemQuantity(
                { id: id, label: label, props: props, value:value }, this.choosen_material_template
            )
        },
        evtUpdateSupplier(item) {            
            this.bom_material = item
            this.edit_supplier_dialog = true
        },
        evtCloseSupplierDialog() {
            this.bom_material = null
            this.edit_supplier_dialog = false
        },
        evtUpdateName(item) {
            this.bill_of_materials.updateBoMItemName({id: item.id, name: item.name})
        },
        evtUploadImage(item) {
            this.bom_item = item      
            this.upload_image_dialog = true
        },
        evtCloseUploadImageDialog() {
            this.upload_image_dialog = false
        },
        getImagePath(item) {
            return String(item).includes('blob:') == true ? item : `${this.image_path}/${item}`
        }
    },
    mounted() {
        this.bill_of_materials.setStore(this.$store)
    },
    watch: {
        
    }
}

</script>