<template>
  <div
    class="d-inline ml-3"
  >
    <v-dialog
      persistent
      v-model="tutorial_dialog"
      width="1000"
    >
      <template
        v-slot:activator="{ on, attrs }"
      >
        <v-icon
          :color="color"
          v-on="on"
          v-bind="attrs"
        >
          help
        </v-icon>
      </template>

      <v-card>
        <v-card-title>
          Tutorial
          <v-spacer></v-spacer>
          <v-icon
            small
            color="red"
            @click="tutorial_dialog = false;"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <!--<vuePDF 
          src="https://api.staging.selfbuildhelper.com/frontend/images/1676353401.pdf" 
          :page="page"
          @num-pages="pageCount = $event"
        />-->
        <v-card-actions>
          {{ page }} / {{ pageCount }}
          <v-spacer></v-spacer>
          <v-icon
            color="black"
            :disabled="page == 1"
            @click="page--"
          >
            mdi-arrow-left-bold
          </v-icon>
          <v-icon
            :disabled="page == pageCount"
            class="ml-2"
            color="black"
            @click="page++"
          >
            mdi-arrow-right-bold
          </v-icon>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import vuePDF from "vue-pdf";

export default {
  components: {
    //vuePDF
  },
  props: [
    'color'
  ],
  data: () => ({
    tutorial_dialog: false,
    page: 1,
    pageCount: null
  }),

  computed: {

  },

  methods: {
    
  },

  created() {

  },

  mounted() {

  }
}
</script>

<style scoped>

</style>