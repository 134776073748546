import Vue from 'vue'

const BudgetTransaction = () => {

    const updateBudgetTransactionItem = ({ name, amount, paymentMethod, transDate}, trans_id, store) => {
        Vue.axios.patch(`budgetmanager_v2/update_budgettransaction_item/${trans_id}`, {
            name: name,
            amount: amount,
            payment_method: paymentMethod,
            created_at: transDate
        }).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    // save update transaction
                    store.dispatch('budget_transaction/set_updated_transaction_item', result.data)
                    store.dispatch('budgetmanager/update_budgetmanageritem_action', result.data.budget_manager)
                } else {
                    console.log(result.data)
                }
            } else {
                console.log(data.message)
            }
        }).catch( (err) => {
            console.log(err)
        })
    }

    return {
        updateBudgetTransactionItem
    }
}

export default BudgetTransaction