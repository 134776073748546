<template>
    <v-container fluid>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                width="90%"
                elevation="0"
                class="mt-15"
            >
                <v-card-title>
                    <small><v-icon class="mr-3">mdi-information-outline</v-icon>Form Request Information</small>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="table_header"
                        :items="get_data"
                        hide-default-header
                    >
                        <template v-slot:header="{ props: { headers } }">
                            <thead>
                                <tr>
                                    <th v-for="(h, i) in headers" :key="i">
                                        <strong>{{h.text}}</strong>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:item="{ item, expand, isExpanded }" >
                            <tr
                                style="cursor:pointer;" 
                                @click="expand(!isExpanded)"
                            >
                                <td>
                                    <label
                                        v-if="item.get_client_information !== null"
                                    >
                                        {{ item.get_client_information.name }}
                                    </label>
                                    <label
                                        v-else
                                    >N/A</label>
                                </td>
                                <td>
                                    {{ item.build_route }} | {{item.build_route_other}}
                                </td>
                                <td>
                                    {{ item.build_type }} | {{item.build_type_other}}
                                </td>
                                <td>
                                    {{item.build_stage}}
                                </td>
                            </tr>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length" class="pa-15">
                                <v-textarea
                                    :label="item.get_client_information === null ? 'Send message to null' : `Send message to ${item.get_client_information.name}`"
                                    v-model="message"
                                >
                                    <template v-slot:append>
                                        <v-btn
                                            icon
                                            class="mt-15"
                                            @click="send_message(item)"
                                        >
                                            <v-icon>mdi-send</v-icon>
                                        </v-btn>
                                    </template>
                                </v-textarea>
                            </td>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>
  
<script>
  export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        table_header: [
            {
                text: 'Name'
            },
            {
                text: 'Build Route'
            },
            {
                text: 'Build Type'
            },
            {
                text: 'Build Stage'
            }
        ],
        search: null,
        tab: null,
        message: null
    }),
    async mounted () {
        await this.$store.dispatch('supplier_form_request/set_data')
    },
    created () {
    },
    computed: {
        get_data(){
            return this.$store.getters['supplier_form_request/get_data'](this.search)
        }
    },
    methods: {
        async send_message(data){
            if(data.get_client_information === null){
                this.showSnackBar('User does not exist!')
                this.message = null
                return
            }
            this.showSnackBar('Please wait...')
            let tp = {
                client_id:          data.client_id,
                supplier_id:        data.supplier_id,
                form_request_id:    data.id,
                message:            this.message,
                from_supplier:      1
            }
            await this.$axios.post('/supplier/from_form/send_message', tp)
            .then(({data}) => {
                if(data.response){
                    this.message = null
                    this.showSnackBar(data.message)
                    return
                }
                this.showSnackBar(data.message)
                return
            })
        },
        showSnackBar(message){
            this.$store.commit("auth/setMessage", 
            {show: true, message: message}, 
            {root: 1})
        },
    },
    watch: {
    }
  }
</script>
  
<style scoped lang="scss">
</style>