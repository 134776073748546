<template>
    <v-row justify="center">
        <v-dialog
            v-model="add_quotation_model"
            width="700">
            <v-card>
                <v-card-title>Add Quotation</v-card-title>
                <v-card-text class="mt-6">
                    <v-text-field
                    label="Quoted Unit Cost"
                    placeholder="Enter desired unit cost for quotation"
                    v-model="unit_cost"
                    type="number"
                    dense
                    outlined></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="evtCloseDialog()"
                        class="px-6">
                        <v-icon>mdi-close</v-icon>
                        Cancel
                    </v-btn>
                    <v-btn color="rgb(6, 42, 130)"
                        class="px-6 white--text"
                        @click="evtAddClientQuotedMaterial()">
                        <v-icon>mdi-plus</v-icon>
                        Add
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientQuotation from '../../modules/ClientQuotation.js'

export default {
    data: () => ({
        add_quotation_model: false,
        unit_cost: 0,
        client_quotation: null
    }),
    props: {
        add_quotation_dialog: Boolean,
        material: Object
    },
    computed: {
        ...mapGetters({
            getuser:    'auth/getuser',
        })
    },
    methods: {
        evtCloseDialog() {
            this.$emit('evtCloseDialog')
        },
        evtAddClientQuotedMaterial() {
            const data = {
                unit_cost: this.unit_cost,
                bom_material_id: this.material.bom_id,
                supplier_material_id: this.material.materials.id,
                supplier_id: this.getuser.company.id,
                users_id: this.material.client_id
            }
            this.client_quotation.createClientRequestQuotation(data)
            this.evtCloseDialog();
        }
    },
    created() {
        this.client_quotation = new ClientQuotation(this.$store)
    },
    watch: {
        add_quotation_dialog() {
            this.add_quotation_model = this.add_quotation_dialog
            if ( this.add_quotation_model == true ) {
                this.unit_cost = parseFloat(this.material.bom_unit_cost).toFixed(2)               
            }
        }
    }
}
</script>