<template>
    <v-container fluid>
        <v-card class="mt-5 flex">
            <v-card-title style="background: #062a82;">
                <v-icon class="mr-2" style="color: white;">mdi-toolbox</v-icon>
                <small style="color:white;">Client Material Quotation</small>
            </v-card-title>
            <v-card-text>
                <v-tabs
                    v-model="tab_quotation">
                    <v-tab href="#request_quotation">Request Material Quotation</v-tab>
                    <v-tab href="#quoted_quotation">Quoted Materials</v-tab>
                </v-tabs>
                <v-tabs-items
                    v-model="tab_quotation">
                    <v-tab-item value="request_quotation">
                        <RequestQuotation></RequestQuotation>
                    </v-tab-item>
                    <v-tab-item value="quoted_quotation">
                        <QuotedQuotation></QuotedQuotation>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import RequestQuotation from './components/RequestQuotation.vue'
import QuotedQuotation from  './components/QuotedQuotation.vue'

export default {
    data: () => ({
        tab_quotation: null
    }),
    components: {
        RequestQuotation,
        QuotedQuotation
    },
    mounted() {
        
    }
}
</script>