<template>
    <v-row justify="center">
        <v-dialog
            v-model="add_transaction_form_dialog"
            persistent
            max-width="650">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    Add Transaction
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-6">
                    <v-form
                        ref="addtrans_form"
                        v-model="valid"
                        lazy-validation>
                        <v-text-field
                            v-model="suppliers_name"
                            label="Supplier's Name"
                            hint="Supplier's Name"
                            prepend-icon="mdi-tooltip-text-outline"
                            dense
                            outlined
                            :rules="rules_form.suppliers_name"
                            type="text"
                            autofocus></v-text-field>
                        <v-text-field
                            v-model="amount"
                            label="Amount"
                            hint="Amount"
                            prepend-icon="mdi-tooltip-text-outline"
                            dense
                            outlined
                            type="number"
                            :rules="rules_form.amount"></v-text-field>
                        <v-select
                            v-model="payment_method"
                            :items="items"
                            prepend-icon="mdi-form-dropdown"
                            label="Payment Method"
                            hint="Payment Method"
                            dense
                            outlined
                            :rules="rules_form.payment_method">
                        </v-select>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date"
                                    label="Transaction Date"
                                    hint="Transaction Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    dense
                                    outlined                                    
                                ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            no-title
                            scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date)">
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="px-6" 
                        color="error"
                        text
                        @click="close_dialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>Close
                    </v-btn>
                    <v-btn class="px-6 white--text" color="rgb(11, 78, 243)"
                        @click="evt_save_transaction()">
                        <v-icon class="mr-2">mdi-content-save-outline</v-icon>Save
                    </v-btn>
                </v-card-actions>                
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        suppliers_name: null,
        amount: null,
        items: [
            { value: "Bank Transfer", text: 'Bank Transfer' },
            { value: "Cheque", text: 'Cheque'},
            { value: "Cash", text: 'Cash'},
            { value: "Credit / Debit Card", text: 'Credit / Debit Card'}
        ],
        payment_method: null,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        add_transaction_form_dialog: false,
        valid: true,
        rules_form: {
            suppliers_name: [v => !!v || `You are required to enter a Supplier's Name.`],
            amount: [v =>!!v || `You are required to enter an amount.`],
            payment_method: [v =>!!v || `You are required to select a payment method.`]
        }
    }),
    props: {
        open_add_transaction_formdialog: Boolean,
        bm_item: Object
    },
    computed: {
        ...mapGetters({
            getproject:                             'auth/getproject',
        })
    },
    methods: {
        ...mapActions({
            add_budgetmanager_transacton:       'budget_transaction/add_budgetmanager_transacton_action',
        }),
        close_dialog() {
            this.$refs.addtrans_form.resetValidation()
            this.$emit('close_dialog');
        },
        async evt_save_transaction() {
            this.valid = this.$refs.addtrans_form.validate();                       
            if (this.valid) {
                await this.add_budgetmanager_transacton({                    
                    users_id: this.bm_item.users_id,
                    project_id: this.getproject.id,
                    parent_id: this.bm_item.parent_id,
                    budget_manager_id: this.bm_item.id,
                    amount: this.amount,
                    name: this.suppliers_name,
                    payment_method: this.payment_method,
                    created_at: this.date
                });
                this.close_dialog();
            }
        }
    },
    watch: {
        open_add_transaction_formdialog() {
            this.add_transaction_form_dialog = this.open_add_transaction_formdialog;
            if ( typeof this.$refs.addtrans_form != 'undefined' ) {
                this.$refs.addtrans_form.reset()
                setTimeout(() => {
                    this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
                }, 100)
            }
        }
    }
}
</script>