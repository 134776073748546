import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        dt_data: [],
        projects: []
    },
    mutations: {
        set_dt_data_mutation(state, payload) {
            state.dt_data = payload
        },
        set_projects_mutation(state, payload) {
            state.projects = [...payload]
        }
    },
    getters: {
        get_fff_data_getter: state => {
            let fff_data = [];
            if ( state.dt_data['fff'] ) {
                state.dt_data['fff'].map( (item) => {
                    if ( item.admin_getchild.length > 0 ) {
                        item.admin_getchild.map( (child) => child.parent_id = item.room );
                        fff_data = [...fff_data, ...item.admin_getchild]
                    }
                });
            }
            return fff_data;
        },
        get_app_data_getter: state => {
            let app_data = []            
            state.dt_data['appliance'].map( (item) => {
                if ( item.admin_getchild.length > 0 ) {
                    item.admin_getchild.map( (child) => child.parent_id = item.room );
                    app_data = [...app_data, ...item.admin_getchild]
                }
            });            
            return app_data
        },
        get_fc_data_getter: state => {
            let fc_data = [];
            state.dt_data['fc'].map( (item) => {
                if ( item.admin_getchild.length > 0 ) {
                    item.admin_getchild.map( (child) => child.parent_id = item.location );
                    fc_data = [...fc_data, ...item.admin_getchild]
                }
            });
            return fc_data
        },
        get_projects_getter: state => state.projects
    },
    actions: {
        async fetch_designtracker_data_action(context, payload) {
            await Vue.axios.get(`admin/designtracker/designtracker_data/${payload.users_id}/${payload.project_id}`)
                .then( (data) => {
                    if ( data.status === 200 ) {
                        const result = data.data;
                        if ( result.response === 'success' ) {                            
                            context.commit('set_dt_data_mutation', result.data)
                        } else {
                            console.log(result.data);
                        }
                    } else {
                        console.log(data)
                    }
                })
        },
        async fetch_get_projects_action(context, payload) {
            await Vue.axios.get(`admin/designtracker/get_projects_by_usersid/${payload.users_id}`)
                .then( (data) => {
                    if ( data.status === 200 ) {
                        const result = data.data
                        if ( result.response === 'success' ) {
                            context.commit('set_projects_mutation', result.data)
                        } else {
                            console.log(result.data)
                        }
                    } else {
                        console.log(data)
                    }
                })
        }
    }
}