<template>
    <v-card
        flat
    >
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="get_cart"
                class="elevation-1"
            >
                <template v-slot:item="props">
                    <tr>
                        <td>
                            <label
                                style="font-weight: 500; font-size: 15px; font-family: 'Poppins', sans-serif;"
                            >
                                {{ props.item.name }}
                            </label>
                        </td>
                        <td>
                            <div
                                v-for="index in 1"
                                :key="index"
                            >
                                <v-img
                                    :max-width="300"
                                    :aspect-ratio="1"
                                    :src="`${image}${JSON.parse(props.item.image)[0]}`"
                                />
                            </div>
                        </td>
                        <td>
                            <label>
                                {{ props.item.qty }}
                            </label>
                        </td>
                        <td>
                            <label
                                style="font-weight: 500; font-size: 12px; font-family: 'Poppins', sans-serif;"
                            >
                                {{ props.item.created_at }}
                            </label>
                        </td>
                        <td>
                            <v-btn
                                icon
                                @click="editItemFn(props.item)"
                            >
                                <v-icon>mdi-pen</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="close_dialog()"
        >
            Close
        </v-btn>
        <v-btn
            color="green darken-1"
            outlined
            class="pl-10 pr-10"
            @click="check_out(get_cart)"
        >
            <!-- Submit for price, availability and Haulage Check -->
            Check Out
        </v-btn>
        </v-card-actions>
        <!-- edit dialog -->
        <v-dialog
            v-model="edit_item"
            persistent
            :width="isMobile() ? '70%' : '30%'"
        >
            <v-card>
                <v-card-title>
                    <label
                        style="font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 14px;"
                    >
                        <v-icon>
                            mdi-database-edit
                        </v-icon>
                        {{ get_edit_item.name }}
                    </label>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text
                    class="mt-5"
                >
                    <v-text-field
                        label="quantity"
                        v-model="get_edit_item.qty"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        @click="remove_item(get_edit_item)"
                        color="red"
                        class="pl-10 pr-10"
                        outlined
                    >
                        Remove item
                    </v-btn>
                    <v-btn
                        @click="update_qty(get_edit_item)"
                        color="primary"
                        class="pl-10 pr-10"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
    'headers',
    'get_cart',
    'get_user'
  ],
  data () {
    return {
        image: process.env.VUE_APP_URL,
        edit_item: false
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_edit_item:      'shop/get_edit_item'
    })
  },
  methods: {
    isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    },
    close_dialog(){
        this.$emit('close_dialog')
    },
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async check_out(data){
        this.showSnackBar("Please wait...")
        await this.$axios.post('shop/checkout', data)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.$store.commit('shop/clear_cart')
            }
        })
    },
    remove_item(item){
        item.users_id = this.get_user.id
        this.$store.dispatch('shop/remove_item', item)
        this.edit_item = false
    },
    async update_qty(item){
        await this.$store.dispatch('shop/update_qty', item)
        await this.$store.dispatch('shop/fetch_cart_api', this.get_user.id)
        this.edit_item = false
    },
    editItemFn(item){
        this.$store.dispatch('shop/set_edit_item', item)
        this.edit_item = true
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>