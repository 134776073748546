<template>
  <v-container fluid class="mt-5 flex">
    <v-layout align-center justify-center>
            <v-card
                class="mx-auto"
                outlined
                style="width: 500px;"
            >
                <v-toolbar dark color="#073095">
                <v-toolbar-title>Registration Form</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-text-field
                        prepend-icon="email"
                        v-model="register.email"
                        name="email"
                        :label="get_supplier_data.email"
                        :value="get_supplier_data.email"
                        disabled
                        type="email"
                    ></v-text-field>
                    <v-text-field
                        prepend-icon="lock"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        name="password"
                        label="password"
                        v-model="register.password"
                        class="input-group--focused"
                        @click:append="show2 = !show2"
                    ></v-text-field>
                    <v-text-field
                        prepend-icon="person"
                        v-model="register.name"
                        name="full name"
                        label="full name"
                        type="text"
                    ></v-text-field>
                    <v-text-field
                        prepend-icon="phone"
                        v-model="register.phone"
                        name="phone"
                        label="phone"
                        type="number"
                    ></v-text-field>
                    <v-text-field
                        prepend-icon="person"
                        v-model="register.company"
                        name="company"
                        :label="get_supplier_data.company_name != null ? get_supplier_data.company_name : 'company'"
                        :value="get_supplier_data.company_name != null ? get_supplier_data.company_name : ''"
                        type="text"
                    ></v-text-field>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#093cba" class="white--text pl-10 pr-10" @click="registerBtn">Register</v-btn>
                    </v-card-actions>
                </v-card-text>
                
            </v-card>
        </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    register: {
        name: '',
        username: '',
        email: '',
        phone: '',
        password: '',
        company: ''
    },
    show2: false,
  }),
  async mounted () {
    // console.log(this.$router.currentRoute.params.token)
    this.verifytoken()
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_supplier_data:                  'auth/get_supplier_data'
    })
  },
  methods: {
    async verifytoken(){
        await this.$axios.get(`supplier/get_profile/${this.$router.currentRoute.params.token}`)
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('auth/set_supplier_data', data.data)
            }
        })
    },
    async registerBtn(){
        await this.$axios.post('supplier/register_invited_supplier', {data: this.register, hash: this.$router.currentRoute.params.token})
        .then(({data}) => {
            if(data.response){
                this.register = {}
                this.$router.push({name: 'login'})
            }
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>