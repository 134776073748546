<template>
    <div>
        <!-- dialog for adding reminder -->
        <div v-if="dialogvalue.reminder">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    max-width="690"
                >
                <v-card>
                    <v-card-title>
                        <v-icon class="mr-2">{{dialogvalue.icon}}</v-icon><small>{{dialogvalue.title}}</small>
                        <v-spacer></v-spacer>
                        <div v-if="dialogvalue.button != null">
                            <v-icon
                                style="cursor: pointer;"
                                v-for="(btn, btnIndex) in dialogvalue.button"
                                :key="btnIndex"
                                @click="deletethis"
                            >
                                {{btn.icon}}
                            </v-icon>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <div
                            v-for="(item, index) in dialogvalue.textfield"
                            :key="index"
                            class="mt-5"
                        >
                            <v-text-field
                                :label="item.label"
                                outlined
                                :prepend-icon="item.icon"
                                :hint="item.hint"
                                class="px-5"
                                dense
                                v-model="entries.textfield[item.id]"
                            ></v-text-field>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="closedialog"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="#093cba"
                        dark
                        @click="savedata"
                        class="pl-16 pr-16"
                    >
                        Save
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Add Reminder -->
        <!-- Edit Profile Dialog -->
        <div v-if="dialogvalue.profileedit">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    max-width="690"
                >
                    <v-card>
                        <v-card-title>
                            <small><v-icon class="mr-1">{{dialogvalue.icon}}</v-icon>{{ dialogvalue.title }}</small>
                            <v-spacer></v-spacer>
                            <div v-if="dialogvalue.button != null">
                                <v-btn
                                    style="cursor: pointer;"
                                    v-for="(btn, btnIndex) in dialogvalue.button"
                                    :key="btnIndex"
                                    @click="btn.action ? addpartner() : ''"
                                    :dark="btn.dark"
                                    :text="btn.text"
                                    :color="btn.color"
                                >
                                    <v-icon>{{btn.icon}}</v-icon>
                                    {{btn.label}}
                                </v-btn>
                            </div>
                        </v-card-title>
                        <v-card-text style="height: 400px;">
                            <div
                                class="mt-5"
                                v-for="(profileitem, profileindex) in dialogvalue.textfield"
                                :key="profileindex"
                            >
                                <label class="ml-9">{{profileitem.label}}</label>
                                <v-text-field
                                    :label="profileitem.label == 'name' ? userinfo.info.name : profileitem.label == 'username' ? userinfo.info.username : profileitem.label == 'email' ? userinfo.info.email : profileitem.label == 'phone' ? userinfo.info.phone : ''"
                                    outlined
                                    :prepend-icon="profileitem.icon"
                                    :hint="profileitem.hint"
                                    dense
                                    v-model="entries.textfield[profileindex]"
                                    :type="profileitem.type"
                                    :disabled="profileitem.disabled"
                                ></v-text-field>
                            </div>
                            <v-divider></v-divider>
                            <div
                                class="mt-5"
                                v-for="(dditem, ddindex) in dialogvalue.dd"
                                :key="ddindex"
                            >
                                <v-autocomplete
                                    v-model="entries.dd"
                                    :items="getemailfrequency"
                                    :label="dditem.label"
                                    :prepend-icon="dditem.icon"
                                    item-value="id"
                                    item-text="frequency"
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    return-object
                                >
                                </v-autocomplete>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#093cba"
                            dark
                            @click="saveprofile"
                            class="pl-16 pr-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Edit Profile Dialog -->
        <!-- Add Partner Dialog -->
        <div v-if="dialogvalue.addpartner">
            <v-row justify-center>
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    max-width="690"
                >
                    <v-card>
                        <v-card-title>
                            <small><v-icon class="mr-1">{{dialogvalue.icon}}</v-icon>{{ dialogvalue.title }}</small>
                        </v-card-title>
                        <v-card-text style="height: 400px;">
                            <div
                                class="mt-5"
                                v-for="(addpartner, addpartnerindex) in dialogvalue.textfield"
                                :key="addpartnerindex"
                            >
                                <label class="ml-9">{{addpartner.label}}</label>
                                <v-text-field
                                    :label="addpartner.label"
                                    outlined
                                    :prepend-icon="addpartner.icon"
                                    :hint="addpartner.hint"
                                    dense
                                    v-model="entries.textfield[addpartner.id]"
                                    :type="addpartner.type"
                                    :disabled="addpartner.disabled"
                                ></v-text-field>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#093cba"
                            dark
                            @click="savepartner"
                            class="pl-16 pr-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Add Partner Dialog -->
        <!-- Project Planner Child Edit -->
        <div v-if="dialogvalue.ppeditchild">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    max-width="690"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon class="mr-2">{{dialogvalue.icon}}</v-icon><small>edit: {{childdata.task_name}}</small>
                            <v-spacer></v-spacer>
                            <div v-if="dialogvalue.button != null">
                                <v-icon
                                    style="cursor: pointer;"
                                    v-for="(btn, btnIndex) in dialogvalue.button"
                                    :key="btnIndex"
                                    @click="deletechild"
                                >
                                    {{btn.icon}}
                                </v-icon>
                            </div>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text style="height: 400px;">
                            <div
                                v-for="(item, index) in dialogvalue.textfield"
                                :key="index"
                                class="mt-5"
                            >
                                <label class="ml-9">{{item.label}}</label>
                                <v-text-field
                                    :label="item.label === 'task name' ? childdata.task_name : item.label === 'duration' ? childdata.duration : item.label === 'who' ? childdata.who : ''"
                                    outlined
                                    :prepend-icon="item.icon"
                                    :hint="item.hint"
                                    dense
                                    v-model="entries.textfield[index]"
                                    :type="item.type"
                                ></v-text-field>
                            </div>
                            <div
                                class="mt-5"
                                v-for="(itemdd, indexdd) in dialogvalue.dropdown"
                                :key="indexdd"
                            >
                                <v-autocomplete
                                    v-model="entries.dd"
                                    :items="ddvalues"
                                    :label="itemdd.label"
                                    :prepend-icon="itemdd.icon"
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                >
                                </v-autocomplete>
                            </div>
                            <v-divider></v-divider>
                            <div
                                class="mt-5"
                            >
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="startdate"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="startdate"
                                            label="start date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            dense
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startdate"
                                        no-title
                                        scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(startdate)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>

                                <!-- end date -->

                                <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :return-value.sync="finishdate"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    class="mt-5"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="finishdate"
                                            label="end date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            dense
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="finishdate"
                                        no-title
                                        scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu2 = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu2.save(finishdate)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </div>
                            <v-divider></v-divider>
                            <div
                                class="mt-5"
                                v-for="(itemtextarea, indextextarea) in dialogvalue.textarea"
                                :key="indextextarea"
                            >
                                <v-textarea
                                    v-model="entries.textarea"
                                    outlined
                                    :label="childdata.notes == null ? itemtextarea.label : childdata.notes"
                                    :value="childdata.notes"
                                    :hint="itemtextarea.hint"
                                    :prepend-inner-icon="itemtextarea.icon"
                                ></v-textarea>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#093cba"
                            dark
                            @click="saveeditedchild"
                            class="pl-16 pr-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Child Edit Project Planner -->
        <!-- Site Diary -->
        <div v-if="dialogvalue.sitediary">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    max-width="690"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon class="mr-2">{{dialogvalue.icon}}</v-icon><small>{{dialogvalue.title}}</small>
                            <v-spacer></v-spacer>
                            <small>
                                <i>{{dialogvalue.datenow}}</i>
                            </small>
                        </v-card-title>
                        <v-card-text>
                            <div
                                class="mt-5"
                            >
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="startdate"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="startdate"
                                            :label="dialogvalue.date[0].label"
                                            :prepend-icon="dialogvalue.date[0].icon"
                                            readonly
                                            dense
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startdate"
                                        no-title
                                        scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(startdate)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </div>
                            <div
                                v-for="item in dialogvalue.textfield"
                                :key="item.id"                              
                                class="mt-5">
                                <v-text-field
                                    :label="item.label"
                                    outlined
                                    :prepend-icon="item.icon"
                                    :hint="item.hint"
                                    dense
                                    v-model="entries.textfield[item.id]"
                                    :type="item.type"
                                ></v-text-field>
                            </div>
                            <div
                                class="mt-5"
                                v-for="itemdd in dialogvalue.dropdown"
                                :key="itemdd.id">
                                <v-combobox
                                    v-model="entries.dd"
                                    :items="ddvalues"
                                    :search-input.sync="search"
                                    hide-selected
                                    :label="itemdd.label"
                                    item-text="tags"
                                    multiple
                                    persistent-hint
                                    small-chips
                                    outlined
                                    dense
                                    :prepend-icon="itemdd.icon"
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </div>
                            <div
                                v-for="(fileinput) in dialogvalue.image"
                                :key="fileinput.id"
                                class="mt-5"
                            >
                                <v-file-input
                                    v-model="entries.image"
                                    small-chips
                                    dense
                                    outlined
                                    :label="fileinput.label"
                                ></v-file-input>
                            </div>
                            <div
                                class="mt-5"
                                v-for="(textarea) in dialogvalue.textarea"
                                :key="textarea.id"
                            >
                                <v-textarea
                                    outlined
                                    dense
                                    v-model="entries.textarea"
                                    :prepend-icon="textarea.icon"
                                    :label="textarea.label"
                                ></v-textarea>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#093cba"
                            dark
                            @click="savesitediary"
                            class="pl-16 pr-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Site Diary -->
        <!-- Edit Item Site Diary -->
        <div v-if="dialogvalue.editchildsitediary">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    max-width="690"
                >
                    <v-card>
                        <v-card-title>
                            <small>{{childdata.log_name}}</small>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-for="(btn, btnIndex) in dialogvalue.button"
                                :key="btnIndex"
                                :dark="btn.dark"
                                :text="btn.text"
                                :class="btn.class"
                                :color="btn.class"
                                @click="btn.action ? deletesitediaryentry() : ''"
                            >
                                <v-icon
                                    :class="btn.classicon"
                                >
                                    {{btn.icon}}
                                </v-icon>
                                {{btn.label}}
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <div
                                class="mt-5">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="startdate"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="startdate"
                                            :label="childdata.site_diary_date"
                                            :prepend-icon="dialogvalue.date[0].icon"
                                            readonly
                                            dense
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startdate"
                                        no-title
                                        scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(startdate)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </div>
                            <div
                                v-for="(item) in dialogvalue.textfield"
                                :key="item.id"
                                class="mt-5"
                            >
                                <v-text-field
                                    :label="childdata.log_name"
                                    outlined
                                    :prepend-icon="item.icon"
                                    :hint="item.hint"
                                    dense
                                    v-model="entries.textfield[item.id]"
                                    :type="item.type"
                                ></v-text-field>
                            </div>
                            <div
                                class="mt-5"
                                v-for="(itemdd) in dialogvalue.dropdown"
                                :key="itemdd.id"
                            >
                                <v-badge
                                    class="ml-9"
                                >
                                    <span slot="badge">{{childdata.tags.join(',')}}</span>
                                </v-badge>
                                <v-combobox
                                    v-model="entries.dd"
                                    :items="ddvalues"
                                    :search-input.sync="search"
                                    hide-selected
                                    :label="itemdd.label"
                                    item-text="tags"
                                    multiple
                                    persistent-hint
                                    small-chips
                                    outlined
                                    dense
                                    :prepend-icon="itemdd.icon"
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </div>
                            <div
                                v-for="(fileinput) in dialogvalue.image"
                                :key="fileinput.id"
                                class="mt-5"
                            >
                                <v-checkbox
                                    v-model="entries.toremove"
                                    label="remove image"
                                >
                                </v-checkbox>
                                <img :src="`${img_url}frontend/images/${childdata.image}`" style="max-width: 280px; height: 180px;"/>
                                <v-file-input
                                    v-model="entries.image"
                                    small-chips
                                    dense
                                    outlined
                                    :label="fileinput.label"
                                ></v-file-input>
                            </div>
                            <div
                                class="mt-5"
                                v-for="(textarea) in dialogvalue.textarea"
                                :key="textarea.id"
                            >
                                <v-textarea
                                    outlined
                                    dense
                                    v-model="entries.textarea"
                                    :prepend-icon="textarea.icon"
                                    :label="childdata.notes"
                                ></v-textarea>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="#0b4ef3"
                            text
                            @click="closedialog"
                        >
                            cancel
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            class="pr-16 pl-16"
                            @click="saveeditedsitediary"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Edit Item Site Diary -->
        <!-- View Image Site Diary -->
        <div v-if="dialogvalue.isimage">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                >
                    <v-card>
                        <v-card-title>
                            <small>{{dialogvalue.title}}</small>
                        </v-card-title>
                        <v-card-text align="center">
                            <img :src="`${img_url}frontend/images/${childdata}`"/>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                        >
                            Close
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End View Image Site Diary -->
        <!-- File Manager Dialog -->
        <div v-if="dialogvalue.filemanager">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="980"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon :class="dialogvalue.iconclass">{{dialogvalue.icon}}</v-icon>
                            <small>{{dialogvalue.title}}</small>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-for="(btndirectory, btndirectoryindex) in dialogvalue.buttondeletedirectory"
                                :key="btndirectoryindex"
                                :class="btndirectory.class"
                                :dark="btndirectory.dark"
                                :text="btndirectory.text"
                                :color="btndirectory.color"
                                @click="deletedirectory"
                            >
                                <v-icon
                                    :class="btndirectory.classicon"
                                >
                                    {{btndirectory.icon}}
                                </v-icon>
                                {{btndirectory.label}}
                            </v-btn>
                        </v-card-title>
                        <v-card-subtitle>
                            <v-row class="mt-5">
                                <v-col cols="6">
                                    <v-btn
                                        v-for="(btn, btnindex) in dialogvalue.buttoninner"
                                        :key="btnindex"
                                        :class="btn.class"
                                        :dark="btn.dark"
                                        :text="btn.text"
                                        :color="btn.color"
                                        @click="btn.action ? addfile() : ''"
                                    >
                                        <v-icon>{{ btn.icon }}</v-icon>
                                        {{btn.label}}
                                    </v-btn>
                                </v-col>
                                <v-col v-if="selectefordelete.length > 0" cols="6" class="text-right">
                                    <v-btn
                                        v-for="(btn, btnindex) in dialogvalue.button"
                                        :key="btnindex"
                                        :class="btn.class"
                                        :dark="btn.dark"
                                        :text="btn.text"
                                        :color="btn.color"
                                        @click="deleteitems"
                                    >
                                        <v-icon
                                            :class="btn.classicon"
                                        >
                                            {{btn.icon}}
                                        </v-icon>
                                        {{btn.label}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-subtitle>
                        <v-card-text>
                            
                            <v-divider></v-divider>
                            <v-row justify="center" class="mt-5">
                                <v-col 
                                    v-for="(media, mediaIndex) in childdata.getmedia"
                                    :key="mediaIndex"
                                    cols="12"
                                    md="4"
                                    lg="4"
                                    sm="12"
                                >
                                    <v-sheet
                                        elevation="1"
                                        color="gray;"
                                        width="100%"
                                    >   
                                        <v-col cols="12">
                                            <v-row>
                                                <v-col cols="2">
                                                    <v-checkbox
                                                        v-model="selectefordelete"
                                                        :value="media"
                                                        style="position: relative; top: -15px;"
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col cols="10">
                                                    <strong>Name: {{media.name}}</strong><br/>
                                                    <small>Created at: {{media.created_at}}</small>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <a :href="`${img_url}${media.path}`" target="_blank">
                                            <img v-if="media.extension_file == 'jpg' || media.extension_file == 'png'" :src="`${img_url}${media.path}`" style="max-height: 250px; max-width: 250px;"/>
                                        </a>
                                        <div v-if="media.extension_file == 'pdf'">
                                            <a :href="`${img_url}${media.path}`" target="_blank">
                                                <img src="https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/27_Pdf_File_Type_Adobe-512.png" style="max-height: 250px; max-width: 250px;"/>
                                            </a>
                                        </div>
                                        <div v-if="media.extension_file == 'docx'">
                                            <img @click="openfile(media)" src="https://images.ctfassets.net/lzny33ho1g45/how-to-work-with-images-in-goo-p-img/bc519a15b782515b68c1f91329b6e8ea/file.png?w=520&fm=jpg&q=30&fit=thumb&h=520" style="max-height: 250px; max-width: 250px;"/>
                                        </div>
                                        <div v-if="media.extension_file == 'xlsx'">
                                            <img @click="openfile(media)" src="https://www.computerhope.com/jargon/e/microsoft-excel.png" style="max-height: 250px; max-width: 250px;"/>
                                        </div>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                        >
                            Close
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End File Manager Dialog -->
        <div v-if="dialogvalue.viewfile">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="960"
                >
                    <v-card>
                        <v-card-title>
                            <small>{{ dialogvalue.title }}</small>
                        </v-card-title>
                        <v-card-text>
                            <VueDocPreview :value="`${img_url}${getfiletoopen.path}`" type="office" />
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                        >
                            Close
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- File manager add file / media -->
        <div v-if="dialogvalue.addfileinfilemanager">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="520">
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.title}}</small>
                        </v-card-title>
                        <v-card-text align="center">
                            <div
                                class="mt-5"
                            >
                                <v-text-field
                                    v-for="(titem, tindex) in dialogvalue.textfield"
                                    :key="tindex"
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    outlined
                                    dense
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                ></v-text-field>
                                <v-file-input
                                    v-model="entries.image"
                                    small-chips
                                    dense
                                    outlined
                                    label="add file, image"
                                ></v-file-input>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="savefilemanagermedia"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- end file manager add file / media -->
        <!-- File manager add directory -->
        <div v-if="dialogvalue.addfiledirectory">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="520"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.title}}</small>
                        </v-card-title>
                        <v-card-text align="center">
                            <div
                                class="mt-5"
                            >
                                <v-text-field
                                    v-for="(titem, tindex) in dialogvalue.textfield"
                                    :key="tindex"
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    outlined
                                    dense
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                ></v-text-field>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="savefilemanagermedia"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- end file manager add directory -->
        <!-- Edit FFF -->
        <div v-if="dialogvalue.editfff">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="520"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>edit <strong>{{childdata.item}}</strong></small>
                        </v-card-title>
                        <v-card-text align="center">
                            <div
                                class="mt-5"
                            >
                                <v-text-field
                                    v-for="(titem, tindex) in dialogvalue.textfield"
                                    :key="tindex"
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    :placeholder="
                                      titem.label == 'quantity' ? childdata.quantity :
                                      titem.label == 'colour' ? childdata.color :
                                      titem.label == 'supplier' ? childdata.supplier_id :
                                      titem.label == 'product code' ? childdata.product_code :
                                      titem.label == 'link to product' ? childdata.link_to_product :
                                      titem.label == 'budget' ? childdata.budget :
                                      titem.label == 'actual' ? childdata.actual :
                                      titem.label == 'item' ? childdata.item : ''
                                    "
                                    outlined
                                    dense
                                    :type="titem.type"
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                ></v-text-field>
                            </div>
                            <v-divider></v-divider>
                            <div
                                class="mt-5"
                                v-for="(itemtextarea, indextextarea) in dialogvalue.textarea"
                                :key="indextextarea"
                            >
                                <v-textarea
                                    v-model="entries.textarea"
                                    outlined
                                    :label="childdata.notes == null ? itemtextarea.label : childdata.notes"
                                    :value="childdata.notes"
                                    :hint="itemtextarea.hint"
                                    :prepend-inner-icon="itemtextarea.icon"
                                ></v-textarea>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="saveeditedfff"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End FFF Edit -->
        <!-- Edit Appliances -->
        <div v-if="dialogvalue.editappliances">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="520"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>edit <strong>{{childdata.item}}</strong></small>
                        </v-card-title>
                        <v-card-text align="center">
                            <div
                                class="mt-5"
                            >
                                <v-text-field
                                    v-for="(titem, tindex) in dialogvalue.textfield"
                                    :key="tindex"
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    :placeholder="
                                      titem.label == 'quantity' ? childdata.quantity :
                                      titem.label == 'colour' ? childdata.color :
                                      titem.label == 'supplier' ? childdata.supplier_id :
                                      titem.label == 'product code' ? childdata.product_code :
                                      titem.label == 'link to product' ? childdata.link_to_product :
                                      titem.label == 'budget' ? childdata.budget :
                                      titem.label == 'actual' ? childdata.actual :
                                      titem.label == 'item' ? childdata.item : ''
                                    "
                                    outlined
                                    dense
                                    :type="titem.type"
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                ></v-text-field>
                            </div>
                            <v-divider></v-divider>
                            <div
                                class="mt-5"
                                v-for="(itemtextarea, indextextarea) in dialogvalue.textarea"
                                :key="indextextarea"
                            >
                                <v-textarea
                                    v-model="entries.textarea"
                                    outlined
                                    :label="childdata.notes == null ? itemtextarea.label : childdata.notes"
                                    :value="childdata.notes"
                                    :hint="itemtextarea.hint"
                                    :prepend-inner-icon="itemtextarea.icon"
                                ></v-textarea>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="saveeditedappliances"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Appliances Edit -->
        <!-- Edit Flooring -->
        <div v-if="dialogvalue.editflooring">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="520"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>edit <strong>{{childdata.item}}</strong></small>
                        </v-card-title>
                        <v-card-text align="center">
                            <div
                                class="mt-5"
                            >
                                <v-text-field
                                    v-for="(titem, tindex) in dialogvalue.textfield"
                                    :key="tindex"
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    :placeholder="
                                      titem.label == 'location' ? childdata.location :
                                      titem.label == 'sqm' ? childdata.sqm :
                                      titem.label == 'floor type' ? childdata.floor_type :
                                      titem.label == 'price per sqm' ? childdata.price_per_sqm :
                                      titem.label == 'total' ? childdata.total :
                                      titem.label == 'supplier' ? childdata.supplier_id : ''
                                    "
                                    outlined
                                    dense
                                    :type="titem.type"
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                ></v-text-field>
                            </div>
                            <v-divider></v-divider>
                            <div
                                class="mt-5"
                                v-for="(itemtextarea, indextextarea) in dialogvalue.textarea"
                                :key="indextextarea"
                            >
                                <v-textarea
                                    v-model="entries.textarea"
                                    outlined
                                    :label="childdata.notes == null ? itemtextarea.label : childdata.notes"
                                    :value="childdata.notes"
                                    :hint="itemtextarea.hint"
                                    :prepend-inner-icon="itemtextarea.icon"
                                ></v-textarea>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="saveeditedflooring"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Flooring Edit -->
        <!-- Decision Notes -->
        <div v-if="dialogvalue.decisionsnotes">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="520"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>add notes</small>
                        </v-card-title>
                        <v-card-text>
                            <!-- {{ childdata.notes }} -->
                            <v-chip
                                v-for="(note, noteindex) in childdata.notes"
                                :key="noteindex"
                                class="mr-3"
                                close
                                @click:close="deleteNoteItem(note, noteindex)"
                            >
                                <v-icon 
                                    left 
                                    small
                                    style="cursor: pointer;"
                                    @click="edit_note(note, noteindex)"
                                >
                                    mdi-pen
                                </v-icon>
                                {{ note }}
                            </v-chip>
                        </v-card-text>
                        <v-card-text align="center">
                            <div
                                class="mt-5"
                                v-for="(itemtextarea, indextextarea) in dialogvalue.textarea"
                                :key="indextextarea"
                            >
                                <v-textarea
                                    v-model="entries.textarea"
                                    outlined
                                    :value="childdata.notes"
                                    :hint="itemtextarea.hint"
                                    :prepend-inner-icon="itemtextarea.icon"
                                ></v-textarea>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="savedecisionnotes"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Decision Notes -->
        <!-- Add Item Decisions -->
        <div v-if="dialogvalue.adddecisions">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="520"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.label}}</small>
                        </v-card-title>
                        <v-card-text align="center">
                            <div
                                class="mt-5"
                                v-for="(dditem) in dialogvalue.dropdown"
                                :key="dditem.id"
                            >
                                <v-autocomplete
                                    v-model="entries.dd"
                                    :items="ddvalues"
                                    :label="dditem.label"
                                    :prepend-icon="dditem.icon"
                                    item-value="id"
                                    item-text="item"
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    return-object
                                >
                                </v-autocomplete>
                            </div>
                            <div
                                class="mt-5"
                                v-for="(titem) in dialogvalue.textfield"
                                :key="titem.id"
                            >
                                <v-text-field
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    outlined
                                    dense
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                ></v-text-field>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="savenewdecision"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Decisions Item -->
        <!-- Add Snags -->
        <div v-if="dialogvalue.issnags">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="520"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.label}}</small>
                        </v-card-title>
                        <v-card-text align="center">
                            <div
                                class="mt-5"
                                v-for="(titem) in dialogvalue.textfield"
                                :key="titem.id"
                            >
                                <v-text-field
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    outlined
                                    dense
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                ></v-text-field>
                            </div>
                            <div
                                class="mt-5"
                                v-for="(itemdd) in dialogvalue.dropdown"
                                :key="itemdd.id"
                            >
                                <v-combobox
                                    v-model="entries.dd"
                                    :items="ddvalues"
                                    :search-input.sync="search"
                                    hide-selected
                                    :label="itemdd.label"
                                    item-text="tags"
                                    multiple
                                    persistent-hint
                                    small-chips
                                    outlined
                                    dense
                                    :prepend-icon="itemdd.icon"
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </div>
                            <div
                                class="mt-5"
                            >
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="startdate"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="startdate"
                                            label="deadline"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            dense
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startdate"
                                        no-title
                                        scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(startdate)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </div>
                            <div
                                class="mt-5"
                            >
                                <v-file-input
                                    v-model="entries.image"
                                    small-chips
                                    dense
                                    outlined
                                    label="add image"
                                ></v-file-input>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="savesnagentry"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Snags -->
        <!-- Edit Snags -->
        <div v-if="dialogvalue.editsnag">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="520"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.label}}</small>
                        </v-card-title>
                        <v-card-text align="center">
                            <div
                                class="mt-5"
                                v-for="(titem) in dialogvalue.textfield"
                                :key="titem.id"
                            >
                                <v-text-field
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    outlined
                                    dense
                                    :placeholder="childdata.description"
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                ></v-text-field>
                            </div>
                            <div
                                class="mt-5"
                                v-for="(itemdd) in dialogvalue.dropdown"
                                :key="itemdd.id"
                            >
                                <v-combobox
                                    v-model="entries.dd"
                                    :items="ddvalues"
                                    :search-input.sync="search"
                                    hide-selected
                                    :label="itemdd.label"
                                    item-text="tags"
                                    multiple
                                    persistent-hint
                                    small-chips
                                    outlined
                                    dense
                                    :prepend-icon="itemdd.icon"
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                            </div>
                            <div
                                class="mt-5"
                            >
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="startdate"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="startdate"
                                            :label="childdata.created_at"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            dense
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startdate"
                                        no-title
                                        scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(startdate)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </div>
                            <div
                                class="mt-5"
                            >
                                <img :src="`${img_url}frontend/images/${childdata.image}`" style="max-width: 300px; max-height: 300px;"/>
                            </div>
                            <div
                                class="mt-5"
                            >
                                <v-file-input
                                    v-model="entries.image"
                                    small-chips
                                    dense
                                    outlined
                                    label="update image"
                                ></v-file-input>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="saveeditsnag"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Edit Snags -->
        <!-- Edit Square Meters -->
        <div v-if="dialogvalue.addsquaremeter">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="520"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.label}}</small>
                        </v-card-title>
                        <v-card-text align="center">
                            <div
                                class="mt-5"
                                v-for="(titem, tindex) in dialogvalue.textfield"
                                :key="tindex"
                            >
                                <v-text-field
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    outlined
                                    dense
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                    :type="titem.type"
                                ></v-text-field>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="savesquaremeter"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Edit Square Meters -->
        <!-- Add Item Description Budget Manager -->
        <div v-if="dialogvalue.additemdescriptiontab1">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="520"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.label}}</small>
                        </v-card-title>
                        <v-card-text align="center">
                            <div
                                class="mt-5"
                                v-for="(titem, tindex) in dialogvalue.textfield"
                                :key="tindex"
                            >
                                <v-text-field
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    outlined
                                    dense
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                    :type="titem.type"
                                ></v-text-field>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="savesquaremeter"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- Add Item Description Budget Manager -->
        <!-- Edit Item Budget Manager -->
        <div v-if="dialogvalue.editbudgetmanageritem">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="520"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.label}}</small>
                        </v-card-title>
                        <v-card-text align="center">
                            <div
                                class="mt-5"
                                v-for="(titem, tindex) in dialogvalue.textfield"
                                :key="tindex"
                            >
                                <v-text-field
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    outlined
                                    dense
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                    :type="titem.type"
                                    :placeholder="titem.label == 'item description' ? childdata.item_description : titem.label == 'budget' ? childdata.budget : titem.label == 'actual price' ? childdata.actual_price : ''"
                                ></v-text-field>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="savebudgetmanageritem"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Edit Item Budget Manager -->
        <!-- Transaction History Budget Manager -->
        <div v-if="dialogvalue.budgetmanagerviewhistory">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="860"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.label}}</small>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-for="(btn, btnindex) in dialogvalue.buttons"
                                :key="btnindex"
                                :class="btn.class"
                                :dark="btn.dark"
                                :text="btn.text"
                                :color="btn.color"
                                @click="btn.action == 'addtransaction' ? addtransactionhistory() : ''"
                            >
                                <v-icon
                                    :class="btn.iconclass"
                                >
                                    {{btn.icon}}
                                </v-icon>
                                {{btn.label}}
                            </v-btn>
                        </v-card-title>
                        <v-card-text align="center">
                            <v-data-table
                                :headers="dialogvalue.headers"
                                :items="childdata"
                                class="elevation-1"
                            >
                                <template v-slot:item="props">
                                    <tr style="cursor: pointer;">
                                        <td><small>€ {{props.item.amount | formatNumber}}</small></td>
                                        <td><small>{{props.item.payment_method}}</small></td>
                                        <td><small>{{props.item.created_at}}</small></td>
                                        <td><small>{{props.item.name}}</small></td>
                                        <td><v-icon @click="deletetransaction(props.item)">mdi-delete</v-icon></td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Transaction History Budget Manager -->
        <!-- Transaction Summary Budget Manager -->
        <div v-if="dialogvalue.budgetmanagersummary">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="860"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.label}}</small>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-for="(btn, btnindex) in dialogvalue.buttons"
                                :key="btnindex"
                                :class="btn.class"
                                :dark="btn.dark"
                                :text="btn.text"
                                :color="btn.color"
                                @click="btn.action == 'addtransaction' ? addtransactionhistory() : ''"
                            >
                                <v-icon
                                    :class="btn.iconclass"
                                >
                                    {{btn.icon}}
                                </v-icon>
                                {{btn.label}}
                            </v-btn>
                        </v-card-title>
                        <v-card-text align="center">
                            <v-data-table
                                :headers="dialogvalue.headers"
                                :items="childdata"
                                class="elevation-1"
                            >
                                <template v-slot:item="props">
                                    <tr style="cursor: pointer;">
                                        <td><small>€ {{props.item.amount | formatNumber}}</small></td>
                                        <td><small>{{props.item.payment_method}}</small></td>
                                        <td><small>{{props.item.created_at}}</small></td>
                                        <td><small>{{props.item.name}}</small></td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Transaction Summary Budget Manager -->
        <!-- Add Transaction History Budget Manager -->
        <div v-if="dialogvalue.addtransactionhistory">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="800"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.label}}</small>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-for="(btn, btnindex) in dialogvalue.buttons"
                                :key="btnindex"
                                :class="btn.class"
                                :dark="btn.dark"
                                :text="btn.text"
                                :color="btn.color"
                                @click="btn.action == 'addtransaction' ? addtransactionhistory() : ''"
                            >
                                <v-icon
                                    :class="btn.iconclass"
                                >
                                    {{btn.icon}}
                                </v-icon>
                                {{btn.label}}
                            </v-btn>
                        </v-card-title>
                        <v-card-text align="center">
                            <div 
                                class="mt-5"
                                v-for="(titem, tindex) in dialogvalue.textfield"
                                :key="tindex"
                            >
                                <v-text-field
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    outlined
                                    dense
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                    :type="titem.type"
                                ></v-text-field>
                            </div>
                            <div
                                class="mt-5"
                            >
                                <v-autocomplete
                                    v-model="entries.dd"
                                    :items="dialogvalue.payment_method.items"
                                    :label="dialogvalue.payment_method.label"
                                    :prepend-icon="dialogvalue.payment_method.icon"
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    return-object
                                >
                                </v-autocomplete>
                            </div>
                            <div
                                class="mt-5"
                            >
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="startdate"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="startdate"
                                            label="transaction date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            dense
                                            outlined
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startdate"
                                        no-title
                                        scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(startdate)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="savetransaction"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Add Transaction History Budget Manager -->

        <!-- Edit Cost Estimation / Budget -->
        <div v-if="dialogvalue.addbudgetcostestimation">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="690"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.label}}</small>
                        </v-card-title>
                        <v-card-text align="center">
                            <div 
                                class="mt-5"
                                v-for="(titem, tindex) in dialogvalue.textfield"
                                :key="tindex"
                            >
                                <v-text-field
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    outlined
                                    dense
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                    :type="titem.type"
                                ></v-text-field>
                                test 2
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="saveprojectbuildbudget"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Cost Estimation / Budget -->

        <!-- Edit Value Bill of Materials -->
        <div v-if="dialogvalue.addvaluesbillofmaterials">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="690"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.label}}</small>
                        </v-card-title>
                        <v-card-text align="center">
                            <div 
                                class="mt-5"
                                v-for="(titem, tindex) in dialogvalue.textfield"
                                :key="tindex"
                            >
                                <v-text-field
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    outlined
                                    dense
                                    :prepend-icon="titem.icon"
                                    v-model="entries.textfield[titem.id]"
                                    :type="titem.type"
                                ></v-text-field>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="savevalueinbillofmaterials"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Value Bill of Materials -->

        <!-- Suppliers Bill Of Materials -->
        <div v-if="dialogvalue.updatesuppliersvalue">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="690"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.label}}</small>
                        </v-card-title>
                        <v-card-text>
                            <div 
                                class="mt-5"
                                v-for="(titem, tindex) in dialogvalue.group"
                                :key="tindex"
                            >
                                <v-sheet
                                    color="white"
                                    elevation="1"
                                    height="inherit"
                                    width="100%"
                                    class="mt-5 mb-5 pl-8 pr-8"
                                >
                                    <label>
                                        <v-icon 
                                            :class="titem.iconclass"
                                        >
                                            {{titem.icon}}
                                        </v-icon>
                                        {{titem.label}}
                                    </label>
                                    <v-text-field
                                        v-for="(tfitem, tfindex) in titem.textfield"
                                        :key="tfindex"
                                        :label="tfitem.label"
                                        :hint="tfitem.hint"
                                        outlined
                                        dense
                                        :prepend-icon="tfitem.icon"
                                        v-model="entries.textfield[tfitem.id]"
                                        :type="tfitem.type"
                                    ></v-text-field>
                                </v-sheet>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="savesupplier"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Suppliers Bill Of Materials -->

        <!-- Send Supplier Email -->
        <div v-if="dialogvalue.suppliersendemail">
            <!-- HERE -->
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="420"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.label}} to {{childdata.company_name}}</small>
                        </v-card-title>
                        <v-card-text>
                            <!-- {{user_info}} -->
                            <div class="mt-5"></div>
                            <v-text-field
                                label="Full Name"
                                outlined
                                dense
                                :value="user_info.info.name"
                                disabled
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon small class="mt-1">mdi-account</v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                label="Your email"
                                outlined
                                dense
                                :value="user_info.info.email"
                                type="email"
                                disabled
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon small class="mt-1">mdi-at</v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field
                                label="Your Phone Number (optional)"
                                outlined
                                dense
                                :value="user_info.info.phone"
                                type="number"
                                disabled
                            >
                                <template v-slot:prepend-inner>
                                    <v-icon small class="mt-1">mdi-phone</v-icon>
                                </template>
                            </v-text-field>
                            <div 
                                v-for="(titem, tindex) in dialogvalue.textarea"
                                :key="tindex"
                            >
                                <v-textarea
                                    v-model="entries.textarea"
                                    outlined
                                    label="email body"
                                    :hint="titem.hint"
                                >
                                    <template v-slot:prepend-inner>
                                        <v-icon small class="mt-1">{{titem.icon}}</v-icon>
                                    </template>
                                </v-textarea>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="sendemailsupplier"
                            class="pr-16 pl-16"
                        >
                            Send
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Supplier Send Email -->

        <!-- Supplier Request-->
        <div v-if="dialogvalue.addsupplierrequest">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    width="690"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon
                                :class="dialogvalue.iconclass"
                            >
                                {{dialogvalue.icon}}
                            </v-icon>
                            <small>{{dialogvalue.title}}</small>
                        </v-card-title>
                        <v-card-text>
                            <v-divider></v-divider>
                            <div
                                v-for="(input, inputindex) in dialogvalue.items"
                                :key="inputindex"
                                class="mb-3 mt-3"
                            >
                                <v-file-input
                                    v-if="input.ttype == 'attachment'"
                                    chips
                                    outlined
                                    dense
                                    multiple
                                    :label="input.label"
                                    v-model="entries.attachment"
                                ></v-file-input>
                                <v-text-field
                                    v-if="input.ttype == 'input'"
                                    :label="input.label"
                                    :hint="input.hint"
                                    :prepend-icon="input.icon"
                                    :placeholder="input.label"
                                    :type="input.type"
                                    outlined
                                    dense
                                    v-model="entries.textfield[input.id]"
                                >
                                </v-text-field>
                                <v-autocomplete
                                    v-if="input.ttype == 'dd'"
                                    v-model="entries.dd"
                                    outlined
                                    small-chips
                                    dense
                                    :items="ddvalues"
                                    item-text="service_type"
                                    item-value="id"
                                    :label="input.label"
                                    return-object
                                    :prepend-icon="input.icon"
                                >
                                </v-autocomplete>
                                <!--<v-textarea
                                    v-if="input.ttype == 'textarea'"
                                    v-model="entries.textarea"
                                    outlined
                                    :label="input.label"
                                    :hint="input.hint"
                                    :prepend-inner-icon="input.icon"
                                ></v-textarea>-->
                                <v-combobox
                                    v-if="input.ttype == 'combobox'"
                                    v-model="entries.combobox"
                                    :search-input.sync="search"
                                    hide-selected
                                    :label="input.label"
                                    item-text="tags"
                                    multiple
                                    persistent-hint
                                    small-chips
                                    outlined
                                    dense
                                    :prepend-icon="input.icon"
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                                <div v-if="input.ttype == 'small'" class="mb-5" style="position: relative; top: -15px;">
                                    <label class="ml-5">scope of work</label>
                                    <v-row>
                                        <v-btn
                                            v-for="(iitem, iitemindex) in entries.combobox"
                                            :key="iitemindex"
                                            outlined
                                            rounded
                                            class="mx-3 ml-8"
                                            small
                                            style="display: block; margin-top: 20px;"
                                        >
                                            {{iitem}}
                                        </v-btn>
                                    </v-row>
                                    <!-- <ul>
                                        <li
                                            v-for="(item, itemindex) in entries.combobox"
                                            :key="itemindex"
                                            class="ml-8"
                                        >
                                            {{item}}
                                        </li>
                                    </ul> -->
                                </div>
                                <v-autocomplete
                                    v-if="input.ttype == 'dd2'"
                                    v-model="entries.dd2"
                                    outlined
                                    small-chips
                                    dense
                                    :items="ddvalues2"
                                    item-text="text"
                                    item-value="id"
                                    :label="input.label"
                                    return-object
                                    :prepend-icon="input.icon"
                                >
                                </v-autocomplete>
                                <v-autocomplete
                                    v-if="input.ttype == 'dd3'"
                                    v-model="entries.dd3"
                                    outlined
                                    small-chips
                                    dense
                                    :items="ddvalues3"
                                    item-text="country_name"
                                    item-value="country_name"
                                    :label="input.label"
                                    return-object
                                    :prepend-icon="input.icon"
                                    @change="getStates"
                                >
                                </v-autocomplete>
                                <v-autocomplete
                                    v-if="input.ttype == 'dd4'"
                                    v-model="entries.dd4"
                                    outlined
                                    small-chips
                                    dense
                                    :disabled="input.disabled"
                                    :items="ddvalues4"
                                    item-text="state_name"
                                    item-value="state_name"
                                    :label="input.label"
                                    return-object
                                    :prepend-icon="input.icon"
                                    @change="getCities"
                                >
                                </v-autocomplete>
                                <v-autocomplete
                                    v-if="input.ttype == 'dd5'"
                                    v-model="entries.dd5"
                                    outlined
                                    small-chips
                                    dense
                                    :disabled="input.disabled"
                                    :items="ddvalues5"
                                    item-text="city_name"
                                    item-value="city_name"
                                    :label="input.label"
                                    return-object
                                    :prepend-icon="input.icon"
                                >
                                </v-autocomplete>
                            </div>
                            <!--<div
                                class="mt-5"
                                v-for="(field, fieldIndex) in dialogvalue.textfield"
                                :key="fieldIndex"
                            >
                                <v-text-field
                                    :label="field.label"
                                    :hint="field.hint"
                                    outlined
                                    dense
                                    :prepend-icon="field.icon"
                                    v-model="entries.textfield[field.id]"
                                    :type="field.type"
                                ></v-text-field>
                            </div>
                            <div 
                                class="mt-5"
                                v-for="(titem, tindex) in dialogvalue.textarea"
                                :key="tindex"
                            >
                                <v-textarea
                                    v-model="entries.textarea"
                                    outlined
                                    :label="titem.label"
                                    :hint="titem.hint"
                                    :prepend-inner-icon="titem.icon"
                                ></v-textarea>
                            </div>
                            <v-divider></v-divider>
                            <div
                                class="mt-5"
                            >
                                <v-autocomplete
                                    v-model="entries.dd"
                                    :items="ddvalues"
                                    :label="dialogvalue.dd[0].label"
                                    :prepend-icon="dialogvalue.dd[0].icon"
                                    item-value="id"
                                    item-text="service_type"
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    return-object
                                >
                                </v-autocomplete>
                            </div>
                            <div
                                class="mt-5"
                            >
                                <v-autocomplete
                                    v-model="entries.dd2"
                                    :items="ddvalues2"
                                    :label="dialogvalue.dd[1].label"
                                    :prepend-icon="dialogvalue.dd[1].icon"
                                    item-value="id"
                                    item-text="text"
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    return-object
                                >
                                </v-autocomplete>
                            </div>
                            <v-divider></v-divider>
                            <div
                                class="mt-5"
                                v-for="(field, fieldIndex) in dialogvalue.textfield2"
                                :key="fieldIndex"
                            >
                                <v-text-field
                                    :label="field.label"
                                    :hint="field.hint"
                                    outlined
                                    dense
                                    :prepend-icon="field.icon"
                                    v-model="entries.textfield[field.id]"
                                    :type="field.type"
                                ></v-text-field>
                            </div>-->
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                            class="mx-5"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="#0b4ef3"
                            dark
                            @click="send_supplier_request"
                            class="pr-16 pl-16"
                        >
                            Save
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- End Supplier Request -->

        <!-- show image -->
        <div v-if="dialogvalue.showimage">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    max-width="690"
                >
                <v-card>
                    <v-card-title style="fontSize: 14px;">
                        <small>Attachment</small>
                    </v-card-title>
                    <v-card-text
                        class="text-center"
                    >
                        <img
                            class="mt-10"
                            :src="`${img_url}/frontend/images/${dialogvalue.item.attachment}`"
                            style="max-width: 600px; max-height: 600px;"
                        />
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="closedialog"
                    >
                        Close
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- end show image -->

        <div v-if="dialogvalue.tips">
            <v-row justify="center">
                <v-dialog
                    :value="value"
                    persistent
                    scrollable
                    max-width="690"
                >
                    <v-card>
                        <v-card-title>
                            <v-icon class="mr-2">{{dialogvalue.icon}}</v-icon><small>{{dialogvalue.title}}</small>
                        </v-card-title>
                        <v-card-text>
                            <wysiwyg 
                                label="Tip description"
                                outlined
                                hint="Tip description"
                                class="px-5"
                                dense
                                v-model="entries.textarea"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="closedialog"
                                class="mx-5"
                            >
                                Close
                            </v-btn>
                            <v-btn
                                color="#0b4ef3"
                                dark
                                @click="savesuggestion"
                                class="pr-16 pl-16"
                            >
                                Confirm
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
    </div>
</template>

<script>
import VueDocPreview from 'vue-doc-preview'
export default {
  components: {
    VueDocPreview
  },
  props: [
    'dialogvalue',
    'value',
    'childdata',
    'ddvalues',
    'ddvalues2',
    'ddvalues3',
    'ddvalues4',
    'ddvalues5',
    'userinfo',
    'getemailfrequency',
    'getfiletoopen',
    'user_info'
  ],
  data: () => ({
    entries: {
      textfield: [],
      dd: [],
      dd2: [],
      dd3: [],
      dd4: [],
      dd5: [],
      textarea: null,
      image: [],
      toremove: false,
      attachment: [],
      combobox: []
    },
    menu: false,
    startdate: null,
    menu2: false,
    finishdate: null,
    showpassword: false,
    search: '',
    img_url: null,
    selectefordelete: [],
  }),
  mounted () {
    this.entries.textfield = []
    this.entries.attachment = []
    this.entries.textarea = null
    this.entries.combobox = []
    this.entries.dd = []
    this.entries.dd2 = []
    this.entries.dd3 = []
    this.entries.dd4 = []
    this.entries.dd5 = []
  },
  created () {
    this.img_url = process.env.VUE_APP_URL
    // this.entries.textfield = []
  },
  computed: {
  },
  methods: {
    closedialog(){
      this.$emit('closedialog')
      this.entries.textfield = []
      this.entries.dd = []
      this.entries.dd2 = []
      this.entries.textarea = null
      this.entries.image = []
      this.startdate = null
      this.finishdate = null
      this.search = ''
      this.entries.combobox = []
      this.selectefordelete = []
    },
    savedata(){
      this.$emit('savedata', {input: this.entries.textfield})
      this.entries.textfield = []
    },
    deletethis(){
      this.$emit('deletethis')
    },
    saveeditedchild(){
      this.$emit('saveeditedchild', {
        textfield:      [
            {
                task_name:      this.entries.textfield[0],
                duration:       this.entries.textfield[1],
                who:            this.entries.textfield[2],
            }
        ],
        dropdown:       this.entries.dd,
        notes:          this.entries.textarea,
        start:          this.startdate,
        finish:         this.finishdate
      })
      this.entries.textfield = []
      this.entries.dd = []
      this.entries.textarea = null
      this.startdate = null
      this.finishdate = null
    },
    deletechild(){
      this.$emit('deletechild')
    },
    addpartner(){
      this.$emit('addpartner')
    },
    saveprofile(){
      this.$emit('saveprofile', {
        textfield:      [
            {
                name:       this.entries.textfield[0],
                username:   this.entries.textfield[1],
                email:      this.entries.textfield[2],
                phone:      this.entries.textfield[3]
            }
        ],
        dropdown:      this.entries.dd
      })
      this.entries.textfield = []
      this.entries.dd = []
    },
    savepartner(){
      this.$emit('savepartner', {
          textfield: [
            {
              name:         this.entries.textfield[1],
              username:     this.entries.textfield[2],
              email:        this.entries.textfield[3],
              password:     this.entries.textfield[4],
              phone:        this.entries.textfield[5]
            }
          ]
      })
      this.entries.textfield = []
    },
    savesitediary(){
      this.$emit('savesitediary', {
        textfield: [
          {
            sitedate:       this.startdate,
            logname:        this.entries.textfield[1],
            image:          this.entries.image,
            notes:          this.entries.textarea
          }
        ],
        dropdown:       this.entries.dd,
      })
      this.entries.textfield = []
      this.entries.dd = []
      this.entries.textarea = null
      this.entries.image = []
      this.startdate = null
      this.finishdate = null
      this.search = ''
    },
    saveeditedsitediary(){
      this.$emit('saveeditedsitediary', {
        textfield: [
          {
            sitedate:       this.startdate,
            logname:        this.entries.textfield[1],
            image:          this.entries.image,
            notes:          this.entries.textarea,
            toremove:       this.entries.toremove
          }
        ],
        dropdown:       this.entries.dd,
      })
      this.entries.textfield = []
      this.entries.dd = []
      this.entries.textarea = null
      this.entries.image = []
      this.startdate = null
      this.finishdate = null
      this.search = ''
      this.entries.toremove = false
    },
    deletesitediaryentry(){
      this.$emit('deletesitediaryentry')
    },
    addfile(){
      this.$emit('addfile')
      this.selectefordelete = []
    },
    savefilemanagermedia(){
      this.$emit('savefilemanagermedia', {
        filename:       this.entries.textfield[1],
        file:           this.entries.image
      })
      this.entries.textfield = []
      this.entries.image = []
      this.selectefordelete = []
    },
    openfile(data){
      this.$store.dispatch('filemanager/setfiletoopen', data)
      this.$emit('openfile')
    },
    deleteitems(){
    //   if(this.selectefordelete.length >= 1){
        this.$store.dispatch('filemanager/settodeleteitems', this.selectefordelete)
        this.$emit('deleteitems')
        this.selectefordelete = []
        // return
    //   }
    },
    deletedirectory() {
    this.$emit('deletedirectory')
    },
    saveeditedfff(){
      this.$emit('saveeditedfff', {
        textfield: [
         {
           quantity:        this.entries.textfield[1],
           color:           this.entries.textfield[2],
           supplier:        this.entries.textfield[3],
           product_code:    this.entries.textfield[4],
           link_to_product: this.entries.textfield[5],
           budget:          this.entries.textfield[6],
           actual:          this.entries.textfield[7],
           notes:           this.entries.textarea,
           item:            this.entries.textfield[8]
         }
        ]
      })
      this.entries.textfield = []
      this.entires.textarea = null
    },
    saveeditedappliances(){
      this.$emit('saveeditedappliances', {
        textfield: [
         {
           quantity:        this.entries.textfield[1],
           color:           this.entries.textfield[2],
           supplier:        this.entries.textfield[3],
           product_code:    this.entries.textfield[4],
           link_to_product: this.entries.textfield[5],
           budget:          this.entries.textfield[6],
           actual:          this.entries.textfield[7],
           notes:           this.entries.textarea,
           item:            this.entries.textfield[8]
         }
        ]
      })
      this.entries.textfield = []
      this.entires.textarea = null
    },
    saveeditedflooring(){
      this.$emit('saveeditedflooring', {
        textfield: [
          {
            location:       this.entries.textfield[1],
            sqm:            this.entries.textfield[2],
            floor_type:     this.entries.textfield[3],
            price_per_sqm:  this.entries.textfield[4],
            total:          this.entries.textfield[5],
            supplier:       this.entries.textfield[6],
            notes:          this.entries.textarea
          }
        ]
      })
      this.entries.textfield = []
      this.entries.textarea = null
    },
    savedecisionnotes(){
      this.$emit('savedecisionnotes', {
        textfield: [
          {
            notes:          this.entries.textarea
          }
        ]
      })
      this.entries.textarea = null
    },
    savenewdecision(){
      this.$emit('savenewdecision', {
        textfield: [
          {
            name:       this.entries.textfield[1],
          }
        ],
        dropdown:       this.entries.dd
      })
      this.entries.textfield = []
      this.entries.dd = []
    },
    savesnagentry(){
      this.$emit('savesnagentry', {
        textfield: [
          {
            description:    this.entries.textfield[1],
            who:            this.entries.dd,
            deadline:       this.startdate,
            image:          this.entries.image
          }
        ]
      })
      this.entries.textfield = []
      this.entries.dd = []
      this.startdate = null
      this.entries.image = []
    },
    saveeditsnag(){
      this.$emit('saveeditsnag', {
        textfield: [
          {
            description:    this.entries.textfield[1],
            who:            this.entries.dd,
            deadline:       this.startdate,
            image:          this.entries.image
          }
        ]
      })
      this.entries.textfield = []
      this.entries.dd = []
      this.startdate = null
      this.entries.image = []
    },
    savesquaremeter(){
      this.$emit('savesquaremeter', {
        textfield: [
          {
            name:           this.entries.textfield[1]
          }
        ]
      })
      this.entries.textfield = []
    },
    savebudgetmanageritem(){
      this.$emit('savebudgetmanageritem', {
        textfield: [
          {
            item_description:           this.entries.textfield[1],
            budget:                     this.entries.textfield[2],
            actual_price:               this.entries.textfield[3]
          }
        ]
      })
      this.entries.textfield = []
    },
    addtransactionhistory(){
      this.$emit('addtransactionhistory')
    },
    savetransaction(){
      this.$emit('savetransaction', {
        textfield: [{
          supplier_name:                this.entries.textfield[1],
          amount:                       this.entries.textfield[2],
          payment_method:               this.entries.dd,
          transaction_date:             this.startdate
        }]
      })
      this.entries.textfield = []
      this.entries.dd = []
      this.startdate = null
    },
    deletetransaction(data){
      this.$store.dispatch('budgetmanager/setbudgetmanagertodeletetransaction', data)
      this.$emit('deletetransaction')
    },
    saveprojectbuildbudget(){
      this.$emit('saveprojectbuildbudget', {
        textfield: [
          {
            budget:                     this.entries.textfield[1]
          }
        ]
      })
      this.entries.textfield = []
    },
    saveadjustedbudgetcostestimation(){
      this.$emit('saveadjustedbudgetcostestimation', {
        textfield: [
          {
            budget:                     this.entries.textfield[1]
          }
        ]
      })
      this.entries.textfield = []
    },
    savevalueinbillofmaterials(){
      this.$emit('savevalueinbillofmaterials', {
        textfield: [
          {
            value:                      this.entries.textfield[1]
          }
        ]
      })
      this.entries.textfield = []
    },
    savesupplier(){
      this.$emit('savesupplier', {
        textfield: [
          {
            supplier1:                  this.entries.textfield[1],
            supplier1price:             this.entries.textfield[2],
            supplier2:                  this.entries.textfield[3],
            supplier2price:             this.entries.textfield[4],
            supplier3:                  this.entries.textfield[5],
            supplier3price:             this.entries.textfield[6]
          }
        ]
      })
      this.entries.textfield = []
    },
    sendemailsupplier(){
      this.$emit('sendemailsupplier', {
        textfield: [
          {
            message:                    this.entries.textarea
          }
        ]
      })
      this.entries.textarea = null
    },
    send_supplier_request(){
      this.$emit('send_supplier_request', {
        textfield: [
          {
            attachments:                this.entries.attachment,
            project_name:               this.entries.textfield[3],
            address:                    this.entries.textfield[7],
            //city:                       this.entries.textfield[8],
            postcode:                   this.entries.textfield[11],
            description:                this.entries.textfield[4],
            scope_of_work:              this.entries.combobox,
            service_type:               this.entries.dd,
            build_stage:                this.entries.dd2,
            country:                    this.entries.dd3,
            state:                      this.entries.dd4,
            city:                       this.entries.dd5,
          }
        ]  
      })
    //   this.entries.textfield = []
    //   this.entries.attachment = []
    //   this.entries.textarea = null
    //   this.entries.combobox = []
    //   this.entries.dd = []
    //   this.entries.dd2 = []
    //   this.entries.dd3 = []
    //   this.entries.dd4 = []
    //   this.entries.dd5 = []
    },
    searchcounty(){
      //onsole.log(this.entries.dd3)
      this.$emit('searchcounty', this.entries.dd3)
    },
    getStates(){
        this.$emit('getStates', this.entries.dd3)
    },
    getCities(){
        this.$emit('getCities', this.entries.dd4)
    },
    deleteNoteItem(note, noteindex){
        let tp = {
            note:       note,
            noteindex:  noteindex
        }
        this.$emit('deleteNoteItem', tp)
    },
    edit_note(note, noteindex){
        let tp = {
            note:       note,
            noteindex:  noteindex
        }
        this.$emit('edit_note', tp)
    },
    savesuggestion(){
        this.$emit('savesuggestion', this.entries.textarea)
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>