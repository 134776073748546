<template>
    <v-card>
        <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <vue-json-to-csv
                :json-data="get_app_data"
                :csv-title="'designtracker_appliance_data'"
                :labels="export_labels">
                <v-btn color="success" small>
                    <v-icon small>mdi-file-delimited</v-icon>
                    Export to CSV
                </v-btn>
            </vue-json-to-csv>
        </v-card-title>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="get_app_data"
                :items-per-page="5"
                :search="search">
            </v-data-table>       
        </v-card-text>
    </v-card>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'
import { mapGetters } from 'vuex';

export default {
    data: () => ({
        headers: [
            {'text': 'Room', 'align': 'center', 'sortable': true, 'value': 'parent_id'},
            {'text': 'Item',  'align': 'center', 'sortable': true, 'value': 'item'},
            {'text': 'Quantity',  'align': 'center', 'sortable': true, 'value': 'quantity'},
            {'text': 'Colour',  'align': 'center', 'sortable': true, 'value': 'colour'},
            {'text': 'Supplier',  'align': 'center', 'sortable': true, 'value': 'supplier_id'},
            {'text': 'Product Code',  'align': 'center', 'sortable': true, 'value': 'product_code'},
            {'text': 'Link To Product',  'align': 'center', 'sortable': true, 'value': 'link_to_product'},
            {'text': 'Notes',  'align': 'center', 'sortable': true, 'value': 'notes'},
            {'text': 'Budget',  'align': 'center', 'sortable': true, 'value': 'budget'},
            {'text': 'Actual',  'align': 'center', 'sortable': true, 'value': 'actual'}
        ],
        project_id: null,
        users_id: null,
        search: null,
        export_labels: { 
            parent_id: {title: 'Room'},
            item: {title: 'Item'},
            quantity: {title: 'Quantity'},
            color: {title: 'Colour'},
            supplier_id: {title: 'Supplier'},
            product_code: {title: 'Product Code'},
            link_to_product: {title: 'Link to Product'},
            notes: {title: 'Notes'},
            budget: {title: 'Budget'},
            actual: {title: 'Actual'}
        }
    }),
    components: {
        VueJsonToCsv
    },
    props: ['user_data', 'project'],
    methods: {
        
    },
    computed: {
        ...mapGetters({
            get_app_data:   'admin_designtracker/get_app_data_getter'
        })
    },
    watch: {
        project() {            
            this.project_id = this.project.id
            this.users_id = this.user_data.id
        }
    }
}
</script>