import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        deleted_transactions: [],
        budgetmanager_transactions: []
    },
    mutations: {
        set_deleted_transactions(state, payload) {
            state.deleted_transactions = [...payload];
        },
        set_budgetmanager_transactions_mutation(state, payload) {
            state.budgetmanager_transactions = [...payload];
        },
        add_budgetmanager_transaction_mutation(state, payload) {
            state.budgetmanager_transactions.push(payload);
        },
        delete_budgetmanager_transaction_mutation(state, payload) {
            const index = state.budgetmanager_transactions.findIndex(item => item.id == payload.id);
            state.budgetmanager_transactions.splice(index, 1);
        },
        update_transaction_item(state, payload) {
            state.budgetmanager_transactions.filter( (item) => {
                if ( item.id == payload.budget_transaction.id) {         
                    item.amount = payload.budget_transaction.amount
                    item.name = payload.budget_transaction.name
                    item.created_at = payload.budget_transaction.created_at
                    item.payment_method = payload.budget_transaction.payment_method
                }
            })
        }
    },
    getters: {
        get_deleted_transaction_getter: state => state.deleted_transactions,
        get_budgetmanager_transactions_getter: state => state.budgetmanager_transactions,
        get_budgetmanager_transaction_by_id_getter: state => (bm_id) => {
            return state.budgetmanager_transactions.filter(item => item.budget_manager_id == bm_id);
        }        
    },
    actions: {
        fetch_deleted_transactions_action(context, payload) {
            Vue.axios.get(`budgetmanager/get_deleted_transactions/${payload.users_id}/${payload.project_id}`).then((data) => {
                if (data.status != 200) {
                    context.dispatch('auth/set_errormessage_dialog_action', {
                        title: 'Budget Manager: Something went wrong!',
                        message: `RESPONSE CODE (${data.status}): ${data.statusText} - Exception: ${data.data.exception}`,
                        opendialog: true,
                    }, { root: true });
                } else {
                    const result = data.data;
                    if (result.response == true) {
                        context.commit('set_deleted_transactions', result.data);
                    } else {
                        context.dispatch('auth/set_errormessage_dialog_action', {
                            title: 'Budget Manager: Something went wrong!',
                            message: `ERROR: ${JSON.stringify(result.data)}`,
                            opendialog: true,
                        }, { root: true });
                    }
                }
            }).catch(err => {
                context.dispatch('auth/set_errormessage_dialog_action', {
                    title: 'Budget Manager: Something went wrong!',
                    message: `ERROR: ${err}`,
                    opendialog: true,
                }, { root: true });
            })
        },
        async fetch_all_budgetmanager_transaction_action(context, payload) {
            await Vue.axios.get(`budgetmanager/get_all_budgetmanager_transactions/${payload.users_id}/${payload.project_id}`)
                .then((data) => {
                    if (data.status == 200) {
                        const result = data.data;
                        if (result.response == true) {
                            context.commit('set_budgetmanager_transactions_mutation', result.data);
                        } else {
                            context.dispatch('auth/set_errormessage_dialog_action', {
                                title: 'Budget Manager: Something went wrong!',
                                message: `ERROR: ${JSON.stringify(result.data)}`,
                                opendialog: true,
                            }, { root: true });
                        }
                    } else {
                        context.dispatch('auth/set_errormessage_dialog_action', {
                            title: 'Budget Manager: Something went wrong!',
                            message: `ERROR: ${data.statusText}`,
                            opendialog: true,
                        }, { root: true });
                    }
                }).catch((err) => {
                    context.dispatch('auth/set_errormessage_dialog_action', {
                        title: 'Budget Manager: Something went wrong!',
                        message: `ERROR: ${err}`,
                        opendialog: true,
                    }, { root: true });
                });
        },
        add_budgetmanager_transacton_action(context, payload) {
            Vue.axios.post('budgetmanager/add_budgetmanager_transaction', payload)
                .then((data) => {
                    if (data.status == 200) {
                        const result = data.data;
                        if (result.response == true) {
                            context.commit('add_budgetmanager_transaction_mutation', result.data);
                            context.dispatch('budgetmanager/update_budgetmanager_item_action', result.data, { root: true });
                            context.dispatch('alert_notifications/set_alert_notification_action', {
                                open: true,
                                icon: 'mdi-check-all',
                                type: 'success',
                                message: 'You have successfully add new budget transaction.'
                              }, {root: true});
                        } else {
                            context.dispatch('auth/set_errormessage_dialog_action', {
                                title: 'Budget Manager: Something went wrong!',
                                message: `ERROR: ${JSON.stringify(result.data)}`,
                                opendialog: true,
                            }, { root: true });
                        }
                    } else {
                        context.dispatch('auth/set_errormessage_dialog_action', {
                            title: 'Budget Manager: Something went wrong!',
                            message: `ERROR: ${data.statusText}`,
                            opendialog: true,
                        }, { root: true });
                    }
                }).catch((err) => {
                    context.dispatch('auth/set_errormessage_dialog_action', {
                        title: 'Budget Manager: Something went wrong!',
                        message: `ERROR: ${err}`,
                        opendialog: true,
                    }, { root: true });
                });
        },
        delete_budget_transaction_action(context, payload) {        
            Vue.axios.post(`budgetmanager/delete_budgetmanager_transaction`, payload)
                .then( async (data) => {
                    if (data.status == 200) {
                        const result = data.data;
                        if (result.response == true) {
                            await context.commit('delete_budgetmanager_transaction_mutation', payload);
                            context.dispatch('budgetmanager/deduct_budgetmanager_item_paidtodate_action', payload, { root: true });                   
                            context.dispatch('alert_notifications/set_alert_notification_action', {
                                open: true,
                                icon: 'mdi-check-all',
                                type: 'success',
                                message: 'You have successfully deleted a budget transaction.'
                            }, {root: true});
                        } else {
                            context.dispatch('auth/set_errormessage_dialog_action', {
                                title: 'Budget Manager: Something went wrong!',
                                message: `ERROR: ${JSON.stringify(result.data)}`,
                                opendialog: true,
                            }, { root: true });
                        }
                    } else {
                        context.dispatch('auth/set_errormessage_dialog_action', {
                            title: 'Budget Manager: Something went wrong!',
                            message: `ERROR: ${data.statusText}`,
                            opendialog: true,
                        }, { root: true });
                    }
                }).catch((err) => {
                    context.dispatch('auth/set_errormessage_dialog_action', {
                        title: 'Budget Manager: Something went wrong!',
                        message: `ERROR: ${err}`,
                        opendialog: true,
                    }, { root: true });
                });
        },
        set_updated_transaction_item({commit}, payload) {
            commit('update_transaction_item', payload)
        }
    }
}