<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card>
                    <v-toolbar dark color="#073095">
                        <v-toolbar-title>Forgot Password</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-text-field
                        prepend-icon="person"
                        v-model="credentials.username"
                        name="Email or Username"
                        label="Email or Username"
                        type="text"
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#062a82" text @click="$router.push({name: 'login'})">Back</v-btn>
                    <v-btn color="#093cba" class="white--text pl-10 pr-10" @click="cfp">Continue</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            persistent
            v-model="showotpdialog"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >An OTP code has been sent to your email: <br/>{{credentials.username}}</v-toolbar>
                <v-card-text>
                    <v-text-field
                        standard
                        v-model="otpInput"
                        label="OTP Code"
                    >
                    </v-text-field>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    text
                    @click="verifyOtp"
                >Verify</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            persistent
            v-model="newpassworddialog"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >Change Password</v-toolbar>
                <v-card-text class="mt-5">
                    <v-text-field
                        prepend-icon="lock"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        name="Password"
                        label="Password"
                        v-model="newp.password"
                        class="input-group--focused"
                        @click:append="show2 = !show2"
                    ></v-text-field>
                    <v-text-field
                        prepend-icon="lock"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        name="Repeat Password"
                        label="Repeat Password"
                        v-model="newp.rpassword"
                        class="input-group--focused"
                        @click:append="show = !show"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    text
                    @click="changepass"
                >Change Password</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
      credentials: {
        username: ''
      },
      newp: {
        password: '',
        rpassword: ''
      },
      showotpdialog: false,
      otpInput: null,
      newpassworddialog: false,
      show2: false,
      show: false
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async cfp(){
      await this.$axios.post('forgotpassword', this.credentials)
      .then(({data}) => {
        if(data.data != null){
          this.showSnackBar('Please wait..')
            this.$axios.post('rotp', {email: this.credentials.username})
            .then(({data}) => {
              this.showSnackBar(data.message)
              this.showotpdialog = true
            })
        }else{
          this.showSnackBar('User does not exist!')
        }
      })
    },
    async verifyOtp(){
      const toPass = {
        id: this.credentials.username,
        otp: this.otpInput
      };
      await this.$axios.post('verifyOtp', toPass)
      .then(({data}) => {
          if(data.response){
            this.showotpdialog = false
            this.newpassworddialog = true
          }else{
            this.showSnackBar(data.message)
          }
      })
    },
    async changepass(){
      if(this.newp.password !== this.newp.rpassword){
        this.showSnackBar('Password did not match')
        return
      }
      let datatopass = {
        email: this.credentials.username,
        password: this.newp.password
      }
      await this.$axios.post('changepassword', datatopass)
      .then(({data}) => {
        if(data.response){
          this.showSnackBar(data.message)
          this.$router.push({name: 'login'})
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>