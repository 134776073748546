import Vue from "vue"

export default {
  namespaced: true,
  state: {
    budgetmanagertemplates: [],
    chosentemplate: {},
    budgetmanagertab1data: [],
    parentbudgetmanagerdata: {},
    budgetmanageredit: {},
    budgetmanageritemdelete: {},
    budgetmanageraddtransactionhistory: {},
    budgetmanagertransactionhistory: [],
    budgetmanagertodeletetransaction: {},
    budgetmanagersummary: [],
    transaction_deleted_history: [],
    /** Newly set of states */
    budgetmanager_data: [],
    selected_budgetmanager_template: {}
  },
  mutations: {
    clearsets(state) {
      state.budgetmanagertemplates = []
      state.chosentemplate = {}
      state.budgetmanagertab1data = []
      state.parentbudgetmanagerdata = {}
      state.budgetmanageredit = {}
      state.budgetmanageritemdelete = {}
      state.budgetmanageraddtransactionhistory = {}
      state.budgetmanagertransactionhistory = []
      state.budgetmanagertodeletetransaction = {}
      state.budgetmanagersummary = []
    },
    setbudgetmanagertemplates(state, payload) {
      state.budgetmanagertemplates = [...payload]
    },
    setchosentemplate(state, payload) {
      state.chosentemplate = payload
    },
    setbudgetmanagertab1data(state, payload) {
      state.budgetmanagertab1data = [...payload]
    },
    clearbudgetmanager(state) {
      state.chosentemplate = {}
      state.budgetmanagertab1data = []
    },
    setbudgetmanageredit(state, payload) {
      state.budgetmanageredit = payload
    },
    setparentbudgetmanagerdata(state, payload) {
      state.parentbudgetmanagerdata = payload
    },
    setbudgetmanageritemdelete(state, payload) {
      state.budgetmanageritemdelete = payload
    },
    setbudgetmanageraddtransactionhistory(state, payload) {
      state.budgetmanageraddtransactionhistory = payload
    },
    setbudgetmanagertransactionhistory(state, payload) {
      state.budgetmanagertransactionhistory = [...payload]
    },
    setbudgetmanagertodeletetransaction(state, payload) {
      state.budgetmanagertodeletetransaction = payload
    },
    setbudgetmanagersummary(state, payload) {
      state.budgetmanagersummary = [...payload]
    },
    update_budgetmanager(state, payload) {
      const budget_item = state.budgetmanager_data.find(item => item.id == payload.parent_id);
      const budgetmanager_item_details = budget_item.get_child.find(item => item.id == payload.id);
      budgetmanager_item_details.paid_to_date = payload.paid_to_date;
      budgetmanager_item_details.outstanding = payload.outstanding;
    },
    set_deleted_transaction_history(state, payload) {
      state.transaction_deleted_history = [...payload];
    },
    delete_transaction_history(state, payload) {
      const index = state.budgetmanagertransactionhistory.findIndex(item => item.id == payload.transaction_id);
      state.budgetmanagertransactionhistory.splice(index, 1);
    },
    /** Newly refactored state */
    set_budgetmanager_data_mutation(state, payload) {
      state.budgetmanager_data = [...payload];
    },
    set_budgetmanager_templates_mutation(state, payload) {
      state.budgetmanagertemplates = [...payload];
    },
    select_budgetmanager_template_action(state, payload) {
      const selected_bm_template = state.budgetmanagertemplates.find(item => item.id == payload.id);
      state.chosentemplate = selected_bm_template;
    },
    update_budgetmanager_propertysize_mutation(state, payload) {
      const budgetmanager_item = state.budgetmanager_data.find(item => item.id == payload.bm_id);
      budgetmanager_item.property_size = payload.property_size;
    },
    additem_budgetmanager_mutation(state, payload) {
      const budgetmanager_item = state.budgetmanager_data.find(item => item.id == payload.parent_id);
      budgetmanager_item.get_child.push(payload);
    },
    deleteitem_budgetmanager_mutation(state, payload) {
      const budgetmanager_item = state.budgetmanager_data.find(item => item.id == payload.parent_id);
      const index = budgetmanager_item.get_child.findIndex(item => item.id == payload.id);
      budgetmanager_item.get_child.splice(index, 1);
    },
    update_budgetmanager_item_paidtodate_mutation(state, payload) {
      const budgetmanager_item = state.budgetmanager_data.find(item => item.id == payload.parent_id);
      const budgetmanager_item_details = budgetmanager_item.get_child.find(item => item.id == payload.budget_manager_id);
      budgetmanager_item_details.paid_to_date += payload.amount;
      budgetmanager_item_details.outstanding = budgetmanager_item_details.actual_price - budgetmanager_item_details.paid_to_date;
    },
    deduct_budgetmanager_item_paidtodate_mutation(state, payload) {
      const budgetmanager_item = state.budgetmanager_data.find(item => item.id == payload.parent_id);
      const budgetmanager_item_details = budgetmanager_item.get_child.find(item => item.id == payload.budget_manager_id);
      budgetmanager_item_details.paid_to_date -= payload.amount;
      budgetmanager_item_details.outstanding = budgetmanager_item_details.actual_price - budgetmanager_item_details.paid_to_date;
    },
    edit_budgetmanager_item_mutation(state, payload) {
      const budgetmanager_item = state.budgetmanager_data.find(item => item.id == payload.parent_id);
      const budgetmanager_item_details = budgetmanager_item.get_child.find(item => item.id == payload.id);
      budgetmanager_item_details.item_description = payload.item_description;
      budgetmanager_item_details.budget = payload.budget;
      budgetmanager_item_details.actual_price = payload.actual_price;
      budgetmanager_item_details.variation = payload.variation;
      budgetmanager_item_details.percent = payload.percent;
      budgetmanager_item_details.paid_to_date = payload.paid_to_date;
      budgetmanager_item_details.outstanding = payload.outstanding;
    },
    update_budgetmanager_item_mutation(state, payload) {
      const budgetmanager_item = state.budgetmanager_data.find(item => item.id == payload.parent_id);
      const budgetmanager_item_details = budgetmanager_item.get_child.find(item => item.id == payload.id);
      budgetmanager_item_details.paid_to_date = payload.paid_to_date;
      budgetmanager_item_details.outstanding = payload.outstanding;
    }
  },
  getters: {
    getbudgetmanagertemplates(state) {
      return state.budgetmanagertemplates
    },
    getchosentemplate(state) {
      return state.chosentemplate
    },
    getbudgetmanagertab1data(state) {
      return state.budgetmanagertab1data
    },
    getbudgetmanageredit(state) {
      return state.budgetmanageredit
    },
    getparentbudgetmanagerdata(state) {
      return state.parentbudgetmanagerdata
    },
    getbudgetmanageritemdelete(state) {
      return state.budgetmanageritemdelete
    },
    getbudgetmanageraddtransactionhistory(state) {
      return state.budgetmanageraddtransactionhistory
    },
    getbudgetmanagertransactionhistory(state) {
      return state.budgetmanagertransactionhistory
    },
    getbudgetmanagertodeletetransaction(state) {
      return state.budgetmanagertodeletetransaction
    },
    getbudgetmanagersummary(state) {
      return state.budgetmanagersummary
    },
    /** Newly refactored codes */
    get_deletedtransaction_history_getters: state => state.transaction_deleted_history,
    get_budgetmanager_data_getter: (state) => (template_id, project_id) => {
      const budgetmanager_data = state.budgetmanager_data.filter(item => item.template_id == template_id && item.project_id == project_id);
      return budgetmanager_data[0];
    },
    get_budgetmanager_item_by_id_getter: state => (bm_id, parent_id) => {
      if (typeof bm_id != 'undefined') {
        const budgetmanager_item = state.budgetmanager_data.find(item => item.id == parent_id);
        return budgetmanager_item.get_child.find(item => item.id == bm_id);
      }
    }
  },
  actions: {
    setbudgetmanagertemplates({ commit }, payload) {
      commit('setbudgetmanagertemplates', payload)
    },
    setchosentemplate({ commit }, payload) {
      commit('setchosentemplate', payload)
    },
    setbudgetmanagertab1data({ commit }, payload) {
      const data = payload.map((item, index) => ({
        ...item,
        index: index + 1
      }))
      commit('setbudgetmanagertab1data', data)
    },
    setbudgetmanageredit({ commit }, payload) {
      commit('setbudgetmanageredit', payload)
    },
    setparentbudgetmanagerdata({ commit }, payload) {
      commit('setparentbudgetmanagerdata', payload)
    },
    setbudgetmanageritemdelete({ commit }, payload) {
      commit('setbudgetmanageritemdelete', payload)
    },
    setbudgetmanageraddtransactionhistory({ commit }, payload) {
      commit('setbudgetmanageraddtransactionhistory', payload)
    },
    setbudgetmanagertransactionhistory({ commit }, payload) {
      commit('setbudgetmanagertransactionhistory', payload)
    },
    setbudgetmanagertodeletetransaction({ commit }, payload) {
      commit('setbudgetmanagertodeletetransaction', payload)
    },
    setbudgetmanagersummary({ commit }, payload) {
      commit('setbudgetmanagersummary', payload)
    },
    async fetch_budgetmanager_transaction_history_action({ commit }, payload) {
      await Vue.axios.get(`budgetmanager/getTransactions/${payload.users_id}`, payload.tp)
        .then(({ data }) => {
          commit('setbudgetmanagertransactionhistory', data.data);
        })
    },
    async update_budgetmanager_outstanding_amount_action(context, payload) {
      await Vue.axios.patch(`budgetmanager/update_budgetmanager_outstanding_amount/${payload.budgetmanager_id}`, { amount: payload.amount })
        .then((data) => {
          if ( data.status == 200 ) {
            const result = data.data
            if ( result.response == true ) {
              context.commit('update_budgetmanager', result.data);
              context.dispatch('alert_notifications/set_alert_notification_action', {
                open: true,
                icon: 'mdi-check-all',
                type: 'success',
                message: 'You have successfully updated the outstanding amoung.'
              }, {root: true});
            } else {
              context.dispatch('alert_notifications/set_alert_notification_action', {
                open: true,
                icon: 'mdi-alert-circle',
                type: 'error',
                message: result.data
              }, {root: true});
            }
          } else {
            context.dispatch('alert_notifications/set_alert_notification_action', {
              open: true,
              icon: 'mdi-alert-circle',
              type: 'error',
              message: data.message
            }, {root: true});
          }
        }).catch((err) => {
          context.dispatch('alert_notifications/set_alert_notification_action', {
            open: true,
            icon: 'mdi-alert-circle',
            type: 'error',
            message: `${err.message}: ${err.response.data}`
          }, {root: true});
        })
    },
    async get_budgetmanager_data_action(context, payload) {
      await Vue.axios.get(`budgetmanager/get_budgetmanager_data/${payload.users_id}/${payload.project_id}`)
        .then((data) => {
          if (data.status == 200) {
            const result = data.data;
            if (result.response == true) {
              context.commit('set_budgetmanager_data_mutation', result.data);
            } else {
              context.dispatch('auth/set_errormessage_dialog_action', {
                title: 'Site Diary: Something went wrong!',
                message: `ERROR: ${result.data}`,
                opendialog: true,
              }, { root: true });
            }
          } else {
            context.dispatch('auth/set_errormessage_dialog_action', {
              title: 'Site Diary: Something went wrong!',
              message: `ERROR: ${data.statusText}`,
              opendialog: true,
            }, { root: true });
          }
        }).catch((err) => {
          context.dispatch('auth/set_errormessage_dialog_action', {
            title: 'Site Diary: Something went wrong!',
            message: `ERROR: ${err}`,
            opendialog: true,
          }, { root: true });
        });
    },
    async get_budgetmanager_templates_action(context) {
      await Vue.axios.get(`budgetmanager/get_budgetmanager_templates`)
        .then((data) => {
          if (data.status == 200) {
            const result = data.data;
            if (result.response == true) {
              context.commit('set_budgetmanager_templates_mutation', result.data);
            } else {
              context.dispatch('auth/set_errormessage_dialog_action', {
                title: 'Budget Manager: Something went wrong!',
                message: `ERROR: ${result.data}`,
                opendialog: true,
              }, { root: true });
            }
          } else {
            context.dispatch('auth/set_errormessage_dialog_action', {
              title: 'Budget Manager: Something went wrong!',
              message: `ERROR: ${data.statusText}`,
              opendialog: true,
            }, { root: true });
          }
        }).catch((err) => {
          context.dispatch('auth/set_errormessage_dialog_action', {
            title: 'Budget Manager: Something went wrong!',
            message: `ERROR: ${err}`,
            opendialog: true,
          }, { root: true });
        });
    },
    select_budgetmanager_template_action(context, payload) {
      context.commit('select_budgetmanager_template_action', payload);
    },
    update_budgetmanager_propertysize_action(context, payload) {
      Vue.axios.patch(`budgetmanager/update_budgetmanager_propertysize/${payload.bm_id}/${payload.property_size}`)
        .then((data) => {
          if (data.status == 200) {
            const result = data.data;
            if (result.response == true) {
              context.commit('update_budgetmanager_propertysize_mutation', payload);
              context.dispatch('alert_notifications/set_alert_notification_action', {
                open: true,
                icon: 'mdi-check-all',
                type: 'success',
                message: `You have successfully updated the property size value`
              }, {root: true});
            } else {
              context.dispatch('auth/set_errormessage_dialog_action', {
                title: 'Budget Manager: Something went wrong!',
                message: `ERROR: ${result.data}`,
                opendialog: true,
              }, { root: true });
            }
          } else {
            context.dispatch('auth/set_errormessage_dialog_action', {
              title: 'Budget Manager: Something went wrong!',
              message: `ERROR: ${data.statusText}`,
              opendialog: true,
            }, { root: true });
          }
        }).catch((err) => {
          context.dispatch('auth/set_errormessage_dialog_action', {
            title: 'Budget Manager: Something went wrong!',
            message: `ERROR: ${err}`,
            opendialog: true,
          }, { root: true });
        });
    },
    additem_budgetmanager_action(context, payload) {
      Vue.axios.post(`budgetmanager/additem_budgetmanager`, payload)
        .then((data) => {
          if (data.status == 200) {
            const result = data.data;
            if (result.response == true) {
              context.commit('additem_budgetmanager_mutation', result.data);
              context.dispatch('alert_notifications/set_alert_notification_action', {
                open: true,
                icon: 'mdi-check-all',
                type: 'success',
                message: 'You have successfully add a new budget item'
              }, {root: true});
            } else {
              context.dispatch('auth/set_errormessage_dialog_action', {
                title: 'Budget Manager: Something went wrong!',
                message: `ERROR: ${result.data}`,
                opendialog: true,
              }, { root: true });
            }
          } else {
            context.dispatch('auth/set_errormessage_dialog_action', {
              title: 'Budget Manager: Something went wrong!',
              message: `ERROR: ${data.statusText}`,
              opendialog: true,
            }, { root: true });
          }
        }).catch((err) => {
          context.dispatch('auth/set_errormessage_dialog_action', {
            title: 'Budget Manager: Something went wrong!',
            message: `ERROR: ${err}`,
            opendialog: true,
          }, { root: true });
        });
    },
    deleteitem_budgetmanager_action(context, payload) {
      Vue.axios.delete(`budgetmanager/delete_item_budgetmanager/${payload.id}`)
        .then((data) => {
          if (data.status == 200) {
            const result = data.data;
            if (result.response == true) {
              context.commit('deleteitem_budgetmanager_mutation', payload);
              context.dispatch('alert_notifications/set_alert_notification_action', {
                open: true,
                icon: 'mdi-check-all',
                type: 'success',
                message: 'You have successfully deleted a budget item.'
              }, {root: true});
            } else {
              context.dispatch('auth/set_errormessage_dialog_action', {
                title: 'Budget Manager: Something went wrong!',
                message: `ERROR: ${result.data}`,
                opendialog: true,
              }, { root: true });
            }
          } else {
            context.dispatch('auth/set_errormessage_dialog_action', {
              title: 'Budget Manager: Something went wrong!',
              message: `ERROR: ${data.statusText}`,
              opendialog: true,
            }, { root: true });
          }
        }).catch((err) => {
          context.dispatch('auth/set_errormessage_dialog_action', {
            title: 'Budget Manager: Something went wrong!',
            message: `ERROR: ${err}`,
            opendialog: true,
          }, { root: true });
        });
    },
    update_budgetmanager_item_action({ commit }, payload) {
      commit('update_budgetmanager_item_paidtodate_mutation', payload);
    },
    deduct_budgetmanager_item_paidtodate_action({ commit }, payload) {
      commit('deduct_budgetmanager_item_paidtodate_mutation', payload);
    },
    edit_budgetmanager_item_action(context, payload) {
      Vue.axios.post(`budgetmanager/edit_budgetmanager_item`, payload)
        .then((data) => {
          if (data.status == 200) {
            const result = data.data;
            if (result.response == true) {
              context.commit('edit_budgetmanager_item_mutation', result.data);
              context.dispatch('alert_notifications/set_alert_notification_action', {
                open: true,
                icon: 'mdi-check-all',
                type: 'success',
                message: 'You have successfully updated the budget item description, budget and actual budget.'
              }, {root: true});
            } else {
              context.dispatch('auth/set_errormessage_dialog_action', {
                title: 'Budget Manager: Something went wrong!',
                message: `ERROR: ${result.data}`,
                opendialog: true,
              }, { root: true });
            }
          } else {
            context.dispatch('auth/set_errormessage_dialog_action', {
              title: 'Budget Manager: Something went wrong!',
              message: `ERROR: ${data.statusText}`,
              opendialog: true,
            }, { root: true });
          }
        }).catch((err) => {
          context.dispatch('auth/set_errormessage_dialog_action', {
            title: 'Budget Manager: Something went wrong!',
            message: `ERROR: ${err}`,
            opendialog: true,
          }, { root: true });
        });
    },
    async recompute_bugetmanaget_items_action(context, payload) {
      await Vue.axios.get(`budgetmanager/recompute_all_bm_items/${payload.users_id}/${payload.project_id}/${payload.template_id}`)
        .then((data) => {
          if (data.status == 200) {
            const result = data.data;
            if (result.response == true) {
              context.dispatch('budget_transaction/fetch_all_budgetmanager_transaction_action', payload, { root: true });
            } else {
              context.dispatch('auth/set_errormessage_dialog_action', {
                title: 'Budget Manager: Something went wrong!',
                message: `ERROR: ${result.data}`,
                opendialog: true,
              }, { root: true });
            }
          } else {
            context.dispatch('auth/set_errormessage_dialog_action', {
              title: 'Budget Manager: Something went wrong!',
              message: `ERROR: ${data.statusText}`,
              opendialog: true,
            }, { root: true });
          }
        }).catch((err) => {
          context.dispatch('auth/set_errormessage_dialog_action', {
            title: 'Budget Manager: Something went wrong!',
            message: `ERROR: ${err}`,
            opendialog: true,
          }, { root: true });
        });
    },
    update_budgetmanageritem_action({commit}, payload) {
      commit('update_budgetmanager_item_mutation', payload)
    }
  }
}