<template>
    <v-container fluid>
      <v-row
        justify="center"
        align="center"
        class="mt-15"
      >
        <v-card
            width="90%"
            elevation="0"
        >
            <v-card-title>
                <v-icon
                    class="mr-2"
                >mdi-account-hard-hat-outline</v-icon>
                <small>Service Requests</small>
            </v-card-title>
        </v-card>
      </v-row>
      <v-row
        justify="center"
        align="center"
        class="mt-5"
      >
        <v-card
            width="90%"
            elevation="1"
        >
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="get_service_requests"
                    :items-per-page="5"
                >
                    <template v-slot:top>
                        <v-text-field
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            placeholder="search service request here..."
                            label="search service request here..."
                        >
                        </v-text-field>
                    </template>
                    <template v-slot:item="{ item }" >
                        <tr>
                            <td>
                                {{ item.user.name }}
                            </td>
                            <td>
                                {{ item.service.service_type }}
                            </td>
                            <td>
                                <label
                                    v-if="item.description !== null"
                                >
                                    {{ item.description }}
                                </label>
                                <label
                                    v-else
                                >
                                    No Description
                                </label>
                            </td>
                            <td>
                                <ul
                                    v-for="(item, itemindex) in item.descriptions"
                                    :key="itemindex"
                                >
                                    <li>{{item}}</li>
                                </ul>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
      </v-row>
    </v-container>
</template>
  
<script>
// import { mapGetters } from 'vuex'
  export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        headers: [
            { text: 'Client Name' },
            { text: 'Service Type' },
            { text: 'Description' },
            { text: 'Details' }
        ],
        search: null
    }),
    async mounted () {
        await this.$store.dispatch('admin_service_request/get_record')
    },
    created () {
    },
    computed: {
        // ...mapGetters({
            // get_service_requests:           'admin_service_request/get_service_requests'
        // })
        get_service_requests(){
            return this.$store.getters['admin_service_request/get_service_requests'](this.search)
        }
    },
    methods: {
    },
    watch: {
    }
  }
  </script>
  
  <style scoped lang="scss">
  </style>