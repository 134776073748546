import store from '@/states/index.js'

import AssetUploaderComponent from '../AssetUploaderComponent.vue';

export default [
    {
        path: '/admin_asset_uploader',
        component: AssetUploaderComponent,
        name: 'admin_asset_uploader',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 1){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]