<template>
<div>
    <v-row>
        <v-col
            cols="12"
            v-for="(item, index) in data"
            :key="index"
            sm="12"
            lg="3"
        >
            <v-card
                class="mx-auto my-12"
                max-width="374"
                @click="show_product_page(item)"
            >
                <v-img
                    v-if="item.image != ''"
                    height="250"
                    :src="`${image}${item.image_holder}`"
                    class="ma-5"
                ></v-img>
                <v-img
                    v-else
                    height="250"
                    src="https://collaroyrugby.com.au/wp-content/uploads/2020/03/Pop-up-kids-coaching-clinic-at-Rat-Park-on-Sunday.png"
                    class="ma-5"
                ></v-img>

                <v-card-title>
                    <small
                        v-if="item.name.length > 50"
                        style="font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 18px;"
                    >
                        {{ item.name }}
                    </small>
                    <label
                        v-else
                        style="font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 18px;"
                    >
                        {{ item.name }}
                    </label>
                </v-card-title>

                <v-card-text>
                <v-row
                    align="center"
                    class="mx-0"
                >
                    <v-rating
                        :value="item.rating"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        size="14"
                    ></v-rating>

                    <div class="grey--text ms-4">
                        {{item.rating}}
                        <small
                            v-if="item.rating == 0"
                            class="ml-5"
                            style="font-weight: 700; font-size: small;"
                        >
                            Rate this product first
                        </small>
                    </div>
                </v-row>

                <div class="my-4 text-subtitle-1">
                    <strong
                        style="font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 12px;"
                    >€</strong>
                    <strong
                        style="font-family: 'Poppins', sans-serif; font-weight: 900; font-size: 16px;"
                    >
                        {{ item.price }}
                    </strong>
                    • 
                    <label
                        style="font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 12px;"
                    >
                        {{item.location}}
                    </label>
                </div>

                <!-- <div>
                    <vue-show-more-text
                        :text="item.description"
                        :lines="4"
                    />
                </div> -->
                </v-card-text>

                <v-divider class="mx-4"></v-divider>

                <v-card-title>
                    <label
                        style="font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 14px;"
                    >
                        Other information
                    </label>
                </v-card-title>

                <v-card-text>
                    <v-chip-group
                        active-class="deep-purple accent-4 white--text"
                        column
                    >
                        <v-chip
                            v-for="(iitem, iindex) in JSON.parse(item.chip_data)"
                            :key="iindex"
                            style="font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 12px;"
                        >{{ iitem }}</v-chip>

                    </v-chip-group>
                </v-card-text>

                <v-card-actions>
                <v-spacer/>
                <!-- <v-btn
                    color="deep-purple lighten-2"
                    outlined
                    rounded
                    class="pl-8 pr-8"
                    @click="add_to_cart(item)"
                    style="font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 14px;"
                >
                    Show more
                </v-btn> -->
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <!-- <v-row
        align="center"
        justify="center"
    >
        <v-btn
            color="primary"
            class="white--text pl-10 pr-10"
            style="font-size: 16px; font-weight: 300;"
            outlined
            rounded
            @click="load_more"
        >
            Show more
        </v-btn>
    </v-row> -->
</div>
</template>

<script>
import { mapGetters } from 'vuex'
// import vueShowMoreText from 'vue-show-more-text'
export default {
  components: {
    // vueShowMoreText
  },
  props: [
    'data'
  ],
  data () {
    return {
        image: process.env.VUE_APP_URL,
        search: null
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        user:           'auth/getuser',
        gNoParamsItem:  'shop/gNoParamsItem'
    }),
  },
  methods: {
    async add_to_cart(data){
        data.users_id = this.user.id
        await this.$store.dispatch('shop/add_to_cart', data)
        await this.$store.dispatch('shop/fetch_cart_api', this.user.id)
    },
    show_product_page(item){
        this.$store.dispatch('shop/setProductPage', item)
        this.$router.push({path: '/product_page'})
    }
    // load_more(){
    //     console.log('meow')
    //     console.log(this.gNoParamsItem)
    // }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>