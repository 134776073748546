<template>
    <v-container fluid>
        <div v-if="dialogsvalue.warningdelete">
            <v-row justify="center">
                <v-dialog
                    max-width="420"
                    :value="value"
                    persistent
                >
                <v-card>
                    <v-card-title style="fontSize: 18px;">
                        <small>{{dialogsvalue.title}}</small>
                    </v-card-title>

                    <v-card-text>
                        <small>{{dialogsvalue.content}}</small>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-for="(btn, btnIndex) in dialogsvalue.buttons"
                        :key="btnIndex"
                        :color="btn.color"
                        :class="btn.class"
                        :text="btn.text"
                        :dark="btn.dark"
                        @click="btn.action ? delproject() : donothing()"
                    >
                        {{btn.label}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </div>
        <div v-if="dialogsvalue.createproject">
            <v-row justify="center">
                <v-dialog
                    max-width="380"
                    :value="value"
                    persistent
                >
                <v-card>
                    <v-card-title>
                        <small>{{dialogsvalue.title}}</small>
                    </v-card-title>

                    <v-card-text>
                        {{dialogsvalue.content}}
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pl-10 pr-10"
                        dark
                        @click="logout"
                    >
                        Log me out
                    </v-btn>
                    <v-btn
                        v-for="(btn, btnIndex) in dialogsvalue.buttons"
                        :key="btnIndex"
                        :color="btn.color"
                        :class="btn.class"
                        :text="btn.text"
                        :dark="btn.dark"
                        @click="btn.action ? createproject() : ''"
                    >
                        {{btn.label}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </div>
    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
    'value', 'dialogsvalue'
  ],
  data: () => ({
  }),
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    delproject(){
      this.$emit('delproject', true)
    },
    donothing(){
      this.$emit('donothing')
    },
    createproject(){
      this.$emit('createproject')
    },
    async logout(){
      await this.$axios.post('nav/logout')
      this.donothing()
      localStorage.clear()
      this.$router.push({name: 'login'})
      location.reload(true)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>