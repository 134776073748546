import Vue from "vue"

export default {
    namespaced: true,
    state: {
      snags: [],
      snagswho: [],
      editsnag: {},
      markdone: {},
      csnags: [],
      all_snags_by_project: []
    },
    mutations: {
      setsnags(state, payload){
        state.snags = [...payload]
      },
      setsnagswho(state, payload){
        state.snagswho = [...payload]
      },
      seteditsnag(state, payload){
        state.editsnag = payload
      },
      setmarkdone(state, payload){
        state.markdone = payload
      },
      setcsnags(state, payload){
        state.csnags = [...payload]
      },
      set_snags_data_getter(state, payload) {
        state.all_snags_by_project = [...payload];
      }
    },
    getters: {
      getsmags(state){
        return state.snags
      },
      getsnagswho(state){
        return state.snagswho
      },
      geteditsnag(state){
        return state.editsnag
      },
      getmarkdone(state){
        return state.markdone
      },
      getcsnags(state){
        return state.csnags
      },
      get_snags_data_getter: state => state.all_snags_by_project
    },
    actions: {
      setsnags({commit}, payload){
        commit('setsnags', payload)
      },
      setsnagswho({commit}, payload){
        commit('setsnagswho', payload)
      },
      seteditsnag({commit}, payload){
        commit('seteditsnag', payload)
      },
      setmarkdone({commit}, payload){
        commit('setmarkdone', payload)
      },
      setcsnags({commit}, payload){
        commit('setcsnags', payload)
      },
      fetch_snags_data_action({commit}, payload) {
        Vue.axios.get(`/snags/get_snags/${payload.project_id}/${payload.user_id}`)
          .then( ({data}) => {
            commit('set_snags_data_getter', data.data);
          });
      }
    }
}