import Vue from 'vue'
import BillOfMaterials from './BillOfMaterials'

export default class Suppliers {
    constructor() {
        this.store = null
        this.supplier_selected_data = [
            {
                selected_material_data: null,
                searched_material_data: null,
                search_material: null,
                selected_data: null,
                search: null,
                searched_data: null,
                data: {
                    id: null,
                    company_name: null,
                    url: null,
                    unit_cost: null,
                    total_cost: 0,
                    material_id: null,                    
                }
            },
            {
                selected_material_data: null,
                searched_material_data: null,
                search_material: null,
                selected_data: null,
                search: null,
                searched_data: null,
                data: {
                    id: null,
                    company_name: null,
                    url: null,
                    unit_cost: null,
                    total_cost: 0,
                    material_id: null                    
                }
            },
            {
                selected_material_data: null,
                searched_material_data: null,
                search_material: null,
                selected_data: null,
                search: null,
                searched_data: null,
                data: {
                    id: null,
                    company_name: null,
                    url: null,
                    unit_cost: null,
                    total_cost: 0,
                    material_id: null                    
                }
            }
        ] // This is not a good design, it should be refactored later
        this.bill_of_material = new BillOfMaterials()             
    }

    setStore(store) {
        this.store = store
    }
    getStore() {
        return this.store
    }

    async searchSupplierByName(name = '', index = null) {        
        await Vue.axios.get(`billofmaterials/get_suppliers/${name}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {                    
                    this.getStore().dispatch('suppliers/setSupplierSearchResultsAction', result.data)
                    if ( index != null ) {
                        this.supplier_selected_data[index].searched_data = [...result.data]
                    } else {
                        this.supplier_selected_data.map( (data) => {
                            data.searched_data = [...result.data]
                        })
                    }
                } else {
                    console.log(result.data)
                }
            }
        }).catch( (err) => {
            console.log(err)
        })
    }

    /** Search Material from a Supplier by User ID */
    searchMaterialsBySupplierUserId(users_id) {
        Vue.axios.get(`billofmaterials/get_materials/${users_id}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    this.store.dispatch('suppliers/setSupplierMaterialsByUserIdAction', result.data)
                } else {
                    console.log(result.data)
                }
                console.log(users_id)
            } else {
                console.log(data)
            }
        })
    }

    getSupplierSearchData() {
        return this.supplier_selected_data
    }

    setSelectedSupplierMaterial(material, index) {
        this.supplier_selected_data[index].data.material_id = material.id
        this.supplier_selected_data[index].data.unit_cost = material.price
    }

    setSupplierSearchData(index) {
        const selected_search_supplier = this.supplier_selected_data[index]        
        if ( selected_search_supplier.selected_data ) {
            selected_search_supplier.data.id = selected_search_supplier.selected_data.id
            selected_search_supplier.data.company_name = selected_search_supplier.selected_data.company_name
            selected_search_supplier.data.url = selected_search_supplier.selected_data.website               
        }
    }

    setSupplierSearchDataUnitCost(index, material) {       
        if ( material != null ) {
            const selected_search_supplier = this.supplier_selected_data[index]                            
            return selected_search_supplier.data.total_cost = selected_search_supplier.data.unit_cost * material.quantity        
        } else {
            return 0
        }
    }

    resetSupplierSearchData() {
        this.supplier_selected_data.map( (item) => {
            item.selected_data = null
            item.data.company_name = null
            item.data.url = null
            item.data.unit_cost = null
            item.data.total_cost = 0
        })
    }

    setSupplierDataBasedOnMaterialRowItem(material) {
        this.supplier_selected_data.map( (item, index) => {
            switch ( index ) {
                case 0:
                    this.searchSupplierByName(material.supplier1name, 0)
                    item.selected_data = {
                        id: material.supplier1id,
                        company_name: material.supplier1name
                    }
                    item.data.id = material.supplier1id ?? null
                    item.data.company_name = material.supplier1name ?? null
                    item.data.unit_cost = parseFloat(material.supplier1).toFixed( 2 )
                    item.data.url = material.supplier1url ?? null
                    item.data.material_id = material.supplier1material,
                    item.selected_material_data = material.material_one ?? null
                    this.searchMaterialsBySupplierUserId(material.supplier_one.user_id)
                    item.searched_material_data = this.store.getters[`suppliers/supplierMaterialsByUsersIdGetters`]
                    break
                case 1:
                    this.searchSupplierByName(material.supplier2name, 1)
                    item.selected_data = {
                        id: material.supplier2id,
                        company_name: material.supplier2name
                    }
                    item.data.id = material.supplier2id ?? null
                    item.data.company_name = material.supplier2name ?? null
                    item.data.unit_cost = parseFloat(material.supplier2).toFixed( 2 )
                    item.data.url = material.supplier2url ?? null
                    item.selected_material_data = material.material_two ?? null
                    item.data.material_id = material.supplier2material,
                    this.searchMaterialsBySupplierUserId(material.supplier_two.user_id)
                    item.searched_material_data = this.store.getters[`suppliers/supplierMaterialsByUsersIdGetters`]
                    break
                case 2:
                    this.searchSupplierByName(material.supplier3name, 2)
                    item.selected_data = {
                        id: material.supplier3id,
                        company_name: material.supplier3name
                    }
                    item.data.id = material.supplier3id ?? null
                    item.data.company_name = material.supplier3name ?? null
                    item.data.unit_cost = parseFloat(material.supplier3).toFixed( 2 )
                    item.data.url = material.supplier3url ?? null
                    item.selected_material_data = material.material_three ?? null
                    item.data.material_id = material.supplier3material,
                    this.searchMaterialsBySupplierUserId(material.supplier_three.user_id)
                    item.searched_material_data = this.store.getters[`suppliers/supplierMaterialsByUsersIdGetters`]
                    break
            }
        })
    }

    /** API backend Saving or Updating of supplier data per material */
    updateSupplierDataPerMaterial(material) {       
        // Save or Update supplier data per material                
        Vue.axios.post(`billofmaterials/updatematerialmanager`, {
            id: material.id,
            supplier1id: this.supplier_selected_data[0].data.id,
            supplier1: this.supplier_selected_data[0].data.unit_cost,
            supplier1name: this.supplier_selected_data[0].data.company_name,
            supplier1url: this.supplier_selected_data[0].data.url,
            supplier1material: this.supplier_selected_data[0].data.material_id,
            supplier2id: this.supplier_selected_data[1].data.id,
            supplier2: this.supplier_selected_data[1].data.unit_cost,
            supplier2name: this.supplier_selected_data[1].data.company_name,
            supplier2url: this.supplier_selected_data[1].data.url,
            supplier2material: this.supplier_selected_data[1].data.material_id,
            supplier3id: this.supplier_selected_data[2].data.id,
            supplier3: this.supplier_selected_data[2].data.unit_cost,
            supplier3name: this.supplier_selected_data[2].data.company_name,
            supplier3url: this.supplier_selected_data[2].data.url,
            supplier3material: this.supplier_selected_data[2].data.material_id,
        }).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    // Pull the list of data
                    const bom_template = this.store.getters['billofmaterials/getBillOfMaterialTemplateById'](material.template_id)                    
                    this.bill_of_material.setStore(this.store)
                    this.bill_of_material.chosenBillOfMaterialsTemplte(bom_template[0])
                    this._display_alert_notification({
                        open: true, icon: 'mdi-check',
                        type: 'success', message: 'Successfully added supplier(s) in a material.'
                    })
                }
            } else {
                console.log(data)
            }
        })

    }

    _display_alert_notification = (alert_info) => {
        this.store.dispatch('alert_notifications/open_alert_notification_action', alert_info.open)
        this.store.dispatch('alert_notifications/set_notification_icon_action', alert_info.icon)
        this.store.dispatch('alert_notifications/set_alert_notification_type_action', alert_info.type)
        this.store.dispatch('alert_notifications/set_notification_message_action', alert_info.message)
    }
}