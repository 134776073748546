<template>
    <v-container fluid class="mt-5 flex">
      <div v-if="getuser.project_id == null" align="center">
        <strong>You need to create a project</strong>
        <p>Kindly click the Proceed button below to create a project, and we can continue to add entries</p>
        <v-btn 
          color="#0b4ef3" 
          dark 
          class="pl-16 pr-16" 
          rounded
          @click="$router.push({name: 'projectplanner'})"
        >
          Proceed
        </v-btn>
      </div>
      <div v-else>
        <v-card>
          <v-card-title style="background: #062a82;">
            <v-icon class="mr-2" style="color: white;">{{budgetmanagersetup.icon}}</v-icon>
            <small style="color: white;">{{budgetmanagersetup.title}}
              <!-- <GuidelineComponent :color="color" /> -->
            </small>
          </v-card-title>
          <v-card-text>
            <v-tabs
              v-model="tab"
              centered
              icons-and-text>
            <v-tabs-slider></v-tabs-slider>
              <v-tab
                v-for="(tab, tabindex) in tabdata"
                :key="tabindex"
                :href="tab.href"
              >
              {{tab.label}}
              <v-icon>{{tab.icon}}</v-icon>
              </v-tab>
              <v-tabs-items v-model="tab">
                <v-tab-item
                  value="tab-1">
                  <Tab1></Tab1>
                </v-tab-item>
                <v-tab-item
                  value="tab-2">
                  <Tab2></Tab2>
                </v-tab-item>
                <v-tab-item
                  value="tab-3"
                >
                  <div v-if="getuser.info.access_id === 1 || getuser.info.access_id === 2 || (getuser.info.access_id === 4 && getuser.info.has_bom === 1)">
                    <strong>You need to upgrade your account to a premium user to use this feature</strong>
                  </div>
                  <div v-else-if="getuser.info.access_id === 3 || (getuser.info.access_id === 4 && getuser.info.has_bom === 2)">
                    <Tab3/>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Tab1 from './components/Tab1Component.vue'
import Tab2 from './components/Tab2Component.vue'
import Tab3 from '@/client/budgetmanager/billofmaterials/BillOfMaterials.vue'
//import Tab3 from './components/Tab3Component.vue'
//import Tab4 from '@/client/budgetmanager/billofmaterials/BillOfMaterials.vue'
// import GuidelineComponent from '@/components/GuidelineComponent.vue'

export default {
  components: {
    Tab1,
    Tab2,
    Tab3    
    // GuidelineComponent
  },
  props: [
  ],
  data: () => ({
    tab: null,
    budgetmanagersetup:{
      title:    'Budget Manager',
      icon:     'mdi-credit-card-outline'
    },
    tabdata: [
      {
        label:        'Budget Manager',
        href:         '#tab-1'
      },
      {
        label:        'Cost Estimation',
        href:         '#tab-2'
      },
      {
        label:        'Bill Of Materials',
        href:         '#tab-3'
      }
    ],
    color: 'white'
  }),
  mounted () {
    
  },
  created () {
    /** Process the restoration of lost data */
    /*this.restore_budgetmanager_lost_data({
      users_id: this.getuser.info.id,
      project_id: this.getproject.id
    })*/    
    //this.gbudgetmanagertemplate()
    this.costestimation()
    this.getmaterialstemp()
  },
  computed: {
    ...mapGetters({
      getuser:                    'auth/getuser',
      pp:                         'pp/getToPassTemplate',
      getchosentemplate:          'budgetmanager/getchosentemplate',
      getproject:                 'auth/getproject',
    })
  },
  methods: {    
    /*async gbudgetmanagertemplate(){
      await this.$axios.get('budgetmanager/gettemplates')
      .then(({data}) => {
        let tovuex = []
        data.data.reverse()
        if(this.getuser.info.access_id == 1){
          data.data.forEach((q, i) => {
            tovuex.push({
              ...q,
              disabled: i > 0 ? true : false
            })
          })
        }else{
          data.data.forEach(q => {
            tovuex.push({
              ...q,
              disabled: false
            })
          })
        }
        this.$store.dispatch('budgetmanager/setbudgetmanagertemplates', tovuex)
      })
    },*/
    async costestimation(){
      
      if(this.getchosentemplate !== null){
        await this.$axios.get(`costestimation/costEstimation/${this.getproject.id}`)
        .then(({data}) => {
          if(data.data.length == 0){
            this.geeneratecostestimation()
            return
          }
          this.$store.dispatch('costestimation/setcostestimationdata', data.data)
        })
      }
    },
    async geeneratecostestimation(){
      await this.$axios.get(`costestimation/generateCostEstimation/${this.getproject.id}/${this.getproject.template_id}/${this.getchosentemplate.id}`)
      .then(({data}) => {
        if(data.response){
          this.costestimation()
        }
      })
    },
    async getmaterialstemp(){
      await this.$axios.get('billofmaterials/getmaterialstemp')
      .then(({data}) => {
        this.$store.dispatch('billofmaterials/setbillofmaterialstemplate', data.data)
      })
    }
  },
  watch: {
    getchosentemplate(newTemplate){
      
      if ( newTemplate != null ) {
        if(Object.keys(newTemplate).length !== 0){
          this.costestimation()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>