import store from '@/states/index.js'

import SupplierComponent from '../SupplierComponent'

export default [
    {
        path: '/trade_rate_suppliers',
        component: SupplierComponent,
        name: 'trade_rate_suppliers',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if (role.info.role === 1) {
                next()
            } else {
                next('/')
            }
            return
        }
    }
]