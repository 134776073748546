export default {
    namespaced: true,
    state: {
        approved_quotes: []
    },
    mutations: {
        set_approved_quotes(state, payload){
            state.approved_quotes = [...payload]
        }
    },
    getters: {
        get_approved_quotes : state => state.approved_quotes
    },
    actions: {
        set_approved_quotes({commit}, payload){
            commit('set_approved_quotes', payload)
        }
    }
}