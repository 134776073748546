<template>
    <div>
        <v-data-iterator
            :items="get_video_archives"
            :item-per-page="5"
            :search="search_archive_video"
            :sort-by="sortBy"
            :sort-desc="sortDesc"           
            item-key="id"
            >
            <template v-slot:header>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="search_archive_video"                     
                            prepend-inner-icon="mdi-magnify"
                            placeholder="search by video name | description here..."
                            label="search by video name | description here...">
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:default="{items}">
                <v-row>
                    <v-col
                        v-for="item in items"
                        :key="item.id"
                        cols="auto"
                        md="3"
                        sm="6"                
                        class="mb-5">                       
                        <VideoArchivedPlayer 
                            :video="item"
                            :user_info="get_user"></VideoArchivedPlayer>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VideoArchivedPlayer from './VideoArchivedPlayer.vue';

export default {
    data: () => ({            
        search_archive_video: null,
        sortBy: 'updated_at',
        sortDesc: true,
    }),
    components: {
        VideoArchivedPlayer
    },
    computed: {
        ...mapGetters({
            get_video_archives:   'video_archived/get_video_archives_getters',
            get_user:             'auth/getuser'
        })
    },
    methods: {
        
    }
}
</script>