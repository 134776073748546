<template>
    <v-container fluid class="mt-5">
      <v-card
        elevation="1"
      >
        <v-card-title
            style="fontSize: 16px; background: #062a82;"
        >
            <small style="color: white;">
                <v-icon
                    class="mr-1"
                    style="color: white;"
                >
                    mdi-briefcase-upload
                </v-icon>
                Upload Your Plans <GuidelineComponent :color="color" />
            </small>
            <v-spacer></v-spacer>
            <v-btn
                class="pl-10 pr-10"
                color="primary"
                dark
                rounded
                @click="addfile"
            >
                <v-icon
                    class="mr-3"
                >
                    mdi-upload
                </v-icon>upload
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-text-field
                placeholder="name"
                label="search here"
                v-model="search"
                prepend-inner-icon="mdi-magnify"
            >
            </v-text-field>
            <v-row>
                <v-col
                    cols="4"
                    v-for="(item, index) in get_uploaded_files"
                    :key="index"
                    class="mt-3 mb-3"
                >
                    <v-card>
                        <v-card-title
                            style="fontSize: 16px;"
                        >
                            <small>{{item.name}}</small>
                            <v-spacer></v-spacer>
                            <small>{{item.build_stage}}</small>
                            <v-icon 
                                class='ml-3' 
                                style="cursor: pointer;"
                                @click="warning_delete_fn(item)"
                            >
                                mdi-delete
                            </v-icon>
                        </v-card-title>
                        <v-card-subtitle>
                            <small>{{item.created_at}}</small>
                        </v-card-subtitle>
                        <v-card-text
                            class="text-center"
                        >
                            <div
                                v-if="item.file_path.length > 2"
                            >
                                <div
                                    v-if="item.file_path[0].split('.').pop() === 'jpg' || item.file_path[0].split('.').pop() === 'jpeg' || item.file_path[0].split('.').pop() === 'png'"
                                >
                                    <img :src="`${img_url}${item.file_path[0]}`" style="max-width:250px; max-height:250px; width: fit; height: 120px;"/>
                                </div>
                                <v-chip
                                    dense
                                    color="secondary"
                                    class="mb-2"
                                >{{item.file_path.length}} total media</v-chip>
                            </div>
                            <div
                                v-else
                            >
                                <div
                                    v-for="(media, mediaindex) in item.file_path"
                                    :key="mediaindex"
                                >
                                    <div
                                        v-if="media.split('.').pop() === 'jpg' || media.split('.').pop() === 'jpeg' || media.split('.').pop() === 'png'"
                                    >
                                        <img :src="`${img_url}${media}`" style="max-width:250px; max-height:250px; width: fit; height: 120px;"/>
                                    </div>
                                    <div
                                        v-if="media.split('.').pop() === 'pdf'"
                                    >
                                        <img :src="`https://media.istockphoto.com/vectors/icon-major-file-format-vector-icon-illustration-vector-id1298834280?k=20&m=1298834280&s=612x612&w=0&h=SxEbyHKi18H1XpPpKKzoppWgMM3x0tc3veN5e6wl7Y8=`" style="max-width:250px; max-height:250px; width: fit; height: 120px;"/>
                                    </div>
                                    <div v-if="media.split('.').pop() === 'docx' || media.split('.').pop() === 'doc'">
                                        <img :src="`https://seeklogo.com/images/M/microsoft-word-logo-E648C182A5-seeklogo.com.png`" style="max-width:125px; max-height:125px; width: fit; height: 120px;"/>
                                    </div>
                                    <div v-if="media.split('.').pop() === 'xlsx'">
                                        <img :src="`https://seeklogo.com/images/E/excel-logo-974BFF9CB9-seeklogo.com.png`" style="max-width:125px; max-height:125px; width: fit; height: 120px;"/>
                                    </div>
                                </div>
                            </div>
                            
                            <div
                                v-if="item.service_types.length > 5"
                            >
                                <v-chip
                                    color="secondary"
                                    dense
                                    style="cursor: pointer;"
                                    @click="show_all_service_type_fn(item)"
                                >5+ service types</v-chip>
                            </div>
                            <div
                                v-else
                            >
                                <label
                                    v-for="(itemm, itemindexx) in item.service_types"
                                    :key="itemindexx"
                                >
                                    <v-chip
                                        dense
                                        color="secondary"
                                    >{{itemm.service_type}}</v-chip>
                                </label>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <small><strong>view count: </strong></small>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                dark
                                dense
                                class="pl-10 pr-10"
                                @click="download_attachment(item.file_path)"
                            >
                                <v-icon
                                    class="mr-3"
                                >mdi-eye</v-icon>
                                view
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
      </v-card>
      <v-row justify="center">
            <v-dialog
                persistent
                max-width="690"
                v-model="add_file_dialog"
            >
                <v-card>
                    <v-card-title style="fontSize: 16px;">
                        <v-icon>mdi-upload</v-icon>
                        upload plans
                    </v-card-title>
                    <v-card-text
                        class="text-center mt-5"
                    >
                        <v-text-field
                            dense
                            outlined
                            label="project name"
                            v-model="u.name"
                            prepend-icon="mdi-file"
                        >
                        </v-text-field>
                        <v-file-input
                            small-chips
                            chips
                            multiple
                            label="upload plan"
                            v-model="u.file"
                            dense
                            outlined
                        ></v-file-input>
                        <v-text-field
                            dense
                            outlined
                            label="address"
                            v-model="u.address"
                            prepend-icon="mdi-card-text-outline"
                        ></v-text-field>
                        <v-text-field
                            dense
                            outlined
                            label="town"
                            v-model="u.town"
                            prepend-icon="mdi-card-text-outline"
                        ></v-text-field>
                        <v-autocomplete
                            :items="get_counties"
                            item-value="id"
                            item-text="name"
                            label="county"
                            v-model="u.county"
                            outlined
                            dense
                            prepend-icon="mdi-card-text-outline"
                            return-object
                        >
                        </v-autocomplete>
                        <v-text-field
                            dense
                            outlined
                            label="eircode"
                            v-model="u.zipcode"
                            prepend-icon="mdi-card-text-outline"
                        ></v-text-field>
                        <v-autocomplete
                          :items="build_type"
                          label="build type"
                          v-model="u.type_of_build"
                          outlined
                          dense
                          prepend-icon="mdi-card-text-outline"
                        >
                        </v-autocomplete>
                        <v-autocomplete
                          :items="build_stage"
                          label="build stage"
                          v-model="u.build_stage"
                          outlined
                          dense
                          prepend-icon="mdi-card-text-outline"
                        >
                        </v-autocomplete>
                        <v-autocomplete
                          :items="get_service_types"
                          label="service type"
                          v-model="u.service_type"
                          item-value="id"
                          item-text="service_type"
                          outlined
                          dense
                          multiple
                          prepend-icon="mdi-card-text-outline"
                        >
                        </v-autocomplete>
                        <v-text-field
                            dense
                            outlined
                            :label="getuser.info.phone"
                            v-model="u.mobile"
                            prepend-icon="mdi-card-text-outline"
                            disabled
                        ></v-text-field>
                        <v-text-field
                            dense
                            outlined
                            :label="getuser.info.email"
                            v-model="u.email"
                            prepend-icon="mdi-card-text-outline"
                            disabled
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="green darken-1"
                            @click="uploadfile"
                            dark
                            class="pl-10 pr-10"
                        >
                            <v-icon class="mr-3">mdi-upload</v-icon>
                            upload
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-dialog
            max-width="420"
            v-model="delete_dialog_warning"
            persistent
        >
            <v-card>
                <v-card-title style="fontSize: 18px;">
                    <small>Warning!</small>
                </v-card-title>

                <v-card-text>
                    <small><strong>You are about to delete this record. Are you sure about this?</strong></small>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    dense
                    @click="delete_dialog_warning = !delete_dialog_warning"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="red"
                    dark
                    dense
                    class="pr-10 pl-10"
                    @click="continue_delete_item"
                >
                    <v-icon class="mr-3">mdi-delete</v-icon>Delete
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            max-width="420"
            v-model="show_all_service_type"
            persistent
        >
            <v-card>
                <v-card-title style="fontSize: 18px;">
                    <small></small>
                </v-card-title>

                <v-card-text>
                    <label
                        v-for="(itemm, itemindexx) in service_types"
                        :key="itemindexx"
                    >
                        <!-- {{itemm.service_type}}<br/> -->
                        <v-chip
                            dense
                            color="secondary"
                            class="ml-2 mr-2 mb-2"
                        >{{itemm.service_type}}</v-chip>
                    </label>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    dense
                    @click="show_all_service_type = !show_all_service_type"
                >
                    Cancel
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            max-width="420"
            v-model="show_media_dialog"
            persistent
        >
            <v-card>
                <v-card-title style="fontSize: 18px;">
                    <small></small>
                    <v-spacer></v-spacer>
                    <v-icon
                        style="cursor:pointer;"
                        @click="show_media_dialog = !show_media_dialog"
                    >mdi-cancel</v-icon>
                </v-card-title>

                <v-card-text>
                    <div
                        v-for="(media, mediaindex) in media_file"
                        :key="mediaindex"
                        class="text-center justify-center"
                    >
                        <div
                            v-if="media.split('.').pop() === 'jpg' || media.split('.').pop() === 'jpeg' || media.split('.').pop() === 'png'"
                        >
                            <div>
                                <img :src="`${img_url}${media}`" style="max-width:250px; max-height:250px; width: fit; height: 250px;"/>
                            </div>
                            <v-btn
                                dense
                                outlined
                                style="display: block;"
                                class="mb-3"
                                @click="view_media_external(media)"
                                block
                            >
                                <v-icon class="mr-3">mdi-eye</v-icon>View
                            </v-btn>
                        </div>
                        <div
                            v-if="media.split('.').pop() === 'pdf'"
                        >
                            <img :src="`https://media.istockphoto.com/vectors/icon-major-file-format-vector-icon-illustration-vector-id1298834280?k=20&m=1298834280&s=612x612&w=0&h=SxEbyHKi18H1XpPpKKzoppWgMM3x0tc3veN5e6wl7Y8=`" style="max-width:250px; max-height:250px; width: fit; height: 125px;"/>
                            <v-btn
                                dense
                                outlined
                                style="display: block;"
                                class="mb-3"
                                @click="view_media_external(media)"
                                block
                            >
                                <v-icon class="mr-3">mdi-eye</v-icon>View
                            </v-btn>
                        </div>
                        <div v-if="media.split('.').pop() === 'docx' || media.split('.').pop() === 'doc'">
                            <img :src="`https://seeklogo.com/images/M/microsoft-word-logo-E648C182A5-seeklogo.com.png`" style="max-width:125px; max-height:125px; width: fit; height: 250px;"/>
                            <v-btn
                                dense
                                outlined
                                style="display: block;"
                                class="mb-3"
                                @click="view_media_external(media)"
                                block
                            >
                                <v-icon class="mr-3">mdi-eye</v-icon>View
                            </v-btn>
                        </div>
                        <div v-if="media.split('.').pop() === 'xlsx'">
                            <img :src="`https://seeklogo.com/images/E/excel-logo-974BFF9CB9-seeklogo.com.png`" style="max-width:125px; max-height:125px; width: fit; height: 250px;"/>
                            <v-btn
                                dense
                                outlined
                                style="display: block;"
                                class="mb-3"
                                @click="view_media_external(media)"
                                block
                            >
                                <v-icon class="mr-3">mdi-eye</v-icon>View
                            </v-btn>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    dense
                    @click="show_media_dialog = !show_media_dialog"
                >
                    Cancel
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import GuidelineComponent from '@/components/GuidelineComponent.vue'

export default {
  components: {
    GuidelineComponent
  },
  props: [
  ],
  data: () => ({
    add_file_dialog: false,
    u: {
        name: null,
        file: [],
        build_stage: null,
        address: null,
        town: null,
        county: null,
        zipcode: null,
        type_of_build: null,
        mobile: null,
        email: null,
        service_type: null
    },
    img_url: null,
    search: null,
    build_stage: [
        'Pending',
        'Started',
        'On-Hold',
        'Waiting on info',
        'Complete'
    ],
    build_type: [
        'Self Build',
        'Renovation'
    ],
    delete_dialog_warning: false,
    show_all_service_type: false,
    service_types: [],
    show_media_dialog: false,
    media_file: [],
    color: 'white'
  }),
  async mounted () {
    await this.$axios.get('upload_plans/g_uploaded_files')
    .then(({data}) => {
        this.$store.dispatch('uploadplan/set_uploaded_files', data.data)
    })
    await this.$axios.get('admin/service_types/get_service_types')
    .then(({data}) => {
        data.data.unshift({
            id: 0,
            service_type: 'ALL',
            is_active: 1
        })
        this.$store.dispatch('uploadplan/set_service_types', data.data)
    })
    await this.$axios.get('counties')
    .then(({data}) => {
        this.$store.dispatch('uploadplan/set_counties', data.data)
    })
    this.img_url = process.env.VUE_APP_URL
  },
  created () {
  },
  computed: {
    ...mapGetters({
        // get_uploaded_files:                 'uploadplan/get_uploaded_files'
        getuser:                            'auth/getuser',
        get_service_types:                  'uploadplan/get_service_types',
        get_counties:                       'uploadplan/get_counties',
        get_to_delete:                      'uploadplan/get_to_delete'
    }),
    get_uploaded_files(){
        return this.$store.getters['uploadplan/get_uploaded_files'](this.search)
    }
  },
  methods: {
    addfile(){
        this.add_file_dialog = true
    },
    closedialog(){
        this.add_file_dialog = false
        this.u = {}
    },
    async uploadfile(){
        const tdata = new FormData()
        for(let i = 0; i < this.u.file.length; i++){
            tdata.append('file_' + i, this.u.file[i])
        }
        // tdata.append('file',                                this.u.file)
        tdata.append('file_count',                          this.u.file.length)
        tdata.append('name',                                this.u.name)
        tdata.append('build_stage',                         this.u.build_stage)
        tdata.append('address',                             this.u.address)
        tdata.append('town',                                this.u.town)
        tdata.append('county',                              this.u.county.name)
        tdata.append('zipcode',                             this.u.zipcode)
        tdata.append('type_of_build',                       this.u.type_of_build)
        tdata.append('mobile',                              this.getuser.info.mobile)
        tdata.append('email',                               this.getuser.info.email)
        tdata.append('service_types',                       this.u.service_type)
        await this.$axios.post('upload_plans/upload_file',  tdata)
        .then(({data}) => {
            if(data.response){
                this.closedialog()
                this.$store.dispatch('uploadplan/set_fetch_data')
            }
        })
    },
    download_attachment(data){
    //   window.open(this.img_url + data)
        this.media_file = []
        this.media_file = data
        this.show_media_dialog = true
    },
    view_media_external(data){
        window.open(this.img_url + data)
    },
    warning_delete_fn(data){
        this.$store.dispatch('uploadplan/set_to_delete', data)
        this.delete_dialog_warning = true
    },
    async continue_delete_item(){
        await this.$axios.delete(`upload_plans/delete/${this.get_to_delete.id}`)
        .then(({data}) => {
            if(data.response){
                this.delete_dialog_warning = false
                this.$store.dispatch('uploadplan/set_to_delete', {})
                this.$store.dispatch('uploadplan/set_fetch_data')
            }
        })
    },
    show_all_service_type_fn(data){
        // console.log(data)
        this.service_types = []
        this.service_types = data.service_types
        this.show_all_service_type = true
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>