import store from '@/states/index.js'

import RemindersComponent from '../RemindersComponent.vue'

export default [
    {
        path: '/reminders',
        component: RemindersComponent,
        name: 'reminders',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if (role.info.role === 3) {
                next()
            } else {
                next('/')
            }
            return
        }
    }
]