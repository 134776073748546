<template>
    <div>
        To be implemented soon!
    </div>
</template>

<script>
export default {
    
}
</script>