<template>
    <v-row justify="center">
            <v-dialog
                width="50%"
                v-model="uploadDialog"
                scrollable
                persistent>
                <v-card>
                    <v-card-title>
                        <span v-if="is_edited == false">Upload a File</span>
                        <span v-else>Edit Uploaded File</span>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="is_disabled" text small fab @click="close_dialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>                       
                    </v-card-title>
                    <v-card-text v-if="is_disabled">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-progress-linear
                                    color="primary"
                                    height="10"
                                    buffer-value="0"
                                    :value="compute_upload_progress()"
                                    stream>
                                </v-progress-linear>
                            </v-col>
                            <v-col cols="12">
                                <v-alert dense text style="margin:0;"
                                    color="info">
                                    <v-row no-gutters>
                                        <v-col cols="10">
                                            <span>{{ Math.ceil((get_upload_progress.loaded) / (1000 * 1000)) }} MB</span>
                                            <span> out of </span>
                                            <span>{{Math.ceil((get_upload_progress.total) / (1000 * 1000))}} MB</span>
                                        </v-col>
                                        <v-col cols="2" class="text-right">
                                            <span>{{ Math.ceil(compute_upload_progress()) }}%</span>
                                        </v-col>
                                    </v-row>
                                </v-alert>                                
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-form
                            ref="upload_video_form"
                            v-model="valid_inputs"
                            :disabled="is_disabled"
                            lazy-validation>
                                <v-text-field
                                    v-model="file_name"
                                    label="File Title"
                                    required>
                                </v-text-field>

                                <v-file-input                                   
                                    v-model="file"
                                    label="File Source"
                                    placeholder="Please pick a file based on the accepted file types."
                                    :accept="accepted_types"
                                    show-size
                                    truncate-length="15">
                                </v-file-input>

                                <v-file-input                                   
                                    v-model="thumbnail"
                                    label="File Thumbnail"
                                    placeholder="Please pick an image for the video thumbnail."                                   
                                    show-size
                                    truncate-length="15">
                                </v-file-input>
                                <!--<v-textarea                                   
                                    counter="250"
                                    filled
                                    v-model="file_description"
                                    label="File Description"
                                    hint="Please shorten your file description to only 250 characters. It should explain brief what the file all about.">
                                </v-textarea>-->
                                <v-divider></v-divider>
                                <v-card-actions class="mt-2"
                                    v-if="is_edited == false">
                                    <v-spacer></v-spacer>
                                    <v-btn width="150" :disabled="is_disabled" text  @click="close_dialog">
                                        <v-icon>mdi-window-close</v-icon>
                                        Cancel
                                    </v-btn>
                                    <v-btn :disabled="is_disabled" width="150" color="primary" @click="upload_file">
                                        <v-icon>mdi-upload</v-icon>
                                        Upload
                                    </v-btn>
                                </v-card-actions>
                                <v-card-actions class="mt-2"
                                    v-else>
                                    <v-spacer></v-spacer>
                                    <v-btn width="150" :disabled="is_disabled" text  @click="close_dialog">
                                        <v-icon>mdi-window-close</v-icon>
                                        Cancel
                                    </v-btn>
                                    <v-btn :disabled="is_disabled" width="150" color="primary" @click="update_file()">
                                        <v-icon>mdi-upload</v-icon>
                                        Update
                                    </v-btn>
                                </v-card-actions>                       
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({        
        is_disabled: false,
        uploadDialog: false,          
        is_edited: false,
        valid_inputs: true,
        file_id: null,       
        file_name: null,
        file: null,
        file_description: null,
        thumbnail: null,
        accepted_types: 'video/mp4, video/ogg, video/webm, application/pdf'
    }),
    props: {
        upload_dialog: Boolean,
        is_edit: Boolean,
        item: Object
    },
    methods: {
        ...mapActions({
            upload_filedata         :    'admin_helper_training/upload_file',
            update_uploaded_file    :    'admin_helper_training/update_uploaded_file',
        }),
        close_dialog() {
            this.$emit('close_dialog');
        },
        async upload_file() {
            this.is_disabled = true;
            await this.upload_filedata({
                name: this.file_name,
                file: this.file,
                description: this.file_description,
                thumbnail: this.thumbnail
            });
            this.is_disabled = false;
            this.file_name = null;
            this.file = null;
            this.file_description = null;
            this.thumbnail = null;
            this.$emit('close_dialog');
        },
        async update_file() {
            this.is_disabled = true;
            await this.update_uploaded_file({
                id: this.file_id,
                name: this.file_name,
                description: this.file_description,
                file: this.file,
                thumbnail: this.thumbnail
            });
            this.is_disabled = false;
            this.file_name = null;
            this.file = null;
            this.file_description = null;
            this.thumbnail = null;           
            this.$emit('close_after_upload');
        },
        compute_upload_progress() {
            let upload_progress = this.get_upload_progress;
            let currentProgress = Math.round((upload_progress.loaded / upload_progress.total) * 100);
            return currentProgress;
        }
    },
    computed: {
        ...mapGetters({
            get_upload_progress     :    'admin_helper_training/get_upload_progress_getters'
        })
    },
    watch: {
        file() {
            // Watch any changes of the file checking if it is a video or pdf
        },
        upload_dialog() {
            this.uploadDialog = this.upload_dialog;
            this.is_edited = this.is_edit;
        },
        item() {           
            this.file_id = this.item.id;     
            this.file_name = this.item.name;            
            this.file_description = this.item.description;
        }
    },
    mounted() {
        
    }
}
</script>