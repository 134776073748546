<template>
    <v-container fluid class="mt-5 flex">
        <v-card>
            <v-card-title style="background: #062a82;">
                <v-icon class="mr-2 white--text"></v-icon>
                <small class="white--text">
                    <v-icon class="mr white--text">mdi-notebook</v-icon>
                    Site Diary
                    <v-icon color="white" style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon>
                </small>
                <v-spacer></v-spacer>
                <v-btn                                      
                    class="pl-16 pr-16 white--text"
                    color="#0b4ef3"
                    rounded
                    @click="event_siteDiaryDialog_openAddEntry()">
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    add entry
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-toolbar class="mt-5 pt-3"
                    elevation="0">                    
                    <v-text-field
                        v-model="search_sitediary"                     
                        prepend-inner-icon="mdi-magnify"
                        placeholder="search by log name | notes | tags..."
                        label="search by log name | notes | tags..."
                        class="mr-6">
                    </v-text-field>                   
                    <v-switch
                        v-model="deleted_sitedairy"
                        label="Show Deleted Site Diary"></v-switch>
                </v-toolbar>

                <v-data-table
                    v-if="deleted_sitedairy == false"
                    :headers="headers"
                    :items="getSiteDiaryData"
                    :search="search_sitediary">
                    <template v-slot:item="{ item }">
                        <tr class="row-pointer">
                            <td @click="event_edit_sitediary(item)">{{ item.log_name }}</td>
                            <td @click="event_edit_sitediary(item)">{{ item.notes }}</td>
                            <td class="row-pointer-default">
                                <v-chip-group
                                    active-class="primary--text"
                                    column>
                                    <v-chip
                                        v-for="tag in item.tags"
                                        :key="tag"
                                        color="primary">
                                        {{ tag }}
                                    </v-chip>
                                </v-chip-group>
                            </td>
                            <td class="row-pointer-default">{{ item.created_at }}</td>
                            <td class="row-pointer-default">{{ item.site_diary_date }}</td>
                            <td>
                                <div>
                                    <img
                                        style="cursor: pointer;" :src="`${imagePath}/${item.image}`"
                                        class="align-self-stretch mt-2" 
                                        width="120" @error="show_error_image($event, item)"
                                        @click="event_showImages(item)" />
                                </div>                               
                            </td>
                            <td></td>
                        </tr>
                    </template>
                </v-data-table>

                <v-data-table
                    v-else
                    :headers="headers"
                    :items="get_deleted_sitediary_data"
                    :search="search_sitediary">
                    <template v-slot:item="{ item }">
                        <tr class="row-pointer">
                            <td>{{ item.log_name }}</td>
                            <td>{{ item.notes }}</td>
                            <td class="row-pointer-default">
                                <v-chip-group
                                    active-class="primary--text"
                                    column>
                                    <v-chip
                                        v-for="tag in item.tags"
                                        :key="tag"
                                        color="primary">
                                        {{ tag }}
                                    </v-chip>
                                </v-chip-group>
                            </td>
                            <td class="row-pointer-default">{{ item.created_at }}</td>
                            <td class="row-pointer-default">{{ item.site_diary_date }}</td>
                            <td>
                                <div>
                                    <img
                                        style="cursor: pointer;" :src="`${imagePath}/${item.image}`"
                                        class="align-self-stretch mt-2" 
                                        width="120" @error="show_error_image($event, item)"
                                        @click="event_showImages(item)" />
                                </div>                               
                            </td>
                            <td>
                                <v-btn text color="success" @click="event_restore_sitediary_data(item)">
                                    <v-icon>mdi-restore</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

            </v-card-text>         
        </v-card>

        <ViewImages
            :viewImageDialog="viewImageDialogComponent"
            :fileLists="viewImageDialogFileList"
            @event_close_viewdialog="eventCloseViewDialog"></ViewImages>
        
        <AddEntry
            :addSiteDiaryEntryDialog="siteDiaryDialog.addEntry"
            @closeAddEntryDialog="eventCloseAddEntryDialog()"></AddEntry>
        
        <EditEntry
            :edit_sitediary_dialog="edit_sitediary"
            :sitediary_item="edit_sitediary_item"
            @close_update_dialog="event_close_update_dialog()"></EditEntry>

        <RestoreConfirmDialog
            :confirm_dialog="confirm_restore_dialog"
            :item="item_tobe_restored"            
            @close_confirm_dialog="event_restore_confirm_dialog()"></RestoreConfirmDialog>
    </v-container>
</template>

<script>
import SiteDiary from './modules/sitediary';
import { mapGetters } from 'vuex';
import ViewImages from './components/ViewImages.vue';
import AddEntry from './components/AddEntry.vue';
import EditEntry from './components/EditEntry.vue';
import DefaultImage from './../../assets/logo.png';
import RestoreConfirmDialog from './components/RestoreConfirmDialog.vue';

export default {
    data: () => ({
        search_sitediary: null,
        deleted_sitedairy: false,
        headers: [
            { text: 'Log Name', value: 'log_name', filterable: true, width: '15%' },
            { text: 'Notes', value: 'notes', filterable: true,  width: '40%'},
            { text: 'Tags', value: 'tags', filterable: true, width: '10%'},
            { text: 'Date', value: 'created_at', filterable: true, sortable: true, width: '10%'},
            { text: 'Site Date', value: 'site_diary_date', filterable: true, sortable: true, width: '10%'},
            { text: 'Media', value: 'media', filterable: false, sortable: false, width: '10%'},
            { text: '', width: '5%'}
        ],
        imagePath: process.env.VUE_APP_URL + 'frontend/images',
        altImagePath: process.env.VUE_APP_URL,
        viewImageDialogComponent: false,
        viewImageDialogFileList: [],
        siteDiaryDialog: {
            addEntry: false
        },
        edit_sitediary: false,
        edit_sitediary_item: {},
        confirm_restore_dialog: false,
        item_tobe_restored: {}
    }),
    components: {
        ViewImages,
        AddEntry,     
        EditEntry,   
        RestoreConfirmDialog
    },
    computed: {
        ...mapGetters({
            getproject:                 'auth/getproject',
            getSiteDiaryData:           'sitediaryv2/getSiteDiaryDataGetter',
            get_deleted_sitediary_data: 'sitediaryv2/get_deleted_sitediary_data_getter'
        })
    },
    methods: {       
        show_error_image(event, item) {
            /** Look for alternative images if available */
            if ( item.alternative_images != null ) {
                const alt_images = JSON.parse(item.alternative_images)
                event.target.src = alt_images != null ? `${this.altImagePath}${alt_images[0]}` : DefaultImage
            } else {
                event.target.src = DefaultImage
            }
        },
        event_showImages(item) {            
            this.viewImageDialogFileList = SiteDiary().processImagePath(item.image, item.alternative_images != null ? JSON.parse(item.alternative_images): [])
            this.viewImageDialogComponent = true
        },
        eventCloseViewDialog() {
            this.viewImageDialogComponent = false
            this.viewImageDialogFileList = []
        },
        event_siteDiaryDialog_openAddEntry() {            
            this.siteDiaryDialog.addEntry = true
        },
        eventCloseAddEntryDialog() {
            this.siteDiaryDialog.addEntry = false
        },
        event_edit_sitediary(item) {            
            this.edit_sitediary_item = item           
            this.edit_sitediary = true
        },
        event_close_update_dialog() {
            this.edit_sitediary = false
            this.edit_sitediary_item = {}
        },
        event_restore_sitediary_data(item) {
            this.item_tobe_restored = item
            this.confirm_restore_dialog = true
        },
        event_restore_confirm_dialog() {
            this.confirm_restore_dialog = false
        }
    },
    created() {        
        SiteDiary().getSiteDiaries(this.getproject.id, this.$store)
        SiteDiary().get_deleted_sitediary(this.getproject.id, this.$store)
    }
}
</script>

<style lang="scss" scoped>
.row-pointer {
    cursor: pointer;
}
.row-pointer-default {
    cursor: default;
}
</style>