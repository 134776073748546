<template>
    <v-row>
        <v-chip
            v-for="(item, itemindex) in data"
            :key="itemindex"
            class="mr-2"
            style="cursor: pointer;"
            @click="filter_item_via_category(item)"
        >
            <v-icon>
                {{ item.icon }}
            </v-icon>
            {{ item.name }}
        </v-chip>
    </v-row>
</template>

<script>
export default {
  components: {
  },
  props: [
    'data'
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    filter_item_via_category(item){
      this.$emit('filter_item_via_category', item)
      this.$store.dispatch('admin_shop/set_bool_cat_selected', true)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>