import store from '@/states/index.js'

import SupplierMaterials from '../SupplierMaterials.vue'

export default [
    {
        path: '/supplier_materials',
        component: SupplierMaterials,
        name: 'supplier_materials',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if (role.info.role === 2) {
                next()
            } else {
                next('/')
            }
            return
        }
    }
]