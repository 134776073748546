import Vue from 'vue';
import SiteDiary from './sitediary';

const MultiUploadImages = () => {
    const upload_files = async (fileList, sitediary_id, store, previous_images = null) => {
        const uploadInfo = {alt_images: [], length: fileList.length, sitediary_id: sitediary_id}        
        fileList.map( (file) => {
            const imageForm = new FormData()
            imageForm.append('file', file)

            Vue.axios.post(`sitediary_v2/uploadfile/${sitediary_id}`, imageForm).then( (data) => {
                if ( data.status == 200 ) {
                    const result = data.data
                    if ( result.response == true ) {
                        // Update the file progress in uploading multiple files
                        uploadInfo.alt_images.push(result.data.image_path)                        
                        if ( uploadInfo.alt_images.length == fileList.length ) {
                            // Indicator that the file upload is done
                            console.log('File upload is done')
                            if ( previous_images != null ) {
                                uploadInfo.alt_images.push(...previous_images)
                            }
                            SiteDiary().update_alternative_images(uploadInfo, store)
                        }
                    }
                }
            }).catch( (err) => {
                console.log(err)
            })
        })
    }
    
    return {
        upload_files
    }
}

export default MultiUploadImages