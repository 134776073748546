import Vue from 'vue';
import MultiUploadImages from './multiuploadimages';
import DefaultImage from '../../../assets/logo.png';

const SiteDiary = () => {

    /**
     * @param {Object} - Based on destructured object properties
     */
    const saveSiteDiary = ({
        project_id, log_name, notes, primary_image, tags, site_date, alt_images
    }, store) => {
        //console.log(project_id, log_name, notes, primary_image, tags, site_date, alt_images, store)
        const formPayload = new FormData();
        formPayload.append('project_id', project_id)
        formPayload.append('log_name', log_name)
        formPayload.append('notes', notes)
        formPayload.append('file', primary_image)
        formPayload.append('tags', JSON.stringify(tags))
        formPayload.append('site_diary_date', site_date)
        
        /** Process saving of site diary entry normally. */
        Vue.axios.post(`sitediary_v2/savesitediary`, formPayload).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data;
                if ( result.response == true ) {
                    // Implement multiple uploading of images using web worker
                    store.dispatch('sitediaryv2/addNewlyAddedSiteDiaryEntryAction', result.data)
                    _display_alert_notification({
                        open: true,
                        type: 'success',
                        message: 'The site diary entry is successfully added.',
                        icon: 'mdi-check-all'
                    }, store)
                    MultiUploadImages().upload_files(alt_images, result.data.id, store)
                } else {
                    _display_alert_notification({
                        open: true,
                        type: 'error',
                        message: `${result.data}`,
                        icon: 'mdi-alert-circle'
                    }, store)
                }
            } else {
                _display_alert_notification({
                    open: true,
                    type: 'error',
                    message: `${data.message}`,
                    icon: 'mdi-alert-circle'
                }, store)
            }
        }).catch( (err) => {
            _display_alert_notification({
                open: true,
                type: 'error',
                message: `${err.message}: ${err.response.data}`,
                icon: 'mdi-alert-circle'
            }, store)
        })
    }

    /**     
     * @param {Integer} project_id 
     */
    const getSiteDiaries = (project_id, store) => {        
        Vue.axios.get(`sitediary_v2/getsitediaries/${project_id}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data;
                if ( result.response == true ) {
                    // Save the record into a state                    
                    store.dispatch('sitediaryv2/setSiteDiaryDataAction', result.data)
                } else {
                    _display_alert_notification({
                        open: true,
                        type: 'error',
                        message: `${result.data}`,
                        icon: 'mdi-alert-circle'
                    }, store)
                }
            }
        }).catch( (err) => {
            // display error message
            _display_alert_notification({
                open: true,
                type: 'error',
                message: `${err.message}: ${err.response.data}`,
                icon: 'mdi-alert-circle'
            }, store)
        })
    }

    /**     
     * @param {String} primaryImage 
     * @param {Array} altImages 
     * @returns Array
     */
    const processImagePath = (primaryImage, altImages) => {        
        const path = process.env.VUE_APP_URL
        if ( altImages != null ) {            
            return [ primaryImage == null ? DefaultImage : `${path}frontend/images/${primaryImage}`, ...altImages.map( (image) => `${path}${image}`)]
        } else {
            return [ primaryImage == null ? DefaultImage : `${path}frontend/images/${primaryImage}`]
        }
    }

    /**
     * @param {Number} site_diary_id
     * @param {Array} data - The data will be based on the laravel eloquent data dictionary
     * @param {object} vuex_store
     */
    const updateSiteDiaryData = (site_diary_id, sitediary_data, store) => {
        
        const form_entry = new FormData();
        if (sitediary_data.image != null) {
            form_entry.append('file', sitediary_data.image)
        }
        form_entry.append('log_name', sitediary_data.log_name)
        form_entry.append('notes', sitediary_data.notes)
        form_entry.append('site_diary_date', sitediary_data.site_diary_date)
        if ( sitediary_data.tags != null) {           
            form_entry.append('tags', JSON.stringify(sitediary_data.tags))
        }        

        Vue.axios.post(`sitediary_v2/updatesitediary/${site_diary_id}`, form_entry).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    store.dispatch('sitediaryv2/update_sitediary_data_action', result.data)
                    _display_alert_notification({
                        open: true,
                        type: 'success',
                        message: 'The site diary entry was updated successfully.',
                        icon: 'mdi-check-all'
                    }, store)
                    // Update alternative images
                    if ( sitediary_data.alt_images.length > 0 ) {
                        MultiUploadImages().upload_files(sitediary_data.alt_images, site_diary_id, store, sitediary_data.previous_images)
                    }
                } else {
                    _display_alert_notification({
                        open: true,
                        type: 'error',
                        message: `${result.data}`,
                        icon: 'mdi-alert-circle'
                    }, store)
                }
            } else {
                _display_alert_notification({
                    open: true,
                    type: 'error',
                    message: `${data.message}`,
                    icon: 'mdi-alert-circle'
                }, store)
            }            
        }).catch( (err) => {
            _display_alert_notification({
                open: true,
                type: 'error',
                message: `${err.message}: ${err.response.data}`,
                icon: 'mdi-alert-circle'
            }, store)
        })
    }

    /**
     * @param {array} upload_info
     * @param {object} vuex_store
     */
    const update_alternative_images = (upload_info, store) => {        
        Vue.axios.post(`sitediary_v2/updatesitediary/${upload_info.sitediary_id}`, {
            alternative_images: JSON.stringify(upload_info.alt_images)
        }).then( (data) => {
            const result = data.data
            if ( result.response == true ) {                
                store.dispatch('sitediaryv2/update_sitediary_data_action', result.data)
                _display_alert_notification({
                    open: true,
                    type: 'success',
                    message: `${upload_info.length}/${upload_info.length} alternative images successfully added.`,
                    icon: 'mdi-check-all'
                }, store)             
            } else {
                _display_alert_notification({
                    open: true,
                    type: 'error',
                    message: `${result.data}`,
                    icon: 'mdi-alert-circle'
                }, store)
            }
        }).catch( (err) => {
            _display_alert_notification({
                open: true,
                type: 'error',
                message: `${err.message}: ${err.response.data}`,
                icon: 'mdi-alert-circle'
            }, store)
        })
    }

    /**     
     * @param {Integer} site_diary_id 
     * @param {Object} store 
     */
    const delete_sitediary_data = (site_diary_id, store) => {       
        Vue.axios.delete(`sitediary_v2/deletesitediary/${site_diary_id}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true) {                                        
                    store.dispatch('sitediaryv2/deleted_sitediaries_data_action', result.data)
                    _display_alert_notification({
                        open: true,
                        type: 'success',
                        message: `The site diary data (${result.data.log_name}) was successfully deleted.`,
                        icon: 'mdi-check-all'
                    }, store)
                } else {
                    _display_alert_notification({
                        open: true,
                        type: 'error',
                        message: `${result.data}`,
                        icon: 'mdi-alert-circle'
                    }, store)
                }
            } else {
                _display_alert_notification({
                    open: true,
                    type: 'error',
                    message: `${data.message}`,
                    icon: 'mdi-alert-circle'
                }, store)
            }
        }).catch( (err) => {
            _display_alert_notification({
                open: true,
                type: 'error',
                message: `${err.message}: ${err.response.data}`,
                icon: 'mdi-alert-circle'
            }, store)
        })
    }

    /**     
     * @param {Integer} project_id 
     * @param {Object} store 
     */
    const get_deleted_sitediary = (project_id, store) => {        
        Vue.axios.get(`sitediary_v2/getdeletedsitediary/${project_id}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    store.dispatch('sitediaryv2/set_deleted_sitediary_data_action', result.data)
                } else {
                    _display_alert_notification({
                        open: true,
                        type: 'error',
                        message: `${result.data}`,
                        icon: 'mdi-alert-circle'
                    }, store)
                }
            } else {
                _display_alert_notification({
                    open: true,
                    type: 'error',
                    message: `${data.message}`,
                    icon: 'mdi-alert-circle'
                }, store)
            }
        }).catch( (err) => {
            _display_alert_notification({
                open: true,
                type: 'error',
                message: `${err.message}: ${err.response.data}`,
                icon: 'mdi-alert-circle'
            }, store)
        })
    }

    /**     
     * @param {Integer} sitediary_id 
     * @param {Object} store 
     */
    const restore_deleted_sitediary = (sitediary_id, store) => {        
        Vue.axios.patch(`sitediary_v2/restoredeletesitediary/${sitediary_id}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    store.dispatch('sitediaryv2/restore_sitediary_data_action', result.data)
                    _display_alert_notification({
                        open: true,
                        type: 'success',
                        message: `The item: ${result.data.log_name} is restored successfully.`,
                        icon: 'mdi-check-all'
                    }, store)
                } else {
                    _display_alert_notification({
                        open: true,
                        type: 'error',
                        message: `${result.data}`,
                        icon: 'mdi-alert-circle'
                    }, store)
                }
            } else {
                _display_alert_notification({
                    open: true,
                    type: 'error',
                    message: `${data.message}`,
                    icon: 'mdi-alert-circle'
                }, store)
            }
        }).catch( (err) => {
            _display_alert_notification({
                open: true,
                type: 'error',
                message: `${err.message}: ${err.response.data}`,
                icon: 'mdi-alert-circle'
            }, store)
        })
    }

    /** Private Function */
    const _display_alert_notification = (alert_info, store) => {
        store.dispatch('alert_notifications/open_alert_notification_action', alert_info.open)
        store.dispatch('alert_notifications/set_notification_icon_action', alert_info.icon)
        store.dispatch('alert_notifications/set_alert_notification_type_action', alert_info.type)
        store.dispatch('alert_notifications/set_notification_message_action', alert_info.message)
    }

    return {
        saveSiteDiary,
        getSiteDiaries,
        processImagePath,
        updateSiteDiaryData,
        update_alternative_images,
        delete_sitediary_data,
        get_deleted_sitediary,
        restore_deleted_sitediary
    }
    
}

export default SiteDiary