export default {
    namespaced: true,
    state: {
        client_plans: [],
        to_chat: {}
    },
    mutations: {
        set_client_plans(state, payload){
            state.client_plans = [...payload]
        },
        set_to_chat(state, payload){
            state.to_chat = payload
        }
    },
    getters: {
        get_client_plans : state => state.client_plans,
        get_to_chat : state => state.to_chat
    },
    actions: {
        set_client_plans({commit}, payload){
            commit('set_client_plans', payload)
        },
        set_to_chat({commit}, payload){
            commit('set_to_chat', payload)
        }
    }
}