<template>
    <v-row justify="center">
        <v-dialog
            v-model="edit_variable_modal"
            max-width="350"
            persistent>
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    <small>Add / Edit Value</small>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="value"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text class="px-6" color="error" @click="evtCloseDialog">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        <small>Cancel</small>
                    </v-btn>
                    <v-btn class="px-6" color="success" @click="evtSaveVariables">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        <small>Save</small>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import BillOfMaterials from '../modules/BillOfMaterials.js'
import { mapGetters } from 'vuex'
export default {
    data: () => ({
        edit_variable_modal: false,
        value: null,
        bill_of_materials: new BillOfMaterials()
    }),
    props: {
        edit_variable_dialog: Boolean,
        bom_variables: Object,
        selected_bom_template: Object
    },
    computed: {
        ...mapGetters({
            //gettoupdatevalue:                         'billofmaterials/gettoupdatevalue',
        })
    },
    methods: {
        evtCloseDialog() {
            this.$emit('evtCloseDialog')
        },
        evtSaveVariables() {            
            this.bill_of_materials.saveValueInBillOfMaterials({data: {
                value:         this.value,
                id:            this.bom_variables.id
            }, choosen_material_template: this.selected_bom_template})
            this.evtCloseDialog()
        }
    },
    mounted() {        
        this.bill_of_materials.setStore(this.$store)
    },
    watch: {
        edit_variable_dialog() {
            this.edit_variable_modal = this.edit_variable_dialog
            if ( this.edit_variable_dialog == true ) {
                this.value = this.bom_variables.value
            }
        }
    }
}
</script>