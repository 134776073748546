import store from '@/states/index.js'

import HomeComponent from '../HomeComponent.vue'

export default [
    {
        path: '/home',
        component: HomeComponent,
        name: 'home',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 3){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]