<template>
    <div>
        <v-data-table
              :headers="headers"
              :items="get_sitediaries"
              class="elevation-1"
              :hide-default-footer="true"
              disable-pagination
            >
              <template v-slot:item="props">
                <tr>
                    <td style="cursor: pointer;">{{ props.item.log_name }}</td>
                    <td style="cursor: pointer;">{{ props.item.notes }}</td>
                    <td style="cursor: pointer;">
                        <label
                            v-for="(item, index) in props.item.tags"
                            :key="index">
                            <v-badge>
                                <span slot="badge">{{item}}</span>
                            </v-badge>
                            <br/>
                        </label>
                    </td>
                    <td style="cursor: pointer;">{{ props.item.created_at }}</td>
                    <td style="cursor: pointer;">{{ props.item.site_diary_date }}</td>
                    <td style="cursor: pointer;">
                        <img :src="`${img_url}frontend/images/${props.item.image}`" style="max-width:125px; max-height:125px;"/>
                    </td>
                </tr>
              </template>
            </v-data-table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        headers: [
            { text: 'Log Name', value: 'log_name' },
            { text: 'Notes', value: 'notes'},
            { text: 'Tags', value: 'tags' },
            { text: 'Date', value: 'created_at'},
            { text: 'Site Date', value: 'site_diary_date'},
            { text: 'Image', value: 'image'}
        ],
        img_url: process.env.VUE_APP_URL
    }),
    props: {
        project: Object,
        user_id: Number
    },
    computed: {
        ...mapGetters({
            get_sitediaries:           'sitediary/get_sitediaries_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_sitediaries:        'sitediary/fetch_sitediaries_action'
        })
    },
    async mounted() {
        await this.fetch_sitediaries({
            project_id: this.project.id,
            user_id: this.user_id
        });
    },
    watch: {
        async project() {
            console.log('test');
            await this.fetch_sitediaries({
                project_id: this.project.id,
                user_id: this.user_id
            });
        }
    }
}
</script>