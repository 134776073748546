<template>
    <v-container fluid>
        <v-card v-if="setup.pp">
            <v-card-title>
                <small>{{template.template_name}}</small>
                <v-spacer></v-spacer>
                <v-btn-toggle v-model="toggle_none" v-if="setup.buttons.length != 0">
                    <v-btn
                        v-for="(btn, btnIndex) in setup.buttons"
                        :key="btnIndex"
                        :text="btn.text"
                        :dark="btn.dark"
                        :color="btn.color"
                        :class="btn.class"
                        rounded
                        @click="btn.action ? grabtemplate() : cancel()"
                    >
                        {{btn.label}}
                    </v-btn>
                </v-btn-toggle>
            </v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-group
                        v-for="item in items"
                        :key="item.title"
                        v-model="item.active"
                        no-action
                        :disabled="item.disable"
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title style="color: #0b4ef3;">
                                    <v-badge
                                        color="#0b4ef3"
                                        bordered
                                    >
                                        <v-icon slot="badge" v-if="item.disable">mdi-exclamation-thick</v-icon>
                                        <v-icon slot="badge" v-else>mdi-check-bold</v-icon>
                                        <span slot="default">{{item.task_name}}</span>
                                    </v-badge>
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-data-table
                            :headers="header"
                            :items="item.getchild"
                            item-key="task_name"
                            class="elevation-1 custom-table"
                            style="font-weight:700;"
                            :hide-default-footer="true"
                            disable-pagination
                            mobile-breakpoint="0">
                            <template v-slot:item="{item}">
                                <tr>
                                    <td style="cursor: pointer; white-space: pre-line;"><label>{{item['task_name']}}</label></td>
                                    <td style="cursor: pointer;">
                                        <label class="unselectable" v-if="item['duration'] === '' || item['duration'] === null"> 
                                            Need duration
                                        </label>
                                        <label class="unselectable" v-else>
                                            {{item['duration']}} Days
                                        </label>
                                    </td>
                                    <td style="cursor: pointer;">
                                        <label class="unselectable" v-if="item['status'] === 'Pending'" style="color: red !important;">
                                            <i>{{item['status']}}</i>
                                        </label>
                                        <label class="unselectable" v-if="item['status'] === 'Started'" style="color: green !important;">
                                            <i>{{item['status']}}</i>
                                        </label>
                                        <label class="unselectable" v-if="item['status'] === 'On-Hold'" style="color: red !important;">
                                            <i>{{item['status']}}</i>
                                        </label>
                                        <label class="unselectable" v-if="item['status'] === 'Waiting-on Info'" style="color: orange !important;">
                                            <i>{{item['status']}}</i>
                                        </label>
                                        <label class="unselectable" v-if="item['status'] === 'Complete'" style="color: green !important;">
                                            <i>{{item['status']}}</i>
                                        </label>
                                    </td>
                                    <td class="unselectable" style="cursor: pointer;">{{item['start']}}</td>
                                    <td class="unselectable" style="cursor: pointer;">{{item['finish']}}</td>
                                    <td class="unselectable" style="cursor: pointer;">{{item['notes']}}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-list-group>
                </v-list>
            </v-card-text>
        </v-card>

        <v-card
            v-if="setup.isreal"
        >
            <v-card-title>
                <small>{{template.template_name}}</small>
                <v-spacer></v-spacer>
                <div v-if="setup.buttons.length != 0">
                    <v-btn
                        v-for="(btn, btnIndex) in setup.buttons"
                        :key="btnIndex"
                        :text="btn.text"
                        :dark="btn.dark"
                        :color="btn.color"
                        :class="btn.class"
                        rounded
                        @click="btn.action ? addsection() : cancel()">
                        <v-icon class="mr-1">{{btn.icon}}</v-icon>{{btn.label}}
                    </v-btn>
                </div>
                <div
                    v-if="items[0].get_checklist.length > 0"
                >
                    <v-btn
                        rounded
                        label="checklist"
                        color="primary"
                        class="ml-3 pl-10 pr-10"
                        @click="showchecklist()"
                    >
                        <v-icon class="mr-1">mdi-clipboard-list-outline</v-icon>checklist
                    </v-btn>
                </div>
            </v-card-title>

            <v-card-text>
                <v-list>
                    <v-list-group
                        v-for="item in items"
                        :key="item.title"
                        v-model="item.active"
                        no-action
                        :disabled="item.disable"
                        style="background: #d8e3fd"
                        class="mt-3"
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <span slot="default"><strong>{{item.task_name}}</strong></span>
                            </v-list-item-content>
                        </template>
                        <v-icon 
                            slot="prependIcon"
                            @click="addchild(item)"
                        >
                            mdi-plus
                        </v-icon>
                        <v-icon 
                            slot="prependIcon"
                            @click="deleteparent(item)"
                            class="ms-5"
                        >
                            mdi-delete
                        </v-icon>
                        <v-data-table
                            :headers="header"
                            :items="item.getchild"
                            item-key="task_name"
                            class="elevation-1 custom-table"
                            style="font-weight:700;"
                            :hide-default-footer="true"
                            disable-pagination
                            mobile-breakpoint="0">
                            <template v-slot:item="{item}">
                                <tr
                                    @click="editrecord(item)"
                                >
                                    <td style="cursor: pointer; white-space: pre-line;"><small>{{item['task_name']}}</small></td>
                                    <td style="cursor: pointer;">
                                        <small class="unselectable" v-if="item['duration'] === '' || item['duration'] === null"> 
                                            Need duration
                                        </small>
                                        <small class="unselectable" v-else>
                                            {{item['duration']}} Days
                                        </small>
                                    </td>
                                    <td style="cursor: pointer;">
                                        <small class="unselectable" v-if="item['status'] === 'Pending'" style="color: red !important;">
                                            <i>{{item['status']}}</i>
                                        </small>
                                        <small class="unselectable" v-if="item['status'] === 'Started'" style="color: green !important;">
                                            <i>{{item['status']}}</i>
                                        </small>
                                        <small class="unselectable" v-if="item['status'] === 'On-Hold'" style="color: red !important;">
                                            <i>{{item['status']}}</i>
                                        </small>
                                        <small class="unselectable" v-if="item['status'] === 'Waiting-on Info'" style="color: orange !important;">
                                            <i>{{item['status']}}</i>
                                        </small>
                                        <small class="unselectable" v-if="item['status'] === 'Complete'" style="color: green !important;">
                                            <i>{{item['status']}}</i>
                                        </small>
                                    </td>
                                    <td class="unselectable" style="cursor: pointer;"><small>{{item['start']}}</small></td>
                                    <td class="unselectable" style="cursor: pointer;"><small>{{item['finish']}}</small></td>
                                    <td class="unselectable" style="cursor: pointer;"><small>{{item['notes']}}</small></td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-list-group>
                </v-list>
            </v-card-text>
        </v-card>

        <!-- Design Tracker FFF -->
        <div
            v-if="setup.isfff"
        >
            <v-list>
                <v-list-group
                    v-for="item in items"
                    :key="item.title"
                    v-model="item.active"
                    no-action
                    :disabled="item.disabled"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title style="color: #0b4ef3;">
                                <v-badge
                                    color="#0b4ef3"
                                    bordered
                                >
                                    <v-icon slot="badge" v-if="item.disabled">mdi-exclamation-thick</v-icon>
                                    <v-icon slot="badge" v-else>mdi-check-bold</v-icon>
                                    <span slot="default"><strong>{{item.room}}</strong></span>
                                </v-badge>
                                <v-progress-linear
                                    :buffer-value="item.getallcount_count"
                                    :value="((item.getalldonecount_count / item.getallcount_count) * 100)"
                                    striped
                                    height="15"
                                    color="#7b9ff9"
                                >
                                    <template v-slot:default="{ value }">
                                        <strong>{{ value.toFixed(2) }}%</strong>
                                    </template>
                                </v-progress-linear>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-data-table
                        :headers="header"
                        :items="item.getchild"
                        item-key="task_name"
                        class="elevation-1 custom-table"
                        style="font-weight:700;"
                        :hide-default-footer="true"
                        disable-pagination
                        mobile-breakpoint="0">
                        <template v-slot:item="{item}">
                            <tr>
                                <td><v-checkbox v-model="item['is_done']" @change="markdonefff(item)"></v-checkbox></td>
                                <td @click="efff(item)" style="cursor: pointer; white-space: pre-line;"><small>{{item['item']}}</small></td>
                                <td @click="efff(item)" style="cursor: pointer;"><small v-if="item['quantity'] == null">add a quantity</small><small v-else>{{item['quantity']}}</small></td>
                                <td @click="efff(item)" style="cursor: pointer;"><small v-if="item['color'] == null">add a color</small><small v-else>{{item['color']}}</small></td>
                                <td @click="efff(item)" style="cursor: pointer;"><small v-if="item['supplier_id'] == null">add a supplier</small><small v-else>{{item['supplier_id']}}</small></td>
                                <td @click="efff(item)" style="cursor: pointer;"><small v-if="item['product_code'] == null">add a product code</small><small v-else>{{item['product_code']}}</small></td>
                                <td style="cursor: pointer;"><small @click="efff(item)" v-if="item['link_to_product'] == null">add a link to product</small><a :href="item['link_to_product']" target="_blank" v-else><small>{{ item['link_to_product'] }}</small></a></td>
                                <td @click="efff(item)" style="cursor: pointer;"><small v-if="item['notes'] == null">add a note</small><small v-else>{{item['notes']}}</small></td>
                                <td @click="efff(item)" style="cursor: pointer;"><small v-if="item['budget'] == null">add a budget</small><small v-else>{{item['budget']}}</small></td>
                                <td @click="efff(item)" style="cursor: pointer;"><small v-if="item['actual'] == null">add an actual</small><small v-else>{{item['actual']}}</small></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-list-group>
            </v-list>
        </div>
        <!-- End Design Tracker FFF -->

        <!-- Design Tracker Appliances -->
        <div
            v-if="setup.isappliances"
        >
            <v-list>
                <v-list-group
                    v-for="item in items"
                    :key="item.title"
                    v-model="item.active"
                    no-action
                    :disabled="item.disabled"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title style="color: #0b4ef3;">
                                <v-badge
                                    color="#0b4ef3"
                                    bordered
                                >
                                    <v-icon slot="badge" v-if="item.disabled">mdi-exclamation-thick</v-icon>
                                    <v-icon slot="badge" v-else>mdi-check-bold</v-icon>
                                    <span slot="default"><strong>{{item.room}}</strong></span>
                                </v-badge>
                                <v-progress-linear
                                    :buffer-value="item.getall_count"
                                    :value="((item.getalldonecount_count / item.getall_count) * 100)"
                                    striped
                                    height="15"
                                    color="#7b9ff9"
                                >
                                    <template v-slot:default="{ value }">
                                        <strong>{{ value.toFixed(2) }}%</strong>
                                    </template>
                                </v-progress-linear>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-data-table
                        :headers="header"
                        :items="item.getchild"
                        item-key="task_name"
                        class="elevation-1 custom-table"
                        style="font-weight:700;"
                        :hide-default-footer="true"
                        disable-pagination
                        mobile-breakpoint="0">
                        <template v-slot:item="{item}">
                            <tr>
                                <td><v-checkbox v-model="item['is_done']" @change="markdoneappliance(item)"></v-checkbox></td>
                                <td @click="eappliances(item)" style="cursor: pointer; white-space: pre-line;"><small>{{item['item']}}</small></td>
                                <td @click="eappliances(item)" style="cursor: pointer;"><small v-if="item['quantity'] == null">add a quantity</small><small v-else>{{item['quantity']}}</small></td>
                                <td @click="eappliances(item)" style="cursor: pointer;"><small v-if="item['color'] == null">add a color</small><small v-else>{{item['color']}}</small></td>
                                <td @click="eappliances(item)" style="cursor: pointer;"><small v-if="item['supplier_id'] == null">add a supplier</small><small v-else>{{item['supplier_id']}}</small></td>
                                <td @click="eappliances(item)" style="cursor: pointer;"><small v-if="item['product_code'] == null">add a product code</small><small v-else>{{item['product_code']}}</small></td>
                                <td style="cursor: pointer;"><small @click="eappliances(item)" v-if="item['link_to_product'] == null">add a link to product</small><a :href="item['link_to_product']" target="_blank" v-else><small>{{ item['link_to_product'] }}</small></a></td>
                                <td @click="eappliances(item)" style="cursor: pointer;"><small v-if="item['notes'] == null">add a note</small><small v-else>{{item['notes']}}</small></td>
                                <td @click="eappliances(item)" style="cursor: pointer;"><small v-if="item['budget'] == null">add a budget</small><small v-else>{{item['budget']}}</small></td>
                                <td @click="eappliances(item)" style="cursor: pointer;"><small v-if="item['actual'] == null">add an actual</small><small v-else>{{item['actual']}}</small></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-list-group>
            </v-list>
        </div>
        <!-- End Design Tracker Appliances -->

        <!-- Design Tracker Flooring -->
        <div
            v-if="setup.isflooring"
        >
            <v-list>
                <v-list-group
                    v-for="item in items"
                    :key="item.title"
                    v-model="item.active"
                    no-action
                    :disabled="item.disabled"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title style="color: #0b4ef3;">
                                <v-badge
                                    color="#0b4ef3"
                                    bordered
                                >
                                    <v-icon slot="badge" v-if="item.disabled">mdi-exclamation-thick</v-icon>
                                    <v-icon slot="badge" v-else>mdi-check-bold</v-icon>
                                    <span slot="default"><strong>{{item.location}}</strong></span>
                                </v-badge>
                                <v-progress-linear
                                    :buffer-value="item.getallcount_count"
                                    :value="((item.getalldone_count / item.getallcount_count) * 100)"
                                    striped
                                    height="15"
                                    color="#7b9ff9"
                                >
                                    <template v-slot:default="{ value }">
                                        <strong>{{ value.toFixed(2) }}%</strong>
                                    </template>
                                </v-progress-linear>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-data-table
                        :headers="header"
                        :items="item.getchild"
                        item-key="task_name"
                        class="elevation-1 custom-table"
                        style="font-weight:700;"
                        :hide-default-footer="true"
                        disable-pagination
                        mobile-breakpoint="0">
                        <template v-slot:item="{item}">
                            <tr>
                                <td><v-checkbox v-model="item['is_done']" @change="markdoneflooring(item)"></v-checkbox></td>
                                <td @click="eflooring(item)" style="cursor: pointer; white-space: pre-line;"><small>{{item['location']}}</small></td>
                                <td @click="eflooring(item)" style="cursor: pointer;"><small v-if="item['sqm'] == null">add a sqm</small><small v-else>{{item['sqm']}}</small></td>
                                <td @click="eflooring(item)" style="cursor: pointer;"><small v-if="item['floor_type'] == null">add a floor_type</small><small v-else>{{item['floor_type']}}</small></td>
                                <td @click="eflooring(item)" style="cursor: pointer;"><small v-if="item['price_per_sqm'] == null">add a price per sqm</small><small v-else>{{item['price_per_sqm']}}</small></td>
                                <td @click="eflooring(item)" style="cursor: pointer;"><small v-if="item['total'] == null">add a total</small><small v-else>{{item['total']}}</small></td>
                                <td @click="eflooring(item)" style="cursor: pointer;"><small v-if="item['supplier_id'] == null">add a supplier</small><small v-else>{{ item['supplier_id'] }}</small></td>
                                <td @click="eflooring(item)" style="cursor: pointer;"><small v-if="item['notes'] == null">add a note</small><small v-else>{{item['notes']}}</small></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-list-group>
            </v-list>
        </div>
        <!-- End Design Tracker Flooring -->

        <!-- Decisions -->
        <div
            v-if="setup.isdecisions"
        >
            <v-list>
                <v-list-group
                    v-for="item in items"
                    :key="item.id"
                    v-model="item.active"
                    no-action
                    :disabled="item.disabled"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title style="color: #0b4ef3;">
                                <v-badge
                                    color="#0b4ef3"
                                    bordered>
                                    <v-icon slot="badge" v-if="item.disabled">mdi-exclamation-thick</v-icon>
                                    <v-icon slot="badge" v-else>mdi-check-bold</v-icon>
                                    <span slot="default"><strong>{{item.item}}</strong><br/><small>{{item.getnotdone_count}} task(s) remaining</small></span>
                                </v-badge>
                                <v-progress-linear
                                    :buffer-value="item.getdone_count + item.getnotdone_count"
                                    :value="((item.getdone_count / (item.getdone_count + item.getnotdone_count)) * 100)"
                                    striped
                                    height="15"
                                    color="#7b9ff9">
                                    <template v-slot:default="{ value }">
                                        <strong>{{ value.toFixed(2) }}%</strong>
                                    </template>
                                </v-progress-linear>
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-data-table
                        :headers="header"
                        :items="item.child"
                        item-key="task_name"
                        class="elevation-1 custom-table"
                        style="font-weight:700;"
                        :hide-default-footer="true"
                        disable-pagination
                        mobile-breakpoint="0">
                        <template v-slot:item="{item}">
                            <tr>
                                <td><v-checkbox v-model="item['is_done']" @change="markdonedecision(item)"></v-checkbox></td>
                                <td @click="decisionaddnotes(item)" style="cursor: pointer; white-space: pre-line;"><small v-if="!item['disabled']">{{item['item']}}</small></td>
                                <td @click="decisionaddnotes(item)" style="cursor: pointer;" v-if="!item['disabled']">
                                    <small v-if="item['notes'] == null || item['notes'].length === 0">
                                        add notes
                                    </small>
                                    <div v-else>
                                        <small
                                            v-for="(notes, notesindex) in item['notes']" 
                                            :key="notesindex"
                                        >
                                            {{notes}}<br/>
                                        </small>
                                    </div>
                                </td>
                                <td v-if="!item['disabled']"><v-btn text @click="deleteitemdecision(item)"><v-icon>mdi-delete</v-icon></v-btn></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-list-group>
            </v-list>
        </div>
        <!-- End Decisions -->

        <!-- Supplier Directory -->
        <!-- <div
            v-if="setup.supplierdirectory"
        >
            <v-card
                class="mx-auto"
            >
                <v-toolbar
                    text
                    elevation="0"
                    style="background: #062a82;"
                >

                    <v-toolbar-title style="color: white;">
                        <v-icon class="mr-2" style="color: white;">
                            mdi-store
                        </v-icon>
                        Supplier Directory
                    </v-toolbar-title>

                </v-toolbar>
                <div>
                    <v-col
                        cols="12"
                        md="12"
                        lg="12"
                        sm="12"
                        class="pl-5 pr-5 mt-5"
                    >
                        <v-row>
                            <v-autocomplete
                                v-model="supplierservicetype"
                                :items="dditem1"
                                label="service type"
                                item-value="id"
                                item-text="service_type"
                                outlined
                                dense
                                chips
                                small-chips
                                return-object
                                @change="searchsupplier"
                            >
                            </v-autocomplete>

                            <v-autocomplete
                                v-model="county"
                                :items="dditem2"
                                label="county"
                                item-value="phy_country"
                                item-text="phy_country"
                                outlined
                                dense
                                chips
                                small-chips
                                return-object
                                @change="searchsupplier"
                            >
                            </v-autocomplete>
                        </v-row>
                    </v-col>
                </div>
                <v-list two-line>
                    <v-list-item-group
                        active-class="pink--text"
                        class="flex"
                    >
                    <template 
                        v-for="item in items"
                    >
                        <v-list-item :key="item.title">
                            <v-list-item-content>
                            <v-list-item-title v-text="item.company_name"></v-list-item-title>

                            <v-list-item-subtitle
                                class="text--primary"
                            >
                                {{item.email}}<br/>
                                {{item.website}}<br/>
                                {{item.phone_number}}
                            </v-list-item-subtitle>

                            </v-list-item-content>

                            <v-list-item-content>
                                <v-list-item-title v-text="item.supplier_type.service_type"></v-list-item-title>
                                <v-list-item-subtitle
                                    class="text--primary"
                                >
                                    {{item.phy_country}}<br/>
                                    <v-icon @click="sendemail(item)" color="#0b4ef3;">mdi-email</v-icon>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-list-item-action-text></v-list-item-action-text>
                                <v-rating
                                    :value="item.average_rating"
                                    color="amber"
                                    dense
                                    half-increments
                                    readonly
                                    size="14"
                                ></v-rating>

                                <div class="grey--text ms-4">
                                    {{item.average_rating}} Review(s)
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </div> -->
        <!-- End Supplier Directory -->

    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
    'items',
    'header',
    'setup',
    'template',
    'dditem1',
    'dditem2'
  ],
  data: () => ({
    toggle_none: null,
    supplierservicetype: null,
    county: null
  }),
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    grabtemplate(){
      this.$emit('grabtemplate')
    },
    cancel(){
      this.$emit('cancelgrab')
    },
    addsection(){
      this.$emit('addsection')
    },
    addchild(data){
      this.$store.dispatch('pp/setparentdata', data)
      this.$emit('addchild')
    },
    deleteparent(data){
      this.$store.dispatch('pp/setparentdata', data)
      this.$emit('deleteparent')
    },
    editrecord(data){
      this.$store.dispatch('pp/setchildata', data)
      this.$emit('editrecord')
    },
    markdonefff(data){
      this.$emit('markdonefff', data)
    },
    efff(data){
      this.$store.dispatch('designtracker/seteditfff', data)
      this.$emit('efff')
    },
    markdoneappliance(data){
      this.$emit('markdoneappliance', data)
    },
    eappliances(data){
      this.$store.dispatch('designtracker/seteditappliances', data)
      this.$emit('eappliances')
    },
    markdoneflooring(data){
      this.$emit('markdoneflooring', data)
    },
    eflooring(data){
      this.$store.dispatch('designtracker/seteditflooring', data)
      this.$emit('eflooring')
    },
    markdonedecision(data){
      this.$emit('markdonedecision', data)
    },
    decisionaddnotes(data){
      this.$store.dispatch('decisions/setdecisionsedit', data)
      this.$emit('decisionaddnotes', data)
    },
    deleteitemdecision(data){
      this.$store.dispatch('decisions/setdecisionstodelete', data)
      this.$emit('deleteitemdecision')
    },
    searchsupplier(){
      this.$emit('searchsupplier', {
        service_type:           this.supplierservicetype.service_type == null ? null : this.supplierservicetype.service_type,
        county:                 this.county == null ? null : this.county
      })
    },
    sendemail(data){
      this.$store.dispatch('supplierdirectory/setsendemailto', data)
      this.$emit('sendemail')
    },
    showchecklist(){
        this.$emit('showchecklist')
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>