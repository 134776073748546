<template>
  <v-container fluid class="mt-5 flex">
    <v-row>
      <v-col
        cols="4"
      >
        <v-card
            style="max-height: 860px; overflow-y: scroll;"
        >
            <v-card-title style="fontSize: 14px;">
                <v-icon class="mr-3">mdi-account-group-outline</v-icon> users
                <v-spacer></v-spacer>
                <v-btn
                    text
                    style="text-transform: none !important;"
                    @click="create_user_dialog"
                >
                    <v-icon 
                        small
                        class="mr-3"
                    >
                        mdi-plus-outline
                    </v-icon>add user
                </v-btn>
            </v-card-title>
            <v-card-subtitle>
                <v-text-field
                    filled
                    dense
                    prepend-inner-icon="mdi-magnify"
                    placeholder="search users here..."
                    v-model="search"
                ></v-text-field>
            </v-card-subtitle>
            <v-card-text>
                <v-list dense>
                    <v-list-item-group
                        color="primary"
                    >
                        <v-list-item
                            v-for="(item, i) in get_users"
                            :key="i"
                            class="mb-3"
                            @click="select_user(item)"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-account-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <label>
                                        <v-icon
                                            v-if="item.from_sb3d === 1"
                                            small
                                        >
                                            mdi-alert-octagon-outline
                                        </v-icon>
                                        {{item.name}}
                                        <small
                                            v-if="item.from_sb3d === 1"
                                        >
                                            | SB3D Client
                                        </small>
                                        <small
                                            v-if="item.has_directory === 1"
                                        >
                                            | Directory
                                        </small>
                                    </label>
                                    <small style="display: block;">{{item.email}} | {{item.phone}}</small>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="8"
      >
        <v-card>
            <v-card-title
                style="fontSize: 14px;"
            >
                <strong>{{get_selected_user.name}}</strong>
                
                <v-spacer></v-spacer>
                <v-btn
                    v-if="get_selected_user.has_directory === 0"
                    dark
                    color="primary"
                    class="pl-10 pr-10"
                    dense
                    @click="create_directory"
                >
                    <v-icon 
                        class="mr-3"
                    >
                        mdi-plus-outline
                    </v-icon>create directory
                </v-btn>
                <label v-else>Has Directory</label>
            </v-card-title>
            <v-card-subtitle>
                <small>
                    <strong>{{get_selected_user.email}} | {{get_selected_user.phone}} 
                        <v-icon
                            small
                            @click="edit_information_user(get_selected_user)"
                        >
                            mdi-pen
                        </v-icon>
                    </strong>
                </small>
            </v-card-subtitle>
            <v-card-text>
                <v-row
                    class="flex"
                    v-if="get_selected_user.has_directory === 1"
                >
                    <v-col
                        v-for="(dir, dirindex) in get_folders"
                        :key="dirindex"
                        cols="6"
                        class="justify-center"
                    >
                        <v-btn
                            dark
                            color="primary"
                            outlined
                            block
                            @click="get_files_a(dir)"
                        >
                            {{dir.string}}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row
                    class="flex mt-5"
                    v-if="get_selected_user.has_directory === 1 && Object.keys(this.get_selected_folder).length !== 0"
                >
                    <v-container
                        fluid 
                        fill-heigth
                    >
                        <div
                            align="center"
                        >
                            <v-col
                                cols="12"
                            >
                                <small><strong>as of now uploader accepts pdf, jpg, jpeg, png. Inform dev team to add more supported file.</strong></small>
                                <v-file-input
                                    label="add file"
                                    outlined
                                    dense
                                    v-model="file"
                                    @change="upload_file"
                                ></v-file-input>
                                <v-text-field
                                    v-model="url_link"
                                    dense
                                    outlined
                                    label="link goes here..."
                                    prepend-icon="mdi-link"
                                    append-icon="mdi-send"
                                    @click:append="send_link"
                                    @keypress.enter="send_link"
                                >
                                </v-text-field>
                            </v-col>
                        </div>
                    </v-container>
                    <v-container
                        v-if="get_files.length === 0"
                        fluid 
                        fill-heigth
                    >
                        <div
                            align="center"
                        >
                            <v-col
                                cols="12"
                            >
                                Upload some file to get started
                            </v-col>
                        </div>
                    </v-container>
                    <v-container
                        v-else
                        fluid
                        fill-height
                    >
                        <v-row>
                            <v-col
                                cols="4"
                                v-for="(file, fileindex) in get_files"
                                :key="fileindex"
                            >
                                <v-card>
                                    <v-card-title
                                        style="fontSize: 14px;"
                                    >
                                        <small>{{file.name}}</small>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="red"
                                            text
                                            outlined
                                            small
                                        >
                                            <v-icon>mdi-delete-alert-outline</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-subtitle>
                                        <strong>{{file.extension_file}} - <small>{{file.created_at}}</small></strong>
                                    </v-card-subtitle>
                                    <v-card-text>
                                        <div
                                            v-if="file.extension_file === 'pdf'"
                                        >
                                            <img :src="`https://media.istockphoto.com/vectors/icon-major-file-format-vector-icon-illustration-vector-id1298834280?k=20&m=1298834280&s=612x612&w=0&h=SxEbyHKi18H1XpPpKKzoppWgMM3x0tc3veN5e6wl7Y8=`" style="max-width:125px; max-height:125px;"/>
                                        </div>
                                        <div
                                            v-if="file.extension_file === 'jpg' || file.extension_file === 'png' || file.extension_file === 'jpeg'"
                                        >
                                            <img :src="`${img_url}${file.path}`" style="max-width:250px; max-height:250px;"/>
                                        </div>
                                        <div
                                            v-if="file.extension_file === '.link'"
                                        >
                                            <a :href="file.link_to_assets" target="_blank">{{file.name}}</a>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-row>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
        <v-dialog
            max-width="560"
            v-model="d_user"
            persistent
        >
            <v-card>
                <v-card-title style="fontSize: 18px;">
                    <v-icon 
                        class="mr-3"
                    >
                        mdi-account-plus-outline
                    </v-icon>create user
                </v-card-title>

                <v-card-text>
                    <div
                        class="mt-3"
                    >
                        <label>fullname</label>
                        <v-text-field
                            v-model="c.name"
                            outlined
                            dense
                            label="name"
                            placeholder="John Doe"
                            prepend-inner-icon="mdi-account-outline"
                            type="text"
                        >
                        </v-text-field>
                        <label>email</label>
                        <v-text-field
                            v-model="c.email"
                            outlined
                            dense
                            label="email"
                            placeholder="johndoe@mail.com"
                            prepend-inner-icon="mdi-at"
                            type="email"
                        >
                        </v-text-field>
                        <label>mobile | phone</label>
                        <v-text-field
                            v-model="c.phone_number"
                            outlined
                            dense
                            label="mobile | phone"
                            placeholder="1234567890"
                            prepend-inner-icon="mdi-cellphone"
                            type="number"
                        >
                        </v-text-field>
                    </div>
                </v-card-text>
                    
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="d_user = !d_user"
                >
                    close
                </v-btn>
                <v-btn
                    color="primary"
                    class="pl-12 pr-12"
                    dark
                    @click="create_user"
                >
                    <v-icon 
                        class="mr-3"
                    >
                        mdi-account-plus-outline
                    </v-icon>create
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <!-- edit information -->
    <v-row justify="center">
        <v-dialog
            max-width="560"
            v-model="edit_information_dialog"
            persistent
        >
            <v-card>
                <v-card-title style="fontSize: 18px;">
                    <v-icon 
                        class="mr-3"
                    >
                        mdi-account-plus-outline
                    </v-icon>create user
                </v-card-title>

                <v-card-text>
                    <div
                        class="mt-3"
                    >
                        <label>fullname</label>
                        <v-text-field
                            v-model="e.name"
                            outlined
                            dense
                            :label="get_to_edit_user.name"
                            :placeholder="get_to_edit_user.name"
                            prepend-inner-icon="mdi-account-outline"
                            type="text"
                        >
                        </v-text-field>
                        <label>email</label>
                        <v-text-field
                            v-model="e.email"
                            outlined
                            dense
                            :label="get_to_edit_user.email"
                            :placeholder="get_to_edit_user.email"
                            prepend-inner-icon="mdi-at"
                            type="email"
                        >
                        </v-text-field>
                        <label>mobile | phone</label>
                        <v-text-field
                            v-model="e.phone"
                            outlined
                            dense
                            :label="get_to_edit_user.phone"
                            :placeholder="get_to_edit_user.phone"
                            prepend-inner-icon="mdi-cellphone"
                            type="number"
                        >
                        </v-text-field>
                    </div>
                </v-card-text>
                    
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="edit_information_dialog = !edit_information_dialog"
                >
                    close
                </v-btn>
                <v-btn
                    color="primary"
                    class="pl-12 pr-12"
                    dark
                    @click="update_user"
                >
                    <v-icon 
                        class="mr-3"
                    >
                        mdi-pen
                    </v-icon>update
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <!-- end edit information -->
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    search: null,
    d_user: false,
    c: {
        name:           null,
        email:          null,
        phone_number:   null
    },
    file: [],
    img_url: null,
    url_link: null,
    edit_information_dialog: false,
    e: {
        name: null,
        email: null,
        phone: null
    }
  }),
  mounted () {
    this.fsb3dc()
  },
  created () {
    this.img_url = process.env.VUE_APP_URL
  },
  computed: {
    ...mapGetters({
        // get_users:          'sb3d/get_users',
        get_folders:            'sb3d/get_folders',
        get_selected_user:      'sb3d/get_selected_user',
        get_files:              'sb3d/get_files',
        get_selected_folder:    'sb3d/get_selected_folder',
        get_to_edit_user:       'sb3d/get_to_edit_user'
    }),
    get_users(){
        return this.$store.getters['sb3d/get_users'](this.search)
    }
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async fsb3dc(){
      await this.$axios.get('/sb3d/fsb3dc')
      .then(({data}) => {
        this.$store.dispatch('sb3d/set_users', data.data)
        this.$store.dispatch('sb3d/set_folders', data.renderdata)
      })
    },
    create_user_dialog(){
        this.d_user = true
    },
    close_dialog(){
        this.d_user = false
        this.c = {}
    },
    async create_user(){
        await this.$axios.post('/sb3d/cfsb3dc', this.c)
        .then(({data}) => {
            if(data.response){
                this.fsb3dc()
                this.close_dialog()
                this.showSnackBar(data.message)
            }else{
                this.showSnackBar(data.message)
            }
        })
    },
    select_user(data){
        // console.log(data)
        this.$store.commit('sb3d/set_empty_set')
        this.$store.dispatch('sb3d/set_selected_user', data)
    },
    async create_directory(){
        await this.$axios.post('sb3d/cdsb3d', {id: this.get_selected_user.id})
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.fsb3dc()
            }
        })
    },
    async get_files_a(data){
        this.$store.dispatch('sb3d/set_selected_folder', data)
        await this.$axios.get(`/sb3d/fdsb3dc/${this.get_selected_user.id}`, data)
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('sb3d/set_files', data.data)
            }
        })
    },
    async upload_file(){
        // console.log(this.get_selected_folder)
        const formData = new FormData()
        formData.append('item', this.file)
        formData.append('id', this.get_selected_user.id)
        formData.append('dir', this.get_selected_folder.id)
        await this.$axios.post('sb3d/udfsb3dr', formData)
        .then(({data}) => {
            if(data.response){
                this.$store.commit('sb3d/refetch_files')
                this.file.length = 0
                this.file = []
            }
        })
    },
    async send_link(){
        // console.log(this.url_link)
        let tp = {
            link:       this.url_link,
            dir:        this.get_selected_folder.id,
            id:         this.get_selected_user.id
        }
        await this.$axios.post('sb3d/link_to_assets', tp)
        .then(({data}) => {
            if(data.response){
                this.url_link = null
                this.$store.commit('sb3d/refetch_files')
                this.file.length = 0
                this.file = []
            }
        })
    },
    edit_information_user(data){
        this.$store.dispatch('sb3d/set_to_edit_user', data)
        this.edit_information_dialog = true
    },
    async update_user(){
        const tp = {
            email: this.e.email,
            name: this.e.name,
            phone: this.e.phone,
            user_id: this.get_to_edit_user.id
        }
        await this.$axios.post('/sb3d/edit_user_information', tp)
        .then(({data}) => {
            if(data.response){
                this.close_edit_information_dialog()
                this.showSnackBar(data.message)
                this.select_user(data.data)
            }else{
                this.showSnackBar(data.message)
                return
            }
        })
    },
    close_edit_information_dialog(){
        this.edit_information_dialog = false
        this.e = {}
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>