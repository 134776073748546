<template>
    <v-container fluid class="mt-5">
        <v-card>
            <v-card-title
                style="fontSize: 16px; background: #062a82;"
            >
                <small style="color: white;">
                    <v-icon
                        class="mr-1"
                        style="color: white;"
                        small
                    >
                        mdi-briefcase-upload
                    </v-icon>
                    Upload Your Plans
                </small>
            </v-card-title>
            <v-card-text
                class="text-center mt-5"
            >
                <v-text-field
                    dense
                    outlined
                    label="project name"
                    v-model="u.name"
                    prepend-icon="mdi-file"
                >
                </v-text-field>
                <v-file-input
                    small-chips
                    label="upload plan"
                    v-model="u.file"
                    dense
                    outlined
                ></v-file-input>
                <v-text-field
                    dense
                    outlined
                    label="address"
                    v-model="u.address"
                    prepend-icon="mdi-card-text-outline"
                ></v-text-field>
                <v-text-field
                    dense
                    outlined
                    label="town"
                    v-model="u.town"
                    prepend-icon="mdi-card-text-outline"
                ></v-text-field>
                <v-autocomplete
                    :items="get_counties"
                    item-value="id"
                    item-text="name"
                    label="county"
                    v-model="u.county"
                    outlined
                    dense
                    prepend-icon="mdi-card-text-outline"
                    return-object
                >
                </v-autocomplete>
                <v-text-field
                    dense
                    outlined
                    label="eircode"
                    v-model="u.zipcode"
                    prepend-icon="mdi-card-text-outline"
                ></v-text-field>
                <v-autocomplete
                    :items="build_type"
                    label="build type"
                    v-model="u.type_of_build"
                    outlined
                    dense
                    prepend-icon="mdi-card-text-outline"
                >
                </v-autocomplete>
                <v-autocomplete
                    :items="build_stage"
                    label="build stage"
                    v-model="u.build_stage"
                    outlined
                    dense
                    prepend-icon="mdi-card-text-outline"
                >
                </v-autocomplete>
                <v-autocomplete
                    :items="get_service_types"
                    label="service type"
                    v-model="u.service_type"
                    item-value="id"
                    item-text="service_type"
                    outlined
                    dense
                    multiple
                    prepend-icon="mdi-card-text-outline"
                >
                </v-autocomplete>
                <v-text-field
                    dense
                    outlined
                    label="fullname"
                    v-model="u.fullname"
                    prepend-icon="mdi-card-text-outline"
                ></v-text-field>
                <v-text-field
                    dense
                    outlined
                    label="mobile number"
                    v-model="u.mobile"
                    prepend-icon="mdi-card-text-outline"
                ></v-text-field>
                <v-text-field
                    dense
                    outlined
                    label="email address"
                    v-model="u.email"
                    prepend-icon="mdi-card-text-outline"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="cancel"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="green darken-1"
                    @click="uploadfile"
                    dark
                    class="pl-10 pr-10"
                >
                    <v-icon class="mr-3">mdi-upload</v-icon>
                    upload
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    u: {
        name: null,
        file: [],
        address: null,
        town: null,
        county: null,
        zipcode: null,
        type_of_build: null,
        service_type: null,
        fullname: null,
        mobile: null,
        email: null
    },
    build_stage: [
        'Pending',
        'Started',
        'On-Hold',
        'Waiting on info',
        'Complete'
    ],
    build_type: [
        'Self Build',
        'Renovation'
    ]
  }),
  async mounted () {
    await this.$axios.get('counties')
    .then(({data}) => {
        this.$store.dispatch('non_auth_upload_plan/set_counties', data.data)
    })
    await this.$axios.get('get_service_types')
    .then(({data}) => {
        this.$store.dispatch('non_auth_upload_plan/set_service_types', data.data)
    })
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_counties:                   'non_auth_upload_plan/get_counties',
        get_service_types:              'non_auth_upload_plan/get_service_types'
    })
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    cancel(){
        this.$router.push({name: 'login'})
        this.u = {}
    },
    async uploadfile(){
        const tdata = new FormData()
        tdata.append('file',                                this.u.file)
        tdata.append('name',                                this.u.name)
        tdata.append('build_stage',                         this.u.build_stage)
        tdata.append('address',                             this.u.address)
        tdata.append('town',                                this.u.town)
        tdata.append('county',                              this.u.county.name)
        tdata.append('zipcode',                             this.u.zipcode)
        tdata.append('type_of_build',                       this.u.type_of_build)
        tdata.append('mobile',                              this.u.mobile)
        tdata.append('email',                               this.u.email)
        tdata.append('fullname',                            this.u.fullname)
        tdata.append('service_types',                       this.u.service_type)
        await this.$axios.post('upload_file', tdata)
        .then(({data}) => {
            if(data.response){
                this.u = {}
                this.showSnackBar(data.message)
            }else{
                this.showSnackBar(data.message)
            }
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>