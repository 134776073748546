<template>
    <v-row justify="center">
        <v-dialog 
            v-model="vp_dialog_fullscreen"
            persistent
            fullscreen>
            <v-card>
                <v-card-title>
                    {{ video_archive_item.name }}
                    <v-spacer></v-spacer>
                    <v-btn text fab @click="close_dialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="max-height: 60vh;">
                    <v-row>
                        <v-col cols="8" md="8" sm="12" width="75%" class="mt-5">
                            <video controls oncontextmenu="return false;" ref="video">
                                <source :src="APP_URL + video_archive_item.url" :type="video_archive_item.mime"> 
                            </video>
                        </v-col>
                        <v-col cols="4" md="4" sm="12" class="mt-5" style="max-height: 60vh;overflow-y: auto;text-align: justify;">
                            {{ video_archive_item.description }}
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="12" sm="12" class="mt-5" style="text-align: right;">
                            <v-btn color="error" @click="close_dialog()">
                                <v-icon>mdi-close</v-icon>
                                Close
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    data: () => ({
        vp_dialog_fullscreen: false,
        APP_URL: process.env.VUE_APP_URL,
    }),
    props: {
        show_dialog: Boolean,
        video_archive_item: Object
    },
    methods: {
        close_dialog() {
            this.$emit('close_dialog');
        }
    },
    watch: {
        show_dialog() {         
            this.vp_dialog_fullscreen = this.show_dialog;
            const that = this;
            setTimeout(function() {
                that.$refs.video.load();
            },100);
        }        
    }
}
</script>

<style lang="scss" scoped>
video {
    object-fit: initial;
    width: 100%;
    height: 60vh;   
}
</style>