<template>
    <v-container fluid class="mt-5 flex">
        <div v-if="getuser.project_id == null" align="center">
            <strong>You need to create a project</strong>
            <p>Kindly click the Proceed button below to create a project, and we can continue to add entries</p>
            <v-btn 
                color="#0b4ef3;" 
                dark 
                class="pl-16 pr-16" 
                rounded
                @click="$router.push({name: 'projectplanner'})"
            >
            Proceed
            </v-btn>
        </div>
        <div v-else>
            <v-card>
                <v-card-title style="background: #062a82;">
                <v-icon class="mr-2" style="color: white;">{{filemanagersetup.icon}}</v-icon>
                <small style="color: white;">{{filemanagersetup.title}} <v-icon color="white" style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon></small>
                <v-spacer></v-spacer>
                <v-btn
                  v-for="(btn, btnIndex) in filemanagersetup.buttons"
                  :key="btnIndex"
                  :dark="btn.dark"
                  :text="btn.text"
                  :class="btn.class"
                  :color="btn.color"
                  rounded
                  @click="btn.action ? adddirectory() : ''"
                >
                  <v-icon
                    :class="btn.classicon"
                  >
                    {{btn.icon}}
                  </v-icon>
                  {{btn.label}}
                </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-tabs
                      v-model="tab"
                      centered
                      icons-and-text
                    >
                    <v-tabs-slider></v-tabs-slider>
                      <v-tab
                        v-for="(tab, tabindex) in tabdata"
                        :key="tabindex"
                        :href="tab.href"
                      >
                        {{tab.label}}
                        <v-icon>{{tab.icon}}</v-icon>
                      </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                      <v-tab-item
                        value="tab-1"
                      >
                        <DataTable
                          :setup="filemanagertablesetup"
                          :items="getdirectories"
                          @openfiles="openfiles"
                        />
                        <DialogComponent
                          v-model="openfiledialog"
                          :value="openfiledialog"
                          :dialogvalue="dialogfilemanagersetup"
                          :childdata="getchildfiles"
                          @closedialog="closedialog"
                          @addfile="addfile"
                          @openfile="openfile"
                          @deleteitems="deleteitems"
                          @deletedirectory="deletedirectory"
                        />
                        <DialogComponent
                          v-model="addfiledialog"
                          :value="addfiledialog"
                          :dialogvalue="addfiledialogsetup"
                          @savefilemanagermedia="savefilemanagermedia"
                          @closedialog="closeaddfiledialog"
                        />
                        <DialogComponent
                          v-model="showfile"
                          :value="showfile"
                          :dialogvalue="showfilesetup"
                          :getfiletoopen="getfiletoopen"
                          @closedialog="closefilepreview"
                        />
                        <DialogComponent
                          v-model="addDirectoryDialog"
                          :value="addDirectoryDialog"
                          :dialogvalue="addDirectoryContent"
                          @savefilemanagermedia="savedirectory"
                          @closedialog="closedirectory"
                        />
                        <DialogWarning
                          v-model="dialogwarningdeleteitem"
                          :value="dialogwarningdeleteitem"
                          :dialogsvalue="dialogwarningdeleteitemcontent"
                          @delproject="proceeddeleteitems"
                          @donothing="donothing"
                        />
                        <DialogWarning
                          v-model="dialogwarningdeletedirectory"
                          :value="dialogwarningdeletedirectory"
                          :dialogsvalue="dialogwarningdeletedirectorycontent"
                          @delproject="proceeddeletedirectory"
                          @donothing="canceldeletedirectory"
                        />
                      </v-tab-item>
                      <v-tab-item
                        value="tab-2"
                      >
                        <selfbuild3d/>
                      </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
        </div>
        <v-dialog
          persistent
          v-model="show_tutorial"
          width="1000"
        >
          <template
            v-slot:activator="{ on, attrs }"
          >
            <v-icon
              :color="color"
              v-on="on"
              v-bind="attrs"
            >
              help
            </v-icon>
          </template>

          <v-card>
            <v-card-title>
              Tutorial
              <v-spacer></v-spacer>
              <v-icon
                small
                color="red"
                @click="show_tutorial = false;"
              >
                mdi-close
              </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <vue-pdf-embed source="file_manager.pdf" />
          </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from '../../components/DataTable.vue'
import DialogComponent from '../../components/DialogComponent.vue'
import DialogWarning from '../../components/DialogWarning.vue'
import selfbuild3d from './SelfBuild3D.vue'
// import GuidelineComponent from '@/components/GuidelineComponent.vue'
// import vuePDF from "vue-pdf";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    DataTable,
    DialogComponent,
    DialogWarning,
    selfbuild3d,
    // vuePDF,
    VuePdfEmbed
    // GuidelineComponent
  },
  props: [
  ],
  data: () => ({
    tab: null,
    show_tutorial: false,
    filemanagersetup: {
      title:    'File Manager',
      icon:     'mdi-file-multiple',
      buttons: [
        {
          id:           1,
          icon:         'mdi-plus',
          label:        'add directory',
          dark:         true,
          action:       true,
          text:         false,
          class:        'pl-8 pr-8',
          color:        '#0b4ef3',
          classicon:    'mr-2'
        }
      ]
    },
    filemanagertablesetup: {
      filemanager:  true,
      headers: [
        { text: 'Name', filterable: true, sortable: true, value: 'name'},
        { text: 'Contains', filterable: true, sortable: true, value: 'contains'},
        { text: 'Total Size', filterable: true, sortable: true, value: 'total_size'},
        { text: 'Last Updated', filterable: true, sortable: true},
      ]
    },
    tabdata: [
      {
        label:      'file manager',
        icon:       'mdi-file-multiple',
        href:       '#tab-1'
      },
      {
        label:      'self build 3d',
        icon:       'mdi-greenhouse',
        href:       '#tab-2'
      }
    ],
    openfiledialog: false,
    dialogfilemanagersetup: {
      filemanager:  true,
      title:        'directory',
      icon:         'mdi-file-multiple',
      iconclass:    'mr-2',
      button: [
        {
          id:           1,
          icon:         'mdi-delete',
          label:        'delete selected item/s',
          action:       true,
          dark:         false,
          text:         true,
          color:        '#0b4ef3',
          class:        '',
          classicon:    'mr-2'
        }
      ],
      buttoninner: [
        {
          id:           1,
          icon:         'mdi-plus',
          label:        'add media / file',
          action:       true,
          dark:         true,
          text:         false,
          color:        '#0b4ef3',
          class:        '',
          classicon:    'mr-2'
        }
      ],
      buttondeletedirectory: [
        {
          id:           1,
          icon:         'mdi-delete',
          label:        'delete directory',
          action:       true,
          dark:         false,
          text:         true,
          color:        '#0b4ef3',
          class:        '',
          classicon:    'mr-2'
        }
      ],
    },
      
    addfiledialog: false,
    addfiledialogsetup:{
      addfileinfilemanager: true,
      title:                'add file / media',
      icon:                 'mdi-file',
      iconclass:            'mr-2',
      textfield: [
        {
          id:               1,
          label:            'file name',
          hint:             'file name',
          icon:            'mdi-tooltip-text-outline'
        }
      ]
    },
    showfile: false,
    showfilesetup: {
      viewfile: true,
      title:    'view file'
    },
    dialogwarningdeleteitem: false,
    dialogwarningdeletedirectory: false,
    dialogwarningdeletedirectorycontent: {
      warningdelete: true,
      title: 'Are you sure you want to delete this directory?',
      content: 'This directory and all the data that belongs to it will be deleted, are you sure about this?',
      buttons: [
        {
          id:         2,
          label:      'Yes',
          action:     true,
          class:      '',
          text:       true,
          dark:       false
        },
        {
          id:         1,
          label:      'No',
          action:     false,
          color:      '#0b4ef3',
          text:       false,
          class:      'pl-16 pr-16',
          dark:       true
        },
      ]
    },
    dialogwarningdeleteitemcontent: {
      warningdelete: true,
      title: 'Are you sure you want to delete these item(s)?',
      content: 'All of the data that belongs to these item(s) will be deleted, are you sure about this?',
      buttons: [
        {
          id:         2,
          label:      'Yes',
          action:     true,
          class:      '',
          text:       true,
          dark:       false
        },
        {
          id:         1,
          label:      'No',
          action:     false,
          color:      '#0b4ef3',
          text:       false,
          class:      'pl-16 pr-16',
          dark:       true
        },
      ]
    },
    addDirectoryDialog: false,
    addDirectoryContent: {
      addfiledirectory: true,
      title:                'add directory',
      icon:                 'mdi-file',
      iconclass:            'mr-2',
      textfield: [
        {
          id:               1,
          label:            'directory name',
          hint:             'directory name',
          icon:            'mdi-tooltip-text-outline'
        }
      ]
    },
    color: 'white'
  }),
  mounted () {
  },
  created () {
    this.getdirectory()
  },
  computed: {
    ...mapGetters({
      getuser:              'auth/getuser',
      getproject:           'auth/getproject',
      getdirectories:       'filemanager/getdirectories',
      getchildfiles:        'filemanager/getchildfiles',
      getfiletoopen:        'filemanager/getfiletoopen',
      gettodeleteitems:     'filemanager/gettodeleteitems'
    })
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    adddirectory(){
      this.addDirectoryDialog = true
    },
    closedirectory(){
      this.addDirectoryDialog = false
    },
    async getdirectory(){
      // await this.$axios.get(`filemanager/getDir/${this.getproject.id}`)
      await this.$axios.get(`/filemanager_v2/get_directories/${this.getuser.id}/${this.getproject.id}`)
      .then(({data}) => {
        if(data.data.length == 0){
          this.showSnackBar('Generating Directories... Please wait...')
          this.generatedirectories()
          return
        }
        this.$store.dispatch('filemanager/setdirectories', data.data)
      })
    },
    async generatedirectories(){
      await this.$axios.get(`filemanager/generateDir/${this.getproject.id}`)
      .then(({data}) => {
        if(data.response){
          this.getdirectory()
        }
      })
    },
    openfiles(){
      this.openfiledialog = true
    },
    closedialog(){
      this.openfiledialog = false
      this.addfiledialog = false
      this.showfile = false
      this.dialogwarningdeleteitem = false
      this.dialogwarningdeletedirectory = false
      this.addDirectoryDialog = false
      this.$store.dispatch('filemanager/setchildfiles', [])
    },
    addfile(){
      this.addfiledialog = true
    },
    closeaddfiledialog(){
      this.addfiledialog = false
    },
    async savefilemanagermedia(data){
      let formData = new FormData()
      formData.append('file', data.file)
      formData.append('name', data.filename)
      formData.append('dirId', this.getchildfiles.id)
      await this.$axios.post(`filemanager/uploadFile/${this.getproject.id}`, formData)
      .then(({data}) => {
        if(data.response){
          this.closedialog()
          this.getdirectory()
        }
      })
    },
    openfile(){
      this.showfile = true
    },
    closefilepreview(){
      this.showfile = false
    },
    deleteitems(){
      this.dialogwarningdeleteitem = true
    },
    deletedirectory() {
      this.dialogwarningdeletedirectory = true
    },
    canceldeletedirectory() {
      this.dialogwarningdeletedirectory = false
    },
    async proceeddeletedirectory() {
      await this.$axios.post(`filemanager/deleteDir`, this.getchildfiles)
      .then(({data}) => {
        if(data.response){
          this.closedialog()
          this.getdirectory()
        }
      })
    },
    donothing(){
      this.dialogwarningdeleteitem = false
    },
    async proceeddeleteitems(){
      let tp = []
      this.gettodeleteitems.forEach( q => {
        tp.push({
          id:       q.id,
          dirId:    q.directory_id
        })
      })
      await this.$axios.post(`filemanager/deleteImage`, tp)
      .then(({data}) => {
        if(data.response){
          this.closedialog()
          this.getdirectory()
        }
      })
    },
    async savedirectory(data){
      await this.$axios.post(`filemanager/addDirectory/${this.getproject.id}`, {directory: data.filename})
      .then(({data}) => {
        if(data.response){
          this.closedialog()
          this.getdirectory()
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>