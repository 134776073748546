import Vue from 'vue';

export default {
    namespaced: true,
    state: {
      country: [],
      supplierlist: [],
      servicetypes: [],
      sendemailto: {},
      supplier_report: {}
    },
    mutations: {
      setcountry(state, payload){
        state.country = [...payload]
      },
      setsupplierlist(state, payload){
        state.supplierlist = [...payload]
      },
      setservicetypes(state, payload){
        state.servicetypes = [...payload]
      },
      setsendemailto(state, payload){
        state.sendemailto = payload
      },
      set_supplier_report(state, payload){
        state.supplier_report = payload
      }
    },
    getters: {
      getcountry(state){
        return state.country
      },
      getsupplierlist(state){
        return state.supplierlist
      },
      getservicetypes(state){
        return state.servicetypes
      },
      getsendemailto(state){
        return state.sendemailto
      },
      get_supplier_report(state){
        return state.supplier_report
      }
    },
    actions: {
      setcountry({commit}, payload){
        commit('setcountry', payload)
      },
      setsupplierlist({commit}, payload){
        commit('setsupplierlist', payload)
      },
      setservicetypes({commit}, payload){
        commit('setservicetypes', payload)
      },
      setsendemailto({commit}, payload){
        commit('setsendemailto', payload)
      },
      set_supplier_report({commit}, payload){
        commit('set_supplier_report', payload)
      },
      async tracked_click_actions({commit}, payload) {
        await Vue.axios.post('supplierdirectory/tracked_clicks', payload).then( ({data}) => {
          if ( data.response == true ) {
            commit('');
          } else {
            console.log(data);
          }
        });
      }
    }
}