<template>
    <v-container fluid class="flex">
        <v-card elevation="0">
            <!-- <v-card-title class="mt-3 white--text" style="background: #062a82">
                <v-icon class="white--text pr-4">
                    mdi-monitor-dashboard
                </v-icon>
                <small>Dashboard</small>
            </v-card-title> -->
            <v-card-text>
                <v-row dense>
                    <v-col cols="12" lg="7" md="7" sm="12">
                        <v-card
                            :class="$vuetify.breakpoint.mdAndUp ? 'v-card-shadow welcome-card-desktop' : 'v-card-shadow welcome-card-mobile'">
                            <v-card-title
                                style="color:#0037BC;font-size:22px;padding:0px;word-break:break-word;white-space:pre-wrap;">
                                <div class="d-flex"><img :src="'/hand40x40.png'" width="40" height="40"
                                        style="margin-right:16px;">
                                    <p style="font-weight:400;">Hello <b>{{ getuser.info.name.split(' ')[0] }}</b>, welcome
                                        to your Self Build Helper Dashboard!</p>
                                </div>
                            </v-card-title>
                            <v-card-subtitle :class="$vuetify.breakpoint.mdAndUp ? 'welcome-margin mt-1' : 'mt-1'"
                                style="font-size:16px;color:black;padding:0px;">Here you can see all
                                your build's progress at a glance.</v-card-subtitle>
                            <v-card-text :class="$vuetify.breakpoint.mdAndUp ? 'welcome-margin' : ''"
                                style="color:black;padding:0px;margin-top:48px;">
                                <v-row dense style="font-size:16px;line-height:18.75px;margin-bottom:24px;margin-top:0px;">
                                    <v-col cols="12" lg="6" md="6" sm="12" style="padding-top:0px;padding-bottom:0px;">
                                        <div class="d-flex">
                                            <div><img :src="'/building16x16.png'" class="mr-2"></div>
                                            <div>Project Name:</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="12" style="padding-top:0px;padding-bottom:0px;"><b>{{
                                        getuser.project_id.name }}</b></v-col>
                                </v-row>
                                <v-row dense style="font-size:16px;line-height:18.75px;margin-bottom:24px;margin-top:0px;">
                                    <v-col cols="12" lg="6" md="6" sm="12" style="padding-top:0px;padding-bottom:0px;">
                                        <div class="d-flex">
                                            <div><img :src="'/size16x16.png'" class="mr-2"></div>
                                            <div>Project Size:</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="12" style="padding-top:0px;padding-bottom:0px;">
                                        <b v-if="get_budgetmanager.sqm != null">
                                            {{ get_budgetmanager.sqm }} sqm
                                        </b>
                                        <v-btn v-else text @click="view_module('budgetmanager')"
                                            style="text-transform:none;height:15px;font-size:16px;padding-left:0px;padding-right:0px;min-width:0px;"
                                            dense color="#0B52FF"><b>Select template</b></v-btn>
                                    </v-col>
                                </v-row>
                                <v-row dense style="font-size:16px;line-height:18.75px;margin-bottom:24px;margin-top:0px;">
                                    <v-col cols="12" lg="6" md="6" sm="12" style="padding-top:0px;padding-bottom:0px;">
                                        <div class="d-flex">
                                            <div><img :src="'/approve16x16.png'" class="mr-2"></div>
                                            <div>Date planning was approved:</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="12" style="padding-top:0px;padding-bottom:0px;">
                                        <v-dialog ref="dialog" v-model="planning_modal" persistent width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" text
                                                    style="text-transform:none;height:20px;font-size:16px;padding-left:0px;padding-right:0px;min-width:0px;"
                                                    dense
                                                    :color="(get_dashboard.planning_approved_date) ? 'black' : '#0B52FF'">
                                                    <b v-if="get_dashboard && get_dashboard.planning_approved_date != null">{{
                                                        $date(get_dashboard.planning_approved_date).format('MMM DD, YYYY')
                                                    }}</b>
                                                    <b v-else>Select date</b>
                                                </v-btn>
                                            </template>
                                            <v-date-picker v-model="get_dashboard.planning_approved_date" scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="planning_modal = false">
                                                    Cancel
                                                </v-btn>
                                                <v-btn text color="primary" @click="create_dashboard_data()">
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </v-col>
                                </v-row>
                                <v-row dense style="font-size:16px;line-height:18.75px;margin-bottom:24px;margin-top:0px;">
                                    <v-col cols="12" lg="6" md="6" sm="12" style="padding-top:0px;padding-bottom:0px;">
                                        <div class="d-flex">
                                            <div><img :src="'/shovel16x16.png'" class="mr-2"></div>
                                            <div>Date you broke ground:</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="12" style="padding-top:0px;padding-bottom:0px;">
                                        <v-dialog ref="dialog" v-model="broke_ground_modal" persistent width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" text
                                                    style="text-transform:none;height:20px;font-size:16px;padding-left:0px;padding-right:0px;min-width:0px;"
                                                    dense :color="(get_dashboard.broke_ground_date) ? 'black' : '#0B52FF'">
                                                    <b v-if="get_dashboard && get_dashboard.broke_ground_date != null">{{
                                                        $date(get_dashboard.broke_ground_date).format('MMM DD, YYYY') }}</b>
                                                    <b v-else>Select date</b>
                                                </v-btn>
                                            </template>
                                            <v-date-picker v-model="get_dashboard.broke_ground_date" scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="broke_ground_modal = false">
                                                    Cancel
                                                </v-btn>
                                                <v-btn text color="primary" @click="create_dashboard_data()">
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </v-col>
                                </v-row>
                                <v-row dense style="font-size:16px;line-height:18.75px;margin-bottom:24px;margin-top:0px;">
                                    <v-col cols="12" lg="6" md="6" sm="12" style="padding-top:0px;padding-bottom:0px;">
                                        <div class="d-flex">
                                            <div><img :src="'/euro16x16.png'" class="mr-2"></div>
                                            <div>Budget:</div>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="12" style="padding-top:0px;padding-bottom:0px;"
                                        v-if="getcostestimationdata[0]"><b>€{{ getcostestimationdata[0].amount |
                                            formatNumber }}</b></v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="5" md="5" sm="12">
                        <RemindersTable />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-card style="border-radius:10px;padding:24px;" class="mt-3 v-card-shadow">
                            <v-card-title style="padding: 0px;font-size:24px;font-weight:700;">
                                <div class="d-flex" style="height:40px;">
                                    <img :src="'/task.svg'" class="mr-5" width="40" height="40" style="margin:auto;">
                                    <div style="margin:auto;">Your next tasks</div>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn @click="view_module('projectplannerv2')" text class="ml-3"
                                    style="text-transform:none;font-size:18px;font-weight:400;" color="#0B52FF">
                                    View
                                </v-btn>
                            </v-card-title>
                            <v-card-text style="color:black;margin-top:32px;">
                                <v-row v-if="sub_tasks.length > 0">
                                    <v-col cols="12" lg="4" md="4" sm="12"
                                        :style="$vuetify.breakpoint.smAndDown ? 'flex-basis:1;' : 'padding-right:50px;padding-left:0px;'" v-for="i in 3"
                                        :key="i">
                                        <div :style="($vuetify.breakpoint.mdAndUp && i < 3) ? 'border-right:1px rgba(0, 0, 0, 0.50) solid;' : ''">
                                            <div class="d-flex" style="margin-bottom:12px;">
                                                <img :src="(i == 1) ? '/layout.svg' : (i == 2) ? '/submit.svg' : '/information.svg'"
                                                    width="17" height="17"
                                                    style="margin-top:auto;margin-bottom:auto;margin-right:12px;">
                                                <div style="margin-top:auto;margin-bottom:auto;font-size:20px;font-weight:600;">
                                                    {{ sub_tasks[i - 1].name }}</div>
                                            </div>
                                            <div
                                                :class="(sub_tasks[i - 1].status === 'Pending') ? 'task-red' :
                                                    (sub_tasks[i - 1].status === 'Started') ? 'task-green' :
                                                        (sub_tasks[i - 1].status === 'On-Hold') ? 'task-red' :
                                                            (sub_tasks[i - 1].status === 'Waiting-on Info') ? 'task-orange' : ''">
                                                {{ sub_tasks[i - 1].status }}
                                            </div>
                                            <div class="task-details" v-if="sub_tasks[i - 1].duration">
                                                Duration: {{ sub_tasks[i - 1].duration }} days
                                            </div>
                                            <div class="task-details" v-else>Need duration</div>
                                            <div class="task-details" v-if="sub_tasks[i - 1].start">
                                                Start: {{ sub_tasks[i - 1].start }}
                                            </div>
                                            <div class="task-details" v-else>Need start date</div>
                                            <div class="task-details" v-if="sub_tasks[i - 1].finish">
                                                Finish: {{ sub_tasks[i - 1].finish }}
                                            </div>
                                            <div class="task-details" v-else>Need finish date</div>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row v-else>
                                    <v-col cols="12" class="pt-5">
                                        No tasks found
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" lg="3" md="6">
                        <v-card class="mt-3" style="border-radius:10px;padding:24px;height:380px;margin-right:7px;">
                            <v-card-title class="header-row">
                                <div class="d-flex" style="height:40px;">
                                    <img :src="'/euro40x40.png'" class="mr-4" width="40" height="40" style="margin:auto;">
                                    <div style="margin:auto;">Money saved to date using Self Build Helper!</div>
                                </div>
                            </v-card-title>
                            <v-card-text style="width:100%;padding:0px;height:210px;">
                                <VueApexCharts :options="get_budgetmanager.money_saved_options" height="380"
                                    :series="get_budgetmanager.money_saved_series"></VueApexCharts>
                            </v-card-text>
                            <v-card-text>
                                <div style="text-align:center;font-size:32px;font-weight:700;color:black;">€{{
                                    get_budgetmanager.money_saved | formatNumberNoDecimal }}</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <!-- <v-col cols="12" lg="3" md="6">
                        <v-card class="mt-3" style="border-radius:10px;padding:24px;height:380px;margin-right:7px;">
                            <v-card-title class="header-row">
                                <div class="d-flex" style="height:40px;">
                                    <img :src="'/time40x40.png'" class="mr-4" width="40" height="40" style="margin:auto;">
                                    <div style="margin:auto;">Time saved to date using Self Build Helper!</div>
                                </div>
                            </v-card-title>
                            <v-card-text style="width:100%;padding:0px;height:210px;">
                                <VueApexCharts :options="get_budgetmanager.time_saved_options" height="380"
                                    :series="get_budgetmanager.time_saved_series"></VueApexCharts>
                            </v-card-text>
                            <v-card-text>
                                <div style="text-align:center;font-size:32px;font-weight:700;color:black;">{{ get_budgetmanager.time_saved }} Hours</div>
                            </v-card-text>
                        </v-card>
                    </v-col> -->
                    <v-col cols="12" lg="9" md="6">
                        <v-card
                            :style="$vuetify.breakpoint.smAndDown ? 'border-radius:10px;;' : 'border-radius:10px;height:380px;'"
                            class="mt-3 v-card-shadow">
                            <v-card-title class="header-row" style="padding:24px 24px 0px 24px;">
                                <div class="d-flex" style="height:40px;">
                                    <img :src="'/money40x40.png'" class="mr-5" width="40" height="40" style="margin:auto;">
                                    <div style="margin:auto;">Budget Distribution</div>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn @click="view_module('budgetmanager')" text class="ml-3"
                                    style="text-transform:none;font-size:18px;font-weight:400;" color="#0B52FF">View</v-btn>
                            </v-card-title>
                            <v-card-text
                                :style="$vuetify.breakpoint.smAndDown ? 'padding:0px 24px 24px 24px;' : 'padding:0px 20px 0px 5px;'">
                                <div style="overflow-x:visible;overflow-y:hidden;width:100%;">
                                    <VueApexCharts :options="get_budgetmanager.budget_options" height="300"
                                        :width="$vuetify.breakpoint.smAndDown ? get_budgetmanager.budget_series[0].data.length * 150 : '100%'"
                                        :series="get_budgetmanager.budget_series"></VueApexCharts>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row dense>
                    <!--<v-col cols="3">
                        <v-card class="mt-3">
                            <v-card-title class="mt-3 white--text" style="background: #062a82">Reminders</v-card-title>                            
                            <v-card-text class="pt-4" style="background-color: #F5F5F5;height: 400px;">
                                <VueApexCharts type="donut" height="350" :options="get_todos.chart_options" :series="get_todos.series"></VueApexCharts>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3">
                        <v-card class="mt-3">
                            <v-card-title 
                                class="mt-3 white--text" 
                                style="background: #062a82">
                                Site Diary 
                                <v-spacer></v-spacer>
                                <v-btn @click="view_module('sitediary')" small text class="white--text ml-3">view</v-btn>
                            </v-card-title>                            
                            <v-card-text class="pt-4" style="background-color: #F5F5F5;height: 400px;">
                                <VueApexCharts type="polarArea" height="350" :options="get_sitediary.chart_options" :series="get_sitediary.series"></VueApexCharts>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3">
                        <v-card class="mt-3">
                            <v-card-title class="mt-3 white--text" style="background: #062a82">
                                Snags
                                <v-spacer></v-spacer>
                                <v-btn @click="view_module('snags')" small text class="white--text ml-3">view</v-btn>
                            </v-card-title>                            
                            <v-card-text class="pt-4" style="background-color: #F5F5F5;height: 400px;">
                                <VueApexCharts type="donut" height="350" :options="get_snags.chart_options" :series="get_snags.series"></VueApexCharts>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3">
                        <v-card class="mt-3">
                            <v-card-title class="mt-3 white--text" style="background: #062a82">
                                File Manager
                                <v-spacer></v-spacer>
                                <v-btn @click="view_module('filemanager')" small text class="white--text ml-3">view</v-btn>
                            </v-card-title>                            
                            <v-card-text class="pt-4" style="background-color: #F5F5F5;height: 400px;">
                                <VueApexCharts type="polarArea" height="350" :options="get_filemanager.chart_options" :series="get_filemanager.series"></VueApexCharts>
                            </v-card-text>
                        </v-card>
                    </v-col>-->
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-card style="border-radius:10px;padding:24px;" class="mt-3 v-card-shadow">
                            <v-card-title class="header-row">
                                <div class="d-flex" style="height:40px;">
                                    <img :src="'/design40x40.png'" class="mr-5" width="40" height="40" style="margin:auto;">
                                    <div style="margin:auto;">Design Tracker</div>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn @click="view_module('designtracker')" text class="ml-3"
                                    style="text-transform:none;font-size:18px;font-weight:400;" color="#0B52FF">View</v-btn>
                            </v-card-title>
                            <v-card-text class="mt-2">
                                <v-tabs v-model="tab">
                                    <v-tab href="#fff">
                                        <b>Furnitures, Fixtures and Fittings</b>
                                    </v-tab>
                                    <v-tab href="#appliances">
                                        <b>Appliances</b>
                                    </v-tab>
                                    <v-tab href="#fc">
                                        <b>Flooring Calculations</b>
                                    </v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tab" disabled>
                                    <v-tab-item value="fff">
                                        <div style="overflow-x:visible;overflow-y:hidden;width:100%;">
                                            <VueApexCharts type="bar" height="330"
                                                :width="$vuetify.breakpoint.smAndDown ? get_designtracker_fff.series[0].data.length * 100 : '100%'"
                                                :options="get_designtracker_fff.chart_options"
                                                :series="get_designtracker_fff.series"></VueApexCharts>
                                        </div>
                                    </v-tab-item>
                                    <v-tab-item value="appliances">
                                        <div style="overflow-x:visible;overflow-y:hidden;width:100%;">
                                            <VueApexCharts type="bar" height="330"
                                                :width="$vuetify.breakpoint.smAndDown ? get_designtracker_appliances.series[0].data.length * 100 : '100%'"
                                                :options="get_designtracker_appliances.chart_options"
                                                :series="get_designtracker_appliances.series"></VueApexCharts>
                                        </div>
                                    </v-tab-item>
                                    <v-tab-item value="fc">
                                        <div style="overflow-x:visible;overflow-y:hidden;width:100%;">
                                            <VueApexCharts type="bar" height="330"
                                                :width="$vuetify.breakpoint.smAndDown ? get_designtracker_fc.series[0].data.length * 100 : '100%'"
                                                :options="get_designtracker_fc.chart_options"
                                                :series="get_designtracker_fc.series"></VueApexCharts>
                                        </div>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-card style="border-radius:10px;padding:24px;" class="mt-3 v-card-shadow">
                            <v-card-title class="header-row">
                                <div class="d-flex" style="height:40px;">
                                    <img :src="'/checklist40x40.png'" class="mr-5" width="40" height="40"
                                        style="margin:auto;">
                                    <div style="margin:auto;">Decisions</div>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn @click="view_module('decision')" text class="ml-3"
                                    style="text-transform:none;font-size:18px;font-weight:400;" color="#0B52FF">View</v-btn>
                            </v-card-title>
                            <v-card-text class="pt-4">
                                <div style="overflow-x:visible;overflow-y:hidden;width:100%;">
                                    <VueApexCharts type="bar" height="330"
                                        :width="$vuetify.breakpoint.smAndDown ? get_decisions.series[0].data.length * 100 : '100%'"
                                        :options="get_decisions.chart_options" :series="get_decisions.series">
                                    </VueApexCharts>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { mapActions, mapGetters } from 'vuex';
import RemindersTable from './RemindersTable.vue'
import { UserAccess } from '../../helpertraining/modules/UserAccess';
export default {
    data: () => ({
        tab: null,
        sub_tasks: [],
        access_tobe_granted: ['Premium Tier', 'Standard Tier'],
        planning_modal: false,
        broke_ground_modal: false
    }),
    components: {
        VueApexCharts, RemindersTable
    },
    computed: {
        ...mapGetters({
            getuser: 'auth/getuser',
            get_todos: 'dashboard/get_todos_getter',
            get_sitediary: 'dashboard/get_sitediary_getter',
            get_snags: 'dashboard/get_snags_getter',
            get_filemanager: 'dashboard/get_filemanager_getter',
            get_decisions: 'dashboard/get_decisions_getter',
            get_designtracker_fff: 'dashboard/get_designtracker_fff_getter',
            get_designtracker_appliances: 'dashboard/get_designtracker_appliances_getter',
            get_designtracker_fc: 'dashboard/get_designtracker_fc_getter',
            get_budgetmanager: 'dashboard/get_budgetmanager_getter',
            get_dashboard: 'dashboard/get_dashboard_getter',
            getcostestimationdata: 'costestimation/getcostestimationdata',
            get_projects: 'project_planner_v2/get_projects_getter',
            getchosentemplate: 'budgetmanager/getchosentemplate'
        })
    },
    methods: {
        ...mapActions({
            fetch_reminders: 'dashboard/fetch_reminders_action',
            fetch_sitediary: 'dashboard/fetch_sitediary_action',
            fetch_snags: 'dashboard/fetch_snags_action',
            fetch_filemanager: 'dashboard/fetch_filemanager_action',
            fetch_decisions: 'dashboard/fetch_decisions_action',
            fetch_designtracker_fff: 'dashboard/fetch_designtracker_fff_action',
            fetch_designtracker_appliances: 'dashboard/fetch_designtracker_appliances_action',
            fetch_designtracker_fc: 'dashboard/fetch_designtracker_fc_action',
            fetch_budgetmanager: 'dashboard/fetch_budgetmanager_action',
            fetch_dashboard: 'dashboard/fetch_dashboard_action',
            fetch_costestimation: 'costestimation/refetchcostestimatin',
            fetch_projects_api: 'project_planner_v2/fetch_projects_api_actions'
        }),
        view_module(module_name) {
            this.$router.push({ name: module_name });
        },
        granting_user_access() {
            const userAccess = new UserAccess(this.getuser.info.access_id, this.access_tobe_granted);
            return userAccess.get_access_grant() &&
                userAccess.allow_multiple_projects(this.getuser.info, this.get_projects.length);
        },
        async fetch_project_tasks() {
            if (this.get_projects[0] == null) {
                await this.fetch_projects_api(this.granting_user_access())
                    .then(() => {
                        this.set_project_tasks()
                    })
            }
            else {
                this.set_project_tasks()
            }
        },
        set_project_tasks() {
            if (this.get_projects[0] != null && this.get_projects[0].sections != null) {
                let sections = this.get_projects[0].sections
                let all_sub_tasks = []
                sections.forEach(item => {
                    all_sub_tasks = all_sub_tasks.concat(item.get_sub_tasks)
                })
                this.sub_tasks = all_sub_tasks.filter(item => item.status != 'Complete')
            }
        },
        create_dashboard_data() {
            this.planning_modal = false
            this.broke_ground_modal = false
            let payload = {
                users_id: this.getuser.id,
                project_id: this.getuser.project_id.id,
                planning_approved_date: this.get_dashboard.planning_approved_date,
                broke_ground_date: this.get_dashboard.broke_ground_date
            }
            this.$store.dispatch('dashboard/set_dashboard_action', payload)
        }
    },
    mounted() {
        setTimeout(() => {
            this.fetch_reminders({ users_id: this.getuser.id, project_id: this.getuser.project_id.id });
            this.fetch_sitediary({ users_id: this.getuser.id, project_id: this.getuser.project_id.id });
            this.fetch_snags({ users_id: this.getuser.id, project_id: this.getuser.project_id.id });
            this.fetch_filemanager({ users_id: this.getuser.id, project_id: this.getuser.project_id.id });
            this.fetch_decisions({ users_id: this.getuser.id, project_id: this.getuser.project_id.id });
            this.fetch_designtracker_fff({ users_id: this.getuser.id, project_id: this.getuser.project_id.id });
            this.fetch_designtracker_appliances({ users_id: this.getuser.id, project_id: this.getuser.project_id.id });
            this.fetch_designtracker_fc({ users_id: this.getuser.id, project_id: this.getuser.project_id.id });
            if (this.getchosentemplate != null && this.getchosentemplate.id != null) {
                this.fetch_budgetmanager({ users_id: this.getuser.id, project_id: this.getuser.project_id.id, template_id: this.getchosentemplate.id });
            }
            this.fetch_costestimation({ id: this.getuser.project_id.id });
            this.fetch_project_tasks()
            this.fetch_dashboard({ users_id: this.getuser.id, project_id: this.getuser.project_id.id })
        }, 400);
    },
    created() {
    }
}
</script>

<style scoped lang="scss">
.header-row {
    font-size: 18px;
    font-weight: 700;
    word-break: break-word;
    white-space: pre-wrap;
    padding: 0px;
    line-height: 23.4px;
}

:deep(.apexcharts-legend) {
    flex-direction: column !important;
}

:deep(.apexcharts-label tspan) {
    text-transform: uppercase;
    word-break: break-all;
    word-wrap: break-word;
}

.v-card-shadow {
    box-shadow: 0px 2px 4px -1px rgba(100, 100, 100, 0.2), 0px 4px 5px 0px rgba(100, 100, 100, 0.14), 0px 1px 10px 0px rgba(100, 100, 100, 0.12) !important;
}

.welcome-margin {
    margin-left: 56px;
}

.welcome-card-desktop {
    height: 406px;
    border-radius: 10px;
    padding: 28px 24px 28px 24px;
    margin-right: 7px;
}

.welcome-card-mobile {
    border-radius: 10px;
    padding: 28px 24px 28px 24px;
    margin-right: 7px;
}

.align-right {
    text-align: right;
}

.task-green {
    color: green;
    font-size: 12px;
    font-weight: 700;
}

.task-orange {
    color: orange;
    font-size: 12px;
    font-weight: 700;
}

.task-red {
    color: red;
    font-size: 12px;
    font-weight: 700;
}

.task-details {
    font-size: 16px;
    font-weight: 400;
    margin-top: 12px;
}
</style>