import store from '@/states/index.js'

import FormRequest from '../FormRequest'

export default [
    {
        path: '/form_request',
        component: FormRequest,
        name: '/form_request',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 1){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]