<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            scrollable
            max-width="690">
            <v-card>
                <v-card-title>
                    <v-icon>mdi-plus</v-icon>
                    <small>Add Section</small>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        outlined
                        prepend-icon="mdi-tooltip-text"
                        label="Add Section"
                        dense
                        class="mt-3"
                        v-model="section_name">
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="closedialog">
                        Close
                    </v-btn>
                    <v-btn
                        color="#093cba"
                        dark
                        @click="save_section"
                        class="pl-16 pr-16">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data: () => ({
        dialog: false,        
        section_name: ""
    }),
    props: {
        add_section_dialog: Boolean,
        project_id: Number
    },
    methods: {
        ...mapActions({
            add_project_section:    'project_planner_v2/add_project_section_actions'           
        }),
        async save_section() {           
            await this.add_project_section({project_id: this.project_id, data: {"name": this.section_name}});           
            this.closedialog();
        },
        closedialog() {
            this.section_name = '';
            this.$emit('close_dialog');
        }
    },
    watch: {
        add_section_dialog() {
            this.dialog = this.add_section_dialog;
        }
    }
}
</script>