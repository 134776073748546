<template>
    <v-container fluid class="mt-5 flex">
        <v-card>
            <v-card-title style="background: #062a82;">
            <v-icon class="mr-2" style="color: white;">{{helpertrainingsetup.icon}}</v-icon>
            <small style="color: white;">{{helpertrainingsetup.title}} 
                <!-- <GuidelineComponent :color="color" /> -->
            </small>
            </v-card-title>
            <v-card-text>
                <v-tabs
                    v-model="tab"
                    centered
                    icons-and-text>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab
                        v-for="(tab, tabindex) in helpertrainingsetup.tabs"
                        :key="tabindex"
                        :href="tab.href">
                        {{ tab.label }}
                        <v-icon>{{tab.icon}}</v-icon>
                    </v-tab>
                    <v-tabs-items v-model="tab">
                        <v-tab-item
                            value="videos">
                            <VideoIterator></VideoIterator>
                        </v-tab-item>
                        <v-tab-item
                            value="pdf">
                            <PdfTable></PdfTable>
                        </v-tab-item>
                    </v-tabs-items>                   
                </v-tabs>
                <!--<FreeTierVideoPdf
                    v-else
                    :grant_access="granting_user_access">
                </FreeTierVideoPdf>-->
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import VideoIterator from './components/VideoIterator.vue';
import PdfTable from './components/PdfTable.vue';
//import FreeTierVideoPdf from './components/FreeTierVideoPdf.vue';
import { UserAccess } from './modules/UserAccess.js';
import { mapGetters } from 'vuex';
// import GuidelineComponent from '@/components/GuidelineComponent.vue';

export default {
    data: () => ({
        tab: null,
        helpertrainingsetup: {
            icon: 'mdi-school',
            title: 'Helper Training',
            tabs: [
                {
                    //icon    : 'mdi-video',
                    label   : 'Videos',
                    href    : '#videos'
                },
                {
                    //icon    : 'mdi-file-pdf-box',
                    label   : 'PDF',
                    href    : '#pdf'
                }
            ]
        },
        color: 'white',
        access_tobe_granted: ['Premium Tier', 'Standard Tier'] 
    }),
    components: {
        VideoIterator,
        PdfTable,
        //FreeTierVideoPdf
        // GuidelineComponent        
    },
    computed: {
        ...mapGetters({
            get_user:   'auth/getuser'
        })
    },
    methods: {
        granting_user_access() {                   
            let userAccess = new UserAccess(this.get_user.info.access_id, this.access_tobe_granted);           
            return userAccess.get_access_grant();
        }
    }, 
    watch: {
        'helpertrainingsetup.tab'() {
            //console.log(this.helpertrainingsetup.tab);
        }
    }
}
</script>