<template>
    <v-container fluid class="mt-5 flex">
      <DataTable
        :setup="selfbuild3dtable"
        :items="get_sb3d"
      />
    </v-container>
</template>

<script>
import DataTable from '../../components/DataTable.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    DataTable
  },
  props: [
  ],
  data: () => ({
    selfbuild3dtable: {
      sb3d: true,
      headers: [
        { text: 'name' },
      ]
    }
  }),
  mounted () {
    this.get_files_sb3d_fn()
  },
  created () {
  },
  computed: {
    ...mapGetters({
      get_sb3d:           'filemanager/get_sb3d'
    })
  },
  methods: {
    async get_files_sb3d_fn(){
      await this.$axios.get('sb3d/get_files_sb3d')
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('filemanager/set_sb3d', data.data)
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>