import Vue from "vue";

export default {
    namespaced: true,
    state: {
      sitediary: [],
      tags: [],
      edititem: {},
      image: '',
      sitediaries: [],
      deleted_sitediaries: []
    },
    mutations: {
      async setsitediary(state, payload){
        state.sitediary = [...payload]
      },
      async settags(state, payload){
        state.tags = [...payload]
      },
      setedititem(state, payload){
        state.edititem = payload
      },
      setimage(state, payload){
        state.image = payload
      },
      set_sitediaries_mutation(state, payload) {
        state.sitediaries = [...payload];
      },
      /** Refactored Codes */
      set_deleted_sitediaries_mutation(state, payload) {
        state.deleted_sitediaries = [...payload];
      },
      add_sitediary_data_mutation(state, payload) {
        state.sitediaries.push(payload);
      },
      restore_deleted_sitedairy_mutation(state, payload) {
        const index = state.deleted_sitediaries.findIndex(item => item.id == payload.id);        
        state.deleted_sitediaries.splice(index, 1);        
        state.sitediaries.push(payload);
      },
      update_sitediary_data_mutation(state, payload) {
        const index = state.sitediaries.findIndex(item => item.id == payload.id);        
        state.sitediaries.splice(index, 1);
        state.deleted_sitediaries.push(payload);
      },
      set_sitediary_data_mutation(state, payload) {
        const sitediary = state.sitediaries.find(item => item.id == payload.id);
        sitediary.log_name = payload.log_name;
        sitediary.image = payload.image;
        sitediary.notes = payload.notes;
        sitediary.created_at = payload.created_at;
        sitediary.site_diary_date = payload.site_diary_date;
        sitediary.tags = payload.tags;
      }
    },
    getters: {
      getsitediary(state){
        return state.sitediary
      },
      gettags(state){
        return state.tags
      },
      getedititem(state){
        return state.edititem
      },
      getimage(state){
        return state.image
      },
      get_sitediaries_getter: state => state.sitediaries,
      /** Refactored Codes */
      get_deleted_sitediaries_getter: state => state.deleted_sitediaries
    },
    actions: {
      callsitediary({commit}, payload){
        commit('getsitediary', payload)
      },
      fetchtags({commit}){
        commit('fetchtags')
      },
      setedititem({commit}, payload){
        commit('setedititem', payload)
      },
      setsitediary({commit}, payload){
        commit('setsitediary', payload)
      },
      settags({commit}, payload){
        commit('settags', payload)
      },
      setimage({commit}, payload){
        commit('setimage', payload)
      },
      /** Inherit Modules */
      async fetch_sitediaries_action(context, payload) {
          await Vue.axios.get(`/sitediary/get_sitediaries/${payload.project_id}/${payload.user_id}`).then( (data) => {
            if (data.status != 200 ) {
              context.dispatch('auth/set_errormessage_dialog_action', {
                title: 'Site Diary: Something went wrong!',
                message: `RESPONSE CODE (${data.status}): ${data.statusText} - Exception: ${data.data.exception}`,
                opendialog: true,
              }, {root: true});
            } else {
              const result = data.data;
              if (result.response == true ) {
                context.commit('set_sitediaries_mutation', result.data);
              } else {
                context.dispatch('auth/set_errormessage_dialog_action', {
                  title: 'Site Diary: Something went wrong!',
                  message: `ERROR: ${result.data}`,
                  opendialog: true,
                }, {root: true});
              }
            }
          });
      },
      async restore_sitediaries_data_action(_, payload) {
        await Vue.axios.patch(`/sitediary/restore_sitediary_data/${payload.user_id}/${payload.project_id}`)
      },
      fetch_deleted_sitediaries_action(context, payload) {
        Vue.axios.get(`sitediary/get_deleted_sitediaries/${payload.user_id}/${payload.project_id}`).then( (data) => {
          if ( data.status != 200 ) {
            context.dispatch('auth/set_errormessage_dialog_action', {
              title: 'Site Diary: Something went wrong!',
              message: `RESPONSE CODE (${data.status}): ${data.statusText} - Exception: ${data.data.exception}`,
              opendialog: true,
            }, {root: true});
          } else {
            const result = data.data;
            if (result.response == true ) {
              context.commit('set_deleted_sitediaries_mutation', result.data);
            } else {
              context.dispatch('auth/set_errormessage_dialog_action', {
                title: 'Site Diary: Something went wrong!',
                message: `ERROR: ${result.data}`,
                opendialog: true,
              }, {root: true});
            }
          }
        }).catch( (err) => {
          context.dispatch('auth/set_errormessage_dialog_action', {
            title: 'Site Diary: Something went wrong!',
            message: `ERROR: ${err}`,
            opendialog: true,
          }, {root: true});
        })
      },
      save_sitediary_action(context, payload) {
        Vue.axios.post(`sitediary/add_sitediary_data/${payload.project_id}`, payload.formData).then( (data) => {
          console.log(data);
          if ( data.status != 200 ) {
            context.dispatch('auth/set_errormessage_dialog_action', {
              title: 'Site Diary: Something went wrong!',
              message: `RESPONSE CODE (${data.status}): ${data.statusText} - Exception: ${data.data.exception}`,
              opendialog: true,
            }, {root: true});
          } else {
            const result = data.data;
            if ( result.response == true ) {
              context.commit('add_sitediary_data_mutation', result.data)
            } else {
              context.dispatch('auth/set_errormessage_dialog_action', {
                title: 'Site Diary: Something went wrong!',
                message: `ERROR: ${result.data}`,
                opendialog: true,
              }, {root: true});
            }
          }
        }).catch( (err) => {
          context.dispatch('auth/set_errormessage_dialog_action', {
            title: 'Site Diary: Something went wrong!',
            message: `ERROR: ${err}`,
            opendialog: true,
          }, {root: true});
        });
      },
      async restore_deleted_sitediary_action(context, payload) {
        //commit('restore_deleted_sitedairy_mutation', payload.item);
        await Vue.axios.patch(`sitediary/restore_deleted_sitediary/${payload.item.id}`).then( (data) => {
          if ( data.status != 200 ) {
            console.log(data.statusText);
          } else {
            const result = data.data;
            if ( result.response == true ) {             
              context.commit('restore_deleted_sitedairy_mutation', payload.item);
            } else {
              context.dispatch('auth/set_errormessage_dialog_action', {
                title: 'Site Diary: Something went wrong!',
                message: `ERROR: ${result.data}`,
                opendialog: true,
              }, {root: true});
            }
          }
        }).catch(err => {
          context.dispatch('auth/set_errormessage_dialog_action', {
            title: 'Site Diary: Something went wrong!',
            message: `ERROR: ${err}`,
            opendialog: true,
          }, {root: true});
        });
      },
      delete_sitediary_action(context, payload) {
        //commit('update_sitediary_data_mutation', payload.item);
        Vue.axios.delete(`sitediary/delete_sitediary_data/${payload.item.id}`).then( (data) => {
          if ( data.status != 200 ) {
            console.log(data.statusText);
          } else {
            const result = data.data;
            if ( result.response == true ) {
              context.commit('update_sitediary_data_mutation', payload.item);
            } else {
              context.dispatch('auth/set_errormessage_dialog_action', {
                title: 'Site Diary: Something went wrong!',
                message: `ERROR: ${result.data}`,
                opendialog: true,
              }, {root: true});
            }
          }
        }).catch(err => {
          context.dispatch('auth/set_errormessage_dialog_action', {
            title: 'Site Diary: Something went wrong!',
            message: `ERROR: ${err}`,
            opendialog: true,
          }, {root: true});
        });
      },
      update_sitediary_action(context, payload) {        
        Vue.axios.post(`sitediary/update_sitediary_data/${payload.item.id}`, payload.formData).then( (data) => {          
          if ( data.status != 200 ) {
            context.dispatch('auth/set_errormessage_dialog_action', {
              title: 'Site Diary: Something went wrong!',
              message: `RESPONSE CODE (${data.status}): ${data.statusText} - Exception: ${data.data.exception}`,
              opendialog: true,
            }, {root: true});
          } else {
            const result = data.data;
            if ( result.response == true ) {
              context.commit('set_sitediary_data_mutation', result.data);
            } else {
              context.dispatch('auth/set_errormessage_dialog_action', {
                title: 'Site Diary: Something went wrong!',
                message: `ERROR: ${result.data}`,
                opendialog: true,
              }, {root: true});
            }
          }
        }).catch(err => {
          context.dispatch('auth/set_errormessage_dialog_action', {
            title: 'Site Diary: Something went wrong!',
            message: `ERROR: ${err}`,
            opendialog: true,
          }, {root: true});
        });
      }
    }
}