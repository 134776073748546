export default {
    namespaced: true,
    state: {
        supplier_materials: []
    },
    mutations: {
        set_supplier_materials(state, payload) {
            state.supplier_materials = [...payload]
        }
    },
    getters: {
        get_supplier_materials: state => state.supplier_materials
    },
    actions: {
        set_supplier_materials({ commit }, payload) {
            commit('set_supplier_materials', payload)
        }
    }
}