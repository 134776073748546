<template>
    <div>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search In Budget Manager..." single-line
            hide-details class="mx-1"></v-text-field>
        <v-data-table :search="search" :headers="headers" :items="get_budgetmanager_data(this.template_id, this.project_id).get_child" class="elevation-1" mobile-breakpoint="0"
            :hide-default-footer="true" disable-pagination>
            <template v-slot:item="props">
                <tr style="cursor: pointer;">
                    <td @click="updatebudgetmanagerrecord(props)"><small>{{ props.index + 1 }}</small></td>
                    <td @click="updatebudgetmanagerrecord(props)"><small>{{ props.item.item_description }}</small></td>
                    <td @click="updatebudgetmanagerrecord(props)"><small v-if="props.item.budget == null">€
                            0.00</small><small v-else>€ {{ props.item.budget | formatNumber }}</small></td>
                    <td @click="updatebudgetmanagerrecord(props)"><small v-if="props.item.actual_price == null">€
                            0.00</small><small v-else>€ {{ props.item.actual_price | formatNumber }}</small></td>
                    <td><small v-if="props.item.variation == null">€ 0.00</small><small v-else>€ {{ props.item.variation |
                        formatNumber }}</small></td>
                    <td><small v-if="props.item.percent == null">0.00 %</small><small v-else>{{ props.item.percent |
                        formatNumber }} %</small></td>
                    <td @click="viewtransactionhistory(props)"><small v-if="props.item.paid_to_date == null">€
                            0.00</small><small v-else>€ {{ props.item.paid_to_date | formatNumber }}</small></td>
                    <td @click="edit_outstanding_value(props)"><small v-if="props.item.outstanding == null">€
                            0.00</small><small v-else>€ {{ props.item.outstanding | formatNumber }}</small></td>
                    <td>
                        <v-btn text small color="error"
                            @click="deleteitembudgetmanager(props.item)">
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
            <template slot="body.append">
                <tr>
                    <th></th>
                    <th>Overall Project Outlay**</th>
                    <th>€ {{ sumField('budget') | formatNumber }}</th>
                    <th>€ {{ sumField('actual_price') | formatNumber }}</th>
                    <th>€ {{ sumField('variation') | formatNumber }}</th>
                    <th>  {{ sumField('variation') / sumField('budget') | formatNumber }} %</th>
                    <th>€ {{ sumField('paid_to_date') | formatNumber }}</th>
                    <th>€ {{ sumField('outstanding') | formatNumber }}</th>
                </tr>
            </template>
        </v-data-table>

        <!-- Dialog Component -->
        <v-row justify="center">
            <v-dialog
                v-model="deleteitem_confirm_dialog"
                persistent
                max-width="450">
                <v-card>
                    <v-card-title>Are you sure you want to delete this item?</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="pt-3">
                        <small>All of the data that belongs to this item wll be deleted, are you sure about this?</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="px-6 white--text"
                            text color="error"
                            @click="close_dialog()">
                            <v-icon class="mr-2">mdi-cancel</v-icon>No
                        </v-btn>
                        <v-btn class="px-6 white--text"
                            color="rgb(11, 78, 243)"
                            @click="event_delete_item_budgetmanager()">
                            <v-icon class="mr-2">mdi-check-circle-outline</v-icon> Yes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <BudgetTransactionTable
            :open_transaction_formdialog="show_transaction_formdialog"
            :budgetmanager_item="item"
            @close_dialog="close_transaction_formdialog()">
        </BudgetTransactionTable>

        <EditBudgetManagerItem
            :show_edit_budgetmanager_item_formdialog="show_edit_budgetmanager_item_formdialog"
            :item="item"
            @close_dialog="evt_close_show_edit_budgetmanager_item_formdialog()">
        </EditBudgetManagerItem>

        <EditOutstanding
          :budgetmanager_data="item"
          :edit_outstanding_dialog="edit_budgetmanager_outstanding_dialog"
          @close_dialog="close_budgetmanager_outstanding_dialog"></EditOutstanding>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BudgetTransactionTable from './budgettransactions/BudgetTransactionTable.vue';
import EditBudgetManagerItem from './budgettransactions/EditBudgetManagerItem.vue';
import EditOutstanding from './EditOutstanding.vue'

export default {
    data: () => ({
        headers: [
            { text: 'ID', value: 'index'},
            { text: 'Item Description', value: 'item_description' },
            { text: 'Budget', value: 'budget' },
            { text: 'Actual Price', value: 'actual_price' },
            { text: 'Variation', value: 'variation' },
            { text: '%', value: 'percent' },
            { text: 'Paid To Date', value: 'paid_to_date' },
            { text: 'Outstanding', value: 'outstanding' },
            { text: 'Action' }
        ],
        search: null,
        deleteitem_confirm_dialog: false,
        item: {},
        show_transaction_formdialog: false,
        show_edit_budgetmanager_item_formdialog: false,
        edit_budgetmanager_outstanding_dialog: false,
        budgetmanager_items: []
    }),
    components: {
        BudgetTransactionTable,
        EditBudgetManagerItem,
        EditOutstanding
    },
    props: {
        template_id: Number,
        project_id: Number
    },
    computed: {
        ...mapGetters({
            get_budgetmanager_data:             'budgetmanager/get_budgetmanager_data_getter',
        })
    },
    methods: {
        ...mapActions({
            deleteitem_budgetmanager:           'budgetmanager/deleteitem_budgetmanager_action'
        }),
        updatebudgetmanagerrecord(props) {
            this.item = props.item;
            this.show_edit_budgetmanager_item_formdialog = true;
        },
        sumField(key) {
            return this.get_budgetmanager_data(this.template_id, this.project_id).get_child.reduce((a, b) => a + (b[key] || 0), 0)
        },
        deleteitembudgetmanager(item) {
            this.item = item;
            this.deleteitem_confirm_dialog = true;
        },
        event_delete_item_budgetmanager() {
            this.deleteitem_budgetmanager(this.item);
            this.deleteitem_confirm_dialog = false;
        },
        viewtransactionhistory(props) {            
            this.item = props.item;
            this.show_transaction_formdialog = true;
        },
        close_dialog() {
            this.item = {};
            this.deleteitem_confirm_dialog = false;
        },
        close_transaction_formdialog() {
            this.budgetmanager_items = this.get_budgetmanager_data(this.template_id, this.project_id).get_child            
            this.show_transaction_formdialog = false;
        },
        evt_close_show_edit_budgetmanager_item_formdialog() {
            this.budgetmanager_items = this.get_budgetmanager_data(this.template_id, this.project_id).get_child
            this.show_edit_budgetmanager_item_formdialog = false;
        },
        edit_outstanding_value(data) {
            this.item = data;
            this.edit_budgetmanager_outstanding_dialog = true;
        },
        close_budgetmanager_outstanding_dialog() {
            this.budgetmanager_items = this.get_budgetmanager_data(this.template_id, this.project_id).get_child
            this.edit_budgetmanager_outstanding_dialog = false;
            this.item = {};      
        }
    },
    created() {
        this.budgetmanager_items = this.get_budgetmanager_data(this.template_id, this.project_id).get_child
    },
    watch: {
        template_id() {
            this.budgetmanager_items = this.get_budgetmanager_data(this.template_id, this.project_id).get_child
        }
    }
}
</script>