<template>
    <v-row justify="center">
        <v-dialog
            v-model="add_sitediary_dialog"
            persistent
            scrollable
            max-width="690">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-notebook</v-icon><small>add site diary</small>
                    <v-spacer></v-spacer>
                    <small>
                        <i>{{ new Date().toLocaleDateString() }}</i>
                    </small>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation>
                        <div class="mt-5">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="startdate"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="startdate"
                                        label="site date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        dense
                                        outlined
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="startdate"
                                    no-title
                                    scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(startdate)">
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </div>
                        <div class="mt-5">
                            <v-text-field
                                label="Log Name"
                                outlined
                                prepend-icon="mdi-tooltip-text-outline"
                                hint="Log Name"
                                dense
                                :rules="required_rules.log_name"
                                required
                                v-model="log_name"
                                type="text"
                            ></v-text-field>
                        </div>
                        <div class="mt-5">
                            <v-combobox
                                v-model="tags"
                                :items="gettags"
                                :search-input.sync="search"
                                hide-selected
                                label="Add Some Tags"
                                item-text="tags"
                                multiple
                                persistent-hint
                                small-chips
                                outlined
                                dense                              
                                prepend-icon="mdi-tooltip-text-outline">
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-combobox>
                        </div>
                        <div class="mt-5">
                            <v-file-input
                                v-model="image"
                                small-chips
                                dense
                                outlined
                                label="Add Image"
                                required
                                :rules="required_rules.image"></v-file-input>
                        </div>
                        <div class="mt-5">
                            <v-textarea
                                outlined
                                dense
                                v-model="notes"
                                prepend-icon="mdi-tooltip-text-outline"
                                label="Notes"
                                required
                                :rules="required_rules.notes">
                            </v-textarea>
                        </div>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="closedialog()">
                    Close
                </v-btn>
                <v-btn
                    color="#093cba"
                    dark
                    @click="savesitediary()"
                    class="pl-16 pr-16">
                    Save
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        valid: true,
        required_rules: {
            log_name: [v => !!v || 'The log name is a required field!'],            
            image: [v =>!!v || 'The Image is a required field!'],
            notes: [v => !!v || 'The Notes are a required field!']
        },
        add_sitediary_dialog: false,        
        image: null,
        search: null,
        menu: null,
        startdate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        log_name: "",
        tags: [],
        notes: ""
    }),
    props: {
        show_add_sitediary_dialog: Boolean,
        project_id: Number,
    },
    computed: {
        ...mapGetters({
            gettags:              'sitediary/gettags'
        })
    },
    methods: {
        ...mapActions({
            save_sitediary:         'sitediary/save_sitediary_action'
        }),
        closedialog() { 
            this.$refs.form.resetValidation();           
            this.image = null;
            this.search = null;
            this.menu = null;
            this.startdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.log_name = "";
            this.tags = [];
            this.notes = "";
            this.$emit("closedialog");
        },
        async savesitediary() {
            this.valid = this.$refs.form.validate();
            if ( this.valid == true ) {
                const formData = new FormData()
                formData.append('date', this.startdate)
                formData.append('logname', this.log_name)
                formData.append('tags', this.tags)
                formData.append('file', this.image)
                formData.append('notes', this.notes)
                await this.save_sitediary({
                    project_id: this.project_id,
                    formData: formData
                });
                this.closedialog();
            }
        }
    },
    watch: {
        show_add_sitediary_dialog() {           
            if ( this.show_add_sitediary_dialog == true ) {
                this.add_sitediary_dialog = true;
            } else {
                this.add_sitediary_dialog = false;
            }
        }
    }
}
</script>