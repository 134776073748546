<template>
    <v-container fluid class="mt-5 flex">
      <v-card>
        <v-card-title style="fontSize: 16px;">
          <small>Supplier Misinformation Reports</small>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="table_header"
            :items="get_suppliers"
            item-key="id"
          >
            <template v-slot:item="{ item, expand, isExpanded }" >
              <tr
                style="cursor:pointer;" 
                @click="expand(!isExpanded)"
              >
                <td>
                  {{ item.id }}
                </td>
                <td>
                  {{ item.get_supplier.company_name }}
                </td>
                <td>
                  {{ item.description }}
                </td>
                <td>
                  {{item.get_supplier.supplier_type.service_type}}
                </td>
                <td>
                  <label
                    v-if="item.get_supplier.user_id == null"
                  >Not Registered</label>
                  <label
                    v-else
                  >
                    Registered
                  </label>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-card>
                  <v-card-title style="fontSize: 16px;">
                    <small>{{item.get_supplier.company_name}}</small>
                    <v-spacer></v-spacer>
                    <v-btn
                      dark
                      color="primary"
                      outlined
                      small
                      class="mr-2"
                      @click="mark_as_done(item.id)"
                    >
                      <v-icon 
                        small
                        class="mr-2"
                      >mdi-check-outline</v-icon>Mark as done
                    </v-btn>
                    <v-btn
                      dark
                      color="primary"
                      outlined
                      small
                      @click="edit_supplier_info(item.get_supplier)"
                    >
                      <v-icon 
                        small
                        class="mr-2"
                      >mdi-pen</v-icon>Edit
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <label
                      style="display: block;"
                    >
                      Supplier Type: {{item.get_supplier.supplier_type.service_type}}
                    </label>
                    <label
                      style="display: block;"
                    >
                      City / County: {{item.get_supplier.phy_country}}
                    </label>
                  </v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-row justify="center">
        <v-dialog
            max-width="650"
            v-model="show_edit_dialog"
            persistent
        >
            <v-card>
                <v-card-title style="fontSize: 18px;">
                  <small>Edit Supplier {{get_to_edit_supplier.company_name}}</small>
                </v-card-title>

                <v-card-text>
                    <v-col
                        cols="12"
                    >
                        <v-autocomplete
                          :items="get_service_types"
                          label="Supplier Type"
                          item-value="id"
                          item-text="service_type"
                          v-model="e.service_type"
                          outlined
                          dense
                          return-object
                        >
                        </v-autocomplete>
                        <v-autocomplete
                          :items="get_counties"
                          label="County | City"
                          item-value="phy_country"
                          item-text="phy_country"
                          v-model="e.county"
                          outlined
                          dense
                        >
                        </v-autocomplete>
                        <label>Email in record: {{get_to_edit_supplier.email}}</label>
                        <v-text-field
                          label="Email"
                          dense
                          outlined
                          v-model="e.email"
                          type="email"
                        >
                        </v-text-field>
                        <label>Website in record: {{get_to_edit_supplier.website}}</label>
                        <v-text-field
                          label="Website"
                          dense
                          outlined
                          v-model="e.website"
                          type="text"
                        >
                        </v-text-field>
                        <label>Phone number in record: {{get_to_edit_supplier.phone_number}}</label>
                        <v-text-field
                          label="Phone Number"
                          dense
                          outlined
                          v-model="e.phone_number"
                          type="text"
                        >
                        </v-text-field>
                        <!-- <v-text-field
                          v-model="e.county"
                          outlined
                          dense
                          label="County | City"
                        >
                        </v-text-field> -->
                    </v-col>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="clear"
                >
                    <v-icon class="mr-3">mdi-window-close</v-icon>Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    dark
                    @click="save_edited_supplier"
                    class="pl-12 pr-12"
                >
                    <v-icon 
                        class="mr-3"
                    >
                        mdi-content-save-outline
                    </v-icon>Save
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
      </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    table_header: [
      { text: 'ID' },
      { text: 'Company' },
      { text: 'Report Description' },
      { text: 'Supplier Type' },
      { text: 'Is registered' }
    ],
    search: null,
    show_edit_dialog: false,
    e: {
      service_type: null,
      county: null,
      email: null,
      website: null,
      phone_number: null
    }
  }),
  mounted () {
    this.fetchinformation()
  },
  created () {
  },
  computed: {
    ...mapGetters({
      get_suppliers:              'admin_supplier_reports/get_suppliers',
      get_to_edit_supplier:       'admin_supplier_reports/get_to_edit_supplier',
      get_counties:               'admin_supplier_reports/get_counties'
    }),
    get_service_types(){
      return this.$store.getters['admin_service_types/get_service_types'](this.search)
    }
  },
  methods: {
    async fetchinformation(){
      await this.$axios.get('admin/supplier_reports/fetch_misinformation_supplier')
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('admin_supplier_reports/set_suppliers', data.data)
          this.$store.dispatch('admin_supplier_reports/set_counties', data.country)
          this.$store.dispatch('admin_service_types/set_fetch_service_types')
        }
      })
    },
    edit_supplier_info(data){
      this.show_edit_dialog = true
      this.$store.dispatch('admin_supplier_reports/set_to_edit_supplier', data)
    },
    clear(){
      this.show_edit_dialog = false
      this.$store.dispatch('admin_supplier_reports/set_to_edit_supplier', {})
    },
    async save_edited_supplier(){
      let tp = {
        supplier_id:  this.get_to_edit_supplier.id,
        service_type: this.e.service_type.id,
        county:       this.e.county,
        email:        this.e.email,
        website:      this.e.website,
        phone_number: this.e.phone_number
      }
      await this.$axios.patch('admin/supplier_reports/edit/supplier_information', tp)
      .then(({data}) => {
        if(data.response){
          this.e = {}
          this.clear()
          this.fetchinformation()
        }
      })
    },
    async mark_as_done(data){
      await this.$axios.delete(`admin/supplier_reports/mark_as_done/${data}`)
      .then(({data}) => {
        if(data.response){
          this.fetchinformation()
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>