import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        users: [],
        folders: [],
        selected_user: {},
        files: [],
        selected_folder: {},
        to_edit_user: {}
    },
    mutations: {
        set_empty_set(state){
            state.files = []
            state.selected_folder = {}
        },
        async refetch_files(state){
            if(Object.keys(state.selected_folder).length !== 0){
                await Vue.axios.get(`/sb3d/fdsb3dc/${state.selected_user.id}`, state.selected_folder)
                .then(({data}) => {
                    if(data.response){
                        state.files = [...data.data]
                    }
                })
            }
        },
        set_users(state, payload){
            if(Object.keys(state.selected_user).length !== 0){
                const user_edited = payload.find(q => {
                    return q.id === state.selected_user.id
                })
                state.selected_user = user_edited
            }
            state.users = [...payload]
        },
        set_folders(state, payload){
            state.folders = [...payload]
        },
        set_selected_user(state, payload){
            state.selected_user = payload
        },
        set_files(state, payload){
            state.files = [...payload]
        },
        set_selected_folder(state, payload){
            state.selected_folder = payload
        },
        set_to_edit_user(state, payload){
            state.to_edit_user = payload
        }
    },
    getters: {
        get_users : state => value => {
            if(value == null || value == ''){
                return state.users
            }
            return state.users.filter(q => {
                return q.email.toLowerCase().includes(value)
                || q.name.toLowerCase().includes(value)
                || q.phone.toLowerCase().includes(value)
            })
        },
        get_folders : state => state.folders,
        get_selected_user : state => state.selected_user,
        get_files : state => state.files,
        get_selected_folder : state => state.selected_folder,
        get_to_edit_user : state => state.to_edit_user
    },
    actions: {
        set_users({commit}, payload){
            commit('set_users', payload)
        },
        set_folders({commit}, payload){
            commit('set_folders', payload)
        },
        set_selected_user({commit}, payload){
            commit('set_selected_user', payload)
        },
        set_files({commit}, payload){
            commit('set_files', payload)
        },
        set_selected_folder({commit}, payload){
            commit('set_selected_folder', payload)
        },
        set_to_edit_user({commit}, payload){
            commit('set_to_edit_user', payload)
        }
    }
}