<template>
    <v-dialog
        v-model="dialog"
        max-width="290">
        <v-card>
            <v-card-title class="text-h5">
                <v-icon>
                    mdi-alert-circle
                </v-icon>
                {{ title }}
            </v-card-title>
            <v-card-text>
                {{ content }}
                Send this error report to help fix this problem.
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>            
            <v-btn
                color="green darken-1"
                text
                @click="dialog = false">
                Ok
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        title: null,
        content: null
    }),
    props: {
        show_error_dialog: Boolean,
        dialog_title: String,
        dialog_content: String,
        detailed_report: Object,
    },
    computed: {
        
    }, methods: {

    }, 
    watch: {
        show_error_dialog() {
            if (this.show_error_dialog == true) {
                this.dialog = true;
                this.title = this.dialog_title;
                this.content = this.dialog_content;
            }
        }
    }
}
</script>