import Vue from 'vue'

export default{
    namespaced: true,
    state: {
        category_list: [],
        item_list: [],
        cart: [],
        category_cart: [],
        checked_out_items: [],
        sub_categories: [],
        product_page: {},
        bcrumbs: [],
        edit_item: {}
    },
    mutations: {
        category_mutation(state, payload){
            state.category_list = [...payload]
            state.category_list.unshift({
                id: 0,
                name: "All",
                icon: "mdi-toy-brick-outline"
            });
        },
        mutate_items(state, payload){
            state.item_list = [...payload]
        },
        add_to_cart(state, payload){
            state.cart.push(payload)
        },
        add_cart_from_api(state, payload){
            state.cart = [...payload]
        },
        add_category_cart(state, payload){
            state.category_cart = [...payload]
        },
        set_remove_item(state, payload){
            const index = state.cart.indexOf(payload)
            state.cart.splice(index, 1)
        },
        clear_cart(state){
            state.cart = []
        },
        add_checked_out_items(state, payload){
            state.checked_out_items = [...payload]
        },
        add_sub_categories(state, payload){
            state.sub_categories = [...payload]
        },
        setProductPage(state, payload){
            state.bcrumbs = []
            const home = {
                text: "Home",
                disabled: false,
                href: 'shop'
            }
            state.bcrumbs.push(home)
            const category = {
                text: payload.get_category.name,
                disabled: true,
                href: 'shop'
            }
            state.bcrumbs.push(category)
            const sub_category = {
                text: payload.get_sub_categories.name,
                disabled: true,
                href: 'shop'
            }
            state.bcrumbs.push(sub_category)
            state.product_page = payload
        },
        set_edit_item(state, payload){
            state.edit_item = payload
        }
    },
    getters: {
        get_category_list : (state) => state.category_list,
        get_items:  (state) => (value) => {
            if(value == null || value == ""){
                return state.item_list
            }
            return state.item_list.filter(q => {
                return q.name.toLowerCase().includes(value)
            })
        },
        gNoParamsItem : (state) => state.item_list,
        get_category_cart : (state) => (value) => {
            if(value == null || value == ""){
                return state.category_cart
            }
            return state.category_cart.filter(q => {
                return q.name.toLowerCase().includes(value)
            })
        },
        get_cart: (state) => state.cart,
        get_checked_out_items : (state) => state.checked_out_items,
        get_sub_categories : (state) => state.sub_categories,
        get_product_page : (state) => state.product_page,
        get_bcrumbs : (state) => state.bcrumbs,
        get_edit_item : (state) => state.edit_item
    },
    actions: {
        async fetch_categories({commit}){
            await Vue.axios.get('/shop/get_all_categories')
            .then(({data}) => {
                commit('category_mutation', data.data)
            })
        },
        async fetch_items({commit}){
            await Vue.axios.get('/shop/get_all_items')
            .then(({data}) => {
                commit('mutate_items', data.data)
            })
        },
        async add_to_cart({commit, state}, payload){
            let status = 0
            if(state.cart.length > 0){
                state.cart.filter(q => {
                    if(q.name === payload.name){
                        status = 1
                        commit("auth/setMessage", 
                        {show: true, message: "Item already added on your cart!"}, 
                        {root: 1})
                    }
                })
            }
            if(status == 0){
                let tp = {
                    users_id:   payload.users_id,
                    item_id:    payload.id,
                    qty:        payload.qty
                }
                await Vue.axios.post("/shop/add_item_to_cart", tp)
                commit('add_to_cart', payload)
                commit("auth/setMessage", 
                {show: true, message: "Added to cart!"}, 
                {root: 1})
            }
        },
        async filter_by_category({commit}, payload){
            if(payload.id == 0){
                await Vue.axios.get('shop/get_all_items')
                .then(({data}) => {
                    commit('add_category_cart', data.data)
                    commit('add_sub_categories', [])
                })
                return
            }
            await Vue.axios.get(`/shop/get_item_by_category/${payload.id}`)
            .then(({data}) => {
                commit('add_category_cart', data.data)
            })

            await Vue.axios.get(`/shop/get_sub_categories/${payload.id}`)
            .then(({data}) => {
                commit('add_sub_categories', data.data)
            })
        },
        remove_item({commit}, payload){
            Vue.axios.delete(`shop/remove_item_to_cart/${payload.item_id}`)
            commit('set_remove_item', payload)
        },
        async fetch_cart_api({commit}, payload){
            await Vue.axios.get(`shop/get_all_items_from_cart/${payload}`)
            .then(({data}) => {
                commit('add_cart_from_api', data.data)
            })
        },
        async fetch_checked_out_items({commit}, payload){
            await Vue.axios.get(`shop/get_checkout_items/${payload}`)
            .then(({data}) => {
                commit('add_checked_out_items', data.data)
            })
        },
        async update_qty(_, payload){
            await Vue.axios.patch(`shop/edit_item_qty/${payload.id}`, payload)
        },
        async set_fetch_sub_category({commit}, payload){
            await Vue.axios.get(`/shop/get_item_via_sub_cat/${payload.id}`)
            .then(({data}) => {
                commit('add_category_cart', data.data)
            })
        },
        setProductPage({commit}, payload){
            commit('setProductPage', payload)
        },
        set_edit_item({commit}, payload){
            commit('set_edit_item', payload)
        }
    }
}