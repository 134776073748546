<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="4"
            >
                <v-card>
                    <v-card-title style="fontSize: 14px;">
                        <v-icon class="mr-3">mdi-chat</v-icon>Service / Supplier Request
                    </v-card-title>
                    <v-card-text>
                        <v-list dense>
                            <v-list-item-group
                                color="primary"
                            >
                                <v-list-item
                                    v-for="(item, i) in get_service_supplier"
                                    :key="i"
                                >
                                    <v-list-item-icon>
                                        <v-icon>mdi-comment-account</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content
                                        @click="content_clicked(item)"
                                    >
                                        <v-list-item-title>
                                            <label>
                                                {{item.supplier_info.company_name}} | {{item.get_information_of_request.build_stage}} | {{item.get_information_of_request.created_at}}
                                            </label>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                cols="8"
                v-if="get_service_supplier_conversation.length > 0"
            >
                <v-row
                    no-gutters
                >
                    <v-container
                        v-for="(item_chat, item_chat_index) in get_service_supplier_conversation"
                        :key="item_chat_index"
                    >
                    <v-card
                        v-if="item_chat.from_client === 1"
                        color="blue darken-4"
                        style="float: right;"
                        class="rounded-tl-0"
                    >
                        <v-card-text>
                            <small class="white--text">you</small>
                            <h4 class="white--text" style="display:block;">{{item_chat.message}}</h4>
                            <small class="white--text" style="display:block;">{{item_chat.created_at}}</small>
                        </v-card-text>
                    </v-card>
                    <v-card
                        v-if="item_chat.from_supplier === 1"
                        color="blue darken-2"
                        style="float: left;"
                        class="rounded-tr-0"
                    >
                        <v-card-text>
                            <small class="white--text">supplier</small>
                            <h4 class="white--text">{{item_chat.message}}</h4>
                            <small class="white--text">{{item_chat.created_at}}</small>
                        </v-card-text>
                    </v-card>
                    </v-container>
                    <v-container>
                    <v-row
                        class="pa-2"
                    >
                        <v-text-field
                            rounded
                            v-model="message"
                            placeholder="Reply Here..."
                            outlined
                            append-icon="send"
                            @click:append="send_upload_plan_message"
                        ></v-text-field>
                    </v-row>
                    </v-container>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapGetters } from 'vuex'
  export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        message: null
    }),
    async mounted () {
        await this.$store.dispatch('inbox/set_service_supplier')
    },
    created () {
    },
    computed: {
        ...mapGetters({
            get_service_supplier:                   'inbox/get_service_supplier',
            get_service_supplier_conversation:      'inbox/get_service_supplier_conversation'
        })
    },
    methods: {
        showSnackBar(message){
            this.$store.commit("auth/setMessage", 
            {show: true, message: message}, 
            {root: 1})
        },
        async content_clicked(data){
            await this.$axios.get(`/from_form/get_convo/${data.form_request_id}`)
            .then(({data}) => {
                this.$store.dispatch('inbox/set_service_supplier_conversation', data.data)
            })
        },
        async send_upload_plan_message(){
            this.showSnackBar('Please wait...')
            let tp = {
                supplier_id:        this.get_service_supplier_conversation[0].supplier_id,
                form_request_id:    this.get_service_supplier_conversation[0].form_request_id,
                message:            this.message,
                from_client:        1
            }
            await this.$axios.post('/from_form/send_message', tp)
            .then(({data}) => {
                if(data.response){
                    this.showSnackBar(data.message)
                    this.message = null
                    this.content_clicked(tp)
                    return
                }
                this.showSnackBar(data.message)
                return
            })
        }
    },
    watch: {
    }
  }
</script>
  
<style scoped lang="scss">
</style>