import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        templates: [],
        parent_data: {},
        to_delete_data: {},
    },
    mutations: {
        set_templates(state, payload){
            state.templates = [...payload]
        },
        set_parent_data(state, payload){
            state.parent_data = payload
        },
        set_to_delete_data(state, payload){
            state.to_delete_data = payload
        }
    },
    getters: {
        get_templates : state => state.templates,
        get_parent_data : state => state.parent_data,
        get_to_delete_data : state => state.to_delete_data
    },
    actions: {
        set_templates({commit}, payload){
            commit('set_templates', payload)
        },
        async fetch_templates({commit}){
            await Vue.axios.get('admin/project_planner/get_project_planner_temps')
            .then(({data}) => {
                // this.$store.dispatch('admin_project_planner/set_templates', data.data)
                commit('set_templates', data.data)
            })
        },
        set_parent_data({commit}, payload){
            commit('set_parent_data', payload)
        },
        set_to_delete_data({commit}, payload){
            commit('set_to_delete_data', payload)
        }
    }
}