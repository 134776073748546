import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        upload_progress: [],
        error_message: {},
        trashed_files: {},
        uploaded_files: {}
    },
    mutations: {
        set_uploaded_files_mutations(state, payload) {
            const objectKeys = Object.keys(payload);
            objectKeys.map( key => {
                Vue.set(state.uploaded_files, key, [...payload[key]]);
            });            
        },       
        add_file_to_specific_container_mutation(state, payload) {
            state.uploaded_files[payload.file_type].push(payload);
        },
        remove_file_by_types(state, payload) {           
            const targetObject = state.uploaded_files[payload.file_type];
            state.uploaded_files[payload.file_type].splice(targetObject.findIndex(file => file.id === payload.id), 1);
            state.trashed_files[payload.file_type].push(payload);
        },
        update_file_data_by_types(state, payload) {
            const targetObject = state.uploaded_files[payload.file_type];
            state.uploaded_files[payload.file_type].splice(targetObject.findIndex(file => file.id === payload.id), 1, payload);           
        },
        set_upload_progress(state, payload) {
            state.upload_progress = payload;
        },
        set_error_message(state, payload) {
            state.error_message = payload;
        },
        set_uploaded_trashed_files_mutation(state, payload) {
            let payloadKeys = Object.keys(payload);
            payloadKeys.map(key => {
                Vue.set(state.trashed_files, key, [...payload[key]]);
            });           
        },
        remove_uploaded_files_from_trash(state, payload) { 
            const targetObject = state.trashed_files[payload.file_type];
            state.trashed_files[payload.file_type].splice(targetObject.findIndex(file => file.id === payload.id), 1);
            state.uploaded_files[payload.file_type].push(payload);
        }
    },
    getters: {       
        get_uploaded_files: state => state.uploaded_files,
        get_upload_progress_getters: state => state.upload_progress,
        get_error_message_getters: state => state.error_message,
        get_uploaded_trashed_files: state => state.trashed_files
    },
    /** All methods for saving, update, delete, and retrieval should be set in the actions */
    actions: {
        async fetch_files_by_types({commit}, payload) {
            await Vue.axios.get(`admin/helper_training_files/get_uploaded_files/${payload}`).then( ({data}) => {
                if ( data.response == true ) {
                    commit('set_uploaded_files_mutations', data.data);
                }
            });          
        },
        async upload_file({commit}, payload) {
            let fileFormData = new FormData();
            fileFormData.append('name', payload.name);
            fileFormData.append('file', payload.file);
            fileFormData.append('description', payload.description);
            fileFormData.append('thumbnail', payload.thumbnail);
            
            await Vue.axios.post(`admin/helper_training_files/upload_file`, fileFormData, {
                onUploadProgress: (progressEvent) => {
                    commit('set_upload_progress',progressEvent);
                }
            }).then( ({data}) => {
                commit('set_upload_progress', []);
                commit('add_file_to_specific_container_mutation', data.data[0]);
            });           
        },
        async update_uploaded_file({commit}, payload) {           
            let fileFormData = new FormData();           
            fileFormData.append('file', payload.file);           
            fileFormData.append('name', payload.name);
            fileFormData.append('description', payload.description);
            fileFormData.append('thumbnail', payload.thumbnail);

            await Vue.axios.post(`admin/helper_training_files/update_uploaded_file/${payload.id}`, fileFormData, {
                onUploadProgress: (progressEvent) => {
                    commit('set_upload_progress', progressEvent);
                }
            }).then( ({data}) => {
                commit('set_upload_progress', []);
                commit('update_file_data_by_types', data.data[0]);  
            }).catch( (error) => {
                console.log(error);
            });           
        },
        async delete_uploaded_file({commit}, payload) {           
            await Vue.axios.delete(`admin/helper_training_files/delete_uploaded_file/${payload.id}`).then( ({data}) => {               
                commit('remove_file_by_types', data.data[0]);
            });
        },
        async get_trashed_uploaded_files_by_types({commit}, payload) {           
            await Vue.axios.get(`admin/helper_training_files/get_uploaded_trashed_files/${payload}`).then( ({data}) => {
                commit('set_uploaded_trashed_files_mutation', data.data);
            });
        },
        async restore_trashed_uploaded_files({commit}, payload) {
            await Vue.axios.patch(`admin/helper_training_files/restore_trashed_files/${payload.id}`)
            .then( ({data}) => {
                if ( data.response == true) {
                    console.log(data.data[0]);
                    commit('remove_uploaded_files_from_trash', data.data[0]);
                } else {
                    console.log(data.error);
                }
            });
        }
    }
}