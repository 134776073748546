<template>
    <v-container fluid class="mt-5">
      <v-card>
        <v-card-title
            style="fontSize: 16px;"
        >
            <small>
                <v-icon>mdi-lightbulb-outline</v-icon>
                Client's Plan
            </small>
        </v-card-title>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="get_client_plans"
            >
                <template v-slot:item="{ item, expand, isExpanded }" >
                    <tr
                        style="cursor:pointer;" 
                        @click="expand(!isExpanded)"
                    >
                        <td>
                            {{ item.get_user.name }}
                        </td>
                        <td>
                            {{ item.address }} {{ item.town }} {{ item.county }} {{ item.zipcode }}
                        </td>
                        <td>
                            {{ item.type_of_build }}
                        </td>
                    </tr>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-row>
                            <v-col
                                cols="6"
                                class="mt-5 mb-5"
                            >
                                <v-icon>mdi-lightbulb-outline</v-icon>
                                plan
                                <div>
                                    <div v-if="item.extension_file == 'png' || item.extension_file == 'jpg'">
                                        <img :src="`${img_url}${item.file_path}`" style="max-width:250px; max-height:250px;"/>
                                    </div>
                                    <div v-if="item.extension_file == 'pdf'">
                                        <img :src="`https://media.istockphoto.com/vectors/icon-major-file-format-vector-icon-illustration-vector-id1298834280?k=20&m=1298834280&s=612x612&w=0&h=SxEbyHKi18H1XpPpKKzoppWgMM3x0tc3veN5e6wl7Y8=`" style="max-width:250px; max-height:250px;"/>
                                    </div>
                                    <div v-if="item.extension_file == 'docx'">
                                        <img :src="`https://seeklogo.com/images/M/microsoft-word-logo-E648C182A5-seeklogo.com.png`" style="max-width:250px; max-height:250px;"/>
                                    </div>
                                    <div v-if="item.extension_file == 'xlsx'">
                                        <img :src="`https://seeklogo.com/images/E/excel-logo-974BFF9CB9-seeklogo.com.png`" style="max-width:250px; max-height:250px;"/>
                                    </div>
                                </div>
                            </v-col>
                            <v-col
                                cols="6"
                                class="mt-5 mb-5"
                            >
                                <label><v-icon>mdi-map-marker</v-icon> {{ item.address }} {{ item.town }} {{ item.county }} {{ item.zipcode }}</label>
                                <v-row
                                    class="mt-3 pl-3"
                                >
                                    <v-btn
                                        outlined
                                        small
                                        class="mr-3"
                                        @click="download_attachment(item.file_path)"
                                    >
                                        <v-icon class="mr-3">mdi-download-outline</v-icon>
                                        view attachment
                                    </v-btn>
                                    <v-btn
                                        outlined
                                        small
                                        @click="chatclient(item)"
                                    >
                                        <v-icon class="mr-3">mdi-chat-outline</v-icon>
                                        chat
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </td>
                </template>
            </v-data-table>
        </v-card-text>
      </v-card>
    <v-row justify="center">
        <v-dialog
            max-width="620"
            v-model="open_chat"
            persistent
        >
            <v-card>
                <v-card-title style="fontSize: 16px;">
                    <small>to chat: {{get_to_chat.name}}</small>
                </v-card-title>

                <v-card-text>
                    <v-textarea
                        rows="5"
                        outlined
                        placeholder="message here..."
                        v-model="chat_message"
                    ></v-textarea>
                </v-card-text>
                    
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    @click="cancel_chat"
                    outlined
                    dense
                >
                    <v-icon
                        small
                        class="mr-2"
                    >mdi-close</v-icon>
                    cancel
                </v-btn>
                <v-btn
                    class="pl-15 pr-15"
                    dense
                    color="primary"
                    @click="sendmessage"
                >
                    <v-icon
                        small
                        class="mr-3"
                    >mdi-send</v-icon>
                    send
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog
            max-width="620"
            v-model="view_media_dialog"
            persistent
        >
            <v-card>
                <v-card-title style="fontSize: 16px;">
                    <small></small>
                    <v-spacer></v-spacer>
                    <v-icon
                        class="mr-3"
                        @click="view_media_dialog = !view_media_dialog"
                    >mdi-cancel</v-icon>
                </v-card-title>

                <v-card-text>
                    <div
                        v-for="(media, mediaindex) in media_file"
                        :key="mediaindex"
                        class="text-center justify-center"
                    >
                        <div
                            v-if="media.split('.').pop() === 'jpg' || media.split('.').pop() === 'jpeg' || media.split('.').pop() === 'png'"
                        >
                            <div>
                                <img :src="`${img_url}${media}`" style="max-width:250px; max-height:250px; width: fit; height: 250px;"/>
                            </div>
                            <v-btn
                                dense
                                outlined
                                style="display: block;"
                                class="mb-3"
                                @click="view_media_external(media)"
                                block
                            >
                                <v-icon class="mr-3">mdi-eye</v-icon>View
                            </v-btn>
                        </div>
                        <div
                            v-if="media.split('.').pop() === 'pdf'"
                        >
                            <img :src="`https://media.istockphoto.com/vectors/icon-major-file-format-vector-icon-illustration-vector-id1298834280?k=20&m=1298834280&s=612x612&w=0&h=SxEbyHKi18H1XpPpKKzoppWgMM3x0tc3veN5e6wl7Y8=`" style="max-width:250px; max-height:250px; width: fit; height: 125px;"/>
                            <v-btn
                                dense
                                outlined
                                style="display: block;"
                                class="mb-3"
                                @click="view_media_external(media)"
                                block
                            >
                                <v-icon class="mr-3">mdi-eye</v-icon>View
                            </v-btn>
                        </div>
                        <div v-if="media.split('.').pop() === 'docx' || media.split('.').pop() === 'doc'">
                            <img :src="`https://seeklogo.com/images/M/microsoft-word-logo-E648C182A5-seeklogo.com.png`" style="max-width:125px; max-height:125px; width: fit; height: 250px;"/>
                            <v-btn
                                dense
                                outlined
                                style="display: block;"
                                class="mb-3"
                                @click="view_media_external(media)"
                                block
                            >
                                <v-icon class="mr-3">mdi-eye</v-icon>View
                            </v-btn>
                        </div>
                        <div v-if="media.split('.').pop() === 'xlsx'">
                            <img :src="`https://seeklogo.com/images/E/excel-logo-974BFF9CB9-seeklogo.com.png`" style="max-width:125px; max-height:125px; width: fit; height: 250px;"/>
                            <v-btn
                                dense
                                outlined
                                style="display: block;"
                                class="mb-3"
                                @click="view_media_external(media)"
                                block
                            >
                                <v-icon class="mr-3">mdi-eye</v-icon>View
                            </v-btn>
                        </div>
                    </div>
                </v-card-text>
                    
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    @click="view_media_dialog = !view_media_dialog"
                    outlined
                    dense
                >
                    <v-icon
                        small
                        class="mr-2"
                    >mdi-close</v-icon>
                    cancel
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    headers:[
        { text: 'client name' },
        { text: 'address'},
        { text: 'type of build' }
    ],
    img_url: null,
    open_chat: false,
    chat_message: null,
    view_media_dialog: false,
    media_file: []
  }),
  async mounted () {
    await this.$axios.get('supplier/client_plans/client_plans')
    .then(({data}) => {
        this.$store.dispatch('client_plan/set_client_plans', data.data)
    }),
    this.img_url = process.env.VUE_APP_URL
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_client_plans:                   'client_plan/get_client_plans',
        get_to_chat:                        'client_plan/get_to_chat'
    })
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    download_attachment(data){
    //   window.open(this.img_url + data)
        this.media_file = []
        this.media_file = data
        this.view_media_dialog = true
    },
    view_media_external(data){
        window.open(this.img_url + data)
    },
    chatclient(data){
        this.$store.dispatch('client_plan/set_to_chat', data)
        this.open_chat = true
    },
    cancel_chat(){
        this.open_chat = false
        this.chat_message = null
    },
    async sendmessage(){
        this.showSnackBar('Please wait...')
        const tp = {
            plan_id:        this.get_to_chat.id,
            client_id:      this.get_to_chat.get_user.id,
            message:        this.chat_message
        }
        await this.$axios.post('supplier/client_plans/send_chat_client_plans', tp)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.cancel_chat()
            }else{
                this.showSnackBar(data.message)
            }
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>