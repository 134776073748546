
export default {
    namespaced: true,
    state: {
        supplier_search_results: [],
        supplier_materials: []
    },
    mutations: {
        setSupplierSearchResultsMutation(state, payload) {
            state.supplier_search_results = [...payload]
        },
        setSupplierMaterialsByUserIdMutation(state, payload) {
            state.supplier_materials = [...payload]
        }
    },
    getters: {
        supplierSearchResultsGetters: state => state.supplier_search_results,
        supplierMaterialsByUsersIdGetters: state => state.supplier_materials
    },
    actions: {
        setSupplierSearchResultsAction({commit}, payload) {
           commit('setSupplierSearchResultsMutation', payload)
        },
        setSupplierMaterialsByUserIdAction({commit}, payload) {
            commit('setSupplierMaterialsByUserIdMutation', payload)
        }
    }
}