
export default class DesignTracker {
    constructor() {
        /** Default Setting for Design Tracker if access is change */
        this.fff_default = {
            room: 'Kitchen',
            is_parent: 1,
            disabled: 0,
            items: [
                {item: 'Sink', disabled: 0},
                {item: 'Sink Tap', disabled: 0},
                {item: 'Waste / Recycling Bins', disabled: 0},
                {item: 'Island Light', disabled: 0},
                {item: 'Wall Light', disabled: 0}
            ]
        }

        this.appliance_default = {
            'room': 'Kitchen',
            'is_parent': 1,
            'disabled': 0,
            'items': [
                {'item': 'Fridge', 'disabled': 0}, 
                {'item': 'Freezer', 'disabled': 0}, 
                {'item': 'Oven', 'disabled': 0},
                {'item': 'Microwave Oven', 'disabled': 0},
                {'item': 'Heating Oven', 'disabled': 0},
            ]
        }

        this.fc_default = {
            'location'      : 'Kitchen',
            'is_parent'     : 1,
            'disabled'      : 0,
            'items'         : [
                {'item' : 'Splashback', 'disabled' : 0},
                {'item' : 'Floor', 'disabled' : 0},
            ]
        }

        //console.log(this.fff_default, this.appliance_default, this.fc_default)
    }

    getFFFDefaultData() {
        return this.fff_default;
    }
    
    getFCDefaultData() {
        return this.fc_default
    }

    getApplianceDefaultData() {
        return this.appliance_default
    }

    addDesignTrackerItem(parent_id, item) {
        console.log(parent_id, item)
    }
}