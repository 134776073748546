import Vue from "vue";

export default {
    namespaced: true,
    state: {
      flooringcalculation: [],
      appliances: [],
      fff: [],
      editfff: {},
      editappliances: {},
      editflooring: {},
      design_trackers: {},
      dt_fff: [],
      dt_appliances: [],
      dt_fc: []
    },
    mutations: {
      setflooringcalculation(state, payload){
        state.flooringcalculation = [...payload]
      },
      setappliances(state, payload){
        state.appliances = [...payload]
      },
      setfff(state, payload){
        state.fff = [...payload]
      },
      seteditfff(state, payload){
        state.editfff = payload
      },
      seteditappliances(state, payload){
        state.editappliances = payload
      },
      seteditflooring(state, payload){
        state.editflooring = payload
      },
      set_design_trackers_mutation(state, payload) {
        state.design_trackers = payload;
      },
      set_dt_fff_mutation(state, payload) {
        state.dt_fff = [...payload];
      },
      set_dt_fff_data_mutation(state, payload) {
        const fff_parent = state.dt_fff.find(item => item.id == payload.parent_id)
        fff_parent.getchild.find(child => {
          if ( child.id == payload.id ) {
            child.item = payload.item
            child.quantity = payload.quantity
            child.color = payload.color
            child.supplier_id = payload.supplier_id
            child.product_code = payload.product_code
            child.link_to_product = payload.link_to_product
            child.notes = payload.notes
            child.budget = payload.budget
            child.actual = payload.actual
          }
        })        
      },
      mark_dt_fff_data_muration(state, payload) {
        const fff_parent = state.dt_fff.find(item => item.id == payload.parent_id)
        fff_parent.getchild.find(child => {
          if (child.id == payload.parent_id) {
            child.is_done = payload.data.is_done
          }
        })
      },
      set_dt_appliance_mutation(state, payload) {
        state.dt_appliances = [...payload]
      },
      set_dt_fc_mutation(state, payload) {
        state.dt_fc = [...payload]
      },
      set_dt_appliance_data_mutation(state, payload) {       
        const appliance_parent = state.dt_appliances.find(item => item.id == payload.parent_id)
        appliance_parent.getchild.find(child => {
          if ( child.id == payload.id) {
            const data = payload.data
            child.item = data.item
            child.quantity = data.quantity
            child.color = data.color
            child.supplier_id = data.supplier_id
            child.product_code = data.product_code
            child.link_to_product = data.link_to_product
            child.notes = data.notes
            child.budget = data.budget
            child.actual = data.actual
          }
        })
      },
      set_dt_mark_appliance(state, payload) {
        const appliance_parent = state.dt_appliances.find(item => item.id == payload.parent_id)
        appliance_parent.getchild.find(child => {
          if ( child.id == payload.parent_id) {
            child.is_done = payload.data.is_done
          }
        })
      },
      set_dt_fc_data_mutation(state, payload) {
        const fc_parent = state.dt_fc.find(item => item.id == payload.parent_id)
        fc_parent.getchild.find(child => {
          if ( child.id == payload.id) {
            child.item = payload.data.item
            child.sqm = payload.data.sqm
            child.floor_type = payload.data.floor_type
            child.price_per_sqm = payload.data.price_per_sqm
            child.total = payload.data.total
            child.supplier_id = payload.data.supplier_id
            child.notes = payload.data.notes
          }
        })
      },
      set_dt_mark_fc_mutation(state, payload) {
        const fc_parent = state.dt_fc.find(item => item.id == payload.parent_id)
        fc_parent.getchild.find(child => {
          if ( child.id == payload.id) {
            child.is_done = payload.data.is_done
          }
        })
      }
    },
    getters: {
      getflooringcalculation(state){
        return state.flooringcalculation
      },
      getappliances(state){
        return state.appliances
      },
      getfff(state){
        return state.fff
      },
      geteditfff(state){
        return state.editfff
      },
      geteditappliances(state){
        return state.editappliances
      },
      geteditflooring(state){
        return state.editflooring
      },
      get_designtrackers_getter: state => state.design_trackers,
      get_dt_fff_getter: state => state.dt_fff,
      get_dt_appliance_getter: state => state.dt_appliances,
      get_dt_fc_getter: state => state.dt_fc
    },
    actions: {
      setflooringcalculation({commit}, payload){
        commit('setflooringcalculation', payload)
      },
      setappliances({commit}, payload){
        commit('setappliances', payload)
      },
      setfff({commit}, payload){
        commit('setfff', payload)
      },
      seteditfff({commit}, payload){
        commit('seteditfff', payload)
      },
      seteditappliances({commit}, payload){
        commit('seteditappliances', payload)
      },
      seteditflooring({commit}, payload){
        commit('seteditflooring', payload)
      },
      async fetch_designtracker_action({commit}, payload) {
        await Vue.axios.get(`/designtracker/get_designtrackers/${payload.project_id}/${payload.user_id}`)
          .then( ({data}) => {
              commit('set_design_trackers_mutation', data.data);             
          });
      },
      fetch_dt_fff_action({commit}, payload) {
        Vue.axios.post(`/designtracker/generate_fff/${payload.user_id}/${payload.project_id}`).then( ({data}) => {
          commit('set_dt_fff_mutation', data.data);
        });
      },
      save_dt_fff_action({commit}, payload) {
        Vue.axios.post(`/designtracker/sava_dt_fff/${payload.data.id}`, payload.data).then( ({data}) => {
          if ( data.response === true ) {
            commit('set_dt_fff_data_mutation', payload.data)
          }
        });        
      },
      mark_dt_fff_action({commit}, payload) {
        Vue.axios.post(`/designtracker/mark_dt_fff/${payload.fff_id}`, payload.data).then( ({data}) => {
          if ( data.response === true ) {
            commit('set_dt_dd_data_mutation', payload)
          }
        });
      },
      /** Design Tracker: Appliance */
      fetch_dt_appliance_action({commit}, payload) {
        Vue.axios.post(`/designtracker/generate_appliances/${payload.user_id}/${payload.project_id}`).then( ({data}) => {
          commit('set_dt_appliance_mutation', data.data);
        });
      },
      /** Design Tracker: Flooring Calculation */
      fetch_dt_fc_action({commit}, payload) {
        Vue.axios.post(`/designtracker/generate_fc/${payload.user_id}/${payload.project_id}`).then( ({data}) => {
          commit('set_dt_fc_mutation', data.data);
        });
      },
      save_dt_appliance_action({commit}, payload) {
        Vue.axios.post(`/designtracker/sava_dt_appliance/${payload.id}`, payload.data).then( ({data}) => {
          if ( data.response === true ) {
            commit('set_dt_appliance_data_mutation', payload)
          }
        });
      },
      mark_dt_appliance_action({commit}, payload) {
        Vue.axios.post(`/designtracker/mark_dt_appliance/${payload.id}`, payload.data).then( ({data}) => {
          if ( data.response === true ) {
            commit('set_dt_mark_appliance', payload)
          }
        });
      },
      /** Flooring Calculation Action */
      save_dt_fc_action({commit}, payload) {
        Vue.axios.post(`/designtracker/sava_dt_fc/${payload.id}`, payload.data).then( ({data}) => {
          if ( data.response === true ) {
            commit('set_dt_fc_data_mutation', payload)
          }
        });
      },
      mark_dt_fc_action({commit}, payload) {
        Vue.axios.post(`/designtracker/mark_dt_fc/${payload.id}`, payload.data).then( ({data}) => {
          if ( data.response === true ) {
            commit('set_dt_mark_fc_mutation', payload)
          }
        });
      }
    }
}