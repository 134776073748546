<template>
    <v-row justify="center">
        <v-dialog
            v-model="form_dialog"
            persistent
            max-width="500">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    Add Item Description
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-text-field
                        v-model="formtext_description"
                        prepend-icon="mdi-tooltip-text-outline"
                        type="text"
                        label="Item Description"
                        hint="Item Description"
                        dense
                        outlined
                        autofocus
                        class="mt-6">
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" class="px-6" @click="event_close_dialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>Close</v-btn>
                    <v-btn class="px-6 white--text" color="rgb(11, 78, 243)" @click="save_additem_budgetmanager()">
                        <v-icon class="mr-2">mdi-content-save-outline</v-icon>Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    data: () => ({
        form_dialog: false,
        formtext_description: null
    }),
    props: {
        add_item_formdialog: Boolean,
        budgetmanager_item: {}
    },
    computed: {

    },
    methods: {
        ...mapActions({
            additem_budgetmanager:          'budgetmanager/additem_budgetmanager_action'
        }),
        event_close_dialog() {
            this.$emit('close_dialog');
        },
        save_additem_budgetmanager() {
            this.additem_budgetmanager({
                users_id: this.budgetmanager_item.users_id,
                project_id: this.budgetmanager_item.project_id,
                bm_parent_id: this.budgetmanager_item.id,
                description: this.formtext_description
            });
            this.event_close_dialog();
        }
    },
    watch: {
        add_item_formdialog() {
            this.formtext_description = null;
            this.form_dialog = this.add_item_formdialog;
        }
    }
}
</script>