export default {
    namespaced: true,
    state: {
      billofmaterialstemplate: [],
      selectedmaterialstemplate: {},
      billofmaterialsdata: [],
      billofmaterialsvariables: [],
      toupdatevalue: {},
      selectedrowforsupplierupdate: {}
    },
    mutations: {
      setbillofmaterialstemplate(state, payload){
        state.billofmaterialstemplate = [...payload]
      },
      setselectedmaterialstemplate(state, payload){
        state.selectedmaterialstemplate = payload
      },
      setbillofmaterialsdatamm(state, payload){
        state.billofmaterialsdata = [...payload]
      },
      setbillofmaterialsdatavariables(state, payload){
        state.billofmaterialsvariables = [...payload]
      },
      settoupdatevalue(state, payload){
        state.toupdatevalue = payload
      },
      setselectedrowforsupplierupdate(state, payload){
        state.selectedrowforsupplierupdate = payload
      },
      setBoMItemImageMutation(state, payload) {
        const bom_item = state.billofmaterialsdata.find( (item) => item.id === payload.id )   
        if ( typeof bom_item != 'undefined' ) {
          bom_item.image = URL.createObjectURL(payload.image)
        }       
      }
    },
    getters: {
      getbillofmaterialstemplate(state){
        return state.billofmaterialstemplate
      },
      getselectedmaterialstemplate(state){
        return state.selectedmaterialstemplate
      },
      getbillofmaterialsdata(state){
        return state.billofmaterialsdata
      },
      billofmaterialsvariables(state){
        return state.billofmaterialsvariables
      },
      gettoupdatevalue(state){
        return state.toupdatevalue
      },
      getselectedrowforsupplierupdate(state){
        return state.selectedrowforsupplierupdate
      },
      getBillOfMaterialTemplateById: (state) => (id) => state.billofmaterialstemplate.filter( (item) => item.id == id)
    },
    actions: {
      setbillofmaterialstemplate({commit}, payload){
        commit('setbillofmaterialstemplate', payload)
      },
      setselectedmaterialstemplate({commit}, payload){
        commit('setselectedmaterialstemplate', payload)
      },
      setbillofmaterialsdata({commit}, payload){
        const mm = payload.mm.map((item, index) => ({
            ...item,
            index: index + 1
        }))
        commit('setbillofmaterialsdatamm', mm)
        commit('setbillofmaterialsdatavariables', payload.variables)
      },
      settoupdatevalue({commit}, payload){
        commit('settoupdatevalue', payload)
      },
      setselectedrowforsupplierupdate({commit}, payload){
        commit('setselectedrowforsupplierupdate', payload)
      },
      setBoMItemImageAction({commit}, payload) { 
        commit('setBoMItemImageMutation', payload)
      }
    }
}