<template>
    <v-row justify="center">
        <v-dialog
            v-model="edit_quotation_model"
            width="700">
            <v-card>
                <v-card-title>Edit Quotation</v-card-title>
                <v-card-text class="mt-6">
                    <v-text-field
                    label="Quoted Unit Cost"
                    placeholder="Enter desired unit cost for quotation"
                    v-model="unit_cost"
                    type="number"
                    dense
                    outlined></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="evtCloseDialog()"
                        class="px-6">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Cancel
                    </v-btn>
                    <v-btn color="rgb(6, 42, 130)"
                        class="px-6 white--text"
                        @click="evtUpdateClientQoutedMaterial()">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import ClientQuotation from '../../modules/ClientQuotation.js'

export default {
    data: () => ({
        edit_quotation_model: false,
        unit_cost: 0,
        client_quotation: null
    }),
    props: {
        edit_quotation_dialog: Boolean,
        quoted_material: Object
    },
    methods: {
        evtCloseDialog() {
            this.$emit('evtCloseDialog')
        },
        evtUpdateClientQoutedMaterial() {
            this.client_quotation.updateClientQuotedMaterials(this.quoted_material.id, {unit_cost: this.unit_cost});
            this.evtCloseDialog()
        }
    },
    mounted() {
        
    },
    created() {
        this.client_quotation = new ClientQuotation(this.$store)
    },
    watch: {
        edit_quotation_dialog() {
            this.edit_quotation_model = this.edit_quotation_dialog
            if ( this.edit_quotation_model == true ) {
                this.unit_cost = this.quoted_material.unit_cost
            }
        }
    }
}
</script>