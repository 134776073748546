import store from '@/states/index.js'

import ServiceRequestReports from '../ServiceRequestReports'

export default [
    {
        path: '/service_request_report',
        component: ServiceRequestReports,
        name: 'service_request_report',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 1){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]