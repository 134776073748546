<template>
    <div>
        <v-data-iterator
            :items="show_deleted ? get_uploaded_trashed_files.video : get_video_files.video"
            :search="search"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            item-key="id">
            <template v-slot:header>
                <v-row>
                    <v-col cols="11">
                        <v-text-field
                            v-model="search"                     
                            prepend-inner-icon="mdi-magnify"
                            placeholder="search by video name | description here..."
                            label="search by video name | description here...">
                        </v-text-field>
                    </v-col>
                    <v-col cols="1" class="justify-content-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-switch 
                                        v-model="show_deleted"
                                        x-small                   
                                        class="mx-3"
                                        inset>
                                    </v-switch>
                                </div>
                            </template>
                            <span>Show or hide deleted files.</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:default="{items}">
                <v-row class="d-flex jsutify-content-center">
                    <v-col
                        v-for="item in items"
                        :key="item.id"
                        cols="auto"
                        lg="3"
                        md="4"             
                        >
                        <v-card class="text-center pt-2 pb-2">
                            <video controls class="video-content" ref="videos" :poster="get_file_complete_url(item.thumbnail)">
                                <source :src="get_file_complete_url(item.file_url)" :type="item.file_mimes" height="300">
                            </video>
                            <v-card-title class="no-gutters">
                                <h4 class="card-video-title px-2">{{ item.name }}</h4>
                            </v-card-title>
                            <v-card-actions>
                                <v-chip
                                    class="p-2"
                                    color="primary"
                                    small
                                    label>
                                <v-icon left>mdi-account-circle-outline</v-icon>
                                    {{ item.get_user.name }}
                                </v-chip>
                                <v-spacer></v-spacer>
                                <div v-if="!show_deleted">
                                    <v-btn text small color="primary" fab @click="edit_dialog(item)">
                                        <v-icon small>mdi-pen</v-icon>
                                    </v-btn>
                                    <v-btn text small fab color="error" @click="delete_uploaded_file_modal(item)">
                                        <v-icon small>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <v-btn text small color="success" fab @click="restore_deleted_file_modal(item)">
                                        <v-icon small>mdi-restore</v-icon>
                                    </v-btn>
                                </div>
                            </v-card-actions>
                            <!--<v-divider></v-divider>
                            <v-card-text>
                                <v-row class="card-desc">
                                    <v-col class="text-left">
                                        <h4 class="text-muted">Description: </h4>
                                        <p class="px-2 text-justify black--text">
                                            {{ item.description }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card-text>-->
                            <v-divider></v-divider>
                            <v-card-text class="white--text text-left">
                                <v-chip
                                    x-small                                           
                                    color="white"
                                    label
                                    text-color="black">
                                <v-icon left>
                                    mdi-calendar
                                </v-icon>
                                    <div>Created: {{ item.created_at }} | Updated: {{ item.updated_at }} {{ item.deleted_at ? `| Deleted: ${item.deleted_at}` : '' }}</div>
                                </v-chip>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>

        <!-- Delete Modal -->
        <v-dialog
            width="50%"
            v-model="delete_modal"           
            persistent
            transition="dialog-bottom-transition"
            :loading="loading">
            <v-card>
                <v-card-title>
                    <span>{{ modal_title }}</span>
                    <v-spacer></v-spacer>
                    <v-btn text small fab @click="close_delete_modal()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <template slot="progress">
                        <v-progress-linear
                            color="primary"
                            height="5"
                            indeterminate></v-progress-linear>
                    </template>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="black--text mt-5">
                    Are you sure your want to delete this file?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text small width="150" class="mx-2" @click="close_delete_modal()">No</v-btn>
                    <v-btn color="error" width="150" class="white--text" small @click="execute_delete_file_modal_action()">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- /Delete Modal -->

        <!-- Restore Modal -->
        <v-dialog
            width="50%"
            v-model="restore_modal"           
            persistent
            transition="dialog-bottom-transition"
            :loading="loading">
            <v-card>
                <v-card-title>
                    <span>{{ modal_title }}</span>
                    <v-spacer></v-spacer>
                    <v-btn text small fab @click="close_restore_modal()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <template slot="progress">
                        <v-progress-linear
                            color="primary"
                            height="5"
                            indeterminate></v-progress-linear>
                    </template>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="black--text mt-5">
                    Are you sure your want to restore this file?
                </v-card-text>               
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text small width="150" class="mx-2" @click="close_restore_modal()">No</v-btn>
                    <v-btn color="error" width="150" class="white--text" small @click="execute_restore_file_modal_action()">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- /Restore Modal -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data: () => ({
        loading: false,
        delete_modal: false,
        restore_modal: false,
        modal_title: null,
        show_deleted: false,
        snackbar: false,
        search: '',
        sortBy: 'updated_at',
        sortDesc: true,
        APP_URL: process.env.VUE_APP_URL,
        file: null
    }),
    computed: {
        ...mapGetters({
            get_video_files:                        'admin_helper_training/get_uploaded_files',
            get_error_message:                      'admin_helper_training/get_error_message_getters',
            get_uploaded_trashed_files:             'admin_helper_training/get_uploaded_trashed_files'
        })
    },
    methods: {
        ...mapActions({
            fetch_files_by_types:                   'admin_helper_training/fetch_files_by_types',
            delete_uploaded_file:                   'admin_helper_training/delete_uploaded_file',
            get_trashed_uploaded_files_by_types:    'admin_helper_training/get_trashed_uploaded_files_by_types',
            restore_trashed_uploaded_files:         'admin_helper_training/restore_trashed_uploaded_files'
        }),
        get_file_complete_url(url) {
            return this.APP_URL + url;
        },
        edit_dialog(item) {
            this.$emit('edit_dialog', item)
        },
        delete_file() {
            this.delete_uploaded_file(this.file);
        },
        restore_trashed_file() {
            this.restore_trashed_uploaded_files(this.file);
        },
        delete_uploaded_file_modal(file) {
            this.file = file;
            this.modal_title = `Delete the file ${file.name}.`;
            this.delete_modal = true
        },
        async execute_delete_file_modal_action() {
            this.loading = true;
            await this.delete_file();
            this.loading = false;
            this.close_delete_modal();          
        },
        close_delete_modal() {
            this.modal_title = null;
            this.delete_modal = false;
            this.file = null;
        },
        restore_deleted_file_modal(file) {
            this.file = file;
            this.modal_title = `Restore the file ${file.name}.`;
            this.restore_modal = true;
        },
        close_restore_modal() {
            this.file = null;
            this.modal_title = null;
            this.restore_modal = false
        }, 
        async execute_restore_file_modal_action() {
            this.loading = true;
            await this.restore_trashed_file();
            this.loading = false;
            this.close_restore_modal();
        }
    },
    mounted() {
        this.fetch_files_by_types('video');
        this.get_trashed_uploaded_files_by_types('video');
    },
    watch: {
        get_error_message() {
            if ( this.get_error_message ) {
                // this.snackbar = true;
            } else {
                // this.snackbar = false;
            }
        },
        'get_video_files.video'() {            
            this.$refs.videos.map(video => {
                video.load();
            });
        }
    }
}
</script>

<style scoped lang="scss">
.card-title {
    font-size: 14px;
}

.card-video.title {
    font-size: 14px;
}
.card-desc{
    height: 100px;
    overflow: hidden;
}
.no-gutters {
    padding:0;
    margin: 0;
}
.video-content {
    object-fit: initial;
    width: 100%;
    height: 300px;
}
</style>