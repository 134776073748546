export default {
    namespaced: true,
    state: {
        unquoted: []
    },
    mutations: {
        setunquoted(state, payload){
            state.unquoted = [...payload]
        }
    },
    getters: {
        getunquoted(state){
            return state.unquoted
        }
    },
    actions: {
        setunquoted({commit}, payload){
            commit('setunquoted', payload)
        }
    }
}