<template>
    <v-row justify="center">
        <v-dialog
            v-model="inherit_modules_dialog"            
            hide-overlay>
            <v-card>
                <v-card-title>
                    <small>Inherit Modules from Delete Project</small>
                    <v-spacer></v-spacer>
                    <v-btn small text fab
                        @click="close_dialog()">
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="mt-3">
                    <v-tabs
                        v-model="sections"
                        show-arrows>
                        <v-tab
                            v-for="project in get_deleted_projects"
                            :key="project.id"
                            @click="select_project(project)">
                            {{ project.name }}
                        </v-tab>
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab-item
                            v-for="project_content in get_deleted_projects"
                            :key="project_content.id">
                                <v-card flat class="ml-5 mb-5 mr-5">
                                    <v-card-text>                                        
                                        <v-expansion-panels popout>
                                            <v-expansion-panel
                                                v-for="(mod, index) in modules"
                                                :key="index">
                                                <v-expansion-panel-header
                                                    style="background: #062a82;"
                                                    class="white--text">
                                                    {{ mod.name }}
                                                    <template v-slot:actions>
                                                        <v-icon color="white">
                                                        $expand
                                                        </v-icon>
                                                    </template>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <RemidersExtend v-if="mod.name == 'Reminders'"
                                                        :project="selected_project"
                                                        :user_id="user_id"></RemidersExtend>
                                                    <SiteDiaryExtend v-if="mod.name == 'Site Diary'"
                                                        :project="selected_project"
                                                        :user_id="user_id"></SiteDiaryExtend>
                                                    <BudgetManagerExtend v-if="mod.name == 'Budget Manager'"
                                                        ></BudgetManagerExtend>
                                                    <FileManagerExtend v-if="mod.name == 'File Manager'"
                                                        :project="selected_project"
                                                        :user_id="user_id"></FileManagerExtend>
                                                    <DesignTrackerExtend v-if="mod.name == 'Design Tracker'"
                                                        :project="selected_project"
                                                        :user_id="user_id"></DesignTrackerExtend>
                                                    <DecisionsExtend v-if="mod.name == 'Decisions'"
                                                        :project="selected_project"
                                                        :user_id="user_id"></DecisionsExtend>
                                                    <SnagsExtend v-if="mod.name == 'Snags'"
                                                        :project="selected_project"
                                                        :user_id="user_id"></SnagsExtend>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>                                       
                                    </v-card-text>
                                </v-card>
                        </v-tab-item>
                    </v-tabs>                  
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        rounded
                        class="px-10"
                        @click="select_project_to_inherit()">
                        Select Project
                    </v-btn>
                    <v-btn
                        color="error"                
                        rounded
                        class="px-10"
                        @click="skip_inherit_module()">
                        <v-icon>mdi-skip-next</v-icon>Skip
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import RemidersExtend from './extends/RemindersExtend.vue';
import SiteDiaryExtend from './extends/SiteDiaryExtend.vue';
import BudgetManagerExtend from './extends/BudgetManagerExtend.vue';
import FileManagerExtend from './extends/FileManagerExtend.vue';
import DesignTrackerExtend from './extends/DesignTrackerExtend.vue';
import DecisionsExtend from './extends/DecisionsExtend.vue';
import SnagsExtend from './extends/SnagsExtend.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        inherit_modules_dialog: false,
        mod_event: null,        
        modules: [
            {id: 0, name: 'Reminders'},
            {id: 1, name: 'Site Diary'},
            {id: 2, name: 'Budget Manager'},
            {id: 3, name: 'File Manager'},
            {id: 4, name: 'Design Tracker'},
            {id: 5, name: 'Decisions'},
            {id: 6, name: 'Snags'},
        ],
        selected_project: [],
        sections: 0
    }),
    components: {
        RemidersExtend,
        SiteDiaryExtend,
        BudgetManagerExtend,
        FileManagerExtend,
        DesignTrackerExtend,
        DecisionsExtend,
        SnagsExtend,
    },
    props: {
        inheritModules: Boolean,
        user_id: Number,
    },
    computed: {
        ...mapGetters({
            get_deleted_projects:               'project_planner_v2/get_deleted_projects_getter',
        })
    },
    methods: {
        ...mapActions({
            fetch_deleted_projects:         'project_planner_v2/fetch_deleted_projects_actions',
            set_inherited_projects:         'project_planner_v2/set_inherited_projects_action'
        }),
        close_dialog() {
            this.$emit('close_dialog');
        },
        select_project(project) {            
            this.selected_project = project;            
        },
        skip_inherit_module() {
            this.$emit('skip_inherit_modules');
        },
        select_project_to_inherit() {
            this.set_inherited_projects({
                "old_projectid": this.selected_project.id
            });
            this.skip_inherit_module();
        }
    },
    watch: {
        async inheritModules() {
            await this.fetch_deleted_projects({user_id: this.user_id});
            this.selected_project = this.get_deleted_projects[0];
            this.inherit_modules_dialog = this.inheritModules;
            this.sections = 0;
        }
    }
}
</script>