<template>
    <div class="pb-5">
        <v-data-table            
            :headers="headers"
            :items="get_directories_with_files"
            :items-per-page="10"
            class="elevation-1"
            mobile-breakpoint="0">
            <template v-slot:item="props">
                <tr style="cursor:pointer;" @click="openfiles(props.item)">
                    <td>{{ props.item.name }}</td>
                    <td><v-icon class="mr-1">mdi-file-cabinet</v-icon>{{ props.item.contains }}</td>
                    <td><v-icon class="mr-1">mdi-clipboard-file</v-icon>{{ props.item.total_size | bytes}}</td>
                    <td>{{ props.item.updated_at }}</td>
                </tr>
            </template>
        </v-data-table>

        <!-- Show Files -->
        <v-row justify="center">
            <v-dialog
                v-model="show_files_dialog"
                persistent
                scrollable
                width="980">
                <v-card>
                    <v-card-title>
                        <small>Directory</small>
                    </v-card-title>
                    <v-card-text>
                        <v-divider></v-divider>
                        <v-row justify="center" class="mt-5">
                            <v-col 
                                v-for="(media, index) in media"
                                :key="index"
                                cols="12"
                                md="4"
                                lg="4"
                                sm="12">
                                <v-sheet
                                    elevation="1"
                                    color="gray;"
                                    width="100%">   
                                    <v-col cols="12">
                                        <v-row>                                            
                                            <v-col cols="12">
                                                <strong>Name: {{media.name}}</strong><br/>
                                                <small>Created at: {{media.created_at}}</small>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <a :href="`${img_url}${media.path}`" target="_blank">
                                        <img v-if="media.extension_file == 'jpg' || media.extension_file == 'png'" :src="`${img_url}${media.path}`" style="max-height: 250px; max-width: 250px;"/>
                                    </a>
                                    <div v-if="media.extension_file == 'pdf'">
                                        <a :href="`${img_url}${media.path}`" target="_blank">
                                            <img src="https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/27_Pdf_File_Type_Adobe-512.png" style="max-height: 250px; max-width: 250px;"/>
                                        </a>
                                    </div>
                                    <div v-if="media.extension_file == 'docx'">
                                        <img @click="openfile(media)" src="https://images.ctfassets.net/lzny33ho1g45/how-to-work-with-images-in-goo-p-img/bc519a15b782515b68c1f91329b6e8ea/file.png?w=520&fm=jpg&q=30&fit=thumb&h=520" style="max-height: 250px; max-width: 250px;"/>
                                    </div>
                                    <div v-if="media.extension_file == 'xlsx'">
                                        <img @click="openfile(media)" src="https://www.computerhope.com/jargon/e/microsoft-excel.png" style="max-height: 250px; max-width: 250px;"/>
                                    </div>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="closedialog">
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- ./Show Files -->
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        headers: [
            { text: 'Name', filterable: true, sortable: true, value: 'name'},
            { text: 'Contains', filterable: true, sortable: true, value: 'contains'},
            { text: 'Total Size', filterable: true, sortable: true, value: 'total_size'},
            { text: 'Last Updated', filterable: true, sortable: true}
        ],
        show_files_dialog: false,
        media: [],
        img_url: process.env.VUE_APP_URL
    }),
    props: {
        project: Object,
        user_id: Number
    },
    computed: {
        ...mapGetters({
            get_directories_with_files:     'filemanager/get_directories_with_files_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_directories_with_files:    'filemanager/fetch_directories_with_files_action'
        }),
        openfiles(item) {
            this.$store.dispatch('filemanager/setchildfiles', item);
            this.media = item.getmedia;
            this.show_files_dialog = true;
        },
        closedialog() {
            this.show_files_dialog = false;
        }
    },
    async mounted() {
        console.log('Fetch Directories...');
        await this.fetch_directories_with_files({
            project_id: this.project.id,
            user_id: this.user_id
        });
    },
    watch: {
        async project() {
            await this.fetch_directories_with_files({
                project_id: this.project.id,
                user_id: this.user_id
            });
        }
    }
}
</script>