<template>
  <v-container fluid class="mt-5 flex">
    <v-card>
      <v-card-title style="fontSize: 14px;">
        <strong>Supplier List</strong>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="mr-3"
          @click="get_supplier()"
        >
          <v-icon>mdi-close</v-icon>clear
        </v-btn>
        <v-btn
          text
          class="mr-3"
        >
          <v-icon class="mr-3">mdi-database-plus-outline</v-icon>add
        </v-btn>
        <v-btn
          dark
          color="primary"
          class="pl-12 pr-12"
          @click="upload_csv_dialog"
        >
          <v-icon class="mr-3">mdi-file-upload-outline</v-icon>upload
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="table_header"
          :items="get_suppliers"
          item-key="id"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="search email | company name here..."
              label="search email | company name here..."
            >
            </v-text-field>
          </template>
          <template v-slot:item="{ item, expand, isExpanded }" >
            <tr
              style="cursor:pointer;" 
              @click="expand(!isExpanded)"
            >
              <td>
                {{ item.id }}
              </td>
              <td>
                {{ item.company_name }}
              </td>
              <td>
                {{ item.email }}
              </td>
              <td>
                <label v-if="item.user_id === null">No</label>
                <label v-else>Yes</label>
              </td>
              <td>
                <v-icon 
                  small
                  class="mr-2"
                >
                  mdi-email-fast-outline
                </v-icon>
                <v-icon 
                  small
                  class="mr-2"
                >
                  mdi-pen
                </v-icon>
                <v-icon 
                  small
                  class="mr-2"
                  @click="delete_supplier(item)"
                >
                  mdi-delete-outline
                </v-icon>
                <v-icon 
                  small
                  class="mr-2"
                  @click="get_link(item)"
                >
                  mdi-link
                </v-icon>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <v-col
                  cols="4"
                >
                  <v-card
                    class="mt-5 mb-5"
                  >
                    <v-card-title class="text-small">
                      <small>Supplier Main Information</small>
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        dense
                        outlined
                        prepend-inner-icon="mdi-account"
                        :value="item.company_name"
                        class="mt-3"
                        label="Company Name"
                      >
                      </v-text-field>
                      <v-text-field
                        dense
                        outlined
                        prepend-inner-icon="mdi-at"
                        :value="item.email"
                        label="Email"
                      >
                      </v-text-field>
                      <v-text-field
                        dense
                        outlined
                        prepend-inner-icon="mdi-web"
                        :value="item.website"
                        label="Website"
                      >
                      </v-text-field>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-card
                    class="mt-5 mb-5"
                  >
                    <v-card-title class="text-small">
                      <small>County and Service Type</small>
                    </v-card-title>
                    <v-card-text>
                      <label>County in record: {{item.phy_country}}</label>
                      <v-autocomplete
                        :items="get_counties"
                        item-value="id"
                        item-text="name"
                        label="County"
                        dense
                        outlined
                        prepend-inner-icon="mdi-map-marker"
                        v-model="u.county"
                        return-object
                      >
                      </v-autocomplete>
                      <label>Service Type in record: {{item.supplier_type.service_type}}</label>
                      <v-autocomplete
                        :items="get_supplier_type"
                        item-value="id"
                        item-text="service_type"
                        label="Service Type"
                        dense
                        outlined
                        prepend-inner-icon="mdi-information-outline"
                        v-model="u.service_type"
                      >
                      </v-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer/>
                      <v-btn
                        color="primary"
                        dark
                        class="pl-10 pr-10"
                        @click="update_record_supplier(item.id)"
                      >
                        <v-icon class="mr-3">mdi-content-save</v-icon>
                        Update
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-row justify="center">
      <v-dialog
          max-width="650"
          v-model="upload_csv"
          persistent
      >
          <v-card>
              <v-card-title style="fontSize: 18px;">
                  <strong>upload supplier *please use CSV</strong>
              </v-card-title>

              <v-card-text>
                  <v-col
                    cols="12"
                  >
                    <v-file-input
                      v-model="file"
                      dense
                      outlined
                      label="Choose CSV"
                    ></v-file-input>
                  </v-col>
              </v-card-text>

              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  text
                  @click="clear"
              >
                  <v-icon class="mr-3">mdi-window-close</v-icon>Cancel
              </v-btn>
              <v-btn
                  color="primary"
                  dark
                  @click="save_supplier"
                  class="pl-12 pr-12"
              >
                  <v-icon 
                      class="mr-3"
                  >
                      mdi-content-save-outline
                  </v-icon>Save
              </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog
        max-width="420"
        v-model="show_dialog_delete"
        persistent
      >
      <v-card>
          <v-card-title style="fontSize: 18px;">
            <small>Deleting Supplier from master list</small>
          </v-card-title>

          <v-card-text>
            <label>Are you sure about this?</label>
          </v-card-text>

          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pl-8 pr-8"
            dark
            @click="cancel"
          >
            cancel
          </v-btn>
          <v-btn
            text
            @click="delete_supplier_continue"
          >
            delete
          </v-btn>
          <!-- <v-btn
              v-for="(btn, btnIndex) in dialogsvalue.buttons"
              :key="btnIndex"
              :color="btn.color"
              :class="btn.class"
              :text="btn.text"
              :dark="btn.dark"
              @click="btn.action ? delproject() : donothing()"
          >
              {{btn.label}}
          </v-btn> -->
          </v-card-actions>
      </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    table_header:[
      { text: 'ID' },
      { text: 'Company' },
      { text: 'Email' },
      { text: 'Registered' },
      { text: 'Action(s)' }
    ],
    search: null,
    upload_csv: false,
    file: null,
    show_dialog_delete: false,
    u: {
      county: null,
      service_type: null
    }
  }),
  async mounted () {
    await this.get_supplier()
    await this.$store.dispatch('admin_supplier/fetch_counties')
    await this.$store.dispatch('admin_supplier/fetch_supplier_type')
  },
  created () {
  },
  computed: {
    ...mapGetters({
      // get_suppliers:                    'admin_supplier/get_suppliers'
      get_to_delete_item:                   'admin_supplier/get_to_delete_item',
      get_counties:                         'admin_supplier/get_counties',
      get_supplier_type:                    'admin_supplier/get_supplier_type'
    }),
    get_suppliers(){
      return this.$store.getters['admin_supplier/get_suppliers'](this.search)
    }
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async get_supplier(){
      await this.$axios.get('admin/suppliers/get_supplier_list')
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('admin_supplier/set_suppliers', data.data)
        }
      })
    },
    clear(){
      this.upload_csv = false
      this.file.length = 0
      this.file = []
    },
    upload_csv_dialog(){
      this.upload_csv = true
    },
    async save_supplier(){
      const fData = new FormData()
      fData.append('file', this.file)
      await this.$axios.post('admin/suppliers/uploadcsv', fData)
      // .then(({data}) => {
        location.reload()
        // if(data.response){
        //   this.showSnackBar(data.message)
        //   this.get_supplier()
        //   this.clear()
        // }else{
        //   this.clear()
        //   this.get_supplier()
        // }
      // })
    },
    delete_supplier(data){
      this.show_dialog_delete = true
      this.$store.dispatch('admin_supplier/set_to_delete_item', data)
    },
    cancel(){
      this.show_dialog_delete = false
      this.$store.dispatch('admin_supplier/set_to_delete_item', {})
    },
    async delete_supplier_continue(){
      // console.log(this.get_to_delete_item.id)
      await this.$axios.delete(`admin/suppliers/delete_supplier/${this.get_to_delete_item.id}`)
      .then(({data}) => {
        if(data.response){
          this.get_supplier()
          this.cancel()
        }
      })
    },
    async update_record_supplier(supplier_id){
      // console.log(this.u)
      await this.$axios.patch(`admin/suppliers/update_supplier_info/${supplier_id}`, {
        phy_country:            this.u.county.name,
        supplier_types:         this.u.service_type
      })
      .then(({data}) => {
        this.$store.dispatch('admin_supplier/set_suppliers', data.data)
        this.showSnackBar('Updating record success')
      })
    },
    async get_link(tp){
      if(tp.hash_link === null){
        await this.$axios.post('/admin/suppliers/generate_link', {
          id:   tp.id,
          user_id:  tp.user_id
        })
        .then(({data}) => {
          if(data.response){
            const link = process.env.VUE_APP_STATE + 'form/' + data.data.hash_link
            try{
              navigator.clipboard.writeText(link)
              alert('copied')
            }catch($e){
              alert('Cannot copy')
            }
            return
          }else{
            this.showSnackBar(data.message)
            return
          }
        })
        return
      }
      const link = process.env.VUE_APP_STATE + 'form/' + tp.hash_link
      try{
        await navigator.clipboard.writeText(link)
        alert('copied')
      }catch($e){
        alert('Cannot copy')
      }
      return
    }
  },
  watch: {
    // async get_suppliers(value){
    //   if(value.length === 0 && this.search !== null){
    //     await setTimeout(() => {
    //       this.$axios.get(`admin/suppliers/search/${this.search}`)
    //       .then(({data}) => {
    //         if(data.response){
    //           this.$store.dispatch('admin_supplier/set_suppliers', data.data)
    //         }else{
    //           this.showSnackBar(data.message)
    //           this.get_supplier()
    //         }
    //       })
    //     }, 500)
    //   }
    //   return
    // }
  }
}
</script>

<style scoped lang="scss">
</style>