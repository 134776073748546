<template>
    <v-row justify="center">
        <v-dialog 
            v-model="addedit_squaremeter_dialog"
            persistent
            max-width="500">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-pen</v-icon>
                    <small>Add / Edit Square Meter</small>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-text-field class="mr-2 mt-6"
                        v-model="square_meter"
                        prepend-icon="mdi-tooltip-text-outline"
                        outlined
                        dense
                        label="Square Meter"
                        hint="Square Meters"
                        type="number"
                        autofocus>
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" class="px-6" @click="close_dialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>Close
                    </v-btn>
                    <v-btn class="px-6 white--text" color="rgb(11, 78, 243)" @click="save_budgetmanager_propertysize()">
                        <v-icon class="mr-2">mdi-content-save-outline</v-icon>Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data: () => ({
        addedit_squaremeter_dialog: false,
        square_meter: 0
    }),
    props: {
        open_dialog: Boolean,
        budgetmanager_data: Object
    },
    computed: {
    },
    methods: {
        ...mapActions({
            update_budgetmanager_propertysize:          'budgetmanager/update_budgetmanager_propertysize_action'
        }),
        close_dialog() {
            this.$emit('close_dialog');
        },
        save_budgetmanager_propertysize() {
            this.update_budgetmanager_propertysize({
                bm_id: this.budgetmanager_data.id, 
                property_size: this.square_meter
            });
            this.close_dialog();
        }
    },
    watch: {
        open_dialog() {            
            this.addedit_squaremeter_dialog = this.open_dialog
        },
        budgetmanager_data() {
            this.square_meter = this.budgetmanager_data.property_size;
        }
    }
}
</script>