<template>
  <v-container fluid class="mt-5 flex">
    <v-card>
        <v-card-title
            style="fontSize: 14px;"
        >
            <strong>Service Types</strong>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="add_service_type"
            >
                <v-icon>mdi-plus-outline</v-icon> add
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-data-table
                :headers="table_header"
                :items="get_service_types"
            >
                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                        placeholder="search service type here..."
                        label="search service type here..."
                    >
                    </v-text-field>
                </template>
                <template v-slot:item="{ item, expand, isExpanded }" >
                    <tr
                        style="cursor:pointer;" 
                        @click="expand(!isExpanded)"
                    >
                        <td>
                            {{ item.id }}
                        </td>
                        <td>
                            {{ item.service_type }}
                        </td>
                        <td>
                            <v-btn
                                text
                                small
                                outlined
                                @click="delete_item(item.id)"
                            >
                                <v-icon 
                                    small
                                    class="mr-2"
                                >mdi-delete-outline</v-icon>
                                delete
                            </v-btn>
                        </td>
                    </tr>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        {{item}}
                    </td>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
    <v-row justify="center">
        <v-dialog
            max-width="650"
            v-model="dialog_add"
            persistent
        >
            <v-card>
                <v-card-title style="fontSize: 18px;">
                    <small>add service type</small>
                </v-card-title>

                <v-card-text>
                    <v-col
                        cols="12"
                    >
                        <v-text-field
                            v-model="service_type"
                            outlined
                            label="Service Type"
                            placeholder="Service Type"
                            dense
                            @keypress.enter="save_service_type"
                            prepend-inner-icon="mdi-card-text-outline"
                        >
                        </v-text-field>
                    </v-col>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="clear"
                >
                    <v-icon class="mr-3">mdi-window-close</v-icon>Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    dark
                    @click="save_service_type"
                    class="pl-12 pr-12"
                >
                    <v-icon 
                        class="mr-3"
                    >
                        mdi-content-save-outline
                    </v-icon>Save
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog
            max-width="420"
            v-model="delete_dialog"
            persistent
        >
            <v-card>
                <v-card-title style="fontSize: 18px;">
                    <small>Deleting Service Type from master list</small>
                </v-card-title>

                <v-card-text>
                    <label>Are you sure about this?</label>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="delete_service_type"
                    small
                    outlined
                >
                    <v-icon class="mr-3" small>mdi-delete-outline</v-icon>delete
                </v-btn>
                <v-btn
                    color="primary"
                    class="pl-8 pr-8"
                    dark
                    small
                    @click="cancel"
                >
                    <v-icon class="mr-3" small>mdi-window-close</v-icon>cancel
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    table_header: [
        { text: 'ID' },
        { text: 'Service Type' },
        { text: 'Action(s)' }
    ],
    search: null,
    dialog_add: false,
    service_type: null,
    delete_dialog: false
  }),
  mounted () {
    this.get_service_types_fn()
  },
  created () {
  },
  computed: {
    ...mapGetters({
        // get_service_types:              'admin_service_types/get_service_types'
        get_to_delete:                      'admin_service_types/get_to_delete'
    }),
    get_service_types(){
        return this.$store.getters['admin_service_types/get_service_types'](this.search)
    }
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async get_service_types_fn(){
        await this.$axios.get('admin/service_types/get_service_types')
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('admin_service_types/set_service_types', data.data)
            }
        })
    },
    clear(){
        this.dialog_add = false
        this.service_type = null
    },
    add_service_type(){
        this.dialog_add = true
    },
    async save_service_type(){
        await this.$axios.post('admin/service_types/add_service_type', {service_type: this.service_type})
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.clear()
                this.get_service_types_fn()
            }else{
                this.showSnackBar(data.message)
            }
        })
    },
    cancel(){
        this.delete_dialog = false
    },
    delete_item(id){
        this.$store.dispatch('admin_service_types/set_to_delete', id)
        this.delete_dialog = true
    },
    async delete_service_type(){
        await this.$axios.delete(`admin/service_types/delete_item/${this.get_to_delete}`)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.get_service_types_fn()
                this.cancel()
            }
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>