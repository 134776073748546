<template>
  <v-container fluid class="mt-5 flex">
    <div
      v-if="ismainloading"
    >
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="article"
          v-for="index in 10"
          :key="index"
        ></v-skeleton-loader>
      </v-col>
    </div>
    <!-- <ListItems
      :setup="suppliersetup"
      :items="getsupplierlists"
      :dditem1="getservicetypes"
      :dditem2="getcountry"
      @searchsupplier="searchsupplier"
      @sendemail="sendemail"
    /> -->
    <div v-else>
      <div v-if="get_user_info.info.has_supplier_directory > 1 || get_user_info.info.access_id > 1">
        <v-card
          class="mx-auto"
        >
            <v-toolbar
              text
              elevation="0"
              style="background: #062a82;"
            >
              <v-toolbar-title style="color: white;">
                <v-icon class="mr-2" style="color: white;">
                  mdi-store
                </v-icon>
                Supplier Directory
              </v-toolbar-title>
            </v-toolbar>
            <div>
              <v-col
                cols="12"
                md="12"
                lg="12"
                sm="12"
                class="pl-5 pr-5 mt-5"
              >
                <v-row>
                  <v-autocomplete
                    v-model="s.service_type"
                    :items="getservicetypes"
                    label="service type"
                    item-value="id"
                    item-text="service_type"
                    outlined
                    dense
                    chips
                    small-chips
                    return-object
                  >
                  </v-autocomplete>
                  
                  <v-autocomplete
                    v-model="s.county"
                    :items="getcountry"
                    label="city or county"
                    item-value="phy_country"
                    item-text="phy_country"
                    outlined
                    dense
                    chips
                    small-chips
                    return-object
                  >
                  </v-autocomplete>
                  <v-btn
                    text
                    @click="searchsupplier"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </div>
            <v-list two-line>
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="300"
                  type="list-item-three-line"
                  v-show="isloading"
                ></v-skeleton-loader>
                <div
                  v-if="!isloading"
                >
                  <v-list-item-group
                    active-class="pink--text"
                    class="flex"
                  >
                  <div
                    v-if="getsupplierlists.length === 0"
                    class="text-center"
                  >
                    <label>I'm sorry there's no result for <strong>{{r.service_type.service_type}} in {{r.county}}</strong></label>
                    <v-btn
                      text
                      style="display: block;"
                      block
                      class="mt-3"
                      @click="s_again"
                    >
                      <v-icon>mdi-magnify</v-icon>search again
                    </v-btn>
                  </div>
                  <template 
                    v-for="(item, itemindex) in getsupplierlists"
                  >
                      <v-list-item :key="itemindex" :ripple="false">
                        <v-list-item-content>
                        <v-list-item-title v-text="item.company_name"></v-list-item-title>

                          <v-list-item-subtitle
                            class="text--primary"
                          >
                            <label
                              style="display: block;"
                            ><v-icon small class="me-3">mdi-at</v-icon>{{item.email}}</label>
                            <!-- <a 
                              :href="`${item.website}`" 
                              target="_blank"
                              style="display: block;"
                            ><v-icon small class="mr-3">mdi-web</v-icon>{{item.website}}</a> -->
                            <label 
                              style="display: block;"
                            ><v-icon small class="mr-3">mdi-web</v-icon>{{item.website}}</label>
                            <label
                              style="display:block;"
                              v-if="item.phone_number === null"
                            >
                              <v-icon small class="mr-3">mdi-phone</v-icon> not specified
                            </label>
                            <label
                              v-else
                              style="display: block;"
                              @click="click_tracker(item)"
                            >
                              <v-icon small class="mr-3">mdi-phone</v-icon>
                              <label
                                v-if="item.is_phone_hidden"
                                style="cursor: pointer;"
                              >
                                {{ hide_details(item.phone_number) }}<v-icon class="ml-1" small>mdi-eye</v-icon>
                              </label>
                              <label
                                v-else
                                style="cursor: pointer;"
                              >
                                {{ item.phone_number }}
                              </label>
                            </label>
                          </v-list-item-subtitle>

                          </v-list-item-content>

                          <v-list-item-content @click="sendemail(item)">
                            <v-list-item-title v-text="item.supplier_type.service_type"></v-list-item-title>
                            <v-list-item-subtitle
                                class="text--primary"
                            >
                                <v-icon>mdi-map-marker</v-icon>
                                {{item.phy_country}}<br/>
                                <v-icon @click="sendemail(item)" color="#0b4ef3;">mdi-email</v-icon> Send a message
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-list-item-action-text>
                              <v-rating
                                :value="item.average_rating"
                                color="amber"
                                dense
                                half-increments
                                readonly
                                size="18"
                              ></v-rating>

                              <div class="grey--text ms-1">
                                <label v-if="item.average_rating != null">{{item.average_rating}} Review(s)</label>
                                <label v-else>0 Review(s)</label>
                              </div>
                              <label
                                @click="report_issue_dialog(item)"
                                style="cursor: pointer;"
                              >
                                <v-icon small>mdi-alert-circle-outline</v-icon>
                                Report an issue
                              </label>
                            </v-list-item-action-text>
                          </v-list-item-action>
                      </v-list-item>
                  </template>
                  </v-list-item-group>
                </div>
            </v-list>
        </v-card>
      </div>
      <div v-else>Please Contact to support to enable this feature!</div>
    </div>
    <DialogComponent
      v-model="sendemaildialog"
      :value="sendemaildialog"
      :dialogvalue="sendemaildialogsetup"
      :childdata="getsendemailto"
      :user_info="get_user_info"
      @closedialog="closedialog"
      @sendemailsupplier="sendemailsupplier"
    />
    <v-row justify="center">
      <v-dialog
        max-width="420"
        v-model="report_dialog"
        persistent
      >
      <v-card>
          <v-card-title>
            <small style="fontSize: 16px;">Report an issue</small>
          </v-card-title>

          <v-card-subtitle>
            {{get_supplier_report.company_name}}
          </v-card-subtitle>

          <v-card-text>
            <v-textarea
              v-model="issue.description"
              outlined
              label="state your issue / description"
              dense
            >
            </v-textarea>
          </v-card-text>

          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closereport"
            text
          >
            close
          </v-btn>
          <v-btn
            color="primary"
            class="pl-15 pr-15"
            dark
            @click="send_issue_report"
          >
            Report
          </v-btn>
          </v-card-actions>
      </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import ListItems from '../../components/ListItems.vue'
import DialogComponent from '../../components/DialogComponent.vue'
export default {
  components: {
    // ListItems,
    DialogComponent
  },
  props: [
  ],
  data: () => ({
    ismainloading: true,
    url: null,
    suppliersetup: {
      supplierdirectory: true
    },
    sendemaildialog: false,
    sendemaildialogsetup: {
      suppliersendemail: true,
      label:            'send email',
      icon:             'mdi-email',
      iconclass:        'mr-2',
      textarea: [
        {
          id:           1,
          label:        'send email',
          hint:         'email content',
          icon:         'mdi-email'
        }
      ]
    },
    s: {
      service_type: null,
      county: null
    },
    r: {
      service_type: null,
      county: null
    },
    isloading: false,
    report_dialog: false,
    issue: {
      description: null
    }
  }),
  mounted () {
  },
  created () {
    this.getsupplierlist()
    this.url = process.env.VUE_APP_URL
  },
  computed: {
    ...mapGetters({
      get_user_info:                  'auth/getuser',
      getsupplierlists:               'supplierdirectory/getsupplierlist',
      getservicetypes:                'supplierdirectory/getservicetypes',
      getcountry:                     'supplierdirectory/getcountry',
      getsendemailto:                 'supplierdirectory/getsendemailto',
      get_supplier_report:            'supplierdirectory/get_supplier_report'
    })
  },
  methods: {
    ...mapActions({
        tracked_click:                'supplierdirectory/tracked_click_actions'
    }),
    hide_details(data){
      const number = data.substring(0, 4)
      const hide = data.replace(/./gi, '*')
      return number + hide
    },
    showSnackBar(message){
      this.$store.commit("auth/setMessage",
      {show: true, message: message},
      {root: 1})
    },
    async getsupplierlist(){
      await this.$axios.get('supplierdirectory/getSupplierList')
      .then(({data}) => {
        let d = []
        data.data.map(q => {
          d.push({
            is_phone_hidden: true,
            ...q
          })
        })
        this.$store.dispatch('supplierdirectory/setcountry', data.country)
        this.$store.dispatch('supplierdirectory/setsupplierlist', d)
        this.$store.dispatch('supplierdirectory/setservicetypes', data.service_types)
        this.ismainloading = false
      })
    },
    async searchsupplier(){
      this.isloading = true
      let tp = {
        service_type:       this.s.service_type === null ? null : this.s.service_type.id,
        phy_country:        this.s.county === null ? null : this.s.county.phy_country
      }
      await this.$axios.get('/supplierdirectory/sSupplierData/', tp)
      .then(({data}) => {
        this.r.service_type = data.service_type
        this.r.county = data.county
        this.$store.dispatch('supplierdirectory/setsupplierlist', data.data)
        this.isloading = false
      })
    },
    sendemail(data){
      this.$store.dispatch('supplierdirectory/setsendemailto', data)
      this.sendemaildialog = true
    },
    closedialog(){
      this.sendemaildialog = false
    },
    async sendemailsupplier(data){
      let tp = {
        message:          data.textfield[0].message,
        supplier_id:      this.getsendemailto.id,
        request_id:       0
      }
      await this.$axios.post('supplierdirectory/sendMessage', tp)
      .then(({data}) => {
        if(data.response){
          this.showSnackBar(data.message)
          this.closedialog()
          return
        }
        this.showSnackBar(data.message)
        return
      })
    },
    s_again(){
      this.s.service_type = null
      this.s.county = null
      this.getsupplierlist()
    },
    report_issue_dialog(data){
      this.$store.dispatch('supplierdirectory/set_supplier_report', data)
      this.report_dialog = true
    },
    closereport(){
      this.$store.dispatch('supplierdirectory/set_supplier_report', {})
      this.report_dialog = false
      this.issue = {}
    },
    async send_issue_report(){
      let tp = {
        description:  this.issue.description,
        supplier_id:  this.get_supplier_report.id
      }
      await this.$axios.post('supplierdirectory/report_issue', tp)
      .then(({data}) => {
        if(data.response){
          this.showSnackBar(data.message)
          this.closereport()
          return
        }
        this.showSnackBar(data.message)
        return
      })
    },
    async click_tracker(item) {
      // Process Click Tracker in this method
      await this.tracked_click({
        'users_id': this.get_user_info.id,
        'supplierprofile_id': item.id
      });     
      //console.log(this.get_user_info);
      return item.is_phone_hidden = false;
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>