<template>
    <div>
        <v-list>
            <v-list-group
                v-for="item in get_designtrackers.flooring_calculations"
                :key="item.title"
                v-model="item.active"
                no-action>
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title style="color: #0b4ef3;">
                            <v-badge
                                color="#0b4ef3"
                                bordered>
                                <v-icon slot="badge" v-if="item.disabled">mdi-exclamation-thick</v-icon>
                                <v-icon slot="badge" v-else>mdi-check-bold</v-icon>
                                <span slot="default"><strong>{{item.location}}</strong></span>
                            </v-badge>
                            <v-progress-linear                                
                                :value="compute_progress(item)"
                                striped
                                height="15"
                                color="#7b9ff9">
                                <template v-slot:default="{ value }">
                                    <strong>{{ value.toFixed(2) }}%</strong>
                                </template>
                            </v-progress-linear>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-data-table
                    :headers="headers"
                    :items="item.getchild"
                    item-key="task_name"
                    class="elevation-1 custom-table"
                    style="font-weight:700;"
                    :hide-default-footer="true"
                    disable-pagination
                    mobile-breakpoint="0">
                    <template v-slot:item="{item}">
                        <tr>
                            <td><v-checkbox v-model="item['is_done']" disabled></v-checkbox></td>
                            <td style="cursor: pointer; white-space: pre-line;"><small>{{item['location']}}</small></td>
                            <td style="cursor: pointer;"><small v-if="item['sqm'] == null">add a sqm</small><small v-else>{{item['sqm']}}</small></td>
                            <td style="cursor: pointer;"><small v-if="item['floor_type'] == null">add a floor_type</small><small v-else>{{item['floor_type']}}</small></td>
                            <td style="cursor: pointer;"><small v-if="item['price_per_sqm'] == null">add a price per sqm</small><small v-else>{{item['price_per_sqm']}}</small></td>
                            <td style="cursor: pointer;"><small v-if="item['total'] == null">add a total</small><small v-else>{{item['total']}}</small></td>
                            <td style="cursor: pointer;"><small v-if="item['supplier_id'] == null">add a supplier</small><small v-else>{{ item['supplier_id'] }}</small></td>
                            <td style="cursor: pointer;"><small v-if="item['notes'] == null">add a note</small><small v-else>{{item['notes']}}</small></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-list-group>
        </v-list>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data: () => ({
        headers: [
            { text: '' },
            { text: 'Location' },
            { text: 'SQM' },
            { text: 'Floor Type' },
            { text: 'Price Per SQM' },
            { text: 'Total' },
            { text: 'Supplier' },
            { text: 'Notes' },
        ]
    }),
    props: {
        project: Object,
        user_id: Number
    },
    computed: {
        ...mapGetters({
            get_designtrackers:     'designtracker/get_designtrackers_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_designtracker:        'designtracker/fetch_designtracker_action'
        }),
        compute_progress(item) {
            const total_items = item.getchild.length;
            const total_completed_items = item.getchild.filter(i => i.is_done == true).length;

            return (total_completed_items/total_items) * 100;
        }
    },
    async mounted() {
        await this.fetch_designtracker({
            project_id: this.project.id,
            user_id: this.user_id
        });
    },
    watch: {
        async project() {
            await this.fetch_designtracker({
                project_id: this.project.id,
                user_id: this.user_id
            });
        }
    }
}
</script>