<template>
    <div>
        <v-card
            class="v-card-border-top"
            elevation="10">
            <v-card-text class="no-gutters">
                <video controls oncontextmenu="return false;" ref="videos">
                    <source :src="process_video_url(video.url)" :type="video.mime"> 
                </video>
            </v-card-text>              
            <v-card-title class="no-gutters ml-3 mr-3">
                {{ video.name }}
                <v-spacer></v-spacer>             
                <!--<v-btn text x-small fab class="success mb-2" @click="view_video(video)">
                    <v-icon>mdi-eye</v-icon>
                </v-btn>-->
            </v-card-title>
            <v-divider></v-divider>       
            <!--<v-card-subtitle class="no-gutters pl-3 pt-2 pb-2 blue darken-1 white--text">
                <v-row no-gutters>
                    <v-col cols="1" class="pt-1 mr-5">
                        <v-avatar class="white--text" color="primary" size="32">RP</v-avatar>
                    </v-col>
                    <v-col cols="auto" class="no-gutters">
                        <div style="font-weight: bold;">{{ video.get_user.name }}</div>
                        <div style="font-size:12px;">Created: {{ video.created_at }}</div>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text class="no-gutters">
                <v-list three-line class="no-gutters">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="no-gutters">Description:</v-list-item-title>
                            <v-list-item-subtitle class="no-gutters">
                                {{ video.description }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>-->
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <div class="non-trashed-video-controls" v-if="video.deleted_at == null">
                    <v-btn text x-small fab color="primary" @click="edit_uploaded_video_archive(video)">
                        <v-icon small>mdi-pen</v-icon>
                    </v-btn>
                    <v-btn text x-small fab color="error" @click="delete_uploaded_video_archived(video)">
                        <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                </div>
                <div class="trashed-video-controls" v-else>
                    <v-btn text x-small fab color="success" @click="restore_uploaded_video_archive(video)">
                        <v-icon small>mdi-restore</v-icon>
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
        <VideoPlayerFullscreen
            :show_dialog="view_video_dialog"
            :video_archive_item="video_archive_item"
            @close_dialog="close_view_video_dialog()"></VideoPlayerFullscreen>

        <!-- Confirm Dialog-->
        <v-dialog
            width="50%"
            v-model="confirm_dialog"           
            persistent
            transition="dialog-bottom-transition">
            <v-card>
                <v-card-title>
                    <span>{{ confirm_dialog_title }}</span>
                    <v-spacer></v-spacer>
                    <v-btn text small fab @click="close_confirm_dialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="black--text mt-5">
                    {{ confirm_dialog_message }}
                </v-card-text>               
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text small width="150" class="mx-2" @click="close_confirm_dialog()">No</v-btn>
                    <v-btn color="error" width="150" class="white--text" small @click="execute_confirm_modal()">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- /Confirm Dialog -->
    </div>
</template>

<script>
import VideoPlayerFullscreen from './VideoPlayerFullscreen.vue'

export default {
    data: () => ({
        confirm_dialog: false,
        confirm_dialog_title: null,
        confirm_dialog_message: null,
        view_video_dialog: false,
        video_archive_item: {},
        APP_URL: process.env.VUE_APP_URL
    }),
    components: {
        VideoPlayerFullscreen
    },
    props: {
        video: Object        
    },
    methods: {
        view_video(item) {           
            this.view_video_dialog = true;
            this.video_archive_item = item;
        }, 
        close_view_video_dialog() {           
            this.view_video_dialog = false;
        },
        process_video_url(url) {           
            return this.APP_URL + url;
        },
        delete_uploaded_video_archived(item) {
            this.confirm_dialog_title = `Deleting the video archive "${item.name}."`;
            this.confirm_dialog_message = `Are you sure you want to delete this video archive?`;
            this.confirm_dialog = true;
            this.video_archive_item = item;
        },
        close_confirm_dialog() {
            this.confirm_dialog = false;
            this.confirm_dialog_title = null;
            this.confirm_dialog_message = null;           
        },
        execute_confirm_modal() {           
            if (this.video_archive_item.deleted_at == null) {
                // Delete
                this.$emit('delete_video', this.video_archive_item);
                this.close_confirm_dialog();
            } else {
                // Restore               
                this.$emit('restore_video', this.video_archive_item);
                this.close_confirm_dialog();
            }
        },
        edit_uploaded_video_archive(item) {
            this.$emit('edit_uploaded_video', item);
        },
        restore_uploaded_video_archive(item) {           
            this.confirm_dialog_title = `Restoring the video archive "${item.name}."`;
            this.confirm_dialog_message = `Are you sure you want to restore this video archive?`;
            this.confirm_dialog = true;
            this.video_archive_item = item;
        }
    },
    watch: {
        video() {
            this.$refs.videos.load();
        }
    }
}
</script>
<style lang="scss" scoped>
.v-card-border-top {
    border-top:5px solid #062a82;
}
video {
    object-fit: initial;
    width: 100%;
    height: 300px;   
}
.no-gutters {
    margin:0;
    padding:0;
}
</style>