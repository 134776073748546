<template>
  <v-container fluid class="mt-5 flex">
    <div align="center">
      <strong>page is under construction.</strong>
      <p>please be patient, we are doing our best to fix the issue / bug / finish maintenance</p>
      <v-btn 
          color="#0b4ef3" 
          dark 
          class="pl-16 pr-16" 
          rounded
          @click="$router.push({name: 'supplierprofile'})"
      >
          Home
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
  }),
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>