import Vue from "vue";

export default {
    namespaced: true,
    state: {
        video_archives: []
    },
    mutations: {
        set_video_archives_mutations(state, payload) {
            state.video_archives = [...payload];
        }
    },
    getters: {
        get_video_archives_getters: state => state.video_archives,
    },
    actions: {
        async fetch_video_archives_actions({commit}) {
            await Vue.axios.get(`video_archives/g_uploaded_video_archives`).then( ({data}) => {
                if ( data.response == false ) {
                    console.log(data.error);
                } else {
                    commit('set_video_archives_mutations', data.data);
                }
            })
        }
    }
}