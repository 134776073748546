export default {
    namespaced: true,
    state: {
        site_diaries: [],
        deleted_site_diaries: [],
        alternative_images: [],
        multiple_file_upload_updates: {
            status: 'in-progress',
            alt_images: [],
            length: 0
        }
    },
    mutations: {
        setSiteDiaryDataMutation(state, payload) {
            state.site_diaries = [...payload]
        },
        addNewlyAddedSiteDiaryEntryMutation(state, payload) {
            state.site_diaries.push(payload)
        },
        updateAlternativeImagesMutation(state, payload) {
            state.site_diaries.filter( (item) => {
                if ( item.id == payload.id ) {
                    item.alternative_images = payload.alternative_images
                }
            })
            state.alternative_images = []
        },
        addAlternativeImageMutation(state, payload) {
            state.alternative_images.push(payload)
        },
        update_sitediary_data_mutation(state, payload) {
            state.site_diaries.map( (item) => {
                if ( item.id == payload.id ) {
                    item.image = payload.image
                    item.log_name = payload.log_name
                    item.notes = payload.notes
                    item.site_diary_date = payload.site_diary_date
                    item.tags = payload.tags
                    item.alternative_images = payload.alternative_images                    
                }
            })
        },
        /** Process multiple upload of images */
        set_upload_setting_mutation(state, payload) {           
            state.multiple_file_upload_updates = payload
        },
        add_alternativeimage_tobe_process_mutation(state, payload) {
            state.multiple_file_upload_updates = payload            
        },
        set_alternativeimage_upload_to_done_mutation(state, payload) {
            state.multiple_file_upload_updates = payload
        },
        /** Update Site Diaries */
        delete_sitediary_data_mutation(state, payload) {
            const index = state.site_diaries.findIndex( (item) => item.id == payload.id )
            state.site_diaries.splice(index, 1)
            state.deleted_site_diaries.push(payload)
        },
        restore_sitediary_data_mutation(state, payload) {
            const index = state.deleted_site_diaries.findIndex( (item) => item.id == payload.id )
            state.deleted_site_diaries.splice(index, 1)
            state.site_diaries.push(payload)
        },
        set_deleted_sitediary_data_mutation(state, payload) {
            state.deleted_site_diaries = [...payload]
        }
    },
    getters: {
        getSiteDiaryDataGetter: state => state.site_diaries,
        getAlternativeImagesGetter: state => state.alternative_images,
        get_upload_setting_getter: state => state.multiple_file_upload_updates,
        get_deleted_sitediary_data_getter: state => state.deleted_site_diaries
    },
    actions: {
        setSiteDiaryDataAction({commit}, payload) {
            commit('setSiteDiaryDataMutation', payload)
        },
        addNewlyAddedSiteDiaryEntryAction({commit}, payload) {
            commit('addNewlyAddedSiteDiaryEntryMutation', payload)
        },
        updateAlternativeImagesAction({commit}, payload) {
            commit('addAlternativeImageMutation', payload)
        },
        addNewlyAddedSiteDiaryImagesAction({commit}, payload) {
            commit('updateAlternativeImagesMutation', payload)
        },
        update_sitediary_data_action({commit}, payload) {
            commit('update_sitediary_data_mutation', payload)
        },
        /** State for the uploaded file one at a time */
        set_upload_setting_action({commit}, payload) {
            /** File List Length, Number of files uploaded, Status */
            commit('set_upload_setting_mutation', payload)
        },
        add_alternativeimage_tobe_process_action({commit}, payload) {
            commit('add_alternativeimage_tobe_process_mutation', payload)
        },
        set_alternativeimage_upload_to_done_action({commit}, payload) {
            commit('set_alternativeimage_upload_to_done_mutation', payload)
        },
        deleted_sitediaries_data_action({commit}, payload) {
            commit('delete_sitediary_data_mutation', payload)
        },
        set_deleted_sitediary_data_action({commit}, payload) {
            commit('set_deleted_sitediary_data_mutation', payload)
        },
        restore_sitediary_data_action({commit}, payload) {
            commit('restore_sitediary_data_mutation', payload)
        }
    }
}