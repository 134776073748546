<template>
    <v-row justify="center">
        <v-dialog
            v-model="messagebox_dialog"
            persistent
            max-width="500"
            max-height="250">
            <v-card>
                <v-card-title class="error white--text">
                    <v-icon class="mr-2 white--text">mdi-cloud-alert</v-icon> Something is not right!
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-3">                   
                    <h2>
                        {{ messagebox.title }}
                    </h2>
                    <v-divider></v-divider>
                    <v-textarea                       
                        no-resize
                        rows="4"
                        class="mt-3"
                        v-model="messagebox.message">
                    </v-textarea>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="success"
                        @click="close_errormessage_dialog()">
                        Okay
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        messagebox_dialog: false,
        messagebox: {}
    }),
    computed: {
        ...mapGetters({
            get_open_messagebox_dialog: 'messagebox/get_open_messagebox_dialog_getter',
            get_messagebox_details:     'messagebox/get_messagebox_details_getter'
        })
    },
    methods: {
        ...mapActions({
            set_messagebox_details: 'messagebox/set_messagebox_details_action',
            open_messagebox_dialog: 'messagebox/open_messagebox_dialog_action',
            close_messagebox_dialog: 'messagebox/close_messagebox_dialog_action'
        }),
        close_errormessage_dialog() {
            this.messagebox_dialog = false;
        }
    },
    mounted() {
        this.close_messagebox_dialog();        
    },
    watch: {
        get_open_messagebox_dialog() {            
            this.messagebox_dialog = this.get_open_messagebox_dialog;            
        },
        get_messagebox_details() {
            this.messagebox = this.get_messagebox_details;
        }
    }
}
</script>