import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        data: []
    },
    mutations: {
        set_data(state, payload){
            state.data = [...payload]
        }
    },
    getters: {
        get_data : state => (value) => {
            if(value === null || value === ''){
                return state.data
            }
            return state.data.filter(q => {
                return q.get_client_information.name.toLowerCase().includes(value)
            })
        }
    },
    actions: {
        async set_data({commit}){
            await Vue.axios.get('/supplier/from_form/get_data')
            .then(({data}) => {
                commit('set_data', data.data)
            })
        }
    }
}