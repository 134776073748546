<template>
    <v-container fluid class="mt-5 flex">
        <div v-if="getuser.project_id == null" align="center">
            <strong>You need to create a project</strong>
            <p>Kindly click the Proceed button below to create a project, and we can continue to add entries</p>
            <v-btn 
                color="#0b4ef3;" 
                dark 
                class="pl-16 pr-16" 
                rounded
                @click="$router.push({name: 'projectplanner'})">
                Proceed
            </v-btn>
        </div>
        <div v-else>
            <small>* The templates below are intended as a guide only.</small>
            <v-card>
                <v-card-title style="background: #062a82">
                    <v-icon class="mr-2" style="color: white;">{{decisionssetup.icon}}</v-icon>
                    <small style="color: white;">{{decisionssetup.title}} <v-icon color="white" style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon></small>
                    <v-spacer></v-spacer>
                    <v-btn
                        :class="decisionssetup.button.class"
                        :dark="decisionssetup.button.dark"
                        :text="decisionssetup.button.text"
                        :color="decisionssetup.button.color"
                        rounded
                        @click="addDecision()">
                        <v-icon
                        :class="decisionssetup.button.classicon">
                        {{decisionssetup.button.icon}}
                        </v-icon>{{decisionssetup.button.label}}</v-btn>
                </v-card-title>
                <v-card-text>
                    <DecisionList
                        :add_new_decision="add_new_decision"></DecisionList>
                </v-card-text>
            </v-card>
        </div>
        
        <DialogComponent
            v-model="adddecisiondialog"
            :value="adddecisiondialog"
            :dialogvalue="dialogdecisionvalue"
            :ddvalues="getdecisionsparent"
            @closedialog="closedialog"
            @savenewdecision="savenewdecision">
        </DialogComponent>
        <v-dialog
          persistent
          v-model="show_tutorial"
          width="1000"
        >
          <v-card>
            <v-card-title>
              Tutorial
              <v-spacer></v-spacer>
              <v-icon
                small
                color="red"
                @click="show_tutorial = false;"
              >
                mdi-close
              </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <vue-pdf-embed source="decisions.pdf" />
          </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogComponent from '@/components/DialogComponent.vue';
import DecisionList from './components/DecisionList.vue';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
export default {
    data: () => ({
        show_tutorial: false,
        decisionssetup: {
            title:    'Decisions',
            icon:     'mdi-arrow-decision',
            button: {
                id:           1,
                icon:         'mdi-plus',
                label:        'add entry',
                dark:         true,
                action:       true,
                text:         false,
                class:        'pl-16 pr-16',
                color:        '#0b4ef3',
                classicon:    'mr-2'
            }
        },
        adddecisiondialog: false,
        dialogdecisionvalue: {
            adddecisions:     true,
            label:            'add item',
            icon:             'mdi-plus',
            iconclass:        'mr-1',
            dropdown: [
                {
                id:       0,
                label:    'select parent',
                hint:     'select parent',
                icon:     'mdi-tooltip-text-outline'
                }
            ],
            textfield: [
                {
                id:       1,
                label:    'item name',
                hint:     'item name',
                icon:     'mdi-tooltip-text-outline'
                }
            ],
        },
        add_new_decision: false
    }),
    components: {
        DialogComponent,
        DecisionList,
        VuePdfEmbed
    },
    computed: {
        ...mapGetters({
            getuser:                    'auth/getuser',
            getproject:                 'auth/getproject',
            getdecisionsparent:         'decisions/getdecisionsparent'
        })
    },
    methods: {
        showSnackBar(message){
            this.$store.commit("auth/setMessage",
            {show: true, message: message},
            {root: 1})
        },
        addDecision() {
            this.adddecisiondialog = true;
        },
        closedialog() {
            //this.addnotesdialog = false
            //this.dialogwarning = false
            this.adddecisiondialog = false
        },
        async savenewdecision(data) {
            console.log(data);
            let tp = {
                name:       data.textfield[0].name,
                item:       data.dropdown
            };

            await this.$axios.post(`decisions/adecisioni/${this.getproject.id}`, tp)
            .then(({data}) => {
                if (data.response) {
                    this.add_new_decision = true;
                    this.closedialog(); 
                } else {
                    this.showSnackBar(data.message)               
                }
            });

            this.add_new_decision = false;
        }
    }
}
</script>