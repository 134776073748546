<template>
    <v-container fluid class="v-container-padding">
        <v-card>
            <v-card-title>
                <strong>Video Archived</strong>
                <v-spacer></v-spacer>
                <v-btn 
                    color="primary"
                    class="white--text pl-12 pr-12"
                    @click="upload_video_archive()">
                    <v-icon small>mdi-upload</v-icon>
                    <span>Upload</span>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <VideoArchivedDisplay
                    @edit_uploaded_video="update_uploaded_video($event)"></VideoArchivedDisplay>
            </v-card-text>
        </v-card>
        <VideoArchivedAddEditModal
            :show_dialog="show_upload_dialog"
            :is_edit="is_edit_video_archive"
            :video_item="video_archive_edit_item"
            @close_dialog="close_upload_dialog"
            ></VideoArchivedAddEditModal>
    </v-container>
</template>

<script>
import VideoArchivedDisplay from './modules/components/VideoArchivedDisplay.vue';
import VideoArchivedAddEditModal from './modules/components/VideoArchivedAddEditModal.vue';

export default {
    data: () => ({
        show_upload_dialog: false,
        is_edit_video_archive: false,
        video_archive_edit_item: {}
    }),
    components: {
        VideoArchivedDisplay,
        VideoArchivedAddEditModal
    },
    methods: {
        upload_video_archive() {
            this.show_upload_dialog = true;
            this.is_edit_video_archive = false;
        },
        close_upload_dialog() {
            this.show_upload_dialog = false;
            this.is_edit_video_archive = false;
        },
        update_uploaded_video(event) {
            this.video_archive_edit_item = event;
            this.show_upload_dialog = true;
            this.is_edit_video_archive = true;
        }
    }
}
</script>
<style lang="scss" scoped>
.v-container-padding {
    padding-bottom: 100px;
}
</style>