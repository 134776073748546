import Vue from "vue";

export default {
    namespaced: true,
    state: {
        todos: [],
        dailylogs: [],
        filemanager: [],
        snags: [],
        decisions: [],
        fff: [],
        appliances: [],
        fc: [],
        budgetmanager: [],
        dashboard: {
            users_id: null,
            project_id: null,
            planning_approved_date: null,
            broke_ground_date: null,
        }
    },
    mutations: {
        set_todos_mutation(state, payload) {
            state.todos = payload;
        },
        set_dailylogs_mutation(state, payload) {
            state.dailylogs = payload;
        },
        set_snags_mutation(state, payload) {
            state.snags = payload;
        },
        set_filemanager_mutation(state, payload) {
            state.filemanager = payload;
        },
        set_decisions_mutation(state, payload) {
            state.decisions = payload;
        },
        set_designtracker_fff_mutation(state, payload) {
            state.fff = payload;
        },
        set_designtracker_appliances_mutation(state, payload) {
            state.appliances = payload;
        },
        set_designtracker_fc_mutation(state, payload) {
            state.fc = payload;
        },
        set_budgetmanager_mutation(state, payload) {
            state.budgetmanager = payload;
        },
        set_dashboard_mutation(state, payload) {
            state.dashboard = payload;
        }
    },
    getters: {
        get_todos_getter: state => {           
            return {               
                series: [state.todos.pending_count ?? 0, state.todos.completed_count ?? 0],
                chart_options: {
                    labels: ['Pending', 'Completed'],
                    chart: {
                        type: 'donut'
                    },
                    fill: {
                        colors: ['#2396f1', '#53af5c']
                    },
                    dataLabels: {
                        enabled: true
                    },
                    legend: {
                        position: 'bottom'
                    },
                }
            }
        },
        get_sitediary_getter: state => {            
            return {
                series: state.dailylogs.series ?? [],
                chart_options: {
                    labels: state.dailylogs.labels ?? [],
                    chart: {
                        type: 'polarArea'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    yaxis: {
                        show: false
                    },
                    legend: {
                        position: 'bottom'
                    },
                    toolbar: {
                        show: true,
                        tools: {
                            zoom: true
                        }
                    }
                }
            }
        },
        get_snags_getter: state => {
            return {               
                series: [state.snags.count_pending ?? 0, state.snags.count_completed ?? 0],
                chart_options: {
                    labels: ['Pending', 'Completed'],
                    chart: {
                        type: 'donut'
                    },
                    fill: {
                        colors: ['#2396f1', '#53af5c']
                    },
                    dataLabels: {
                        enabled: true
                    },
                    legend: {
                        position: 'bottom'
                    },
                }
            }
        },
        get_filemanager_getter: state => {
            return {
                series: state.filemanager.series ?? [],
                chart_options: {
                    labels: state.filemanager.categories ?? [],
                    chart: {
                        type: 'polarArea'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    yaxis: {
                        show: false
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }
        },
        get_decisions_getter: state => {            
            return {
                series: state.decisions.series ?? [{name: 'series-1', data: []}],
                chart_options: {
                    chart: {
                        type: 'bar',                        
                        stacked: true,
                        stackType: '100%',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top'
                            }
                        }
                    },
                    xaxis: {
                        categories: state.decisions.categories ?? [],
                        labels: {
                            rotate: 0,
                            hideOverlappingLabels: false,
                            formatter: (value) => {
                                if (state.decisions.categories != null && state.decisions.categories.length > 0) {
                                    return value.replace(/&/gi, ",&").split(',');
                                }
                            },
                            style: {
                                fontSize: '10px',
                                fontWeight: 600,
                                cssClass: 'apexcharts-label'
                            }
                        }
                    },
                    colors: ['#A04191', '#DB4848'],
                    fill: {
                        opacity: 1,
                        type: 'solid',
                    },
                    legend: {
                        horizontalAlign: 'left',
                        position: 'bottom',
                        offsetY: 30,
                        height: 75,
                        inverseOrder: true,
                        labels: {
                            useSeriesColors: true
                        },
                        fontSize: '16px',
                        fontWeight: 400,
                        fontFamily: 'Roboto'
                    }
                }
            }
        },
        get_designtracker_fff_getter: state => {
            return {
                series: state.fff.series ?? [{name: 'series-1', data: []}],
                chart_options: {
                    chart: {
                        type: 'bar',                        
                        stacked: true,
                        stackType: '100%',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top'
                            }
                        }
                    },
                    xaxis: {
                        categories: state.fff.categories ?? [],
                        labels: {
                            rotate: 0,
                            hideOverlappingLabels: false,
                            formatter: (value) => {
                                if (value == 'Bedroom 1' || value == 'Bedroom 2' || value == 'Bedroom 3' || value == 'Bedroom 4') {
                                    return value;
                                }
                                else if (value == 'Utility / Laundry / Boot Room') {
                                    return value.replace(/\/ /gi, "/,").split(',');
                                }
                                else if (value == 'En Suite') {
                                    return value;
                                }
                                else if (value == 'Walk In Wardrobe') {
                                    return value.replace(/In /gi, "In,").split(',');
                                }
                                else if (state.fff.categories != null && state.fff.categories.length > 0) {
                                    return value.split(' ');
                                }
                            },
                            style: {
                                fontSize: '10px',
                                fontWeight: 600,
                                cssClass: 'apexcharts-label'
                            }
                        }
                    },
                    colors: ['#211EC1', '#04B900'],
                    fill: {
                        opacity: 1,
                        type: 'solid',
                    },
                    legend: {
                        horizontalAlign: 'left',
                        position: 'bottom',
                        offsetY: 30,
                        height: 75,
                        inverseOrder: true,
                        labels: {
                            useSeriesColors: true
                        },
                        fontSize: '16px',
                        fontWeight: 400,
                        fontFamily: 'Roboto'
                    }
                }
            }
        },
        get_designtracker_appliances_getter: state => {
            return {
                series: state.appliances.series ?? [{name: 'series-1', data: []}],
                chart_options: {
                    chart: {
                        type: 'bar',                        
                        stacked: true,
                        stackType: '100%',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top'
                            }
                        }
                    },
                    xaxis: {
                        categories: state.appliances.categories ?? [],
                        labels: {
                            rotate: 0,
                            hideOverlappingLabels: false,
                            formatter: (value) => {
                                if (state.appliances.categories != null && state.appliances.categories.length > 0) {
                                    return value.split(' ');
                                }
                            },
                            style: {
                                fontSize: '10px',
                                fontWeight: 600,
                                cssClass: 'apexcharts-label'
                            }
                        }
                    },
                    colors: ['#211EC1', '#04B900'],
                    fill: {
                        opacity: 1,
                        type: 'solid',
                    },
                    legend: {
                        horizontalAlign: 'left',
                        position: 'bottom',
                        offsetY: 30,
                        height: 75,
                        inverseOrder: true,
                        labels: {
                            useSeriesColors: true
                        },
                        fontSize: '16px',
                        fontWeight: 400,
                        fontFamily: 'Roboto'
                    }
                }
            }
        },
        get_designtracker_fc_getter: state => {
            return {
                series: state.fc.series ?? [{name: 'series-1', data: []}],
                chart_options: {
                    chart: {
                        type: 'bar',                        
                        stacked: true,
                        stackType: '100%',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top'
                            }
                        }
                    },
                    xaxis: {
                        categories: state.fc.categories ?? [],
                        labels: {
                            rotate: 0,
                            hideOverlappingLabels: false,
                            formatter: (value) => {
                                if (value == 'Bedroom 1' || value == 'Bedroom 2' || value == 'Bedroom 3' || value == 'Bedroom 4') {
                                    return value;
                                }
                                else if (value == 'Utility / Laundry') {
                                    return value.replace(/\/ /gi, "/,").split(',');
                                }
                                else if (value == 'En Suite') {
                                    return value;
                                }
                                else if (value == 'Walk In Wardrobe') {
                                    return value.replace(/In /gi, "In,").split(',');
                                }
                                else if (state.fc.categories != null && state.fc.categories.length > 0) {
                                    return value.split(' ');
                                }
                            },
                            style: {
                                fontSize: '10px',
                                fontWeight: 600,
                                cssClass: 'apexcharts-label'
                            }
                        }
                    },
                    colors: ['#211EC1', '#04B900'],
                    fill: {
                        opacity: 1,
                        type: 'solid',
                    },
                    legend: {
                        horizontalAlign: 'left',
                        position: 'bottom',
                        offsetY: 30,
                        height: 75,
                        inverseOrder: true,
                        labels: {
                            useSeriesColors: true
                        },
                        fontSize: '16px',
                        fontWeight: 400,
                        fontFamily: 'Roboto'
                    }
                }
            }
        },
        get_budgetmanager_getter: state => {       
            return {
                sqm: state.budgetmanager.sqm ?? 0,
                money_saved: state.budgetmanager.money_saved ?? 0,
                money_saved_series: state.budgetmanager.money_saved_percent == undefined ? [0] : [state.budgetmanager.money_saved_percent],
                money_saved_options: {
                    chart: {
                        type: 'radialBar',
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#F1F1F1",
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -2,
                                    fontSize: '32px',
                                    color: '#026400'
                                },
                            },
                            hollow: {
                                margin: 15,
                                size: "35%"
                            }
                        },
                    },
                    fill: {
                        colors: ['#04B900']
                    },
                },
                time_saved: state.budgetmanager.time_saved ?? 0,
                time_saved_series: state.budgetmanager.time_saved_percent == undefined ? [0] : [state.budgetmanager.time_saved_percent],
                time_saved_options: {
                    chart: {
                        type: 'radialBar',
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#F1F1F1",
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -2,
                                    fontSize: '32px',
                                    color: '#DE9300'
                                }
                            },
                            hollow: {
                                margin: 15,
                                size: "35%"
                            }
                        },
                    },
                    fill: {
                        colors: ['#EDB900']
                    },
                },
                budget_series: state.budgetmanager.series ?? [{name: 'series-1', data: []}],
                budget_options: {
                    chart: {
                        type: 'bar',
                        toolbar: {
                            tools: {
                                download: false
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            distributed: true,
                            dataLabels: {
                                position: 'top',
                            }
                        }
                    },
                    xaxis: {
                        categories: state.budgetmanager.categories ?? [],
                        labels: {
                            rotate: 0,
                            hideOverlappingLabels: false,
                            formatter: (value) => {
                                if (state.budgetmanager.categories != null && state.budgetmanager.categories.length > 0) {
                                    return value.split(' ');
                                }
                            },
                            style: {
                                fontSize: '10px',
                                fontWeight: 600,
                                cssClass: 'apexcharts-label'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: (value) => {
                                if (value != null) {
                                    return '€' + value.toLocaleString();
                                }
                            }
                        }
                    },
                    dataLabels: {
                        formatter: (value) => {
                            if (value != null) {
                                return '€' + value.toLocaleString();
                            }
                        }
                    },
                    fill: {
                        colors: ['#4A86E8', '#8E7CC3', '#EA4335', '#FBBC04', '#34A853', '#EA9999'],
                        opacity: 1,
                        type: 'solid',
                    },
                    legend: {
                        show: false
                    }
                }
            }
        },
        get_dashboard_getter: state => {
            return state.dashboard;
        }
    },
    actions: {
        fetch_reminders_action({commit}, payload) {           
            Vue.axios.get(`/dashboard/get_reminders_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {
                if (data.response == true) {               
                    commit('set_todos_mutation', data.data);
                }
            });
        },
        fetch_sitediary_action({commit}, payload) {           
            Vue.axios.get(`/dashboard/get_sitediary_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {                
                commit('set_dailylogs_mutation', data.data);
            });
        },
        fetch_snags_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_snags_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {               
                commit('set_snags_mutation', data.data);
            });
        },
        fetch_filemanager_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_filemanager_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {
                commit('set_filemanager_mutation', data.data);
            });
        },
        fetch_decisions_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_decision_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {
                commit('set_decisions_mutation', data.data);
            });
        },
        fetch_designtracker_fff_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_designtracker_fff_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {
                commit('set_designtracker_fff_mutation', data.data);
            })
        },
        fetch_designtracker_appliances_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_designtracker_appliance_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {
                commit('set_designtracker_appliances_mutation', data.data);
            })
        },
        fetch_designtracker_fc_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_designtracker_fc_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {
                commit('set_designtracker_fc_mutation', data.data);
            })
        },
        fetch_budgetmanager_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_budgetmanager_data/${payload.users_id}/${payload.project_id}/${payload.template_id}`)
            .then( ({data}) => {
                commit('set_budgetmanager_mutation', data.data);
            })
        },
        fetch_dashboard_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_dashboard_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {
                let new_data = {
                    users_id: (data.data) ? data.data.users_id : null,
                    project_id: (data.data) ? data.data.project_id : null,
                    planning_approved_date: (data.data) ? data.data.planning_approved_date : null,
                    broke_ground_date: (data.data) ? data.data.broke_ground_date : null,
                }
                commit('set_dashboard_mutation', new_data);
            })
        },
        set_dashboard_action({ commit }, payload) {
            commit('set_dashboard_mutation', payload)
            Vue.axios.post('/dashboard/create_dashboard_data', payload)
        }
    }
}