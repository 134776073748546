export default {
    namespaced: true,
    state: {
        suppliers: []
    },
    mutations: {
        set_suppliers(state, payload) {
            state.suppliers = [...payload]
        },
    },
    getters: {
        get_suppliers(state) {
            return state.suppliers
        }
    },
    actions: {
        set_suppliers({ commit }, payload) {
            commit('set_suppliers', payload)
        },
    }
}