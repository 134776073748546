<template>
    <v-row justify="center">
        <v-dialog
            :value="value"
            persistent
            width="560"
        >
        <v-card>
            <v-card-title>
                <label
                    style="font-weight: 500; font-size: medium;"
                >
                    {{ get_to_edit_item.name }}
                </label>
            </v-card-title>
            <v-card-subtitle>
                Edit this item
            </v-card-subtitle>
            <v-card-text
                v-if="Object.keys(get_to_edit_item).length > 0"
            >
                <v-row>
                    <v-col
                        cols="12"
                        lg="12"
                        sm="12"
                    >
                        <label
                            style="font-weight: 500; font-size: small;"
                        >Item Name</label>
                        <v-text-field
                            outlined
                            dense
                            v-model="get_to_edit_item.name"
                            prepend-icon="mdi-database-plus"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="12"
                        sm="12"
                    >
                        <label
                            style="font-weight: 500; font-size: small;"
                        >Location <a :href="get_to_edit_item.product_url" target="_blank">URL</a></label>
                        <v-text-field
                            outlined
                            dense
                            v-model="get_to_edit_item.product_url"
                            prepend-icon="mdi-database-plus"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="12"
                        sm="12"
                    >
                        <label
                            style="font-weight: 500; font-size: small;"
                        >Image</label>
                        <v-file-input
                            truncate-length="15"
                            v-model="a.image"
                            outlined
                            dense
                        ></v-file-input>
                    </v-col>
                    <v-row>
                        <v-col
                            cols="12"
                            lg="12"
                            sm="12"
                        >
                            <label
                                style="font-weight: 500; font-size: small;"
                            >
                                Rating
                            </label>
                            <v-rating
                                length="5"
                                v-model="get_to_edit_item.rating"
                                half-increments
                            ></v-rating>
                        </v-col>
                        <v-col
                            cols="4"
                            lg="4"
                            sm="4"
                        >
                            <label
                                style="font-weight: 500; font-size: small;"
                            >
                                (zł) Original Price
                            </label>
                            <v-text-field
                                v-model="get_to_edit_item.original_price"
                                outlined
                                dense
                                @change="check_rate"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="4"
                            lg="4"
                            sm="4"
                        >
                            <label
                                style="font-weight: 500; font-size: small;"
                            >
                                % Markup
                            </label>
                            <v-text-field
                                v-model="get_to_edit_item.markup"
                                outlined
                                dense
                                prepend-icon="mdi-percent-outline"
                                @change="check_rate"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="4"
                            lg="4"
                            sm="4"
                        >
                            <label
                                style="font-weight: 500; font-size: small;"
                            >
                                Price
                            </label>
                            <v-text-field
                                v-model="get_to_edit_item.price"
                                outlined
                                dense
                                prepend-icon="mdi-currency-eur"
                                :disabled="true"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-col
                        cols="12"
                        lg="12"
                        sm="12"
                    >
                        <label
                            style="font-weight: 500; font-size: small;"
                        >
                            Location
                        </label>
                        <v-text-field
                            dense
                            outlined
                            v-model="get_to_edit_item.location"
                            prepend-icon="mdi-database-plus"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="12"
                        sm="12"
                    >
                        <v-combobox
                            chips
                            v-model="a.chips"
                            clearable
                            label="Add Tags"
                            prepend-icon="mdi-database-plus"
                            dense
                            outlined
                            @change="press_enter"
                        ></v-combobox>
                        <v-chip
                            v-for="(chip, chipindex) in JSON.parse(get_to_edit_item.chip_data)"
                            :key="chipindex"
                            class="mr-2 mt-2"
                            close
                            @click:close="remove_item_chip(chip, chipindex)"
                        >
                            {{ chip }}
                        </v-chip>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="12"
                        sm="12"
                    >
                        <label
                            style="font-weight: 500; font-size: small;"
                        >Description</label>
                        <wysiwyg
                            v-model="get_to_edit_item.description"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="close_dialog()"
            >
                Close
            </v-btn>
            <v-btn
                outlined
                class="pl-10 pr-10"
                rounded
                @click="save_data"
            >
                Save
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  components: {
  },
  props: [
    'value'
  ],
  data () {
    return {
        a: {
            chips: null,
            image: null
        }
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_to_edit_item:           'admin_shop/get_to_edit_item',
        get_selected_category:      'admin_shop/get_selected_category'
    })
  },
  methods: {
    close_dialog(){
        this.$emit('close_dialog')
        this.$store.dispatch('admin_shop/get_items_via_cat_id', this.get_selected_category)
    },
    remove_item_chip(item, index){
        if(index == 0){
            this.$store.commit("auth/setMessage", 
            {show: true, message: "You cannot remove Category!"}, 
            {root: 1})
            return
        }
        const chip_data = JSON.parse(this.get_to_edit_item.chip_data)
        chip_data.splice(index, 1)
        this.$store.dispatch('admin_shop/update_edit_chip_item', JSON.stringify(chip_data))
    },
    press_enter(){
        const data = JSON.parse(this.get_to_edit_item.chip_data)
        data.push(this.a.chips)
        this.$store.dispatch('admin_shop/update_edit_chip_item', JSON.stringify(data))
    },
    async check_rate(){
        if(this.get_to_edit_item.original_price != null && this.get_to_edit_item.markup != null){
            await axios.get('https://open.er-api.com/v6/latest/PLN')
            .then(({data}) => {
                let price_raw = this.get_to_edit_item.original_price * data.rates["EUR"]
                let markup = "0."+this.get_to_edit_item.markup
                let price = price_raw * markup
                let new_price = parseFloat(price_raw) + parseFloat(price)
                this.$store.dispatch('admin_shop/set_update_edit_price', new_price)
            })
        }
    },
    async save_data(){
        let formData = new FormData();
        this.a.image != null ? formData.append('file', this.a.image) : "";
        formData.append('name', this.get_to_edit_item.name);
        formData.append('product_url', this.get_to_edit_item.product_url)
        formData.append('rating', this.get_to_edit_item.rating)
        formData.append('price', this.get_to_edit_item.price)
        formData.append('markup', this.get_to_edit_item.markup)
        formData.append('original_price', this.get_to_edit_item.original_price)
        formData.append('location', this.get_to_edit_item.location)
        formData.append('description', this.get_to_edit_item.description)
        formData.append('chip_data', this.get_to_edit_item.chip_data)
        formData.append('category_name', this.get_selected_category.name)
        // await this.$axios.post(`/shop/update_item/${this.get_to_edit_item.id}`, this.get_to_edit_item)
        await this.$axios.post(`/shop/update_item/${this.get_to_edit_item.id}`, formData)
        .then(({data}) => {
            if(data.response){
                this.close_dialog()
                return
            }
            this.$store.commit("auth/setMessage", 
            {show: true, message: data.message}, 
            {root: 1})
            return
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>