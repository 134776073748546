<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="12"
                class="flex"
                v-if="!showeditprofile"
            >
                <v-card>
                    <v-img
                        :src="`${url}frontend/images/${getuser.company.logo}`"
                        style="max-height: 350px;"
                    >
                    <v-card-title class="white--text mt-8">
                        <v-avatar size="56">
                        <img
                            alt="user"
                            :src="`${url}frontend/images/${getuser.company.logo}`"
                        >
                        </v-avatar>
                        <p class="ml-3" style="color: black;">
                            <v-btn
                                dense
                                outlined
                                rounded          
                                color="white" 
                            >
                                {{getuser.company.company_name}}
                            </v-btn>
                        </p>
                        <v-spacer></v-spacer>
                        <label
                            @click="showeditprofile = !showeditprofile"
                        >
                            <v-btn
                                dense
                                dark
                            >
                                <v-icon class="mr-1">mdi-pen</v-icon>edit profile
                            </v-btn>
                        </label>
                    </v-card-title>
                    </v-img>

                    <v-card-text>
                    <div class="font-weight-bold ml-8 mb-2">
                        Company Profile
                    </div>

                        <v-timeline
                            group
                            dense
                            style="width: 50%;"
                        >
                            <v-timeline-item
                                small
                                fill-dot
                            >
                                <div>
                                    <div class="font-weight-normal">
                                        <v-alert
                                            :value="true"
                                            color="primary"
                                        >
                                            <v-icon class="mr-3 white--text">mdi-domain</v-icon><small class="white--text">Company Name {{getuser.company.company_name}}</small>
                                        </v-alert>
                                    </div>
                                </div>
                            </v-timeline-item>
                            <v-timeline-item
                                small
                                fill-dot
                                color="black"
                            >
                                <div>
                                    <div class="font-weight-normal">
                                        <v-alert
                                            :value="true"
                                        >
                                            <v-icon class="mr-3">mdi-at</v-icon><small>{{getuser.company.email}}</small>
                                        </v-alert>
                                    </div>
                                </div>
                            </v-timeline-item>
                            <v-timeline-item
                                small
                                fill-dot
                            >
                                <div>
                                    <div class="font-weight-normal">
                                        <v-alert
                                            :value="true"
                                            color="primary"
                                        >
                                            <v-icon class="mr-3 white--text">mdi-web</v-icon><small class="white--text">{{getuser.company.website}}</small>
                                        </v-alert>
                                    </div>
                                </div>
                            </v-timeline-item>
                            <v-timeline-item
                                small
                                fill-dot
                                color="black"
                            >
                                <div>
                                    <div class="font-weight-normal">
                                        <v-alert
                                            :value="true"
                                        >
                                            <v-btn
                                                dense
                                                rounded
                                                outlined
                                            >
                                                {{getuser.company.supplier_type.service_type}}
                                            </v-btn>
                                        </v-alert>
                                    </div>
                                </div>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col
              :cols="showeditprofile ? 12 : 4"
            >
                <!--<label @click="showeditprofile = !showeditprofile" style="cursor: pointer"><v-icon class="mr-2">mdi-pen</v-icon>Edit Profile</label>-->
                <v-expand-transition>
                    <div
                      v-if="showeditprofile"
                    >
                        <v-stepper
                            v-model="e6"
                            v-for="(item, itemindex) in supplier_profile_edit"
                            :key="itemindex"
                            dense
                        >
                            <v-stepper-step
                                :step="item.step"
                            >
                                {{item.title}}
                                <small>{{item.hint}}</small>
                            </v-stepper-step>

                            <v-stepper-content :step="item.step">
                                <v-card class="mb-5">
                                    <v-card-text>
                                        <div
                                            v-for="(input, inputindex) in item.items"
                                            :key="inputindex"
                                        >
                                            <v-file-input
                                                v-if="input.ttype == 'file_input'"
                                                v-model="supplierprofile.logo"
                                                accept="image/*"
                                                outlined
                                                dense
                                                :prepend-icon="input.icon"
                                                :label="input.label"
                                            ></v-file-input>
                                            <v-text-field
                                                v-if="input.ttype == 'input'"
                                                :label="input.label == 'Company Name' ? getuser.company.company_name : input.label == 'website' ? getuser.company.website : input.label"
                                                :hint="input.hint"
                                                :prepend-icon="input.icon"
                                                :placeholder="input.label == 'Company Name' ? getuser.company.company_name : input.label == 'Account Number' ? getuser.company.account_number : input.label == 'website' ? getuser.company.website : input.label == 'phone' ? getuser.company.phone_number : input.label == 'fax' ? getuser.company.fax_number : ''"
                                                :type="input.type"
                                                outlined
                                                dense
                                                v-model="supplierprofile.input[input.id]"
                                            >
                                            </v-text-field>
                                            <v-autocomplete
                                                v-if="input.ttype == 'dd'"
                                                v-model="supplierprofile.dd"
                                                outlined
                                                small-chips
                                                dense
                                                :items="getservice_types"
                                                item-text="service_type"
                                                item-value="id"
                                                :label="input.label"
                                                return-object
                                                :prepend-icon="input.icon"
                                            >
                                            </v-autocomplete>
                                            <v-autocomplete
                                                v-if="input.ttype == 'ddcountry'"
                                                v-model="supplierprofile.ddcountry"
                                                outlined
                                                small-chips
                                                dense
                                                :items="getcountry_list"
                                                item-text="country"
                                                item-value="iso3"
                                                :label="input.label"
                                                return-object
                                                :prepend-icon="input.icon"
                                                @change="getcities"
                                            >
                                            </v-autocomplete>
                                            <v-autocomplete
                                                v-if="input.ttype == 'ddcities'"
                                                v-model="supplierprofile.ddcounty"
                                                outlined
                                                small-chips
                                                dense
                                                :items="getcitieslist"
                                                item-text="name"
                                                item-value="id"
                                                :label="input.label"
                                                :prepend-icon="input.icon"
                                                return-object
                                            >
                                            </v-autocomplete>

                                            <v-autocomplete
                                                v-if="input.ttype == 'postalddcountry'"
                                                v-model="supplierprofile.postalddcountry"
                                                outlined
                                                small-chips
                                                dense
                                                :items="getcountry_list"
                                                item-text="country"
                                                item-value="iso3"
                                                :label="input.label"
                                                return-object
                                                :prepend-icon="input.icon"
                                                @change="getcitiespostal"
                                            >
                                            </v-autocomplete>
                                            <v-autocomplete
                                                v-if="input.ttype == 'postalddcities'"
                                                v-model="supplierprofile.postalddcities"
                                                outlined
                                                small-chips
                                                dense
                                                :items="getpostalcities"
                                                item-text="name"
                                                item-value="id"
                                                :label="input.label"
                                                :prepend-icon="input.icon"
                                                return-object
                                            >
                                            </v-autocomplete>
                                            <v-autocomplete
                                                v-if="input.ttype == 'phycountry'"
                                                v-model="supplierprofile.phycountry"
                                                outlined
                                                small-chips
                                                dense
                                                :items="getcountry_list"
                                                item-text="country"
                                                item-value="iso3"
                                                :label="input.label"
                                                :prepend-icon="input.icon"
                                                @change="getcitiesphy"
                                                return-object
                                            >
                                            </v-autocomplete>
                                            <v-autocomplete
                                                v-if="input.ttype == 'phycities'"
                                                v-model="supplierprofile.phycities"
                                                outlined
                                                small-chips
                                                dense
                                                :items="getphycities"
                                                item-text="name"
                                                item-value="id"
                                                :label="input.label"
                                                :prepend-icon="input.icon"
                                                return-object
                                            >
                                            </v-autocomplete>
                                            <v-autocomplete
                                                v-if="input.ttype == 'currency'"
                                                v-model="supplierprofile.currency"
                                                outlined
                                                small-chips
                                                dense
                                                :items="getcurrencies"
                                                item-text="currencyCode"
                                                item-value="currencyCode"
                                                :label="input.label"
                                                :prepend-icon="input.icon"
                                                return-object
                                            >
                                            </v-autocomplete>
                                            <v-textarea
                                                v-if="input.ttype == 'textarea'"
                                                v-model="supplierprofile.payment_terms"
                                                :prepend-icon="input.icon"
                                                :label="input.label"
                                                :hint="input.hint"
                                                rows="3"
                                                outlined
                                            ></v-textarea>
                                            <v-textarea
                                                v-if="input.ttype == 'internal_notes'"
                                                v-model="supplierprofile.internal_notes"
                                                :prepend-icon="input.icon"
                                                :label="input.label"
                                                :hint="input.hint"
                                                rows="3"
                                                outlined
                                            ></v-textarea>
                                            <label v-if="input.ttype == 'dateyear'">Financial Year</label>
                                            <v-date-picker
                                                v-if="input.ttype == 'dateyear'"
                                                v-model="supplierprofile.financial_year"
                                            >
                                            </v-date-picker>
                                        </div>
                                    </v-card-text>
                                </v-card>
                                <div
                                    style="float: right"
                                >
                                    <v-btn 
                                        text
                                        @click="checkifback"
                                    >
                                        cancel
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="checkstep"
                                        class="pl-12 pr-12"
                                    >
                                        Continue
                                    </v-btn>
                                </div>
                            </v-stepper-content>
                        </v-stepper>
                    </div>
                </v-expand-transition>
            </v-col>
            <v-col
                :cols="showeditprofile ? 8 : 12"
                transition="slide-x-reverse-transition"
            >
                <!--<Unquoted/>-->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
//import Unquoted from '../requests/RequestComponent.vue'
export default {
  components: {
    //Unquoted
  },
  props: [
  ],
  data: () => ({
    e6: 1,
    showeditprofile: false,
    supplier_profile_edit: [
        {
            title:  'Supplier Information',
            hint:   'company name | account number | website',
            step:    1,
            items: [
                {
                    ttype:          'file_input',
                    label:          'company logo',
                    hint:           'company logo',
                    icon:           'mdi-file-image',
                },
                {
                    id:             1,
                    ttype:          'input',
                    label:          'Company Name',
                    hint:           'company name',
                    type:           'text',
                    icon:           'mdi-domain'
                },
                {
                    id:             2,
                    ttype:          'input',
                    label:          'Account Number',
                    hint:           'account number',
                    type:           'number',
                    icon:           'mdi-account'
                },
                {
                    id:             3,
                    ttype:          'input',
                    label:          'website',
                    hint:           'website',
                    type:           'text',
                    icon:           'mdi-link-variant'
                },
                {
                    id:             4,
                    ttype:          'dd',
                    label:          'supplier type',
                    hint:           'supplier type',
                    icon:           'mdi-arrow-down-drop-circle'
                }
            ]
        },
        // {
        //     title:      'Location Setup',
        //     hint:       'address | zipcode',
        //     step:       2,
        //     items: [
        //         {
        //             id:             5,
        //             ttype:          'ddcountry',
        //             label:          'country you operate',
        //             hint:           'country',
        //             icon:           'mdi-arrow-down-drop-circle'
        //         },
        //         {
        //             id:             6,
        //             ttype:          'ddcities',
        //             label:          'select counties | provinces | state you operate in',
        //             hint:           'select counties | provinces | state you operate in',
        //             icon:           'mdi-arrow-down-drop-circle'
        //         },
        //         {
        //             id:             7,
        //             ttype:          'input',
        //             label:          'phone',
        //             hint:           'phone',
        //             type:           'number',
        //             icon:           'mdi-phone'
        //         },
        //         {
        //             id:             8,
        //             ttype:          'input',
        //             label:          'fax number',
        //             hint:           'fax number',
        //             type:           'number',
        //             icon:           'mdi-fax'
        //         }
        //     ]
        // },
        // {
        //     title:      'Postal Location Setup',
        //     hint:       'postal location setup',
        //     step:       3,
        //     items: [
        //         {
        //             id:             9,
        //             ttype:          'input',
        //             label:          'postal address',
        //             hint:           'postal address',
        //             type:           'text',
        //             icon:           'mdi-map-marker'
        //         },
        //         {
        //             id:             10,
        //             ttype:          'input',
        //             label:          'city',
        //             hint:           'city',
        //             type:           'text',
        //             icon:           'mdi-tooltip-text-outline'
        //         },
        //         {
        //             id:             11,
        //             ttype:          'postalddcountry',
        //             label:          'country',
        //             icon:           'mdi-arrow-down-drop-circle'
        //         },
        //         {
        //             id:             12,
        //             ttype:          'postalddcities',
        //             label:          'select county | province | state',
        //             hint:           'select county | province | state',
        //             icon:           'mdi-arrow-down-drop-circle'
        //         },
        //         {
        //             id:             13,
        //             ttype:          'input',
        //             label:          'zipcode',
        //             hint:           'zipcode',
        //             icon:           'mdi-tooltip-text-outline',
        //             type:           'number'
        //         }
        //     ]
        // },
        // {
        //     title:      'Physical Location Setup',
        //     hint:       'physical location setup',
        //     step:       4,
        //     items: [
        //         {
        //             id:             14,
        //             ttype:          'input',
        //             label:          'address',
        //             hint:           'address',
        //             type:           'text',
        //             icon:           'mdi-tooltip-text-outline',
        //         },
        //         {
        //             id:             15,
        //             ttype:          'input',
        //             label:          'state',
        //             hint:           'state',
        //             type:           'text',
        //             icon:           'mdi-tooltip-text-outline'
        //         },
        //         {
        //             id:             16,
        //             ttype:          'input',
        //             label:          'city',
        //             hint:           'city',
        //             type:           'text',
        //             icon:           'mdi-tooltip-text-outline'
        //         },
        //         {
        //             id:             17,
        //             ttype:          'phycountry',
        //             label:          'country',
        //             icon:           'mdi-arrow-down-drop-circle'
        //         },
        //         {
        //             id:             18,
        //             ttype:          'phycities',
        //             label:          'select county | province | state',
        //             hint:           'select county | province | state',
        //             icon:           'mdi-arrow-down-drop-circle'
        //         }
        //     ]
        // },
        // {
        //     title:          'Financial Details Setup',
        //     hint:           'financial details setup',
        //     step:           5,
        //     items: [
        //         {
        //             id:             19,
        //             ttype:          'currency',
        //             label:          'currency',
        //             hint:           'currency',
        //             icon:           'mdi-arrow-down-drop-circle'
        //         },
        //         {
        //             id:             20,
        //             ttype:          'textarea',
        //             label:          'default payment terms',
        //             hint:           'default payment terms',
        //             icon:           'mdi-tooltip-text-outline',
        //             type:           'text'
        //         },
        //         {
        //             id:             21,
        //             ttype:          'input',
        //             label:          'default tax name',
        //             hint:           'default tax name',
        //             icon:           'mdi-tooltip-text-outline',
        //             type:           'text'
        //         },
        //         {
        //             id:             22,
        //             ttype:          'input',
        //             label:          'tax %',
        //             hint:           'tax %',
        //             icon:           'mdi-tooltip-text-outline',
        //             type:           'number'
        //         },
        //         {
        //             id:             23,
        //             ttype:          'input',
        //             label:          'tax #',
        //             hint:           'tax #',
        //             icon:           'mdi-tooltip-text-outline',
        //             type:           'number'
        //         },
        //         {
        //             id:             24,
        //             ttype:          'input',
        //             label:          'bank account #',
        //             hint:           'bank account #',
        //             icon:           'mdi-tooltip-text-outline',
        //             type:           'number'
        //         },
        //         {
        //             id:             25,
        //             ttype:          'dateyear'
        //         },
        //         {
        //             id:             26,
        //             ttype:          'internal_notes',
        //             label:          'internal notes',
        //             hint:           'internal notes',
        //             icon:           'mdi-tooltip-text-outline'
        //         }
        //     ]
        // }
    ],
    supplierprofile: {
        logo: null,
        input: [],
        dd: null,
        ddcountry: null,
        ddcounty: null,
        postalddcountry: null,
        postalddcities: null,
        phycountry: null,
        phycities: null,
        currency: null,
        payment_terms: null,
        financial_year: null,
        internal_notes: null
    },
    url: null
  }),
  mounted () {
  },
  created () {
    this.fetchsuppliertype()
    this.url = process.env.VUE_APP_URL
  },
  computed: {
    ...mapGetters({
        getuser:                'auth/getuser',
        getservice_types:       'supplierprofile/getservice_types',
        getcountry_list:        'supplierprofile/getcountry_list',
        getcitieslist:          'supplierprofile/getcities',
        getpostalcities:        'supplierprofile/getpostalcities',
        getphycities:           'supplierprofile/getphycities',
        getcurrencies:          'currencies/getcurrencies'
    })
  },
  methods: {
    async fetchsuppliertype(){
      await this.$axios.get('misc/servicetypes')
      .then(({data}) => {
        if(data.response){
          this.$store.dispatch('supplierprofile/setservice_types', data.data)
        }
      })
      await this.$axios.get('misc/getCountries')
      .then(({data}) => {
        this.$store.dispatch('supplierprofile/setcountry_list', data.data)
      })
    },
    async checkstep(){
      if(this.e6 > 0){
        // console.log('send me to api', this.supplierprofile)
        const formData = new FormData()
        formData.append('logo', this.supplierprofile.logo)
        formData.append('company_name', this.supplierprofile.input[1])
        formData.append('account_number', this.supplierprofile.input[2])
        formData.append('website', this.supplierprofile.input[3])
        formData.append('currency', this.supplierprofile.currency == null ? '' : this.supplierprofile.currency.currencyCode)
        formData.append('financial_year', this.supplierprofile.financial_year)
        formData.append('phone', this.supplierprofile.input[7])
        formData.append('fax_number', this.supplierprofile.input[8])
        formData.append('postal_address', this.supplierprofile.input[9])
        formData.append('postal_city', this.supplierprofile.input[10])
        formData.append('postal_zipcode', this.supplierprofile.input[13])
        formData.append('physical_addrss', this.supplierprofile.input[14])
        formData.append('physical_state', this.supplierprofile.input[15])
        formData.append('physical_city', this.supplierprofile.input[16])
        formData.append('payment_terms', this.supplierprofile.payment_terms)
        formData.append('tax_name', this.supplierprofile.input[21])
        formData.append('tax_percent', this.supplierprofile.input[22])
        formData.append('tax_number', this.supplierprofile.input[23])
        formData.append('bank_account_number', this.supplierprofile.input[24])
        formData.append('internal_notes', this.supplierprofile.internal_notes)
        formData.append('supplier_type', this.supplierprofile.dd == null ? '' : this.supplierprofile.dd.id)
        formData.append('country', this.supplierprofile.ddcountry == null ? '' : this.supplierprofile.ddcountry.country)
        formData.append('postal_country', this.supplierprofile.postalddcountry == null ? '' : this.supplierprofile.postalddcountry.country)
        formData.append('postal_county', this.supplierprofile.postalddcities == null ? '' : this.supplierprofile.postalddcities.name)
        formData.append('phy_country', this.supplierprofile.phycountry == null ? '' : this.supplierprofile.phycountry.country)
        formData.append('phy_county', this.supplierprofile.phycities == null ? '' : this.supplierprofile.phycities.name)
        await this.$axios.post(`supplier/requests/edit_profile/${this.getuser.id}`, formData)
        .then(({data}) => {
            if(data.response){
                this.e6 = 1
                this.showeditprofile = false
                this.$store.dispatch('auth/setcompanyprofile', data.supplier)
            }
        })
      }else{
        this.e6++
      }
    },
    checkifback(){
        // if(this.e6 <= 1){
        //     this.e6 = 1
        // }else{
        //     this.e6--
        // }
        this.showeditprofile = false
    },
    getcities(){
      let counties = []
      this.supplierprofile.ddcountry.cities.forEach((q, i) => {
          counties.push({
            id:     i + 1,
            name:   q
          })
      })
      this.$store.dispatch('supplierprofile/setcities', counties)
    },
    getcitiespostal(){
        let counties = []
        this.supplierprofile.postalddcountry.cities.forEach((q, i) => {
            counties.push({
                id:     i + 1,
                name:   q
            })
        })
        this.$store.dispatch('supplierprofile/setpostalcities', counties)
    },
    getcitiesphy(){
        let counties = []
        this.supplierprofile.phycountry.cities.forEach((q, i) => {
            counties.push({
                id:     i + 1,
                name:   q
            })
        })
        this.$store.dispatch('supplierprofile/setphycities', counties)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>