<template>
    <v-container fluid class="mt-5 flex">
        <v-row
            justify="center"
            class="mt-5"
        >
            <v-card
                width="75%"
            >
                <v-card-title>
                    <small>
                        <v-icon>mdi-asterisk</v-icon>Plan upload(s) of client
                    </small>
                    <v-spacer/>
                    <small
                        style="font-size: 14px;"
                    >
                        Total: {{ get_plan_upload_data.length }}
                    </small>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="table_header"
                        :items="get_plan_upload_data"
                        item-key="id"
                    >
                        <template v-slot:item="{ item, expand, isExpanded }" >
                            <tr
                                style="cursor:pointer;"
                                @click="expand(!isExpanded)"
                            >
                                <td>
                                    {{ item.get_user.name }}
                                </td>
                                <td>
                                    {{ item.address }}, {{ item.county }}, {{ item.town }}, {{ item.zipcode }}
                                </td>
                                <td>
                                    {{ item.email }}
                                </td>
                                <td>
                                    {{ item.created_at }}
                                </td>
                            </tr>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                <v-row>
                                    <v-col
                                        cols="6"
                                    >
                                        <label>Service Type</label><br/>
                                        <v-chip
                                            v-for="(chip, chipindex) in item.service_type"
                                            :key="chipindex"
                                            class="mr-2 mb-2"
                                        >{{ chip.service_type }}</v-chip>
                                    </v-col>
                                    <v-col
                                        cols="6"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="6"
                                                v-for="(pdf, pdfindex) in item.file_path"
                                                :key="pdfindex"
                                            >
                                                <v-card
                                                    elevation="0"
                                                    width="100%"
                                                    class="mt-2"
                                                >
                                                    <v-card-title>
                                                        <small
                                                            style="font-size: 14px;"
                                                        >{{ item.name }}</small>
                                                    </v-card-title>
                                                    <v-card-text class="text-center">
                                                        <img :src="`https://media.istockphoto.com/vectors/icon-major-file-format-vector-icon-illustration-vector-id1298834280?k=20&m=1298834280&s=612x612&w=0&h=SxEbyHKi18H1XpPpKKzoppWgMM3x0tc3veN5e6wl7Y8=`" style="max-width:125px; max-height:125px;"/>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer/>
                                                        <v-btn
                                                            block
                                                            text
                                                            @click="view_pdf(pdf)"
                                                        >
                                                            View
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </td>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data () {
    return {
        table_header: [
            {
                text: "Client Name"
            },
            {
                text: "Address"
            },
            {
                text: "Email"
            },
            {
                text:  "Created at"
            }
        ],
        img_url: process.env.VUE_APP_URL
    }
  },
  mounted () {
  },
  async created () {
    await this.get_plan_uploads()
  },
  computed: {
    ...mapGetters({
        get_plan_upload_data:           'admin_plan_upload/get_plan_upload_data'
    })
  },
  methods: {
    async get_plan_uploads(){
        await this.$axios.get('/admin/plan_upload/g_plan_upload')
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('admin_plan_upload/set_plan_upload_data', data.data)
            }
        })
    },
    view_pdf(data){
        window.open(this.img_url + data)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>