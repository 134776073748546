<template>
    <div>
        <v-text-field
            v-model="searchpdf"                     
            prepend-inner-icon="mdi-magnify"
            placeholder="search by video name | description here..."
            label="search by video name | description here...">
        </v-text-field>   
        <v-data-table
            :headers="headers"
            :items="get_pdf_files.pdf" 
            :sort-by="sortBy"
            :sort-desc="sortDesc"          
            :search="searchpdf">  
            <template v-slot:item="{ item }" >
                <tr>
                    <td>{{ item.name }}</td>
                    <!--<td>{{ item.description }}</td>-->
                    <td>{{ item.get_user.name }}</td>
                    <td class="text-center">
                        <div>
                            <v-icon color="red">mdi-file-pdf-box</v-icon>
                            <v-btn text small
                                @click="open_pdf(item.file_url)">
                                View
                            </v-btn>
                        </div>
                    </td>
                    <td class="text-center">{{ item.created_at }}</td>
                </tr>
            </template>
        </v-data-table>
        <v-row justify="center">
            <v-dialog
                width="25%"
                v-model="training_files_lock"
                scrollable
                persistent>
                <v-card>
                    <v-card-title>
                        <v-icon color="error">mdi-lock</v-icon>
                        Helper Training Files - Locked
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="mt-5 black--text">Please upgrade your account to access this feature!</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>                        
                        <v-btn                           
                            text
                            color="error"
                            @click="training_files_lock = false">
                            Okay
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { UserAccess } from '../modules/UserAccess';

export default {
    data: () => ({
        training_files_lock: false,
        searchpdf: '',
        toggle_none: 0,
        sortBy: 'updated_at',
        sortDesc: true,
        APP_URL: process.env.VUE_APP_URL,
        headers: [
            {
                text: 'Title',
                align: 'start',
                sortable: true,
                filterable: true,
                value: 'name',
            }, 
            /*{
                text: 'Description',
                align: 'start',
                sortable: true,
                filterable: true,               
                value: 'description',
            },*/
            {
                text: 'Uploaded By',
                align: 'start',
                sortable: true,
                filterable: true,
                value: 'get_user.name',
            },
            {
                text: 'File(s)',
                align: 'center',
                sortable: false,
                value: 'file_url',
            }, 
            {
                text: 'Created',
                align: 'center',
                sortable: true,
                value: 'created_at',
            }
        ],
        access_tobe_granted: ['Premium Tier', 'Standard Tier']
    }),
    components: {

    },
    computed: {
        ...mapGetters({
            get_pdf_files           :    'helper_training/get_uploaded_files_getters',
            get_user                :    'auth/getuser'
        })
    },
    methods: {
        ...mapActions({
            fetch_files_by_types    :   'helper_training/fetch_files_by_types'
        }),
        open_pdf(file_url) {
            if ( this.granting_user_access() == false ) {
                // trigger a dialog box
                this.training_files_lock = true;
            } else {
                window.open(this.APP_URL + file_url, '_blank');
            }
        },
        granting_user_access() {
            let userAccess = new UserAccess(this.get_user.info.access_id, this.access_tobe_granted);           
            return userAccess.get_access_grant();
        }
    },
    watch: {

    },
    mounted() {
        this.fetch_files_by_types('pdf');
    }
}

</script>