import Vue from "vue";

export default {
    namespaced: true,
    state: {
        /** Additional training materials can be added in here. */
        login_user: [],
        uploaded_files: {}
    },
    mutations: {
        set_uploaded_files_mutations(state, payload) {
            const objectKeys = Object.keys(payload);
            objectKeys.map( key => {
                Vue.set(state.uploaded_files, key, [...payload[key]]);
            });            
        }
    },
    getters: {
        get_uploaded_files_getters: state => state.uploaded_files        
    },
    actions: {
        async fetch_files_by_types({commit}, payload) {
            await Vue.axios.get(`helper_training/g_uploaded_files/${payload}`).then( ({data}) => {
                commit('set_uploaded_files_mutations', data.data);
            });           
        }
    }
}