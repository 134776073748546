import Vue from "vue"

export default {
    namespaced: true,
    state: {
        suppliers: [],
        to_delete_item: {},
        counties: [],
        supplier_type: []
    },
    mutations: {
        set_suppliers(state, payload){
            state.suppliers = []
            state.suppliers = [...payload]
        },
        set_to_delete_item(state, payload){
            state.to_delete_item = payload
        },
        set_counties(state, payload){
            state.counties = [...payload]
        },
        set_supplier_type(state, payload){
            state.supplier_type = [...payload]
        }
    },
    getters: {
        get_suppliers : state => value => {
            if(value === null || value === ''){
                return state.suppliers
            }
            const data = state.suppliers.filter(q => {
                if(q.email === null || q.email === ''){
                    return q.company_name.toLowerCase().includes(value)
                }else{
                    return q.email.toLowerCase().includes(value)
                    || q.company_name.toLowerCase().includes(value)
                }
            })
            // const timer = undefined
            if(data.length === 0){
                setTimeout(() => {
                    Vue.axios.get(`admin/suppliers/search/${value}`)
                    .then(({data}) => {
                        if(data.response){
                            state.suppliers = [...data.data]
                        }
                    })
                }, 500)
            }
            return data
        },
        get_to_delete_item : state => state.to_delete_item,
        get_counties : state => state.counties,
        get_supplier_type : state => state.supplier_type
    },
    actions: {
        set_suppliers({commit}, payload){
            commit('set_suppliers', payload)
        },
        set_to_delete_item({commit}, payload){
            commit('set_to_delete_item', payload)
        },
        async fetch_counties({commit}){
            await Vue.axios.get('counties')
            .then(({data}) => {
                commit('set_counties', data.data)
            })
        },
        async fetch_supplier_type({commit}){
            await Vue.axios.get('admin/service_types/get_service_types')
            .then(({data}) => {
                commit('set_supplier_type', data.data)
            })
        }
    }
}