export default {
    namespaced: true,
    state: {
        tips: [],
        suggested_tips: []
    },
    mutations: {
        set_tips(state, payload) {
            state.tips = [...payload]
        },
        set_suggested_tips(state, payload) {
            state.suggested_tips = [...payload]
        },
    },
    getters: {
        get_tips: state => value => {
            if (value === null || value === '') {
                return state.tips
            }
            return state.tips.filter(q => {
                return q.description.toLowerCase().includes(value)
            })
        },
        get_suggested_tips: state => value => {
            if (value === null || value === '') {
                return state.suggested_tips
            }
            return state.suggested_tips.filter(q => {
                return q.description.toLowerCase().includes(value)
            })
        },
    },
    actions: {
        set_tips({ commit }, payload) {
            commit('set_tips', payload)
        },
        set_suggested_tips({ commit }, payload) {
            commit('set_suggested_tips', payload)
        },
    }
}