export default {
    namespaced: true,
    state: {
        counties: [],
        service_types: []
    },
    mutations: {
        set_counties(state, payload){
            state.counties = [...payload]
        },
        set_service_types(state, payload){
            state.service_types = [...payload]
        }
    },
    getters: {
        get_counties : state => state.counties,
        get_service_types : state => state.service_types
    },
    actions: {
        set_counties({commit}, payload){
            commit('set_counties', payload)
        },
        set_service_types({commit}, payload){
            commit('set_service_types', payload)
        }
    }
}