<template>
    <v-container fluid class="mt-5 flex">
        <div v-if="getuser.project_id == null" align="center">
            <strong>You need to create a project</strong>
            <p>Kindly click the Proceed button below to create a project, and we can continue to add entries</p>
            <v-btn color="#0b4ef3" dark class="pl-16 pr-16" rounded @click="$router.push({ name: 'projectplannerv2' })">
                Proceed
            </v-btn>
        </div>
        <div v-else>
            <v-card class="pb-10">
                <v-card-title style="background: #062a82;">
                    <v-icon class="mr-2" style="color: white;"></v-icon>
                    <small style="color: white;">
                        Reminders
                        <!-- <v-icon color="white" style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon> -->
                    </small>
                    <v-spacer></v-spacer>
                    <v-btn class="pl-16 pr-16 white--text" color="#0b4ef3" rounded @click="add_reminder_dialog = true">
                        <v-icon class="mr-2">
                            mdi-plus
                        </v-icon>
                        add
                    </v-btn>
                </v-card-title>
                <v-row class="px-10 py-10">
                    <v-col cols="12" lg="6" md="6">
                        <v-card-title>
                            <div style="font-weight:600;font-size:20px;">Ongoing</div>
                            <div class="ml-1" style="font-size:16px;color:#676767;">({{ get_reminders_data(false).length }})</div>
                        </v-card-title>
                        <v-card-text>
                            <v-row align="center" dense v-for="(item, index) in get_reminders_data(false)" :key="index" class="mb-1" style="color:black;">
                                <v-col cols="2" lg="1" md="1">
                                    <v-checkbox
                                        v-model="item.is_completed"
                                        @click="complete_reminder(item)">
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="10" lg="11" md="11">{{ item.todo }}</v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <v-card-title>
                            <div style="font-weight:700;font-size:20px;color:#026400;">Completed</div>
                            <div class="ml-1" style="font-size:16px;color:#676767;">({{ get_reminders_data(true).length }})</div>
                        </v-card-title>
                        <v-card-text>
                            <v-row align="center" dense v-for="(item, index) in get_reminders_data(true)" :key="index" class="mb-1" style="color:grey;">
                                <v-col cols="2" lg="1" md="1">
                                    <v-checkbox
                                        v-model="item.is_completed"
                                        @click="uncomplete_reminder(item)">
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="10" lg="11" md="11"><s>{{ item.todo }}</s></v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <AddRemindereDialog
            :open_add_reminder_dialog="add_reminder_dialog"
            @close_add_reminder_dialog="evt_close_add_reminder_dialog()"/>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AddRemindereDialog from '../home/components/AddRemindereDialog.vue'
export default {
    components: {
        AddRemindereDialog
    },
    props: [
    ],
    data: () => ({
        add_reminder_dialog: false,
    }),
    async mounted() {

    },
    created() {
        if (this.getproject.id != null) {
            this.fetch_all_reminders_data({
                users_id: this.getuser.id,
                project_id: this.getproject.id
            });
        }
    },
    computed: {
        ...mapGetters({
            getuser:            'auth/getuser',
            getproject:         'auth/getproject',
            get_reminders_data: 'reminders/get_reminders_data_getter'
        })
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit("auth/setMessage",
                { show: true, message: message },
                { root: 1 })
        },
        ...mapActions({
            fetch_all_reminders_data:   'reminders/fetch_all_reminders_data_action',
            set_complete_reminder:      'reminders/set_complete_reminder_action',
            set_uncomplete_reminder:    'reminders/set_uncomplete_reminder_action'
        }),
        complete_reminder(item) {
            this.set_complete_reminder(item);
        },
        uncomplete_reminder(item) {
            this.set_uncomplete_reminder(item);
        },
        evt_close_add_reminder_dialog() {
            this.add_reminder_dialog = false;
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
:deep(.v-input--checkbox), :deep(.v-input__slot) {
    margin-top:0px;
    padding-top:0px;
    margin-bottom:0px;
}

:deep(.v-messages) {
    min-height:0px;
}
</style>