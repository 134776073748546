<template>
    <div>
        <v-list class="pb-16">
            <v-list-group
                v-for="item in get_dt_fc"
                :key="item.title"
                v-model="item.active"
                no-action
                :disabled="userAccessParent(item)">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title style="color: #0b4ef3;">
                            <v-badge
                                :color="userAccessParent(item) == true ? 'warning' : '#0b4ef3'"
                                bordered>
                                <v-icon slot="badge" v-if="userAccessParent(item) == true">mdi-exclamation-thick</v-icon>
                                <v-icon slot="badge" v-else>mdi-check-bold</v-icon>
                                <span slot="default"><strong>{{item.location}}</strong></span>
                            </v-badge>
                            <v-progress-linear
                                :buffer-value="100"
                                :value="item_percentage(item)"
                                striped
                                height="15"
                                color="#7b9ff9">
                                <template v-slot:default="{ value }">
                                    <strong>{{ value.toFixed(2) }}%</strong>
                                </template>
                            </v-progress-linear>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-data-table
                    :headers="header"
                    :items="item.getchild"
                    item-key="task_name"
                    class="elevation-1 custom-table"
                    style="font-weight:700;"
                    :hide-default-footer="true"
                    disable-pagination
                    mobile-breakpoint="0">
                    <template v-slot:item="{item}">
                        <tr>
                            <td><v-checkbox v-model="item['is_done']" @change="mark_dtfc(item)"></v-checkbox></td>
                            <td @click="edit_fc(item)" style="cursor: pointer; white-space: pre-line;"><small>{{item['location']}}</small></td>
                            <td @click="edit_fc(item)" style="cursor: pointer;"><small v-if="item['sqm'] == null">add a sqm</small><small v-else>{{item['sqm']}}</small></td>
                            <td @click="edit_fc(item)" style="cursor: pointer;"><small v-if="item['floor_type'] == null">add a floor_type</small><small v-else>{{item['floor_type']}}</small></td>
                            <td @click="edit_fc(item)" style="cursor: pointer;"><small v-if="item['price_per_sqm'] == null">add a price per sqm</small><small v-else>{{item['price_per_sqm']}}</small></td>
                            <td @click="edit_fc(item)" style="cursor: pointer;"><small v-if="item['total'] == null">add a total</small><small v-else>{{item['total']}}</small></td>
                            <td @click="edit_fc(item)" style="cursor: pointer;"><small v-if="item['supplier_id'] == null">add a supplier</small><small v-else>{{ item['supplier_id'] }}</small></td>
                            <td @click="edit_fc(item)" style="cursor: pointer;"><small v-if="item['notes'] == null">add a note</small><small v-else>{{item['notes']}}</small></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-list-group>
        </v-list>

        <v-row justify="center">
            <v-dialog
                v-model="edit_fc_dialog"
                persistent
                scrollable
                width="520">
                <v-card>
                    <v-card-title>
                        <v-icon
                            class="mr-2">
                            mdi-pencil
                        </v-icon>
                        <small>edit <strong>{{location}}</strong></small>
                    </v-card-title>
                    <v-card-text>
                        <div
                            class="mt-5">
                            <v-text-field                               
                                label="Location"
                                hint="Location"
                                v-model="location"
                                placeholder="location"
                                outlined
                                dense
                                type="text"
                                prepend-icon="mdi-tooltip-text-outline"></v-text-field>
                            
                            <v-text-field                               
                                label="Sqm"
                                hint="Sqm"
                                v-model="sqm"
                                placeholder="Sqm"
                                outlined
                                dense
                                type="number"
                                prepend-icon="mdi-tooltip-text-outline"></v-text-field>
                            
                            <v-text-field                               
                                label="Floor Type"
                                hint="Floor Type"
                                v-model="floor_type"
                                placeholder="Floor Type"
                                outlined
                                dense
                                type="text"
                                prepend-icon="mdi-tooltip-text-outline"></v-text-field>
                            
                            <v-text-field                               
                                label="Price per sqm"
                                hint="Price per sqm"
                                v-model="price_per_sqm"
                                placeholder="Price per sqm"
                                outlined
                                dense
                                type="number"
                                prepend-icon="mdi-tooltip-text-outline"></v-text-field>
                            
                            <v-text-field                               
                                label="Total"
                                hint="Total"
                                v-model="total"
                                placeholder="Total"
                                outlined
                                dense
                                type="number"
                                prepend-icon="mdi-tooltip-text-outline"></v-text-field>
                            
                            <v-text-field                               
                                label="Supplier"
                                hint="Supplier"
                                v-model="supplier_id"
                                placeholder="supplier"
                                outlined
                                dense
                                type="text"
                                prepend-icon="mdi-tooltip-text-outline"></v-text-field>                            
                        </div>
                        <v-divider></v-divider>
                        <div class="mt-5">
                            <v-textarea
                                v-model="notes"
                                outlined
                                label="Notes"
                                hint="Notes"
                                prepend-inner-icon="mdi-tooltip-text-outline"
                            ></v-textarea>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="closedialog()"
                        class="mx-5">
                        Close
                    </v-btn>
                    <v-btn
                        color="#0b4ef3"
                        dark
                        @click="saveeditedflooring()"
                        class="pr-16 pl-16">
                        Save
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DesignTracker from '../modules/DesignTracker.js';

export default {
    data: () =>({
        header: [
            { text: '' },
            { text: 'Location' },
            { text: 'SQM' },
            { text: 'Floor Type' },
            { text: 'Price Per SQM' },
            { text: 'Total' },
            { text: 'Supplier' },
            { text: 'Notes' },         
        ],
        id: null,
        parent_id: null,
        location: null,
        sqm: null,
        floor_type: null,
        price_per_sqm: null,
        total: null,
        supplier_id: null,
        notes: null,
        edit_fc_dialog: false,
        design_tracker: new DesignTracker(),            
    }),
    props: {
        User: Object,
        Project: Object
    },
    computed: {
        ...mapGetters({
            get_dt_fc:      'designtracker/get_dt_fc_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_dt_fc:        'designtracker/fetch_dt_fc_action',
            save_dt_fc:         'designtracker/save_dt_fc_action',
            mark_dt_fc:         'designtracker/mark_dt_fc_action'
        }),
        closedialog() {
            this.edit_fc_dialog = false
        },
        saveeditedflooring() {
            this.save_dt_fc({
                id: this.id,
                parent_id: this.parent_id,
                data: { 
                    location: this.location,
                    sqm: this.sqm,
                    floor_type: this.floor_type,
                    price_per_sqm: this.price_per_sqm,
                    total: this.total,
                    supplier_id: this.supplier_id,
                    notes: this.notes
                }
            })
            this.edit_fc_dialog = false
        },
        edit_fc(item) {
            this.id = item.id;
            this.parent_id = item.parent_id;
            this.location = item.location;
            this.sqm = item.sqm;
            this.floor_type = item.floor_type;
            this.price_per_sqm = item.price_per_sqm;
            this.total = item.total;
            this.supplier_id = item.supplier_id;
            this.notes = item.notes;
            this.edit_fc_dialog = true;
        },
        item_percentage(item) {
            return (item.getchild.filter(item=> item.is_done == true).length / item.getchild.length) * 100
        },
        mark_dtfc(item) {
            this.mark_dt_fc({
                id: item.id,
                parent_id: item.parent_id,
                data: { 
                    is_done: item.is_done
                }
            })
        },
        userAccessParent(item) {
           if ( this.User.info.access_id == 1 ) {                              
                return item.location == this.design_tracker.getFCDefaultData().location ? false : true                    
           } else {
                return false
           }
        }
    },
    mounted(){
        this.fetch_dt_fc({
            user_id: this.User.id,
            project_id: this.Project.id
        })
    }
}
</script>