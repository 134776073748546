<template>
    <v-container fluid class="mt-5 flex">
      <div v-if="getuser.project_id == null" align="center">
        <strong>You need to create a project</strong>
        <p>Kindly click the Proceed button below to create a project, and we can continue to add entries</p>
        <v-btn 
          color="#0b4ef3" 
          dark 
          class="pl-16 pr-16" 
          rounded
          @click="$router.push({name: 'projectplanner'})"
        >
          Proceed
        </v-btn>
        <v-card class="mt-5">
          <v-card-title style="background: #062a82">
            <v-icon class="mr-2" style="color: white;">{{snagsetup.icon}}</v-icon>
            <small style="color: white;">{{snagsetup.title}} | Sample Data 
              <!-- <GuidelineComponent :color="color" /> -->
            </small>
          </v-card-title>
          <v-card-text>
            <DataTable
              class="mt-5"
              :setup="snagstablesamplesetup"
              :items="sampledata"
            />
          </v-card-text>
        </v-card>
      </div>
      <div v-else>
        <v-card>
          <v-card-title style="background: #062a82">
            <v-icon class="mr-2" style="color: white;">{{snagsetup.icon}}</v-icon>
            <small style="color: white;">{{snagsetup.title}} <v-icon color="white" style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon></small>
            <v-spacer></v-spacer>
            <v-btn
              v-for="(btn, btnIndex) in snagsetup.buttons"
              :key="btnIndex"
              :dark="btn.dark"
              :text="btn.text"
              :class="btn.class"
              :color="btn.color"
              rounded
              @click="btn.action ? addsnags() : ''"
            >
              <v-icon
                :class="btn.classicon"
              >
                {{btn.icon}}
              </v-icon>
              {{btn.label}}
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-tabs
              v-model="tab"
              centered
              icons-and-text
            >
            <v-tabs-slider></v-tabs-slider>
              <v-tab
                v-for="(tab, tabindex) in tabdata"
                :key="tabindex"
                :href="tab.href"
              >
              {{tab.label}}
              <v-icon>{{tab.icon}}</v-icon>
              </v-tab>
              <v-tabs-items v-model="tab">
                <v-tab-item
                  value="tab-1"
                >
                  <DataTable
                    :setup="snagstablesetup"
                    :headers="snagstablesetup.headers"
                    :items="getsmags"
                    @editsnags="editsnags"
                    @markasdone="markasdone"
                  />
                  <DialogComponent
                    v-model="addsnagsdialog"
                    :value="addsnagsdialog"
                    :dialogvalue="snagsdialogvalue"
                    :ddvalues="getsnagswho"
                    @closedialog="closedialog"
                    @savesnagentry="savesnagentry"
                  />
                  <DialogComponent
                    v-model="editsnagdialog"
                    :value="editsnagdialog"
                    :dialogvalue="snagseditdialogvalue"
                    :childdata="geteditsnag"
                    :ddvalues="getsnagswho"
                    @closedialog="closedialog"
                    @saveeditsnag="saveeditsnag"
                  />
                  <DialogWarning
                    v-model="dialogwarning"
                    :value="dialogwarning"
                    :dialogsvalue="dialogwarningcontent"
                    @delproject="delitem"
                    @donothing="donothing"
                  />
                </v-tab-item>
                <v-tab-item
                  value="tab-2"
                >
                  <DataTable
                    :setup="snagstablesetup"
                    :headers="snagstablesetup.headers"
                    :items="getcsnags"
                    @editsnags="editsnags"
                    @markasdone="markDelete"
                  />
                  <DialogWarning
                    v-model="dialogwarningDelete"
                    :value="dialogwarningDelete"
                    :dialogsvalue="dialogwarningcontentDelete"
                    @delproject="delitem"
                    @donothing="donothing"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card-text>
        </v-card>
      </div>
      <v-dialog
        persistent
        v-model="show_tutorial"
        width="1000"
      >
        <template
          v-slot:activator="{ on, attrs }"
        >
          <v-icon
            :color="color"
            v-on="on"
            v-bind="attrs"
          >
            help
          </v-icon>
        </template>

        <v-card>
          <v-card-title>
            Tutorial
            <v-spacer></v-spacer>
            <v-icon
              small
              color="red"
              @click="show_tutorial = false;"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <vue-pdf-embed source="snags.pdf" />
        </v-card>
      </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from '../../components/DataTable.vue'
import DialogComponent from '../../components/DialogComponent.vue'
import DialogWarning from '../../components/DialogWarning.vue'
// import GuidelineComponent from '@/components/GuidelineComponent.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    DataTable,
    DialogComponent,
    DialogWarning,
    VuePdfEmbed,
    // GuidelineComponent
  },
  props: [
  ],
  data: () => ({
    tab: null,
    show_tutorial: false,
    snagsetup: {
      title:    'Snags',
      icon:     'mdi-format-list-checks',
      buttons: [
        {
          id:           1,
          icon:         'mdi-plus',
          label:        'add snag entry',
          dark:         true,
          action:       true,
          text:         false,
          class:        'pl-10 pr-10',
          color:        '#0b4ef3',
          classicon:    'mr-2'
        }
      ]
    },
    tabdata: [
      {
        label:        'Snags',
        href:         '#tab-1'
      },
      {
        label:        'Completed Snags',
        href:         '#tab-2'
      }
    ],
    snagstablesetup: {
      issnag: true,
      headers: [
        { text: '', width: '2%' },
        { text: 'Description', filterable: true, sortable: true, value: 'description' },
        { text: 'Who', filterable: true, sortable: true, value: 'who' },
        { text: 'Deadline', filterable: true, sortable: true, value: 'created_at' },
        { text: 'Image' }
      ]
    },
    snagstablesamplesetup: {
      issnagsample: true,
      headers: [
        { text: '', width: '2%' },
        { text: 'Description', filterable: true, sortable: true, value: 'description' },
        { text: 'Who', filterable: true, sortable: true, value: 'who' },
        { text: 'Deadline', filterable: true, sortable: true, value: 'created_at' },
        { text: 'Image' }
      ]
    },
    sampledata: [
      {id: 1, description: 'Example: trim back the alarm cable', who: ["Dean"], created_at: 'June 05, 2020', image: '1633463886.jpg'},
      {id: 2, description: 'Example: Fix skirting in the hall', who: ["Dean"], created_at: 'June 05, 2020', image: '1633463967.jpg'},
      {id: 3, description: 'Example: Hang coat hanger in gall', who: ["Dean"], created_at: 'June 05, 2020', image: '1633464106.jpg'},
      {id: 4, description: 'Example: Finish ensuite skirting chaulking and stud for toilet', who: ["Dean"], created_at: 'June 05, 2020', image: '1633464253.jpg'},
    ],
    addsnagsdialog: false,
    snagsdialogvalue: {
      issnags:    true,
      label:      'add snag entry',
      icon:       'mdi-plus',
      iconclass:  'mr-1',
      textfield: [
        {
          id:       1,
          label:    'description',
          hint:     'description',
          icon:     'mdi-tooltip-text-outline'
        }
      ],
      dropdown: [
        {
          id:       2,
          label:    'who / enter email',
          hint:     'enter email',
          icon:     'mdi-tooltip-text-outline'
        }
      ],
      date: [
        {
          id:       3,
          label:    'deadline',
          hint:     'deadline',
          icon:     'mdi-calendar'
        },
      ],
    },
    editsnagdialog: false,
    snagseditdialogvalue: {
      editsnag:     true,
      label:        'edit snags',
      icon:         'mdi-pen',
      iconclass:    'mr-2',
      textfield: [
        {
          id:       1,
          label:    'description',
          hint:     'description',
          icon:     'mdi-tooltip-text-outline'
        }
      ],
      dropdown: [
        {
          id:       2,
          label:    'update who',
          hint:     'enter email',
          icon:     'mdi-tooltip-text-outline'
        }
      ]
    },
    dialogwarning: false,
    dialogwarningcontent: {
      warningdelete: true,
      title: 'Has this snag been completed?',
      content: '',
      buttons: [
        {
          id:         2,
          label:      'Yes',
          action:     true,
          class:      '',
          text:       true,
          dark:       false
        },
        {
          id:         1,
          label:      'No',
          action:     false,
          color:      '#0b4ef3',
          text:       false,
          class:      'pl-16 pr-16',
          dark:       true
        },
      ]
    },
    dialogwarningDelete: false,
    dialogwarningcontentDelete: {
      warningdelete: true,
      title: 'Are you sure you want to delete this item?',
      content: 'All of the data that belongs to this item will be deleted, are you sure about this?',
      buttons: [
        {
          id:         2,
          label:      'Yes',
          action:     true,
          class:      '',
          text:       true,
          dark:       false
        },
        {
          id:         1,
          label:      'No',
          action:     false,
          color:      '#0b4ef3',
          text:       false,
          class:      'pl-16 pr-16',
          dark:       true
        },
      ]
    },
    color: 'white'
  }),
  mounted () {
  },
  created () {
    this.gsnags()
    this.getcompletedsnags()
  },
  computed: {
    ...mapGetters({
      getuser:              'auth/getuser',
      getproject:           'auth/getproject',
      getsmags:             'snags/getsmags',
      getsnagswho:          'snags/getsnagswho',
      geteditsnag:          'snags/geteditsnag',
      getmarkdone:          'snags/getmarkdone',
      getcsnags:            'snags/getcsnags'
    })
  },
  methods: {
    async getcompletedsnags(){
      await this.$axios.get(`snags/gCSnags/${this.getproject.id}`)
      .then(({data}) => {
        this.$store.dispatch('snags/setcsnags', data.data)
      })
    },
    async gsnags(){
      await this.$axios.get(`snags/gSnags/${this.getproject.id}`)
      .then(({data}) => {
        this.$store.dispatch('snags/setsnags', data.data)
      })
      await this.$axios.get('snags/gSnagsWho')
      .then(({data}) => {
        let datatovuex = []
        data.data.forEach(q => {
          datatovuex.push(q.who)
        })
        this.$store.dispatch('snags/setsnagswho', datatovuex)
      })
    },
    addsnags(){
      this.addsnagsdialog = true
    },
    closedialog(){
      this.addsnagsdialog = false
      this.editsnagdialog = false
    },
    async savesnagentry(data){
      const formData = new FormData()
      formData.append('description', data.textfield[0].description)
      formData.append('deadline', data.textfield[0].deadline)
      formData.append('file', data.textfield[0].image)
      formData.append('who', data.textfield[0].who)
      await this.$axios.post(`snags/aSnag/${this.getproject.id}`, formData)
      .then(({data}) => {
        if(data.response){
          this.gsnags()
          this.closedialog()
        }
      })
    },
    editsnags(){
      this.editsnagdialog = true
    },
    async saveeditsnag(data){
      const formData = new FormData()
      formData.append('description', data.textfield[0].description == null ? '' : data.textfield[0].description)
      formData.append('deadline', data.textfield[0].deadline == null ? '' : data.textfield[0].deadline)
      formData.append('file', data.textfield[0].image.length == 0 ? '' : data.textfield[0].image)
      formData.append('who', data.textfield[0].length == 0 ? '' : data.textfield[0].who)
      await this.$axios.post(`snags/eSnag/${this.geteditsnag.id}`, formData)
      .then(({data}) => {
        if(data.response){
          this.gsnags()
          this.closedialog()
        }
      })
    },
    markasdone(data){
      this.$store.dispatch('snags/setmarkdone', data)
      this.dialogwarning = true
    },
    markDelete(data){
      this.$store.dispatch('snags/setmarkdone', data)
      this.dialogwarningDelete = true
    },
    donothing(){
      this.dialogwarning = false
      this.dialogwarningDelete = false
    },
    async delitem(){
      await this.$axios.delete(`snags/markDoneRecord/${this.getmarkdone.id}`)
      .then(({data}) => {
        if(data.response){
          this.gsnags()
          this.getcompletedsnags()
          this.dialogwarning = false
          this.dialogwarningDelete = false
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>