

export default {
    namespaced: true,
    state: {
        request_quotation: [],
        quoted_materials: []
    },
    mutations: {
        setClientRequestedQuotationMutation(state, payload) {
            state.request_quotation = [...payload];
        },
        setClientQuotedMaterialsMutation(state, payload) {
            state.quoted_materials = [...payload];
        },
        addClientQuotedMaterialMutation(state, payload) {                       
            const index = state.request_quotation.findIndex( (item) => 
                item.materials.id === payload.supplier_material.id && 
                item.bom_id === payload.bom_material_id && 
                item.supplier_id === payload.supplier_id)
            state.request_quotation.splice(index, 1)
        },
        updateClientQuotedMaterialMutation(state, payload) {
            const quoted_material = state.quoted_materials.filter( (item) => item.id === payload.id)
            quoted_material[0].unit_cost = payload.unit_cost
        }
    },
    getters: {
        getClientRequestedQuotationGetter: state => state.request_quotation,
        getClientQuotedMaterialsGetter: state => state.quoted_materials
    },
    actions: {
        setClientRequestedQuotationAction({commit}, payload) {
            commit('setClientRequestedQuotationMutation', payload)
        },
        setClientQuotedMaterialsAction({commit}, payload) {
            commit('setClientQuotedMaterialsMutation', payload)
        }, 
        addClientQuotedMaterialAction({commit}, payload) {
            commit('addClientQuotedMaterialMutation', payload.result[0])
            commit('setClientQuotedMaterialsMutation', payload.result)
        },
        updateClientQuotedMaterialAction({commit}, payload) {
            commit('updateClientQuotedMaterialMutation', payload)
        }
    }
}