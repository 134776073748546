<template>
    <v-container
        fluid
    >
        <v-col
            cols="4"
        >
            <AddCat
                @add_category="add_category"
            />
        </v-col>
        <v-col
            cols="12"
        >
            <CatList 
                :data="get_categories"
                @filter_item_via_category="filter_item_via_category"
            />
        </v-col>
        <v-col
            cols="12"
        >
            <v-row>
                <v-col
                    cols="12"
                    lg="4"
                    sm="12"
                    md="12"
                >
                    <v-text-field
                        v-if="get_bool_cat_selected"
                        v-model="sub_category"
                        outlined
                        dense
                        label="Add Sub Category"
                        prepend-inner-icon="mdi-plus"
                        suffix="press ENTER to save"
                        @keypress.enter="add_sub_category"
                    >
                    </v-text-field>
                </v-col>
                <!-- <v-col
                    cols="12"
                    lg="6"
                    sm="12"
                    md="12"
                >
                    <v-text-field
                        v-if="get_bool_cat_selected"
                        v-model="search"
                        outlined
                        dense
                        label="Search here..."
                        prepend-inner-icon="mdi-magnify"
                    >
                    </v-text-field>
                </v-col> -->
            </v-row>
            <v-row>
                <v-chip
                    v-for="(item, itemindex) in get_sub_category_items"
                    :key="itemindex"
                    class="mr-2"
                    style="cursor: pointer;"
                    @click="search_via_sub_item(item)"
                >
                    <v-icon>
                        {{ item.icon }}
                    </v-icon>
                    {{ item.name }}
                </v-chip>
            </v-row>
            <v-btn
                class="float-right"
                rounded
                outlined
                @click="add_item"
                :disabled="!get_bool_cat_selected"
            >
                <v-icon>
                    mdi-plus
                </v-icon>
                add item
            </v-btn>
            <ItemList
                :data_item="get_items"
            />
            <AddItem
                :value="add_item_model"
                @cancel_dialog_add="cancel_dialog_add"
            />
        </v-col>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
// category related
import AddCat from './components/AddCategory.vue'
import CatList from './components/CategoriesList.vue'
// end category related
// item related
import ItemList from './components/ItemList.vue'
import AddItem from './components/AddItem.vue'
// end item related
export default {
  components: {
    CatList,
    AddCat,
    ItemList,
    AddItem
  },
  props: [
  ],
  data () {
    return {
        search: null,
        add_item_model: false,
        sub_category: null
    }
  },
  async mounted () {
    await this.$store.dispatch('admin_shop/get_categories')
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_categories:         'admin_shop/get_categories',
        get_bool_cat_selected:  'admin_shop/get_bool_cat_selected',
        get_selected_category:  'admin_shop/get_selected_category',
        get_sub_category_items: 'admin_shop/get_sub_category_items'
    }),
    get_items(){
        return this.$store.getters['admin_shop/get_items'](this.search)
    }
  },
  methods: {
    async add_category(item){
        let tp = {
            name:   item,
            icon:   'mdi-toy-brick-outline'
        }
        await this.$store.dispatch('admin_shop/add_category', tp)
        await this.$store.dispatch('admin_shop/get_categories')
    },
    filter_item_via_category(item){
        this.$store.dispatch('admin_shop/get_items_via_cat_id', item)
    },
    add_item(){
        this.add_item_model = true
    },
    cancel_dialog_add(){
        this.add_item_model = false
    },
    async add_sub_category(){
        let tp = {
            cat_id:     this.get_selected_category.id,
            name:       this.sub_category
        }
        await this.$axios.post('/shop/add_sub_category', tp)
        .then(({data}) => {
            if(!data.response){
                this.$store.commit("auth/setMessage",
                {show: true, message: data.message},
                {root: 1})
                return
            }
            this.sub_category = ""
            this.$store.dispatch('admin_shop/get_items_via_cat_id', this.get_selected_category)
        })
    },
    async search_via_sub_item(item){
        await this.$store.dispatch('admin_shop/set_search_via_sub_item', item)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>