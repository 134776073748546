import Login from '../LoginComponent'
import Register from '../RegistrationComponent'
import RegisterSupplier from '../RegistrationSupplierComponent'
import ForgotPassword from '../ForgotPassword'
// import Upload from '../UploadComponent'

export default [
    {
        path: '/',
        component: Login,
        name: 'login',
    },
    {
        path: '/register',
        component: Register,
        name: 'register'
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'forgotpassword'
    },
    {
        path: '/supplier_register/:token',
        component: RegisterSupplier,
        name: 'register_supplier'
    },
    // {
    //     path: '/upload',
    //     component: Upload,
    //     name: '/upload'
    // }
]