
export default {
    namespaced: true,
    state: {
        open_alert_notification: false,
        alert_notification_type: 'success',
        notification_icon: 'mdi-information-outline',
        notification_message: null
    },
    mutations: {
        open_alert_notification_mutation(state, payload) {
            state.open_alert_notification = payload
        },
        set_notification_icon_mutation(state, payload) {
            state.notification_icon = payload
        },
        set_notification_message_mutation(state, payload) {
            state.notification_message = payload
        },
        set_alert_notification_type_mutation(state, payload) {
            state.alert_notification_type = payload
        }
    },
    getters: {
        get_open_alert_notification_getter: state => state.open_alert_notification,
        get_alert_notification_type_getter: state => state.alert_notification_type,
        get_notification_message_getter: state => state.notification_message,
        get_notification_icon_getter: state => state.notification_icon,
    },
    actions: {
        open_alert_notification_action({commit}, payload) {
            commit('open_alert_notification_mutation', payload)
            setTimeout( () => {
                commit('open_alert_notification_mutation', false)
            }, 4000)
        },
        set_notification_icon_action({commit}, payload) {
            commit('set_notification_icon_mutation', payload)
        },
        set_notification_message_action({commit}, payload) {
            commit('set_notification_message_mutation', payload)
        },
        set_alert_notification_type_action({commit}, payload) {
            commit('set_alert_notification_type_mutation', payload)
        },
        set_alert_notification_action({commit}, payload) {
            commit('open_alert_notification_mutation', payload.open)
            setTimeout( () => {
                commit('open_alert_notification_mutation', false)
            }, 4000)
            commit('set_notification_icon_mutation', payload.icon)
            commit('set_alert_notification_type_mutation', payload.type)
            commit('set_notification_message_mutation', payload.message)
        }
    }
}