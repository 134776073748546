<template>
    <div>
        <v-list>
            <v-list-group
                v-for="item in get_designtrackers.fff"
                :key="item.title"
                v-model="item.active"
                no-action>
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title style="color: #0b4ef3;">
                            <v-badge
                                color="#0b4ef3"
                                bordered>
                                <v-icon slot="badge" v-if="item.disabled">mdi-exclamation-thick</v-icon>
                                <v-icon slot="badge" v-else>mdi-check-bold</v-icon>
                                <span slot="default"><strong>{{item.room}}</strong></span>
                            </v-badge>
                            <v-progress-linear                                
                                :value="compute_progress(item)"
                                striped
                                height="15"
                                color="#7b9ff9">
                                <template v-slot:default="{ value }">
                                    <strong>{{ value.toFixed(2) }}%</strong>
                                </template>
                            </v-progress-linear>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-data-table
                    :headers="header"
                    :items="item.getchild"
                    item-key="task_name"
                    class="elevation-1 custom-table"
                    style="font-weight:700;"
                    :hide-default-footer="true"                    
                    mobile-breakpoint="0">
                    <template v-slot:item="{item}">
                        <tr>
                            <td><v-checkbox v-model="item['is_done']" disabled></v-checkbox></td>
                            <td style="cursor: pointer; white-space: pre-line;"><small>{{item['item']}}</small></td>
                            <td style="cursor: pointer;"><small v-if="item['quantity'] == null">add a quantity</small><small v-else>{{item['quantity']}}</small></td>
                            <td style="cursor: pointer;"><small v-if="item['color'] == null">add a color</small><small v-else>{{item['color']}}</small></td>
                            <td style="cursor: pointer;"><small v-if="item['supplier_id'] == null">add a supplier</small><small v-else>{{item['supplier_id']}}</small></td>
                            <td style="cursor: pointer;"><small v-if="item['product_code'] == null">add a product code</small><small v-else>{{item['product_code']}}</small></td>
                            <td style="cursor: pointer;"><small v-if="item['link_to_product'] == null">add a link to product</small><a :href="item['link_to_product']" target="_blank" v-else><small>{{ item['link_to_product'] }}</small></a></td>
                            <td style="cursor: pointer;"><small v-if="item['notes'] == null">add a note</small><small v-else>{{item['notes']}}</small></td>
                            <td style="cursor: pointer;"><small v-if="item['budget'] == null">add a budget</small><small v-else>{{item['budget']}}</small></td>
                            <td style="cursor: pointer;"><small v-if="item['actual'] == null">add an actual</small><small v-else>{{item['actual']}}</small></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-list-group>
        </v-list>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        header: [
            { text: '' },
            { text: 'Item' },
            { text: 'Quantity' },
            { text: 'Colour' },
            { text: 'Supplier' },
            { text: 'Product Code' },
            { text: 'Link To Product' },
            { text: 'Notes' },
            { text: 'Budget' },
            { text: 'Actual' }
        ]
    }),
    props: {
        project: Object,
        user_id: Number
    },
    computed: {
        ...mapGetters({
            get_designtrackers:     'designtracker/get_designtrackers_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_designtracker:        'designtracker/fetch_designtracker_action'
        }),
        compute_progress(item) {
            const total_items = item.getchild.length;
            const total_completed_items = item.getchild.filter(i => i.is_done == true).length;

            return (total_completed_items/total_items) * 100;
        }
    },
    async mounted() {
        await this.fetch_designtracker({
            project_id: this.project.id,
            user_id: this.user_id
        });
    },
    watch: {
        async project() {
            await this.fetch_designtracker({
                project_id: this.project.id,
                user_id: this.user_id
            });
        }
    }
}
</script>