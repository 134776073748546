/**
 * # is the implementation of private class in javascript - It can be implemented both variables and functions.
 * reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes/Private_class_fields
 */
export class UserAccess {
    #access_list = [
        {id: 1, access: 'Free Tier'},
        {id: 2, access: 'Paid Tier'},
        {id: 3, access: 'Premium Tier'},
        {id: 4, access: 'Standard Tier'}
    ];
    #access_id = Number;
    #access_to_be_granted = Array;

    constructor(access_id, access_to_be_granted) {
        this.#access_id = access_id;       
        this.#access_to_be_granted = access_to_be_granted;
    }

    get_access_grant() {
        let user_access = this.#access_list.filter(uaccess => uaccess.id === this.#access_id);       
        return this.#access_to_be_granted.includes(user_access[0].access);       
    }

    allow_multiple_projects(user_info, number_of_projects) {
        if ( user_info.allow_multiple_projects == 0) {
            if ( number_of_projects == 0 ) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }
}