import Vue from "vue"

export default {
    namespaced: true,
    state: {
      costestimationdata: [],
      toeditbudget: {}
    },
    mutations: {
      clearmutation(state){
        state.costestimationdata = []
      },
      setcostestimationdata(state, payload){
        state.costestimationdata = [...payload]
      },
      settoeditbudget(state, payload){
        state.toeditbudget = payload
      }
    },
    getters: {
      getcostestimationdata(state){
        return state.costestimationdata
      },
      gettoeditbudget(state){
        return state.toeditbudget
      }
    },
    actions: {
      setcostestimationdata({commit}, payload){
        const data = payload.map((item, index) => ({
          ...item,
          index: index + 1
        }))
        commit('setcostestimationdata', data)
      },
      async refetchcostestimatin({commit}, payload){
        await Vue.axios.get(`costestimation/costEstimation/${payload.id}`)
        .then(({data}) => {
          const topasswithindex = data.data.map((item, index) => ({
            ...item,
            index: index + 1
          }))
          commit('setcostestimationdata', topasswithindex)
        })
      },
      settoeditbudget({commit}, payload){
        commit('settoeditbudget', payload)
      }
    }
}