<template>
    <v-container fluid class="flex pb-16">
    <small>* The cost calculated below is an estimate and is intended only as a guide. Please check and verify all costs throughout your build with your engineer or QS. If you would like exact costs specific to your location and site conditions please contact www.selfbuild3d.com <v-icon style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon></small>
          <v-col
            cols="12"
          >
            <v-autocomplete
              v-model="chosentemplatecomputed"
              outlined
              small-chips
              dense
              :items="getbudgetmanagertemplates"
              item-text="template_name"
              item-value="id"
              label="choose a template"
              return-object
              prepend-inner-icon="mdi-form-dropdown">
              <template slot="item" slot-scope="{ item }">
                {{item.template_name}} <v-icon v-if="item.disabled" small>mdi-lock</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
              v-if="chosentemplatecomputed != null"
              cols="12">
              <v-btn class="mr-2 mb-2 white--text"
                type="text" color="#0b4ef3"
                @click="show_edit_squaremeter_dialog()">
                <v-icon class="mr-2">mdi-pen</v-icon>
                EDIT SQUARE METER
              </v-btn>
              <v-btn class="mr-2 mb-2 white--text"
                type="text" color="#0b4ef3"
                @click="show_additem_form_dialog()">
                <v-icon class="mr-2">mdi-plus</v-icon>
                Add Item
              </v-btn>
              <v-btn class="mb-2 white--text"
                type="text" color="#0b4ef3"
                @click="show_all_trans_formdialog()">
                <v-icon class="mr-2">mdi-details</v-icon>
                Summary
              </v-btn>
              <v-btn class="mb-2 ml-2 white--text"
                color="#0b4ef3"
                @click="show_deleted_transactions()">
                <v-icon class="mr-2">mdi-delete</v-icon>
                Deleted Transactions
              </v-btn>      
          </v-col>
        <v-row
          v-if="chosentemplatecomputed != null">
            <v-col cols="12">
              <strong>Square Meter: {{get_budgetmanager(getchosentemplate.id, getproject.id).property_size}}</strong>
            </v-col>
            <v-col cols="12">
                <BudgetManagerTable
                  :template_id="getchosentemplate.id"
                  :project_id="getproject.id">
                </BudgetManagerTable>
            </v-col>

            <!-- Dialog Component here! -->
            <EditSquareMeterDialog
              :open_dialog="edit_squaremeter_dialog"
              :budgetmanager_data="get_budgetmanager(getchosentemplate.id, getproject.id)"
              @close_dialog="close_edit_squaremeter_dialog()">
            </EditSquareMeterDialog>
            <AddItem
              :add_item_formdialog="open_additem_formdialog"
              :budgetmanager_item="get_budgetmanager(getchosentemplate.id, getproject.id)"
              @close_dialog="close_additem_formdialog()"></AddItem>
            <AllBudgetTransactionTable
              :show_all_bm_trans_formdialog="show_all_trans_form_dialog"
              :users_id="getuser.id"
              :project_id="getproject.id"
              @close_dialog="close_all_trans_form_dialog()">
            </AllBudgetTransactionTable>
        </v-row>
          <!-- View Deleted Transaction -->
          <ViewDeletedTransactionVue
            :show_deletedtransaction_dialog="open_show_deleted_transaction_dialog"
            :users_id="getuser.id"
            :project_id="getproject.id"            
            @closedialog="close_deleted_transaction()"></ViewDeletedTransactionVue>
          
            <v-dialog
              persistent
              v-model="show_tutorial"
              scrollable
              width="1000">
              <v-card>
                <v-card-title>
                  Tutorial
                  <v-spacer></v-spacer>
                  <v-icon
                    small
                    color="red"
                    @click="show_tutorial = false;">
                    mdi-close
                  </v-icon>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <vue-pdf-embed source="budget_manager.pdf" />
                </v-card-text>
              </v-card>
          </v-dialog>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BudgetManagerTable from './components/BudgetManagerTable.vue';
import EditSquareMeterDialog from './components/budgetmanager/EditSquareMeterDialog.vue';
import AddItem from './components/budgetmanager/AddItem.vue';
import AllBudgetTransactionTable from './components/budgettransactions/AllBudgetTransactionTable.vue';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
//import EditOutstanding from './components/EditOutstanding.vue'
import ViewDeletedTransactionVue from './components/ViewDeletedTransaction.vue'
//import BudgetManagetViewHistory from './components/BudgetManagetViewHistory.vue'

export default {
  components: {
    BudgetManagerTable,
    EditSquareMeterDialog,
    AddItem,
    AllBudgetTransactionTable,
    ViewDeletedTransactionVue,
    VuePdfEmbed,
    //EditOutstanding, 
  },
  props: [
  ],
  data: () => ({
    chosentemplatecomputed: null,
    show_tutorial: false,
    edit_squaremeter_dialog: false,
    open_additem_formdialog: false,
    open_show_deleted_transaction_dialog: false,
    dialogwarningdeleteitem: false,
    show_all_trans_form_dialog: false,
    budgetmanagerdata: {},
    edit_budgetmanager_outstanding_dialog: false,
    budgetmanager_id: null,
    open_transaction_history: false
  }),
  mounted () {
    if ( this.getchosentemplate != null ) {
      this.chosentemplatecomputed = this.getchosentemplate;
    }
  },
  created() {    
    this.get_budgetmanager_data({
      users_id: this.getuser.id,
      project_id: this.getproject.id
    });
    this.get_budgetmanager_templates();

    this.fetch_all_budgetmanager_transaction({
        users_id: this.getuser.id,
        project_id: this.getproject.id
    });
  },
  computed: {
    ...mapGetters({
      getuser:                              'auth/getuser',
      getbudgetmanagertemplates:            'budgetmanager/getbudgetmanagertemplates',
      getproject:                           'auth/getproject',
      getbudgetmanagertab1data:             'budgetmanager/getbudgetmanagertab1data',
      getbudgetmanageredit:                 'budgetmanager/getbudgetmanageredit',
      getparentbudgetmanagerdata:           'budgetmanager/getparentbudgetmanagerdata',
      getbudgetmanageritemdelete:           'budgetmanager/getbudgetmanageritemdelete',
      getbudgetmanagertransactionhistory:   'budgetmanager/getbudgetmanagertransactionhistory',
      getbudgetmanagertodeletetransaction:  'budgetmanager/getbudgetmanagertodeletetransaction',
      getbudgetmanagersummary:              'budgetmanager/getbudgetmanagersummary',
      getchosentemplate:                    'budgetmanager/getchosentemplate',
      get_budgetmanager:                    'budgetmanager/get_budgetmanager_data_getter'      
    }),
  },
  methods: {
    ...mapActions({
        get_budgetmanager_data:                 'budgetmanager/get_budgetmanager_data_action',
        get_budgetmanager_templates:            'budgetmanager/get_budgetmanager_templates_action',
        select_budgetmanager_template:          'budgetmanager/select_budgetmanager_template_action',
        fetch_all_budgetmanager_transaction:    'budget_transaction/fetch_all_budgetmanager_transaction_action',
        recompute_bugetmanaget_items:           'budgetmanager/recompute_bugetmanaget_items_action'
    }),
    show_edit_squaremeter_dialog() {      
      this.edit_squaremeter_dialog = true;
    },
    close_edit_squaremeter_dialog() {
      this.edit_squaremeter_dialog = false;
    },
    show_additem_form_dialog() {
      this.open_additem_formdialog = true;
    },
    close_additem_formdialog() {
      this.open_additem_formdialog = false;
    },
    /** Deleted Transaction Update - 06/26/2023 */
    show_deleted_transactions() {
      this.open_show_deleted_transaction_dialog = true;
    },
    close_deleted_transaction() {
      this.open_show_deleted_transaction_dialog = false;
    },
    show_all_trans_formdialog() {
      this.show_all_trans_form_dialog = true;
    },
    close_all_trans_form_dialog() {
      this.show_all_trans_form_dialog = false;
    }
  },
  watch: {
    chosentemplatecomputed() {
      if ( this.chosentemplatecomputed != null ) {        
        /** Selection of Budget Manager based on template and project id */
        this.select_budgetmanager_template(this.chosentemplatecomputed);
      }
    },
    async getchosentemplate() {
      this.chosentemplatecomputed = this.getchosentemplate;
      /** Update Paid to date and Outstanding */
      if ( typeof this.getchosentemplate != "undefined" ) {
        await this.recompute_bugetmanaget_items({
          users_id: this.getuser.id,
          project_id: this.getproject.id,
          template_id: this.getchosentemplate.id
        });

        await this.fetch_all_budgetmanager_transaction({
          users_id: this.getuser.id,
          project_id: this.getproject.id
        });
        
        this.get_budgetmanager_data({
          users_id: this.getuser.id,
          project_id: this.getproject.id
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>