<template>
    <v-row justify="center">
        <v-dialog :value="value" persistent scrollable max-width="690">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">{{ dialogvalue.icon }}</v-icon><small>{{ dialogvalue.title }}</small>
                </v-card-title>
                <v-card-text>
                    <wysiwyg label="Tip description" outlined hint="Tip description" class="px-5" dense
                        v-model="entries.textarea" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="closedialog" class="mx-5">
                        Close
                    </v-btn>
                    <v-btn color="#0b4ef3" dark @click="savesuggestion" class="pr-16 pl-16">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    components: {
    },
    props: [
        'dialogvalue',
        'value'
    ],
    data: () => ({
        entries: {
            textarea: null,
        },
    }),
    mounted() {
        this.entries.textarea = null
    },
    created() {
    },
    computed: {
    },
    methods: {
        closedialog() {
            this.$emit('closedialog')
            this.entries.textarea = null
        },
        savesuggestion() {
            this.$emit('savesuggestion', this.entries.textarea)
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">

</style>