import Vue from 'vue';

export default class BillOfMaterials {
    constructor() {
        this.store = null;
    }

    setStore(store) {
        this.store = store;
    }

    chosenBillOfMaterialsTemplte (chosenmaterialstemplate) {
        Vue.axios.get(`billofmaterials/setmaterials/${chosenmaterialstemplate.id}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {                    
                    this.store.dispatch('billofmaterials/setbillofmaterialsdata', result)
                } else {
                    console.log(result.data)
                }
            } else {
                console.log(data)
            }
        })
    }
    
    /**     
     * @param {data, choosen_material_template} param 
     */
    saveValueInBillOfMaterials(param) {        
        Vue.axios.post('billofmaterials/addvariable', param.data).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response ) {
                    this._adjustQuantity(param.choosen_material_template)
                }
            }
        })
    }

    updateBoMItemQuantity({ id, label, props, value}, choosen_material_template) {        
        Vue.axios.post('billofmaterials/updatequantity', {
            id: id, label: label, props: props, value: value
        }).then( (data) => {
            if ( data.status == 200 ) {
                this.chosenBillOfMaterialsTemplte(choosen_material_template)
            } else {
                console.log(data)
            }
        })
    }

    updateBoMItemName({ id, name }) {
        Vue.axios.post(`billofmaterials/update_name`, {id: id, name: name}).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    // Do nothing as it was updated
                    this._display_alert_notification({
                        open: true, icon: 'mdi-check',
                        type: 'success', message: 'Successfully updated item name.'
                    })
                } else {
                    this._display_alert_notification({
                        open: true, icon: 'mdi-alert-circle',
                        type: 'error', message: result
                    })
                }
            }
        })
    }

    async uploadBoMItemImageUpload({ id, image }) {        
        const upload_form_data = new FormData()
        upload_form_data.append('id', id)
        upload_form_data.append('image', image)
        
        await Vue.axios.post(`billofmaterials/update_image`, upload_form_data).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    this.store.dispatch('billofmaterials/setBoMItemImageAction', {id: id, image: image})
                    this._display_alert_notification({
                        open: true, icon: 'mdi-check',
                        type: 'success', message: 'Successfully uploaded an image.'
                    })
                }
            } else {
                this._display_alert_notification({
                    open: true, icon: 'mdi-alert-circle',
                    type: 'error', message: `${data.status}: ${data.statusText}`
                })
            }
        }).catch( (err) => {
            this._display_alert_notification({
                open: true, icon: 'mdi-alert-circle',
                type: 'error', message: err.message
            })
        });

    }

    async addBoMMaterialPerTemplate({name, image, quantity, unit}, template) {
        
        const add_material_form = new FormData()        
        add_material_form.append('template_id', template.id)
        add_material_form.append('name', name)
        if ( image != null ) {
            add_material_form.append('image', image)
        }
        add_material_form.append('quantity', quantity)
        add_material_form.append('unit', unit)

        await Vue.axios.post(`billofmaterials/create_material`, add_material_form).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    
                    this.chosenBillOfMaterialsTemplte(template)

                    this._display_alert_notification({
                        open: true, icon: 'mdi-check',
                        type: 'success', message: 'Successfully added a material into a template.'
                    })
                } else {
                    this._display_alert_notification({
                        open: true, icon: 'mdi-alert-circle',
                        type: 'error', message: result
                    })
                }
            } else {
                this._display_alert_notification({
                    open: true, icon: 'mdi-alert-circle',
                    type: 'error', message: `${data.status}: ${data.statusText}`
                })
            }
        }).catch( (err) => {
            this._display_alert_notification({
                open: true, icon: 'mdi-alert-circle',
                type: 'error', message: err.message
            })
        })
    }
    
    /** Private Methods */
    _adjustQuantity(choosen_material_template) {        
        Vue.axios.patch(`billofmaterials/aqty/${choosen_material_template.id}`).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    this.chosenBillOfMaterialsTemplte(choosen_material_template)
                } else {
                    this._display_alert_notification({
                        open: true, icon: 'mdi-alert-circle',
                        type: 'error', message: result
                    })
                }
            }
        })
    }

    _display_alert_notification = (alert_info) => {
        this.store.dispatch('alert_notifications/open_alert_notification_action', alert_info.open)
        this.store.dispatch('alert_notifications/set_notification_icon_action', alert_info.icon)
        this.store.dispatch('alert_notifications/set_alert_notification_type_action', alert_info.type)
        this.store.dispatch('alert_notifications/set_notification_message_action', alert_info.message)
    }
}