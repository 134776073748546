
export class MessageBox {
    #context = Object;

    constructor(context) {
        this.#context = context;     
    }

    show_message_dialog(payload) {
        this.#context.dispatch('messagebox/open_messagebox_dialog_action', payload, { root: true });
    }

    set_messagebox_details(payload) {  
        this.#context.dispatch('messagebox/set_messagebox_details_action', payload, { root: true });
    }
}