import store from '@/states/index.js'

import Chase from '../ChaseTheTrade.vue'

export default [
    {
        path: '/chase',
        component: Chase,
        name: 'chase',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 3){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]