<template>
    <v-row justify="center">
        <v-dialog
            :value="value"
            persistent
            width="560"
        >
            <v-card>
                <v-card-title>
                    <label
                        style="font-weight: 500; font-size: medium;"
                    >
                        {{ get_selected_category.name }}
                    </label>
                    <v-spacer/>
                    <v-btn
                        v-if="!is_bulk"
                        small
                        color="green"
                        dark
                        class="pl-5 pr-5"
                        outlined
                        @click="is_bulk = !is_bulk"
                    >
                        Bulk upload
                    </v-btn>
                    <v-btn
                        v-else
                        small
                        color="green"
                        dark
                        class="pl-5 pr-5"
                        outlined
                        @click="is_bulk = !is_bulk"
                    >
                        single entry
                    </v-btn>
                </v-card-title>
                <v-card-subtitle>
                    <v-chip color="primary">{{ get_selected_category.name }}</v-chip> <v-chip color="secondary">{{ get_selected_sub_category.name }}</v-chip>
                </v-card-subtitle>
                <div
                    v-if="!is_bulk"

                >
                    <v-card-text>
                        <v-row>
                            <v-col
                                cols="12"
                                lg="12"
                                sm="12"
                            >
                                <label
                                    style="font-weight: 500; font-size: small;"
                                >Item Name</label>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="a.name"
                                    prepend-icon="mdi-database-plus"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                lg="12"
                                sm="12"
                            >
                                <label
                                    style="font-weight: 500; font-size: small;"
                                >Product URL</label>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="a.product_url"
                                    prepend-icon="mdi-database-plus"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                lg="12"
                                sm="12"
                            >
                                <label
                                    style="font-weight: 500; font-size: small;"
                                >Image</label>
                                <v-file-input
                                    truncate-length="15"
                                    v-model="a.file"
                                    outlined
                                    dense
                                ></v-file-input>
                            </v-col>
                            <v-row>
                                <v-col
                                    cols="12"
                                    lg="12"
                                    sm="12"
                                >
                                    <label
                                        style="font-weight: 500; font-size: small;"
                                    >
                                        Rating
                                    </label>
                                    <v-rating
                                        length="5"
                                        v-model="a.rating"
                                        half-increments
                                    ></v-rating>
                                </v-col>
                                <v-col
                                    cols="4"
                                    lg="4"
                                    sm="4"
                                >
                                    <label
                                        style="font-weight: 500; font-size: small;"
                                    >
                                        (zł) Original Price
                                    </label>
                                    <v-text-field
                                        v-model="a.orignal_price"
                                        outlined
                                        dense
                                        @change="check_rate"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="4"
                                    lg="4"
                                    sm="4"
                                >
                                    <label
                                        style="font-weight: 500; font-size: small;"
                                    >
                                        % Markup
                                    </label>
                                    <v-text-field
                                        v-model="a.markup"
                                        outlined
                                        dense
                                        prepend-icon="mdi-percent-outline"
                                        @change="check_rate"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="4"
                                    lg="4"
                                    sm="4"
                                >
                                    <label
                                        style="font-weight: 500; font-size: small;"
                                    >
                                        Price
                                    </label>
                                    <v-text-field
                                        v-model="a.price_raw"
                                        outlined
                                        dense
                                        prepend-icon="mdi-currency-eur"
                                        @change="check_rate"
                                        :disabled="true"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-col
                                cols="12"
                                lg="12"
                                sm="12"
                            >
                                <label
                                    style="font-weight: 500; font-size: small;"
                                >
                                    Location
                                </label>
                                <v-text-field
                                    dense
                                    outlined
                                    v-model="a.location"
                                    prepend-icon="mdi-database-plus"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                lg="12"
                                sm="12"
                            >
                                <v-combobox
                                    v-model="a.chip_data"
                                    chips
                                    clearable
                                    label="Add Tags"
                                    multiple
                                    prepend-icon="mdi-database-plus"
                                    dense
                                    outlined
                                ></v-combobox>
                            </v-col>
                            <v-col
                                cols="12"
                                lg="12"
                                sm="12"
                            >
                                <label
                                    style="font-weight: 500; font-size: small;"
                                >Description</label>
                                <wysiwyg
                                    v-model="a.description"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="cancel_dialog_add()"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            outlined
                            rounded
                            class="pl-10 pr-10"
                            @click="add_item"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </div>
                <div
                    v-else
                >
                    <v-card-text>
                        <v-text-field
                            v-model="b.markup"
                            label="Price markup for this bulk upload"
                            outlined
                            dense
                            prepend-icon="mdi-database-plus"
                        >
                        </v-text-field>
                        <v-file-input
                            dense
                            label="select a CSV"
                            outlined
                            truncate-length="15"
                            v-model="b.file"
                        ></v-file-input>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="cancel_dialog_add(); is_bulk =! is_bulk"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            outlined
                            rounded
                            class="pl-10 pr-10"
                            @click="upload_file"
                        >
                            upload
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  components: {
  },
  props: [
    'value',
  ],
  data () {
    return {
        a: {
            file: null,
            name: null,
            rating: 0,
            price: null,
            orignal_price: null,
            price_raw: null,
            markup: null,
            location: null,
            description: null,
            chip_data: null,
            product_url: null
        },
        is_bulk: false,
        b: {
            file: null,
            markup: null
        }
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_selected_category:          'admin_shop/get_selected_category',
        get_selected_sub_category:      'admin_shop/get_selected_sub_category'
    })
  },
  methods: {
    cancel_dialog_add(){
        this.$emit('cancel_dialog_add')
        this.is_bulk = false
        this.a = {}
        this.b = {}
    },
    async add_item(){
        let formData = new FormData();
        formData.append('file', this.a.file);
        formData.append('name', this.a.name);
        formData.append('product_url', this.a.product_url)
        formData.append('rating', this.a.rating)
        formData.append('price', this.a.price.toFixed(2))
        formData.append('markup', this.a.markup)
        formData.append('original_price', this.a.orignal_price)
        formData.append('location', this.a.location)
        formData.append('description', this.a.description)
        formData.append('chip_data', this.a.chip_data)
        formData.append('category_id', this.get_selected_category.id)
        formData.append('sub_category_id', this.get_selected_sub_category.id)
        formData.append('category_name', this.get_selected_category.name)
        await this.$axios.post('/shop/add_new_item', formData)
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('admin_shop/get_items_via_cat_id', this.get_selected_category)
                this.cancel_dialog_add()
                this.a = {}
            }
        })       
    },
    async check_rate(){
        if(this.a.orignal_price != null && this.a.markup != null){
            await axios.get('https://open.er-api.com/v6/latest/PLN')
            .then(({data}) => {
                this.a.price_raw = this.a.orignal_price * data.rates["EUR"]
                let markup = "0."+this.a.markup
                let price = this.a.price_raw * markup
                this.a.price = parseFloat(this.a.price_raw) + parseFloat(price)
                this.a.price_raw = parseFloat(this.a.price_raw) + parseFloat(price)
            })
        }
    },
    async upload_file(){
        this.$store.commit("auth/setMessage", 
        {show: true, message: "Please wait..."}, 
        {root: 1})
        
        const rate = await axios.get('https://open.er-api.com/v6/latest/PLN')
        let formData = new FormData();
        let chip_data = [];
        chip_data.push(this.get_selected_category.name)
        chip_data.push(this.get_selected_sub_category.name)
        formData.append('category_id', this.get_selected_category.id)
        formData.append('sub_category_id', this.get_selected_sub_category.id)
        formData.append('file', this.b.file)
        formData.append('rate', rate.data.rates['EUR'])
        formData.append('chip_data', chip_data)
        formData.append('markup', "0."+this.b.markup)
        await this.$store.dispatch('admin_shop/bulk_upload_csv', formData)
        await this.$store.dispatch('admin_shop/get_items_via_cat_id', this.get_selected_category)
        this.cancel_dialog_add()

        this.$store.commit("auth/setMessage", 
        {show: true, message: "CSV uploaded!"}, 
        {root: 1})
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>