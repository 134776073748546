<template>
    <v-card>
      <v-card-title style="background: #062a82;">
        <v-icon class="mr-2" style="color: white;">{{setup.icon}}</v-icon>
        <small style="color: white;">{{setup.name}} <v-icon color="white" style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon>
          <!-- <GuidelineComponent :color="color" /> -->
        </small>
        <v-spacer></v-spacer>
        <div v-if="setup.button.length != 0">
          <v-btn
            v-for="(btn, btnIndex) in setup.button"
            :key="btnIndex"
            :dark="btn.dark"
            :text="btn.text"
            :class="btn.class"
            :color="btn.color"
            rounded
            @click="btn.action ? addsuggestion() : ''"
          >
            <v-icon
              :class="btn.classicon"
            >
              {{btn.icon}}
            </v-icon>
            {{btn.label}}
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" placeholder="search tips" label="search tips"></v-text-field>
        <v-data-table
          :headers="setup.tableheader"
          :items="items"
          :search="search"
          class="elevation-1"
          :hide-default-header="true"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:item="props">
            <tr>
              <td><div class="pt-3 pb-3" style="max-height:300px;overflow-y:auto" v-html="props.item.description"></div></td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-dialog
        persistent
        v-model="show_tutorial"
        width="1000"
      >
        <v-card>
          <v-card-title>
            Tutorial
            <v-spacer></v-spacer>
            <v-icon
              small
              color="red"
              @click="show_tutorial = false;"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <vue-pdf-embed source="tips.pdf" />
        </v-card>
      </v-dialog>
    </v-card>
</template>


<script>
// import GuidelineComponent from '@/components/GuidelineComponent.vue';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
export default {
    components: {
      // GuidelineComponent
      VuePdfEmbed
    },
    props: [
        'setup',
        'items',
    ],
    data: () => ({
        img_url: null,
        search: '',
        expanded: [],
        text: 'center',
        is_dialog: false,
        chat_data: {},
        chat_whole_data: {},
        chat_message: null,
        is_dialog_supplier_profile: false,
        supplier_data_for_view: {},
        sortBy: 'created_at',
        sortDesc: true,
        color: 'white',
        show_tutorial: false,
    }),
    methods: {
        addsuggestion() {
            this.$emit('addsuggestion')
        },
    }
}
</script>