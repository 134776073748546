<template>
    <v-row justify="center">
        <v-dialog
            v-model="edit_supplier_model"
            persistent
            scrollable           
            max-width="800">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    <small>Update or Add Supplier</small>
                </v-card-title>
                <v-card-text>
                    <v-card class="mb-3 mt-3"
                        v-for="(supplier, index) in search_supplier_data"
                        :key="index">
                        <v-card-title>
                            <small>Supplier #{{ index + 1 }}</small>
                        </v-card-title>
                        <v-card-text>
                            <v-autocomplete
                                v-model="supplier.selected_data"
                                :items="supplier.searched_data"
                                :loading="loading"
                                :search-input.sync="supplier.search"
                                clearable
                                prepend-icon="mdi-warehouse"
                                label="Search for a Supplier"
                                hint="Search for a supplier"
                                item-text="company_name"
                                return-object                              
                                dense
                                outlined
                                @change="selectedSupplier(supplier, index)"
                                @keyup="search(supplier, index)">
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-title>
                                            Search a supplier name in the field. If no supplier found, please register the new supplier.
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                            <!-- Searchable Supplier Items -->
                            <v-autocomplete v-if="supplier.selected_data != null"
                                v-model="supplier.selected_material_data"
                                :items="supplierMaterialsByUsersId"
                                :loading="loading"
                                :search-input.sync="supplier.search_material"
                                clearable
                                prepend-icon="mdi-warehouse"
                                label="Search for a Materials per Supplier"
                                hint="Search for a Materials from a specific supplier"
                                item-text="name"
                                return-object                              
                                dense
                                outlined
                                @change="evtSelectSupplierMaterial(supplier, index)">
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-list-item-title>
                                            Search a material based on selected supplier.
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                            <v-text-field
                                v-model="supplier.data.url"
                                label="Supplier Link"
                                hint="Enter a supplier link."
                                prepend-icon="mdi-link-box"
                                dense
                                outlined>
                            </v-text-field>
                            <v-text-field
                                v-model="supplier.data.unit_cost"
                                label="Unit Cost"
                                hint="Enter the unit cost of the product"
                                prepend-icon="mdi-cash"
                                type="number"
                                prefix="€"
                                dense
                                outlined>
                            </v-text-field>
                            <v-alert color="primary" class="white--text">
                                <v-icon class="mr-2 white--text">mdi-cash-multiple</v-icon>
                                <strong>Total Cost: € {{ computeTotalCost(index) | formatNumber }}</strong>
                            </v-alert>
                        </v-card-text>
                    </v-card>                   
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" class="px-6" @click="evtCloseDialog">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Cancel
                    </v-btn>
                    <v-btn color="success" class="px-6" @click="evtSaveSupplierPerMaterial">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import Suppliers from '../modules/Suppliers.js'
import { mapGetters } from 'vuex'
export default {
    data: () => ({
        edit_supplier_model: false,
        supplier: new Suppliers(),
        loading: false,
        search_supplier_data: null,        
        timeOutId: null
    }),
    props: {
        edit_supplier_dialog: Boolean,
        material: Object
    },
    computed: {
        ...mapGetters({
            supplierSearchResults:          'suppliers/supplierSearchResultsGetters',
            supplierMaterialsByUsersId:     'suppliers/supplierMaterialsByUsersIdGetters'
        })
    },
    methods: {
        evtCloseDialog() {            
            this.$emit('evtCloseDialog')
        },
        searchDebounce(val, index) {
            clearTimeout(this.timeOutId)
            this.timeOutId = setTimeout( async () => {
                if ( val.length > 2 ) {
                    await this.supplier.searchSupplierByName(val, index)
                }
                this.loading = false
            }, 500)
        },
        computeTotalCost(index) {            
            return this.supplier.setSupplierSearchDataUnitCost(index, this.material)
        },
        search(supplier, index) {                        
            if ( !supplier.search ) return
            this.loading = true
            this.searchDebounce(supplier.search, index)
        },
        selectedSupplier(supplier, index) {
            this.supplier.setSupplierSearchData(index)            
            this.supplier.searchMaterialsBySupplierUserId(supplier.selected_data.user_id)
        },
        evtSaveSupplierPerMaterial() {
            this.supplier.updateSupplierDataPerMaterial(this.material)
            this.evtCloseDialog()
        },
        evtSelectSupplierMaterial(supplier, index) {
            this.supplier.setSelectedSupplierMaterial(supplier.selected_material_data, index)
        }
    },
    async mounted() {
        this.supplier.setStore(this.$store)
        await this.supplier.searchSupplierByName()
        this.search_supplier_data = this.supplier.getSupplierSearchData()
    },   
    watch: {
        edit_supplier_dialog() {
            this.edit_supplier_model = this.edit_supplier_dialog
            if ( this.edit_supplier_dialog == true ) {
                this.supplier.resetSupplierSearchData()
                // Set Item data based on the data in the table row - In this way, all the data will remain and can be
                // updated.
                this.supplier.setSupplierDataBasedOnMaterialRowItem(this.material)                
            }
        }
    }
}
</script>