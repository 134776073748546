<template>
    <v-row justify="center">
        <v-dialog
            persistent
            max-width="390"
            :value="value"
        >
        <v-card>
            <v-card-title>
                <label
                    style="font-weight: 500; font-size: medium;"
                >
                    {{ item.name }}
                </label>
            </v-card-title>
            <v-card-subtitle>
                Are you sure you want to delete this item?
            </v-card-subtitle>
            <v-card-text>
                <label
                    style="font-weight: 900; font-size: large;"
                >
                    Deleting this item will result a questioning, specially if someone added this item in their cart and proceed to checkout!
                </label>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="continue_delete_item"
            >
                Continue
            </v-btn>
            <v-btn
                outlined
                class="pr-10 pl-10"
                rounded
                @click="close_dialog_delete"
            >
                Cancel
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
  components: {
  },
  props: [
    'value',
    'item'
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    close_dialog_delete(){
        this.$emit('close_dialog_delete')
    },
    continue_delete_item(){
        this.$emit('continue_delete_item')
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>