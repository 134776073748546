import Vue from 'vue'

export default{
    namespaced: true,
    state: {
        service_requests: []
    },
    actions: {
        async get_record({commit}){
            await Vue.axios.get('/admin/service_request_reports/get_service_reports')
            .then(({data}) => {
                // console.log(commit, data)
                commit('set_service_requests', data.data)
            })
        }
    },
    mutations: {
        set_service_requests(state, payload){
            state.service_requests = [...payload]
        }
    },
    getters: {
        get_service_requests : state => (value) => {
            if(value === null || value === ''){
                return state.service_requests
            }
            return state.service_requests.filter(q => {
                return q.user.name.toLowerCase().includes(value)
                || q.service.service_type.toLowerCase().includes(value)
            })
        }
    }
}