<template>
    <v-container fluid>
        <v-data-table
            :headers="headers"
            :items="getClientRequestedQuotation">
            <template
                v-slot:[`item.image`]="{item}">
                <v-img
                    width="200"
                    aspect-ratio="1"
                    cover
                    :src="`${image}uploads/bom/${item.image}`"
                />
            </template>
            <template v-slot:[`item.bom_unit_cost`]="{item}">
                € {{ item.bom_unit_cost | formatNumber }}
            </template>
            <template v-slot:[`item.bom_total_cost`]="{item}">
                € {{ item.bom_total_cost | formatNumber }}
            </template>
            <template v-slot:[`item.action`]="{item}">
                <v-btn small color="success" @click="evtOpenAddQuotationDialog(item)">
                    <v-icon small class="mr-2">mdi-plus</v-icon>
                    Place Quotation
                </v-btn>
            </template>    
        </v-data-table>

        <AddQuotation
            :add_quotation_dialog="add_quotation_dialog"
            :material="selected_material"
            @evtCloseDialog="evtCloseAddQuotationDialog()"></AddQuotation>
    </v-container>
</template>

<script>
import ClientQuotation from '../modules/ClientQuotation.js'
import AddQuotation from './subcomponents/AddQuotation.vue'
import { mapGetters } from 'vuex';

export default {
    data: () => ({
        headers: [            
            {text: "Client Name", value: "client_name", width: "15%"},
            //{text: "BoM Line Item", value: "name"},
            {text: "Material Name", value: "materials.name"},    
            {text: "Image", value: "image"},
            {text: "Qty", value: "quantity"},
            {text: "Units", value: "unit"},
            {text: "Unit Cost", value: "bom_unit_cost"},
            {text: "Total Cost", value: "bom_total_cost"},
            {text: "Action", value: 'action', width: "10%"}
        ],
        client_quotation: null,
        add_quotation_dialog: false,
        selected_material: null,
        image: null,
    }),
    components: {
        AddQuotation
    },
    computed: {
        ...mapGetters({
            getuser:    'auth/getuser',
            getClientRequestedQuotation:    'client_quotation/getClientRequestedQuotationGetter'
        })
    },
    methods: {
        evtOpenAddQuotationDialog(material) {
            this.selected_material = material
            this.add_quotation_dialog = true
        },
        evtCloseAddQuotationDialog() {
            this.add_quotation_dialog = false
        }
    },
    created() {
        this.client_quotation = new ClientQuotation(this.$store),
        this.image = process.env.VUE_APP_URL
    },
    mounted() {        
        this.client_quotation.getClientRequestQuotationFromBoM(this.getuser.company.id)
    }
}
</script>