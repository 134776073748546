<template>
    <v-col
        cols="6"
        class="ml-15"
    >
        <v-card
            elevation="0"
        >
            <v-card-title>
                <label
                    style="font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 18px;"
                >
                    {{ get_product_page.name }}
                </label>
            </v-card-title>
            <v-card-subtitle>
                <v-chip-group
                    active-class="deep-purple accent-4 white--text"
                    column
                >
                    <v-chip
                        v-for="(iitem, iindex) in JSON.parse(get_product_page.chip_data)"
                        :key="iindex"
                        style="font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 12px;"
                    >{{ iitem }}</v-chip>
                </v-chip-group>
                <v-row
                    align="center"
                    class="mt-1"
                >
                    <v-rating
                        :value="get_product_page.rating"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        size="24"
                    ></v-rating>
                    <div class="grey--text ms-4">
                        {{get_product_page.rating}}
                        <small
                            v-if="get_product_page.rating == 0"
                            class="ml-5"
                            style="font-weight: 700; font-size: small;"
                        >
                            Rate this product first
                        </small>
                    </div>
                </v-row>
            </v-card-subtitle>
            <v-card-subtitle>
                <div class="my-4 text-subtitle-1">
                    <strong
                        style="font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 16px;"
                    >€</strong>
                    <strong
                        style="font-family: 'Poppins', sans-serif; font-weight: 900; font-size: 16px;"
                    >
                        {{ get_product_page.price }}
                    </strong>
                    • 
                    <label
                        style="font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 14px;"
                    >
                        {{get_product_page.location}}
                    </label>
                </div>
            </v-card-subtitle>
            <v-card-text
                v-if="!summary"
            >
                <label
                    style="font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 14px;"
                    v-html="cut_string(get_product_page.description)"
                >
                </label>
                <label
                    style="display: block; font-family: 'Poppins', sans-serif; font-weight: 600; font-size: 16px; text-decoration: underline; cursor: pointer;"
                    @click="summary = !summary"
                >
                    See more
                </label>
            </v-card-text>
            <v-card-text
                v-else
            >
                <label
                    style="font-family: 'Poppins', sans-serif; font-weight: 500; font-size: 14px;"
                    v-html="get_product_page.description"
                >
                </label>
                <label
                    style="display: block; font-family: 'Poppins', sans-serif; font-weight: 600; font-size: 16px; text-decoration: underline; cursor: pointer;"
                    @click="summary = !summary"
                >
                    See less
                </label>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-btn-toggle>
                        <v-btn
                            @click="removeQty"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                        <v-text-field
                            label="quantity"
                            v-model="qty"
                            outlined
                            dense
                            :disabled="true"
                        >
                        </v-text-field>
                        <v-btn
                            @click="addQty"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </v-row>
            </v-card-actions>
            <v-card-actions>
                <v-btn
                    color="deep-purple lighten-2"
                    outlined
                    rounded
                    class="pl-8 pr-8"
                    @click="add_to_cart(get_product_page)"
                    style="font-family: 'Poppins', sans-serif; font-weight: 700; font-size: 14px;"
                >
                    <v-icon
                        class="mr-2"
                    >
                        mdi-cart
                    </v-icon>
                    Add to cart
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    props: [
        'get_product_page'
    ],
    data : () => ({
        summary: false,
        qty: 1
    }),
    computed: {
        ...mapGetters({
            user:                   'auth/getuser',
        }),
    },
    methods: {
        cut_string(text){
            return text.substring(0, 100)
        },
        async add_to_cart(data){
            data.users_id = this.user.id
            data.qty = this.qty
            await this.$store.dispatch('shop/add_to_cart', data)
            await this.$store.dispatch('shop/fetch_cart_api', this.user.id)
            this.$router.push({path: '/shop'})
        },
        addQty(){
            this.qty++
        },
        removeQty(){
            if(this.qty >= 2){
                this.qty--
                return
            }
        }
    },
    
}
</script>