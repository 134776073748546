<template>
    <v-container fluid>
        <v-data-table
            :headers="headers"
            :items="getClientQuotedMaterials">
            <template
                v-slot:[`item.bom_materials.image`]="{item}">
                <v-img
                    width="200"
                    aspect-ratio="1"
                    cover
                    :src="`${image}uploads/bom/${item.bom_materials.image}`"
                />
            </template>
            <template v-slot:[`item.unit_cost`]="{item}">
                € {{ parseFloat(item.unit_cost).toFixed(2) }}
            </template>
            <template v-slot:[`item.total_cost`]="{item}">
                {{ parseFloat(item.bom_materials.quantity * item.unit_cost).toFixed(2) }}
            </template>
            <template v-slot:[`item.action`]="{item}">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn fab text small color="success" 
                            @click="evtOpenEditQuotedMaterialDialog(item)"
                            v-bind="attrs"
                            v-on="on">
                            <v-icon small>mdi-pen</v-icon>
                        </v-btn>
                    </template>
                    <span>Edit the unit cost of a quoted material.</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <EditQuotation
            :edit_quotation_dialog="edit_quotation_dialog"
            :quoted_material="selected_material"
            @evtCloseDialog="evtCloseEditClientQuotedMaterial()"></EditQuotation>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientQuotation from '../modules/ClientQuotation.js'
import EditQuotation from './subcomponents/EditQuotation.vue'

export default {
    data: () => ({
        headers: [
            {text: "Client Name", value: "client_name"},
            {text: "Material", value: "supplier_material.name"},
            {text: "Image", value: "bom_materials.image"},
            {text: "Qty", value: "bom_materials.quantity"},
            {text: "Units", value: "supplier_material.unit"},
            {text: "Unit Cost", value: "unit_cost"},
            {text: "Total Cost", value: "total_cost"},
            {text: "Action", value: "action"}
        ],
        client_quotation: null,
        edit_quotation_dialog: false,
        selected_material: null,
        image: null
    }),
    components: {
        EditQuotation
    },
    computed: {
        ...mapGetters({
            getClientQuotedMaterials:           'client_quotation/getClientQuotedMaterialsGetter',
            getuser:                            'auth/getuser',
        })
    },
    methods: {
        evtOpenEditQuotedMaterialDialog(item) {
            this.selected_material = item
            this.edit_quotation_dialog = true
        },
        evtCloseEditClientQuotedMaterial() {
            this.edit_quotation_dialog = false
        }
    },
    created() {
        this.client_quotation = new ClientQuotation(this.$store),
        this.image = process.env.VUE_APP_URL        
    },
    mounted() {
        this.client_quotation.getClientQuotedMaterials(this.getuser.company.id)   
    }
}
</script>