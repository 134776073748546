import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        uploaded_files: [],
        service_types: [],
        counties: [],
        to_delete: {}
    },
    mutations: {
        set_uploaded_files(state, payload){
            state.uploaded_files = [...payload]
        },
        set_service_types(state, payload){
            state.service_types = [...payload]
        },
        set_counties(state, payload){
            state.counties = [...payload]
        },
        set_to_delete(state, payload){
            state.to_delete = payload
        }
    },
    getters: {
        get_uploaded_files : state => value => {
            if(value === null || value === ''){
                return state.uploaded_files
            }
            return state.uploaded_files.filter(q => {
                return q.name.toLowerCase().includes(value)
            })
        },
        get_service_types : state => state.service_types,
        get_counties : state => state.counties,
        get_to_delete : state => state.to_delete
    },
    actions: {
        set_uploaded_files({commit}, payload){
            commit('set_uploaded_files', payload)
        },
        async set_fetch_data({commit}){
            await Vue.axios.get('upload_plans/g_uploaded_files')
            .then(({data}) => {
                commit('set_uploaded_files', data.data)
            })
        },
        set_service_types({commit}, payload){
            commit('set_service_types', payload)
        },
        set_counties({commit}, payload){
            commit('set_counties', payload)
        },
        set_to_delete({commit}, payload){
            commit('set_to_delete', payload)
        }
    }
}