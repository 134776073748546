<template>
    <div>
        <BCrumbs
            :get_bcrumbs="get_bcrumbs"
        />
        <v-container
            class="pa-0 mt-10"
            style="width: 1080px;"
        >
            <v-row
                no-gutters
            >
                <ImageComponent
                    :get_product_page="get_product_page"
                />
                <ProductDetails 
                    :get_product_page="get_product_page"
                />
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BCrumbs from './components/BreadCrumbs.vue'
import ImageComponent from './components/ImageComponent.vue'
import ProductDetails from './components/ProductDetails.vue'
export default {
    data : () => ({
    }),
    components: {
        BCrumbs,
        ImageComponent,
        ProductDetails
    },
    computed: {
        ...mapGetters({
            get_bcrumbs:            'shop/get_bcrumbs',
            get_product_page:       'shop/get_product_page',
        }),
        // isMobile() {
        //     if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //         return true
        //     } else {
        //         return false
        //     }
        // }
    }
}
</script>