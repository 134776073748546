import store from '@/states/index.js'

import SupplierDashboard from '../SupplierComponent'

export default [
    {
        path: '/supplier_dashboard',
        component: SupplierDashboard,
        name: 'supplier_dashboard',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 1){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]