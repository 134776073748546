import store from '@/states/index.js'

import VideoArchiveComponent from '../VideoArchiveComponent.vue'

export default [
    {
        path: '/video_archives',
        component: VideoArchiveComponent,
        name: 'video_archives',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            // console.log(role?.role)
            if(role?.role && role.info.role === 3){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]