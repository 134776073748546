<template>
    <v-container
        fluid
        class="pa-0 ma-0"
    >
        <v-row
            justify="center"
            align="center"
            class="mt-5"
            no-gutters
        >
            <v-card
                width="80%"
                elevation="0"
            >
                <v-card-title>
                    <v-row>
                        <!-- <v-col
                            cols="8"
                        >
                            <v-text-field
                                label="search here..."
                                prepend-inner-icon="mdi-magnify"
                                outlined
                                dense
                                v-model="search_model"
                            ></v-text-field>
                        </v-col> -->
                        <v-col
                            cols="4"
                        >
                            <v-btn
                                icon
                                @click="view_cart"
                            >
                                <v-badge
                                    color="green"
                                    :content="`${get_cart.length}`"
                                >
                                    <v-icon>
                                        mdi-cart
                                    </v-icon>
                                </v-badge>
                            </v-btn> 
                        </v-col>                 
                    </v-row>
                    <v-spacer/>
                </v-card-title>
                <v-card-text>
                    <category
                        :data="get_category_list"
                        :sub_data="get_sub_categories"
                    />
                </v-card-text>
                <v-card-text
                    class="mt-5"
                >
                    <v-text-field
                        dense
                        label="Seach here..." 
                        outlined
                        prepend-inner-icon="mdi-magnify"
                        v-model="search_model"
                    >
                    </v-text-field>
                    <items
                        :data="get_category_cart"
                    />
                </v-card-text>
            </v-card>
        </v-row>
        <view_cart
            :value="view_cart_model"
            @close_dialog="close_dialog"
        />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import items from './components/ItemComponent.vue'
import category from './components/CategoryList.vue'
import view_cart from './components/CartComponent.vue'
export default {
  components: {
    items,
    category,
    view_cart
  },
  props: [
  ],
  data () {
    return {
        search_model: null,
        view_cart_model: false
    }
  },
  async mounted () {
    await this.$store.dispatch('shop/fetch_categories')
    await this.$store.dispatch('shop/fetch_items')
    await this.$store.dispatch('shop/fetch_cart_api', this.get_user.id)
    await this.$store.dispatch('shop/fetch_checked_out_items', this.get_user.id)
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_category_list:      'shop/get_category_list',
        get_cart:               'shop/get_cart',
        get_user:               'auth/getuser',
        // get_category_cart:      'shop/get_category_cart',
        get_sub_categories:     'shop/get_sub_categories'
    }),
    get_items(){
        return this.$store.getters['shop/get_items']
    },
    get_category_cart(){
        return this.$store.getters['shop/get_category_cart'](this.search_model)
    }
  },
  methods: {
    view_cart(){
        this.view_cart_model = true
    },
    close_dialog(){
        this.view_cart_model = false
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>