import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        user_list: [],
        to_delete_user: {},
        edit_user: {},
        supplier_click_trackers: []
    },
    mutations: {
        set_user_list(state, payload) {
            state.user_list = [...payload]
        },
        set_to_delete_user(state, payload) {
            state.to_delete_user = payload
        },
        set_edit_user(state, payload) {
            state.edit_user = payload
        },
        set_supplier_click_trackers_mutations(state, payload) {
            state.supplier_click_trackers = [...payload];
        }
    },
    getters: {
        get_user_list: state => value => {
            if (value === null || value === '') {
                return state.user_list
            }
            return state.user_list.filter(q => {
                return q.email.toLowerCase().includes(value)
                    || q.name.toLowerCase().includes(value)
            })
        },
        get_to_delete_user: state => state.to_delete_user,
        get_edit_user: state => state.edit_user,
        get_supplier_click_trackers_getters: state => state.supplier_click_trackers,
    },
    actions: {
        set_user_list({ commit }, payload) {
            commit('set_user_list', payload)
        },
        set_to_delete_user({ commit }, payload) {
            commit('set_to_delete_user', payload)
        },
        set_edit_user({ commit }, payload) {
            commit('set_edit_user', payload)
        },
        async fetch_supplier_click_tracker_data_actions({ commit }, payload) {           
            await Vue.axios.get(`supplierdirectory/g_tracked_clicks/${payload}`).then(({data}) => {
                commit('set_supplier_click_trackers_mutations', data.data);
            });
        }
    }
}