<template>
    <v-container fluid>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                :width="isMobile() ? '100%' : '50%'"
                class="mt-15"
            >
                <v-card-title class="text-h4">
                    <label>Group Buying Scheme<br/>Construction Materials</label>
                </v-card-title>
                <v-card-title>
                    <small
                        style="font-size: 14px;"
                    >We are in the process of finalising a new feature in our self build software (Self Build Helper). This feature will allow you to receive significant discounts on materials/supplies in your locality, for your projects.<br/>However, in order to allow us to negotiate the best prices for you, we need to know some up to date details about your self build/renovation.<br/>Please fill out the details below and we will be in contact once this feature is finalised.<br/>Lets make your Self Build a success..<br/>Warmest Regards,<br/>The Self Build and Renovations Team.</small>
                </v-card-title>
            </v-card>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                :width="isMobile() ? '100%' : '50%'"
                class="mt-5"
            >
                <v-card-title>
                    Email *
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        placeholder="Your answer"
                        hint="This is a required question"
                        v-model="c.email"
                        :disabled="!is_valid"
                    >
                    </v-text-field>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                :width="isMobile() ? '100%' : '50%'"
                class="mt-5"
            >
                <v-card-title>
                    Full Name *
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        placeholder="Your answer"
                        hint="This is a required question"
                        v-model="c.fullname"
                        :disabled="!is_valid"
                    >
                    </v-text-field>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                :width="isMobile() ? '100%' : '50%'"
                class="mt-5"
            >
                <v-card-title>
                    Contact Number
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        placeholder="Your answer"
                        v-model="c.contact_number"
                        :disabled="!is_valid"
                    >
                    </v-text-field>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                :width="isMobile() ? '100%' : '50%'"
                class="mt-5"
            >
                <v-card-title>
                    Build County
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        placeholder="Your answer"
                        v-model="c.build_county"
                        :disabled="!is_valid"
                    >
                    </v-text-field>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                :width="isMobile() ? '100%' : '50%'"
                class="mt-5"
            >
                <v-card-title>
                    Build Address
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        placeholder="Your answer"
                        v-model="c.address"
                        :disabled="!is_valid"
                    >
                    </v-text-field>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                :width="isMobile() ? '100%' : '50%'"
                class="mt-5"
            >
                <v-card-title>
                    Build Eircode (if you have one)
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        placeholder="Your answer"
                        v-model="c.eircode"
                        :disabled="!is_valid"
                    >
                    </v-text-field>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                :width="isMobile() ? '100%' : '50%'"
                class="mt-5"
            >
                <v-card-title>
                    Build Route *
                </v-card-title>
                <v-card-text>
                    <v-radio-group 
                        v-model="c.build_route"
                        :disabled="!is_valid"
                    >
                        <v-radio
                            v-for="(n, i) in build_route_items"
                            :key="i"
                            :label="n.label"
                            :value="n.label"
                            class="mt-3"
                        ></v-radio>
                    </v-radio-group>
                    <v-text-field
                        v-if="c.build_route === 'Other'"
                        placeholder="Your answer"
                        hint="This is a required question"
                        v-model="c.build_route_other"
                    >

                    </v-text-field>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                :width="isMobile() ? '100%' : '50%'"
                class="mt-5"
            >
                <v-card-title>
                    Build Type *
                </v-card-title>
                <v-card-text>
                    <v-radio-group 
                        v-model="c.build_type"
                        :disabled="!is_valid"
                    >
                        <v-radio
                            v-for="(n, i) in build_type_items"
                            :key="i"
                            :label="n.label"
                            :value="n.label"
                            class="mt-3"
                        ></v-radio>
                    </v-radio-group>
                    <v-text-field
                        v-if="c.build_type === 'Other'"
                        placeholder="Your answer"
                        hint="This is a required question"
                        v-model="c.build_type_other"
                    >
                    </v-text-field>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                :width="isMobile() ? '100%' : '50%'"
                class="mt-5"
            >
                <v-card-title>
                    Build Stage (What's happening on site?)
                </v-card-title>
                <v-card-text>
                    <v-radio-group 
                        v-model="c.build_stage"
                    >
                        <v-radio
                            v-for="(n, i) in build_stage_items"
                            :key="i"
                            :label="n.label"
                            :value="n.value"
                        ></v-radio>
                    </v-radio-group>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                :width="isMobile() ? '100%' : '50%'"
                class="mt-5"
            >
                <v-card-title>
                    Would you like an invite to our free webinar for self builders & renovators to learn how you can save time and money on your build?
                </v-card-title>
                <v-card-text>
                    <v-radio-group 
                        v-model="c.invite"
                        :disabled="!is_valid"
                    >
                        <v-radio
                            v-for="(n, i) in invite_items"
                            :key="i"
                            :label="n.label"
                            :value="n.label"
                            class="mt-3"
                        ></v-radio>
                    </v-radio-group>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                :width="isMobile() ? '100%' : '50%'"
                class="mt-5"
                elevation="0"
                :disabled="!is_valid"
            >
                <v-card-actions>
                    <v-btn
                        text
                        style="textTransform: none !important;"
                        @click="clear_form"
                    >
                        Clear form
                    </v-btn>
                    <v-spacer/>
                    <v-btn
                        color="primary"
                        class="pl-10 pr-10"
                        style="textTransform: none !important;"
                        @click="submit_form_fn"
                    >
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
            <v-card
                :width="isMobile() ? '100%' : '50%'"
                class="mt-5"
                elevation="0"
            >
                <v-card-title>
                    <small>Never submit passwords through the form.</small>
                </v-card-title>
            </v-card>
        </v-row>
    </v-container>
</template>
  
<script>
  export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        c: {
            email: null,
            fullname: null,
            contact_number: null,
            build_county: null,
            address: null,
            eircode: null,
            build_route: null,
            build_route_other: null,
            build_type: null,
            build_type_other: null,
            build_stage: null,
            invite: null
        },
        build_route_items: [
            {
                label:      'Self Build'
            },
            {
                label:      'Contractor'
            },
            {
                label:      'Not sure yet'
            },
            {
                label:      'Other'
            }
        ],
        build_type_items: [
            {
                label:      'Block'
            },
            {
                label:      'Timber Frame'
            },
            {
                label:      'ICF'
            },
            {
                label:      'Other'
            }
        ],
        invite_items: [
            {
                label:      'Yes'
            },
            {
                label:      'No'
            }
        ],
        build_stage_items: [
            {
                label:      'Research',
                value:      'Research'
            },
            {
                label:      'Waiting on planning information',
                value:      'Waiting on planning information'
            },
            {
                label:      'Planning approved breaking ground soon',
                value:      'Planning approved breaking ground soon'
            },
            {
                label:      'Just broke ground',
                value:      'Just broke ground'
            },
            {
                label:      'Building up walls',
                value:      'Building up walls'
            },
            {
                label:      'Roof on.. house sealed',
                value:      'Roof on.. house sealed'
            }
        ],
        is_valid: true
    }),
    async mounted () {
        this.verify_token()
    },
    created () {
    },
    computed: {
    },
    methods: {
        showSnackBar(message){
            this.$store.commit("auth/setMessage", 
            {show: true, message: message}, 
            {root: 1})
        },
        clear_form(){
            this.c = {}
        },
        async verify_token(){
            await this.$axios.get('verify_token', {
                id:     this.$router.currentRoute.params.token
            })
            .then(({data}) => {
                if(data.data.length === 0){
                    this.showSnackBar('Invalid Link')
                    this.is_valid = false
                }
            })
        },
        async submit_form_fn(){
            this.showSnackBar('Please wait...')
            this.$axios.post('/form_submit', {
                tp: this.c,
                token:  this.$router.currentRoute.params.token
            })
            .then(({data}) => {
                if(data.response){
                    this.showSnackBar('Submitting request successful!')
                    this.clear_form()
                    return
                }
                this.showSnackBar(data.message)
                return
            })
        },
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
  }
  </script>
  
<style scoped lang="scss">
</style>