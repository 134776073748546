<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="tableheader"
          :items="getunquoted"
          class="elevation-1 table"
          :expanded.sync="expanded"
          single-expand
          item-key="id"
          :search="search"
        >
          <template v-slot:top>
            <v-card
              class="elevation-1"
            >
              <v-card-title style="fontSize: 18px;">
                Unquoted Requests
              </v-card-title>
              <v-card-subtitle>
                <v-text-field
                  v-model="search"
                  label="search"
                  placeholder="just looking"
                  hint="search project name, build stage"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                ></v-text-field>
              </v-card-subtitle>
            </v-card>
          </template>
          <template v-slot:item="{ item, expand, isExpanded }" >
            <tr
              style="cursor:pointer;" 
              @click="expand(!isExpanded)"
            >
              <td><strong>{{ item.project_name }} <small style="display: block; color: blue;" v-if="item.deleted_at != null">request deleted</small></strong></td>
              <td><strong>{{ item.build_stage }} <small style="display: block; color: blue;" v-if="item.deleted_at != null">request deleted</small></strong></td>
              <td><strong>{{ item.user.name }} <small style="display: block; color: blue;" v-if="item.deleted_at != null">request deleted</small></strong></td>
              <td><strong>{{ item.address_line }} {{ item.city }}, {{ item.state }}, {{ item.country }}, {{ item.postal_code }} <small style="display: block; color: blue;" v-if="item.deleted_at != null">request deleted</small></strong></td>
              <td><strong>{{ item.description }} <small style="display: block; color: blue;" v-if="item.deleted_at != null">request deleted</small></strong></td>
              <td>
                <div
                  v-if="item.attachments !== null"
                >
                  <div v-if="item.attachments[0].split('.').pop() === 'png' || item.attachments[0].split('.').pop() == 'jpg'">
                    <img :src="`${img_url}frontend/images/${item.attachments}`" style="max-width:250px; max-height:250px;"/>
                  </div>
                  <div v-if="item.attachments[0].split('.').pop() === 'pdf'">
                    <img :src="`https://media.istockphoto.com/vectors/icon-major-file-format-vector-icon-illustration-vector-id1298834280?k=20&m=1298834280&s=612x612&w=0&h=SxEbyHKi18H1XpPpKKzoppWgMM3x0tc3veN5e6wl7Y8=`" style="max-width:125px; max-height:125px;"/>
                  </div>
                  <div v-if="item.attachments[0].split('.').pop() === 'docx'">
                    <img :src="`https://seeklogo.com/images/M/microsoft-word-logo-E648C182A5-seeklogo.com.png`" style="max-width:125px; max-height:125px;"/>
                  </div>
                  <div v-if="item.attachments[0].split('.').pop() === 'xlsx'">
                    <img :src="`https://seeklogo.com/images/E/excel-logo-974BFF9CB9-seeklogo.com.png`" style="max-width:125px; max-height:125px;"/>
                  </div>
                </div>
                <div
                  v-else
                >
                  no attachment
                </div>
              </td>
              <!-- <td><img :src="`${url}frontend/images/${item.attachments}`" style="max-width: 150px; max-height: 150px;"/></td> -->
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
            <v-tabs
              v-model="tab"
              icons-and-text
            >
              <v-tabs-slider></v-tabs-slider>
                <v-tab
                  v-for="(tab, tabindex) in tabdata"
                  :key="tabindex"
                  :href="tab.href"
                >
                  <small><label><v-icon class="mr-3">{{tab.icon}}</v-icon>{{tab.label}}</label></small>
                </v-tab>
                <v-tabs-items v-model="tab">
                  <v-tab-item
                    value="tab-1"
                  >
                    <v-card>
                      <v-card-title style="fontSize: 18px;">
                        <small>Project Type: {{item.project_name}}</small>
                        <v-spacer></v-spacer>
                        <small style="fontSize: 14px;">Description: {{item.description}}</small>
                      </v-card-title>
                      <v-card-subtitle>
                        Build Stage: {{item.build_stage}}
                      </v-card-subtitle>
                      
                      <v-card-text>
                        <v-divider></v-divider>
                        <v-row>
                          <v-col
                            cols="12"
                            class="mt-5"
                          >
                            <v-text-field
                              disabled
                              label="client name"
                              :value="item.user.name"
                              outlined
                              dense
                            >
                            </v-text-field>
                          </v-col>
                          <v-col>
                            Address Information
                            <v-row>
                              <v-col
                                cols="3"
                              >
                                <v-text-field
                                  disabled
                                  label="address line"
                                  :value="item.address_line"
                                  outlined
                                  dense
                                >
                                </v-text-field>
                              </v-col>
                              <v-col
                                cols="3"
                              >
                                <v-text-field
                                  disabled
                                  label="city/town | state/county"
                                  :value="item.city + ', ' + item.state"
                                  outlined
                                  dense
                                >
                                </v-text-field>
                              </v-col>
                              <v-col
                                cols="3"
                              >
                                <v-text-field
                                  disabled
                                  label="country"
                                  :value="item.country"
                                  outlined
                                  dense
                                >
                                </v-text-field>
                              </v-col>
                              <v-col
                                cols="3"
                              >
                                <v-text-field
                                  disabled
                                  label="eircode | zipcode"
                                  :value="item.postal_code"
                                  outlined
                                  dense
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            <small>scope of work</small>
                            <ul>
                              <li
                                v-for="(item, itemindex) in item.descriptions"
                                :key="itemindex"
                              >
                                {{item}}
                              </li>
                            </ul>
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            Attachments
                            <br>
                            <div v-if="item.attachments != null">
                              <v-btn
                                v-for="(attachment, index) in item.attachments"
                                :key="index"
                                style="display: inline-block;margin-right:5px;margin-bottom:5px;"
                                outlined
                                @click="download_attachment(item.attachments[index])"
                              >
                                <v-icon
                                  class="mr-3"
                                >
                                  mdi-briefcase-download-outline                              
                                </v-icon>
                                Download Attachment By Client
                              </v-btn>
                            </div>
                            <div v-else>
                              None
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                    value="tab-2"
                  >
                    <v-card>
                      <v-card-title
                        style="fontSize: 18px;"
                      >
                        <small>place quotation to: {{item.project_name}} | {{item.user.name}}</small>
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            v-for="(item, itemindex) in quotation_object.textfield"
                            :key="itemindex"
                          >
                            <v-text-field
                              v-if="item.ttype == 'input'"
                              v-model="quotation.price"
                              :label="item.label"
                              :type="item.type"
                              :prepend-inner-icon="item.icon"
                              outlined
                              dense
                            >
                            </v-text-field>
                            <v-textarea
                              v-model="quotation.description"
                              v-if="item.ttype == 'textarea'"
                              :label="item.label"
                              :hint="item.hint"
                              :prepend-inner-icon="item.icon"
                              outlined
                            ></v-textarea>
                            <v-file-input
                              v-if="item.ttype == 'attachment'"
                              v-model="quotation.attachment"
                              accept="image/*"
                              :label="item.label"
                              outlined
                              dense
                            ></v-file-input>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          @click="cancelquote"
                        >
                          cancel
                        </v-btn>
                        <v-btn
                          color="primary"
                          dark
                          class="pl-16 pr-16"
                          @click="sendquotation(item)"
                        >
                          place quotation
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                    value="tab-3"
                    v-if="item.get_chat == null"
                  >
                    <v-card>
                      <v-card-title>
                        Chat with client
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                          >
                            <v-textarea
                              placeholder="Your message here"
                              outlined
                              label="Your message here"
                              v-model="message"
                            >
                            </v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn
                          v-if="item.get_chat == null"
                          color="primary"
                          dark
                          class="pl-10 pr-10"
                          @click="aend_message_to_client(item)"
                        >
                          Send Message
                        </v-btn>
                        <v-btn
                          v-else
                          color="primary"
                          text
                          class="pl-10 pr-10"
                          outlined
                        >
                          Message has been sent
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                    value="tab-3"
                    v-else
                    class="text-center mt-15 mb-15"
                  >
                    <div
                      style="border: 1px solid black;"
                      class="pa-15"
                    >
                      <strong>Message has been sent, please use inbox</strong>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    img_url: null,
    expanded: [],
    toolsIndex: null,
    search: '',
    url: null,
    tableheader: [
      { text: 'Project Name', value: 'project_name', class: 'headerColorToBlue' },
      { text: 'Build Stage', value: 'build_stage', class: 'headerColorToBlue' },
      { text: 'Client Name' },
      { text: 'Address' },
      { text: 'Description' },
      { text: 'Attachment' }
    ],
    tab: null,
    tabdata: [
      {
        label:        'Project Information',
        href:         '#tab-1',
        icon:         'mdi-information'
      },
      {
        label:        'Chat Client',
        href:         '#tab-3',
        icon:         'mdi-chat'
      },
      {
        label:        'Place Quotation',
        href:         '#tab-2',
        icon:         'mdi-alert-circle'
      }
    ],
    quotation_object: {
      textfield: [
        {
          id:         1,
          ttype:      'input',
          label:      'price',
          hint:       'price',
          type:       'number',
          icon:       'mdi-currency-usd'
        },
        {
          id:         2,
          label:      'description',
          hint:       'description',
          ttype:      'textarea',
          type:       'text',
          icon:       'mdi-comment-text'
        },
        {
          id:         3,
          label:      'attachment',
          hint:       'attachment',
          ttype:      'attachment',
        }
      ]
    },
    quotation: {
      price: null,
      attachment: null,
      description: null
    },
    message: null
  }),
  mounted () {
    if(localStorage.getItem('token') === null){
      localStorage.clear()
      this.$router.push({name: 'login'})
      location.reload(true)
    }
    this.url = process.env.VUE_APP_URL
  },
  created () {
    this.get_unqouted()
    this.img_url = process.env.VUE_APP_URL
  },
  computed: {
    ...mapGetters({
      getunquoted:                    'supplier_requests/getunquoted'
    })
  },
  methods: {
    download_attachment(data){
      window.open(this.url+ 'frontend/images/' + data)
    },
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async get_unqouted(){
      await this.$axios.get('supplier/requests/get_unquoted')
      .then(({data}) => {
        console.log(data)
        if(data.response){
          this.$store.dispatch('supplier_requests/setunquoted', data.data)
        }
      })
    },
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
    cancelquote(){
      this.quotation = {}
    },
    async sendquotation(request_information) {
      console.log(this.quotation.attachment)
      const formData = new FormData()
      formData.append('request_id', request_information.id)
      formData.append('price', this.quotation.price)
      formData.append('description', this.quotation.description)
      formData.append('attachment', this.quotation.attachment)
      await this.$axios.post('/supplier/quotes/send_quotation', formData)
      .then(({data}) => {
        if(data.response){
          this.showSnackBar(data.message)
          this.quotation = {}
          this.tab = null
          this.get_unqouted()
        }else{
          this.showSnackBar(data.message)
        }
      })
    },
    async aend_message_to_client(data){
      this.showSnackBar('Please wait...')
      await this.$axios.post('/supplier/inbox/send_message', {
        chat_id:                  null,
        message:                  this.message,
        receiver_id:              data.user.id,
        request_id:               data.id,
        parent_convo:             {
          client:                 {
            email:                data.user.email,
            name:                 data.user.name
          },
          request:                {
            project_name:         data.project_name
          }
        }
      })
      .then(({data}) => {
        if(data.response){
          this.message = null
          this.get_unqouted()
        }else{
          this.showSnackBar(data.message)
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.divider {
  display: flex;
  align-items: center;
  text-align: center;
}

/* To show the lines on right 
and left sides of the text */
.divider::after,
.divider::before {
  content: "";
  border: 0.1px solid gray;
  flex: 1;
}

/* Space on left and right sides of text */
.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}
.table >>> th {
  font-size: 0.8rem !important; 
}
</style>