import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        conversation: [],
        convo: [],
        parent_convo: null,
        conversation_supplier_directory: [],
        convo_supplier_directory: [],
        parent_convo_supplier_directory: null,
        convo_upload_plan: [],
        conversation_upload_plan: [],
        service_supplier: [],
        service_supplier_conversation: []
    },
    mutations: {
        set_conversation(state, payload){
            state.conversation = [...payload]
        },
        set_convo(state, payload){
            state.convo = [...payload]
        },
        set_parent_convo(state, payload){
            state.parent_convo = payload
            const data = state.conversation.find(q => {
                return q.id === payload.id
            })
            data.is_read = 1
        },
        set_conversation_supplier_directory(state, payload) {
            state.conversation_supplier_directory = [...payload]
        },
        set_convo_supplier_directory(state, payload) {
            state.convo_supplier_directory = [...payload]
        },
        set_parent_convo_supplier_directory(state, payload) {
            state.parent_convo_supplier_directory = payload
            const data = state.conversation_supplier_directory.find(q => {
                return q.id === payload.id
            })
            data.is_read = 1
        },
        set_convo_upload_plan(state, payload){
            state.convo_upload_plan = [...payload]
        },
        set_conversation_upload_plan(state, payload){
            state.conversation_upload_plan = [...payload]
        },
        clean_conversation_upload_plan(state){
            state.conversation_upload_plan = []
        },
        set_service_supplier(state, payload){
            state.service_supplier = [...payload]
        },
        set_service_supplier_conversation(state, payload){
            state.service_supplier_conversation = [...payload]
        }
    },
    getters: {
        get_conversation : state => value => {
            if(value == null || value == ''){
                return state.conversation
            }
            return state.conversation.filter(q => {
                if(q.request != null){
                    return q.request.project_name.toLowerCase().includes(value)
                }
                return q.client.name.toLowerCase().includes(value)
            })
        },
        get_convo : state => state.convo,
        get_parent_convo : state => state.parent_convo,
        get_conversation_supplier_directory : state => value => {
            if(value == null || value == ''){
                return state.conversation_supplier_directory
            }
            return state.conversation_supplier_directory.filter(q => {
                return q.client.name.toLowerCase().includes(value)
            })
        },
        get_convo_supplier_directory: state => state.convo_supplier_directory,
        get_parent_convo_supplier_directory : state => state.parent_convo_supplier_directory,
        get_convo_upload_plan : state => value => {
            if(value == null || value == ''){
                return state.convo_upload_plan
            }
        },
        get_conversation_upload_plan : state => state.conversation_upload_plan,
        get_service_supplier : state => state.service_supplier,
        get_service_supplier_conversation : state => state.service_supplier_conversation
    },
    actions: {
        set_conversation({commit}, payload){
            commit('set_conversation', payload)
        },
        set_convo({commit}, payload){
            commit('set_convo', payload)
        },
        set_parent_convo({commit}, payload){
            commit('set_parent_convo', payload)
        },
        set_conversation_supplier_directory({commit}, payload){
            commit('set_conversation_supplier_directory', payload)
        },
        set_convo_supplier_directory({commit}, payload){
            commit('set_convo_supplier_directory', payload)
        },
        set_parent_convo_supplier_directory({commit}, payload){
            commit('set_parent_convo_supplier_directory', payload)
        },
        async s_convo_from_server({commit}){
            await Vue.axios.get('/inbox/getChats')
            .then(({data}) => {
                commit('set_conversation', data.data)
            })
        },
        set_convo_upload_plan({commit}, payload){
            commit('set_convo_upload_plan', payload)
        },
        set_conversation_upload_plan({commit}, payload){
            commit('set_conversation_upload_plan', payload)
        },
        async set_service_supplier({commit}){
            await Vue.axios.get('/from_form/get_data')
            .then(({data}) => {
                commit('set_service_supplier', data.data)
            })
        },
        set_service_supplier_conversation({commit}, payload){
            commit('set_service_supplier_conversation', payload)
        }
    }
}