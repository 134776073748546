import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        users: [],
        user_projects: [],
        users_project_directories: []
    },
    mutations: {
        set_users_mutations(state, payload) {
            state.users = [...payload];
        },
        set_users_project_mutations(state, payload) {
            state.user_projects = [...payload];           
        },
        set_users_project_to_empty_mutations(state, payload) {
            state.user_projects = payload;
            state.users_project_directories = payload;
        },
        set_users_project_directories(state, payload) {
            state.users_project_directories = [...payload];
        },
        update_users_directory_media_mutations(state, payload) {
            const index = state.users_project_directories.findIndex(directory => directory.id == payload.directory_id)
            state.users_project_directories[index].getmedia.push(payload);
            state.users_project_directories[index].contains += 1;
            state.users_project_directories[index].total_size += payload.size;
        }
    },
    getters: {
        get_users_getters: state => state.users.filter(user => user.role > 1),
        get_users_project_getters: state => state.user_projects,
        get_users_project_directories_getters: state => state.users_project_directories,
    },
    actions: {
        async fetch_userslist_actions({commit}) {
            await Vue.axios.get('admin/users/f_users').then( ({data}) => {
                if (data.response) {                   
                    commit('set_users_mutations', data.data);
                } else {
                    console.log(data);
                }
            });
        },
        async fetch_users_project_actions({commit}, payload) {            
            await Vue.axios.get(`admin/assest_uploader/get_projects_by_users/${payload}`)
            .then( ({data}) => {
                commit('set_users_project_mutations', data.data);                
            });           
        },
        async fetch_users_directories_actions({commit}, payload) {
            await Vue.axios.get(`admin/assest_uploader/get_directories_by_project/${payload.project_id}`)
            .then( ({data}) => {
                commit('set_users_project_directories', data.data)
            });
        },
        set_users_project_to_empty_actions({commit}) {
            commit('set_users_project_to_empty_mutations', []);
        },
        async add_user_media_action({commit}, payload) {           
            await Vue.axios.post(`admin/assest_uploader/upload_media_file`, payload.formData)
            .then( ({data}) => {               
                commit('update_users_directory_media_mutations', data.data);
            });
        }
    }
}