import store from '@/states/index.js'

import TipsAdmin from '../TipsAdmin'

export default [
    {
        path: '/tips_admin',
        component: TipsAdmin,
        name: 'tips_admin',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if (role.info.role === 1) {
                next()
            } else {
                next('/')
            }
            return
        }
    }
]