export default {
    namespaced: true,
    state: {
        suppliers: [],
        materials: []
    },
    mutations: {
        set_suppliers(state, payload) {
            state.suppliers = [...payload]
        },
        set_materials(state, payload) {
            state.materials = [...payload]
        },
    },
    getters: {
        get_suppliers(state) {
            return state.suppliers
        },
        get_materials(state) {
            return state.materials
        }
    },
    actions: {
        set_suppliers({ commit }, payload) {
            commit('set_suppliers', payload)
        },
        set_materials({ commit }, payload) {
            commit('set_materials', payload)
        },
    }
}