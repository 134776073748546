<template>
    <v-container fluid class="mt-5 flex">
        <div
            v-if="ismainloading"
        >
            <v-col
                cols="12"
                md="12"
            >
                <v-skeleton-loader
                type="article"
                v-for="index in 5"
                :key="index"
                ></v-skeleton-loader>
            </v-col>
        </div>
        <div v-else>
            <DataTable
                :setup="tablesetup" 
                :items="get_suppliers"
                :user_info="get_user_info"
            />
        </div>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from './components/DataTable.vue'
export default {
    components: {
        DataTable
    },
    props: [
    ],
    data: () => ({
        ismainloading: true,
        tablesetup: {
            name: 'Trade Rate Supplier Directory',
            icon: 'mdi-account',
            table_header: [
                { text: 'Supplier', value: 'supplier_name', sortable: false, width: '15%' },
                { text: 'Materials', value: 'materials', sortable: false, width: '25%' },
                { text: 'Website', value: 'website', sortable: false, width: '25%' },
                { text: 'Notes', value: 'notes', sortable: false },
            ],
        },
    }),
    mounted() {
        this.fetchsuppliers()
    },
    created() {
    },
    computed: {
        ...mapGetters({
            get_user_info:  'auth/getuser',
            get_suppliers:  'trade_rate_suppliers/get_suppliers'
        }),
    },
    methods: {
        async fetchsuppliers() {
            await this.$axios.get('admin/trade_rate_suppliers/get_suppliers')
                .then(({ data }) => {
                    console.log(data)
                    if (data.response) {
                        this.$store.dispatch('trade_rate_suppliers/set_suppliers', data.data)
                        this.ismainloading = false
                    }
                })
        },
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">

</style>