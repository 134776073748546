import store from '@/states/index.js'

import ProjectPlanner from '../ProjectPlannerV2.vue'

export default [
    {
        path: '/projectplannerv2',
        component: ProjectPlanner,
        name: 'projectplannerv2',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 3){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]