<template>
    <div>
        <v-list>
            <v-list-group
                v-for="data in getdecisionsdata"
                :key="data.id"
                v-model="data.active"
                no-action
                :disabled="user_access(data.item)">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title style="color: #0b4ef3;">
                            <v-badge
                                color="#0b4ef3"
                                bordered>
                                <v-icon slot="badge" v-if="user_access(data.item)">mdi-exclamation-thick</v-icon>
                                <v-icon slot="badge" v-else>mdi-check-bold</v-icon>
                                <span slot="default"><strong>{{data.item}}</strong><br/><small>{{data.getnotdone_count}} task(s) remaining</small></span>
                            </v-badge>
                            <v-progress-linear
                                :value="compute_progress(data)"
                                striped
                                height="25"
                                color="#7b9ff9">
                                <template v-slot:default="{ value }">
                                    <strong>{{ value.toFixed(2) }}%</strong>
                                </template>
                            </v-progress-linear>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-data-table
                    :headers="headers"
                    :items="data.child"
                    item-key="task_name"
                    class="elevation-1 custom-table"
                    style="font-weight:700;"
                    :hide-default-footer="true"
                    disable-pagination
                    mobile-breakpoint="0">
                    <template v-slot:item="{item}">
                        <tr v-if="child_access(data.item, item.item) == false">
                            <td><v-checkbox v-model="item['is_done']" @change="markdonedecision(item)"></v-checkbox></td>
                            <td @click="decisionaddnotes(item)" style="cursor: pointer; white-space: pre-line;"><small>{{item['item']}}</small></td>
                            <td @click="decisionaddnotes(item)" style="cursor: pointer;">
                                <small v-if="item['notes'] == null || item['notes'].length === 0 || Object.keys(item.notes).length === 0">
                                    add notes
                                </small>
                                <div v-else>
                                    <small
                                        v-for="(notes, notesindex) in item['notes']" 
                                        :key="notesindex">
                                        {{notes}}<br/>
                                    </small>
                                </div>
                            </td>
                            <td><v-btn text @click="deleteitemdecision(item)"><v-icon>mdi-delete</v-icon></v-btn></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-list-group>
        </v-list>
        <DialogWarning
            v-model="dialogwarning"
            :value="dialogwarning"
            :dialogsvalue="dialogwarningcontent"
            @delproject="deleteitem"
            @donothing="donothing"></DialogWarning>
        
        <DialogComponent
            v-model="addnotesdialog"
            :value="addnotesdialog"
            :dialogvalue="decisionsnotessetup"
            :childdata="getdecisionsedit"
            @closedialog="closedialog"
            @savedecisionnotes="savedecisionnotes"
            @deleteNoteItem="deleteNoteItem"
            @edit_note="edit_note"></DialogComponent>

        <v-row justify="center">
            <v-dialog
                v-model="deleteNoteItemModel"
                persistent
                max-width="540">
                <v-card>
                    <v-card-title class="text-small">
                        Are you sure you want delete this item?
                    </v-card-title>
                    <v-card-text>
                        This operation is irreversible
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="deleteNoteItemModel = false; deleteNoteItemObj = {}">
                            Cancel
                        </v-btn>
                        <v-btn
                            color="red"
                            class="pl-10 pr-10 white--text"
                            @click="cDeleteItemNote">
                            <v-icon
                                class="mr-2">
                                mdi-delete
                            </v-icon>
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog
            v-model="editNoteItemModel"
            persistent
            max-width="540">
                <v-card>
                    <v-card-title class="text-small">
                    Edit note
                    </v-card-title>
                    <v-card-text>
                    <v-text-field
                        v-model="editNoteItemObj.note"
                        :value="editNoteItemObj.note"
                        outlined
                        dense
                        prepend-inner-icon="mdi-note-outline">
                    </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="editNoteItemModel = false; editNoteItemObj = {}">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pl-10 pr-10 white--text"
                        @click="save_edited_note">
                        <v-icon
                            class="mr-2">
                            mdi-content-save-check-outline
                        </v-icon>
                        Update
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DialogWarning from '@/components/DialogWarning.vue';
import DialogComponent from '@/components/DialogComponent.vue';

export default {
    data: () => ({
        headers: [
            { text: '', width: '1%' },
            { text: 'Item' },
            { text: 'Notes' },
            { text: 'Delete' }
        ],
        item_progress: 0,
        remaining_item_not_done: 0,
        deleteNoteItemModel: false,
        deleteNoteItemObj: {},
        dialogwarning: false,
        dialogwarningcontent: {
            warningdelete: true,
            title: 'Are you sure you want to delete this item?',
            content: 'All of the data that belongs to this item will be deleted, are you sure about this?',
            buttons: [
                {
                    id:         2,
                    label:      'Yes',
                    action:     true,
                    class:      '',
                    text:       true,
                    dark:       false
                },
                {
                    id:         1,
                    label:      'No',
                    action:     false,
                    color:      '#0b4ef3',
                    text:       false,
                    class:      'pl-16 pr-16',
                    dark:       true
                },
            ]
        },
        addnotesdialog: false,
        decisionsnotessetup: {
            decisionsnotes:   true,
            icon:             'mdi-plus',
            iconclass:        'mr-1',
            textarea: [
                {
                    id:       1,
                    label:    'notes',
                    hint:     'add a note',
                    icon:     'mdi-tooltip-text-outline'
                }
            ]
        },
        editNoteItemObj: {},
        editNoteItemModel: false,
    }),
    props: {
        add_new_decision: Boolean
    },
    components: {
        DialogWarning,
        DialogComponent
    },
    computed: {
        ...mapGetters({
            getuser:                    'auth/getuser',
            getproject:                 'auth/getproject',
            getdecisionsdata:           'decisions/getdecisionsdata',
            getdecisionsedit:           'decisions/getdecisionsedit',
            getdecisionstodelete:       'decisions/getdecisionstodelete'
        })
    },
    methods: {
        ...mapActions({
            update_decision_data:   'decisions/update_decision_data_action',
            delete_decision_data:   'decisions/delete_decision_data_action',
            delete_decision_note:   'decisions/delete_decision_note_action',
            update_decision_note:   'decisions/update_decision_note_action'
        }),
        showSnackBar(message){
            this.$store.commit("auth/setMessage",
            {show: true, message: message},
            {root: 1})
        },
        async gDecisionL(){
            await this.$axios.get(`decisions/gDecisionL/${this.getuser.id}/${this.getproject.id}`)
            .then(({data}) => {
                if(data.data.length === 0){
                    this.generatedecisionstemplate()
                    return
                }
                this.$store.dispatch('decisions/setdecisionsdata', data.data);
            });
        },
        async generatedecisionstemplate(){
            await this.$axios.get(`decisions/generateDecisionTemplate/${this.getproject.id}`)
            .then(({data}) => {
                if(data.response){
                    this.gDecisionL()                    
                }
            })
        },
        async markdonedecision(item) {
            await this.$axios.patch(`decisions/setDoneDec`, {id: item.id, is_done: item.is_done})
            .then(({data}) => {
                if(data.response){
                    this.update_decision_data(item);        
                }
            });
        },
        compute_progress(item) {
            const total_count = item.child.length;
            const total_done = item.child.filter(child => child.is_done == true).length;
            item.getnotdone_count = total_count - total_done;
            return (total_done / total_count) * 100;
        },
        async cDeleteItemNote(){
            let tp = {
                id:           this.getdecisionsedit.id,
                note:         this.deleteNoteItemObj.note,
                noteindex:    this.deleteNoteItemObj.noteindex
            }            
            await this.$axios.post('/delete_item_note', tp)
            .then(({data}) => {
                if(data.response){
                    this.showSnackBar(data.message)
                    this.deleteNoteItemModel = false
                    this.deleteNoteItemObj = {}                    
                    this.delete_decision_note({item: this.getdecisionsedit, tp: tp})
                    this.closedialog()
                }
            })
        },
        deleteitemdecision(data) {
            this.$store.dispatch('decisions/setdecisionstodelete', data)
            this.dialogwarning = true
        },
        async deleteitem(){
            await this.$axios.delete(`decisions/deletedecisioninstance/${this.getdecisionstodelete.id}`)
            .then(({data}) => {
                if(data.response){
                    this.delete_decision_data(this.getdecisionstodelete)
                    this.closedialog()
                }
            })
        },
        closedialog() {
            this.dialogwarning = false
            this.addnotesdialog = false
        },
        donothing(){
            this.dialogwarning = false
        },
        decisionaddnotes(data){
            this.$store.dispatch('decisions/setdecisionsedit', data)
            this.addnotesdialog = true
        },
        async savedecisionnotes(data){
            let tp = {
                note:           data.textfield[0].notes,
                data:           this.getdecisionsedit
            }
            
            await this.$axios.post('decisions/addNoteDecision', tp)
            .then(({data}) => {
                if(data.response){                    
                    // Update Decision
                    this.update_decision_note({item: tp.data, note: tp.note});
                    this.closedialog()
                    this.showSnackBar("successful adding a note!")
                }else{
                    this.showSnackBar(data.message)
                }
            })
        },
        deleteNoteItem(data){
            this.deleteNoteItemModel = true
            this.deleteNoteItemObj = data
        },
        edit_note(data){
            this.editNoteItemObj = data
            this.editNoteItemModel = true
        },
        async save_edited_note(){           
            let tp = {
                id:         this.getdecisionsedit.id,
                note:       this.editNoteItemObj.note,
                noteindex:  this.editNoteItemObj.noteindex
            }

            await this.$axios.post('/edit_item_note', tp)
            .then(({data}) => {
                if(data.response){
                    this.editNoteItemObj = {}
                    this.editNoteItemModel = false
                    this.showSnackBar(data.message)
                    this.gDecisionL()
                    this.closedialog()
                }
            })
        },
        user_access(item_name) {
            if ( item_name == 'PLANNING & BANKING' ) {
                return false;
            } else {               
                if ( this.getuser.info.access_id == 1 ) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        child_access(parent_name, child_name) {
            console.log(child_name);
            if ( parent_name == 'PLANNING & BANKING' ) {
                const access_free_tier = [
                    "Decide on what type of external cladding you want, e.g. stone, timber, none, etc.",
                    "Decide if you will include a garage on your plans.",
                    "Decide your construction type, e.g., block build, timber frame, icf etc.",
                    "Decide how much money you will need to complete your build.",
                    "Decide which mortgage lender you will bank with."
                ];                
                if ( access_free_tier.includes(child_name) && this.getuser.info.access_id == 1 ) {
                    return true;
                } else {
                    return false;
                }               

            } else {
                /** Check for access_id */
                if ( this.getuser.info.access_id == 1 ) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    },
    mounted() {
        this.gDecisionL();
    },
    watch: {
        add_new_decision() {
            if ( this.add_new_decision == true ) {
                this.gDecisionL();
            }
        }
    }
}

</script>