<template>
    <v-col
        cols="5"
    >
        <v-card>
            <v-img
                v-if="get_product_page.image == ''"
                :max-width="650"
                src="https://collaroyrugby.com.au/wp-content/uploads/2020/03/Pop-up-kids-coaching-clinic-at-Rat-Park-on-Sunday.png"
                class="ma-5"
            ></v-img>
            <v-carousel v-model="model">
                <v-carousel-item
                    v-for="(iimage, imageindex) in JSON.parse(get_product_page.image)"
                    :key="imageindex"
                >
                    <v-img
                        :src="`${image}${iimage}`"
                        :max-width="650"
                    />
                </v-carousel-item>
            </v-carousel>
        </v-card>
    </v-col>
</template>
<script>
export default {
    props: [
        'get_product_page'
    ],
    data : () => ({
        image: process.env.VUE_APP_URL,
        model: 0,
    }),
}
</script>