export default {
    namespaced: true,
    state: {
        suppliers: [],
        to_edit_supplier: {},
        counties: []
    },
    mutations: {
        set_suppliers(state, payload){
            state.suppliers = [...payload]
        },
        set_to_edit_supplier(state, payload){
            state.to_edit_supplier = payload
        },
        set_counties(state, payload){
            state.counties = [...payload]
        }
    },
    getters: {
        get_suppliers : state => state.suppliers,
        get_to_edit_supplier : state => state.to_edit_supplier,
        get_counties : state => state.counties
    },
    actions: {
        set_suppliers({commit}, payload){
            commit('set_suppliers', payload)
        },
        set_to_edit_supplier({commit}, payload){
            commit('set_to_edit_supplier', payload)
        },
        set_counties({commit}, payload){
            commit('set_counties', payload)
        }
    }
}