<template>
    <v-container fluid>
        <v-card>
            <v-card-title class="card-title">
                <strong>Helper Training File(s)</strong>
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    color="primary"
                    class="pl-12 pr-12"
                    @click="show_dialog">
                    <v-icon class="mr-3">mdi-file-upload-outline</v-icon>upload
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-tabs
                    v-model="tab"
                    centered
                    icons-and-text>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab
                        v-for="(tab, tabindex) in tab_items"
                        :key="tabindex"
                        :href="tab.href">
                        {{ tab.label }}
                        <v-icon>{{tab.icon}}</v-icon>
                    </v-tab>
                    <v-tabs-items v-model="tab">
                        <v-tab-item
                            value="videos">
                            <VideoIterator 
                                @edit_dialog="edit_dialog($event)">
                            </VideoIterator>
                        </v-tab-item>
                        <v-tab-item
                            value="pdf">
                            <PdfTable
                                @edit_dialog="edit_dialog($event)">
                            </PdfTable>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
            </v-card-text>
        </v-card>
        <!-- Dialog Boxes -->
        <UploadDialog
            :upload_dialog="uploaddialog"
            :is_edit="is_edit"
            @close_dialog="close_dialog"
            v-on:close_after_upload="close_dialog"
            :item="item_for_edit">
        </UploadDialog>
        <!-- /Upload Dialogbox  --> 

        <GlobalMessage
            :message="'This is a test'"
            ></GlobalMessage>
    </v-container>
</template>

<script>
import VideoIterator from  './components/VideoIterator.vue';
import UploadDialog from './components/UploadDialog.vue';
import PdfTable from './components/PdfTable.vue';
import GlobalMessage from '@/components/GlobalMessage.vue';

/**
 * Please ensure that all XMLHTTP Request like axios should be put in the
 * vuex state. Just mapped the actions in the component, and called the action
 * in the component method.
 */
export default {
    data: () => ({
        item_for_edit: {},
        uploaddialog: false,
        is_edit: false,    
        tab: null,
        tab_items:  [
            {
                //icon    : 'mdi-video',
                label   : 'Videos',
                href    : '#videos'
            },
            {
                //icon    : 'mdi-file-pdf-box',
                label   : 'PDF',
                href    : '#pdf'
            }
        ],
        show_deleted: false
    }),
    components: {
        VideoIterator,
        UploadDialog,
        PdfTable,
        GlobalMessage
    },
    computed: {
    },
    methods: {
        edit_dialog(e) {
            this.uploaddialog = true;
            this.is_edit = true;
            this.item_for_edit = e;                      
        },
        show_dialog() {
            this.uploaddialog = true;
            this.is_edit = false;
        },
        close_dialog() {
            this.uploaddialog = false;
            this.is_edit = false;
            this.item_for_edit = {};
        }
    },
    watch: {
        
    }, mounted() {
       
    }
}
</script>