<template>
    <v-row justify="center">
        <v-dialog
            v-model="editTransactionItemDialog"
            transition="dialog-bottom-transition"
            max-width="500"
            persistent>
            <v-card>
                <v-card-title>Update Transaction Item</v-card-title>
                <v-card-text class="mt-4">
                    <v-form ref="editTransItemForm">
                        <v-text-field
                            label="Supplier's Name"
                            hint="Supplier's Name"
                            prepend-icon="mdi-tooltip-text-outline"
                            dense
                            outlined
                            type="text"
                            v-model="transItemForm.name">
                        </v-text-field>
                        <v-text-field
                            label="Amount"
                            hint="Amount"
                            prepend-icon="mdi-tooltip-text-outline"
                            dense
                            outlined
                            type="text"
                            v-model="transItemForm.amount">
                        </v-text-field>
                        <v-select
                            v-model="transItemForm.paymentMethod"
                            :items="items"
                            prepend-icon="mdi-form-dropdown"
                            label="Payment Method"
                            hint="Payment Method"
                            dense
                            outlined>
                        </v-select>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="transItemForm.transDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="transItemForm.transDate"
                                    label="Transaction Date"
                                    hint="Transaction Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    dense
                                    outlined                                    
                                ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="transItemForm.transDate"
                            no-title
                            scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false">
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(transItemForm.transDate)">
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" class="px-8" @click="evtCloseEditTransItemDialog()">
                        <v-icon>mdi-close</v-icon>
                        <small>Close</small>
                    </v-btn>
                    <v-btn color="rgb(11, 78, 243)" class="white--text px-8" @click="evtUpdateBudgetTransactionItem()">
                        <v-icon>mdi-content-save</v-icon>
                        <small>Save</small>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import BudgetTransaction from './modules/budgettransaction.js'

export default {
    data: () => ({
        editTransactionItemDialog: false,
        transItemForm: {
            name: null,
            amount: 0,
            paymentMethod: null,
            transDate: null
        },
        menu: false,
        items: [
            { value: "Bank Transfer", text: 'Bank Transfer' },
            { value: "Cheque", text: 'Cheque'},
            { value: "Cash", text: 'Cash'},
            { value: "Credit / Debit Card", text: 'Credit / Debit Card'}
        ]
    }),
    methods: {
        evtCloseEditTransItemDialog() {
            this.$emit('evtCloseTransItemDialog')
        },
        evtUpdateBudgetTransactionItem() {                        
            BudgetTransaction().updateBudgetTransactionItem(this.transItemForm, this.transItem.id, this.$store)
            this.evtCloseEditTransItemDialog()
        }
    },
    props: {
        editTransItemDialog: Boolean,
        transItem: Object
    },
    watch: {
        editTransItemDialog() {            
            this.editTransactionItemDialog = this.editTransItemDialog;
        },
        transItem() {
           this.transItemForm.name = this.transItem.name
           this.transItemForm.amount = this.transItem.amount
           this.transItemForm.paymentMethod = this.transItem.payment_method
           this.transItemForm.transDate = new Date(this.transItem.created_at ?? new Date()).toISOString().substr(0, 10)
        }
    }
}

</script>