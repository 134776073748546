<template>
    <v-row justify="center">
        <v-dialog
            width="50%"
            v-model="add_edit_dialog"
            scrollable
            persistent>
                <v-card>
                    <v-card-title>
                        <span v-if="is_edit == false">Upload a Video Archive</span>
                        <span v-else>Edit Uploaded Video Archive</span>
                        <v-spacer></v-spacer>
                        <v-btn text small fab @click="close_dialog" :disabled="is_loading">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>                       
                    </v-card-title>
                    <v-card-text v-if="is_loading">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-progress-linear
                                    color="primary"
                                    height="10"
                                    buffer-value="0"
                                    :value="compute_upload_progress().get_percentage()"
                                    stream>
                                </v-progress-linear>
                            </v-col>
                            <v-col cols="12">
                                <v-alert dense text style="margin:0;"
                                    color="info">
                                    <v-row no-gutters>
                                        <v-col cols="10">
                                            <span>{{compute_upload_progress().get_total_upload_size()}}</span>
                                        </v-col>
                                        <v-col cols="2" class="text-right">
                                            <span>{{ Math.ceil(compute_upload_progress().get_percentage()) }}%</span>
                                        </v-col>
                                    </v-row>
                                </v-alert>                                
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-form
                            ref="form_upload"
                            v-model="valid_inputs"
                            :disabled="is_loading"
                            lazy-validation>
                                <v-text-field
                                    v-model="name"
                                    label="File Title"
                                    :prepend-icon="'mdi-text'"
                                    :rules="name_rules"
                                    required>
                                </v-text-field>

                                <v-file-input                               
                                    v-model="file"
                                    label="File Source"
                                    placeholder="Please pick a file based on the accepted file types."
                                    :accept="accepted_types"
                                    show-size                                    
                                    truncate-length="15">
                                </v-file-input>

                                <!--<v-textarea                                   
                                    counter="2500"
                                    filled
                                    v-model="description"                                    
                                    label="File Description"
                                    hint="Please shorten your file description to only 250 characters. It should explain brief what the file all about.">
                                </v-textarea>-->
                                <v-divider></v-divider>
                                <v-card-actions class="mt-2"
                                    v-if="is_edit == false">
                                    <v-spacer></v-spacer>
                                    <v-btn width="150" text :disabled="is_loading"  @click="close_dialog">
                                        <v-icon>mdi-window-close</v-icon>
                                        Cancel
                                    </v-btn>
                                    <v-btn width="150" color="primary" :disabled="is_loading" @click="upload_video_archive()">
                                        <v-icon>mdi-upload</v-icon>
                                        Upload
                                    </v-btn>
                                </v-card-actions>
                                <v-card-actions class="mt-2"
                                    v-else>
                                    <v-spacer></v-spacer>
                                    <v-btn width="150" text :disabled="is_loading" @click="close_dialog">
                                        <v-icon>mdi-window-close</v-icon>
                                        Cancel
                                    </v-btn>
                                    <v-btn width="150" color="primary" :disabled="is_loading" @click="edit_uploaded_video_archive()">
                                        <v-icon>mdi-upload</v-icon>
                                        Update
                                    </v-btn>
                                </v-card-actions>                       
                        </v-form>
                    </v-card-text>
                </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';


export default {
    data: () => ({
        is_loading: false,
        add_edit_dialog: false,
        valid_inputs: true,
        accepted_types: 'video/mp4, video/ogg, video/webm',
        name: null,
        description: null,
        file: null,
        name_rules: [
            v => !! v || 'File name is required.'
        ]
    }),
    props: {
        show_dialog: Boolean,
        is_edit: Boolean,
        video_item: Object
    },
    computed: {
        ...mapGetters({
            'get_upload_progress':      'admin_video_archived/get_upload_progress_getters'
        })
    },
    methods: {
        ...mapActions({
            'upload_video_archived':            'admin_video_archived/upload_video_archived_actions',
            'update_uploaded_video_archived':   'admin_video_archived/update_uploaded_video_archived_actions'
        }),
        close_dialog() {
            this.$refs.form_upload.reset();
            this.$emit('close_dialog');
        },
        async upload_video_archive() {
            if ( this.$refs.form_upload.validate() == true ) {
                this.is_loading = true;
                await this.upload_video_archived({
                    file: this.file,
                    name: this.name,
                    description: this.description
                });
                this.is_loading = false;
                this.close_dialog();
            }
        },
        async edit_uploaded_video_archive() {
            if ( this.$refs.form_upload.validate() == true ) {
                this.is_loading = true;
                await this.update_uploaded_video_archived({
                    id: this.video_item.id,
                    name: this.name,
                    description: this.description,
                    file: this.file
                });
                this.is_loading = false;
                this.close_dialog();      
            }
        },
        compute_upload_progress() {
            const upload_progress = this.get_upload_progress;

            function get_percentage() {
                return ((upload_progress.loaded / upload_progress.total) * 100)
            }

            function get_total_upload_size() {
                return `${Math.ceil(upload_progress.loaded / (1000 * 1000))} / ${Math.ceil(upload_progress.total / (1000 * 1000))} MB`;
            }

            return { get_percentage, get_total_upload_size };
            
        }
    },
    watch: {
        show_dialog() {
            this.add_edit_dialog = this.show_dialog;
            if ( this.is_edit == false ) {
                this.file = null;
                this.name = null;
                this.description = null;
            } else {
                this.name = this.video_item.name;
                this.description = this.video_item.description;
            }
        }
    }
}

</script>