import Vue from "vue";

export default {
    namespaced: true,
    state: {
      directories: [],
      childfiles: {},
      filetoopen: {},
      todeleteitems: [],
      sb3d: [],
      directories_with_files: []
    },
    mutations: {
      setdirectories(state, payload){
        state.directories = [...payload]
      },
      setchildfiles(state, payload){
        state.childfiles = payload
      },
      setfiletoopen(state, payload){
        state.filetoopen = payload
      },
      settodeleteitems(state, payload){
        state.todeleteitems = [...payload]
      },
      set_sb3d(state, payload){
        state.sb3d = [...payload]
      },
      set_directories_with_files(state, payload) {
        state.directories_with_files = [...payload];
      }
    },
    getters: {
      getdirectories(state){
        return state.directories
      },
      getchildfiles(state){
        return state.childfiles
      },
      getfiletoopen(state){
        return state.filetoopen
      },
      gettodeleteitems(state){
        return state.todeleteitems
      },
      get_sb3d : state => state.sb3d,
      get_directories_with_files_getter: state => state.directories_with_files
    },
    actions: {
      setdirectories({commit}, payload){
        commit('setdirectories', payload)
      },
      setchildfiles({commit}, payload){
        commit('setchildfiles', payload)
      },
      setfiletoopen({commit}, payload){
        commit('setfiletoopen', payload)
      },
      settodeleteitems({commit}, payload){
        commit('settodeleteitems', payload)
      },
      set_sb3d({commit}, payload){
        commit('set_sb3d', payload)
      },
      async fetch_directories_with_files_action({commit}, payload) {
        await Vue.axios.get(`/filemanager_v2/get_directories/${payload.users_id}/${payload.project_id}`).then( ({data}) => {
          commit('set_directories_with_files', data.data);
        });
      }
    }
}