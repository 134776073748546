<template>
    <v-container fluid class="mt-5">
        <v-data-table
          :headers="headers"
          class="elevation-1"
          :items="get_templates"
        >
            <template v-slot:top>
                <v-row>
                    <v-col
                        cols="10"
                    >
                        <v-text-field
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            placeholder="search service type here..."
                            label="search service type here..."
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="2"
                    >
                        <v-btn
                            dense
                            color="primary"
                            class="pl-10 pr-10"
                            @click="create_template"
                        >
                            <v-icon
                                class="mr-3"
                            >mdi-plus</v-icon>
                            Create Template
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item="{ item, expand, isExpanded }" >
                <tr
                    style="cursor:pointer;" 
                    @click="expand(!isExpanded)"
                >
                    <td>{{ item.id }}</td>
                    <td>
                        <v-switch
                            v-model="item.has_checklist"
                            @change="togglechecklist(item)"
                        ></v-switch>
                    </td>
                    <td>
                        {{ item.template_name }}
                    </td>
                    <td>
                        {{ item.created_at }}
                    </td>
                    <td>
                        <v-btn
                            text
                            small
                            outlined
                            @click="delete_item(item.id)"
                        >
                            <v-icon 
                                small
                                class="mr-2"
                            >mdi-delete-outline</v-icon>
                            delete
                        </v-btn>
                    </td>
                </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-card>
                        <v-card-title
                            style="fontSize: 16px;"
                        >
                            <small>
                                <v-icon 
                                    small
                                >
                                </v-icon>
                                {{ item.template_name }}
                            </small>
                            <v-spacer></v-spacer>
                            <v-btn
                                dense
                                text
                                @click="delete_parent(item)"
                            >
                                <v-icon small class="mr-3">mdi-delete</v-icon>
                                delete
                            </v-btn>
                            <v-btn
                                dense
                                class="pl-10 pr-10"
                                color="primary"
                                @click="add_parent(item)"
                            >
                                <v-icon
                                    class="mr-3"
                                    small
                                >
                                    mdi-plus
                                </v-icon>
                                add parent
                            </v-btn>
                        </v-card-title>
                        <v-card-subtitle>
                            created at: {{ item.created_at }}
                        </v-card-subtitle>
                        <v-card-text>
                            <v-data-table
                                :headers="headers_parent"
                                class="elevation-1"
                                :items="item.get_parent"
                            >
                                <template v-slot:item="{ item, expand, isExpanded }" >
                                    <tr
                                        style="cursor:pointer;" 
                                        @click="expand(!isExpanded)"
                                    >
                                        <td>{{ item.id }}</td>
                                        <td>
                                            <small>{{ item.task_name }}</small>
                                        </td>
                                        <td>
                                            {{ item.created_at }}
                                        </td>
                                        <td>
                                            <v-btn
                                                text
                                                small
                                                outlined
                                                @click="delete_parent_task(item.id)"
                                            >
                                                <v-icon 
                                                    small
                                                    class="mr-2"
                                                >mdi-delete-outline</v-icon>
                                                delete
                                            </v-btn>
                                            <v-btn
                                                small
                                                class="mr-3"
                                                color="primary"
                                                @click="batch_upload_child(item)"
                                            >
                                                <v-icon
                                                    small
                                                >
                                                    mdi-upload
                                                </v-icon>
                                                upload tasks
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length">
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-title
                                                    v-for="(itemm, itemmindex) in item.getchild"
                                                    :key="itemmindex"
                                                >
                                                    <v-icon 
                                                        small
                                                    >mdi-tally-mark-2</v-icon>
                                                    {{itemm.task_name}}
                                                    <v-btn
                                                        text
                                                        outlined
                                                        small
                                                        class="ml-3 mr-3"
                                                    >
                                                        <v-icon
                                                            small
                                                        >
                                                            mdi-pen
                                                        </v-icon>
                                                        edit
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        outlined
                                                        small
                                                        class="mr-3"
                                                    >
                                                        <v-icon
                                                            small
                                                        >
                                                            mdi-delete
                                                        </v-icon>
                                                        delete
                                                    </v-btn>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </td>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </td>
            </template>
        </v-data-table>
        <v-row justify="center">
            <v-dialog
                max-width="520"
                v-model="create_template_dialog"
                persistent
            >
            <v-card>
                <v-card-title style="fontSize: 18px;">
                    <small>Add Project Planner Template</small>
                </v-card-title>

                <v-card-text>
                    <v-text-field
                        v-model="a.template_name"
                        dense
                        outlined
                        label="template name"
                    >

                    </v-text-field>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    outlined
                    @click="cancel_add_template"
                >
                    <v-icon>mdi-cancel</v-icon>
                    cancel
                </v-btn>
                <v-btn
                    class="pl-10 pr-10"
                    color="primary"
                    @click="save_new_template"
                >
                    <v-icon
                        class="mr-3"
                    >mdi-content-save-outline</v-icon>save
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                max-width="520"
                v-model="add_parent_dialog"
                persistent
            >
            <v-card>
                <v-card-title style="fontSize: 18px;">
                    <small>Add Parent item of: <strong>{{get_parent_data.template_name}}</strong></small>
                </v-card-title>

                <v-card-text>
                    <v-text-field
                        v-model="p.parent_task"
                        dense
                        outlined
                        label="parent task"
                    >
                    </v-text-field>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    outlined
                    @click="cancel_add_template"
                >
                    <v-icon>mdi-cancel</v-icon>
                    cancel
                </v-btn>
                <v-btn
                    class="pl-10 pr-10"
                    color="primary"
                    @click="save_parent_task"
                >
                    <v-icon
                        class="mr-3"
                    >mdi-content-save-outline</v-icon>save
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                max-width="380"
                v-model="warning_delete_dialog"
                persistent
            >
            <v-card>
                <v-card-title style="fontSize: 18px;" class="text-center">
                    <small><strong>Warming You're About To Delete Parent Item!</strong></small>
                </v-card-title>

                <v-card-text class="text-center">
                    <strong>
                        Are you sure about this?
                    </strong>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    outlined
                    @click="cancel_add_template"
                >
                    <v-icon>mdi-cancel</v-icon>
                    No
                </v-btn>
                <v-btn
                    class="pl-10 pr-10"
                    color="primary"
                    @click="delete_parent_item"
                >
                    <v-icon
                        class="mr-3"
                    >mdi-content-save-outline</v-icon>Yes
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
                max-width="480"
                v-model="upload_batch_child_dialog"
                persistent
            >
            <v-card>
                <v-card-title style="fontSize: 14px;">
                    <small><strong>Choose A CSV File that contains item(s) of this parent task</strong></small>
                </v-card-title>

                <v-card-text>
                    <v-file-input
                      v-model="u.file"
                      dense
                      outlined
                      label="Choose CSV"
                    ></v-file-input>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    outlined
                    @click="cancel_upload_child_template"
                >
                    <v-icon>mdi-cancel</v-icon>
                    No
                </v-btn>
                <v-btn
                    class="pl-10 pr-10"
                    color="primary"
                    @click="save_upload_child_tasks"
                >
                    <v-icon
                        class="mr-3"
                    >mdi-content-save-outline</v-icon>Save
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    headers: [
        { text: "ID" },
        { text: 'Has Checklist' },
        { text: 'Template Name' },
        { text: 'Date Created' },
        { text: 'Actions' }
    ],
    headers_parent: [
        { text: 'ID' },
        { text: 'Task Name' },
        { text: 'Date Created' },
        { text: 'Action(s)' }
    ],
    search: null,
    create_template_dialog: false,
    a: {
        template_name: null
    },
    add_parent_dialog: false,
    p: {
        parent_task: null
    },
    warning_delete_dialog: false,
    upload_batch_child_dialog: false,
    u: {
        file: []
    }
  }),
  async mounted () {
    await this.$axios.get('admin/project_planner/get_project_planner_temps')
    .then(({data}) => {
        this.$store.dispatch('admin_project_planner/set_templates', data.data)
    })
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_templates:              'admin_project_planner/get_templates',
        get_parent_data:            'admin_project_planner/get_parent_data',
        get_to_delete_data:         'admin_project_planner/get_to_delete_data'
    })
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    create_template(){
        this.create_template_dialog = true
    },
    cancel_add_template(){
        this.a.template_name = null
        this.create_template_dialog = false
        this.add_parent_dialog = false
        this.p.parent_task = null
        this.warning_delete_dialog = false
    },
    async save_new_template(){
        const tp = {
            template_name: this.a.template_name
        }
        await this.$axios.post('admin/project_planner/add_template', tp)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.cancel_add_template()
                this.$store.dispatch('admin_project_planner/fetch_templates')
            }else{
                this.showSnackBar(data.message)
            }
        })
    },
    add_parent(data){
        // console.log(data)
        this.$store.dispatch('admin_project_planner/set_parent_data', data)
        this.add_parent_dialog = true
    },
    async save_parent_task(){
        const tp = {
            task:       this.p.parent_task,
            id:         this.get_parent_data.id
        }
        await this.$axios.post('admin/project_planner/add_parent_task', tp)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.cancel_add_template()
                this.$store.dispatch('admin_project_planner/fetch_templates')
            }else{
                this.showSnackBar(data.message)
            }
        })
    },
    delete_parent(data){
        this.warning_delete_dialog = true
        this.$store.dispatch('admin_project_planner/set_to_delete_data', data)
    },
    async delete_parent_item(){
        await this.$axios.delete(`admin/project_planner/delete_parent_item/${this.get_to_delete_data.id}`)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.cancel_add_template()
                this.$store.dispatch('admin_project_planner/fetch_templates')
            }
        })
    },
    async delete_parent_task(data){
        await this.$axios.delete(`admin/project_planner/delete_parent_task/${data}`)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.$store.dispatch('admin_project_planner/fetch_templates')
            }
            this.showSnackBar(data.message)
        })
    },
    batch_upload_child(data){
        this.$store.dispatch('admin_project_planner/set_parent_data', data)
        this.upload_batch_child_dialog = true
    },
    cancel_upload_child_template(){
        this.upload_batch_child_dialog = false
        this.u.file = []
    },
    async save_upload_child_tasks(){
        const fData = new FormData()
        fData.append('file', this.u.file)
        fData.append('parent_id', this.get_parent_data.id)
        await this.$axios.post('admin/project_planner/upload_child_data', fData)
        .then(({data}) => {
            if(data.response){
                this.cancel_upload_child_template()
                this.$store.dispatch('admin_project_planner/fetch_templates')
            }
        })
    },
    async togglechecklist(data){
        let tp = {
            checklist:          data.has_checklist ?? 0,
            id:                 data.id
        }
        await this.$axios.patch('admin/project_planner/update_has_checklist', tp)
        .then(({data}) => {
            if(data.response){
                this.$store.dispatch('admin_project_planner/fetch_templates')
            }
        })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>