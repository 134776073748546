import store from '@/states/index.js'

import ShopComponent from '../ShopComponent.vue'

export default [
    {
        path: '/admin_shop',
        component: ShopComponent,
        name: 'admin_shop',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 1){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]