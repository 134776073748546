import Vue from "vue";

export default {
    namespaced: true,
    state: {
      decisionsdata: [],
      decisionsedit: {},
      decisionstodelete: {},
      decisions_viewdata: []
    },
    mutations: {
      setdecisionsdata(state, payload){
        state.decisionsdata = [...payload]
      },
      setdecisionsedit(state, payload){
        state.decisionsedit = payload
      },
      setdecisionstodelete(state, payload) {
        state.decisionstodelete = payload
      },
      set_decisions_viewdata(state, payload) {
        state.decisions_viewdata = [...payload];
      },
      update_decision_data_mutation(state, payload) {
        const decision = state.decisionsdata.find(item => item.id == payload.parent_id)
                         .child.find(child => child.id == payload.id);
        decision.is_done = payload.is_done;
      },
      delete_decision_data_mutation(state, payload) {
        const decision = state.decisionsdata.find(item => item.id == payload.parent_id);
        const index = decision.child.findIndex(child => child.id == payload.id);
        decision.child.splice(index, 1);
      },
      delete_decision_note_mutation(state, payload) {
        const decision = state.decisionsdata.find(item => item.id == payload.item.parent_id)
                        .child.find(child => child.id == payload.item.id);
        decision.notes.splice(payload.tp.noteindex, 1);     
      },
      update_decision_note_mutation(state, payload) {
        const decision = state.decisionsdata.find(item => item.id == payload.item.parent_id)
                        .child.find(child => child.id == payload.item.id);
        if ( decision.notes == null ) {
          decision.notes = [];
        }         
        decision.notes.push(payload.note);        
      }
    },
    getters: {
      getdecisionsdata(state){
        return state.decisionsdata
      },
      getdecisionsedit(state){
        return state.decisionsedit
      },
      getdecisionstodelete(state){
        return state.decisionstodelete
      },
      getdecisionsparent(state){
        return state.decisionsdata.filter(q => {
          return q.is_parent === 1
        })
      },
      get_decisions_viewdata_getter: state => state.decisions_viewdata
    },
    actions: {
      setdecisionsdata({commit}, payload){
        commit('setdecisionsdata', payload)
      },
      setdecisionsedit({commit}, payload){
        commit('setdecisionsedit', payload)
      },
      setdecisionstodelete({commit}, payload){
        commit('setdecisionstodelete', payload)
      },
      async fetch_decisions_data_action({commit}, payload) {
        await Vue.axios.get(`/decisions/get_decisions/${payload.project_id}/${payload.user_id}`)
          .then( ({data}) => {
            commit('set_decisions_viewdata', data.data);
          });
      },
      update_decision_data_action({commit}, payload) {       
        commit('update_decision_data_mutation', payload);
      },
      delete_decision_data_action({commit}, payload) {
        commit('delete_decision_data_mutation', payload);
      },
      delete_decision_note_action({commit}, payload) {        
        commit('delete_decision_note_mutation', payload);
      },
      update_decision_note_action({commit}, payload) {
        commit('update_decision_note_mutation', payload);
      }
    }
}