<template>
        <v-card style="height:406px;border-radius:10px;padding:24px;" class="v-card-shadow">
            <v-card-title style="padding:0px;height:23px;">
                <div style="font-weight:600;font-size:20px;height:23px;">Reminders</div>
                <div class="ml-1" style="font-size:16px;color:#676767;height:23px;">({{ get_reminders_data(false).length }})</div>
                <v-spacer></v-spacer>
                <v-btn text style="text-transform:none;color:#0B52FF;font-size:18px;font-weight:400;height:23px;" @click="add_reminder_dialog = true"><v-icon>mdi-plus</v-icon> Add</v-btn>
            </v-card-title>
            <v-card-text style="height:127px;padding:0px;margin-top:16px;">
                <v-row align="center" dense v-for="(item, index) in get_reminders_data(false).slice(0, 3)" :key="index" style="color:black;margin-top:0px;padding-bottom:5px;font-weight:400;font-size:16px;">
                    <v-col cols="2" lg="1" md="2">
                        <v-checkbox
                            v-model="item.is_completed"
                            @click="complete_reminder(item)">
                        </v-checkbox>
                    </v-col>
                    <v-col cols="10" lg="11" md="10">{{ item.todo }}</v-col>
                </v-row>
            </v-card-text>
            <v-card-title style="padding:0px;height:23px;">
                <div style="font-weight:700;font-size:16px;color:#026400;">Completed</div>
                <div class="ml-1" style="font-size:16px;color:#676767;">({{ get_reminders_data(true).length }})</div>
                <v-spacer></v-spacer>
                <v-btn text style="text-transform:none;color:#0B52FF;font-size:18px;font-weight:400;" @click="$router.push('reminders')">View all</v-btn>
            </v-card-title>
            <v-card-text style="height:100px;padding:0px;margin-top:16px;">
                <v-row align="center" dense v-for="(item, index) in get_reminders_data(true).slice(0, 2)" :key="index" style="color:#676767;margin-top:0px;padding-bottom:5px;font-size:16px;">
                    <v-col cols="2" lg="1" md="2">
                        <v-checkbox
                            v-model="item.is_completed"
                            @click="uncomplete_reminder(item)">
                        </v-checkbox>
                    </v-col>
                    <v-col cols="10" lg="11" md="10"><s>{{ item.todo }}</s></v-col>
                </v-row>
            </v-card-text>
            <v-card-text style="padding:0px;">
                <v-btn 
                    style="text-transform:none;color:#0B52FF;background-color:#CBE9FF;font-size:18px;
                    font-weight:700;border-radius:24px;padding:12px 24px;box-shadow:none;height:45px;width:198px;
                    letter-spacing:0px;" 
                    @click="$router.push('reminders')">
                    View all reminders
                </v-btn>
            </v-card-text>
            <AddRemindereDialog
              :open_add_reminder_dialog="add_reminder_dialog"
              @close_add_reminder_dialog="evt_close_add_reminder_dialog()"/>
        </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddRemindereDialog from './AddRemindereDialog.vue'
export default {
    components: {
        AddRemindereDialog
    },
    data: () => ({
        add_reminder_dialog: false,
    }),
    computed: {
        ...mapGetters({
            getuserinfo:            'auth/getuser',
            getproject:             'auth/getproject',
            get_reminders_data:     'reminders/get_reminders_data_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_all_reminders_data:   'reminders/fetch_all_reminders_data_action',
            set_complete_reminder:      'reminders/set_complete_reminder_action',
            set_uncomplete_reminder:    'reminders/set_uncomplete_reminder_action'
        }),
        complete_reminder(item) {
            this.set_complete_reminder(item);
        },
        uncomplete_reminder(item) {
            this.set_uncomplete_reminder(item);
        },
        evt_close_add_reminder_dialog() {
            this.add_reminder_dialog = false;
        }
    },
    mounted() {
        
    },
    created() {
        if ( this.getproject.id != null ) {
            this.fetch_all_reminders_data({
                users_id: this.getuserinfo.id,
                project_id: this.getproject.id
            });
        }
    },
    watch: {

    }
}
</script>

<style scoped lang="scss">
:deep(.v-input--checkbox), :deep(.v-input__slot) {
    margin-top:0px;
    padding-top:0px;
    margin-bottom:0px;
}

:deep(.v-messages) {
    min-height:0px;
}
</style>