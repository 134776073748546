<template>
    <v-row justify="center">
        <v-dialog
            v-model="view_deletedtransaction_dialog"
            persistent
            scrollable>
            <v-card>
                <v-card-title>Deleted Transactions</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="get_deleted_transaction"
                        :items-per-page="10"
                        :search="search">
                        <template v-slot:top>
                            <v-text-field
                            v-model="search"
                            label="Search Name | Payment Method"
                            class="mx-4"
                            ></v-text-field>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="px-16 white--text" text color="error"
                        @click="closedialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        view_deletedtransaction_dialog: false,
        headers: [
            {text: 'Amount', value: 'amount'},
            {text: 'Payment Method', value: 'payment_method'},
            {text: 'Date', value: 'created_at'},
            {text: 'Name', value: 'name'},
            {text: 'Item', value: 'get_budgetmanager.item_description'},
            {text: 'Template', value: 'get_budgetmanager.get_template.template_name'}
        ],
        search: ''
    }),
    props: {
        show_deletedtransaction_dialog: Boolean,
        users_id: Number,
        project_id: Number        
    },
    computed: {
        ...mapGetters({
            get_deleted_transaction:            'budget_transaction/get_deleted_transaction_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_deleted_transactions:         'budget_transaction/fetch_deleted_transactions_action'
        }),
        closedialog() {
            this.$emit('closedialog');
        }
    },
    watch: {
        show_deletedtransaction_dialog() {
            this.view_deletedtransaction_dialog = this.show_deletedtransaction_dialog;
            if ( this.show_deletedtransaction_dialog == true ) {
                this.fetch_deleted_transactions({
                    'users_id': this.users_id,
                    'project_id': this.project_id
                });
            }
        }
    }
}
</script>