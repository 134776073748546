import store from '@/states/index.js'

import SiteDiaryPage from '../SiteDiaryPage.vue'

export default [
    {
        path: '/sitediaryv2',
        component: SiteDiaryPage,
        name: 'sitediaryv2',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if ( role.info.role === 3 ) {
                next()
            } else {
                next('/')
            }            
        }
    }
]