<template>
    <v-card
        flat
    >
        <v-card-text>
            <v-data-table
                :headers="header_checked_ut"
                :items="get_cart"
                class="elevation-1"
            >
                <template v-slot:item="props">
                    <tr>
                        <td>
                            <label
                                style="font-weight: 500; font-size: 15px; font-family: 'Poppins', sans-serif;"
                            >
                                {{ props.item.name }}
                            </label>
                        </td>
                        <td>
                            <div
                                v-for="index in 1"
                                :key="index"
                            >
                                <v-img
                                    :max-width="300"
                                    :aspect-ratio="1"
                                    :src="`${image}${JSON.parse(props.item.image)[0]}`"
                                />
                            </div>
                        </td>
                        <td>
                            <label>{{ props.item.qty }}</label>
                        </td>
                        <td>
                            <label
                                style="font-weight: 500; font-size: 12px; font-family: 'Poppins', sans-serif;"
                            >
                                {{ props.item.created_at }}
                            </label>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="close_dialog()"
        >
            Close
        </v-btn>
        </v-card-actions>
    </v-card>
    </template>
    
    <script>
    export default {
      components: {
      },
      props: [
        'header_checked_ut',
        'get_cart',
        'get_user'
      ],
      data () {
        return {
            image: process.env.VUE_APP_URL,
        }
      },
      mounted () {
      },
      created () {
      },
      computed: {
      },
      methods: {
        close_dialog(){
            this.$emit('close_dialog')
        }
      },
      watch: {
      }
    }
    </script>
    
    <style scoped lang="scss">
    </style>