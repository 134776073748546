import store from '@/states/index.js'

import VideoArchived from '../VideoArchived'

export default [
    {
        path: '/admin_video_archived',
        component: VideoArchived,
        name: 'admin_video_archived',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if (role.info.role === 1) {
                next()
            } else {
                next('/')
            }
            return
        }
    }
]