export default {
    namespaced: true,
    state: {
        lost_quotes: []
    },
    mutations: {
        set_lost_quotes(state, payload){
            state.lost_quotes = [...payload]
        }
    },
    getters: {
        get_lost_quotes : state => state.lost_quotes
    },
    actions: {
        set_lost_quotes({commit}, payload){
            console.log(payload)
            commit('set_lost_quotes', payload)
        }
    }
}